export const OPTIONSComuni = [
  {
    value: 'AG',
    label: 'Agrigento',
    children: [
      {
        value: 'A089',
        label: 'AGRIGENTO o GIRGENTI',
      },
      {
        value: 'A181',
        label: 'ALESSANDRIA o ALESSANDRIA DELLA ROCCA',
      },
      {
        value: 'A351',
        label: 'ARAGONA',
      },
      {
        value: 'A896',
        label: 'BIVONA',
      },
      {
        value: 'B275',
        label: 'BURGIO',
      },
      {
        value: 'B377',
        label: 'CALAMONACI',
      },
      {
        value: 'B427',
        label: 'CALTABELLOTTA',
      },
      {
        value: 'B460',
        label: 'CAMASTRA',
      },
      {
        value: 'B486',
        label: 'CAMMARATA',
      },
      {
        value: 'B520',
        label: 'CAMPOBELLO DI LICATA',
      },
      {
        value: 'B602',
        label: "CANICATTI'",
      },
      {
        value: 'C275',
        label: 'CASTELTERMINI',
      },
      {
        value: 'C341',
        label: 'CASTROFILIPPO',
      },
      {
        value: 'C356',
        label: 'CATTOLICA o CATTOLICA ERACLEA',
      },
      {
        value: 'C668',
        label: 'CIANCIANA',
      },
      {
        value: 'C928',
        label: 'COMITINI',
      },
      {
        value: 'D514',
        label: 'FAVARA',
      },
      {
        value: 'E209',
        label: 'GROTTE',
      },
      {
        value: 'E390',
        label: 'JOPPOLO GIANCAXIO',
      },
      {
        value: 'E431',
        label: 'LAMPEDUSA o LAMPEDUSA E LINOSA',
      },
      {
        value: 'E573',
        label: 'LICATA',
      },
      {
        value: 'E714',
        label: 'LUCCA o LUCCA SICULA',
      },
      {
        value: 'F126',
        label: 'MENFI',
      },
      {
        value: 'F299',
        label: 'MOLO o PORTO EMPEDOCLE',
      },
      {
        value: 'F414',
        label: 'MONTALLEGRO',
      },
      {
        value: 'F655',
        label: 'MONTEVAGO',
      },
      {
        value: 'F845',
        label: 'NARO',
      },
      {
        value: 'G282',
        label: 'PALMA o PALMA DI MONTECHIARO',
      },
      {
        value: 'H148',
        label: 'RACALMUTO',
      },
      {
        value: 'H159',
        label: 'RAFFADALI',
      },
      {
        value: 'H194',
        label: 'RAVANUSA',
      },
      {
        value: 'H205',
        label: 'REALMONTE',
      },
      {
        value: 'H269',
        label: 'RIBERA',
      },
      {
        value: 'H743',
        label: 'SAMBUCA o SAMBUCA DI SICILIA o SAMBUCA LABAT o SAMBUCA ZABUT',
      },
      {
        value: 'H778',
        label: 'SAN BIAGIO o SAN BIAGIO PLATANI',
      },
      {
        value: 'H914',
        label: 'SAN GIOVANNI DI CAMMARATA o SAN GIOVANNI GEMINI',
      },
      {
        value: 'I290',
        label: "SANT'ANGELO MUXARO",
      },
      {
        value: 'I185',
        label: 'SANTA ELISABETTA',
      },
      {
        value: 'I224',
        label: 'SANTA MARGHERITA o SANTA MARGHERITA DI BELICE',
      },
      {
        value: 'I356',
        label: 'SANTO STEFANO o SANTO STEFANO QUISQUINA',
      },
      {
        value: 'I533',
        label: 'SCIACCA',
      },
      {
        value: 'I723',
        label: 'SICULIANA',
      },
      {
        value: 'L944',
        label: 'VILLAFRANCA o VILLAFRANCA SICULA',
      },
    ],
  },

  {
    value: 'AL',
    label: 'Alessandria',
    children: [
      {
        value: 'A052',
        label: 'ACQUI o ACQUI TERME',
      },
      {
        value: 'A146',
        label: 'ALBERA o ALBERA LIGURE',
      },
      {
        value: 'A182',
        label: 'ALESSANDRIA',
      },
      {
        value: 'A189',
        label: 'ALFIANO o ALFIANO NATTA',
      },
      {
        value: 'A197',
        label: 'ALICE o ALICE BEL COLLE',
      },
      {
        value: 'A211',
        label: "ALLUVIONI CAMBIO'",
      },
      {
        value: 'M397',
        label: 'ALLUVIONI PIOVERA',
      },
      {
        value: 'A227',
        label: 'ALTAVILLA o ALTAVILLA MONFERRATO',
      },
      {
        value: 'A245',
        label: 'ALZANO o ALZANO SCRIVIA',
      },
      {
        value: 'A300',
        label: 'ANNONE o CASTELLO DI ANNONE',
      },
      {
        value: 'A436',
        label: 'ARQUATA o ARQUATA SCRIVIA',
      },
      {
        value: 'A523',
        label: 'AVOLASCA',
      },
      {
        value: 'A527',
        label: "AZZANO o AZZANO DEL TANARO o AZZANO D'ASTI",
      },
      {
        value: 'A556',
        label: "BAGNASCO D'ASTI",
      },
      {
        value: 'A605',
        label: 'BALZOLA',
      },
      {
        value: 'A689',
        label: 'BASALUZZO',
      },
      {
        value: 'A708',
        label: 'BASSIGNANA',
      },
      {
        value: 'A738',
        label: 'BELFORTE o BELFORTE MONFERRATO',
      },
      {
        value: 'A793',
        label: 'BERGAMASCO',
      },
      {
        value: 'A812',
        label: 'BERZANO o BERZANO DI SAN PIETRO',
      },
      {
        value: 'A813',
        label: 'BERZANO o BERZANO DI TORTONA',
      },
      {
        value: 'A888',
        label: 'BISIO',
      },
      {
        value: 'A889',
        label: 'BISTAGNO',
      },
      {
        value: 'A998',
        label: 'BORGHETTO DI BORBERA',
      },
      {
        value: 'B037',
        label: 'BORGO SAN MARTINO',
      },
      {
        value: 'B029',
        label: 'BORGORATTO o BORGORATTO ALESSANDRINO',
      },
      {
        value: 'B071',
        label: 'BOSCO o BOSCO MARENGO',
      },
      {
        value: 'B080',
        label: 'BOSIO',
      },
      {
        value: 'B109',
        label: 'BOZZOLE',
      },
      {
        value: 'B177',
        label: 'BRIGNANO o BRIGNANO DEL CURONE',
      },
      {
        value: 'B179',
        label: 'BRIGNANO-FRASCATA',
      },
      {
        value: 'B224',
        label: 'BRUSASCHETTO',
      },
      {
        value: 'B311',
        label: 'CABELLA LIGURE',
      },
      {
        value: 'B453',
        label: 'CAMAGNA MONFERRATO',
      },
      {
        value: 'B482',
        label: 'CAMINO',
      },
      {
        value: 'B629',
        label: 'CANTALUPO o CANTALUPO LIGURE',
      },
      {
        value: 'B701',
        label: "CAPRIATA o CAPRIATA D'ORBA",
      },
      {
        value: 'B736',
        label: 'CARBONARA o CARBONARA SCRIVIA',
      },
      {
        value: 'B765',
        label: 'CARENTINO',
      },
      {
        value: 'B769',
        label: 'CAREZZANO o CAREZZANO INFERIORE o CAREZZANO MAGGIORE',
      },
      {
        value: 'B770',
        label: 'CAREZZANO SUPERIORE',
      },
      {
        value: 'B818',
        label: 'CARPENETO',
      },
      {
        value: 'B836',
        label: 'CARREGA LIGURE',
      },
      {
        value: 'B840',
        label: 'CARROSIO',
      },
      {
        value: 'B847',
        label: 'CARTOSIO',
      },
      {
        value: 'B870',
        label: 'CASAL CERMELLI',
      },
      {
        value: 'B885',
        label: 'CASALE MONFERRATO',
      },
      {
        value: 'B882',
        label: 'CASALEGGIO o CASALEGGIO BOIRO',
      },
      {
        value: 'B902',
        label: 'CASALNOCETO',
      },
      {
        value: 'B941',
        label: 'CASASCO',
      },
      {
        value: 'C005',
        label: 'CASSANO SPINOLA',
      },
      {
        value: 'M388',
        label: 'CASSANO SPINOLA',
      },
      {
        value: 'C027',
        label: 'CASSINE',
      },
      {
        value: 'C030',
        label: 'CASSINELLE',
      },
      {
        value: 'C047',
        label: 'CASTAGNOLE o CASTAGNOLE MONFERRATO',
      },
      {
        value: 'C087',
        label: "CASTEL DE' RATTI",
      },
      {
        value: 'C264',
        label: 'CASTEL SAN PIETRO o CASTEL SAN PIETRO MONFERRATO',
      },
      {
        value: 'C137',
        label: 'CASTELLANIA o CASTELLANIA COPPI',
      },
      {
        value: 'C142',
        label: 'CASTELLAR GUIDOBONO',
      },
      {
        value: 'C148',
        label: 'CASTELLAZZO o CASTELLAZZO BORMIDA',
      },
      {
        value: 'C156',
        label: "CASTELLETTO D'ERRO",
      },
      {
        value: 'C158',
        label: "CASTELLETTO D'ORBA",
      },
      {
        value: 'C160',
        label: 'CASTELLETTO MERLI',
      },
      {
        value: 'C162',
        label: 'CASTELLETTO MONFERRATO o CASTELLETTO SCAZZOSO',
      },
      {
        value: 'C229',
        label: 'CASTELNUOVO BORMIDA',
      },
      {
        value: 'C232',
        label: "CASTELNUOVO D'ASTI o CASTELNUOVO DON BOSCO",
      },
      {
        value: 'C243',
        label: 'CASTELNUOVO SCRIVIA',
      },
      {
        value: 'C274',
        label: 'CASTELSPINA',
      },
      {
        value: 'C064',
        label: 'CASTELVERO o CASTEL BOGLIONE',
      },
      {
        value: 'C281',
        label: "CASTELVERO o CASTELVERO D'ASTI",
      },
      {
        value: 'C295',
        label: "CASTIGLIONE o CASTIGLIONE D'ASTI",
      },
      {
        value: 'C387',
        label: 'CAVATORE',
      },
      {
        value: 'C432',
        label: 'CELLA o CELLA MONTE',
      },
      {
        value: 'C440',
        label: 'CELLE o CELLE ENOMONDO',
      },
      {
        value: 'C503',
        label: 'CERESETO',
      },
      {
        value: 'C507',
        label: 'CERETTO o CERETTO GRUE o CERRETO GRUE',
      },
      {
        value: 'C528',
        label: "CERRETTO o CERRETO D'ASTI",
      },
      {
        value: 'C531',
        label: 'CERRINA o CERRINA MONFERRATO',
      },
      {
        value: 'C533',
        label: 'CERRO o CERRO TANARO',
      },
      {
        value: 'C658',
        label: "CHIUSANO o CHIUSANO D'ASTI",
      },
      {
        value: 'C739',
        label: "CISTERNA o CISTERNA D'ASTI",
      },
      {
        value: 'C808',
        label: 'COCCONITO',
      },
      {
        value: 'C962',
        label: 'CONIOLO',
      },
      {
        value: 'C977',
        label: 'CONZANO',
      },
      {
        value: 'D069',
        label: 'CORTERANZO',
      },
      {
        value: 'D072',
        label: 'CORTICELLE o CORTIGLIONE',
      },
      {
        value: 'D102',
        label: 'COSTA o COSTA VESCOVATO',
      },
      {
        value: 'D149',
        label: 'CREMOLINO',
      },
      {
        value: 'D194',
        label: 'CUCCARO o CUCCARO MONFERRATO',
      },
      {
        value: 'D212',
        label: 'CUQUELLO',
      },
      {
        value: 'D272',
        label: 'DENICE',
      },
      {
        value: 'D277',
        label: 'DERNICE',
      },
      {
        value: 'D447',
        label: 'FABBRICA o FABBRICA CURONE',
      },
      {
        value: 'D528',
        label: 'FELIZZANO',
      },
      {
        value: 'D559',
        label: 'FIACCONE o FRACONALTO',
      },
      {
        value: 'D729',
        label: 'FOROTONDO',
      },
      {
        value: 'D759',
        label: 'FRANCAVILLA o FRANCAVILLA BISIO',
      },
      {
        value: 'D770',
        label: 'FRASCARO',
      },
      {
        value: 'D772',
        label: 'FRASCATA',
      },
      {
        value: 'D777',
        label: 'FRASSINELLO o FRASSINELLO MONFERRATO',
      },
      {
        value: 'D778',
        label: 'FRASSINELLO-OLIVOLA',
      },
      {
        value: 'D780',
        label: 'FRASSINETO PO',
      },
      {
        value: 'D797',
        label: 'FRESONARA',
      },
      {
        value: 'D813',
        label: 'FRUGAROLO',
      },
      {
        value: 'D814',
        label: 'FUBINE o FUBINE MONFERRATO',
      },
      {
        value: 'D835',
        label: 'GABIANO',
      },
      {
        value: 'D890',
        label: 'GAMALERO',
      },
      {
        value: 'D910',
        label: 'GARBAGNA',
      },
      {
        value: 'D941',
        label: 'GAVAZZANA',
      },
      {
        value: 'D944',
        label: 'GAVI',
      },
      {
        value: 'E015',
        label: 'GIAROLE',
      },
      {
        value: 'E159',
        label: 'GRAZZANO o GRAZZANO BADOGLIO o GRAZZANO MONFERRATO',
      },
      {
        value: 'E164',
        label: 'GREMIASCO',
      },
      {
        value: 'E188',
        label: 'GROGNARDO',
      },
      {
        value: 'E191',
        label: 'GRONDONA',
      },
      {
        value: 'E198',
        label: 'GROPPO',
      },
      {
        value: 'E255',
        label: 'GUAZZORA',
      },
      {
        value: 'E295',
        label: 'INCISA o INCISA BELBO o INCISA SCAPACCINO',
      },
      {
        value: 'E338',
        label: "ISOLA o ISOLA D'ASTI",
      },
      {
        value: 'E360',
        label: "ISOLA SANT'ANTONIO",
      },
      {
        value: 'E503',
        label: 'LAZZARONE o VILLABELLA',
      },
      {
        value: 'E543',
        label: 'LERMA',
      },
      {
        value: 'E712',
        label: 'LU',
      },
      {
        value: 'M420',
        label: 'LU E CUCCARO MONFERRATO',
      },
      {
        value: 'E870',
        label: 'MALVICINO',
      },
      {
        value: 'E871',
        label: 'MALVINO',
      },
      {
        value: 'F015',
        label: 'MASIO',
      },
      {
        value: 'F096',
        label: 'MELAZZO',
      },
      {
        value: 'F131',
        label: 'MERANA',
      },
      {
        value: 'F232',
        label: 'MIRABELLO o MIRABELLO MONFERRATO',
      },
      {
        value: 'F281',
        label: 'MOLARE',
      },
      {
        value: 'F292',
        label: 'MOLINO ALZANO',
      },
      {
        value: 'F293',
        label: 'MOLINO DEI TORTI',
      },
      {
        value: 'F300',
        label: 'MOLO o MOLO DI BORBERA',
      },
      {
        value: 'F313',
        label: 'MOMBELLO o MOMBELLO MONFERRATO',
      },
      {
        value: 'F320',
        label: 'MOMPERONE',
      },
      {
        value: 'F325',
        label: 'MONASTERO o MONASTERO BORMIDA',
      },
      {
        value: 'F337',
        label: 'MONCESTINO',
      },
      {
        value: 'F343',
        label: 'MONCUCCO o MONCUCCO TORINESE',
      },
      {
        value: 'F350',
        label: 'MONDONIO',
      },
      {
        value: 'F365',
        label: 'MONGIARDINO o MONGIARDINO LIGURE',
      },
      {
        value: 'F374',
        label: 'MONLEALE',
      },
      {
        value: 'F387',
        label: 'MONTACUTO',
      },
      {
        value: 'F403',
        label: 'MONTALDEO',
      },
      {
        value: 'F404',
        label: 'MONTALDO o MONTALDO BORMIDA',
      },
      {
        value: 'F412',
        label: 'MONTALERO',
      },
      {
        value: 'F455',
        label: 'MONTECASTELLO',
      },
      {
        value: 'F468',
        label: "MONTECHIARO o MONTECHIARO D'ASTI",
      },
      {
        value: 'F469',
        label: "MONTECHIARO o MONTECHIARO D'ACQUI",
      },
      {
        value: 'F472',
        label: 'MONTECHIARO DENICE',
      },
      {
        value: 'F518',
        label: 'MONTEGIOCO',
      },
      {
        value: 'F527',
        label: "MONTEGROSSO o MONTEGROSSO D'ASTI",
      },
      {
        value: 'F562',
        label: 'MONTEMARZINO',
      },
      {
        value: 'F707',
        label: 'MORANO o MORANO SUL PO',
      },
      {
        value: 'F713',
        label: 'MORBELLO',
      },
      {
        value: 'F737',
        label: 'MORNESE',
      },
      {
        value: 'F751',
        label: 'MORSASCO',
      },
      {
        value: 'F814',
        label: 'MURISENGO',
      },
      {
        value: 'F965',
        label: 'NOVI o NOVI LIGURE o NOVI PIEMONTE',
      },
      {
        value: 'F995',
        label: 'OCCIMIANO',
      },
      {
        value: 'F997',
        label: 'ODALENGO GRANDE',
      },
      {
        value: 'F998',
        label: 'ODALENGO PICCOLO',
      },
      {
        value: 'G042',
        label: 'OLIVOLA',
      },
      {
        value: 'G048',
        label: 'OLMO o OLMO GENTILE',
      },
      {
        value: 'G124',
        label: 'ORSARA o ORSARA BORMIDA',
      },
      {
        value: 'G193',
        label: 'OTTIGLIO',
      },
      {
        value: 'G197',
        label: 'OVADA',
      },
      {
        value: 'G199',
        label: 'OVIGLIO',
      },
      {
        value: 'G204',
        label: 'OZZANO o OZZANO DI ROBIANO o OZZANO MONFERRATO',
      },
      {
        value: 'G215',
        label: 'PADERNA',
      },
      {
        value: 'G334',
        label: 'PARETO',
      },
      {
        value: 'G338',
        label: 'PARODI o PARODI LIGURE',
      },
      {
        value: 'G357',
        label: 'PASSERANO',
      },
      {
        value: 'G367',
        label: 'PASTURANA',
      },
      {
        value: 'G390',
        label: "PAVONE o PAVONE D'ALESSANDRIA",
      },
      {
        value: 'G397',
        label: 'PECETTO o PECETTO DI VALENZA',
      },
      {
        value: 'G619',
        label: 'PIETRA MARAZZI',
      },
      {
        value: 'G676',
        label: "PINO o PINO D'ASTI",
      },
      {
        value: 'G695',
        label: 'PIOVERA',
      },
      {
        value: 'G807',
        label: 'POMARO o POMARO MONFERRATO',
      },
      {
        value: 'G839',
        label: 'PONTECURONE',
      },
      {
        value: 'G858',
        label: 'PONTESTURA',
      },
      {
        value: 'G861',
        label: 'PONTI',
      },
      {
        value: 'G872',
        label: 'PONZANO o PONZANO MONFERRATO',
      },
      {
        value: 'G877',
        label: 'PONZONE',
      },
      {
        value: 'G960',
        label: 'POZZOL GROPPO',
      },
      {
        value: 'G961',
        label: 'POZZOLO FORMIGARO',
      },
      {
        value: 'G987',
        label: 'PRASCO',
      },
      {
        value: 'H021',
        label: 'PREDOSA',
      },
      {
        value: 'H065',
        label: 'PRIMEGLIO SCHIERANO',
      },
      {
        value: 'H104',
        label: 'QUARGNENTO',
      },
      {
        value: 'H111',
        label: 'QUARTI',
      },
      {
        value: 'H113',
        label: 'QUARTO o QUARTO ASTESE',
      },
      {
        value: 'H121',
        label: 'QUATTORDIO',
      },
      {
        value: 'H250',
        label: "REVIGLIASCO o REVIGLIASCO D'ASTI",
      },
      {
        value: 'H272',
        label: 'RICALDONE',
      },
      {
        value: 'H296',
        label: 'RINCO',
      },
      {
        value: 'H334',
        label: 'RIVALTA o RIVALTA BORMIDA',
      },
      {
        value: 'H343',
        label: 'RIVARONE',
      },
      {
        value: 'H414',
        label: 'ROCCA GRIMALDA',
      },
      {
        value: 'H406',
        label: 'ROCCAFORTE o ROCCAFORTE LIGURE',
      },
      {
        value: 'H465',
        label: 'ROCCHETTA LIGURE',
      },
      {
        value: 'H569',
        label: 'ROSIGNANO o ROSIGNANO MONFERRATO',
      },
      {
        value: 'H571',
        label: 'ROSINGO',
      },
      {
        value: 'H677',
        label: 'SALA o SALA MONFERRATO',
      },
      {
        value: 'H685',
        label: 'SALABUE',
      },
      {
        value: 'H694',
        label: 'SALE',
      },
      {
        value: 'H810',
        label: 'SAN CRISTOFORO',
      },
      {
        value: 'H811',
        label: "SAN DAMIANO o SAN DAMIANO D'ASTI",
      },
      {
        value: 'H878',
        label: 'SAN GIORGIO o SAN GIORGIO MONFERRATO',
      },
      {
        value: 'H987',
        label: 'SAN MARTINO o SAN MARTINO AL TANARO o SAN MARTINO ALFIERI',
      },
      {
        value: 'I017',
        label: 'SAN MARZANO o SAN MARZANO OLIVETO',
      },
      {
        value: 'I020',
        label: 'SAN MARZANO MOASCA',
      },
      {
        value: 'I021',
        label: 'SAN MARZANOTTO',
      },
      {
        value: 'I033',
        label: "SAN MICHELE o SAN MICHELE D'ASTI",
      },
      {
        value: 'I070',
        label: 'SAN PAOLO o SAN PAOLO DELLA VALLE',
      },
      {
        value: 'I144',
        label: 'SAN SALVATORE o SAN SALVATORE MONFERRATO',
      },
      {
        value: 'I150',
        label: 'SAN SEBASTIANO o SAN SEBASTIANO CURONE',
      },
      {
        value: 'I190',
        label: "SANT'AGATA o SANT'AGATA FOSSILI",
      },
      {
        value: 'I429',
        label: 'SARDIGLIANO',
      },
      {
        value: 'I432',
        label: 'SAREZZANO',
      },
      {
        value: 'I645',
        label: 'SERRALUNGA o SERRALUNGA DI CREA',
      },
      {
        value: 'I657',
        label: 'SERRAVALLE o SERRAVALLE LIBARNA o SERRAVALLE SCRIVIA',
      },
      {
        value: 'I658',
        label: "SERRAVALLE o SERRAVALLE D'ASTI",
      },
      {
        value: 'I680',
        label: 'SESSANT',
      },
      {
        value: 'I711',
        label: "SEZZADIO o SEZZE'",
      },
      {
        value: 'I738',
        label: "SILVANO D'ORBA",
      },
      {
        value: 'I795',
        label: 'SOLBRITO',
      },
      {
        value: 'I798',
        label: 'SOLERO',
      },
      {
        value: 'I808',
        label: 'SOLONGHELLO',
      },
      {
        value: 'I859',
        label: 'SORLI',
      },
      {
        value: 'I901',
        label: 'SPIGNO o SPIGNO MONFERRATO',
      },
      {
        value: 'I911',
        label: 'SPINETO o SPINETO SCRIVIA',
      },
      {
        value: 'I941',
        label: 'STAZZANO',
      },
      {
        value: 'I977',
        label: 'STREVI',
      },
      {
        value: 'L027',
        label: 'TAGLIOLO o TAGLIOLO MONFERRATO',
      },
      {
        value: 'L028',
        label: 'TAGLIOLO-BELFORTE',
      },
      {
        value: 'L059',
        label: 'TASSAROLO',
      },
      {
        value: 'L139',
        label: 'TERRUGGIA',
      },
      {
        value: 'L143',
        label: 'TERZO',
      },
      {
        value: 'L165',
        label: 'TICINETO',
      },
      {
        value: 'L264',
        label: "TORRE DE' RATTI",
      },
      {
        value: 'L304',
        label: 'TORTONA',
      },
      {
        value: 'L403',
        label: 'TREVILLE',
      },
      {
        value: 'L432',
        label: 'TRISOBBIO',
      },
      {
        value: 'L530',
        label: 'VAGLIERANO',
      },
      {
        value: 'L531',
        label: 'VAGLIO o VAGLIO SERRA',
      },
      {
        value: 'L570',
        label: 'VALENZA',
      },
      {
        value: 'L633',
        label: 'VALMACCA',
      },
      {
        value: 'L679',
        label: 'VARENGO',
      },
      {
        value: 'L683',
        label: 'VARGO',
      },
      {
        value: 'L879',
        label: "VIGLIANO o VIGLIANO D'ASTI",
      },
      {
        value: 'L881',
        label: 'VIGNALE MONFERRATO',
      },
      {
        value: 'L887',
        label: 'VIGNOLE BORBERA',
      },
      {
        value: 'L904',
        label: 'VIGUZZOLO',
      },
      {
        value: 'L931',
        label: 'VILLADEATI',
      },
      {
        value: 'L945',
        label: "VILLAFRANCA o VILLAFRANCA D'ASTI",
      },
      {
        value: 'L963',
        label: 'VILLALVERNIA',
      },
      {
        value: 'L970',
        label: 'VILLAMIROGLIO',
      },
      {
        value: 'L972',
        label:
          'VILLANOVA o VILLANOVA DI CASALE MONFERRATO o VILLANOVA MONFERRATO',
      },
      {
        value: 'L984',
        label: "VILLANUOVA o VILLANOVA D'ASTI o VILLANUOVA D'ASTI",
      },
      {
        value: 'M009',
        label: 'VILLAROMAGNANO',
      },
      {
        value: 'M077',
        label: 'VISONE',
      },
      {
        value: 'M120',
        label: 'VOLPEDO',
      },
      {
        value: 'M121',
        label: 'VOLPEGLINO',
      },
      {
        value: 'M123',
        label: 'VOLTAGGIO',
      },
    ],
  },

  {
    value: 'AN',
    label: 'Ancona',
    children: [
      {
        value: 'A092',
        label: 'AGUGLIANO',
      },
      {
        value: 'A271',
        label: 'ANCONA',
      },
      {
        value: 'A366',
        label: 'ARCEVIA',
      },
      {
        value: 'A626',
        label: 'BARBARA',
      },
      {
        value: 'A769',
        label: 'BELVEDERE o BELVEDERE OSTRENSE',
      },
      {
        value: 'B468',
        label: 'CAMERANO',
      },
      {
        value: 'B470',
        label: 'CAMERATA o CAMERATA PICENA',
      },
      {
        value: 'C071',
        label: 'CASTEL COLONNA o TOMBA DI SENIGALLIA',
      },
      {
        value: 'C060',
        label: 'CASTELBELLINO',
      },
      {
        value: 'C100',
        label: 'CASTELFIDARDO',
      },
      {
        value: 'C152',
        label: 'CASTELLEONE o CASTELLEONE DI SUASA',
      },
      {
        value: 'C248',
        label: 'CASTELPLANIO',
      },
      {
        value: 'C524',
        label: "CERRETO o CERRETO D'ESI",
      },
      {
        value: 'C615',
        label: 'CHIARAVALLE',
      },
      {
        value: 'D007',
        label: 'CORINALDO',
      },
      {
        value: 'D211',
        label: 'CUPRAMONTANA o MASSACCIO',
      },
      {
        value: 'D451',
        label: 'FABRIANO',
      },
      {
        value: 'D472',
        label: 'FALCONARA o FALCONARA MARITTIMA',
      },
      {
        value: 'D597',
        label: 'FILOTTRANO',
      },
      {
        value: 'D965',
        label: 'GENGA',
      },
      {
        value: 'E388',
        label: 'JESI',
      },
      {
        value: 'E690',
        label: 'LORETO',
      },
      {
        value: 'E837',
        label: 'MAIOLATI o MAIOLATI SPONTINI',
      },
      {
        value: 'F145',
        label: 'MERGO',
      },
      {
        value: 'F381',
        label: 'MONSANO o MOSCIANO',
      },
      {
        value: 'F401',
        label: 'MONTALBODDO o OSTRA',
      },
      {
        value: 'F600',
        label: 'MONTE ROBERTO',
      },
      {
        value: 'F634',
        label: 'MONTE SAN VITO',
      },
      {
        value: 'F453',
        label: 'MONTECAROTTO',
      },
      {
        value: 'F560',
        label: 'MONTEMARCIANO',
      },
      {
        value: 'F581',
        label: 'MONTENOVO o OSTRA VETERE',
      },
      {
        value: 'F593',
        label: 'MONTERADO',
      },
      {
        value: 'F645',
        label: 'MONTESICURO',
      },
      {
        value: 'F745',
        label: "MORRO o MORRO D'ALBA",
      },
      {
        value: 'F978',
        label: 'NUMANA o UMANA',
      },
      {
        value: 'G003',
        label: 'OFFAGNA',
      },
      {
        value: 'G157',
        label: 'OSIMO',
      },
      {
        value: 'G369',
        label: "PATERNO o PATERNO D'ANCONA",
      },
      {
        value: 'G771',
        label: 'POGGIO SAN MARCELLO',
      },
      {
        value: 'G803',
        label: 'POLVERIGI',
      },
      {
        value: 'H322',
        label: 'RIPE',
      },
      {
        value: 'H575',
        label: 'ROSORA',
      },
      {
        value: 'H576',
        label: 'ROSORA MERGO',
      },
      {
        value: 'H979',
        label: 'SAN MARCELLO',
      },
      {
        value: 'I071',
        label: 'SAN PAOLO o SAN PAOLO DI JESI',
      },
      {
        value: 'I251',
        label: 'SANTA MARIA NUOVA',
      },
      {
        value: 'I461',
        label: 'SASSOFERRATO',
      },
      {
        value: 'I608',
        label: 'SENIGALLIA',
      },
      {
        value: 'I643',
        label: "SERRA DE' CONTI",
      },
      {
        value: 'I653',
        label: 'SERRA SAN QUIRICO',
      },
      {
        value: 'I758',
        label: 'SIROLO',
      },
      {
        value: 'I932',
        label: 'STAFFOLO',
      },
      {
        value: 'M318',
        label: 'TRECASTELLI',
      },
    ],
  },

  {
    value: 'AO',
    label: 'Aosta',
    children: [
      {
        value: 'A094',
        label: 'AIAS o AYAS',
      },
      {
        value: 'A108',
        label: 'AIMAVILLA o AYMAVILLES',
      },
      {
        value: 'A205',
        label: 'ALLAIN o ALLEIN o ALLENO',
      },
      {
        value: 'A305',
        label: "ANTEI SANT'ANDREA o ANTEY-SAINT-ANDRE'",
      },
      {
        value: 'A326',
        label: 'AOSTA',
      },
      {
        value: 'A424',
        label: 'ARNAD o ARNAZ',
      },
      {
        value: 'A452',
        label: 'ARVIE o ARVIER',
      },
      {
        value: 'A521',
        label: 'AVISE',
      },
      {
        value: 'A585',
        label: 'BAIRO TORRE',
      },
      {
        value: 'A643',
        label: 'BARD',
      },
      {
        value: 'A877',
        label: 'BIONA o BIONAZ',
      },
      {
        value: 'B192',
        label: 'BRISSOGNE',
      },
      {
        value: 'B230',
        label: 'BRUSSON o BRUSSONE',
      },
      {
        value: 'B491',
        label: 'CAMOSIO o CHAMOIS',
      },
      {
        value: 'B510',
        label: 'CAMPIGLIA SOANA o CAMPIGLIA o VALPRATO SOANA',
      },
      {
        value: 'B540',
        label: 'CAMPO LARIS o CHAMPORCHER',
      },
      {
        value: 'C282',
        label: "CASTEL VERRES o VERRE'S o VERRES",
      },
      {
        value: 'C294',
        label: 'CASTIGLION DORA o CHATILLON o CH�TILLON',
      },
      {
        value: 'C593',
        label: 'CHALLAND-SAINT-ANSELME o CHALLANT SAINT ANSELME',
      },
      {
        value: 'C594',
        label: 'CHALLAND-SAINT-VICTOR o CHALLANT SAINT VICTOR',
      },
      {
        value: 'C592',
        label: "CHALLANT o VILLA SANT'ANSELMO",
      },
      {
        value: 'C595',
        label: 'CHAMBAVE o CIAMBAVE',
      },
      {
        value: 'C596',
        label: 'CHAMPDEPRAZ',
      },
      {
        value: 'C598',
        label: 'CHARVENSOD',
      },
      {
        value: 'C821',
        label: 'COGNE',
      },
      {
        value: 'D012',
        label: 'CORMAIORE o COURMAYEUR',
      },
      {
        value: 'D338',
        label: 'DONAS o DONNAS o DONNAZ',
      },
      {
        value: 'D356',
        label: "DOUES o DOVIA D'AOSTA",
      },
      {
        value: 'D375',
        label: 'DRUSACCO',
      },
      {
        value: 'D402',
        label: "EMARE'SE o EMARESE",
      },
      {
        value: 'D444',
        label: 'ETROBLE o ETROUBLES',
      },
      {
        value: 'D537',
        label: "FE'NIS o FENIS",
      },
      {
        value: 'D666',
        label: 'FONTAINEMORE',
      },
      {
        value: 'D839',
        label: 'GABY',
      },
      {
        value: 'D939',
        label: 'GAUNA',
      },
      {
        value: 'E029',
        label: 'GIGNOD',
      },
      {
        value: 'E165',
        label: 'GRESSAN',
      },
      {
        value: 'E166',
        label: 'GRESSONEI o GRESSONEY',
      },
      {
        value: 'E167',
        label: "GRESSONEY-LA-TRINITE'",
      },
      {
        value: 'E168',
        label: 'GRESSONEY-SAINT-JEAN',
      },
      {
        value: 'E273',
        label: 'HONE o H�NE',
      },
      {
        value: 'E306',
        label: 'INTROD',
      },
      {
        value: 'E369',
        label: 'ISSIME',
      },
      {
        value: 'E371',
        label: 'ISSOGNE',
      },
      {
        value: 'E391',
        label: 'JOVENCAN o JOVEN�AN',
      },
      {
        value: 'A308',
        label: "LA MADDALENA D'AOSTA o LA MAGDELEINE o ANTEY LA MAGDELEINE",
      },
      {
        value: 'E458',
        label: 'LA SALLE o SALA DORA',
      },
      {
        value: 'E470',
        label: 'LA THUILE o PORTA LITTORIA',
      },
      {
        value: 'E587',
        label: 'LILLIANA o LILLIANES',
      },
      {
        value: 'F367',
        label: 'MONGIOVE o MONTJOVET',
      },
      {
        value: 'F726',
        label: 'MORGEX',
      },
      {
        value: 'F812',
        label: 'MURIAGLIO',
      },
      {
        value: 'F953',
        label: 'NOVAREGLIA',
      },
      {
        value: 'F987',
        label: 'NUS',
      },
      {
        value: 'G012',
        label: 'OIASSE o OYACE',
      },
      {
        value: 'G045',
        label: 'OLLOMONT o OLLOMONTE',
      },
      {
        value: 'G401',
        label: 'PEDANEA',
      },
      {
        value: 'G459',
        label: 'PERLOZ',
      },
      {
        value: 'G545',
        label: 'PIANBOSETO o PONT BOZET o PONTBOSET',
      },
      {
        value: 'G794',
        label: 'POLLEIN',
      },
      {
        value: 'G854',
        label: 'PONT-SAINT-MARTIN o PONTE SAN MARTINO',
      },
      {
        value: 'G860',
        label: 'PONTEY',
      },
      {
        value: 'H042',
        label: "PRE'-SAINT-DIDIER o SAN DESIDERIO TERME",
      },
      {
        value: 'H110',
        label: 'QUART o QUARTO PRAETORIA',
      },
      {
        value: 'H261',
        label: 'RHEMES o VAL DI REMA',
      },
      {
        value: 'H262',
        label: 'RHEMES-NOTRE-DAME o RH�MES-NOTRE-DAME',
      },
      {
        value: 'H263',
        label: 'RHEMES-SAINT-GEORGES o RH�MES-SAINT-GEORGES',
      },
      {
        value: 'H497',
        label: 'ROISAN',
      },
      {
        value: 'H669',
        label: 'SAINT-CHRISTOPHE',
      },
      {
        value: 'H670',
        label: 'SAINT-DENIS',
      },
      {
        value: 'H671',
        label: 'SAINT-MARCEL',
      },
      {
        value: 'H672',
        label: 'SAINT-NICOLAS',
      },
      {
        value: 'H673',
        label: "SAINT-OYEN o SANT'EUGENDO",
      },
      {
        value: 'H674',
        label: 'SAINT-PIERRE',
      },
      {
        value: 'H675',
        label: "SAINT-RHE'MY-EN-BOSSES o SAINT-RHEMY-EN-BOSSES o SAN REMIGIO",
      },
      {
        value: 'H676',
        label: 'SAINT-VINCENT o SAN VINCENZO DELLA FONTE',
      },
      {
        value: 'H697',
        label: 'SALE CASTELNUOVO',
      },
      {
        value: 'I126',
        label: 'SAN PONSO CANAVESE o SAN PONSO',
      },
      {
        value: 'I442',
        label: 'SARRE',
      },
      {
        value: 'I971',
        label: 'STRAMBINO ROMANO',
      },
      {
        value: 'L217',
        label: 'TORGNON o TORGNONE',
      },
      {
        value: 'L549',
        label: 'VALCHIUSELLA',
      },
      {
        value: 'L560',
        label: "VALDIGNA D'AOSTA",
      },
      {
        value: 'L582',
        label: 'VALGRISANCHE o VALGRISENCHE o VALGRISENZA',
      },
      {
        value: 'L643',
        label: 'VALPELLINA o VALPELLINE',
      },
      {
        value: 'L645',
        label: 'VALPRATO',
      },
      {
        value: 'L647',
        label: 'VALSAVARA o VALSAVARANCHE o VALSAVARENCHE',
      },
      {
        value: 'L654',
        label: 'VALTORNENZA o VALTOURNANCHE o VALTOURNENCHE',
      },
      {
        value: 'L783',
        label: 'VERRAYES',
      },
      {
        value: 'L921',
        label: 'VILLA CASTELNUOVO',
      },
      {
        value: 'L981',
        label: 'VILLANOVA BALTEA o VILLENEUVE',
      },
    ],
  },

  {
    value: 'AP',
    label: 'Ascoli Piceno',
    children: [
      {
        value: 'A044',
        label: 'ACQUASANTA o ACQUASANTA TERME',
      },
      {
        value: 'A047',
        label: 'ACQUAVIVA o ACQUAVIVA PICENA',
      },
      {
        value: 'A232',
        label: 'ALTETA',
      },
      {
        value: 'A233',
        label: 'ALTIDONA o ALTIDONA',
      },
      {
        value: 'A252',
        label: 'AMANDOLA o AMANDOLA',
      },
      {
        value: 'A335',
        label: 'APPIGNANO DEL TRONTO o APPIGNANO DI OFFIDA',
      },
      {
        value: 'A437',
        label: 'ARQUATA o ARQUATA DEL TRONTO',
      },
      {
        value: 'A462',
        label: 'ASCOLI o ASCOLI PICENO',
      },
      {
        value: 'A760',
        label: 'BELMONTE o BELMONTE PICENO o BELMONTE PICENO',
      },
      {
        value: 'B534',
        label: 'CAMPOFILONE o CAMPOFILONE',
      },
      {
        value: 'B683',
        label: 'CAPRADOSSO',
      },
      {
        value: 'B727',
        label: 'CARASSAI',
      },
      {
        value: 'C070',
        label: 'CASTEL CLEMENTINO o SERVIGLIANO o SERVIGLIANO',
      },
      {
        value: 'C088',
        label: 'CASTEL DI CROCE',
      },
      {
        value: 'C093',
        label: 'CASTEL DI LAMA o LAMA',
      },
      {
        value: 'C321',
        label: 'CASTIGNANO',
      },
      {
        value: 'C331',
        label: 'CASTORANO',
      },
      {
        value: 'C877',
        label: 'COLLI o COLLI DEL TRONTO',
      },
      {
        value: 'C935',
        label: 'COMUNANZA o COMUNANZA DEL LITTORIO',
      },
      {
        value: 'D096',
        label: 'COSSIGNANO',
      },
      {
        value: 'D210',
        label: 'CUPRA MARITTIMA o MARANO',
      },
      {
        value: 'D477',
        label: 'FALERONE o FALERONE',
      },
      {
        value: 'D542',
        label: 'FERMO o FERMO',
      },
      {
        value: 'D652',
        label: 'FOLIGNANO',
      },
      {
        value: 'D691',
        label: 'FORCE',
      },
      {
        value: 'D760',
        label: "FRANCAVILLA o FRANCAVILLA D'ETE o FRANCAVILLA D'ETE",
      },
      {
        value: 'E207',
        label: 'GROTTAMMARE',
      },
      {
        value: 'E208',
        label: 'GROTTAZZOLINA o GROTTAZZOLINA',
      },
      {
        value: 'E447',
        label: 'LAPEDONA o LAPEDONA',
      },
      {
        value: 'E612',
        label: 'LISCIANO o LISCIANO DEL TRONTO',
      },
      {
        value: 'E807',
        label: 'MAGLIANO o MAGLIANO DI TENNA o MAGLIANO DI TENNA',
      },
      {
        value: 'E868',
        label: 'MALTIGNANO',
      },
      {
        value: 'F021',
        label: 'MASSA o MASSA FERMANA o MASSA FERMANA',
      },
      {
        value: 'F044',
        label: 'MASSIGNANO',
      },
      {
        value: 'F379',
        label: 'MONSAMPIETRO MORICO o MONSAMPIETRO MORICO',
      },
      {
        value: 'F380',
        label: 'MONSAMPOLO o MONSAMPOLO DEL TRONTO',
      },
      {
        value: 'F388',
        label: 'MONTACUTO',
      },
      {
        value: 'F389',
        label: 'MONTADAMO',
      },
      {
        value: 'F415',
        label: 'MONTALTO o MONTALTO DELLE MARCHE',
      },
      {
        value: 'F428',
        label: 'MONTAPPONE o MONTAPPONE',
      },
      {
        value: 'F517',
        label: 'MONTE GIBERTO o MONTE GIBERTO',
      },
      {
        value: 'F599',
        label: 'MONTE RINALDO o MONTE RINALDO',
      },
      {
        value: 'F626',
        label: 'MONTE SAN PIETRANGELI o MONTE SAN PIETRANGELI',
      },
      {
        value: 'F653',
        label: 'MONTE URANO o MONTE URANO',
      },
      {
        value: 'F664',
        label: 'MONTE VIDON COMBATTE o MONTE VIDON COMBATTE',
      },
      {
        value: 'F665',
        label: 'MONTE VIDON CORRADO o MONTE VIDON CORRADO',
      },
      {
        value: 'F447',
        label: 'MONTECALVO o MONTECALVO DEL CASTELLANO',
      },
      {
        value: 'F487',
        label: 'MONTEDINOVE',
      },
      {
        value: 'F493',
        label: 'MONTEFALCONE o MONTEFALCONE APPENNINO o MONTEFALCONE APPENNINO',
      },
      {
        value: 'F501',
        label: "MONTEFIORE o MONTEFIORE DELL'ASO",
      },
      {
        value: 'F509',
        label: 'MONTEFORTINO o MONTEFORTINO',
      },
      {
        value: 'F516',
        label: 'MONTEGALLO',
      },
      {
        value: 'F520',
        label: 'MONTEGIORGIO o MONTEGIORGIO',
      },
      {
        value: 'F522',
        label: 'MONTEGRANARO o MONTEGRANARO',
      },
      {
        value: 'F536',
        label: 'MONTELEONE o MONTELEONE DI FERMO o MONTELEONE DI FERMO',
      },
      {
        value: 'F549',
        label: 'MONTELPARO o MONTELPARO',
      },
      {
        value: 'F570',
        label: 'MONTEMONACO',
      },
      {
        value: 'F591',
        label: 'MONTEPRANDONE',
      },
      {
        value: 'F614',
        label: 'MONTERUBBIANO o MONTERUBBIANO',
      },
      {
        value: 'F697',
        label: 'MONTOTTONE o MONTOTTONE',
      },
      {
        value: 'F719',
        label: 'MOREGNANO',
      },
      {
        value: 'F722',
        label: 'MORESCO o MORESCO',
      },
      {
        value: 'F787',
        label: 'MOZZANO',
      },
      {
        value: 'G005',
        label: 'OFFIDA',
      },
      {
        value: 'G137',
        label: 'ORTEZZANO o ORTEZZANO',
      },
      {
        value: 'G162',
        label: 'OSOLI',
      },
      {
        value: 'G235',
        label: 'PAGLIARE',
      },
      {
        value: 'G289',
        label: 'PALMIANO',
      },
      {
        value: 'G375',
        label: 'PATRIGNONE',
      },
      {
        value: 'G403',
        label: 'PEDASO o PEDASO',
      },
      {
        value: 'G516',
        label: 'PETRITOLI o PETRITOLI',
      },
      {
        value: 'G873',
        label: 'PONZANO o PONZANO DI FERMO o PONZANO DI FERMO',
      },
      {
        value: 'G883',
        label: 'PORCHIA',
      },
      {
        value: 'G885',
        label: "PORCHIANO ASCOLI o PORCHIANO DELL'ASCENSIONE",
      },
      {
        value: 'G920',
        label: 'PORTO SAN GIORGIO o PORTO SAN GIORGIO',
      },
      {
        value: 'G921',
        label: "PORTO SANT'ELPIDIO o PORTO SANT'ELPIDIO",
      },
      {
        value: 'H136',
        label: 'QUINTODECIMO',
      },
      {
        value: 'H182',
        label: 'RAPAGNANO o RAPAGNANO',
      },
      {
        value: 'H310',
        label: 'RIPABERARDA',
      },
      {
        value: 'H321',
        label: 'RIPATRANSONE',
      },
      {
        value: 'H390',
        label: 'ROCCACASAREGNANA o ROCCAFLUVIONE',
      },
      {
        value: 'H435',
        label: 'ROCCAREONILE',
      },
      {
        value: 'H588',
        label: 'ROTELLA',
      },
      {
        value: 'H769',
        label: 'SAN BENEDETTO o SAN BENEDETTO DEL TRONTO',
      },
      {
        value: 'I324',
        label: "SANT'ELPIDIO A MARE o SANT'ELPIDIO A MARE",
      },
      {
        value: 'I325',
        label: "SANT'ELPIDIO MORICO",
      },
      {
        value: 'I231',
        label: 'SANTA MARIA o SANTA MARIA DEL TRONTO',
      },
      {
        value: 'I315',
        label:
          'SANTA VITTORIA o SANTA VITTORIA IN MATENANO o SANTA VITTORIA IN MATENANO',
      },
      {
        value: 'I774',
        label: 'SMERILLO o SMERILLO',
      },
      {
        value: 'I912',
        label: 'SPINETOLI',
      },
      {
        value: 'L268',
        label: 'TORRE DI PALME',
      },
      {
        value: 'L279',
        label: 'TORRE SAN PATRIZIO o TORRE SAN PATRIZIO',
      },
      {
        value: 'L728',
        label: 'VENAROTTA',
      },
    ],
  },

  {
    value: 'AQ',
    label: "L'Aquila",
    children: [
      {
        value: 'A018',
        label: 'ACCIANO',
      },
      {
        value: 'A100',
        label: 'AIELLI',
      },
      {
        value: 'A187',
        label: 'ALFEDENA',
      },
      {
        value: 'A318',
        label: 'ANVERSA o ANVERSA DEGLI ABRUZZI',
      },
      {
        value: 'A345',
        label: "AQUILA o AQUILA DEGLI ABRUZZI o L'AQUILA",
      },
      {
        value: 'A406',
        label: 'ARISCHIA',
      },
      {
        value: 'A481',
        label: 'ATELETA',
      },
      {
        value: 'A515',
        label: 'AVEZZANO',
      },
      {
        value: 'A563',
        label: 'BAGNO',
      },
      {
        value: 'A603',
        label: 'BALSORANO',
      },
      {
        value: 'A656',
        label: 'BARETE',
      },
      {
        value: 'A667',
        label: 'BARISCIANO',
      },
      {
        value: 'A678',
        label: 'BARREA',
      },
      {
        value: 'A884',
        label: 'BISEGNA',
      },
      {
        value: 'B256',
        label: 'BUGNARA',
      },
      {
        value: 'B294',
        label: 'BUSSI o BUSSI SUL TIRINO',
      },
      {
        value: 'B358',
        label: 'CAGNANO o CAGNANO AMITERNO',
      },
      {
        value: 'B381',
        label: 'CALASCIO o CALASCIBETTA',
      },
      {
        value: 'B382',
        label: 'CALASCIO o CALASETTA',
      },
      {
        value: 'B458',
        label: 'CAMARDA',
      },
      {
        value: 'B526',
        label: 'CAMPO DI GIOVE',
      },
      {
        value: 'B569',
        label: 'CAMPOTOSTO',
      },
      {
        value: 'B606',
        label: 'CANISTRO',
      },
      {
        value: 'B624',
        label: 'CANSANO',
      },
      {
        value: 'B651',
        label: 'CAPESTRANO',
      },
      {
        value: 'B656',
        label: 'CAPISTRELLO',
      },
      {
        value: 'B658',
        label: 'CAPITIGNANO',
      },
      {
        value: 'B672',
        label: 'CAPORCIANO',
      },
      {
        value: 'B677',
        label: 'CAPPADOCIA',
      },
      {
        value: 'B725',
        label: 'CARAPELLE o CARAPELLE CALVISIO',
      },
      {
        value: 'B842',
        label: 'CARSOLI',
      },
      {
        value: 'C083',
        label: 'CASTEL DEL MONTE',
      },
      {
        value: 'C090',
        label: 'CASTEL DI IERI',
      },
      {
        value: 'C096',
        label: 'CASTEL DI SANGRO',
      },
      {
        value: 'C126',
        label: 'CASTELLAFIUME',
      },
      {
        value: 'C278',
        label: 'CASTELVECCHIO CALVISIO',
      },
      {
        value: 'C279',
        label: 'CASTELVECCHIO SUBEQUO',
      },
      {
        value: 'C426',
        label: 'CELANO',
      },
      {
        value: 'C492',
        label: 'CERCHIO',
      },
      {
        value: 'C766',
        label: "CIVITA D'ANTINO",
      },
      {
        value: 'C778',
        label: 'CIVITELLA ALFEDENA',
      },
      {
        value: 'C783',
        label: 'CIVITELLA ROVETO',
      },
      {
        value: 'C811',
        label: 'COCULLO',
      },
      {
        value: 'C844',
        label: 'COLLARMELE',
      },
      {
        value: 'C862',
        label: 'COLLELONGO',
      },
      {
        value: 'C866',
        label: 'COLLEPIETRO',
      },
      {
        value: 'C999',
        label: 'CORFINIO o PENTIMA',
      },
      {
        value: 'D465',
        label: 'FAGNANO o FAGNANO ALTO',
      },
      {
        value: 'D681',
        label: 'FONTECCHIO',
      },
      {
        value: 'D736',
        label: 'FOSSA',
      },
      {
        value: 'D850',
        label: 'GAGLIANO o GAGLIANO ATERNO',
      },
      {
        value: 'E040',
        label: 'GIOIA o GIOIA DEI MARSI',
      },
      {
        value: 'E096',
        label: 'GORIANO SICOLI',
      },
      {
        value: 'E097',
        label: 'GORIANO VALLI',
      },
      {
        value: 'E307',
        label: 'INTRODACQUA',
      },
      {
        value: 'E505',
        label: 'LECCE o LECCE NEI MARSI',
      },
      {
        value: 'E723',
        label: 'LUCO DEI MARSI',
      },
      {
        value: 'E724',
        label: 'LUCOLI',
      },
      {
        value: 'E811',
        label: "MAGLIANO o MAGLIANO DE' MARSI",
      },
      {
        value: 'F022',
        label: "MASSA o MASSA D'ALBE",
      },
      {
        value: 'M255',
        label: 'MOLINA ATERNO',
      },
      {
        value: 'F595',
        label: 'MONTEREALE',
      },
      {
        value: 'F732',
        label: 'MORINO',
      },
      {
        value: 'F852',
        label: 'NAVELLI',
      },
      {
        value: 'F996',
        label: 'OCRE',
      },
      {
        value: 'G002',
        label: 'OFENA',
      },
      {
        value: 'G079',
        label: 'OPI',
      },
      {
        value: 'G102',
        label: 'ORICOLA',
      },
      {
        value: 'G142',
        label: 'ORTONA DEI MARSI',
      },
      {
        value: 'G145',
        label: 'ORTUCCHIO',
      },
      {
        value: 'G200',
        label: 'OVINDOLI',
      },
      {
        value: 'G210',
        label: 'PACENTRO',
      },
      {
        value: 'G231',
        label: 'PAGANICA',
      },
      {
        value: 'G449',
        label: 'PERETO',
      },
      {
        value: 'G484',
        label: 'PESCASSEROLI',
      },
      {
        value: 'G492',
        label: 'PESCINA',
      },
      {
        value: 'G493',
        label: 'PESCOCOSTANZO',
      },
      {
        value: 'G524',
        label: 'PETTORANO o PETTORANO SUL GIZIO',
      },
      {
        value: 'G726',
        label: 'PIZZOLI',
      },
      {
        value: 'G766',
        label: 'POGGIO PICENZE',
      },
      {
        value: 'G992',
        label: "PRATA o PRATA D'ANSIDONIA",
      },
      {
        value: 'H007',
        label: 'PRATOLA o PRATOLA PELIGNA',
      },
      {
        value: 'H053',
        label: 'PRETURO',
      },
      {
        value: 'H056',
        label: 'PREZZA',
      },
      {
        value: 'H166',
        label: 'RAIANO',
      },
      {
        value: 'H353',
        label: 'RIVISONDOLI',
      },
      {
        value: 'H399',
        label: 'ROCCA DI BOTTE',
      },
      {
        value: 'H400',
        label: 'ROCCA DI CAMBIO',
      },
      {
        value: 'H402',
        label: 'ROCCA DI MEZZO',
      },
      {
        value: 'H429',
        label: 'ROCCA PIA o ROCCAVALLEOSCURA',
      },
      {
        value: 'H389',
        label: 'ROCCACASALE',
      },
      {
        value: 'H434',
        label: 'ROCCARASO',
      },
      {
        value: 'H496',
        label: 'ROIO o ROIO PIANO',
      },
      {
        value: 'H772',
        label: 'SAN BENEDETTO DEI MARSI',
      },
      {
        value: 'H773',
        label: 'SAN BENEDETTO IN PERILLIS',
      },
      {
        value: 'H819',
        label: "SAN DEMETRIO o SAN DEMETRIO NE' VESTINI",
      },
      {
        value: 'I055',
        label: "SAN NICANDRO o SAN NICANDRO D'AQUILA",
      },
      {
        value: 'I121',
        label: 'SAN PIO DELLE CAMERE',
      },
      {
        value: 'I389',
        label: 'SAN VINCENZO o SAN VINCENZO VALLE ROVETO',
      },
      {
        value: 'I336',
        label: "SANT'EUSANIO o SANT'EUSANIO FORCONESE",
      },
      {
        value: 'I326',
        label: 'SANTE MARIE',
      },
      {
        value: 'I360',
        label: 'SANTO STEFANO o SANTO STEFANO DI SESSANIO',
      },
      {
        value: 'I450',
        label: 'SASSA',
      },
      {
        value: 'I501',
        label: 'SCANNO',
      },
      {
        value: 'I543',
        label: 'SCONTRONE',
      },
      {
        value: 'I546',
        label: 'SCOPPITO',
      },
      {
        value: 'I553',
        label: 'SCURCOLA o SCURCOLA MARSICANA',
      },
      {
        value: 'I558',
        label: 'SECINARO',
      },
      {
        value: 'I804',
        label: 'SOLMONA o SULMONA',
      },
      {
        value: 'L025',
        label: 'TAGLIACOZZO',
      },
      {
        value: 'L173',
        label: 'TIONE o TIONE DEGLI ABRUZZI',
      },
      {
        value: 'L227',
        label: 'TORNIMPARTE',
      },
      {
        value: 'L334',
        label: 'TRASACCO',
      },
      {
        value: 'M023',
        label: "VILLA SANT'ANGELO",
      },
      {
        value: 'M021',
        label: 'VILLA SANTA LUCIA DEGLI ABRUZZI',
      },
      {
        value: 'L958',
        label: 'VILLALAGO',
      },
      {
        value: 'M031',
        label: 'VILLAVALLELONGA',
      },
      {
        value: 'M041',
        label: 'VILLETTA BARREA',
      },
      {
        value: 'M090',
        label: 'VITTORITO',
      },
    ],
  },

  {
    value: 'AR',
    label: 'Arezzo',
    children: [
      {
        value: 'A291',
        label: 'ANGHIARI',
      },
      {
        value: 'A390',
        label: 'AREZZO',
      },
      {
        value: 'A541',
        label: 'BADIA TEDALDA',
      },
      {
        value: 'A851',
        label: 'BIBBIENA',
      },
      {
        value: 'B243',
        label: 'BUCINE',
      },
      {
        value: 'B670',
        label: 'CAPOLONA',
      },
      {
        value: 'B693',
        label: 'CAPRESE o CAPRESE MICHELANGELO',
      },
      {
        value: 'C102',
        label: 'CASTEL FOCOGNANO',
      },
      {
        value: 'C263',
        label: "CASTEL SAN NICCOLO'",
      },
      {
        value: 'C112',
        label: 'CASTELFRANCO DI SOPRA',
      },
      {
        value: 'M322',
        label: "CASTELFRANCO PIANDISCO'",
      },
      {
        value: 'C318',
        label: 'CASTIGLION FIBOCCHI o DUE COMUNI DI LATERINA',
      },
      {
        value: 'C319',
        label: 'CASTIGLION FIORENTINO',
      },
      {
        value: 'C320',
        label: 'CASTIGLION UBERTINI',
      },
      {
        value: 'C407',
        label: 'CAVRIGLIA',
      },
      {
        value: 'C648',
        label: 'CHITIGNANO',
      },
      {
        value: 'C663',
        label: 'CHIUSI DELLA VERNA o CHIUSI IN CASENTINO',
      },
      {
        value: 'C774',
        label: 'CIVITELLA o CIVITELLA IN VAL DI CHIANA',
      },
      {
        value: 'D077',
        label: 'CORTONA',
      },
      {
        value: 'D649',
        label: 'FOIANO o FOIANO DELLA CHIANA',
      },
      {
        value: 'E468',
        label: 'LATERINA',
      },
      {
        value: 'M392',
        label: 'LATERINA PERGINE VALDARNO',
      },
      {
        value: 'E693',
        label: 'LORO o LORO CIUFFENNA',
      },
      {
        value: 'E718',
        label: 'LUCIGNANO',
      },
      {
        value: 'E933',
        label: 'MARCIANO DELLA CHIANA',
      },
      {
        value: 'F628',
        label: 'MONTE SAN SAVINO',
      },
      {
        value: 'F629',
        label: 'MONTE SANTA MARIA o MONTE SANTA MARIA TIBERINA',
      },
      {
        value: 'F565',
        label: 'MONTEMIGNAIO',
      },
      {
        value: 'F594',
        label: 'MONTERCHI',
      },
      {
        value: 'F656',
        label: 'MONTEVARCHI',
      },
      {
        value: 'G139',
        label: 'ORTIGNANO o ORTIGNANO RAGGIOLO',
      },
      {
        value: 'G451',
        label: "PERGINE o PERGINE VALDARNO o VAL D'AMBRA",
      },
      {
        value: 'G552',
        label: 'PIAN DI SCO',
      },
      {
        value: 'G653',
        label: 'PIEVE SANTO STEFANO',
      },
      {
        value: 'G879',
        label: 'POPPI',
      },
      {
        value: 'H008',
        label: 'PRATOVECCHIO',
      },
      {
        value: 'M329',
        label: 'PRATOVECCHIO STIA',
      },
      {
        value: 'H009',
        label: 'PRATOVECCHIO-STIA',
      },
      {
        value: 'H160',
        label: 'RAGGIOLO',
      },
      {
        value: 'H901',
        label: 'SAN GIOVANNI o SAN GIOVANNI VALDARNO',
      },
      {
        value: 'I155',
        label: 'SANSEPOLCRO',
      },
      {
        value: 'I681',
        label: 'SESTINO',
      },
      {
        value: 'I952',
        label: 'STIA',
      },
      {
        value: 'I991',
        label: 'SUBBIANO',
      },
      {
        value: 'L038',
        label: 'TALLA',
      },
      {
        value: 'L123',
        label: 'TERRANOVA o TERRANUOVA BRACCIOLINI',
      },
    ],
  },

  {
    value: 'AT',
    label: 'Asti',
    children: [
      {
        value: 'A072',
        label: 'AGLIANO o AGLIANO TERME',
      },
      {
        value: 'A173',
        label: 'ALBUGNANO',
      },
      {
        value: 'A312',
        label: 'ANTIGNANO',
      },
      {
        value: 'A352',
        label: 'ARAMENGO',
      },
      {
        value: 'A479',
        label: 'ASTI',
      },
      {
        value: 'A588',
        label: "BALDICHIERI D'ASTI",
      },
      {
        value: 'A770',
        label: 'BELVEDERE o BELVEGLIO',
      },
      {
        value: 'B221',
        label: 'BRUNO',
      },
      {
        value: 'B236',
        label: 'BUBBIO',
      },
      {
        value: 'B306',
        label: "BUTTIGLIERA D'ASTI",
      },
      {
        value: 'B376',
        label: 'CALAMANDRANA',
      },
      {
        value: 'B418',
        label: 'CALLIANO',
      },
      {
        value: 'B425',
        label: 'CALOSSO',
      },
      {
        value: 'B469',
        label: 'CAMERANO CASASCO',
      },
      {
        value: 'B594',
        label: 'CANELLI',
      },
      {
        value: 'B633',
        label: 'CANTARANA',
      },
      {
        value: 'B707',
        label: 'CAPRIGLIO',
      },
      {
        value: 'B991',
        label: 'CASORZO',
      },
      {
        value: 'C022',
        label: 'CASSINASCO',
      },
      {
        value: 'C049',
        label: 'CASTAGNOLE DELLE LANZE',
      },
      {
        value: 'C253',
        label: 'CASTEL ROCCHERO',
      },
      {
        value: 'C127',
        label: "CASTELL'ALFERO",
      },
      {
        value: 'C154',
        label: 'CASTELLERO',
      },
      {
        value: 'C161',
        label: 'CASTELLETTO MOLINA',
      },
      {
        value: 'C226',
        label: 'CASTELNUOVO BELBO',
      },
      {
        value: 'C230',
        label: 'CASTELNUOVO CALCEA',
      },
      {
        value: 'C438',
        label: 'CELLARENGO',
      },
      {
        value: 'C583',
        label: 'CESSOLE',
      },
      {
        value: 'C701',
        label: 'CINAGLIO',
      },
      {
        value: 'C804',
        label: 'COAZZOLO',
      },
      {
        value: 'C807',
        label: 'COCCONATO',
      },
      {
        value: 'C831',
        label: 'COLCAVAGNO',
      },
      {
        value: 'D046',
        label: 'CORSIONE',
      },
      {
        value: 'D050',
        label: 'CORTANDONE',
      },
      {
        value: 'D051',
        label: 'CORTANZE',
      },
      {
        value: 'D052',
        label: 'CORTAZZONE',
      },
      {
        value: 'D101',
        label: 'COSSOMBRATO',
      },
      {
        value: 'D119',
        label: "COSTIGLIOLE D'ASTI",
      },
      {
        value: 'D207',
        label: 'CUNICO',
      },
      {
        value: 'D387',
        label: 'DUSINO',
      },
      {
        value: 'D388',
        label: 'DUSINO SAN MICHELE',
      },
      {
        value: 'D554',
        label: 'FERRERE',
      },
      {
        value: 'D678',
        label: 'FONTANILE',
      },
      {
        value: 'D802',
        label: 'FRINCO',
      },
      {
        value: 'E134',
        label: 'GRANA',
      },
      {
        value: 'E633',
        label: 'LOAZZOLO',
      },
      {
        value: 'E917',
        label: 'MARANZANA',
      },
      {
        value: 'E944',
        label: 'MARETTO',
      },
      {
        value: 'E964',
        label: 'MARMORITO',
      },
      {
        value: 'F254',
        label: 'MOASCA',
      },
      {
        value: 'F308',
        label: 'MOMBALDONE',
      },
      {
        value: 'F311',
        label: 'MOMBARUZZO',
      },
      {
        value: 'F316',
        label: 'MOMBERCELLI',
      },
      {
        value: 'F323',
        label: 'MONALE',
      },
      {
        value: 'F336',
        label: 'MONCALVO',
      },
      {
        value: 'F361',
        label: 'MONGARDINO',
      },
      {
        value: 'F386',
        label: 'MONTABONE',
      },
      {
        value: 'F390',
        label: 'MONTAFIA',
      },
      {
        value: 'F409',
        label: 'MONTALDO SCARAMPI',
      },
      {
        value: 'F556',
        label: 'MONTEMAGNO',
      },
      {
        value: 'F678',
        label: 'MONTIGLIO',
      },
      {
        value: 'M302',
        label: 'MONTIGLIO MONFERRATO',
      },
      {
        value: 'F709',
        label: 'MORANSENGO',
      },
      {
        value: 'F902',
        label: 'NIZZA MONFERRATO',
      },
      {
        value: 'G358',
        label: 'PASSERANO MARMORITO',
      },
      {
        value: 'G430',
        label: 'PENANGO',
      },
      {
        value: 'G593',
        label: 'PIEA',
      },
      {
        value: 'G692',
        label: "PIOVA' o PIOVA' MASSAIA",
      },
      {
        value: 'G894',
        label: 'PORTACOMARO',
      },
      {
        value: 'H102',
        label: 'QUARANTI',
      },
      {
        value: 'H219',
        label: 'REFRANCORE',
      },
      {
        value: 'H366',
        label: 'ROATTO',
      },
      {
        value: 'H376',
        label: 'ROBELLA',
      },
      {
        value: 'H392',
        label: "ROCCA D'ARAZZO",
      },
      {
        value: 'H451',
        label: 'ROCCAVERANO',
      },
      {
        value: 'H466',
        label: 'ROCCHETTA PALAFEA',
      },
      {
        value: 'H468',
        label: 'ROCCHETTA TANARO',
      },
      {
        value: 'H899',
        label: 'SAN GIORGIO SCARAMPI',
      },
      {
        value: 'I076',
        label: 'SAN PAOLO SOLBRITO',
      },
      {
        value: 'I495',
        label: 'SCANDELUZZA',
      },
      {
        value: 'I555',
        label: 'SCURZOLENGO',
      },
      {
        value: 'I637',
        label: 'SEROLE',
      },
      {
        value: 'I678',
        label: 'SESSAME',
      },
      {
        value: 'I698',
        label: 'SETTIME',
      },
      {
        value: 'I781',
        label: 'SOGLIO',
      },
      {
        value: 'L168',
        label: 'TIGLIOLE',
      },
      {
        value: 'L203',
        label: 'TONCO',
      },
      {
        value: 'L204',
        label: 'TONENGO',
      },
      {
        value: 'L574',
        label: 'VALFENERA',
      },
      {
        value: 'L807',
        label: 'VESIME',
      },
      {
        value: 'L829',
        label: "VIALE o VIALE D'ASTI",
      },
      {
        value: 'L834',
        label: 'VIARIGI',
      },
      {
        value: 'L927',
        label: 'VILLA CORSIONE',
      },
      {
        value: 'M019',
        label: 'VILLA SAN SECONDO',
      },
      {
        value: 'M058',
        label: 'VINCHIO',
      },
    ],
  },

  {
    value: 'AV',
    label: 'Avellino',
    children: [
      {
        value: 'A101',
        label: 'AIELLO o AIELLO DEL SABATO',
      },
      {
        value: 'A228',
        label: 'ALTAVILLA o ALTAVILLA IRPINA',
      },
      {
        value: 'A284',
        label: 'ANDRETTA',
      },
      {
        value: 'A347',
        label: 'AQUILONIA o CARBONARA',
      },
      {
        value: 'A399',
        label: 'ARIANO o ARIANO DI PUGLIA o ARIANO IRPINO',
      },
      {
        value: 'A489',
        label: 'ATRIPALDA',
      },
      {
        value: 'A508',
        label: 'AVELLA',
      },
      {
        value: 'A509',
        label: 'AVELLINO',
      },
      {
        value: 'A566',
        label: 'BAGNOLI o BAGNOLI IRPINO',
      },
      {
        value: 'A580',
        label: 'BAIANO',
      },
      {
        value: 'A753',
        label: 'BELLIZZI o BELLIZZI IRPINO',
      },
      {
        value: 'A881',
        label: 'BISACCIA',
      },
      {
        value: 'A975',
        label: 'BONITO',
      },
      {
        value: 'B367',
        label: 'CAIRANO',
      },
      {
        value: 'B374',
        label: 'CALABRITTO',
      },
      {
        value: 'B414',
        label: 'CALITRI',
      },
      {
        value: 'B415',
        label: 'CALITRI o CALIZZANO',
      },
      {
        value: 'B590',
        label: 'CANDIDA',
      },
      {
        value: 'B674',
        label: 'CAPOSELE',
      },
      {
        value: 'B706',
        label: 'CAPRIGLIA IRPINA',
      },
      {
        value: 'B776',
        label: 'CARIFE',
      },
      {
        value: 'B866',
        label: 'CASALBORE',
      },
      {
        value: 'B997',
        label: 'CASSANO o CASSANO IRPINO',
      },
      {
        value: 'C058',
        label: 'CASTEL BARONIA',
      },
      {
        value: 'C105',
        label: 'CASTELFRANCI',
      },
      {
        value: 'C283',
        label:
          'CASTELVETERE o CASTELVETERE DI CALORE o CASTELVETERE SUL CALORE',
      },
      {
        value: 'C557',
        label: 'CERVINARA',
      },
      {
        value: 'C576',
        label: 'CESINALI',
      },
      {
        value: 'C606',
        label: 'CHIANCHE',
      },
      {
        value: 'C607',
        label: 'CHIANCHETELLE',
      },
      {
        value: 'C659',
        label: 'CHIUSANO o CHIUSANO DI SAN DOMENICO',
      },
      {
        value: 'C971',
        label: 'CONTRADA',
      },
      {
        value: 'C976',
        label: 'CONZA DELLA CAMPANIA',
      },
      {
        value: 'D331',
        label: 'DOMICELLA',
      },
      {
        value: 'D638',
        label: 'FLUMERI',
      },
      {
        value: 'D671',
        label: 'FONTANAROSA',
      },
      {
        value: 'D701',
        label: 'FORINO',
      },
      {
        value: 'D798',
        label: 'FRIGENTO',
      },
      {
        value: 'D998',
        label: 'GESUALDO',
      },
      {
        value: 'E161',
        label: 'GRECI',
      },
      {
        value: 'E206',
        label: 'GROTTAMINARDA',
      },
      {
        value: 'E214',
        label: 'GROTTOLELLA',
      },
      {
        value: 'E245',
        label: 'GUARDIA LOMBARDI',
      },
      {
        value: 'E397',
        label: 'LACEDONIA',
      },
      {
        value: 'E448',
        label: 'LAPIO',
      },
      {
        value: 'E487',
        label: 'LAURO',
      },
      {
        value: 'E605',
        label: 'LIONI',
      },
      {
        value: 'E746',
        label: 'LUOGOSANO',
      },
      {
        value: 'E891',
        label: 'MANOCALZATI',
      },
      {
        value: 'E997',
        label: 'MARZANO o MARZANO DI NOLA',
      },
      {
        value: 'F110',
        label: 'MELITO o MELITO IRPINO o MELITO VALLE BONITO',
      },
      {
        value: 'F141',
        label: 'MERCOGLIANO',
      },
      {
        value: 'F197',
        label: 'MIGLIANO',
      },
      {
        value: 'F230',
        label: 'MIRABELLA o MIRABELLA ECLANO',
      },
      {
        value: 'F397',
        label: 'MONTAGUTO',
      },
      {
        value: 'F448',
        label: 'MONTECALVO o MONTECALVO IRPINO',
      },
      {
        value: 'F491',
        label: 'MONTEFALCIONE',
      },
      {
        value: 'F506',
        label: 'MONTEFORTE o MONTEFORTE IRPINO',
      },
      {
        value: 'F511',
        label: 'MONTEFREDANE',
      },
      {
        value: 'F512',
        label: 'MONTEFUSCO',
      },
      {
        value: 'F546',
        label: 'MONTELLA',
      },
      {
        value: 'F557',
        label: "MONTEMALE o SANT'ARCANGELO o SANT'ARCANGELO TRIMONTE",
      },
      {
        value: 'F559',
        label: 'MONTEMARANO',
      },
      {
        value: 'F566',
        label: 'MONTEMILETTO',
      },
      {
        value: 'F660',
        label: 'MONTEVERDE',
      },
      {
        value: 'M330',
        label: 'MONTORO',
      },
      {
        value: 'F693',
        label: 'MONTORO INFERIORE',
      },
      {
        value: 'F694',
        label: 'MONTORO SUPERIORE',
      },
      {
        value: 'F744',
        label: 'MORRA o MORRA DE SANCTIS o MORRA IRPINO',
      },
      {
        value: 'F762',
        label: 'MOSCHIANO',
      },
      {
        value: 'F798',
        label: 'MUGNANO o MUGNANO DEL CARDINALE',
      },
      {
        value: 'F988',
        label: 'NUSCO',
      },
      {
        value: 'G125',
        label: 'ORSARA o ORSARA-DAUNO IRPINA o ORSARA DI PUGLIA',
      },
      {
        value: 'G165',
        label: "OSPEDALETTO o OSPEDALETTO D'ALPINOLO",
      },
      {
        value: 'G242',
        label: 'PAGO o PAGO DEL VALLO DI LAURO',
      },
      {
        value: 'G340',
        label: 'PAROLISE',
      },
      {
        value: 'G370',
        label: 'PATERNO o PATERNOPOLI',
      },
      {
        value: 'G519',
        label: 'PETRURO o PETRURO IRPINO',
      },
      {
        value: 'G611',
        label: 'PIETRADEFUSI',
      },
      {
        value: 'G629',
        label: 'PIETRASTORNINA',
      },
      {
        value: 'G990',
        label: 'PRATA o PRATA DI PRINCIPATO ULTRA',
      },
      {
        value: 'H006',
        label: 'PRATOLA o PRATOLA SERRA',
      },
      {
        value: 'H097',
        label: 'QUADRELLE',
      },
      {
        value: 'H099',
        label: 'QUAGLIETTA',
      },
      {
        value: 'H128',
        label: 'QUINDICI',
      },
      {
        value: 'H438',
        label: 'ROCCA SAN FELICE',
      },
      {
        value: 'H382',
        label: 'ROCCABASCERANA',
      },
      {
        value: 'H592',
        label: 'ROTONDI',
      },
      {
        value: 'H733',
        label: 'SALZA o SALZA IRPINA',
      },
      {
        value: 'H758',
        label: 'SAN BARBATO',
      },
      {
        value: 'H975',
        label: 'SAN MANGO o SAN MANGO SUL CALORE',
      },
      {
        value: 'I016',
        label: 'SAN MARTINO VALLE CAUDINA',
      },
      {
        value: 'I034',
        label: 'SAN MICHELE o SAN MICHELE DI SERINO',
      },
      {
        value: 'I061',
        label: 'SAN NICOLA BARONIA',
      },
      {
        value: 'I112',
        label: 'SAN PIETRO IRPINO o SAN PIETRO INDELICATO',
      },
      {
        value: 'I129',
        label: 'SAN POTITO o SAN POTITO ULTRA',
      },
      {
        value: 'I163',
        label: 'SAN SOSSIO o SAN SOSSIO BARONIA',
      },
      {
        value: 'I200',
        label: "SANT'AGATA DI SOTTO o SANT'AGATA IRPINA",
      },
      {
        value: 'I264',
        label: "SANT'ANDREA o SANT'ANDREA DI CONZA",
      },
      {
        value: 'I280',
        label: "SANT'ANGELO A SCALA",
      },
      {
        value: 'I279',
        label: "SANT'ANGELO ALL'ESCA",
      },
      {
        value: 'I281',
        label: "SANT'ANGELO DEI LOMBARDI",
      },
      {
        value: 'I219',
        label: 'SANTA LUCIA o SANTA LUCIA DI SERINO',
      },
      {
        value: 'I301',
        label: 'SANTA PAOLINA',
      },
      {
        value: 'I357',
        label: 'SANTO STEFANO o SANTO STEFANO DEL SOLE',
      },
      {
        value: 'I471',
        label: 'SAVIGNANO o SAVIGNANO DI PUGLIA o SAVIGNANO IRPINO',
      },
      {
        value: 'I493',
        label: 'SCAMPITELLA',
      },
      {
        value: 'I606',
        label: 'SENERCHIA',
      },
      {
        value: 'I630',
        label: 'SERINO',
      },
      {
        value: 'I756',
        label: 'SIRIGNANO',
      },
      {
        value: 'I805',
        label: 'SOLOFRA',
      },
      {
        value: 'I843',
        label: 'SORBO o SORBO SERPICO',
      },
      {
        value: 'I893',
        label: 'SPERONE',
      },
      {
        value: 'I990',
        label: 'STURNO',
      },
      {
        value: 'L004',
        label: 'SUMMONTE',
      },
      {
        value: 'L061',
        label: 'TAURANO',
      },
      {
        value: 'L062',
        label: 'TAURASI',
      },
      {
        value: 'L072',
        label: 'TAVERNOLA o TAVERNOLA SAN FELICE',
      },
      {
        value: 'L102',
        label: 'TEORA',
      },
      {
        value: 'L214',
        label: 'TORELLA o TORELLA DEI LOMBARDI',
      },
      {
        value: 'L272',
        label: 'TORRE LE NOCELLE',
      },
      {
        value: 'L301',
        label: 'TORRIONI',
      },
      {
        value: 'L399',
        label: 'TREVICO',
      },
      {
        value: 'L461',
        label: 'TUFO',
      },
      {
        value: 'L589',
        label: 'VALLATA',
      },
      {
        value: 'L616',
        label: 'VALLESACCARDA',
      },
      {
        value: 'L739',
        label: 'VENTICANO',
      },
      {
        value: 'L965',
        label: 'VILLAMAINA',
      },
      {
        value: 'L973',
        label: 'VILLANOVA o VILLANOVA DEL BATTISTA',
      },
      {
        value: 'M130',
        label: 'VOLTURARA o VOLTURARA IRPINA',
      },
      {
        value: 'M203',
        label: 'ZUNGOLI',
      },
    ],
  },

  {
    value: 'BA',
    label: 'Bari',
    children: [
      {
        value: 'A048',
        label: 'ACQUAVIVA o ACQUAVIVA DELLE FONTI',
      },
      {
        value: 'A055',
        label: 'ADELFIA',
      },
      {
        value: 'A149',
        label: 'ALBEROBELLO',
      },
      {
        value: 'A225',
        label: 'ALTAMURA',
      },
      {
        value: 'A285',
        label: 'ANDRIA o ANDRIA',
      },
      {
        value: 'A662',
        label: 'BARI o BARI DELLE PUGLIE',
      },
      {
        value: 'A669',
        label: 'BARLETTA o BARLETTA',
      },
      {
        value: 'A874',
        label: 'BINETTO',
      },
      {
        value: 'A883',
        label: 'BISCEGLIE o BISCEGLIE',
      },
      {
        value: 'A892',
        label: 'BITETTO',
      },
      {
        value: 'A893',
        label: 'BITONTO',
      },
      {
        value: 'A894',
        label: 'BITRITTO',
      },
      {
        value: 'B611',
        label: 'CANNETO o CANNETO DI BARI',
      },
      {
        value: 'B619',
        label: 'CANOSA o CANOSA DI PUGLIA o CANOSA DI PUGLIA',
      },
      {
        value: 'B716',
        label: 'CAPURSO',
      },
      {
        value: 'B737',
        label: 'CARBONARA o CARBONARA DI BARI',
      },
      {
        value: 'B923',
        label: 'CASAMASSIMA',
      },
      {
        value: 'B998',
        label: 'CASSANO o CASSANO DELLE MURGE',
      },
      {
        value: 'C134',
        label: 'CASTELLANA o CASTELLANA GROTTE',
      },
      {
        value: 'C423',
        label: 'CEGLIE o CEGLIE DEL CAMPO',
      },
      {
        value: 'C436',
        label: 'CELLAMARE',
      },
      {
        value: 'C975',
        label: 'CONVERSANO',
      },
      {
        value: 'C983',
        label: 'CORATO',
      },
      {
        value: 'E038',
        label: 'GIOIA o GIOIA DEL COLLE',
      },
      {
        value: 'E047',
        label: 'GIOVINAZZO',
      },
      {
        value: 'E155',
        label: 'GRAVINA o GRAVINA IN PUGLIA',
      },
      {
        value: 'E223',
        label: 'GRUMO o GRUMO APPULA',
      },
      {
        value: 'E645',
        label: 'LOCOROTONDO',
      },
      {
        value: 'E697',
        label: 'LOSETO',
      },
      {
        value: 'F220',
        label: 'MINERVINO o MINERVINO MURGE o MINERVINO MURGE',
      },
      {
        value: 'F262',
        label: 'MODUGNO',
      },
      {
        value: 'F280',
        label: 'MOLA DI BARI',
      },
      {
        value: 'F284',
        label: 'MOLFETTA',
      },
      {
        value: 'F376',
        label: 'MONOPOLI',
      },
      {
        value: 'F700',
        label: 'MONTRONE',
      },
      {
        value: 'F915',
        label: 'NOCI',
      },
      {
        value: 'F923',
        label: 'NOICATTARO o NOJA',
      },
      {
        value: 'G291',
        label: 'PALO o PALO DEL COLLE',
      },
      {
        value: 'G769',
        label: 'POGGIORSINI',
      },
      {
        value: 'G787',
        label: 'POLIGNANO o POLIGNANO A MARE',
      },
      {
        value: 'H096',
        label: 'PUTIGNANO',
      },
      {
        value: 'H643',
        label: 'RUTIGLIANO',
      },
      {
        value: 'H645',
        label: 'RUVO o RUVO DI PUGLIA',
      },
      {
        value: 'H749',
        label: 'SAMMICHELE DI BARI o SAN MICHELE',
      },
      {
        value: 'I053',
        label: 'SAN NICANDRO o SANNICANDRO DI BARI',
      },
      {
        value: 'I330',
        label: 'SANTERAMO o SANTERAMO IN COLLE',
      },
      {
        value: 'I907',
        label: 'SPINAZZOLA o SPINAZZOLA',
      },
      {
        value: 'L109',
        label: 'TERLIZZI',
      },
      {
        value: 'L220',
        label: 'TORITTO',
      },
      {
        value: 'L328',
        label: 'TRANI o TRANI',
      },
      {
        value: 'L425',
        label: 'TRIGGIANO',
      },
      {
        value: 'L472',
        label: 'TURI',
      },
      {
        value: 'L571',
        label: 'VALENZANO',
      },
    ],
  },

  {
    value: 'BG',
    label: 'Bergamo',
    children: [
      {
        value: 'A057',
        label: 'ADRARA SAN MARTINO',
      },
      {
        value: 'A058',
        label: 'ADRARA SAN ROCCO',
      },
      {
        value: 'A129',
        label: "ALBANO o ALBANO SANT'ALESSANDRO",
      },
      {
        value: 'A144',
        label: 'ALBEGNO',
      },
      {
        value: 'A163',
        label: 'ALBINO',
      },
      {
        value: 'A193',
        label: 'ALGUA o ALGUA DI COSTA SERINA',
      },
      {
        value: 'A214',
        label: "ALME'",
      },
      {
        value: 'M305',
        label: "ALME' CON VILLA",
      },
      {
        value: 'A216',
        label: 'ALMENNO SAN BARTOLOMEO',
      },
      {
        value: 'A217',
        label: 'ALMENNO SAN SALVATORE',
      },
      {
        value: 'A246',
        label: 'ALZANO LOMBARDO',
      },
      {
        value: 'A247',
        label: 'ALZANO MAGGIORE',
      },
      {
        value: 'A248',
        label: 'ALZANO SOPRA',
      },
      {
        value: 'A259',
        label: 'AMBIVERE',
      },
      {
        value: 'A304',
        label: 'ANTEGNATE',
      },
      {
        value: 'A365',
        label: 'ARCENE',
      },
      {
        value: 'A383',
        label: 'ARDESIO',
      },
      {
        value: 'A440',
        label: "ARSAGO o ARZAGO D'ADDA",
      },
      {
        value: 'A511',
        label: 'AVERARA',
      },
      {
        value: 'A517',
        label: 'AVIATICO',
      },
      {
        value: 'A528',
        label: 'AZZANO o AZZANO SAN PAOLO',
      },
      {
        value: 'A533',
        label: 'AZZONE',
      },
      {
        value: 'A557',
        label: 'BAGNATICA',
      },
      {
        value: 'A631',
        label: 'BARBATA',
      },
      {
        value: 'A654',
        label: 'BARESI',
      },
      {
        value: 'A664',
        label: 'BARIANO',
      },
      {
        value: 'A684',
        label: 'BARZANA',
      },
      {
        value: 'A679',
        label: 'BARZIZZA',
      },
      {
        value: 'A732',
        label: 'BEDULITA',
      },
      {
        value: 'A786',
        label: 'BERBENNO',
      },
      {
        value: 'A794',
        label: 'BERGAMO',
      },
      {
        value: 'A815',
        label: 'BERZO o BERZO SAN FERMO',
      },
      {
        value: 'A846',
        label: 'BIANZANO',
      },
      {
        value: 'A903',
        label: 'BLELLO',
      },
      {
        value: 'A937',
        label: 'BOLGARE',
      },
      {
        value: 'A950',
        label: 'BOLTIERE',
      },
      {
        value: 'A963',
        label: 'BONATE SOPRA',
      },
      {
        value: 'A962',
        label: 'BONATE SOTTO',
      },
      {
        value: 'A966',
        label: 'BONDIONE',
      },
      {
        value: 'A969',
        label: 'BONDO PETELLO',
      },
      {
        value: 'A985',
        label: 'BORDOGNA',
      },
      {
        value: 'B010',
        label: 'BORGO DI TERZO',
      },
      {
        value: 'B045',
        label: 'BORGOUNITO',
      },
      {
        value: 'B083',
        label: 'BOSSICO',
      },
      {
        value: 'B088',
        label: 'BOTTANUCO',
      },
      {
        value: 'B112',
        label: 'BRACCA',
      },
      {
        value: 'B113',
        label: 'BRACCA DI COSTA SERINA',
      },
      {
        value: 'B123',
        label: 'BRANZI',
      },
      {
        value: 'B137',
        label: 'BREMBATE',
      },
      {
        value: 'B138',
        label: 'BREMBATE DI SOPRA',
      },
      {
        value: 'B139',
        label: 'BREMBATE DI SOTTO',
      },
      {
        value: 'B140',
        label: 'BREMBILLA',
      },
      {
        value: 'B148',
        label: 'BRENO o SOMBRENO',
      },
      {
        value: 'B178',
        label: "BRIGNANO o BRIGNANO GERA D'ADDA",
      },
      {
        value: 'B217',
        label: 'BRUMANO',
      },
      {
        value: 'B222',
        label: 'BRUNTINO',
      },
      {
        value: 'B223',
        label: 'BRUSAPORTO',
      },
      {
        value: 'B310',
        label: "BUZZONE SAN PAOLO o SAN PAOLO D'ARGON",
      },
      {
        value: 'B392',
        label: 'CALCINATE o CALCINAIA',
      },
      {
        value: 'B393',
        label: 'CALCINATE o CALCINATO',
      },
      {
        value: 'B394',
        label: 'CALCIO o CALCINATO',
      },
      {
        value: 'B395',
        label: 'CALCIO',
      },
      {
        value: 'B406',
        label: 'CALEPIO o CALENZANO',
      },
      {
        value: 'B423',
        label: 'CALOLZIO o CALOLZIOCORTE',
      },
      {
        value: 'B434',
        label: "CALUSCO o CALUSCO D'ADDA",
      },
      {
        value: 'B442',
        label: 'CALVENZANO',
      },
      {
        value: 'B471',
        label: 'CAMERATA o CAMERATA CORNELLO',
      },
      {
        value: 'B618',
        label: "CANONICA D'ADDA",
      },
      {
        value: 'B661',
        label: 'CAPIZZONE',
      },
      {
        value: 'B702',
        label: "CAPRIATE o CAPRIATE D'ADDA",
      },
      {
        value: 'B703',
        label: 'CAPRIATE SAN GERVASIO',
      },
      {
        value: 'B710',
        label: 'CAPRINO BERGAMASCO',
      },
      {
        value: 'B731',
        label: 'CARAVAGGIO',
      },
      {
        value: 'B800',
        label: 'CAROBBIO',
      },
      {
        value: 'B801',
        label: 'CAROBBIO DEGLI ANGELI',
      },
      {
        value: 'B803',
        label: 'CARONA',
      },
      {
        value: 'B854',
        label: 'CARVICO',
      },
      {
        value: 'B947',
        label: 'CASAZZA o MOLOGNO',
      },
      {
        value: 'B971',
        label: "CASIRATE o CASIRATE D'ADDA",
      },
      {
        value: 'B978',
        label: 'CASNIGO',
      },
      {
        value: 'C007',
        label: 'CASSIGLIO',
      },
      {
        value: 'C079',
        label: "CASTEL DE' CONTI o CASTELLI CALEPIO",
      },
      {
        value: 'C255',
        label: 'CASTEL ROZZONE',
      },
      {
        value: 'C324',
        label: 'CASTIONE o CASTIONE DELLA PRESOLANA',
      },
      {
        value: 'C337',
        label: 'CASTRO',
      },
      {
        value: 'C396',
        label: 'CAVERNAGO',
      },
      {
        value: 'C410',
        label: "CAZZANO o CAZZANO SANT'ANDREA",
      },
      {
        value: 'C454',
        label: 'CENATE',
      },
      {
        value: 'C455',
        label: "CENATE D'ARGON",
      },
      {
        value: 'C456',
        label: 'CENATE DI SOPRA o CENATE SOPRA',
      },
      {
        value: 'C457',
        label: 'CENATE DI SOTTO o CENATE SOTTO',
      },
      {
        value: 'C459',
        label: 'CENE',
      },
      {
        value: 'C473',
        label: 'CENTRISOLA',
      },
      {
        value: 'C475',
        label: 'CEPINO',
      },
      {
        value: 'C506',
        label: 'CERETE',
      },
      {
        value: 'C635',
        label: "CHIGNOLO o CHIGNOLO D'ISOLA",
      },
      {
        value: 'C649',
        label: 'CHIUDUNO',
      },
      {
        value: 'C728',
        label: 'CISANO o CISANO BERGAMASCO',
      },
      {
        value: 'C730',
        label: 'CISERANO',
      },
      {
        value: 'C759',
        label: 'CIVIDATE o CIVIDATE AL PIANO',
      },
      {
        value: 'C789',
        label: 'CLANEZZO o UBIALE CLANEZZO',
      },
      {
        value: 'C800',
        label: 'CLUSONE',
      },
      {
        value: 'C835',
        label: 'COLERE',
      },
      {
        value: 'C894',
        label: 'COLOGNO o COLOGNO AL SERIO',
      },
      {
        value: 'C896',
        label: 'COLOGNOLA o COLOGNOLA DEL PIANO',
      },
      {
        value: 'C910',
        label: 'COLZATE',
      },
      {
        value: 'C937',
        label: 'COMUN NUOVO',
      },
      {
        value: 'D015',
        label: 'CORNA IMAGNA',
      },
      {
        value: 'D016',
        label: 'CORNALBA',
      },
      {
        value: 'D053',
        label: 'CORTE',
      },
      {
        value: 'D066',
        label: 'CORTENUOVA',
      },
      {
        value: 'D103',
        label: 'COSTA o COSTA VALLE IMAGNA',
      },
      {
        value: 'D110',
        label: 'COSTA DI MEZZATE o COSTA DI MONTICELLI',
      },
      {
        value: 'D111',
        label: 'COSTA DI SERINA o COSTA SERINA',
      },
      {
        value: 'D117',
        label: 'COSTA VOLPINO o VOLPINO',
      },
      {
        value: 'D126',
        label: 'COVO',
      },
      {
        value: 'D139',
        label: 'CREDARO',
      },
      {
        value: 'D215',
        label: 'CURDOMO',
      },
      {
        value: 'D220',
        label: 'CURNASCO',
      },
      {
        value: 'D221',
        label: 'CURNO',
      },
      {
        value: 'D233',
        label: 'CUSIO',
      },
      {
        value: 'D245',
        label: 'DALMINE',
      },
      {
        value: 'D283',
        label: 'DESENZANO o DESENZANO AL SERIO',
      },
      {
        value: 'D288',
        label: 'DEZZO DI SCALVE',
      },
      {
        value: 'D352',
        label: 'DOSSENA',
      },
      {
        value: 'D404',
        label: 'ENDENNA',
      },
      {
        value: 'D405',
        label: 'ENDINE',
      },
      {
        value: 'D406',
        label: 'ENDINE GAIANO',
      },
      {
        value: 'D411',
        label: 'ENTRATICO',
      },
      {
        value: 'D439',
        label: 'ESMATE',
      },
      {
        value: 'D490',
        label: "FARA o FARA D'ADDA o FARA GERA D'ADDA",
      },
      {
        value: 'D491',
        label: 'FARA o FARA OLIVANA CON SOLA',
      },
      {
        value: 'D588',
        label: 'FILAGO o MARNE',
      },
      {
        value: 'D604',
        label: 'FINO o FINO DEL MONTE',
      },
      {
        value: 'D606',
        label: 'FIORANO o FIORANO AL SERIO',
      },
      {
        value: 'D625',
        label: 'FIUMENERO',
      },
      {
        value: 'D664',
        label: 'FONDRA',
      },
      {
        value: 'D672',
        label: 'FONTANELLA',
      },
      {
        value: 'D684',
        label: 'FONTENO',
      },
      {
        value: 'D688',
        label: 'FOPPOLO',
      },
      {
        value: 'D697',
        label: 'FORESTO o FORESTO SPARSO',
      },
      {
        value: 'D727',
        label: 'FORNOVO o FORNOVO DI SAN GIOVANNI o FORNOVO SAN GIOVANNI',
      },
      {
        value: 'D795',
        label: 'FREROLA',
      },
      {
        value: 'D816',
        label: 'FUIPIANO o FUIPIANO AL BREMBO',
      },
      {
        value: 'D817',
        label: 'FUIPIANO VALLE IMAGNA',
      },
      {
        value: 'D903',
        label: 'GANDELLINO',
      },
      {
        value: 'D905',
        label: 'GANDINO',
      },
      {
        value: 'D906',
        label: 'GANDOSSO',
      },
      {
        value: 'D943',
        label: 'GAVERINA o GAVERINA TERME',
      },
      {
        value: 'D952',
        label: 'GAZZANIGA',
      },
      {
        value: 'D991',
        label: 'GEROSA',
      },
      {
        value: 'E006',
        label: 'GHISALBA',
      },
      {
        value: 'E100',
        label: 'GORLAGO',
      },
      {
        value: 'E103',
        label: 'GORLE',
      },
      {
        value: 'E106',
        label: 'GORNO',
      },
      {
        value: 'E148',
        label: 'GRASSOBBIO',
      },
      {
        value: 'E175',
        label: 'GRIGNANO',
      },
      {
        value: 'E189',
        label: 'GROMO',
      },
      {
        value: 'E192',
        label: 'GRONE',
      },
      {
        value: 'E218',
        label: 'GRUMELLO DEI ZANCHI',
      },
      {
        value: 'E219',
        label: 'GRUMELLO DEL MONTE',
      },
      {
        value: 'E220',
        label: 'GRUMELLO DEL PIANO',
      },
      {
        value: 'E353',
        label: 'ISOLA DI FONDRA',
      },
      {
        value: 'E370',
        label: 'ISSO',
      },
      {
        value: 'E422',
        label: 'LALLIO',
      },
      {
        value: 'E509',
        label: 'LEFFE',
      },
      {
        value: 'E524',
        label: 'LENNA',
      },
      {
        value: 'E562',
        label: 'LEVATE',
      },
      {
        value: 'E631',
        label: 'LIZZOLA',
      },
      {
        value: 'E637',
        label: 'LOCATE o LOCATE BERGAMASCO',
      },
      {
        value: 'E640',
        label: 'LOCATELLO',
      },
      {
        value: 'E686',
        label: 'LORENTINO',
      },
      {
        value: 'E704',
        label: 'LOVERE',
      },
      {
        value: 'E751',
        label: 'LURANO',
      },
      {
        value: 'E770',
        label: 'LUZZANA',
      },
      {
        value: 'E794',
        label: 'MADONE',
      },
      {
        value: 'E901',
        label: 'MAPELLO',
      },
      {
        value: 'E950',
        label: 'MARIANO o MARIANO AL BREMBO',
      },
      {
        value: 'E987',
        label: 'MARTINENGO',
      },
      {
        value: 'F034',
        label: 'MASSARI-MELZI',
      },
      {
        value: 'F071',
        label: 'MAZZOLENI E FALGHERA',
      },
      {
        value: 'F085',
        label: 'MEDOLAGO',
      },
      {
        value: 'F186',
        label: 'MEZZOLDO',
      },
      {
        value: 'F243',
        label: "MISANO o MISANO DI GERA D'ADDA",
      },
      {
        value: 'F276',
        label: "MOIO o MOIO DE' CALVI",
      },
      {
        value: 'F289',
        label: 'MOLINI DI COLOGNOLA',
      },
      {
        value: 'F328',
        label: 'MONASTEROLO o MONASTEROLO DEL CASTELLO',
      },
      {
        value: 'F547',
        label: 'MONTELLO o MONTICELLI o MONTICELLI DI BORGOGNA',
      },
      {
        value: 'F720',
        label: 'MORENGO',
      },
      {
        value: 'F738',
        label: 'MORNICO o MORNICO AL SERIO',
      },
      {
        value: 'F786',
        label: 'MOZZANICA',
      },
      {
        value: 'F791',
        label: 'MOZZO',
      },
      {
        value: 'F864',
        label: 'NEMBRO',
      },
      {
        value: 'F875',
        label: 'NESE',
      },
      {
        value: 'F941',
        label: 'NOSSA o PONTE NOSSA',
      },
      {
        value: 'G049',
        label: 'OLMO o OLMO AL BREMBO',
      },
      {
        value: 'G050',
        label: 'OLTRE IL COLLE',
      },
      {
        value: 'G051',
        label: 'OLTREPOVO',
      },
      {
        value: 'G054',
        label: 'OLTRESSENDA ALTA',
      },
      {
        value: 'G055',
        label: 'OLTRESSENDA BASSA',
      },
      {
        value: 'G068',
        label: 'ONETA',
      },
      {
        value: 'G075',
        label: 'ONORE',
      },
      {
        value: 'G094',
        label: 'OREZZO',
      },
      {
        value: 'G108',
        label: 'ORIO o ORIO AL SERIO',
      },
      {
        value: 'G118',
        label: 'ORNICA',
      },
      {
        value: 'G159',
        label: 'OSIO SOPRA',
      },
      {
        value: 'G160',
        label: 'OSIO SOTTO',
      },
      {
        value: 'G174',
        label: 'OSSANESGA',
      },
      {
        value: 'G233',
        label: 'PAGAZZANO',
      },
      {
        value: 'G249',
        label: 'PALADINA',
      },
      {
        value: 'G259',
        label: 'PALAZZAGO',
      },
      {
        value: 'G295',
        label: 'PALOSCO',
      },
      {
        value: 'G346',
        label: 'PARRE',
      },
      {
        value: 'G350',
        label: 'PARZANICA',
      },
      {
        value: 'G412',
        label: 'PEDRENGO',
      },
      {
        value: 'G418',
        label: 'PEIA',
      },
      {
        value: 'G562',
        label: 'PIAN GAIANO',
      },
      {
        value: 'G564',
        label: 'PIANICO',
      },
      {
        value: 'G574',
        label: 'PIARIO',
      },
      {
        value: 'G579',
        label: 'PIAZZA o PIAZZA BREMBANA',
      },
      {
        value: 'G583',
        label: 'PIAZZATORRE',
      },
      {
        value: 'G585',
        label: 'PIAZZO ALTO',
      },
      {
        value: 'G586',
        label: 'PIAZZO BASSO',
      },
      {
        value: 'G588',
        label: 'PIAZZOLO',
      },
      {
        value: 'G774',
        label: 'POGNANO',
      },
      {
        value: 'G845',
        label: 'PONTE DI NOSSA',
      },
      {
        value: 'G856',
        label: 'PONTE SAN PIETRO',
      },
      {
        value: 'G853',
        label: 'PONTERANICA',
      },
      {
        value: 'G864',
        label: 'PONTIDA o PONTITA',
      },
      {
        value: 'G867',
        label: 'PONTIROLO o PONTIROLO NUOVO',
      },
      {
        value: 'G930',
        label: 'POSCANTE',
      },
      {
        value: 'G968',
        label: 'PRADALUNGA',
      },
      {
        value: 'H020',
        label: 'PREDORE',
      },
      {
        value: 'H036',
        label: 'PREMOLO',
      },
      {
        value: 'H046',
        label: 'PRESEZZO',
      },
      {
        value: 'H091',
        label: 'PUMENENGO',
      },
      {
        value: 'H176',
        label: 'RANICA',
      },
      {
        value: 'H177',
        label: 'RANZANICO',
      },
      {
        value: 'H217',
        label: 'REDONA',
      },
      {
        value: 'H290',
        label: 'RIGOSA',
      },
      {
        value: 'H331',
        label: 'RIVA DI SOLTO',
      },
      {
        value: 'H351',
        label: "RIVIERA D'ADDA",
      },
      {
        value: 'H397',
        label: 'ROCCA DEL COLLE',
      },
      {
        value: 'H492',
        label: 'ROGNO',
      },
      {
        value: 'H509',
        label: 'ROMANO o ROMANO DI LOMBARDIA',
      },
      {
        value: 'H535',
        label: 'RONCO o RONCOBELLO',
      },
      {
        value: 'H544',
        label: 'RONCOLA',
      },
      {
        value: 'H563',
        label: 'ROSCIATE',
      },
      {
        value: 'H582',
        label: 'ROSSINO',
      },
      {
        value: 'H584',
        label: "ROTA o ROTA D'IMAGNA",
      },
      {
        value: 'H586',
        label: 'ROTA DENTRO',
      },
      {
        value: 'H587',
        label: 'ROTA FUORI',
      },
      {
        value: 'H615',
        label: 'ROVETTA',
      },
      {
        value: 'H616',
        label: 'ROVETTA CON FINO',
      },
      {
        value: 'H649',
        label: 'SABBIO o SABBIO BERGAMASCO',
      },
      {
        value: 'H854',
        label: 'SAN GALLO',
      },
      {
        value: 'H864',
        label: "SAN GERVASIO o SAN GERVASIO D'ADDA",
      },
      {
        value: 'H910',
        label: 'SAN GIOVANNI BIANCO',
      },
      {
        value: 'I006',
        label: "SAN MARTINO DE' CALVI",
      },
      {
        value: 'I079',
        label: 'SAN PELLEGRINO o SAN PELLEGRINO TERME',
      },
      {
        value: 'I106',
        label: "SAN PIETRO D'ORZIO",
      },
      {
        value: 'I297',
        label: "SANT'ANTONIO o SANT'ANTONIO D'ADDA",
      },
      {
        value: 'I349',
        label: "SANT'OMOBONO IMAGNA",
      },
      {
        value: 'M333',
        label: "SANT'OMOBONO TERME",
      },
      {
        value: 'I168',
        label: 'SANTA BRIGIDA',
      },
      {
        value: 'I358',
        label: 'SANTO STEFANO o SANTO STEFANO DEL MONTE DEGLI ANGELI',
      },
      {
        value: 'I437',
        label: 'SARNICO',
      },
      {
        value: 'I502',
        label: 'SCANO o SCANO AL BREMBO',
      },
      {
        value: 'I505',
        label: 'SCANZO',
      },
      {
        value: 'I506',
        label: 'SCANZOROSCIATE',
      },
      {
        value: 'I530',
        label: 'SCHILPARIO',
      },
      {
        value: 'I567',
        label: 'SEDRINA',
      },
      {
        value: 'I583',
        label: 'SELINO',
      },
      {
        value: 'I587',
        label: 'SELLERE',
      },
      {
        value: 'I597',
        label: 'SELVINO',
      },
      {
        value: 'I628',
        label: 'SERIATE',
      },
      {
        value: 'I629',
        label: 'SERINA',
      },
      {
        value: 'I713',
        label: 'SFORZATICA',
      },
      {
        value: 'I811',
        label: 'SOLTO',
      },
      {
        value: 'I812',
        label: 'SOLTO COLLINA',
      },
      {
        value: 'I813',
        label: 'SOLZA',
      },
      {
        value: 'I818',
        label: 'SOMENDENNA',
      },
      {
        value: 'I830',
        label: 'SONGAVAZZO',
      },
      {
        value: 'I858',
        label: 'SORISOLE',
      },
      {
        value: 'I869',
        label: 'SOTTO IL MONTE o SOTTO IL MONTE GIOVANNI XXIII',
      },
      {
        value: 'I873',
        label: 'SOVERE',
      },
      {
        value: 'I913',
        label: 'SPINO o SPINO AL BREMBO',
      },
      {
        value: 'I915',
        label: 'SPINONE',
      },
      {
        value: 'I916',
        label: 'SPINONE AL LAGO o SPINONE DEI CASTELLI',
      },
      {
        value: 'I919',
        label: 'SPIRANO',
      },
      {
        value: 'I931',
        label: 'STABELLO',
      },
      {
        value: 'I951',
        label: 'STEZZANO',
      },
      {
        value: 'I986',
        label: 'STROZZA',
      },
      {
        value: 'I997',
        label: 'SUISIO',
      },
      {
        value: 'L029',
        label: 'TAGLIUNO',
      },
      {
        value: 'L037',
        label: 'TALEGGIO',
      },
      {
        value: 'L073',
        label: 'TAVERNOLA o TAVERNOLA BERGAMASCA',
      },
      {
        value: 'L087',
        label: 'TELGATE',
      },
      {
        value: 'L118',
        label: "TERNO o TERNO D'ISOLA",
      },
      {
        value: 'L251',
        label: 'TORRE BOLDONE',
      },
      {
        value: 'L257',
        label: "TORRE DE' BUSI o TORRE DE' BUSI",
      },
      {
        value: 'L265',
        label: "TORRE DE' ROVERI",
      },
      {
        value: 'L276',
        label: 'TORRE PALLAVICINA',
      },
      {
        value: 'L318',
        label: 'TRABUCHELLO',
      },
      {
        value: 'L388',
        label: 'TRESCORE o TRESCORE BALNEARIO',
      },
      {
        value: 'L400',
        label: 'TREVIGLIO',
      },
      {
        value: 'L404',
        label: 'TREVIOLO',
      },
      {
        value: 'L502',
        label: 'URGNANO',
      },
      {
        value: 'M334',
        label: 'VAL BREMBILLA',
      },
      {
        value: 'L544',
        label: 'VALBONDIONE',
      },
      {
        value: 'L545',
        label: 'VALBREMBO',
      },
      {
        value: 'L579',
        label: 'VALGOGLIO',
      },
      {
        value: 'L585',
        label: "VALL'ALTA",
      },
      {
        value: 'L623',
        label: 'VALLEVE',
      },
      {
        value: 'L642',
        label: 'VALNEGRA',
      },
      {
        value: 'L649',
        label: 'VALSECCA',
      },
      {
        value: 'L652',
        label: 'VALTESSE',
      },
      {
        value: 'L655',
        label: 'VALTORTA',
      },
      {
        value: 'L707',
        label: 'VEDESETA',
      },
      {
        value: 'L752',
        label: 'VERDELLINO',
      },
      {
        value: 'L753',
        label: 'VERDELLO',
      },
      {
        value: 'L795',
        label: 'VERTOVA',
      },
      {
        value: 'L827',
        label: 'VIADANICA',
      },
      {
        value: 'L865',
        label: 'VIGANO o VIGANO SAN MARTINO',
      },
      {
        value: 'L894',
        label: 'VIGOLO',
      },
      {
        value: 'L929',
        label: "VILLA D'ADDA",
      },
      {
        value: 'A215',
        label: "VILLA D'ALME'",
      },
      {
        value: 'L938',
        label: "VILLA D'OGNA",
      },
      {
        value: 'L936',
        label: 'VILLA DI SERIO',
      },
      {
        value: 'M045',
        label: 'VILLONGO',
      },
      {
        value: 'M046',
        label: 'VILLONGO SAN FILASTRO',
      },
      {
        value: 'M047',
        label: "VILLONGO SANT'ALESSANDRO",
      },
      {
        value: 'M049',
        label: 'VILMINORE',
      },
      {
        value: 'M050',
        label: 'VILMINORE DI SCALVE',
      },
      {
        value: 'M144',
        label: 'ZANDOBBIO',
      },
      {
        value: 'M147',
        label: 'ZANICA',
      },
      {
        value: 'M184',
        label: 'ZOGNO',
      },
      {
        value: 'M193',
        label: 'ZORZINO',
      },
    ],
  },

  {
    value: 'BI',
    label: 'Biella',
    children: [
      {
        value: 'A107',
        label: 'AILOCHE',
      },
      {
        value: 'A280',
        label: 'ANDORNO MICCA',
      },
      {
        value: 'A784',
        label: 'BENNA',
      },
      {
        value: 'A859',
        label: 'BIELLA',
      },
      {
        value: 'A876',
        label: 'BIOGLIO',
      },
      {
        value: 'B058',
        label: 'BORRIANA',
      },
      {
        value: 'B229',
        label: 'BRUSNENGO',
      },
      {
        value: 'B417',
        label: 'CALLABIANA',
      },
      {
        value: 'B457',
        label: 'CAMANDONA',
      },
      {
        value: 'B465',
        label: 'CAMBURZANO',
      },
      {
        value: 'B508',
        label: 'CAMPIGLIA CERVO o CAMPIGLIA',
      },
      {
        value: 'M373',
        label: 'CAMPIGLIA CERVO',
      },
      {
        value: 'B586',
        label: 'CANDELO',
      },
      {
        value: 'B708',
        label: 'CAPRILE',
      },
      {
        value: 'B933',
        label: 'CASAPINTA',
      },
      {
        value: 'C155',
        label: 'CASTELLETTO CERVO',
      },
      {
        value: 'C363',
        label: "CAVAGLIA'",
      },
      {
        value: 'C526',
        label: 'CERRETO CASTELLO o CERRETO',
      },
      {
        value: 'C532',
        label: 'CERRIONE',
      },
      {
        value: 'C819',
        label: 'COGGIOLA',
      },
      {
        value: 'D094',
        label: 'COSSATO',
      },
      {
        value: 'D165',
        label: 'CREVACUORE',
      },
      {
        value: 'D182',
        label: 'CROSA',
      },
      {
        value: 'D219',
        label: 'CURINO',
      },
      {
        value: 'D339',
        label: 'DONATO',
      },
      {
        value: 'D350',
        label: 'DORZANO',
      },
      {
        value: 'D848',
        label: 'GAGLIANICO',
      },
      {
        value: 'E024',
        label: 'GIFFLENGA',
      },
      {
        value: 'E130',
        label: 'GRAGLIA',
      },
      {
        value: 'E552',
        label: 'LESSONA',
      },
      {
        value: 'M371',
        label: 'LESSONA',
      },
      {
        value: 'E821',
        label: 'MAGNANO',
      },
      {
        value: 'F037',
        label: 'MASSAZZA',
      },
      {
        value: 'F042',
        label: 'MASSERANO',
      },
      {
        value: 'F167',
        label: 'MEZZANA MORTIGLIENGO o MEZZANA',
      },
      {
        value: 'F189',
        label: 'MIAGLIANO',
      },
      {
        value: 'F369',
        label: 'MONGRANDO',
      },
      {
        value: 'M304',
        label: 'MOSSO',
      },
      {
        value: 'F769',
        label: 'MOSSO SANTA MARIA',
      },
      {
        value: 'F776',
        label: 'MOTTALCIATA',
      },
      {
        value: 'F833',
        label: 'MUZZANO',
      },
      {
        value: 'F878',
        label: 'NETRO',
      },
      {
        value: 'F992',
        label: 'OCCHIEPPO INFERIORE',
      },
      {
        value: 'F993',
        label: 'OCCHIEPPO SUPERIORE',
      },
      {
        value: 'G521',
        label: 'PETTINENGO',
      },
      {
        value: 'G577',
        label: 'PIATTO',
      },
      {
        value: 'G594',
        label: 'PIEDICAVALLO',
      },
      {
        value: 'G714',
        label: 'PISTOLESA',
      },
      {
        value: 'G798',
        label: 'POLLONE',
      },
      {
        value: 'G820',
        label: 'PONDERANO',
      },
      {
        value: 'G927',
        label: 'PORTULA',
      },
      {
        value: 'G980',
        label: 'PRALUNGO',
      },
      {
        value: 'G974',
        label: 'PRAY',
      },
      {
        value: 'H103',
        label: 'QUAREGNA',
      },
      {
        value: 'M414',
        label: 'QUAREGNA CERRETO',
      },
      {
        value: 'H145',
        label: 'QUITTENGO',
      },
      {
        value: 'H538',
        label: 'RONCO BIELLESE o RONCO',
      },
      {
        value: 'H553',
        label: 'ROPPOLO',
      },
      {
        value: 'H561',
        label: 'ROSAZZA',
      },
      {
        value: 'H662',
        label: 'SAGLIANO MICCA o SAGLIANO',
      },
      {
        value: 'H681',
        label: 'SALA BIELLESE o SALA',
      },
      {
        value: 'H726',
        label: 'SALUSSOLA',
      },
      {
        value: 'I074',
        label: 'SAN PAOLO CERVO o SAN PAOLO',
      },
      {
        value: 'H821',
        label: 'SANDIGLIANO',
      },
      {
        value: 'I596',
        label: 'SELVE MARCONE o SELVE',
      },
      {
        value: 'I835',
        label: 'SOPRANA',
      },
      {
        value: 'I847',
        label: 'SORDEVOLO',
      },
      {
        value: 'I868',
        label: 'SOSTEGNO',
      },
      {
        value: 'I980',
        label: 'STRONA',
      },
      {
        value: 'L075',
        label: 'TAVIGLIANO',
      },
      {
        value: 'L116',
        label: 'TERNENGO',
      },
      {
        value: 'L193',
        label: 'TOLLEGNO',
      },
      {
        value: 'L239',
        label: 'TORRAZZO',
      },
      {
        value: 'L436',
        label: 'TRIVERO',
      },
      {
        value: 'L556',
        label: 'VALDENGO',
      },
      {
        value: 'M417',
        label: 'VALDILANA',
      },
      {
        value: 'L586',
        label: 'VALLANZENGO',
      },
      {
        value: 'L606',
        label: 'VALLE MOSSO o VALLE INFERIORE MOSSO',
      },
      {
        value: 'L620',
        label: 'VALLE SAN NICOLAO',
      },
      {
        value: 'L712',
        label: 'VEGLIO',
      },
      {
        value: 'L785',
        label: 'VERRONE',
      },
      {
        value: 'L880',
        label: 'VIGLIANO BIELLESE o VIGLIANO',
      },
      {
        value: 'L933',
        label: 'VILLA DEL BOSCO',
      },
      {
        value: 'L978',
        label: 'VILLANOVA BIELLESE o VILLANOVA',
      },
      {
        value: 'M098',
        label: 'VIVERONE',
      },
      {
        value: 'M179',
        label: 'ZIMONE',
      },
      {
        value: 'M196',
        label: 'ZUBIENA',
      },
      {
        value: 'M201',
        label: 'ZUMAGLIA',
      },
    ],
  },

  {
    value: 'BL',
    label: 'Belluno',
    children: [
      {
        value: 'A083',
        label: 'AGORDO',
      },
      {
        value: 'A121',
        label: 'ALANO o ALANO DI PIAVE',
      },
      {
        value: 'A206',
        label: 'ALLEGHE',
      },
      {
        value: 'M375',
        label: 'ALPAGO',
      },
      {
        value: 'A266',
        label: "AMPEZZO o CORTINA D'AMPEZZO",
      },
      {
        value: 'A443',
        label: "ARSIE'",
      },
      {
        value: 'A501',
        label: 'AURONZO o AURONZO DI CADORE',
      },
      {
        value: 'A757',
        label: 'BELLUNO',
      },
      {
        value: 'A982',
        label: 'BORCA o BORCA DI CADORE',
      },
      {
        value: 'M421',
        label: 'BORGO VALBELLUNA',
      },
      {
        value: 'B375',
        label: 'CALALZO o CALALZO DI CADORE',
      },
      {
        value: 'B574',
        label: "CANALE D'AGORDO o FORNO DI CANALE",
      },
      {
        value: 'B662',
        label: 'CAPODIPONTE o PONTE NELLE ALPI',
      },
      {
        value: 'C146',
        label: 'CASTELLAVAZZO',
      },
      {
        value: 'C458',
        label: 'CENCENIGHE o CENCENIGHE AGORDINO',
      },
      {
        value: 'C562',
        label: 'CESANA o LENTIAI',
      },
      {
        value: 'C577',
        label: 'CESIO o CESIOMAGGIORE',
      },
      {
        value: 'C630',
        label: "CHIES D'ALPAGO",
      },
      {
        value: 'C672',
        label: 'CIBIANA o CIBIANA DI CADORE',
      },
      {
        value: 'C872',
        label: 'COLLE SANTA LUCIA',
      },
      {
        value: 'C919',
        label: 'COMELICO INFERIORE o SANTO STEFANO DI CADORE',
      },
      {
        value: 'C920',
        label: 'COMELICO SUPERIORE',
      },
      {
        value: 'D247',
        label: 'DANTA o DANTA DI CADORE',
      },
      {
        value: 'D330',
        label: 'DOMEGGE o DOMEGGE DI CADORE',
      },
      {
        value: 'D470',
        label: 'FALCADE',
      },
      {
        value: 'D506',
        label: "FARRA D'ALPAGO",
      },
      {
        value: 'D530',
        label: 'FELTRE',
      },
      {
        value: 'D686',
        label: 'FONZASO',
      },
      {
        value: 'D726',
        label: 'FORNO DI ZOLDO',
      },
      {
        value: 'E113',
        label: 'GOSALDO',
      },
      {
        value: 'E490',
        label: 'LA VALLE o LA VALLE AGORDINA',
      },
      {
        value: 'E429',
        label: 'LAMON',
      },
      {
        value: 'E588',
        label: 'LIMANA',
      },
      {
        value: 'E622',
        label: 'LIVINALLONGO o LIVINALLONGO DEL COL DI LANA',
      },
      {
        value: 'E672',
        label: 'LONGARONE',
      },
      {
        value: 'M342',
        label: 'LONGARONE',
      },
      {
        value: 'E687',
        label: 'LORENZAGO o LORENZAGO DI CADORE',
      },
      {
        value: 'E708',
        label: 'LOZZO o LOZZO CADORE o LOZZO DI CADORE',
      },
      {
        value: 'F094',
        label: 'MEL',
      },
      {
        value: 'G169',
        label: 'OSPITALE o OSPITALE DI CADORE',
      },
      {
        value: 'G404',
        label: 'PEDAVENA',
      },
      {
        value: 'G442',
        label: 'PERAROLO o PERAROLO DI CADORE',
      },
      {
        value: 'G638',
        label: "PIEVE D'ALPAGO",
      },
      {
        value: 'G642',
        label: 'PIEVE DI CADORE',
      },
      {
        value: 'H092',
        label: "PUOS D'ALPAGO",
      },
      {
        value: 'H124',
        label: 'QUERO',
      },
      {
        value: 'M332',
        label: 'QUERO VAS',
      },
      {
        value: 'H327',
        label: 'RIVA o RIVAMONTE o RIVAMONTE AGORDINO',
      },
      {
        value: 'H379',
        label: "ROCCA o ROCCA D'AGORDO o ROCCA PIETORE",
      },
      {
        value: 'H938',
        label: 'SAN GREGORIO o SAN GREGORIO NELLE ALPI',
      },
      {
        value: 'I063',
        label: "SAN NICOLO' o SAN NICOLO' DI COMELICO",
      },
      {
        value: 'I088',
        label: 'SAN PIETRO o SAN PIETRO CADORE o SAN PIETRO DI CADORE',
      },
      {
        value: 'I345',
        label: 'SAN TIZIANO DI GOIMNA o ZOLDO ALTO',
      },
      {
        value: 'I347',
        label: 'SAN TOMASO o SAN TOMASO AGORDINO',
      },
      {
        value: 'I392',
        label: 'SAN VITO o SAN VITO DI CADORE',
      },
      {
        value: 'I206',
        label: 'SANTA GIUSTINA',
      },
      {
        value: 'I421',
        label: 'SAPPADA o SAPPADA',
      },
      {
        value: 'I563',
        label: 'SEDICO',
      },
      {
        value: 'I592',
        label: 'SELVA BELLUNESE o SELVA DI CADORE',
      },
      {
        value: 'I626',
        label: 'SEREN o SEREN DEL GRAPPA',
      },
      {
        value: 'I673',
        label: 'SERVO o SOVRAMONTE',
      },
      {
        value: 'I866',
        label: 'SOSPIROLO',
      },
      {
        value: 'I876',
        label: 'SOVERZENE',
      },
      {
        value: 'L030',
        label: 'TAIBON o TAIBON AGORDINO',
      },
      {
        value: 'L040',
        label: 'TAMBRE',
      },
      {
        value: 'L422',
        label: 'TRICHIANA',
      },
      {
        value: 'M374',
        label: 'VAL DI ZOLDO',
      },
      {
        value: 'L584',
        label: 'VALLADA o VALLADA AGORDINA',
      },
      {
        value: 'L590',
        label: 'VALLE o VALLE DI CADORE',
      },
      {
        value: 'L692',
        label: 'VAS',
      },
      {
        value: 'L890',
        label: 'VIGO o VIGO DI CADORE',
      },
      {
        value: 'M108',
        label: 'VODO CADORE o VODO DI CADORE',
      },
      {
        value: 'M124',
        label: 'VOLTAGO o VOLTAGO AGORDINO',
      },
      {
        value: 'M189',
        label: "ZOPPE' o ZOPPE' DI CADORE",
      },
    ],
  },

  {
    value: 'BN',
    label: 'Benevento',
    children: [
      {
        value: 'A110',
        label: 'AIROLA',
      },
      {
        value: 'A265',
        label: 'AMOROSI',
      },
      {
        value: 'A328',
        label: 'APICE',
      },
      {
        value: 'A330',
        label: 'APOLLOSA',
      },
      {
        value: 'A431',
        label: 'ARPAIA',
      },
      {
        value: 'A432',
        label: 'ARPAISE',
      },
      {
        value: 'A549',
        label: 'BAGNARA o BAGNARA DI BENEVENTO',
      },
      {
        value: 'A696',
        label: 'BASELICE',
      },
      {
        value: 'A783',
        label: 'BENEVENTO',
      },
      {
        value: 'A970',
        label: 'BONEA',
      },
      {
        value: 'B239',
        label: 'BUCCIANO',
      },
      {
        value: 'B267',
        label: 'BUONALBERGO',
      },
      {
        value: 'B444',
        label: 'CALVI',
      },
      {
        value: 'B449',
        label: 'CALVI SAN NAZZARO o SAN NAZZARO CALVI',
      },
      {
        value: 'B541',
        label: 'CAMPOLATTARO',
      },
      {
        value: 'B542',
        label: 'CAMPOLI o CAMPOLI DEL MONTE TABURNO',
      },
      {
        value: 'B873',
        label: 'CASALDUNI',
      },
      {
        value: 'C106',
        label: 'CASTELFRANCO o CASTELFRANCO IN MISCANO',
      },
      {
        value: 'C245',
        label: 'CASTELPAGANO',
      },
      {
        value: 'C250',
        label: 'CASTELPOTO',
      },
      {
        value: 'C280',
        label: 'CASTELVENERE',
      },
      {
        value: 'C284',
        label: 'CASTELVETERE o CASTELVETERE IN VAL FORTORE',
      },
      {
        value: 'C359',
        label: 'CAUTANO',
      },
      {
        value: 'C476',
        label: 'CEPPALONI',
      },
      {
        value: 'C525',
        label: 'CERRETO o CERRETO SANNITA',
      },
      {
        value: 'C719',
        label: 'CIRCELLO',
      },
      {
        value: 'C775',
        label: 'CIVITELLA o CIVITELLA LICINIO',
      },
      {
        value: 'C846',
        label: 'COLLE o COLLE SANNITA',
      },
      {
        value: 'D230',
        label: 'CUSANO o CUSANO MUTRI',
      },
      {
        value: 'D380',
        label: 'DUGENTA',
      },
      {
        value: 'D386',
        label: 'DURAZZANO',
      },
      {
        value: 'D469',
        label: 'FAICCHIO',
      },
      {
        value: 'D644',
        label: 'FOGLIANISE',
      },
      {
        value: 'D650',
        label: 'FOIANO o FOIANO DI VAL FORTORE',
      },
      {
        value: 'D693',
        label: 'FORCHIA',
      },
      {
        value: 'D755',
        label: "FRAGNETO L'ABATE",
      },
      {
        value: 'D756',
        label: 'FRAGNETO MONFORTE',
      },
      {
        value: 'D784',
        label: 'FRASSO o FRASSO TELESINO',
      },
      {
        value: 'E034',
        label: 'GINESTRA DEGLI SCHIAVONI',
      },
      {
        value: 'E249',
        label: 'GUARDIA SANFRAMONDI',
      },
      {
        value: 'E589',
        label: 'LIMATOLA',
      },
      {
        value: 'E771',
        label: 'LUZZANO',
      },
      {
        value: 'F113',
        label: 'MELIZZANO',
      },
      {
        value: 'F274',
        label: 'MOIANO',
      },
      {
        value: 'F287',
        label: 'MOLINARA',
      },
      {
        value: 'F494',
        label: 'MONTEFALCONE o MONTEFALCONE DI VAL FORTORE',
      },
      {
        value: 'F636',
        label: 'MONTESARCHIO',
      },
      {
        value: 'F695',
        label: 'MONTORSO',
      },
      {
        value: 'F717',
        label: 'MORCONE',
      },
      {
        value: 'G227',
        label: 'PADULI',
      },
      {
        value: 'G243',
        label: 'PAGO o PAGO VEIANO',
      },
      {
        value: 'G311',
        label: 'PANNARANO',
      },
      {
        value: 'G318',
        label: 'PAOLISI',
      },
      {
        value: 'G363',
        label: 'PASTENE',
      },
      {
        value: 'G386',
        label: 'PAUPISI',
      },
      {
        value: 'G466',
        label: 'PERRILLO',
      },
      {
        value: 'G494',
        label: 'PESCO SANNITA o PESCOLAMAZZA',
      },
      {
        value: 'G626',
        label: 'PIETRAROJA',
      },
      {
        value: 'G631',
        label: 'PIETRELCINA',
      },
      {
        value: 'G827',
        label: 'PONTE',
      },
      {
        value: 'G848',
        label: 'PONTELANDOLFO',
      },
      {
        value: 'H087',
        label: 'PUGLIANELLO',
      },
      {
        value: 'H227',
        label: 'REINO',
      },
      {
        value: 'H764',
        label: 'SAN BARTOLOMEO IN GALDO',
      },
      {
        value: 'H894',
        label: 'SAN GIORGIO DEL SANNIO o SAN GIORGIO LA MONTAGNA',
      },
      {
        value: 'H898',
        label: 'SAN GIORGIO LA MOLARA',
      },
      {
        value: 'H953',
        label: 'SAN LEUCIO o SAN LEUCIO DEL SANNIO',
      },
      {
        value: 'H955',
        label: 'SAN LORENZELLO',
      },
      {
        value: 'H967',
        label: 'SAN LORENZO MAGGIORE',
      },
      {
        value: 'H973',
        label: 'SAN LUPO',
      },
      {
        value: 'H983',
        label: 'SAN MARCO A MONTI',
      },
      {
        value: 'H984',
        label: 'SAN MARCO DEI CAVOTI',
      },
      {
        value: 'I002',
        label: 'SAN MARTINO AVE GRATIA PLOENA o SAN MARTINO SANNITA',
      },
      {
        value: 'I049',
        label: 'SAN NAZZARO',
      },
      {
        value: 'I062',
        label: 'SAN NICOLA MANFREDI',
      },
      {
        value: 'I145',
        label: 'SAN SALVATORE o SAN SALVATORE TELESINO',
      },
      {
        value: 'I197',
        label: "SANT'AGATA DE' GOTI",
      },
      {
        value: 'I277',
        label: "SANT'ANGELO A CUPOLO",
      },
      {
        value: 'I179',
        label: 'SANTA CROCE DEL SANNIO o SANTA CROCE DI MORCONE',
      },
      {
        value: 'I455',
        label: 'SASSINORO',
      },
      {
        value: 'I809',
        label: 'SOLOPACA',
      },
      {
        value: 'L086',
        label: 'TELESE o TELESE TERME',
      },
      {
        value: 'L185',
        label: 'TOCCO o TOCCO CAUDIO',
      },
      {
        value: 'L254',
        label: 'TORRECUSO',
      },
      {
        value: 'L688',
        label: 'VARONI',
      },
      {
        value: 'M093',
        label: 'VITULANO',
      },
    ],
  },

  {
    value: 'BO',
    label: 'Bologna',
    children: [
      {
        value: 'M369',
        label: 'ALTO RENO TERME',
      },
      {
        value: 'A324',
        label: "ANZOLA o ANZOLA DELL'EMILIA",
      },
      {
        value: 'A392',
        label: 'ARGELATO',
      },
      {
        value: 'A558',
        label: 'BAGNI DELLA PORRETTA o PORRETTA o PORRETTA TERME',
      },
      {
        value: 'A665',
        label: 'BARICELLA',
      },
      {
        value: 'A726',
        label: 'BAZZANO',
      },
      {
        value: 'A771',
        label: 'BELVEDERE o LIZZANO IN BELVEDERE',
      },
      {
        value: 'A785',
        label: 'BENTIVOGLIO o SANTA MARIA IN DUNO',
      },
      {
        value: 'A944',
        label: 'BOLOGNA',
      },
      {
        value: 'B027',
        label: 'BORGO PANIGALE',
      },
      {
        value: 'B044',
        label: 'BORGO TOSSIGNANO o TOSSIGNANO',
      },
      {
        value: 'B249',
        label: 'BUDRIO',
      },
      {
        value: 'B398',
        label: 'CALDERARA o CALDAROLA',
      },
      {
        value: 'B399',
        label: 'CALDERARA DI RENO o CALDES',
      },
      {
        value: 'B572',
        label: 'CAMUGNANO',
      },
      {
        value: 'B689',
        label: 'CAPRARA SOPRA PANICO o MARZABOTTO',
      },
      {
        value: 'B880',
        label: 'CASALECCHIO DI RENO',
      },
      {
        value: 'B892',
        label: 'CASALFIUMANESE',
      },
      {
        value: 'B969',
        label: 'CASIO E CASOLA o CASTEL DI CASIO',
      },
      {
        value: 'C075',
        label: "CASTEL D'AIANO",
      },
      {
        value: 'C086',
        label: 'CASTEL DEL RIO',
      },
      {
        value: 'C121',
        label: 'CASTEL GUELFO o CASTEL GUELFO DI BOLOGNA',
      },
      {
        value: 'C204',
        label: 'CASTEL MAGGIORE',
      },
      {
        value: 'C265',
        label:
          "CASTEL SAN PIETRO o CASTEL SAN PIETRO NELL'EMILIA o CASTEL SAN PIETRO TERME",
      },
      {
        value: 'C107',
        label: "CASTELFRANCO o CASTELFRANCO EMILIA o CASTELFRANCO NELL'EMILIA",
      },
      {
        value: 'C185',
        label: "CASTELLO D'ARGILE",
      },
      {
        value: 'C191',
        label: 'CASTELLO DI SERRAVALLE o SERRAVALLE',
      },
      {
        value: 'C292',
        label: 'CASTENASO',
      },
      {
        value: 'C296',
        label: 'CASTIGLIONE o CASTIGLIONE DEI PEPOLI',
      },
      {
        value: 'D158',
        label: 'CRESPELLANO',
      },
      {
        value: 'D166',
        label: 'CREVALCORE',
      },
      {
        value: 'D360',
        label: 'DOZZA',
      },
      {
        value: 'D668',
        label: 'FONTANA ELICE o FONTANELICE o FONTANA',
      },
      {
        value: 'D847',
        label: 'GAGGIO MONTANO',
      },
      {
        value: 'D878',
        label: 'GALLIERA',
      },
      {
        value: 'E135',
        label: 'GRANAGLIONE',
      },
      {
        value: 'E136',
        label: "GRANAROLO DELL'EMILIA o VIADAGOLA",
      },
      {
        value: 'E187',
        label: 'GRIZZANA o GRIZZANA MORANDI o TAVERNOLA o TAVERNOLA RENO',
      },
      {
        value: 'E289',
        label: 'IMOLA',
      },
      {
        value: 'E655',
        label: 'LOIANO',
      },
      {
        value: 'E844',
        label: 'MALALBERGO',
      },
      {
        value: 'F083',
        label: 'MEDICINA',
      },
      {
        value: 'F219',
        label: 'MINERBIO',
      },
      {
        value: 'F288',
        label: 'MOLINELLA',
      },
      {
        value: 'F363',
        label: 'MONGHIDORO',
      },
      {
        value: 'F627',
        label: 'MONTE SAN PIETRO',
      },
      {
        value: 'F597',
        label: 'MONTERENZIO',
      },
      {
        value: 'F659',
        label: 'MONTEVEGLIO',
      },
      {
        value: 'F706',
        label: 'MONZUNO',
      },
      {
        value: 'F718',
        label: 'MORDANO',
      },
      {
        value: 'F824',
        label: 'MUSIANO',
      },
      {
        value: 'G205',
        label: "OZZANO o OZZANO DELL'EMILIA",
      },
      {
        value: 'G467',
        label: 'PERSICETO o SAN GIOVANNI IN PERSICETO',
      },
      {
        value: 'G566',
        label: 'PIANO o PIANO DEL VOGLIO o SAN BENEDETTO VAL DI SAMBRO',
      },
      {
        value: 'G570',
        label: 'PIANORO',
      },
      {
        value: 'G643',
        label: 'PIEVE DI CENTO',
      },
      {
        value: 'G972',
        label: 'PRADURO E SASSO o SASSO BOLOGNESE o SASSO MARCONI',
      },
      {
        value: 'H678',
        label: 'SALA o SALA BOLOGNESE',
      },
      {
        value: 'H896',
        label: 'SAN GIORGIO DI PIANO',
      },
      {
        value: 'H945',
        label: 'SAN LAZZARO o SAN LAZZARO DI SAVENA',
      },
      {
        value: 'I110',
        label: 'SAN PIETRO IN CASALE',
      },
      {
        value: 'I191',
        label: "SANT'AGATA o SANT'AGATA BOLOGNESE",
      },
      {
        value: 'I474',
        label: 'SAVIGNO',
      },
      {
        value: 'M320',
        label: 'VALSAMOGGIA',
      },
      {
        value: 'L762',
        label: 'VERGATO',
      },
      {
        value: 'M185',
        label: 'ZOLA PREDOSA',
      },
    ],
  },

  {
    value: 'BR',
    label: 'Brindisi',
    children: [
      {
        value: 'B180',
        label: 'BRINDISI',
      },
      {
        value: 'B809',
        label: 'CAROVIGNO',
      },
      {
        value: 'C424',
        label: 'CEGLIE o CEGLIE MESSAPICA o CEGLIE MESSAPICO',
      },
      {
        value: 'C448',
        label: 'CELLINO o CELLINO SAN MARCO',
      },
      {
        value: 'C741',
        label: 'CISTERNINO',
      },
      {
        value: 'D422',
        label: 'ERCHIE',
      },
      {
        value: 'D508',
        label: 'FASANO',
      },
      {
        value: 'D761',
        label: 'FRANCAVILLA o FRANCAVILLA FONTANA',
      },
      {
        value: 'E471',
        label: 'LATIANO',
      },
      {
        value: 'F152',
        label: 'MESAGNE',
      },
      {
        value: 'G098',
        label: 'ORIA',
      },
      {
        value: 'G187',
        label: 'OSTUNI',
      },
      {
        value: 'H822',
        label: 'SAN DONACI',
      },
      {
        value: 'I045',
        label: 'SAN MICHELE SALENTINO',
      },
      {
        value: 'I066',
        label: 'SAN PANCRAZIO SALENTINO o SAN PANCRAZIO',
      },
      {
        value: 'I119',
        label: 'SAN PIETRO VERNOTICO',
      },
      {
        value: 'I396',
        label: 'SAN VITO DEI NORMANNI o SAN VITO',
      },
      {
        value: 'L213',
        label: 'TORCHIAROLO',
      },
      {
        value: 'L280',
        label: 'TORRE SANTA SUSANNA',
      },
      {
        value: 'L920',
        label: 'VILLA CASTELLI',
      },
    ],
  },

  {
    value: 'BS',
    label: 'Brescia',
    children: [
      {
        value: 'A034',
        label: 'ACQUAFREDDA',
      },
      {
        value: 'A036',
        label: 'ACQUALUNGA',
      },
      {
        value: 'A038',
        label: 'ACQUANEGRA o ACQUANEGRA SUL CHIESE',
      },
      {
        value: 'A060',
        label: 'ADRO',
      },
      {
        value: 'A082',
        label: 'AGNOSINE',
      },
      {
        value: 'A188',
        label: 'ALFIANELLO',
      },
      {
        value: 'A219',
        label: 'ALONE',
      },
      {
        value: 'A288',
        label: 'ANFO',
      },
      {
        value: 'A289',
        label: 'ANFURRO',
      },
      {
        value: 'A293',
        label: 'ANGOLO o ANGOLO TERME',
      },
      {
        value: 'A417',
        label: 'ARMO',
      },
      {
        value: 'A451',
        label: 'ARTOGNE',
      },
      {
        value: 'A510',
        label: 'AVENONE',
      },
      {
        value: 'A529',
        label: 'AZZANO o AZZANO MELLA',
      },
      {
        value: 'A569',
        label: 'BAGNOLO o BAGNOLO MELLA',
      },
      {
        value: 'A578',
        label: 'BAGOLINO',
      },
      {
        value: 'A630',
        label: 'BARBARIGA',
      },
      {
        value: 'A641',
        label: 'BARCO',
      },
      {
        value: 'A661',
        label: 'BARGHE',
      },
      {
        value: 'A702',
        label: 'BASSANO o BASSANO BRESCIANO',
      },
      {
        value: 'A729',
        label: 'BEDIZZOLE',
      },
      {
        value: 'A767',
        label: 'BEL PRATO o PRATO',
      },
      {
        value: 'A799',
        label: 'BERLINGO',
      },
      {
        value: 'A816',
        label: 'BERZO DEMO',
      },
      {
        value: 'A817',
        label: 'BERZO INFERIORE',
      },
      {
        value: 'A861',
        label: 'BIENNO',
      },
      {
        value: 'A878',
        label: 'BIONE',
      },
      {
        value: 'B035',
        label: 'BORGO SAN GIACOMO o GABBIANO',
      },
      {
        value: 'B022',
        label: 'BORGONATO',
      },
      {
        value: 'B040',
        label: 'BORGOSATOLLO',
      },
      {
        value: 'B053',
        label: 'BORNATO',
      },
      {
        value: 'B054',
        label: 'BORNO',
      },
      {
        value: 'B091',
        label: 'BOTTICINO',
      },
      {
        value: 'B092',
        label: 'BOTTICINO MATTINA',
      },
      {
        value: 'B093',
        label: 'BOTTICINO SERA',
      },
      {
        value: 'B100',
        label: 'BOVEGNO',
      },
      {
        value: 'B102',
        label: 'BOVEZZO',
      },
      {
        value: 'B120',
        label: 'BRANDICO',
      },
      {
        value: 'B124',
        label: 'BRAONE',
      },
      {
        value: 'B149',
        label: 'BRENO',
      },
      {
        value: 'B157',
        label: 'BRESCIA',
      },
      {
        value: 'B184',
        label: 'BRIONE',
      },
      {
        value: 'B211',
        label: 'BROZZO',
      },
      {
        value: 'B271',
        label: 'BURAGO o BURAGO RIVIERA',
      },
      {
        value: 'B316',
        label: 'CACCAVERO o CAMPOVERDE',
      },
      {
        value: 'B343',
        label: 'CADIGNANO',
      },
      {
        value: 'B365',
        label: 'CAINO',
      },
      {
        value: 'B373',
        label: 'CAJONVICO',
      },
      {
        value: 'B413',
        label: 'CALINO o CALIMERA',
      },
      {
        value: 'B436',
        label: 'CALVAGESE o CALVAGESE DELLA RIVIERA',
      },
      {
        value: 'B450',
        label: 'CALVISANO',
      },
      {
        value: 'B478',
        label: 'CAMIGNONE',
      },
      {
        value: 'B612',
        label: "CANNETO o CANNETO SULL'OGLIO",
      },
      {
        value: 'B664',
        label: 'CAPO DI PONTE',
      },
      {
        value: 'B676',
        label: 'CAPOVALLE o HANO',
      },
      {
        value: 'B698',
        label: 'CAPRIANO o CAPRIANO DEL COLLE',
      },
      {
        value: 'B700',
        label: 'CAPRIANO-AZZANO',
      },
      {
        value: 'B711',
        label: 'CAPRIOLO',
      },
      {
        value: 'B751',
        label: 'CARCINA',
      },
      {
        value: 'B817',
        label: 'CARPENEDOLO',
      },
      {
        value: 'B855',
        label: 'CARZAGO o CARZAGO DELLA RIVIERA',
      },
      {
        value: 'C055',
        label: 'CASTEGNATO',
      },
      {
        value: 'C208',
        label: 'CASTEL MELLA o CASTELNUOVO',
      },
      {
        value: 'C072',
        label: 'CASTELCOVATI',
      },
      {
        value: 'C293',
        label: 'CASTENEDOLO',
      },
      {
        value: 'C330',
        label: 'CASTO',
      },
      {
        value: 'C332',
        label: 'CASTREZZATO',
      },
      {
        value: 'C333',
        label: 'CASTREZZONE',
      },
      {
        value: 'C408',
        label: 'CAZZAGO o CAZZAGO SAN MARTINO',
      },
      {
        value: 'C417',
        label: 'CEDEGOLO o GREVO',
      },
      {
        value: 'C439',
        label: 'CELLATICA',
      },
      {
        value: 'C549',
        label: 'CERVENO',
      },
      {
        value: 'C585',
        label: 'CETO',
      },
      {
        value: 'C586',
        label: 'CETO-CERVENO',
      },
      {
        value: 'C591',
        label: 'CEVO',
      },
      {
        value: 'C618',
        label: 'CHIARI',
      },
      {
        value: 'C682',
        label: 'CIGNANO',
      },
      {
        value: 'C685',
        label: 'CIGOLE',
      },
      {
        value: 'C687',
        label: 'CILIVERGHE',
      },
      {
        value: 'C691',
        label: 'CIMBERGO',
      },
      {
        value: 'C692',
        label: 'CIMBERGO-PASPARDO',
      },
      {
        value: 'C698',
        label: 'CIMMO o TAVERNOLE SUL MELLA o TAVERNOLE-CIMMO',
      },
      {
        value: 'C760',
        label: 'CIVIDATE CAMUNO o CIVITADE o CIVITADE ALPINO',
      },
      {
        value: 'C761',
        label: 'CIVIDATE-MALEGNO',
      },
      {
        value: 'C786',
        label: 'CIZZAGO',
      },
      {
        value: 'C798',
        label: 'CLUSANE o CLUSANE SUL LAGO',
      },
      {
        value: 'C806',
        label: 'COCCAGLIO',
      },
      {
        value: 'C850',
        label: 'COLLEBEATO',
      },
      {
        value: 'C883',
        label: 'COLLIO',
      },
      {
        value: 'C893',
        label: 'COLOGNE',
      },
      {
        value: 'C898',
        label: 'COLOMBARO',
      },
      {
        value: 'C923',
        label: 'COMERO',
      },
      {
        value: 'C924',
        label: 'COMEZZANO',
      },
      {
        value: 'C925',
        label: 'COMEZZANO-CIZZAGO',
      },
      {
        value: 'C948',
        label: 'CONCESIO',
      },
      {
        value: 'D058',
        label: 'CORTE FRANCA',
      },
      {
        value: 'D063',
        label: 'CORTENEDOLO',
      },
      {
        value: 'D064',
        label: 'CORTENO o CORTENO GOLGI',
      },
      {
        value: 'D073',
        label: 'CORTICELLE o CORTICELLE PIEVE',
      },
      {
        value: 'D082',
        label: 'CORZANO',
      },
      {
        value: 'D098',
        label: 'COSSIRANO',
      },
      {
        value: 'D146',
        label: 'CREMEZZANO',
      },
      {
        value: 'D251',
        label: 'DARFO o DARFO BOARIO TERME',
      },
      {
        value: 'D262',
        label: 'DEGAGNA',
      },
      {
        value: 'D270',
        label: 'DELLO',
      },
      {
        value: 'D284',
        label: 'DESENZANO o DESENZANO DEL GARDA o DESENZANO SUL LAGO',
      },
      {
        value: 'D391',
        label: 'EDOLO',
      },
      {
        value: 'D418',
        label: 'ERBANNO',
      },
      {
        value: 'D421',
        label: 'ERBUSCO',
      },
      {
        value: 'D434',
        label: 'ESINE',
      },
      {
        value: 'D498',
        label: 'FARFENGO',
      },
      {
        value: 'D517',
        label: 'FAVERZANO',
      },
      {
        value: 'D576',
        label: 'FIESSE',
      },
      {
        value: 'D626',
        label: 'FIUMICELLO o FIUMICELLO URAGO',
      },
      {
        value: 'D634',
        label: 'FLERO',
      },
      {
        value: 'D658',
        label: 'FOLZANO',
      },
      {
        value: 'D806',
        label: 'FRONTIGNANO',
      },
      {
        value: 'D891',
        label: 'GAMBARA',
      },
      {
        value: 'D917',
        label: 'GARDONE RIVIERA',
      },
      {
        value: 'D918',
        label: 'GARDONE VAL TROMPIA',
      },
      {
        value: 'D924',
        label: 'GARGNANO',
      },
      {
        value: 'D940',
        label: 'GAVARDO',
      },
      {
        value: 'D989',
        label: 'GEROLA o GEROLANUOVA',
      },
      {
        value: 'D999',
        label: 'GHEDI',
      },
      {
        value: 'E010',
        label: 'GIANICO',
      },
      {
        value: 'E075',
        label: 'GOGLIONE SOPRA',
      },
      {
        value: 'E076',
        label: 'GOGLIONE SOTTO',
      },
      {
        value: 'E112',
        label: 'GORZONE',
      },
      {
        value: 'E116',
        label: 'GOTTOLENGO',
      },
      {
        value: 'E271',
        label: 'GUSSAGO',
      },
      {
        value: 'E280',
        label: 'IDRO',
      },
      {
        value: 'E297',
        label: 'INCUDINE',
      },
      {
        value: 'E318',
        label: 'INZINO',
      },
      {
        value: 'E325',
        label: 'IRMA',
      },
      {
        value: 'E333',
        label: 'ISEO',
      },
      {
        value: 'E364',
        label: 'ISORELLA',
      },
      {
        value: 'E497',
        label: 'LAVENONE',
      },
      {
        value: 'E526',
        label: 'LENO',
      },
      {
        value: 'E567',
        label: 'LEVRANGE',
      },
      {
        value: 'E596',
        label: 'LIMONE o LIMONE DI SAN GIOVANNI o LIMONE SUL GARDA',
      },
      {
        value: 'E619',
        label: 'LIVEMMO',
      },
      {
        value: 'E652',
        label: 'LODRINO',
      },
      {
        value: 'E654',
        label: 'LOGRATO',
      },
      {
        value: 'E667',
        label: 'LONATO',
      },
      {
        value: 'M312',
        label: 'LONATO DEL GARDA',
      },
      {
        value: 'E673',
        label: 'LONGHENA',
      },
      {
        value: 'E698',
        label: 'LOSINE',
      },
      {
        value: 'E701',
        label: 'LOVENO o LOVENO GRUMELLO',
      },
      {
        value: 'E706',
        label: 'LOZIO',
      },
      {
        value: 'E725',
        label: 'LUDRIANO',
      },
      {
        value: 'E738',
        label: 'LUMEZZANE',
      },
      {
        value: 'E739',
        label: 'LUMEZZANE PIEVE',
      },
      {
        value: 'E740',
        label: 'LUMEZZANE SAN SEBASTIANO',
      },
      {
        value: 'E741',
        label: "LUMEZZANE SANT'APOLLONIO",
      },
      {
        value: 'E787',
        label: 'MACLODIO',
      },
      {
        value: 'E792',
        label: 'MADERNO',
      },
      {
        value: 'E800',
        label: 'MAGASA',
      },
      {
        value: 'E823',
        label: 'MAGNO SOPRA INZINO',
      },
      {
        value: 'E841',
        label: 'MAIRANO',
      },
      {
        value: 'E851',
        label: 'MALEGNO',
      },
      {
        value: 'E865',
        label: 'MALONNO',
      },
      {
        value: 'E883',
        label: 'MANERBA o MANERBA DEL GARDA',
      },
      {
        value: 'E884',
        label: 'MANERBIO',
      },
      {
        value: 'E928',
        label: 'MARCHENO',
      },
      {
        value: 'E961',
        label: 'MARMENTINO',
      },
      {
        value: 'E967',
        label: 'MARONE',
      },
      {
        value: 'F063',
        label: 'MAZZANO',
      },
      {
        value: 'F072',
        label: 'MAZZUNNO',
      },
      {
        value: 'F215',
        label: 'MILZANELLO',
      },
      {
        value: 'F216',
        label: 'MILZANO',
      },
      {
        value: 'F321',
        label: 'MOMPIANO',
      },
      {
        value: 'F373',
        label: 'MONIGA o MONIGA DEL GARDA',
      },
      {
        value: 'F375',
        label: 'MONNO',
      },
      {
        value: 'F532',
        label: 'MONTE ISOLA',
      },
      {
        value: 'F471',
        label: 'MONTECHIARO o MONTECHIARO SUL CHIESE o MONTICHIARI',
      },
      {
        value: 'F672',
        label: 'MONTICELLI BRUSATI',
      },
      {
        value: 'F680',
        label: 'MONTIRONE',
      },
      {
        value: 'F792',
        label: 'MU',
      },
      {
        value: 'F806',
        label: 'MURA',
      },
      {
        value: 'F820',
        label: 'MUSCOLINE',
      },
      {
        value: 'F851',
        label: 'NAVE',
      },
      {
        value: 'F854',
        label: 'NAVONO',
      },
      {
        value: 'F884',
        label: 'NIARDO',
      },
      {
        value: 'F896',
        label: 'NIGOLINE o NIGOLINE-BONOMELLI',
      },
      {
        value: 'F971',
        label: 'NOZZA',
      },
      {
        value: 'F989',
        label: 'NUVOLENTO',
      },
      {
        value: 'F990',
        label: 'NUVOLERA',
      },
      {
        value: 'G001',
        label: 'ODOLO',
      },
      {
        value: 'G006',
        label: 'OFFLAGA',
      },
      {
        value: 'G061',
        label: 'OME',
      },
      {
        value: 'G073',
        label: 'ONO o ONO DEGNO',
      },
      {
        value: 'G074',
        label: 'ONO o ONO SAN PIETRO',
      },
      {
        value: 'G099',
        label: 'ORIANO',
      },
      {
        value: 'G149',
        label: 'ORZINUOVI',
      },
      {
        value: 'G150',
        label: 'ORZIVECCHI',
      },
      {
        value: 'G170',
        label: 'OSPITALETTO',
      },
      {
        value: 'G179',
        label: 'OSSIMO',
      },
      {
        value: 'G213',
        label: 'PADENGHE o PADENGHE SUL GARDA',
      },
      {
        value: 'G216',
        label: 'PADERNELLO',
      },
      {
        value: 'G217',
        label: 'PADERNO o PADERNO FRANCIACORTA',
      },
      {
        value: 'G246',
        label: 'PAISCO',
      },
      {
        value: 'G247',
        label: 'PAISCO LOVENO',
      },
      {
        value: 'G248',
        label: 'PAITONE',
      },
      {
        value: 'G264',
        label: "PALAZZOLO o PALAZZOLO SULL'OGLIO",
      },
      {
        value: 'G327',
        label: 'PARATICO',
      },
      {
        value: 'G354',
        label: 'PASPARDO',
      },
      {
        value: 'G361',
        label: 'PASSIRANO',
      },
      {
        value: 'G391',
        label: 'PAVONE o PAVONE DEL MELLA',
      },
      {
        value: 'G407',
        label: 'PEDERGNAGA o PEDERGNAGA-ORIANO o SAN PAOLO',
      },
      {
        value: 'G474',
        label: 'PERTICA ALTA',
      },
      {
        value: 'G475',
        label: 'PERTICA BASSA',
      },
      {
        value: 'G490',
        label: "PESCHIERA D'ISEO o PESCHIERA MARAGLIO",
      },
      {
        value: 'G529',
        label: 'PEZZAZE',
      },
      {
        value: 'G533',
        label: 'PEZZORO',
      },
      {
        value: 'G546',
        label: 'PIAN CAMUNO o PIANO',
      },
      {
        value: 'G550',
        label: "PIAN D'ARTOGNE",
      },
      {
        value: 'G549',
        label: 'PIANCOGNO',
      },
      {
        value: 'G668',
        label: 'PILZONE',
      },
      {
        value: 'G710',
        label: 'PISOGNE',
      },
      {
        value: 'G779',
        label: 'POLAVENO',
      },
      {
        value: 'G801',
        label: 'POLPENAZZE DEL GARDA',
      },
      {
        value: 'G815',
        label: 'POMPIANO',
      },
      {
        value: 'G818',
        label: 'PONCARALE',
      },
      {
        value: 'G819',
        label: 'PONCARALE FLERO',
      },
      {
        value: 'G824',
        label: 'PONTAGNA',
      },
      {
        value: 'G844',
        label: 'PONTE DI LEGNO',
      },
      {
        value: 'G859',
        label: 'PONTEVICO',
      },
      {
        value: 'G869',
        label: 'PONTOGLIO',
      },
      {
        value: 'G901',
        label: 'PORTESE',
      },
      {
        value: 'G928',
        label: 'PORZANO',
      },
      {
        value: 'G959',
        label: 'POZZOLENGO',
      },
      {
        value: 'G977',
        label: 'PRALBOINO',
      },
      {
        value: 'G983',
        label: 'PRANDAGLIO',
      },
      {
        value: 'H043',
        label: 'PRESEGLIE',
      },
      {
        value: 'H044',
        label: 'PRESEGNO',
      },
      {
        value: 'H050',
        label: 'PRESTINE',
      },
      {
        value: 'H055',
        label: 'PREVALLE',
      },
      {
        value: 'H077',
        label: 'PROVAGLIO o PROVAGLIO VAL SABBIA',
      },
      {
        value: 'H078',
        label: "PROVAGLIO D'ISEO",
      },
      {
        value: 'H079',
        label: 'PROVAGLIO SOPRA',
      },
      {
        value: 'H080',
        label: 'PROVAGLIO SOTTO',
      },
      {
        value: 'H082',
        label: 'PROVEZZE',
      },
      {
        value: 'H086',
        label: 'PUEGNAGO o PUEGNAGO DEL GARDA o PUEGNAGO SUL GARDA',
      },
      {
        value: 'H139',
        label: 'QUINZANELLO',
      },
      {
        value: 'H140',
        label: "QUINZANO o QUINZANO D'OGLIO",
      },
      {
        value: 'H158',
        label: 'RAFFA',
      },
      {
        value: 'H230',
        label: 'REMEDELLO',
      },
      {
        value: 'H231',
        label: 'REMEDELLO SOPRA',
      },
      {
        value: 'H232',
        label: 'REMEDELLO SOTTO',
      },
      {
        value: 'H256',
        label: 'REZZATO',
      },
      {
        value: 'H358',
        label: 'RIVOLTELLA SUL GARDA',
      },
      {
        value: 'H410',
        label: 'ROCCAFRANCA',
      },
      {
        value: 'H476',
        label: 'RODENGO',
      },
      {
        value: 'H477',
        label: 'RODENGO SAIANO o RODENGO-SAIANO',
      },
      {
        value: 'H484',
        label: "ROE' VOLCIANO o VOLCIANO",
      },
      {
        value: 'H525',
        label: 'RONCADELLE',
      },
      {
        value: 'H598',
        label: 'ROVATO',
      },
      {
        value: 'H630',
        label: 'RUDIANO',
      },
      {
        value: 'H650',
        label: 'SABBIO o SABBIO CHIESE',
      },
      {
        value: 'H668',
        label: 'SAIANO',
      },
      {
        value: 'H699',
        label: 'SALE MARASINO',
      },
      {
        value: 'H717',
        label: "SALO'",
      },
      {
        value: 'H759',
        label: 'SAN BARTOLOMEO',
      },
      {
        value: 'H832',
        label: 'SAN FELICE o SAN FELICE DI SCOVOLO',
      },
      {
        value: 'H838',
        label: 'SAN FELICE DEL BENACO',
      },
      {
        value: 'H865',
        label: 'SAN GERVASIO o SAN GERVASIO BRESCIANO',
      },
      {
        value: 'I050',
        label: 'SAN NAZZARO o SAN NAZZARO MELLA',
      },
      {
        value: 'I385',
        label: 'SAN VIGILIO',
      },
      {
        value: 'I412',
        label: 'SAN ZENO o SAN ZENO NAVIGLIO',
      },
      {
        value: 'I212',
        label: "SANT'ALESSANDRO",
      },
      {
        value: 'I186',
        label: "SANT'EUFEMIA o SANT'EUFEMIA DELLA FONTE o SANTA EUFEMIA",
      },
      {
        value: 'I338',
        label: 'SANTICOLO',
      },
      {
        value: 'I433',
        label: 'SAREZZO',
      },
      {
        value: 'I476',
        label: "SAVIORE o SAVIORE DELL'ADAMELLO",
      },
      {
        value: 'I515',
        label: 'SCARPIZZOLO',
      },
      {
        value: 'I588',
        label: 'SELLERO',
      },
      {
        value: 'I607',
        label: 'SENIGA',
      },
      {
        value: 'I631',
        label: 'SERLE',
      },
      {
        value: 'I633',
        label: 'SERMIONE o SIRMIONE',
      },
      {
        value: 'I766',
        label: 'SIVIANO',
      },
      {
        value: 'I782',
        label: 'SOIANO DEL LAGO',
      },
      {
        value: 'I831',
        label: 'SONICO',
      },
      {
        value: 'I836',
        label: 'SOPRAPONTE',
      },
      {
        value: 'I837',
        label: 'SOPRAZOCCO',
      },
      {
        value: 'L002',
        label: 'SULZANO',
      },
      {
        value: 'L094',
        label: "TEMU'",
      },
      {
        value: 'L141',
        label: 'TERZANO',
      },
      {
        value: 'L169',
        label: 'TIGNALE',
      },
      {
        value: 'L170',
        label: 'TIMOLINE',
      },
      {
        value: 'L209',
        label: 'TORBIATO',
      },
      {
        value: 'L210',
        label: 'TORBOLE o TORBOLE CASAGLIA',
      },
      {
        value: 'L311',
        label: 'TOSCOLANO',
      },
      {
        value: 'L312',
        label: 'TOSCOLANO-MADERNO',
      },
      {
        value: 'L339',
        label: 'TRAVAGLIATO',
      },
      {
        value: 'L372',
        label: 'TREMOSINE o TREMOSINE SUL GARDA',
      },
      {
        value: 'L380',
        label: 'TRENZANO',
      },
      {
        value: 'L406',
        label: 'TREVISO o TREVISO BRESCIANO',
      },
      {
        value: 'L468',
        label: 'TURANO o VALVESTINO',
      },
      {
        value: 'L494',
        label: "URAGO D'OGLIO",
      },
      {
        value: 'L495',
        label: 'URAGO MELLA',
      },
      {
        value: 'L626',
        label: 'VALLIO o VALLIO TERME',
      },
      {
        value: 'L648',
        label: 'VALSAVIORE',
      },
      {
        value: 'L721',
        label: 'VELLO',
      },
      {
        value: 'L777',
        label: 'VEROLANUOVA',
      },
      {
        value: 'L778',
        label: 'VEROLAVECCHIA',
      },
      {
        value: 'L812',
        label: 'VESTONE',
      },
      {
        value: 'L816',
        label: "VEZZA o VEZZA D'OGLIO",
      },
      {
        value: 'L919',
        label: 'VILLA CARCINA',
      },
      {
        value: 'L925',
        label: 'VILLA COGOZZO',
      },
      {
        value: 'L930',
        label: "VILLA D'ALLEGNO",
      },
      {
        value: 'L923',
        label: 'VILLACHIARA',
      },
      {
        value: 'L995',
        label: 'VILLANUOVA o VILLANUOVA SUL CLISI',
      },
      {
        value: 'M065',
        label: 'VIONE',
      },
      {
        value: 'M068',
        label: 'VIRLE o VIRLE TRE PONTI',
      },
      {
        value: 'M070',
        label: 'VISANO',
      },
      {
        value: 'M104',
        label: 'VOBARNO',
      },
      {
        value: 'M188',
        label: 'ZONE',
      },
    ],
  },

  {
    value: 'BT',
    label: 'Barletta-Andria-Trani',
    children: [
      {
        value: 'E946',
        label:
          'MARGHERITA DI SAVOIA o MARGHERITA DI SAVOIA o SALINE DI BARLETTA',
      },
      {
        value: 'H839',
        label:
          'SAN FERDINANDO DI PUGLIA o SAN FERDINANDO o SAN FERDINANDO DI PUGLIA',
      },
      {
        value: 'B915',
        label: 'TRINITAPOLI o CASALTRINITA o TRINITAPOLI',
      },
    ],
  },

  {
    value: 'BZ',
    label: 'Bolzano',
    children: [
      {
        value: 'A021',
        label: 'ACERETO',
      },
      {
        value: 'A022',
        label: 'ACERNES o CERMES',
      },
      {
        value: 'A151',
        label: 'ALBES',
      },
      {
        value: 'A179',
        label: 'ALDINO o VALDAGNO o VALDAGNO DI TRENTO',
      },
      {
        value: 'A209',
        label: 'ALLIZ',
      },
      {
        value: 'A286',
        label: 'ANDRIANO',
      },
      {
        value: 'A306',
        label: 'ANTERIVO',
      },
      {
        value: 'A307',
        label: 'ANTERSELVA',
      },
      {
        value: 'A332',
        label: 'APPIANO o APPIANO SULLA STRADA DEL VINO',
      },
      {
        value: 'A507',
        label: 'AVELENGO o AVELIGNA',
      },
      {
        value: 'A537',
        label: 'BADIA',
      },
      {
        value: 'A635',
        label: 'BARBIANO',
      },
      {
        value: 'A952',
        label: 'BOLZANO',
      },
      {
        value: 'B116',
        label: 'BRAIES',
      },
      {
        value: 'B145',
        label: 'BRENNERO',
      },
      {
        value: 'B160',
        label: 'BRESSANONE',
      },
      {
        value: 'B203',
        label: 'BRONZOLO',
      },
      {
        value: 'B220',
        label: 'BRUNICO',
      },
      {
        value: 'B277',
        label: 'BURGUSIO',
      },
      {
        value: 'B364',
        label: 'CAINES',
      },
      {
        value: 'B396',
        label: 'CALDARO o CALCO',
      },
      {
        value: 'B397',
        label: 'CALDARO SULLA STRADA DEL VINO o CALDAROLA',
      },
      {
        value: 'B480',
        label: 'CAMINATA DI TURES',
      },
      {
        value: 'B529',
        label: 'CAMPO DI TRENS',
      },
      {
        value: 'B570',
        label: 'CAMPO TURES',
      },
      {
        value: 'B799',
        label: "CARNEDO o CORNEDO o CORNEDO ALL'ISARCO",
      },
      {
        value: 'C061',
        label: 'CASTELBELLO',
      },
      {
        value: 'C062',
        label: 'CASTELBELLO CIARDES o CASTELBELLO-CIARDES',
      },
      {
        value: 'C077',
        label: 'CASTELDARNE o CASTELLO DI PUSTERIA',
      },
      {
        value: 'C254',
        label: 'CASTELROTTO',
      },
      {
        value: 'C401',
        label: 'CAVOLANO o COVELANO',
      },
      {
        value: 'C464',
        label: 'CENGLES',
      },
      {
        value: 'C590',
        label: 'CEVES o ZEDES',
      },
      {
        value: 'C625',
        label: 'CHIENES',
      },
      {
        value: 'C652',
        label: "CHIUSA o CHIUSA ALL'ISARCO",
      },
      {
        value: 'C671',
        label: 'CIARDES o CIARS',
      },
      {
        value: 'C737',
        label: 'CISSICO o ISSENGO',
      },
      {
        value: 'C799',
        label: 'CLUSIO',
      },
      {
        value: 'C833',
        label: 'COLDERANO o COLDRANO',
      },
      {
        value: 'C837',
        label: 'COLFOSCO',
      },
      {
        value: 'C861',
        label: 'COLLE IN CASIES',
      },
      {
        value: 'M306',
        label: 'COLLE ISARCO',
      },
      {
        value: 'C881',
        label: 'COLLI IN PUSTERIA',
      },
      {
        value: 'C907',
        label: 'COLSANO',
      },
      {
        value: 'D083',
        label: 'CORCES',
      },
      {
        value: 'D032',
        label: 'CORONA ALLA MUTA o CURON',
      },
      {
        value: 'D048',
        label: 'CORTACCIA o CORTACCIA SULLA STRADA DEL VINO',
      },
      {
        value: 'D059',
        label: 'CORTE IN PUSTERIA o CORTI IN PUSTERIA',
      },
      {
        value: 'D075',
        label: "CORTINA ALL'ADIGE o CORTINA SULLA STRADA DEL VINO",
      },
      {
        value: 'D079',
        label: 'CORVARA IN BADIA o LADINIA',
      },
      {
        value: 'D080',
        label: 'CORVARA IN PASSIRIA',
      },
      {
        value: 'D213',
        label: 'CURACES o QUARAZZE',
      },
      {
        value: 'D222',
        label: 'CURON VENOSTA',
      },
      {
        value: 'D311',
        label: 'DOBBIACO',
      },
      {
        value: 'D392',
        label: 'EGNA',
      },
      {
        value: 'D396',
        label: 'ELLE',
      },
      {
        value: 'D413',
        label: 'EORES',
      },
      {
        value: 'D484',
        label: 'FALZES',
      },
      {
        value: 'D519',
        label: 'FAOGNA DI SOTTO o FAVOGNA DI SOTTO',
      },
      {
        value: 'D571',
        label: "FIE' o FIE' ALLO SCILIAR",
      },
      {
        value: 'D633',
        label: 'FLERES',
      },
      {
        value: 'D648',
        label: 'FOIANA',
      },
      {
        value: 'D731',
        label: "FORTEZZA o MEZZASELVA o MEZZASELVA ALL'ISARCO",
      },
      {
        value: 'D820',
        label: 'FUNDOLES o FUNDRES',
      },
      {
        value: 'D821',
        label: 'FUNES',
      },
      {
        value: 'D860',
        label: 'GAIS',
      },
      {
        value: 'D923',
        label: 'GARGAZZONE',
      },
      {
        value: 'E069',
        label: 'GLORENZA',
      },
      {
        value: 'E174',
        label: 'GRIES o GRIES DI BOLZANO',
      },
      {
        value: 'E181',
        label: 'GRIMALDO o VILLA IN SELVA',
      },
      {
        value: 'E257',
        label: 'GUDON',
      },
      {
        value: 'E491',
        label: 'LA VALLE',
      },
      {
        value: 'E398',
        label: 'LACES',
      },
      {
        value: 'E399',
        label: 'LACIGNO o LACINIGO',
      },
      {
        value: 'E412',
        label: 'LAGUNDO',
      },
      {
        value: 'E420',
        label: 'LAION',
      },
      {
        value: 'E421',
        label: 'LAIVES',
      },
      {
        value: 'E434',
        label: 'LANA',
      },
      {
        value: 'E449',
        label: 'LAPPAGO',
      },
      {
        value: 'E457',
        label: 'LASA',
      },
      {
        value: 'E477',
        label: 'LAUDES',
      },
      {
        value: 'E481',
        label: 'LAUREGNO',
      },
      {
        value: 'E501',
        label: 'LAZFONS',
      },
      {
        value: 'E676',
        label: "LONGIARU'",
      },
      {
        value: 'E764',
        label: 'LUSON',
      },
      {
        value: 'E768',
        label: 'LUTAGO',
      },
      {
        value: 'E829',
        label: "MAGRE' ALL'ADIGE o MAGRE' SULLA STRADA DEL VINO",
      },
      {
        value: 'E831',
        label: 'MAIA ALTA',
      },
      {
        value: 'E832',
        label: 'MAIA BASSA',
      },
      {
        value: 'E861',
        label: 'MALLES',
      },
      {
        value: 'E862',
        label: 'MALLES VENOSTA',
      },
      {
        value: 'E895',
        label: 'MANTANA o MONTALE IN PUSTERIA',
      },
      {
        value: 'E916',
        label: 'MARANZA o MARANZA IN PUSTERIA',
      },
      {
        value: 'E938',
        label: 'MAREBBE',
      },
      {
        value: 'E943',
        label: 'MARETA',
      },
      {
        value: 'E959',
        label: 'MARLENGO',
      },
      {
        value: 'E981',
        label: 'MARTELLO',
      },
      {
        value: 'F062',
        label: 'MAZIA',
      },
      {
        value: 'F118',
        label: 'MELTINA',
      },
      {
        value: 'F132',
        label: 'MERANO',
      },
      {
        value: 'F211',
        label: 'MILLAN-SARNES',
      },
      {
        value: 'F291',
        label: 'MOLINI DI TURES',
      },
      {
        value: 'F362',
        label: 'MONGHEZZO DI FUORI o MONGHEZZO ESTERNO',
      },
      {
        value: 'F371',
        label: 'MONGUELFO o MONGUELFO-TESIDO',
      },
      {
        value: 'F392',
        label: 'MONTAGNA',
      },
      {
        value: 'F431',
        label: 'MONTASSILO o MONTASSILONE',
      },
      {
        value: 'F436',
        label: "MONTE A MEZZODI' o MONTE DI MEZZODI'",
      },
      {
        value: 'F438',
        label: 'MONTE A TRAMONTANA o MONTE DI TRAMONTANA',
      },
      {
        value: 'F617',
        label: 'MONTE SAN CANDIDO',
      },
      {
        value: 'F470',
        label: 'MONTECHIARO',
      },
      {
        value: 'F505',
        label: 'MONTEFONTANA',
      },
      {
        value: 'F588',
        label: 'MONTEPONENTE',
      },
      {
        value: 'F757',
        label: 'MORTER',
      },
      {
        value: 'F766',
        label: 'MOSO o MOSO IN PASSIRIA o PIANO IN PASSIRIA',
      },
      {
        value: 'F803',
        label: 'MULES',
      },
      {
        value: 'F836',
        label: 'NALLES',
      },
      {
        value: 'F849',
        label: 'NATURNO',
      },
      {
        value: 'F855',
        label: 'NAZ o NOCI',
      },
      {
        value: 'F856',
        label: 'NAZ SCIAVES o NAZ-SCIAVES',
      },
      {
        value: 'F949',
        label: 'NOVA LEVANTE',
      },
      {
        value: 'F950',
        label: 'NOVA PONENTE',
      },
      {
        value: 'F945',
        label: 'NOVACELLA',
      },
      {
        value: 'G069',
        label: 'ONIES',
      },
      {
        value: 'G083',
        label: 'ORA',
      },
      {
        value: 'G112',
        label: 'ORIS',
      },
      {
        value: 'G140',
        label: 'ORTISEI',
      },
      {
        value: 'G299',
        label: 'PALUS o PLAUS',
      },
      {
        value: 'G328',
        label: 'PARCINES',
      },
      {
        value: 'G443',
        label: 'PERCA o PERGA',
      },
      {
        value: 'G730',
        label: 'PLAGNOLO o PLANOL',
      },
      {
        value: 'G732',
        label: 'PLATA o PLATEA',
      },
      {
        value: 'G830',
        label: "PONTE ALL'ISARCO o PONTE GARDENA",
      },
      {
        value: 'G936',
        label: 'POSTAL',
      },
      {
        value: 'G996',
        label: 'PRATI',
      },
      {
        value: 'H003',
        label: 'PRATO ALLA DRAVA',
      },
      {
        value: 'H004',
        label: 'PRATO ALLO STELVIO o PRATO IN VENOSTA o PRATO VENOSTA',
      },
      {
        value: 'H019',
        label: 'PREDOI o SAN VALENTINO IN PREDOI',
      },
      {
        value: 'H081',
        label: 'PROVES',
      },
      {
        value: 'H152',
        label: 'RACIGNES o RACINES',
      },
      {
        value: 'H167',
        label: 'RAISSA o RISCONE',
      },
      {
        value: 'H189',
        label: 'RASUN ANTERSELVA o RASUN-ANTERSELVA',
      },
      {
        value: 'H190',
        label: 'RASUN DI SOPRA',
      },
      {
        value: 'H191',
        label: 'RASUN DI SOTTO',
      },
      {
        value: 'M307',
        label: 'RASUN VALDAORA',
      },
      {
        value: 'H236',
        label: 'RENON',
      },
      {
        value: 'H241',
        label: "RESIA o RESIA ALL'ADIGE",
      },
      {
        value: 'H279',
        label: 'RIDANNA',
      },
      {
        value: 'H284',
        label: 'RIFIANO',
      },
      {
        value: 'H295',
        label: 'RINA o RINNA',
      },
      {
        value: 'H299',
        label: 'RIO DI PUSTERIA',
      },
      {
        value: 'H306',
        label: 'RIO MOLINO',
      },
      {
        value: 'H332',
        label: 'RIVA DI TURES o RIVA IN VALLE',
      },
      {
        value: 'H475',
        label: 'RODENGO',
      },
      {
        value: 'H719',
        label: 'SALORNO o SALORNO SULLA STRADA DEL VINO',
      },
      {
        value: 'H786',
        label: 'SAN CANDIDO',
      },
      {
        value: 'H837',
        label: 'SAN FELICE',
      },
      {
        value: 'H858',
        label: 'SAN GENESIO o SAN GENESIO ATESINO',
      },
      {
        value: 'H866',
        label: 'SAN GIACOMO o SAN GIACOMO IN VALLE AURINA',
      },
      {
        value: 'H879',
        label: 'SAN GIORGIO o SAN GIORGIO IN VAL TURES',
      },
      {
        value: 'H902',
        label: 'SAN GIOVANNI o SAN GIOVANNI IN VALLE AURINA',
      },
      {
        value: 'H950',
        label: 'SAN LEONARDO',
      },
      {
        value: 'H952',
        label: 'SAN LEONARDO IN PASSIRIA',
      },
      {
        value: 'H956',
        label: 'SAN LORENZO o SAN LORENZO DI SEBATO o SAN LORENZO IN PUSTERIA',
      },
      {
        value: 'H988',
        label: 'SAN MARTINO o SAN MARTINO IN BADIA',
      },
      {
        value: 'H989',
        label: 'SAN MARTINO o SAN MARTINO IN PASSIRIA',
      },
      {
        value: 'I001',
        label: 'SAN MARTINO AL MONTE',
      },
      {
        value: 'I010',
        label: 'SAN MARTINO IN CASIES',
      },
      {
        value: 'I065',
        label: 'SAN PANCRAZIO',
      },
      {
        value: 'I087',
        label: 'SAN PIETRO o SAN PIETRO IN VALLE AURINA',
      },
      {
        value: 'I159',
        label: 'SAN SIGISMONDO',
      },
      {
        value: 'I379',
        label: 'SAN VALENTINO o SAN VALENTINO ALLA MUTTA',
      },
      {
        value: 'I378',
        label: 'SAN VALENTINO AL BRENNERO',
      },
      {
        value: 'I272',
        label: "SANT'ANDREA IN MONTE",
      },
      {
        value: 'I173',
        label:
          'SANTA CRISTINA o SANTA CRISTINA IN GARDENA o SANTA CRISTINA VALGARDENA',
      },
      {
        value: 'I223',
        label: 'SANTA MADDALENA IN CASIES',
      },
      {
        value: 'I431',
        label: 'SARENTINO',
      },
      {
        value: 'I488',
        label: 'SCALE o SCALERES',
      },
      {
        value: 'I516',
        label: 'SCAVES o SCIAVES',
      },
      {
        value: 'I519',
        label: 'SCENA',
      },
      {
        value: 'I591',
        label: 'SELVA o SELVA DI VAL GARDENA o SELVA IN GARDENA',
      },
      {
        value: 'I593',
        label: 'SELVA DEI MOLINI',
      },
      {
        value: 'I603',
        label: 'SENALE o SENALE-SAN FELICE',
      },
      {
        value: 'I604',
        label: 'SENALES o SENALES IN VENOSTA',
      },
      {
        value: 'I687',
        label: 'SESTO',
      },
      {
        value: 'I729',
        label: 'SILANDRO',
      },
      {
        value: 'I769',
        label: 'SLINGIA o SLINIGA',
      },
      {
        value: 'I771',
        label: 'SLUDERNO',
      },
      {
        value: 'I900',
        label: 'SPINGA',
      },
      {
        value: 'I940',
        label: 'STAVA o STAVE',
      },
      {
        value: 'I948',
        label: 'STELVIO',
      },
      {
        value: 'I958',
        label: 'STILVES',
      },
      {
        value: 'L021',
        label: "TABLA'",
      },
      {
        value: 'L041',
        label: 'TANAS',
      },
      {
        value: 'L051',
        label: 'TARCES o TARRES DI SOPRA',
      },
      {
        value: 'L054',
        label: 'TARRES o TARRES DI SOTTO',
      },
      {
        value: 'L091',
        label: 'TELVES',
      },
      {
        value: 'L098',
        label: 'TEODONA o TEODONE',
      },
      {
        value: 'L106',
        label: 'TERENTO',
      },
      {
        value: 'L108',
        label: 'TERLANO',
      },
      {
        value: 'L111',
        label: 'TERMENO o TERMENO SULLA STRADA DEL VINO',
      },
      {
        value: 'L148',
        label: 'TESIDO',
      },
      {
        value: 'L149',
        label: 'TESIMO',
      },
      {
        value: 'L176',
        label: 'TIRES',
      },
      {
        value: 'L178',
        label: 'TIROLO',
      },
      {
        value: 'L179',
        label: 'TISA o TISO',
      },
      {
        value: 'L374',
        label: 'TRENS',
      },
      {
        value: 'L444',
        label: 'TRODENA o TRODENA NEL PARCO NATURALE',
      },
      {
        value: 'L455',
        label: 'TUBRE',
      },
      {
        value: 'L465',
        label: 'TUNES',
      },
      {
        value: 'L490',
        label: 'ULTIMO',
      },
      {
        value: 'L527',
        label: 'VADENA',
      },
      {
        value: 'L564',
        label: 'VAL DI VIZZE',
      },
      {
        value: 'L543',
        label: 'VALAS o VELASIO',
      },
      {
        value: 'L552',
        label: 'VALDAORA',
      },
      {
        value: 'L559',
        label: 'VALDIGIOVO o VALGIOVO',
      },
      {
        value: 'L587',
        label: 'VALLARGA',
      },
      {
        value: 'L595',
        label: 'VALLE AURINA',
      },
      {
        value: 'L601',
        label: 'VALLE DI CASIES',
      },
      {
        value: 'L621',
        label: 'VALLE SAN SILVESTRO',
      },
      {
        value: 'L608',
        label: 'VALLELUNGA o VALLELUNGA CARLINO',
      },
      {
        value: 'L615',
        label: 'VALLES',
      },
      {
        value: 'L660',
        label: 'VANDOIES',
      },
      {
        value: 'L661',
        label: 'VANDOIES DI SOPRA',
      },
      {
        value: 'L662',
        label: 'VANDOIES DI SOTTO',
      },
      {
        value: 'L663',
        label: 'VANGA',
      },
      {
        value: 'L687',
        label: 'VARNA',
      },
      {
        value: 'L724',
        label: 'VELTURNO',
      },
      {
        value: 'L745',
        label: 'VERANO',
      },
      {
        value: 'L790',
        label: 'VERSCIAGO',
      },
      {
        value: 'L818',
        label: 'VEZZANO o VEZZANO IN VENOSTA',
      },
      {
        value: 'L941',
        label: "VILLA D'UTA o VILLA OTTONE",
      },
      {
        value: 'M020',
        label: 'VILLA SANTA CATERINA',
      },
      {
        value: 'L915',
        label: 'VILLABASSA',
      },
      {
        value: 'L971',
        label: 'VILLANDRO',
      },
      {
        value: 'M067',
        label: 'VIPITENO',
      },
      {
        value: 'M099',
        label: 'VIZZE',
      },
    ],
  },

  {
    value: 'CA',
    label: 'Cagliari',
    children: [
      {
        value: 'A359',
        label: 'ARBUS o ARBUS',
      },
      {
        value: 'A368',
        label: "ARCIDANO o SAN NICOLO' D'ARCIDANO",
      },
      {
        value: 'A408',
        label: 'ARIXI',
      },
      {
        value: 'A419',
        label: 'ARMUNGIA o ARMUNGIA',
      },
      {
        value: 'A474',
        label: 'ASSEMINI',
      },
      {
        value: 'A477',
        label: 'ASSOLO NURECI o ASSOLO',
      },
      {
        value: 'A597',
        label: 'BALLAO o BALLAO',
      },
      {
        value: 'A609',
        label: 'BANNARI o BANNARI DI USELLUS o VILLA VERDE',
      },
      {
        value: 'A621',
        label: 'BARATILI o BARATILI SAN PIETRO',
      },
      {
        value: 'A663',
        label: 'BARI o BARI SARDO',
      },
      {
        value: 'A677',
        label: 'BARRALI o BARRALI',
      },
      {
        value: 'A681',
        label: 'BARUMINI o BARUMINI',
      },
      {
        value: 'B250',
        label: 'BUGGERRU o BUGGERRU',
      },
      {
        value: 'B274',
        label: 'BURCEI o BURCEI',
      },
      {
        value: 'B354',
        label: 'CAGLIARI',
      },
      {
        value: 'B383',
        label: 'CALASETTA o CALATABIANO o CALASETTA',
      },
      {
        value: 'B675',
        label: 'CAPOTERRA',
      },
      {
        value: 'B738',
        label: 'CARBONARA o VILLASIMIUS o VILLASIMIUS',
      },
      {
        value: 'B745',
        label: 'CARBONIA o CARBONIA',
      },
      {
        value: 'B789',
        label: 'CARLOFORTE o CARLOFORTE',
      },
      {
        value: 'M288',
        label: 'CASTIADAS o CASTIADAS',
      },
      {
        value: 'C882',
        label: 'COLLINAS o FORRU o COLLINAS',
      },
      {
        value: 'D259',
        label: 'DECIMOMANNU',
      },
      {
        value: 'D260',
        label: 'DECIMOPUTZU o DECIMOPUTZU',
      },
      {
        value: 'D323',
        label: 'DOLIANOVA o DOLIANOVA',
      },
      {
        value: 'D333',
        label: 'DOMUS DE MARIA o DOMUS DE MARIA',
      },
      {
        value: 'D334',
        label: 'DOMUSNOVAS o DOMUSNOVAS',
      },
      {
        value: 'D335',
        label: 'DOMUSNOVAS CANALES',
      },
      {
        value: 'D342',
        label: 'DONIGALA o DONIGALA FENUGHEDU',
      },
      {
        value: 'D343',
        label: 'DONIGALA o DONIGALA SIURGUS',
      },
      {
        value: 'D344',
        label: 'DONORI o DONORI',
      },
      {
        value: 'D399',
        label: 'ELMAS',
      },
      {
        value: 'D432',
        label: 'ESCOVEDU',
      },
      {
        value: 'D584',
        label: 'FIGU',
      },
      {
        value: 'D585',
        label: "FIGU-GONNOSNO' o GONNOSNO'",
      },
      {
        value: 'D639',
        label: 'FLUMINIMAGGIORE o FLUMINIMAGGIORE',
      },
      {
        value: 'D827',
        label: 'FURTEI o FURTEI',
      },
      {
        value: 'D970',
        label: 'GENURI o GENURI',
      },
      {
        value: 'D994',
        label: 'GESICO o GESICO',
      },
      {
        value: 'D997',
        label: 'GESTURI o GESTURI',
      },
      {
        value: 'E005',
        label: 'GHILARZA-ABBASANTA',
      },
      {
        value: 'E022',
        label: 'GIBA o VILLARIOS o VILLARIOS MASAINAS o GIBA',
      },
      {
        value: 'E084',
        label: 'GONI o GONI',
      },
      {
        value: 'E086',
        label: 'GONNESA o GONNESA',
      },
      {
        value: 'E085',
        label: 'GONNOSFANADIGA o GONNOSFANADIGA',
      },
      {
        value: 'E234',
        label: 'GUAMAGGIORE o GUAMAGGIORE',
      },
      {
        value: 'E252',
        label: 'GUASILA o GUASILA',
      },
      {
        value: 'E270',
        label: 'GUSPINI o GUSPINI',
      },
      {
        value: 'E281',
        label: 'IGLESIAS o IGLESIAS',
      },
      {
        value: 'E464',
        label: 'LAS PLASSAS o LAS PLASSAS',
      },
      {
        value: 'E742',
        label: 'LUNAMATRONA o LUNAMATRONA',
      },
      {
        value: 'E877',
        label: 'MANDAS o MANDAS',
      },
      {
        value: 'E903',
        label: 'MARACALAGONIS',
      },
      {
        value: 'M270',
        label: 'MASAINAS o MASAINAS',
      },
      {
        value: 'F031',
        label: 'MASSAMA',
      },
      {
        value: 'F073',
        label: 'MEANA o MEANA SARDO',
      },
      {
        value: 'F271',
        label: 'MOGORELLA RUINAS o RUINAS',
      },
      {
        value: 'F333',
        label: 'MONASTIR o MONASTIR',
      },
      {
        value: 'F383',
        label: 'MONSERRATO o PAULI MONSERRATO o PAULI PIRRI',
      },
      {
        value: 'F800',
        label: 'MULARGIA',
      },
      {
        value: 'F808',
        label: 'MURAVERA o MURAVERA',
      },
      {
        value: 'F822',
        label: 'MUSEI o MUSEI',
      },
      {
        value: 'A357',
        label: 'MUSSOLINIA o ARBOREA',
      },
      {
        value: 'F841',
        label: 'NARCAO o NARCAO',
      },
      {
        value: 'F934',
        label: 'NORGHIDDO o NORBELLO',
      },
      {
        value: 'F974',
        label: 'NUGHEDU o NUGHEDU SANTA VITTORIA',
      },
      {
        value: 'F983',
        label: 'NURAMINIS o NURAMINIS',
      },
      {
        value: 'F984',
        label: 'NURAXINIEDDU',
      },
      {
        value: 'F991',
        label: 'NUXIS o NUXIS',
      },
      {
        value: 'A126',
        label: 'OLLASTA o OLLASTRA USELLUS o ALBAGIARA',
      },
      {
        value: 'G133',
        label: 'ORTACESUS o ORTACESUS',
      },
      {
        value: 'G207',
        label: 'PABILLONIS o PABILLONIS',
      },
      {
        value: 'G286',
        label: 'PALMAS o PALMAS ARBOREA',
      },
      {
        value: 'G287',
        label:
          'PALMAS o PALMAS SUERGIU o SAN GIOVANNI SUERGIU o SAN GIOVANNI SUERGIU',
      },
      {
        value: 'G382',
        label: 'PAULI ARBAREI o PAULI ARBAREI',
      },
      {
        value: 'G383',
        label: "PAULI GERREI o SAN NICOLO' GERREI o SAN NICOLO' GERREI",
      },
      {
        value: 'G446',
        label: 'PERDAXIUS o PERDAXIUS',
      },
      {
        value: 'G669',
        label: 'PIMENTEL o PIMENTEL',
      },
      {
        value: 'G701',
        label: 'PIRRI',
      },
      {
        value: 'M291',
        label: 'PISCINAS o PISCINAS',
      },
      {
        value: 'G817',
        label: 'POMPU o POMPU',
      },
      {
        value: 'G922',
        label: 'PORTOSCUSO o PORTOSCUSO',
      },
      {
        value: 'H088',
        label: 'PULA',
      },
      {
        value: 'H118',
        label: "QUARTU o QUARTU SANT'ELENA",
      },
      {
        value: 'H119',
        label: 'QUARTUCCIU',
      },
      {
        value: 'H301',
        label: 'RIOLA o RIOLA SARDO',
      },
      {
        value: 'H738',
        label: 'SAMASSI o SAMASSI',
      },
      {
        value: 'H739',
        label: 'SAMATZAI o SAMATZAI',
      },
      {
        value: 'H766',
        label: 'SAN BASILIO o SAN BASILIO',
      },
      {
        value: 'H856',
        label: 'SAN GAVINO o SAN GAVINO MONREALE o SAN GAVINO MONREALE',
      },
      {
        value: 'I069',
        label: 'SAN PANTALEO',
      },
      {
        value: 'I118',
        label: 'SAN PIETRO PULA o VILLA SAN PIETRO',
      },
      {
        value: 'I166',
        label: 'SAN SPERATE o SAN SPERATE',
      },
      {
        value: 'I383',
        label: 'SAN VERO CONGIUS',
      },
      {
        value: 'I402',
        label: 'SAN VITO o SAN VITO',
      },
      {
        value: 'H974',
        label: 'SANLURI o SANLURI',
      },
      {
        value: 'I271',
        label: "SANT'ANDREA FRIUS o SANT'ANDREA FRIUS",
      },
      {
        value: 'M209',
        label: "SANT'ANNA ARRESI o SANT'ANNA ARRESI",
      },
      {
        value: 'I294',
        label: "SANT'ANTIOCO o SANT'ANTIOCO",
      },
      {
        value: 'I298',
        label: "SANT'ANTONIO o SANT'ANTONIO RUINAS o VILLA SANT'ANTONIO",
      },
      {
        value: 'I182',
        label: 'SANTADI o SANTADI',
      },
      {
        value: 'I428',
        label: 'SARDARA o SARDARA',
      },
      {
        value: 'I443',
        label: 'SARROCH',
      },
      {
        value: 'I503',
        label: 'SCANO o SCANO MONTIFERRO o SCANO DI MONTIFERRO',
      },
      {
        value: 'I570',
        label: 'SEGARIU o SEGARIU',
      },
      {
        value: 'I580',
        label: 'SELARGIUS',
      },
      {
        value: 'I582',
        label: 'SELEGAS o SELEGAS',
      },
      {
        value: 'I615',
        label: "SENORBI' o SENORBI'",
      },
      {
        value: 'I623',
        label: 'SERBARIU',
      },
      {
        value: 'I624',
        label: 'SERDIANA o SERDIANA',
      },
      {
        value: 'I647',
        label: 'SERRAMANNA o SERRAMANNA',
      },
      {
        value: 'I667',
        label: 'SERRENTI o SERRENTI',
      },
      {
        value: 'I695',
        label: 'SESTU',
      },
      {
        value: 'I699',
        label: 'SETTIMO o SETTIMO SAN PIETRO',
      },
      {
        value: 'I705',
        label: 'SETZU o SETZU',
      },
      {
        value: 'I708',
        label: 'SEUNI',
      },
      {
        value: 'I722',
        label: 'SICCI o SICCI SAN BIAGIO',
      },
      {
        value: 'I724',
        label: 'SIDDI o SIDDI',
      },
      {
        value: 'I731',
        label: 'SILI',
      },
      {
        value: 'I734',
        label: 'SILIQUA o SILIQUA',
      },
      {
        value: 'I735',
        label: 'SILIUS o SILIUS',
      },
      {
        value: 'I752',
        label: 'SINNAI',
      },
      {
        value: 'I762',
        label: 'SISINI',
      },
      {
        value: 'I764',
        label: 'SIURGUS',
      },
      {
        value: 'I765',
        label: 'SIURGUS DONIGALA o SIURGUS DONIGALA',
      },
      {
        value: 'I784',
        label: 'SOLANAS',
      },
      {
        value: 'I797',
        label: 'SOLEMINIS o SOLEMINIS',
      },
      {
        value: 'I995',
        label: 'SUELLI o SUELLI',
      },
      {
        value: 'L154',
        label: 'TEULADA o TEULADA',
      },
      {
        value: 'L337',
        label: 'TRATALIAS o TRATALIAS',
      },
      {
        value: 'L463',
        label: 'TUILI o TUILI',
      },
      {
        value: 'L473',
        label: 'TURRI o TURRI',
      },
      {
        value: 'L488',
        label: "ULA' o ULA' TIRSO",
      },
      {
        value: 'L512',
        label: 'USSANA o USSANA',
      },
      {
        value: 'L513',
        label: 'USSARAMANNA o USSARAMANNA',
      },
      {
        value: 'L521',
        label: 'UTA',
      },
      {
        value: 'L613',
        label: 'VALLERMOSA o VALLERMOSA',
      },
      {
        value: 'L924',
        label: 'VILLACIDRO o VILLACIDRO',
      },
      {
        value: 'L954',
        label: 'VILLAGRECA',
      },
      {
        value: 'L966',
        label: 'VILLAMAR o VILLAMAR',
      },
      {
        value: 'L968',
        label: 'VILLAMASSARGIA o VILLAMASSARGIA',
      },
      {
        value: 'L986',
        label: 'VILLANOVAFORRU o VILLANOVAFORRU',
      },
      {
        value: 'L987',
        label: 'VILLANOVAFRANCA o VILLANOVAFRANCA',
      },
      {
        value: 'M278',
        label: 'VILLAPERUCCIO o VILLAPERUCCIO',
      },
      {
        value: 'L998',
        label: 'VILLAPUTZU o VILLAPUTZU',
      },
      {
        value: 'M016',
        label: 'VILLASALTO o VILLASALTO',
      },
      {
        value: 'M025',
        label: 'VILLASOR o VILLASOR',
      },
      {
        value: 'M026',
        label: 'VILLASPECIOSA o VILLASPECIOSA',
      },
      {
        value: 'M164',
        label: 'ZEPPARA',
      },
      {
        value: 'M206',
        label: 'ZURI',
      },
    ],
  },

  {
    value: 'CB',
    label: 'Campobasso',
    children: [
      {
        value: 'A050',
        label: 'ACQUAVIVA COLLECROCE',
      },
      {
        value: 'A567',
        label: 'BAGNOLI o BAGNOLI DEL TRIGNO',
      },
      {
        value: 'A616',
        label: 'BARANELLO',
      },
      {
        value: 'A761',
        label: 'BELMONTE o BELMONTE DEL SANNIO',
      },
      {
        value: 'A930',
        label: 'BOIANO o BOIANO (corr. BOJANO) o BOJANO',
      },
      {
        value: 'A971',
        label: 'BONEFRO',
      },
      {
        value: 'B295',
        label: 'BUSSO',
      },
      {
        value: 'B317',
        label: 'CACCAVONE o POGGIO SANNITA',
      },
      {
        value: 'B466',
        label: "CAMELI o SANT'ELENA SANNITA",
      },
      {
        value: 'B519',
        label: 'CAMPOBASSO',
      },
      {
        value: 'B522',
        label: 'CAMPOCHIARO',
      },
      {
        value: 'B528',
        label: 'CAMPODIPIETRA',
      },
      {
        value: 'B544',
        label: 'CAMPOLIETO',
      },
      {
        value: 'B550',
        label: 'CAMPOMARINO',
      },
      {
        value: 'B630',
        label: 'CANTALUPO o CANTALUPO NEL SANNIO',
      },
      {
        value: 'B858',
        label: 'CASACALENDA',
      },
      {
        value: 'B871',
        label: 'CASALCIPRANO',
      },
      {
        value: 'C066',
        label: 'CASTELBOTTACCIO',
      },
      {
        value: 'C175',
        label: 'CASTELLINO o CASTELLINO DEL BIFERNO',
      },
      {
        value: 'C192',
        label: 'CASTELLONE AL VOLTURNO',
      },
      {
        value: 'C197',
        label: 'CASTELLUCCIO ACQUABORRANO o CASTELMAURO',
      },
      {
        value: 'C200',
        label: 'CASTELLUCCIO IN VERRINO o VERRINO o CASTELVERRINO',
      },
      {
        value: 'C346',
        label: 'CASTROPIGNANO',
      },
      {
        value: 'C486',
        label: 'CERCEMAGGIORE',
      },
      {
        value: 'C488',
        label: 'CERCEPICCOLA',
      },
      {
        value: 'C534',
        label: 'CERRO o CERRO AL VOLTURNO',
      },
      {
        value: 'C764',
        label: 'CIVITACAMPOMARANO',
      },
      {
        value: 'C769',
        label: 'CIVITANOVA o CIVITANOVA DEL SANNIO',
      },
      {
        value: 'C772',
        label: 'CIVITAVECCHIA o DURONIA',
      },
      {
        value: 'C854',
        label: "COLLE D'ANCHISE",
      },
      {
        value: 'C875',
        label: 'COLLETORTO',
      },
      {
        value: 'C878',
        label: 'COLLI o COLLI A VOLTURNO',
      },
      {
        value: 'D550',
        label: 'FERRAZZANO',
      },
      {
        value: 'D703',
        label: "FORLI' o FORLI' DEL SANNIO",
      },
      {
        value: 'D737',
        label: 'FOSSACECA o FOSSALTO',
      },
      {
        value: 'D896',
        label: 'GAMBATESA',
      },
      {
        value: 'E030',
        label: 'GILDONE',
      },
      {
        value: 'E244',
        label: 'GUARDIALFIERA',
      },
      {
        value: 'E248',
        label: 'GUARDIAREGIA',
      },
      {
        value: 'E259',
        label: 'GUGLIONESI',
      },
      {
        value: 'E381',
        label: 'JELSI',
      },
      {
        value: 'E456',
        label: 'LARINO',
      },
      {
        value: 'E599',
        label: 'LIMOSANO',
      },
      {
        value: 'E722',
        label: 'LUCITO',
      },
      {
        value: 'E748',
        label: 'LUPARA',
      },
      {
        value: 'E780',
        label: 'MACCHIA VALFORTORE',
      },
      {
        value: 'E799',
        label: 'MAFALDA o RIPALDA o RIPALTA SUL TRIGNO',
      },
      {
        value: 'F055',
        label: 'MATRICE',
      },
      {
        value: 'F233',
        label: 'MIRABELLO o MIRABELLO SANNITICO',
      },
      {
        value: 'F294',
        label: 'MOLISE',
      },
      {
        value: 'F322',
        label: 'MONACILIONI',
      },
      {
        value: 'F391',
        label: 'MONTAGANO',
      },
      {
        value: 'F475',
        label: 'MONTECILFONE',
      },
      {
        value: 'F495',
        label: 'MONTEFALCONE o MONTEFALCONE NEL SANNIO',
      },
      {
        value: 'F548',
        label: 'MONTELONGO',
      },
      {
        value: 'F569',
        label: 'MONTEMITRO',
      },
      {
        value: 'F576',
        label: 'MONTENERO DI BISACCIA',
      },
      {
        value: 'F689',
        label: 'MONTORIO o MONTORIO NEI FRENTANI',
      },
      {
        value: 'F748',
        label: 'MORRONE o MORRONE DEL SANNIO',
      },
      {
        value: 'G086',
        label: 'ORATINO',
      },
      {
        value: 'G257',
        label: 'PALATA',
      },
      {
        value: 'G506',
        label: 'PETACCIATO',
      },
      {
        value: 'G512',
        label: 'PETRELLA o PETRELLA TIFERNINA',
      },
      {
        value: 'G523',
        label: 'PETTORANELLO DI MOLISE o PETTORANO o PETTORANELLO DEL MOLISE',
      },
      {
        value: 'G609',
        label: 'PIETRACATELLA',
      },
      {
        value: 'G610',
        label: 'PIETRACUPA',
      },
      {
        value: 'G910',
        label: 'PORTOCANNONE',
      },
      {
        value: 'H083',
        label: 'PROVVIDENTI',
      },
      {
        value: 'H273',
        label: 'RICCIA',
      },
      {
        value: 'H308',
        label: 'RIONERO o RIONERO SANNITICO',
      },
      {
        value: 'H311',
        label: 'RIPABOTTONI',
      },
      {
        value: 'H313',
        label: 'RIPALIMOSANI',
      },
      {
        value: 'H454',
        label: 'ROCCAVIVARA',
      },
      {
        value: 'H458',
        label: 'ROCCHETTA o ROCCHETTA A VOLTURNO',
      },
      {
        value: 'H589',
        label: 'ROTELLO',
      },
      {
        value: 'H693',
        label: 'SALCITO',
      },
      {
        value: 'H782',
        label: 'SAN BIASE',
      },
      {
        value: 'H833',
        label:
          'SAN FELICE o SAN FELICE DEL LITTORIO o SAN FELICE DEL MOLISE o SAN FELICE SLAVO',
      },
      {
        value: 'H867',
        label: 'SAN GIACOMO o SAN GIACOMO DEGLI SCHIAVONI',
      },
      {
        value: 'H920',
        label: 'SAN GIOVANNI IN GALDO',
      },
      {
        value: 'H928',
        label: 'SAN GIULIANO DEL SANNIO o SAN GIULIANO DI SEPINO',
      },
      {
        value: 'H929',
        label: 'SAN GIULIANO DI PUGLIA',
      },
      {
        value: 'H990',
        label: 'SAN MARTINO o SAN MARTINO IN PENSILIS',
      },
      {
        value: 'I023',
        label: 'SAN MASSIMO',
      },
      {
        value: 'I122',
        label: 'SAN POLO o SAN POLO MATESE o SAN POLOMATESE',
      },
      {
        value: 'I387',
        label: 'SAN VINCENZO o SAN VINCENZO A VOLTURNO',
      },
      {
        value: 'I238',
        label: "SANT'ANGELO IN GROTTE o SANTA MARIA DEL MOLISE",
      },
      {
        value: 'I289',
        label: "SANT'ANGELO LIMOSANO",
      },
      {
        value: 'I320',
        label: "SANT'ELIA o SANT'ELIA A PIANISI",
      },
      {
        value: 'I181',
        label: 'SANTA CROCE DI MAGLIANO',
      },
      {
        value: 'I618',
        label: 'SEPINO',
      },
      {
        value: 'I679',
        label: 'SESSANO o SESSANO DEL MOLISE',
      },
      {
        value: 'I682',
        label: 'SESTO o SESTO CAMPANO',
      },
      {
        value: 'I910',
        label: 'SPINETE',
      },
      {
        value: 'L069',
        label: 'TAVENNA',
      },
      {
        value: 'L113',
        label: 'TERMOLI',
      },
      {
        value: 'L215',
        label: 'TORELLA o TORELLA DEL SANNIO',
      },
      {
        value: 'L230',
        label: 'TORO',
      },
      {
        value: 'L435',
        label: 'TRIVENTO',
      },
      {
        value: 'L458',
        label: 'TUFARA',
      },
      {
        value: 'L505',
        label: 'URURI',
      },
      {
        value: 'M057',
        label: 'VINCHIATURO',
      },
    ],
  },

  {
    value: 'CE',
    label: 'Caserta',
    children: [
      {
        value: 'A081',
        label: 'AGNONE o VILLA LATINA',
      },
      {
        value: 'A106',
        label: 'AILANO',
      },
      {
        value: 'A133',
        label: 'ALBANOVA',
      },
      {
        value: 'A200',
        label: 'ALIFE',
      },
      {
        value: 'A243',
        label: 'ALVIGNANO',
      },
      {
        value: 'A403',
        label: 'ARIENZO',
      },
      {
        value: 'A404',
        label: 'ARIENZO SANFELICE',
      },
      {
        value: 'A483',
        label: 'ATELLA DI NAPOLI',
      },
      {
        value: 'A512',
        label: 'AVERSA',
      },
      {
        value: 'A579',
        label: 'BAIA E LATINA',
      },
      {
        value: 'A755',
        label: 'BELLONA',
      },
      {
        value: 'B361',
        label: 'CAIANELLO',
      },
      {
        value: 'B362',
        label: 'CAIAZZO',
      },
      {
        value: 'B445',
        label: 'CALVI o CALVI RISORTA',
      },
      {
        value: 'B477',
        label: 'CAMIGLIANO',
      },
      {
        value: 'B494',
        label: 'CAMPAGNANO o CASTEL CAMPAGNANO o CASTELLO DI CAMPAGNANO',
      },
      {
        value: 'B543',
        label: 'CAMPOLI o CAMPOLI APPENNINO',
      },
      {
        value: 'B581',
        label: 'CANCELLO ED ARNONE',
      },
      {
        value: 'B667',
        label: 'CAPODRISE',
      },
      {
        value: 'B704',
        label: 'CAPRIATI o CAPRIATI A VOLTURNO',
      },
      {
        value: 'B715',
        label: 'CAPUA',
      },
      {
        value: 'B779',
        label: 'CARINARO',
      },
      {
        value: 'B781',
        label: 'CARINOLA',
      },
      {
        value: 'B860',
        label: 'CASAGIOVE o CASANOVA o CASANOVA E COCCAGNA',
      },
      {
        value: 'B872',
        label: 'CASAL DI PRINCIPE',
      },
      {
        value: 'B863',
        label: 'CASALBA',
      },
      {
        value: 'B916',
        label: 'CASALUCE',
      },
      {
        value: 'M260',
        label: 'CASAPESENNA',
      },
      {
        value: 'B935',
        label: 'CASAPULLA',
      },
      {
        value: 'B963',
        label: 'CASERTA',
      },
      {
        value: 'C097',
        label: 'CASTEL DI SASSO o SASSO',
      },
      {
        value: 'C211',
        label: 'CASTEL MORRONE o MORRONE',
      },
      {
        value: 'C291',
        label: 'CASTEL VOLTURNO',
      },
      {
        value: 'C178',
        label: "CASTELLO o CASTELLO D'ALIFE o CASTELLO DEL MATESE",
      },
      {
        value: 'C177',
        label: 'CASTELLUCCIO o CASTELLUCCIO DI SORA o CASTELLIRI',
      },
      {
        value: 'M262',
        label: 'CELLOLE',
      },
      {
        value: 'C558',
        label: 'CERVINO',
      },
      {
        value: 'C561',
        label: 'CESA',
      },
      {
        value: 'C716',
        label: 'CIORLANO',
      },
      {
        value: 'C939',
        label: 'CONCA o CONCA DELLA CAMPANIA',
      },
      {
        value: 'D228',
        label: 'CURTI',
      },
      {
        value: 'D361',
        label: 'DRAGONI',
      },
      {
        value: 'D471',
        label: 'FALCIANO DEL MASSICO',
      },
      {
        value: 'D558',
        label: 'FERTILIA',
      },
      {
        value: 'D683',
        label: 'FONTEGRECA o FOSSACECA',
      },
      {
        value: 'D709',
        label: 'FORMICOLA',
      },
      {
        value: 'D769',
        label: 'FRANCOLISE',
      },
      {
        value: 'D799',
        label: 'FRIGNANO',
      },
      {
        value: 'D800',
        label: 'FRIGNANO MAGGIORE',
      },
      {
        value: 'D801',
        label: 'FRIGNANO PICCOLO o VILLA DI BRIANO',
      },
      {
        value: 'D884',
        label: 'GALLO o GALLO MATESE',
      },
      {
        value: 'D886',
        label: 'GALLUCCIO',
      },
      {
        value: 'E011',
        label: 'GIANO o GIANO VETUSTO',
      },
      {
        value: 'E039',
        label: 'GIOIA o GIOIA SANNITICA',
      },
      {
        value: 'E158',
        label: 'GRAZZANISE',
      },
      {
        value: 'E173',
        label: 'GRICIGNANO o GRICIGNANO DI AVERSA',
      },
      {
        value: 'E554',
        label: 'LETINO',
      },
      {
        value: 'E570',
        label: 'LIBERI o SCHIAVI DI FORMICOLA',
      },
      {
        value: 'E754',
        label: 'LUSCIANO',
      },
      {
        value: 'E755',
        label: 'LUSCIANO E DUCENTA',
      },
      {
        value: 'E784',
        label: 'MACERATA CAMPANIA o MACERATA MARCIANISE o MACERATA',
      },
      {
        value: 'E791',
        label: 'MADDALONI',
      },
      {
        value: 'E932',
        label: 'MARCIANISE',
      },
      {
        value: 'E998',
        label: 'MARZANO o MARZANO APPIO',
      },
      {
        value: 'F203',
        label: 'MIGNANO o MIGNANO MONTE LUNGO',
      },
      {
        value: 'F352',
        label: 'MONDRAGONE',
      },
      {
        value: 'G130',
        label: 'ORTA o ORTA DI ATELLA',
      },
      {
        value: 'C340',
        label: 'PALAZZOLO DI CASTROCIELO o CASTROCIELO o PALAZZOLO',
      },
      {
        value: 'G283',
        label: 'PALMA o PALMA CAMPANIA',
      },
      {
        value: 'G333',
        label: 'PARETE',
      },
      {
        value: 'G364',
        label: 'PASTORANO',
      },
      {
        value: 'G541',
        label: 'PIANA o PIANA DI CAIAZZO o PIANA DI MONTE VERNA',
      },
      {
        value: 'G596',
        label: "PIEDIMONTE o PIEDIMONTE D'ALIFE o PIEDIMONTE MATESE",
      },
      {
        value: 'G620',
        label: 'PIETRAMELARA',
      },
      {
        value: 'G630',
        label: 'PIETRAVAIRANO',
      },
      {
        value: 'G661',
        label: 'PIGNATARO o PIGNATARO MAGGIORE',
      },
      {
        value: 'G849',
        label: 'PONTELATONE',
      },
      {
        value: 'G903',
        label: 'PORTICO o PORTICO DI CASERTA',
      },
      {
        value: 'G991',
        label: 'PRATA o PRATA SANNITA',
      },
      {
        value: 'G995',
        label: 'PRATELLA',
      },
      {
        value: 'H045',
        label: 'PRESENZANO',
      },
      {
        value: 'H165',
        label: 'RAIANO o RUVIANO',
      },
      {
        value: 'H202',
        label: 'RAVISCANINA',
      },
      {
        value: 'H210',
        label: 'RECALE',
      },
      {
        value: 'H268',
        label: 'RIARDO',
      },
      {
        value: 'H398',
        label: "ROCCA D'EVANDRO",
      },
      {
        value: 'H423',
        label: 'ROCCAMONFINA',
      },
      {
        value: 'H436',
        label: 'ROCCAROMANA',
      },
      {
        value: 'H459',
        label: 'ROCCHETTA o ROCCHETTA E CROCE',
      },
      {
        value: 'H779',
        label: 'SAN BIAGIO o SAN BIAGIO SARACINISCO',
      },
      {
        value: 'H798',
        label: "SAN CIPRIANO o SAN CIPRIANO D'AVERSA",
      },
      {
        value: 'H824',
        label: 'SAN DONATO o SAN DONATO VAL DI COMINO',
      },
      {
        value: 'H834',
        label: 'SAN FELICE o SAN FELICE A CANCELLO',
      },
      {
        value: 'H880',
        label: 'SAN GIORGIO o SAN GIORGIO A LIRI',
      },
      {
        value: 'H939',
        label: 'SAN GREGORIO o SAN GREGORIO MATESE',
      },
      {
        value: 'H978',
        label: 'SAN MARCELLINO',
      },
      {
        value: 'F043',
        label: 'SAN MARCO EVANGELISTA o MASSERIE o SAN MARCO EVANGELISTA',
      },
      {
        value: 'I056',
        label: 'SAN NICOLA o SAN NICOLA LA STRADA',
      },
      {
        value: 'I111',
        label: 'SAN PIETRO IN CUROLIS',
      },
      {
        value: 'I113',
        label: 'SAN PIETRO INFINE',
      },
      {
        value: 'I130',
        label: 'SAN POTITO o SAN POTITO SANNITICO',
      },
      {
        value: 'I131',
        label: 'SAN PRISCO',
      },
      {
        value: 'I261',
        label: 'SAN TAMMARO',
      },
      {
        value: 'I408',
        label: 'SAN VITTORE o SAN VITTORE DEL LAZIO',
      },
      {
        value: 'I256',
        label: "SANT'AMBROGIO o SANT'AMBROGIO SUL GARIGLIANO",
      },
      {
        value: 'I270',
        label: "SANT'ANDREA DI VALLEFREDDA",
      },
      {
        value: 'I273',
        label: "SANT'ANGELO o SANT'ANGELO D'ALIFE",
      },
      {
        value: 'I306',
        label: "SANT'ARPINO",
      },
      {
        value: 'I321',
        label: "SANT'ELIA o SANT'ELIA FIUMERAPIDO",
      },
      {
        value: 'I233',
        label: 'SANTA MARIA A VICO',
      },
      {
        value: 'I234',
        label: 'SANTA MARIA CAPUA VETERE o SANTA MARIA MAGGIORE',
      },
      {
        value: 'I247',
        label: 'SANTA MARIA LA FOSSA',
      },
      {
        value: 'I676',
        label: 'SESSA o SESSA AURUNCA',
      },
      {
        value: 'I885',
        label: 'SPARANISE',
      },
      {
        value: 'I993',
        label: 'SUCCIVO',
      },
      {
        value: 'L083',
        label: 'TEANO',
      },
      {
        value: 'L155',
        label: 'TEVEROLA',
      },
      {
        value: 'L205',
        label: 'TORA E PICCILLI',
      },
      {
        value: 'F224',
        label: 'TRAETTO o MINTURNO',
      },
      {
        value: 'L379',
        label: 'TRENTOLA o TRENTOLA DUCENTA',
      },
      {
        value: 'L540',
        label: 'VAIRANO o VAIRANO PATENORA',
      },
      {
        value: 'L591',
        label: 'VALLE o VALLE DI MADDALONI',
      },
      {
        value: 'L594',
        label: 'VALLE AGRICOLA o VALLE DI PRATA',
      },
      {
        value: 'L844',
        label: 'VICO DI PANTANO o VILLA LITERNO',
      },
      {
        value: 'L905',
        label: 'VILLA o VILLA SANTA LUCIA',
      },
      {
        value: 'M035',
        label: 'VILLA VOLTURNO',
      },
      {
        value: 'M084',
        label: 'VITICUSO E ACQUAFONDATA',
      },
      {
        value: 'M092',
        label: 'VITULACCIO o VITULAZIO',
      },
    ],
  },

  {
    value: 'CH',
    label: 'Chieti',
    children: [
      {
        value: 'A235',
        label: 'ALTINO',
      },
      {
        value: 'A367',
        label: 'ARCHI',
      },
      {
        value: 'A398',
        label: 'ARI',
      },
      {
        value: 'A402',
        label: 'ARIELLI',
      },
      {
        value: 'A485',
        label: 'ATESSA',
      },
      {
        value: 'A956',
        label: 'BOMBA',
      },
      {
        value: 'B057',
        label: 'BORRELLO',
      },
      {
        value: 'B238',
        label: 'BUCCHIANICO',
      },
      {
        value: 'B268',
        label: 'BUONANOTTE o MONTEBELLO SUL SANGRO',
      },
      {
        value: 'B620',
        label: 'CANOSA o CANOSA SANNITA',
      },
      {
        value: 'B826',
        label: 'CARPINETO o CARPINETO SINELLO',
      },
      {
        value: 'B853',
        label: 'CARUNCHIO',
      },
      {
        value: 'B859',
        label: 'CASACANDITELLA',
      },
      {
        value: 'B861',
        label: 'CASALANGUIDA',
      },
      {
        value: 'B865',
        label: 'CASALBORDINO',
      },
      {
        value: 'B896',
        label: 'CASALINCONTRADA',
      },
      {
        value: 'B985',
        label: 'CASOLI',
      },
      {
        value: 'C114',
        label: 'CASTEL FRENTANO o CASTEL TRENTANO o CASTELNUOVO',
      },
      {
        value: 'C123',
        label: 'CASTELGUIDONE',
      },
      {
        value: 'C298',
        label: 'CASTIGLIONE o CASTIGLIONE MESSER MARINO',
      },
      {
        value: 'C428',
        label: 'CELENZA o CELENZA SUL FRIGNO o CELENZA SUL TRIGNO',
      },
      {
        value: 'C632',
        label: 'CHIETI',
      },
      {
        value: 'C768',
        label: 'CIVITALUPARELLA',
      },
      {
        value: 'C776',
        label: 'CIVITELLA o CIVITELLA MESSER RAIMONDO',
      },
      {
        value: 'C855',
        label: 'COLLEDIMACINE',
      },
      {
        value: 'C856',
        label: 'COLLEDIMEZZO',
      },
      {
        value: 'D137',
        label: 'CRECCHIO',
      },
      {
        value: 'D209',
        label: 'CUPELLO',
      },
      {
        value: 'D315',
        label: 'DOGLIOLA',
      },
      {
        value: 'D479',
        label: 'FALLASCOSO',
      },
      {
        value: 'D480',
        label: 'FALLO',
      },
      {
        value: 'D494',
        label: 'FARA FILIORUM PETRI',
      },
      {
        value: 'D495',
        label: 'FARA SAN MARTINO',
      },
      {
        value: 'D592',
        label: 'FILETTO',
      },
      {
        value: 'D690',
        label: 'FORCABOBOLINA o SAN GIOVANNI TEATINO',
      },
      {
        value: 'D738',
        label: 'FOSSACECA o FOSSACESIA',
      },
      {
        value: 'D757',
        label: 'FRAINE',
      },
      {
        value: 'D763',
        label: 'FRANCAVILLA o FRANCAVILLA AL MARE',
      },
      {
        value: 'D796',
        label: 'FRESAGRANDINARIA',
      },
      {
        value: 'D803',
        label: 'FRISA',
      },
      {
        value: 'D823',
        label: 'FURCI',
      },
      {
        value: 'D898',
        label: 'GAMBERALE',
      },
      {
        value: 'D996',
        label: 'GESSOPALENA',
      },
      {
        value: 'E052',
        label: 'GISSI',
      },
      {
        value: 'E056',
        label: 'GIULIANO o GIULIANO TEATINO',
      },
      {
        value: 'E243',
        label: 'GUARDIAGRELE',
      },
      {
        value: 'E266',
        label: 'GUILMI',
      },
      {
        value: 'E372',
        label: 'ISTONIO o VASTO',
      },
      {
        value: 'E424',
        label: 'LAMA o LAMA DEI PELIGNI',
      },
      {
        value: 'E435',
        label: 'LANCIANO',
      },
      {
        value: 'E531',
        label: 'LENTELLA',
      },
      {
        value: 'E559',
        label: 'LETTOPALENA',
      },
      {
        value: 'E611',
        label: 'LISCIA',
      },
      {
        value: 'F196',
        label: 'MIGLIANICO',
      },
      {
        value: 'F433',
        label: 'MONTAZZOLI',
      },
      {
        value: 'F498',
        label: 'MONTEFERRANTE',
      },
      {
        value: 'F535',
        label: 'MONTELAPIANO',
      },
      {
        value: 'F578',
        label: 'MONTENERODOMO',
      },
      {
        value: 'F582',
        label: 'MONTEODORISIO',
      },
      {
        value: 'F785',
        label: 'MOZZAGROGNA',
      },
      {
        value: 'G128',
        label: 'ORSOGNA',
      },
      {
        value: 'G141',
        label: 'ORTONA',
      },
      {
        value: 'G237',
        label: 'PAGLIETA',
      },
      {
        value: 'G271',
        label: 'PALENA',
      },
      {
        value: 'G290',
        label: 'PALMOLI',
      },
      {
        value: 'G294',
        label: 'PALOMBARO',
      },
      {
        value: 'G434',
        label: 'PENNADOMO',
      },
      {
        value: 'G435',
        label: 'PENNAPIEDIMONTE',
      },
      {
        value: 'G441',
        label: 'PERANO',
      },
      {
        value: 'G613',
        label: 'PIETRAFERRAZZANA',
      },
      {
        value: 'G724',
        label: 'PIZZOFERRATO',
      },
      {
        value: 'G760',
        label: 'POGGIOFIORITO o VILLARIELLI',
      },
      {
        value: 'G799',
        label: 'POLLUTRI',
      },
      {
        value: 'H052',
        label: 'PRETORO',
      },
      {
        value: 'H098',
        label: 'QUADRI',
      },
      {
        value: 'H184',
        label: 'RAPINO',
      },
      {
        value: 'H320',
        label: 'RIPA TEATINA',
      },
      {
        value: 'H439',
        label: 'ROCCA SAN GIOVANNI',
      },
      {
        value: 'H424',
        label: 'ROCCAMONTEPIANO',
      },
      {
        value: 'H442',
        label: 'ROCCASCALEGNA',
      },
      {
        value: 'H448',
        label: 'ROCCASPINALVETI',
      },
      {
        value: 'H495',
        label: 'ROIO o ROIO DEL SANGRO',
      },
      {
        value: 'H566',
        label: 'ROSELLO',
      },
      {
        value: 'H784',
        label: 'SAN BUONO',
      },
      {
        value: 'H923',
        label: 'SAN GIOVANNI LIPIONI',
      },
      {
        value: 'H991',
        label: 'SAN MARTINO o SAN MARTINO SULLA MARRUCINA',
      },
      {
        value: 'I148',
        label: 'SAN SALVO',
      },
      {
        value: 'I161',
        label: 'SAN SILVESTRO',
      },
      {
        value: 'I376',
        label: 'SAN VALENTINO o SAN VALENTINO IN ABRUZZO CITERIORE',
      },
      {
        value: 'I394',
        label: 'SAN VITO o SAN VITO CHIETINO',
      },
      {
        value: 'I332',
        label: "SANT'EUFEMIA o SANT'EUFEMIA A MAIELLA",
      },
      {
        value: 'I335',
        label: "SANT'EUSANIO o SANT'EUSANIO DEL SANGRO",
      },
      {
        value: 'I244',
        label: 'SANTA MARIA IMBARO',
      },
      {
        value: 'I520',
        label: 'SCERNI',
      },
      {
        value: 'I526',
        label: 'SCHIAVI o SCHIAVI DI ABRUZZO',
      },
      {
        value: 'L047',
        label: 'TARANTA o TARANTA PELIGNA',
      },
      {
        value: 'L186',
        label: 'TOCCO o TOCCO DA CASAURIA',
      },
      {
        value: 'L194',
        label: 'TOLLO',
      },
      {
        value: 'L218',
        label: 'TORINO o TORINO DI SANGRO',
      },
      {
        value: 'L224',
        label: 'TORNARECCIO',
      },
      {
        value: 'L253',
        label: 'TORREBRUNA',
      },
      {
        value: 'L284',
        label: 'TORREVECCHIA o TORREVECCHIA TEATINA',
      },
      {
        value: 'L291',
        label: 'TORRICELLA o TORRICELLA PELIGNA',
      },
      {
        value: 'L363',
        label: 'TREGLIO',
      },
      {
        value: 'L459',
        label: 'TUFILLO',
      },
      {
        value: 'L526',
        label: 'VACRI',
      },
      {
        value: 'M022',
        label: 'VILLA SANTA MARIA',
      },
      {
        value: 'L961',
        label: 'VILLALFONSINA',
      },
      {
        value: 'L964',
        label: 'VILLAMAGNA',
      },
    ],
  },

  {
    value: 'CL',
    label: 'Caltanissetta',
    children: [
      {
        value: 'A049',
        label: 'ACQUAVIVA o ACQUAVIVA PLATANI',
      },
      {
        value: 'A957',
        label: 'BOMPENSIERE',
      },
      {
        value: 'B302',
        label: 'BUTERA',
      },
      {
        value: 'B429',
        label: 'CALTANISSETTA',
      },
      {
        value: 'B537',
        label: 'CAMPOFRANCO',
      },
      {
        value: 'D267',
        label: 'DELIA',
      },
      {
        value: 'D960',
        label: 'GELA o TERRANOVA o TERRANOVA DI SICILIA',
      },
      {
        value: 'E618',
        label: 'LITTORIA NISSENA o MILENA o MILOCCA',
      },
      {
        value: 'E953',
        label: 'MARIANOPOLI',
      },
      {
        value: 'F065',
        label: 'MAZZARINO',
      },
      {
        value: 'F489',
        label: 'MONTEDORO',
      },
      {
        value: 'F830',
        label: 'MUSSOMELI',
      },
      {
        value: 'F899',
        label: 'NISCEMI',
      },
      {
        value: 'G580',
        label: 'PIAZZA o PIAZZA ARMERINA',
      },
      {
        value: 'H245',
        label: 'RESUTTANO',
      },
      {
        value: 'H281',
        label: 'RIESI',
      },
      {
        value: 'H792',
        label: 'SAN CATALDO',
      },
      {
        value: 'I169',
        label: 'SANTA CATERINA o SANTA CATERINA VILLARMOSA',
      },
      {
        value: 'I644',
        label: 'SERRADIFALCO',
      },
      {
        value: 'I824',
        label: 'SOMMATINO',
      },
      {
        value: 'L016',
        label: 'SUTERA',
      },
      {
        value: 'L609',
        label: 'VALLELUNGA o VALLELUNGA PRATAMENO',
      },
      {
        value: 'L959',
        label: 'VILLALBA',
      },
    ],
  },

  {
    value: 'CN',
    label: 'Cuneo',
    children: [
      {
        value: 'A016',
        label: 'ACCEGLIO',
      },
      {
        value: 'A113',
        label: 'AISONE',
      },
      {
        value: 'A124',
        label: 'ALBA',
      },
      {
        value: 'A139',
        label: 'ALBARETTO o ALBARETTO DELLA TORRE',
      },
      {
        value: 'A140',
        label: 'ALBARETTO o ALBARETTO VALLE DI MACRA',
      },
      {
        value: 'A212',
        label: 'ALMA',
      },
      {
        value: 'A238',
        label: 'ALTO',
      },
      {
        value: 'A277',
        label: 'ANDONNO',
      },
      {
        value: 'A394',
        label: 'ARGENTERA',
      },
      {
        value: 'A396',
        label: 'ARGUELLO',
      },
      {
        value: 'A555',
        label: 'BAGNASCO',
      },
      {
        value: 'A571',
        label: 'BAGNOLO o BAGNOLO PIEMONTE',
      },
      {
        value: 'A589',
        label: "BALDISSERO o BALDISSERO D'ALBA",
      },
      {
        value: 'A629',
        label: 'BARBARESCO',
      },
      {
        value: 'A660',
        label: 'BARGE',
      },
      {
        value: 'A671',
        label: 'BAROLO',
      },
      {
        value: 'A709',
        label: "BASTIA o BASTIA MONDOVI'",
      },
      {
        value: 'A716',
        label: 'BATTIFOLLO',
      },
      {
        value: 'A735',
        label: 'BEINETTE',
      },
      {
        value: 'A750',
        label: 'BELLINO',
      },
      {
        value: 'A774',
        label: 'BELVEDERE o BELVEDERE LANGHE',
      },
      {
        value: 'A779',
        label: 'BENE o BENE VAGIENNA',
      },
      {
        value: 'A782',
        label: 'BENEVELLO',
      },
      {
        value: 'A798',
        label: 'BERGOLO',
      },
      {
        value: 'A805',
        label: 'BERNEZZO',
      },
      {
        value: 'A807',
        label: 'BERSEZIO',
      },
      {
        value: 'A979',
        label: 'BONVICINO',
      },
      {
        value: 'B033',
        label: 'BORGO SAN DALMAZZO',
      },
      {
        value: 'B018',
        label: 'BORGOMALE',
      },
      {
        value: 'B079',
        label: 'BOSIA',
      },
      {
        value: 'B084',
        label: 'BOSSOLASCO',
      },
      {
        value: 'B101',
        label: 'BOVES',
      },
      {
        value: 'B111',
        label: 'BRA',
      },
      {
        value: 'B167',
        label: 'BRIAGLIA',
      },
      {
        value: 'B174',
        label: 'BRIGA o BRIGA MARITTIMA',
      },
      {
        value: 'B175',
        label: 'BRIGA ALTA',
      },
      {
        value: 'B200',
        label: 'BRONDELLO',
      },
      {
        value: 'B204',
        label: 'BROSSASCO',
      },
      {
        value: 'B285',
        label: 'BUSCA',
      },
      {
        value: 'B467',
        label: 'CAMERANA',
      },
      {
        value: 'B489',
        label: 'CAMO',
      },
      {
        value: 'B573',
        label: 'CANALE',
      },
      {
        value: 'B621',
        label: 'CANOSIO',
      },
      {
        value: 'B692',
        label: 'CAPRAUNA',
      },
      {
        value: 'B719',
        label: 'CARAGLIO',
      },
      {
        value: 'B720',
        label: 'CARAMAGNA o CARAMAGNA PIEMONTE',
      },
      {
        value: 'B755',
        label: "CARDE'",
      },
      {
        value: 'B841',
        label: "CARRU'",
      },
      {
        value: 'B845',
        label: 'CARTIGNANO',
      },
      {
        value: 'B894',
        label: 'CASALGRASSO',
      },
      {
        value: 'C046',
        label: 'CASTAGNITO',
      },
      {
        value: 'C081',
        label: 'CASTELDELFINO',
      },
      {
        value: 'C140',
        label: 'CASTELLAR',
      },
      {
        value: 'C163',
        label: 'CASTELLETTO MONFORTE',
      },
      {
        value: 'C165',
        label: 'CASTELLETTO STURA',
      },
      {
        value: 'C167',
        label: 'CASTELLETTO UZZONE',
      },
      {
        value: 'C173',
        label: "CASTELLINALDO o CASTELLINALDO D'ALBA",
      },
      {
        value: 'C176',
        label: 'CASTELLINO o CASTELLINO TANARO',
      },
      {
        value: 'C205',
        label: 'CASTELMAGNO',
      },
      {
        value: 'C214',
        label: 'CASTELNOVO o CASTELNUOVO DI CEVA',
      },
      {
        value: 'C314',
        label: 'CASTIGLIONE FALLETTO',
      },
      {
        value: 'C317',
        label: 'CASTIGLIONE TINELLA',
      },
      {
        value: 'C323',
        label: 'CASTINO',
      },
      {
        value: 'C375',
        label: 'CAVALLERLEONE',
      },
      {
        value: 'C376',
        label: 'CAVALLERMAGGIORE',
      },
      {
        value: 'C441',
        label: 'CELLE o CELLE DI MACRA',
      },
      {
        value: 'C466',
        label: 'CENTALLO',
      },
      {
        value: 'C504',
        label: 'CERESOLE o CERESOLE ALBA',
      },
      {
        value: 'C530',
        label: 'CERRETTO o CERRETTO DELLE LANGHE o CERRETTO LANGHE',
      },
      {
        value: 'C547',
        label: 'CERVASCA',
      },
      {
        value: 'C550',
        label: 'CERVERE',
      },
      {
        value: 'C589',
        label: 'CEVA',
      },
      {
        value: 'C599',
        label: 'CHERASCO',
      },
      {
        value: 'C653',
        label: 'CHIUSA o CHIUSA DI PESIO',
      },
      {
        value: 'C681',
        label: "CIGLIE'",
      },
      {
        value: 'C738',
        label: 'CISSONE',
      },
      {
        value: 'C792',
        label: 'CLAVESANA',
      },
      {
        value: 'D022',
        label: "CORNELIANO o CORNELIANO D'ALBA",
      },
      {
        value: 'D062',
        label: 'CORTEMILIA',
      },
      {
        value: 'D093',
        label: 'COSSANO BELBO',
      },
      {
        value: 'D120',
        label: 'COSTIGLIOLE SALUZZO',
      },
      {
        value: 'D133',
        label: 'CRAVANZANA',
      },
      {
        value: 'D172',
        label: 'CRISSOLO',
      },
      {
        value: 'D205',
        label: 'CUNEO',
      },
      {
        value: 'D271',
        label: 'DEMONTE',
      },
      {
        value: 'D291',
        label: "DIANO o DIANO D'ALBA",
      },
      {
        value: 'D314',
        label: 'DOGLIANI',
      },
      {
        value: 'D372',
        label: 'DRONERO',
      },
      {
        value: 'D401',
        label: 'ELVA',
      },
      {
        value: 'D410',
        label: 'ENTRACQUE o ENTRAQUE',
      },
      {
        value: 'D412',
        label: 'ENVIE',
      },
      {
        value: 'D499',
        label: 'FARIGLIANO',
      },
      {
        value: 'D511',
        label: 'FAULE',
      },
      {
        value: 'D523',
        label: 'FEISOGLIO',
      },
      {
        value: 'D742',
        label: 'FOSSANO',
      },
      {
        value: 'D751',
        label: 'FRABOSA SOPRANA',
      },
      {
        value: 'D752',
        label: 'FRABOSA SOTTANA',
      },
      {
        value: 'D782',
        label: 'FRASSINO',
      },
      {
        value: 'D856',
        label: 'GAIOLA',
      },
      {
        value: 'D857',
        label: 'GAIOLA-MOIOLA',
      },
      {
        value: 'D894',
        label: 'GAMBASCA',
      },
      {
        value: 'D920',
        label: 'GARESSIO',
      },
      {
        value: 'D967',
        label: 'GENOLA',
      },
      {
        value: 'E110',
        label: 'GORRINO',
      },
      {
        value: 'E111',
        label: 'GORZEGNO',
      },
      {
        value: 'E115',
        label: 'GOTTASECCA',
      },
      {
        value: 'E118',
        label: 'GOVONE',
      },
      {
        value: 'E182',
        label: 'GRINZANE o GRINZANE CAVOUR',
      },
      {
        value: 'E251',
        label: 'GUARENE',
      },
      {
        value: 'E282',
        label: 'IGLIANO',
      },
      {
        value: 'E327',
        label: 'ISASCA',
      },
      {
        value: 'E430',
        label: 'LA MORRA o MORRA',
      },
      {
        value: 'E406',
        label: 'LAGNASCO',
      },
      {
        value: 'E539',
        label: 'LEQUIO o LEQUIO TANARO',
      },
      {
        value: 'E540',
        label: 'LEQUIO o LEQUIO BERRIA',
      },
      {
        value: 'E546',
        label: 'LESEGNO',
      },
      {
        value: 'E564',
        label: 'LEVICE',
      },
      {
        value: 'E597',
        label: 'LIMONE o LIMONE PIEMONTE',
      },
      {
        value: 'E615',
        label: 'LISIO',
      },
      {
        value: 'E699',
        label: 'LOTTULO',
      },
      {
        value: 'E789',
        label: 'MACRA',
      },
      {
        value: 'E808',
        label: 'MAGLIANO o MAGLIANO ALPI',
      },
      {
        value: 'E809',
        label: "MAGLIANO o MAGLIANO ALFIERI o MAGLIANO D'ALBA",
      },
      {
        value: 'E867',
        label: 'MALPOTREMO',
      },
      {
        value: 'E887',
        label: 'MANGO',
      },
      {
        value: 'E894',
        label: 'MANTA',
      },
      {
        value: 'E939',
        label: 'MARENE',
      },
      {
        value: 'E945',
        label: 'MARGARITA',
      },
      {
        value: 'E963',
        label: 'MARMORA',
      },
      {
        value: 'E973',
        label: 'MARSAGLIA',
      },
      {
        value: 'E988',
        label: 'MARTINIANA o MARTINIANA PO',
      },
      {
        value: 'F114',
        label: 'MELLE',
      },
      {
        value: 'F279',
        label: 'MOIOLA',
      },
      {
        value: 'F309',
        label: 'MOMBARCARO',
      },
      {
        value: 'F312',
        label: 'MOMBASIGLIO',
      },
      {
        value: 'F326',
        label: 'MONASTERO o MONASTERO DI VASCO',
      },
      {
        value: 'F329',
        label: 'MONASTEROLO o MONASTEROLO CASOTTO o MONASTEROLO CASSOTO',
      },
      {
        value: 'F330',
        label: 'MONASTEROLO o MONASTEROLO DI SAVIGLIANO',
      },
      {
        value: 'F338',
        label: 'MONCHIERO',
      },
      {
        value: 'F339',
        label: 'MONCHIERO NOVELLO o NOVELLO MONCHIERO',
      },
      {
        value: 'F351',
        label: "MONDOVI'",
      },
      {
        value: 'F355',
        label: 'MONESIGLIO',
      },
      {
        value: 'F358',
        label: "MONFORTE o MONFORTE D'ALBA",
      },
      {
        value: 'F385',
        label: "MONTA'",
      },
      {
        value: 'F405',
        label: "MONTALDO o MONTALDO DI MONDOVI'",
      },
      {
        value: 'F408',
        label: 'MONTALDO ROERO',
      },
      {
        value: 'F424',
        label: 'MONTANERA',
      },
      {
        value: 'F550',
        label: 'MONTELUPO o MONTELUPO ALBESE',
      },
      {
        value: 'F558',
        label: 'MONTEMALE o MONTEMALE DI CUNEO',
      },
      {
        value: 'F608',
        label: 'MONTEROSSO o MONTEROSSO GRANA',
      },
      {
        value: 'F654',
        label: 'MONTEU ROERO',
      },
      {
        value: 'F666',
        label: 'MONTEZEMOLO',
      },
      {
        value: 'F669',
        label: "MONTICELLI o MONTICELLI ALBA o MONTICELLO D'ALBA",
      },
      {
        value: 'F723',
        label: 'MORETTA',
      },
      {
        value: 'F743',
        label: 'MOROZZO',
      },
      {
        value: 'F809',
        label: 'MURAZZANO',
      },
      {
        value: 'F811',
        label: 'MURELLO',
      },
      {
        value: 'F846',
        label: 'NARZOLE',
      },
      {
        value: 'F863',
        label: 'NEIVE',
      },
      {
        value: 'F883',
        label: 'NEVIGLIE',
      },
      {
        value: 'F894',
        label: 'NIELLA BELBO',
      },
      {
        value: 'F895',
        label: 'NIELLA TANARO',
      },
      {
        value: 'F961',
        label: 'NOVELLO',
      },
      {
        value: 'F972',
        label: 'NUCETTO',
      },
      {
        value: 'G066',
        label: 'ONCINO',
      },
      {
        value: 'G114',
        label: 'ORMEA',
      },
      {
        value: 'G183',
        label: 'OSTANA',
      },
      {
        value: 'G228',
        label: 'PAESANA',
      },
      {
        value: 'G236',
        label: 'PAGLIERES',
      },
      {
        value: 'G240',
        label: 'PAGNO',
      },
      {
        value: 'G302',
        label: 'PAMPARATO',
      },
      {
        value: 'G339',
        label: 'PAROLDO',
      },
      {
        value: 'G457',
        label: 'PERLETTO',
      },
      {
        value: 'G458',
        label: 'PERLO',
      },
      {
        value: 'G460',
        label: 'PERNO',
      },
      {
        value: 'G526',
        label: 'PEVERAGNO',
      },
      {
        value: 'G532',
        label: 'PEZZOLO VALLE UZZONE',
      },
      {
        value: 'G561',
        label: 'PIANFEI',
      },
      {
        value: 'G575',
        label: 'PIASCO',
      },
      {
        value: 'G625',
        label: 'PIETRAPORZIO',
      },
      {
        value: 'G683',
        label: "PIOBESI o PIOBESI D'ALBA",
      },
      {
        value: 'G697',
        label: 'PIOZZO',
      },
      {
        value: 'G742',
        label: 'POCAPAGLIA',
      },
      {
        value: 'G800',
        label: 'POLONGHERA',
      },
      {
        value: 'G837',
        label: 'PONTECHIANALE',
      },
      {
        value: 'G970',
        label: 'PRADLEVE o PRADLEVES',
      },
      {
        value: 'H011',
        label: 'PRAZZO',
      },
      {
        value: 'H059',
        label: 'PRIERO',
      },
      {
        value: 'H060',
        label: 'PRIERO-MONTEZEMOLO',
      },
      {
        value: 'H068',
        label: 'PRIOCCA',
      },
      {
        value: 'H069',
        label: 'PRIOLA',
      },
      {
        value: 'H085',
        label: 'PRUNETTO',
      },
      {
        value: 'H150',
        label: 'RACCONIGI',
      },
      {
        value: 'H247',
        label: 'REVELLO',
      },
      {
        value: 'H285',
        label: 'RIFREDDO',
      },
      {
        value: 'H326',
        label: 'RITTANA',
      },
      {
        value: 'H362',
        label: 'ROASCHIA',
      },
      {
        value: 'H363',
        label: 'ROASCIO',
      },
      {
        value: 'H377',
        label: 'ROBILANTE',
      },
      {
        value: 'H378',
        label: 'ROBURENT o ROBURENTO',
      },
      {
        value: 'H391',
        label: "ROCCA CIGLIE'",
      },
      {
        value: 'H395',
        label: "ROCCA DE' BALDI",
      },
      {
        value: 'H385',
        label: 'ROCCABRUNA',
      },
      {
        value: 'H407',
        label: "ROCCAFORTE o ROCCAFORTE MONDOVI'",
      },
      {
        value: 'H447',
        label: 'ROCCASPARVERA',
      },
      {
        value: 'H453',
        label: 'ROCCAVIONE',
      },
      {
        value: 'H462',
        label: 'ROCCHETTA BELBO',
      },
      {
        value: 'H472',
        label: 'RODDI',
      },
      {
        value: 'H473',
        label: 'RODDINO',
      },
      {
        value: 'H474',
        label: 'RODELLO',
      },
      {
        value: 'H578',
        label: 'ROSSANA',
      },
      {
        value: 'H633',
        label: 'RUFFIA',
      },
      {
        value: 'H695',
        label: 'SALE o SALE DELLE LANGHE',
      },
      {
        value: 'H704',
        label: 'SALE SAN GIOVANNI',
      },
      {
        value: 'H710',
        label: 'SALICETO',
      },
      {
        value: 'H716',
        label: 'SALMORE o SALMOUR',
      },
      {
        value: 'H727',
        label: 'SALUZZO',
      },
      {
        value: 'H746',
        label: 'SAMBUCO',
      },
      {
        value: 'H747',
        label: 'SAMBUCO PIETRAPORZIO',
      },
      {
        value: 'H755',
        label: 'SAMPEIRE o SAMPEYRE',
      },
      {
        value: 'H770',
        label: 'SAN BENEDETTO o SAN BENEDETTO BELBO',
      },
      {
        value: 'H812',
        label: 'SAN DAMIANO o SAN DAMIANO MACRA',
      },
      {
        value: 'I037',
        label: "SAN MICHELE o SAN MICHELE MONDOVI'",
      },
      {
        value: 'I038',
        label: 'SAN MICHELE o SAN MICHELE PRAZZO',
      },
      {
        value: 'I104',
        label: 'SAN PIETRO DI MONTEROSSO',
      },
      {
        value: 'H851',
        label: "SANFRE'",
      },
      {
        value: 'H852',
        label: 'SANFRONT o SANFRONTE',
      },
      {
        value: 'I210',
        label: "SANT'ALBANO o SANT'ALBANO STURA",
      },
      {
        value: 'I316',
        label: "SANTA VITTORIA o SANTA VITTORIA D'ALBA",
      },
      {
        value: 'I367',
        label: 'SANTO STEFANO BELBO',
      },
      {
        value: 'I372',
        label: 'SANTO STEFANO ROERO',
      },
      {
        value: 'I470',
        label: 'SAVIGLIANO',
      },
      {
        value: 'I484',
        label: 'SCAGNELLO',
      },
      {
        value: 'I491',
        label: 'SCALETTA o SCALETTA UZZONE',
      },
      {
        value: 'I512',
        label: 'SCARNAFIGI',
      },
      {
        value: 'I513',
        label: 'SCARNAFIGI-RUFFIA',
      },
      {
        value: 'I646',
        label: "SERRALUNGA CERESETO o SERRALUNGA D'ALBA",
      },
      {
        value: 'I659',
        label: 'SERRAVALLE o SERRAVALLE DELLE LANGHE o SERRAVALLE LANGHE',
      },
      {
        value: 'I750',
        label: 'SINIO',
      },
      {
        value: 'I817',
        label: 'SOMANO',
      },
      {
        value: 'I822',
        label: 'SOMMARIVA DEL BOSCO',
      },
      {
        value: 'I823',
        label: 'SOMMARIVA PERNO',
      },
      {
        value: 'I985',
        label: 'STROPPO',
      },
      {
        value: 'L048',
        label: 'TARANTASCA',
      },
      {
        value: 'L095',
        label: 'TENDA',
      },
      {
        value: 'L241',
        label: "TORRE o TORRE MONDOVI'",
      },
      {
        value: 'L252',
        label: 'TORRE BORMIDA',
      },
      {
        value: 'L278',
        label: 'TORRE SAN GIORGIO',
      },
      {
        value: 'L283',
        label: 'TORRE UZZONE',
      },
      {
        value: 'L281',
        label: 'TORRESINA o TORRICELLA',
      },
      {
        value: 'L367',
        label: 'TREISO',
      },
      {
        value: 'L410',
        label: 'TREZZO o TREZZO TINELLA',
      },
      {
        value: 'L427',
        label: "TRINITA'",
      },
      {
        value: 'L518',
        label: 'USSOLO',
      },
      {
        value: 'L558',
        label: 'VALDIERI',
      },
      {
        value: 'L580',
        label: 'VALGRANA',
      },
      {
        value: 'L631',
        label: 'VALLORIA o VALLORIATE',
      },
      {
        value: 'L636',
        label: 'VALMALA',
      },
      {
        value: 'L729',
        label: 'VENASCA',
      },
      {
        value: 'L758',
        label: 'VERDUNO',
      },
      {
        value: 'L771',
        label: 'VERNANTE',
      },
      {
        value: 'L804',
        label: 'VERZUOLO',
      },
      {
        value: 'L817',
        label: "VEZZA o VEZZA D'ALBA",
      },
      {
        value: 'L841',
        label: 'VICO o VICOFORTE',
      },
      {
        value: 'L888',
        label: 'VIGNOLO',
      },
      {
        value: 'L942',
        label: 'VILLAFALLETTO',
      },
      {
        value: 'L974',
        label: "VILLANOVA o VILLANOVA MONDOVI'",
      },
      {
        value: 'L990',
        label: 'VILLANOVA SOLARO',
      },
      {
        value: 'L993',
        label: 'VILLANOVAVETTA',
      },
      {
        value: 'M015',
        label: 'VILLAR SAN COSTANZO',
      },
      {
        value: 'M055',
        label: 'VINADIO',
      },
      {
        value: 'M063',
        label: 'VIOLA',
      },
      {
        value: 'M136',
        label: 'VOTTIGNASCO',
      },
    ],
  },

  {
    value: 'CO',
    label: 'Como',
    children: [
      {
        value: 'A002',
        label: 'ABBADIA o ABBADIA SOPRA ADDA',
      },
      {
        value: 'A046',
        label: 'ACQUATE',
      },
      {
        value: 'A114',
        label: 'AIZURRO',
      },
      {
        value: 'A142',
        label: 'ALBATE',
      },
      {
        value: 'A143',
        label: 'ALBAVILLA',
      },
      {
        value: 'A152',
        label: 'ALBESE',
      },
      {
        value: 'A153',
        label: 'ALBESE CON CASSANO',
      },
      {
        value: 'A164',
        label: 'ALBIOLO',
      },
      {
        value: 'A168',
        label: 'ALBOGASIO',
      },
      {
        value: 'A224',
        label: 'ALSERIO',
      },
      {
        value: 'M383',
        label: 'ALTA VALLE INTELVI',
      },
      {
        value: 'A249',
        label: 'ALZATE o ALZATE BRIANZA o ALZATE CON VERZAGO',
      },
      {
        value: 'A301',
        label: 'ANNONE o ANNONE DI BRIANZA',
      },
      {
        value: 'A319',
        label: 'ANZANO o ANZANO DEL PARCO',
      },
      {
        value: 'A333',
        label: 'APPIANO o APPIANO GENTILE',
      },
      {
        value: 'A364',
        label: 'ARCELLASCO',
      },
      {
        value: 'A391',
        label: 'ARGEGNO',
      },
      {
        value: 'A430',
        label: 'AROSIO',
      },
      {
        value: 'A469',
        label: 'ASNAGO',
      },
      {
        value: 'A476',
        label: 'ASSO',
      },
      {
        value: 'A543',
        label: 'BAGAGGERA',
      },
      {
        value: 'A582',
        label: 'BAIEDO',
      },
      {
        value: 'A595',
        label: 'BALLABIO INFERIORE',
      },
      {
        value: 'A596',
        label: 'BALLABIO SUPERIORE',
      },
      {
        value: 'A642',
        label: 'BARCONE',
      },
      {
        value: 'A670',
        label: 'BARNI',
      },
      {
        value: 'A680',
        label: 'BARTESATE',
      },
      {
        value: 'A705',
        label: 'BASSANO DI TRONZANO o TRONZANO o TRONZANO LAGO MAGGIORE',
      },
      {
        value: 'A744',
        label: 'BELLAGIO',
      },
      {
        value: 'M335',
        label: 'BELLAGIO',
      },
      {
        value: 'A748',
        label: 'BELLEDO',
      },
      {
        value: 'A778',
        label: 'BENE o BENE LARIO',
      },
      {
        value: 'A791',
        label: 'BEREGAZZO o BEREGAZZO CON FIGLIARO',
      },
      {
        value: 'A800',
        label: 'BERNAGA',
      },
      {
        value: 'A803',
        label: 'BERNATE o BERNATE DI COMO o BERNATE ROSALES',
      },
      {
        value: 'A869',
        label: 'BIGLIO',
      },
      {
        value: 'A870',
        label: 'BINAGO',
      },
      {
        value: 'A873',
        label: 'BINDO',
      },
      {
        value: 'A898',
        label: 'BIZZARONE',
      },
      {
        value: 'A904',
        label: 'BLESSAGNO',
      },
      {
        value: 'A905',
        label: 'BLEVIO',
      },
      {
        value: 'A598',
        label: 'BOSCO o BOSCO DI GAVIRATE o BALLARATE',
      },
      {
        value: 'B072',
        label: 'BOSCO o BOSCO VALTRAVAGLIA',
      },
      {
        value: 'B081',
        label: 'BOSISIO o BOSISIO PARINI',
      },
      {
        value: 'B127',
        label: 'BRECCIA',
      },
      {
        value: 'B133',
        label: 'BREGLIA',
      },
      {
        value: 'B134',
        label: 'BREGNANO',
      },
      {
        value: 'B144',
        label: 'BRENNA',
      },
      {
        value: 'B146',
        label: 'BRENNO o BRENNO USERIA',
      },
      {
        value: 'B147',
        label: 'BRENNO o BRENNO DELLA TORRE',
      },
      {
        value: 'B168',
        label: 'BRIANZOLA',
      },
      {
        value: 'B172',
        label: 'BRIENNO',
      },
      {
        value: 'B218',
        label: 'BRUNATE',
      },
      {
        value: 'B241',
        label: 'BUCCINIGO',
      },
      {
        value: 'B252',
        label: 'BUGGIOLO',
      },
      {
        value: 'B253',
        label: 'BUGIALLO',
      },
      {
        value: 'B262',
        label: 'BULGAROGRASSO',
      },
      {
        value: 'B263',
        label: 'BULGORELLO',
      },
      {
        value: 'B313',
        label: 'CABIATE',
      },
      {
        value: 'B346',
        label: 'CADORAGO',
      },
      {
        value: 'B353',
        label: 'CAGLIANO',
      },
      {
        value: 'B355',
        label: 'CAGLIO',
      },
      {
        value: 'B359',
        label: 'CAGNO',
      },
      {
        value: 'B475',
        label: 'CAMERLATA',
      },
      {
        value: 'B487',
        label: 'CAMNAGO o CAMNAGO SAN MARTINO o CAMNAGO VOLTA',
      },
      {
        value: 'B488',
        label: "CAMNAGO D'UGGIATE o CAMNAGO FALOPPIA",
      },
      {
        value: 'B495',
        label: 'CAMPAGNANO o CAMPAGNANO VEDASCA',
      },
      {
        value: 'B513',
        label: "CAMPIONE o CAMPIONE D'ITALIA",
      },
      {
        value: 'B639',
        label: "CANTU'",
      },
      {
        value: 'B641',
        label: 'CANZO',
      },
      {
        value: 'B652',
        label: 'CAPIAGO',
      },
      {
        value: 'B653',
        label: 'CAPIAGO INTIMIANO o SALVADERA',
      },
      {
        value: 'B654',
        label: 'CAPIATE',
      },
      {
        value: 'B728',
        label: 'CARATE o CARATE LARIO',
      },
      {
        value: 'B730',
        label: 'CARATE URIO',
      },
      {
        value: 'B742',
        label: 'CARBONATE',
      },
      {
        value: 'B747',
        label: 'CARCANO',
      },
      {
        value: 'B761',
        label: 'CARELLA CON MARIAGA',
      },
      {
        value: 'B764',
        label: 'CARENO',
      },
      {
        value: 'B778',
        label: 'CARIMATE',
      },
      {
        value: 'B785',
        label: 'CARLAZZO',
      },
      {
        value: 'B786',
        label: 'CARLAZZO VALSOLDA',
      },
      {
        value: 'B851',
        label: 'CARUGO',
      },
      {
        value: 'B852',
        label: 'CARUGO AROSIO o CARUGO CON AROSIO',
      },
      {
        value: 'B926',
        label: 'CASANOVA o CASANOVA DI UGGIATE o CASANOVA LANZA',
      },
      {
        value: 'B942',
        label: "CASASCO o CASASCO D'INTELVI",
      },
      {
        value: 'B970',
        label: 'CASIRAGO',
      },
      {
        value: 'B973',
        label: 'CASLETTO',
      },
      {
        value: 'B974',
        label: "CASLINO o CASLINO D'ERBA o CASLINO PIANO D'ERBA",
      },
      {
        value: 'B975',
        label: 'CASLINO o CASLINO AL PIANO',
      },
      {
        value: 'B976',
        label: 'CASNATE',
      },
      {
        value: 'B977',
        label: 'CASNATE CON BERNATE',
      },
      {
        value: 'B995',
        label: 'CASSAGO',
      },
      {
        value: 'B999',
        label: 'CASSANO o CASSANO VALCUVIA',
      },
      {
        value: 'C001',
        label: 'CASSANO o CASSANO ALBESE',
      },
      {
        value: 'C011',
        label: "CASSINA DE' BRACCHI",
      },
      {
        value: 'C016',
        label: 'CASSINA MARIAGA',
      },
      {
        value: 'C020',
        label: 'CASSINA RIZZARDI',
      },
      {
        value: 'C179',
        label: 'CASTELLO o CASTELLO VALSOLDA',
      },
      {
        value: 'C180',
        label: 'CASTELLO o CASTELLO SOPRA LECCO',
      },
      {
        value: 'C181',
        label: 'CASTELLO o CASTELLO VALTRAVAGLIA o CASTELVECCANA',
      },
      {
        value: 'C206',
        label: 'CASTELMARTE',
      },
      {
        value: 'C220',
        label: 'CASTELNUOVO o CASTELNUOVO BAZENTE o CASTELNUOVO BOZZENTE',
      },
      {
        value: 'C299',
        label: "CASTIGLIONE o CASTIGLIONE D'INTELVI",
      },
      {
        value: 'C300',
        label: 'CASTIGLIONE o CASTIGLIONE OLONA',
      },
      {
        value: 'C374',
        label: 'CAVALLASCA',
      },
      {
        value: 'C381',
        label: 'CAVARGNA',
      },
      {
        value: 'C397',
        label: 'CAVERSACCIO',
      },
      {
        value: 'C409',
        label: 'CAZZAGO o CAZZAGO BRABBIA',
      },
      {
        value: 'C468',
        label: 'CENTEMERO',
      },
      {
        value: 'M394',
        label: 'CENTRO VALLE INTELVI',
      },
      {
        value: 'C482',
        label: "CERANO o CERANO D'INTELVI",
      },
      {
        value: 'C499',
        label: 'CEREDA',
      },
      {
        value: 'C516',
        label: 'CERMENATE',
      },
      {
        value: 'C520',
        label: 'CERNOBBIO',
      },
      {
        value: 'C521',
        label: 'CERNUSCO LOMBARDONE o CERNUSCO LOMBARDONE',
      },
      {
        value: 'C522',
        label: 'CERNUSCO MONTEVECCHIA',
      },
      {
        value: 'C535',
        label: 'CERRO o CERRO LAGO MAGGIORE',
      },
      {
        value: 'C563',
        label: 'CESANA o CESANA DI BRIANZA o CESANA BRIANZA',
      },
      {
        value: 'C572',
        label: 'CESELLO BRIANZA',
      },
      {
        value: 'C664',
        label: 'CHIUSO',
      },
      {
        value: 'C688',
        label: 'CIMA',
      },
      {
        value: 'C724',
        label: 'CIRIMIDO',
      },
      {
        value: 'C753',
        label: 'CIVELLO',
      },
      {
        value: 'C754',
        label: 'CIVENNA',
      },
      {
        value: 'C762',
        label: 'CIVIGLIO',
      },
      {
        value: 'C787',
        label: 'CLAINO CON OSTENO',
      },
      {
        value: 'C832',
        label: 'COLCIAGO',
      },
      {
        value: 'C889',
        label: 'COLOGNA',
      },
      {
        value: 'C902',
        label: 'COLONNO',
      },
      {
        value: 'M336',
        label: 'COLVERDE',
      },
      {
        value: 'C933',
        label: 'COMO',
      },
      {
        value: 'C936',
        label: 'COMUNE DELLA VALSOLDA o VALSOLDA',
      },
      {
        value: 'C945',
        label: 'CONCENEDO',
      },
      {
        value: 'C965',
        label: 'CONSIGLIO DI RUMO',
      },
      {
        value: 'C966',
        label: 'CONSONNO',
      },
      {
        value: 'C970',
        label: 'CONTRA',
      },
      {
        value: 'C997',
        label: 'CORENNO o CORENNO PLINIO',
      },
      {
        value: 'D041',
        label: 'CORRIDO',
      },
      {
        value: 'D047',
        label: 'CORTABBIO',
      },
      {
        value: 'D091',
        label: 'COSSANO o DUE COSSANI',
      },
      {
        value: 'D131',
        label: 'CRANDOLA o CRANDOLA VALSASSINA',
      },
      {
        value: 'D147',
        label: 'CREMIA',
      },
      {
        value: 'D148',
        label: 'CREMNAGO',
      },
      {
        value: 'D163',
        label: 'CRESSOGNO',
      },
      {
        value: 'D167',
        label: 'CREVENNA',
      },
      {
        value: 'D173',
        label: 'CROCE',
      },
      {
        value: 'D196',
        label: 'CUCCIAGO',
      },
      {
        value: 'D232',
        label: 'CUSINO',
      },
      {
        value: 'D254',
        label: 'DASIO',
      },
      {
        value: 'D310',
        label: 'DIZZASCO',
      },
      {
        value: 'D329',
        label: 'DOMASO',
      },
      {
        value: 'D341',
        label: 'DONGO',
      },
      {
        value: 'D355',
        label: 'DOSSO DEL LIRO',
      },
      {
        value: 'D359',
        label: 'DOZIO',
      },
      {
        value: 'D363',
        label: 'DRANO',
      },
      {
        value: 'D369',
        label: 'DREZZO',
      },
      {
        value: 'D416',
        label: 'ERBA',
      },
      {
        value: 'D417',
        label: 'ERBA INCINO',
      },
      {
        value: 'D435',
        label: 'ESINO INFERIORE',
      },
      {
        value: 'D437',
        label: 'ESINO SUPERIORE',
      },
      {
        value: 'D445',
        label: 'EUPILIO',
      },
      {
        value: 'D448',
        label: 'FABBRICA o FABBRICA DURINI',
      },
      {
        value: 'D462',
        label: 'FAGGETO LARIO',
      },
      {
        value: 'D482',
        label: 'FALOPPIO',
      },
      {
        value: 'D531',
        label: "FENEGRO'",
      },
      {
        value: 'D551',
        label: 'FERRERA o FERRERA DI VARESE',
      },
      {
        value: 'D579',
        label: 'FIGINO o FIGINO SERENZA',
      },
      {
        value: 'D581',
        label: 'FIGLIARO o MIRABELLO COMASCO',
      },
      {
        value: 'D605',
        label: 'FINO o FINO MORNASCO',
      },
      {
        value: 'D846',
        label: 'GAGGINO',
      },
      {
        value: 'D871',
        label: 'GALLIATE o GALLIATE LOMBARDO',
      },
      {
        value: 'D930',
        label: 'GARZENO',
      },
      {
        value: 'D974',
        label: 'GERA o GERA LARIO',
      },
      {
        value: 'D985',
        label: 'GERMANEDO',
      },
      {
        value: 'D986',
        label: 'GERMASINO',
      },
      {
        value: 'E051',
        label: 'GIRONICO',
      },
      {
        value: 'E117',
        label: 'GOTTRO',
      },
      {
        value: 'B334',
        label: 'GRAGLIO CON CADERO o CADERO CON GRAGLIO',
      },
      {
        value: 'E139',
        label: 'GRANDATE',
      },
      {
        value: 'E140',
        label: 'GRANDOLA',
      },
      {
        value: 'E141',
        label: 'GRANDOLA ED UNITI',
      },
      {
        value: 'E151',
        label: 'GRAVEDONA',
      },
      {
        value: 'M315',
        label: 'GRAVEDONA ED UNITI',
      },
      {
        value: 'E172',
        label: 'GRIANTE',
      },
      {
        value: 'E190',
        label: 'GRONA',
      },
      {
        value: 'E235',
        label: 'GUANZATE',
      },
      {
        value: 'E286',
        label: 'IMBERIDO',
      },
      {
        value: 'E293',
        label: 'INCINO',
      },
      {
        value: 'E298',
        label: 'INDOVERO o NARRO',
      },
      {
        value: 'E299',
        label: 'INDUNO o INDUNO OLONA',
      },
      {
        value: 'E302',
        label: 'INTIMIANO',
      },
      {
        value: 'E309',
        label: 'INVERIGO',
      },
      {
        value: 'E347',
        label: 'ISOLA COMACINA',
      },
      {
        value: 'E405',
        label: 'LAGLIO o LAGLIO BRIENNO',
      },
      {
        value: 'E416',
        label: 'LAINO',
      },
      {
        value: 'E428',
        label: 'LAMBRUGO',
      },
      {
        value: 'E444',
        label: "LANZO o LANZO D'INTELVI",
      },
      {
        value: 'E446',
        label: 'LAORCA',
      },
      {
        value: 'E462',
        label: 'LASNIGO',
      },
      {
        value: 'E521',
        label: 'LEMNA',
      },
      {
        value: 'E525',
        label: 'LENNO',
      },
      {
        value: 'E529',
        label: 'LENTATE o LENTATE VERBANO o OSMATE o OSMATE-LENTATE',
      },
      {
        value: 'E568',
        label: 'LEZZA',
      },
      {
        value: 'E569',
        label: 'LEZZENO',
      },
      {
        value: 'E582',
        label: 'LIETO COLLE',
      },
      {
        value: 'E593',
        label: 'LIMIDO o LIMIDO COMASCO',
      },
      {
        value: 'E598',
        label: 'LIMONTA',
      },
      {
        value: 'E604',
        label: 'LINZANICO',
      },
      {
        value: 'E607',
        label: 'LIPOMO',
      },
      {
        value: 'E623',
        label: 'LIVO',
      },
      {
        value: 'E638',
        label: 'LOCATE o LOCATE VARESINO',
      },
      {
        value: 'E657',
        label: 'LOMANIGA',
      },
      {
        value: 'E659',
        label: 'LOMAZZO',
      },
      {
        value: 'E679',
        label: 'LONGONE o LONGONE AL SEGRINO',
      },
      {
        value: 'E702',
        label: 'LOVENO o LOVENO SOPRA MENAGGIO',
      },
      {
        value: 'E721',
        label: 'LUCINO',
      },
      {
        value: 'E735',
        label: 'LUISAGO',
      },
      {
        value: 'E749',
        label: "LURAGO o LURAGO D'ERBA",
      },
      {
        value: 'E750',
        label: 'LURAGO MARINONE',
      },
      {
        value: 'E753',
        label: 'LURATE ABBATE o LURATE CACCIVIO',
      },
      {
        value: 'E734',
        label: 'LUVINO o LUINO',
      },
      {
        value: 'E781',
        label: 'MACCIO',
      },
      {
        value: 'E802',
        label: 'MAGGIANICO',
      },
      {
        value: 'E830',
        label: 'MAGREGLIO',
      },
      {
        value: 'E879',
        label: 'MANDELLO o MANDELLO DEL LARIO',
      },
      {
        value: 'E951',
        label: 'MARIANO o MARIANO COMENSE',
      },
      {
        value: 'F007',
        label: 'MASCIAGO o MASCIAGO PRIMO',
      },
      {
        value: 'F017',
        label: 'MASLIANICO',
      },
      {
        value: 'F120',
        label: 'MENAGGIO',
      },
      {
        value: 'F151',
        label: 'MERONE',
      },
      {
        value: 'F181',
        label: 'MEZZEGRA',
      },
      {
        value: 'F222',
        label: 'MINOPRIO',
      },
      {
        value: 'F273',
        label: 'MOIANA',
      },
      {
        value: 'F285',
        label: 'MOLINA',
      },
      {
        value: 'F305',
        label: 'MOLTRASIO',
      },
      {
        value: 'F314',
        label: 'MOMBELLO o MOMBELLO LAGO MAGGIORE',
      },
      {
        value: 'F349',
        label: 'MONDONICO',
      },
      {
        value: 'F372',
        label: 'MONGUZZO',
      },
      {
        value: 'F425',
        label: 'MONTANO o MONTANO COMASCO',
      },
      {
        value: 'F427',
        label: 'MONTANO LUCINO',
      },
      {
        value: 'F583',
        label: 'MONTE OLIMPINO',
      },
      {
        value: 'F564',
        label: 'MONTEMEZZO',
      },
      {
        value: 'F657',
        label: 'MONTEVECCHIA o MONTEVECCHIA',
      },
      {
        value: 'F688',
        label: 'MONTORFANO',
      },
      {
        value: 'F788',
        label: 'MOZZATE',
      },
      {
        value: 'F828',
        label: 'MUSSO',
      },
      {
        value: 'F850',
        label: 'NAVA',
      },
      {
        value: 'F877',
        label: 'NESSO',
      },
      {
        value: 'F954',
        label: 'NOVATE o NOVATE BRIANZA',
      },
      {
        value: 'F958',
        label: 'NOVEDRATE',
      },
      {
        value: 'G013',
        label: 'OLATE',
      },
      {
        value: 'G017',
        label: 'OLCIO',
      },
      {
        value: 'G025',
        label: 'OLGIATE o OLGIATE COMASCO',
      },
      {
        value: 'G026',
        label: 'OLGIATE o OLGIATE MOLGORA',
      },
      {
        value: 'G027',
        label: 'OLGIATE CALCO',
      },
      {
        value: 'G056',
        label: 'OLTRONA o OLTRONA DI SAN MAMETTE',
      },
      {
        value: 'G072',
        label: 'ONNO',
      },
      {
        value: 'G100',
        label: 'ORIANO o ORIANO DI BRIANZA',
      },
      {
        value: 'G126',
        label: 'ORSENIGO',
      },
      {
        value: 'G182',
        label: 'OSSUCCIO',
      },
      {
        value: 'G218',
        label: "PADERNO o PADERNO D'ADDA",
      },
      {
        value: 'G223',
        label: 'PADERNO ROBBIATE o ROBBIATE PADERNO o ROBBIATE',
      },
      {
        value: 'G239',
        label: 'PAGNANO o PAGNANO VALSASSINA',
      },
      {
        value: 'G256',
        label: 'PALANZO',
      },
      {
        value: 'G329',
        label: "PARE'",
      },
      {
        value: 'G345',
        label: 'PARRAVICINO',
      },
      {
        value: 'G415',
        label: 'PEGLIO',
      },
      {
        value: 'G427',
        label: 'PELLIO INTELVI',
      },
      {
        value: 'G440',
        label: 'PENZANO',
      },
      {
        value: 'G503',
        label: 'PESSINA o PESSINA VALSASSINA',
      },
      {
        value: 'G556',
        label: 'PIANELLO o PIANELLO DEL LARIO',
      },
      {
        value: 'G567',
        label: 'PIANO o PIANO PORLEZZA',
      },
      {
        value: 'G581',
        label: 'PIAZZA o PIAZZA SANTO STEFANO',
      },
      {
        value: 'G665',
        label: 'PIGRA',
      },
      {
        value: 'G677',
        label: 'PINO o PINO SULLA SPONDA DEL LAGO MAGGIORE',
      },
      {
        value: 'G737',
        label: 'PLESIO',
      },
      {
        value: 'G773',
        label: 'POGNANA o POGNANA LARIO',
      },
      {
        value: 'G821',
        label: 'PONNA',
      },
      {
        value: 'G828',
        label: 'PONTE',
      },
      {
        value: 'G847',
        label: 'PONTE LAMBRO o PONTELEZZA',
      },
      {
        value: 'G876',
        label: 'PONZATE',
      },
      {
        value: 'G889',
        label: 'PORLEZZA',
      },
      {
        value: 'G906',
        label: 'PORTO o PORTO CERESIO',
      },
      {
        value: 'G907',
        label: 'PORTO o PORTO VALTRAVAGLIA',
      },
      {
        value: 'H074',
        label: 'PROSERPIO',
      },
      {
        value: 'H093',
        label: 'PURIA',
      },
      {
        value: 'H094',
        label: 'PUSIANO',
      },
      {
        value: 'H170',
        label: 'RAMPONIO',
      },
      {
        value: 'H171',
        label: 'RAMPONIO VERNA',
      },
      {
        value: 'H172',
        label: 'RANCIO o RANCIO DI LECCO',
      },
      {
        value: 'H173',
        label: 'RANCIO o RANCIO VALCUVIA',
      },
      {
        value: 'H197',
        label: 'RAVELLINO o TEGNONE',
      },
      {
        value: 'H208',
        label: 'REBBIO',
      },
      {
        value: 'H255',
        label: 'REZZAGO',
      },
      {
        value: 'H259',
        label: 'REZZO CAVARGNA o VAL REZZO',
      },
      {
        value: 'H260',
        label: 'REZZONICO',
      },
      {
        value: 'H478',
        label: 'RODERO',
      },
      {
        value: 'H487',
        label: 'ROGGIANO o ROGGIANO VALTRAVAGLIA',
      },
      {
        value: 'H510',
        label: 'ROMANO o ROMANO BRIANZA',
      },
      {
        value: 'H521',
        label: 'RONAGO',
      },
      {
        value: 'H548',
        label: 'RONGIO',
      },
      {
        value: 'H601',
        label: 'ROVELLASCA o ROVI PORRO',
      },
      {
        value: 'H602',
        label: 'ROVELLO o ROVELLO PORRO',
      },
      {
        value: 'H603',
        label: 'ROVENNA',
      },
      {
        value: 'H651',
        label: 'SABBIONCELLO',
      },
      {
        value: 'H679',
        label: 'SALA o SALA COMACINA',
      },
      {
        value: 'H680',
        label: 'SALA o SALA AL BARRO',
      },
      {
        value: 'H760',
        label: 'SAN BARTOLOMEO o SAN BARTOLOMEO VAL CAVARGNA',
      },
      {
        value: 'H761',
        label: 'SAN BARTOLOMEO o SAN BARTOLOMEO AL BOSCO',
      },
      {
        value: 'H830',
        label: 'SAN FEDELE o SAN FEDELE INTELVI',
      },
      {
        value: 'H840',
        label: 'SAN FERMO DELLA BATTAGLIA o VERGOSA',
      },
      {
        value: 'H905',
        label: 'SAN GIOVANNI ALLA CASTAGNA',
      },
      {
        value: 'I051',
        label: 'SAN NAZZARO o SAN NAZZARO VAL CAVARGNA',
      },
      {
        value: 'I162',
        label: 'SAN SIRO',
      },
      {
        value: 'I167',
        label: "SANT'ABBONDIO",
      },
      {
        value: 'I257',
        label: "SANT'AMBROGIO o SANT'AMBROGIO-OLONA",
      },
      {
        value: 'I241',
        label: 'SANTA MARIA DI ROVAGNATE',
      },
      {
        value: 'I252',
        label: 'SANTA MARIA REZZONICO',
      },
      {
        value: 'I313',
        label: 'SANTA VALERIA',
      },
      {
        value: 'I446',
        label: 'SARTIRANA o SARTIRANA BRIANTEA',
      },
      {
        value: 'I508',
        label: 'SCARENNA',
      },
      {
        value: 'I509',
        label: 'SCARIA',
      },
      {
        value: 'I529',
        label: 'SCHIGNANO',
      },
      {
        value: 'I572',
        label: 'SEGHEBBIA',
      },
      {
        value: 'I611',
        label: 'SENNA o SENNA COMASCO',
      },
      {
        value: 'I620',
        label: 'SEPRIO',
      },
      {
        value: 'I792',
        label: 'SOLBIATE',
      },
      {
        value: 'M412',
        label: 'SOLBIATE CON CAGNO',
      },
      {
        value: 'I814',
        label: 'SOLZAGO',
      },
      {
        value: 'I816',
        label: 'SOMANA',
      },
      {
        value: 'I856',
        label: 'SORICO',
      },
      {
        value: 'I860',
        label: 'SORMANO',
      },
      {
        value: 'I943',
        label: 'STAZZONA',
      },
      {
        value: 'I944',
        label: 'STAZZONA-GERMASINO',
      },
      {
        value: 'L071',
        label: 'TAVERNERIO',
      },
      {
        value: 'L080',
        label: 'TAVORDO',
      },
      {
        value: 'L228',
        label: 'TORNO',
      },
      {
        value: 'L343',
        label: 'TRAVERSA',
      },
      {
        value: 'L365',
        label: 'TREGOLO',
      },
      {
        value: 'L370',
        label: 'TREMEZZINA',
      },
      {
        value: 'M341',
        label: 'TREMEZZINA',
      },
      {
        value: 'L371',
        label: 'TREMEZZO',
      },
      {
        value: 'L395',
        label: 'TREVANO',
      },
      {
        value: 'L413',
        label: 'TREZZONE',
      },
      {
        value: 'L470',
        label: 'TURATE',
      },
      {
        value: 'L486',
        label: 'UGGIATE',
      },
      {
        value: 'L487',
        label: 'UGGIATE-TREVANO',
      },
      {
        value: 'L504',
        label: 'URIO',
      },
      {
        value: 'L547',
        label: 'VALBRONA',
      },
      {
        value: 'L640',
        label: 'VALMOREA',
      },
      {
        value: 'L671',
        label: 'VARANO o VARANO BORGHI',
      },
      {
        value: 'L695',
        label: 'VASSENA',
      },
      {
        value: 'L703',
        label: 'VEDANO o VEDANO OLONA',
      },
      {
        value: 'L715',
        label: 'VELESO',
      },
      {
        value: 'L737',
        label: 'VENIANO',
      },
      {
        value: 'L748',
        label: 'VERCANA',
      },
      {
        value: 'L754',
        label: 'VERDERIO',
      },
      {
        value: 'L760',
        label: 'VERGANO o VILLA VERGANO',
      },
      {
        value: 'L770',
        label: 'VERNA',
      },
      {
        value: 'L791',
        label: 'VERTEMATE',
      },
      {
        value: 'L792',
        label: 'VERTEMATE CON MINOPRIO',
      },
      {
        value: 'L960',
        label: "VILL'ALBESE",
      },
      {
        value: 'L956',
        label: 'VILLA GUARDIA',
      },
      {
        value: 'M010',
        label: 'VILLA ROMANO',
      },
      {
        value: 'M054',
        label: 'VIMOGNO',
      },
      {
        value: 'M076',
        label: 'VISINO',
      },
      {
        value: 'M156',
        label: 'ZELBIO',
      },
      {
        value: 'M157',
        label: 'ZELBIO VELESO',
      },
    ],
  },

  {
    value: 'CR',
    label: 'Cremona',
    children: [
      {
        value: 'A037',
        label: 'ACQUALUNGA BADONA',
      },
      {
        value: 'A039',
        label: 'ACQUANEGRA o ACQUANEGRA CREMONESE',
      },
      {
        value: 'A076',
        label: 'AGNADELLO',
      },
      {
        value: 'A147',
        label: 'ALBERA o TRIBURGO',
      },
      {
        value: 'A190',
        label: 'ALFIANO o ALFIANO ED UNITI',
      },
      {
        value: 'A299',
        label: 'ANNICCO',
      },
      {
        value: 'A384',
        label: 'ARDOLE SAN MARINO',
      },
      {
        value: 'A526',
        label: 'AZZANELLO',
      },
      {
        value: 'A554',
        label: 'BAGNAROLO',
      },
      {
        value: 'A570',
        label: 'BAGNOLO o BAGNOLO CREMASCO',
      },
      {
        value: 'A636',
        label: 'BARBISELLE',
      },
      {
        value: 'A685',
        label: 'BARZANIGA',
      },
      {
        value: 'A830',
        label: 'BETTENESCO',
      },
      {
        value: 'A871',
        label: 'BINANUOVA',
      },
      {
        value: 'A972',
        label: 'BONEMERSE',
      },
      {
        value: 'A986',
        label: 'BORDOLANO',
      },
      {
        value: 'B087',
        label: 'BOTTAIANO',
      },
      {
        value: 'B119',
        label: 'BRANCERE',
      },
      {
        value: 'B129',
        label: "BREDA DE' BUGNI o CASTELVERDE",
      },
      {
        value: 'B130',
        label: 'BREDA GUAZZONE',
      },
      {
        value: 'B199',
        label: 'BROLPASINO',
      },
      {
        value: 'B320',
        label: "CA' D'ANDREA",
      },
      {
        value: 'B322',
        label: "CA' DE' BONAVOGLI",
      },
      {
        value: 'B323',
        label: "CA' DE' CAGGI",
      },
      {
        value: 'B324',
        label: "CA' DE' CORTI",
      },
      {
        value: 'B333',
        label: "CA' DE' QUINZANI",
      },
      {
        value: 'B336',
        label: "CA' DE' SFONDRATI",
      },
      {
        value: 'B337',
        label: "CA' DE' SORESINI",
      },
      {
        value: 'B338',
        label: "CA' DE' STAOLI",
      },
      {
        value: 'B339',
        label: "CA' DE' STEFANI",
      },
      {
        value: 'B439',
        label: 'CALVATONE',
      },
      {
        value: 'B484',
        label: 'CAMISANO',
      },
      {
        value: 'B498',
        label: 'CAMPAGNOLA o CAMPAGNOLA CREMASCA',
      },
      {
        value: 'B622',
        label: 'CANOVA DEL MORBASCO',
      },
      {
        value: 'B623',
        label: 'CANOVA OLZANO',
      },
      {
        value: 'B625',
        label: 'CANSERO',
      },
      {
        value: 'B650',
        label: 'CAPERGNANICA',
      },
      {
        value: 'B679',
        label: 'CAPPELLA CANTONE o CAPPELLA CANTONE CON OSCASALE',
      },
      {
        value: 'B680',
        label: "CAPPELLA DE' PICENARDI",
      },
      {
        value: 'B686',
        label: 'CAPRALBA o CAPRALBA CON FARINATE',
      },
      {
        value: 'B811',
        label: 'CARPANETA CON DOSIMO',
      },
      {
        value: 'B849',
        label: 'CARUBERTO',
      },
      {
        value: 'B869',
        label: 'CASALBUTTANO o CASALBUTTANO ED UNITI',
      },
      {
        value: 'B874',
        label: 'CASALE o CASALE CREMASCO',
      },
      {
        value: 'B881',
        label: 'CASALE CREMASCO-VIDOLASCO',
      },
      {
        value: 'B889',
        label: 'CASALETTO CEREDANO',
      },
      {
        value: 'B890',
        label: 'CASALETTO DI SOPRA',
      },
      {
        value: 'B891',
        label: 'CASALETTO VAPRIO',
      },
      {
        value: 'B898',
        label: 'CASALMAGGIORE',
      },
      {
        value: 'B900',
        label: 'CASALMORANO',
      },
      {
        value: 'B908',
        label: 'CASALORZO GEROLDI',
      },
      {
        value: 'B913',
        label: 'CASALSIGONE',
      },
      {
        value: 'B914',
        label: 'CASALSIGONE ED UNITI o POZZAGLIO ED UNITI',
      },
      {
        value: 'M224',
        label: 'CASANOVA DEL MORBASCO',
      },
      {
        value: 'B931',
        label: 'CASANUOVA OFFREDI',
      },
      {
        value: 'C026',
        label: 'CASCINE',
      },
      {
        value: 'C029',
        label: 'CASCINE GANDINI',
      },
      {
        value: 'C115',
        label: 'CASTEL GABBIANO o GABBIANO',
      },
      {
        value: 'C089',
        label: 'CASTELDIDONE',
      },
      {
        value: 'C109',
        label: "CASTELFRANCO o CASTELFRANCO D'OGLIO",
      },
      {
        value: 'C153',
        label: 'CASTELLEONE',
      },
      {
        value: 'C221',
        label: 'CASTELNUOVO o CASTELNUOVO CREMASCO',
      },
      {
        value: 'C233',
        label: 'CASTELNUOVO DEL VESCOVO',
      },
      {
        value: 'C234',
        label: 'CASTELNUOVO DEL ZAPPA',
      },
      {
        value: 'C239',
        label: 'CASTELNUOVO GHERARDI',
      },
      {
        value: 'C249',
        label: 'CASTELPONZONE',
      },
      {
        value: 'C290',
        label: 'CASTELVISCONTI',
      },
      {
        value: 'C386',
        label: 'CAVA TIGOZZI',
      },
      {
        value: 'C373',
        label: 'CAVALLARA',
      },
      {
        value: 'C433',
        label: 'CELLA',
      },
      {
        value: 'C435',
        label: 'CELLA DATI o PUGNOLO',
      },
      {
        value: 'C634',
        label: 'CHIEVE',
      },
      {
        value: 'C678',
        label: 'CICOGNOLO',
      },
      {
        value: 'C683',
        label: 'CIGNONE',
      },
      {
        value: 'C703',
        label: "CINGIA DE' BOTTI",
      },
      {
        value: 'C899',
        label: 'COLOMBAROLO',
      },
      {
        value: 'D034',
        label: 'CORPI SANTI DI CREMONA',
      },
      {
        value: 'D055',
        label: "CORTE DE' CORTESI",
      },
      {
        value: 'D056',
        label: "CORTE DE' CORTESI CON CIGNONE",
      },
      {
        value: 'D057',
        label: "CORTE DE' FRATI",
      },
      {
        value: 'D060',
        label: 'CORTE MADAMA',
      },
      {
        value: 'D071',
        label: 'CORTETANO',
      },
      {
        value: 'D115',
        label: "COSTA SANT'ABRAMO",
      },
      {
        value: 'D140',
        label: 'CREDERA',
      },
      {
        value: 'M252',
        label: 'CREDERA CON ROVERETO',
      },
      {
        value: 'D141',
        label: 'CREDERA RUBBIANO',
      },
      {
        value: 'D142',
        label: 'CREMA',
      },
      {
        value: 'D150',
        label: 'CREMONA o CREMONA ED UNITI',
      },
      {
        value: 'D151',
        label: 'CREMOSANO',
      },
      {
        value: 'D186',
        label: "CROTTA D'ADDA",
      },
      {
        value: 'D203',
        label: 'CUMIGNANO o CUMIGNANO SUL NAVIGLIO',
      },
      {
        value: 'D278',
        label: 'DEROVERE',
      },
      {
        value: 'D353',
        label: 'DOSSO BAROARDO',
      },
      {
        value: 'D354',
        label: "DOSSO DE' FRATI",
      },
      {
        value: 'D358',
        label: 'DOVERA',
      },
      {
        value: 'D370',
        label: 'DRIZZONA',
      },
      {
        value: 'D378',
        label: 'DUEMIGLIA',
      },
      {
        value: 'D500',
        label: 'FARINATE',
      },
      {
        value: 'D533',
        label: 'FENGO',
      },
      {
        value: 'D574',
        label: 'FIESCO',
      },
      {
        value: 'D692',
        label: 'FORCELLO',
      },
      {
        value: 'D710',
        label: 'FORMIGARA',
      },
      {
        value: 'D739',
        label: 'FOSSA GUAZZONA',
      },
      {
        value: 'D753',
        label: 'FRACCHIA',
      },
      {
        value: 'D833',
        label: 'GABBIONETA',
      },
      {
        value: 'D834',
        label: 'GABBIONETA BINANUOVA o GABBIONETA-BINANUOVA',
      },
      {
        value: 'D840',
        label: 'GADESCO',
      },
      {
        value: 'D841',
        label: 'GADESCO PIEVE DELMONA o GADESCO-PIEVE DELMONA',
      },
      {
        value: 'D900',
        label: 'GAMBINA BARCHETTI',
      },
      {
        value: 'D904',
        label: 'GANDINI',
      },
      {
        value: 'D955',
        label: 'GAZZO',
      },
      {
        value: 'D966',
        label: 'GENIVOLTA',
      },
      {
        value: 'D979',
        label: 'GERE DEL PESCE',
      },
      {
        value: 'D993',
        label: "GERRE DE' CAPRIOLI",
      },
      {
        value: 'E082',
        label: 'GOMBITO',
      },
      {
        value: 'E123',
        label: 'GRADELLA',
      },
      {
        value: 'E193',
        label: 'GRONTARDO',
      },
      {
        value: 'E194',
        label: 'GRONTORTO',
      },
      {
        value: 'E217',
        label:
          'GRUMELLO o GRUMELLO CON FARFENGO CREMONESE o GRUMELLO CREMONESE ED UNITI',
      },
      {
        value: 'E272',
        label: 'GUSSOLA',
      },
      {
        value: 'E356',
        label: 'ISOLA DOVARESE',
      },
      {
        value: 'E357',
        label: 'ISOLA PESCAROLI',
      },
      {
        value: 'E362',
        label: 'ISOLELLO',
      },
      {
        value: 'E380',
        label: 'IZANO',
      },
      {
        value: 'E561',
        label: 'LEVATA',
      },
      {
        value: 'E575',
        label: 'LICENGO',
      },
      {
        value: 'E670',
        label: 'LONGARDORE',
      },
      {
        value: 'E733',
        label: 'LUIGNANO',
      },
      {
        value: 'E793',
        label: 'MADIGNANO',
      },
      {
        value: 'E843',
        label: 'MALAGNINO',
      },
      {
        value: 'E983',
        label: 'MARTIGNANA o MARTIGNANA DI PO',
      },
      {
        value: 'E996',
        label: 'MARZALENGO',
      },
      {
        value: 'F234',
        label: 'MIRABELLO o MIRABELLO CIRIA',
      },
      {
        value: 'F421',
        label: 'MONTANARA',
      },
      {
        value: 'F434',
        label: 'MONTE o MONTE CREMASCO',
      },
      {
        value: 'F673',
        label: "MONTICELLI RIPA D'OGLIO",
      },
      {
        value: 'F681',
        label: 'MONTODINE',
      },
      {
        value: 'F761',
        label: 'MOSCAZZANO',
      },
      {
        value: 'F771',
        label: 'MOTTA BALUFFI',
      },
      {
        value: 'F938',
        label: 'NOSADELLO',
      },
      {
        value: 'G004',
        label: 'OFFANENGO',
      },
      {
        value: 'G047',
        label: 'OLMENETA',
      },
      {
        value: 'G060',
        label: 'OMBRIANO',
      },
      {
        value: 'G172',
        label: 'OSSALENGO',
      },
      {
        value: 'G180',
        label: 'OSSOLARO',
      },
      {
        value: 'G185',
        label: 'OSTIANO',
      },
      {
        value: 'G219',
        label: 'PADERNO o PADERNO CREMONESE o PADERNO FASOLARO',
      },
      {
        value: 'G222',
        label: 'PADERNO OSSOLARO o PADERNO PONCHIELLI',
      },
      {
        value: 'G260',
        label: 'PALAZZO o PALAZZO PIGNANO o PALAZZO PUGNANO',
      },
      {
        value: 'G301',
        label: 'PALVARETO',
      },
      {
        value: 'G306',
        label: 'PANDINO',
      },
      {
        value: 'G355',
        label: 'PASSARERA',
      },
      {
        value: 'G468',
        label: 'PERSICO',
      },
      {
        value: 'G469',
        label: 'PERSICO DOSIMO',
      },
      {
        value: 'G483',
        label: 'PESCAROLO o PESCAROLO ED UNITI',
      },
      {
        value: 'G504',
        label: 'PESSINA o PESSINA CREMONESE o PESSINA CREMONESE ED UNITI',
      },
      {
        value: 'G536',
        label: 'PIADENA',
      },
      {
        value: 'M418',
        label: 'PIADENA DRIZZONA',
      },
      {
        value: 'G558',
        label: 'PIANENGO',
      },
      {
        value: 'G603',
        label: 'PIERANICA',
      },
      {
        value: 'G647',
        label: "PIEVE D'OLMI",
      },
      {
        value: 'G640',
        label: 'PIEVE DELMONA',
      },
      {
        value: 'G651',
        label: 'PIEVE SAN GIACOMO',
      },
      {
        value: 'G652',
        label: 'PIEVE SAN MAURIZIO',
      },
      {
        value: 'G655',
        label: 'PIEVE TERZAGNI',
      },
      {
        value: 'G721',
        label: 'PIZZIGHETTONE',
      },
      {
        value: 'G781',
        label: 'POLENGO',
      },
      {
        value: 'G868',
        label: 'PONTIROLO o PONTIROLO CAPREDONI',
      },
      {
        value: 'G897',
        label: 'PORTA OMBRIANO',
      },
      {
        value: 'G952',
        label: 'POZZAGLIO',
      },
      {
        value: 'G958',
        label: 'POZZO BARONZIO',
      },
      {
        value: 'G998',
        label: 'PRATO o PRATO MUZIO',
      },
      {
        value: 'H130',
        label: 'QUINTANO',
      },
      {
        value: 'H144',
        label: 'QUISTRO',
      },
      {
        value: 'H215',
        label: 'RECORFANO',
      },
      {
        value: 'H276',
        label: 'RICENGO',
      },
      {
        value: 'H314',
        label: 'RIPALTA ARPINA',
      },
      {
        value: 'H315',
        label: 'RIPALTA CREMASCA',
      },
      {
        value: 'H316',
        label: 'RIPALTA GUERINA',
      },
      {
        value: 'H317',
        label: 'RIPALTA NUOVA',
      },
      {
        value: 'H318',
        label: 'RIPALTA VECCHIA',
      },
      {
        value: 'H341',
        label: 'RIVAROLO CREMONESE o RIVAROLO DEL RE ED UNITI',
      },
      {
        value: 'H357',
        label: "RIVOLTA o RIVOLTA D'ADDA",
      },
      {
        value: 'H372',
        label: "ROBECCO o ROBECCO D'OGLIO",
      },
      {
        value: 'H508',
        label: 'ROMANENGO',
      },
      {
        value: 'H520',
        label: 'ROMPREZZAGNO',
      },
      {
        value: 'H524',
        label: "RONCA DE' GOLFERANI",
      },
      {
        value: 'H526',
        label: "RONCADELLO o RONCADELLO D'ADDA",
      },
      {
        value: 'H611',
        label: 'ROVERETO',
      },
      {
        value: 'H626',
        label: 'RUBBIANO',
      },
      {
        value: 'H731',
        label: 'SALVIROLA o SALVIROLA CREMASCA',
      },
      {
        value: 'H767',
        label: 'SAN BASSANO',
      },
      {
        value: 'H776',
        label: 'SAN BERNARDINO',
      },
      {
        value: 'H815',
        label: 'SAN DANIELE o SAN DANIELE PO o SAN DANIELE RIPA PO',
      },
      {
        value: 'H918',
        label: 'SAN GIOVANNI IN CROCE',
      },
      {
        value: 'H960',
        label: 'SAN LORENZO AROLDO',
      },
      {
        value: 'H963',
        label: "SAN LORENZO DE' PICENARDI",
      },
      {
        value: 'H965',
        label: 'SAN LORENZO GUAZZONE',
      },
      {
        value: 'H968',
        label: 'SAN LORENZO MONDINARI',
      },
      {
        value: 'I007',
        label: 'SAN MARTINO DEL LAGO',
      },
      {
        value: 'I009',
        label: 'SAN MARTINO IN BELISETO',
      },
      {
        value: 'I036',
        label: 'SAN MICHELE o SAN MICHELE CREMASCO',
      },
      {
        value: 'I075',
        label: "SAN PAOLO RIPA D'OGLIO",
      },
      {
        value: 'I146',
        label: 'SAN SALVATORE',
      },
      {
        value: 'I149',
        label: 'SAN SAVINO',
      },
      {
        value: 'I160',
        label: 'SAN SILLO',
      },
      {
        value: 'I395',
        label: 'SAN VITO o SAN VITO E MODESTO',
      },
      {
        value: 'I235',
        label: 'SANTA MARIA DELLA CROCE',
      },
      {
        value: 'I497',
        label: 'SCANDOLARA RAVARA',
      },
      {
        value: 'I498',
        label: "SCANDOLARA RIPA D'OGLIO",
      },
      {
        value: 'I500',
        label: 'SCANNABUE',
      },
      {
        value: 'I627',
        label: 'SERGNANO',
      },
      {
        value: 'I683',
        label: 'SESTO o SESTO ED UNITI',
      },
      {
        value: 'I740',
        label: 'SILVELLA',
      },
      {
        value: 'I788',
        label: 'SOLAROLO DEL PERSICO',
      },
      {
        value: 'I789',
        label: 'SOLAROLO MONASTEROLO',
      },
      {
        value: 'I790',
        label: 'SOLAROLO RAINERIO',
      },
      {
        value: 'I827',
        label: 'SONCINO',
      },
      {
        value: 'I849',
        label: 'SORESINA',
      },
      {
        value: 'I865',
        label: 'SOSPIRO',
      },
      {
        value: 'I906',
        label: 'SPINADESCO',
      },
      {
        value: 'I909',
        label: 'SPINEDA',
      },
      {
        value: 'I914',
        label: "SPINO o SPINO D'ADDA",
      },
      {
        value: 'I935',
        label: 'STAGNO LOMBARDO o STAGNO PAGLIARO',
      },
      {
        value: 'I957',
        label: "STILO DE' MARIANI",
      },
      {
        value: 'I966',
        label: 'STRACONDOLO',
      },
      {
        value: 'L164',
        label: 'TICENGO',
      },
      {
        value: 'L221',
        label: 'TORLINO o TORLINO VIMERCATI',
      },
      {
        value: 'L225',
        label: 'TORNATA',
      },
      {
        value: 'L255',
        label: "TORRE D'ANGIOLINI",
      },
      {
        value: 'L258',
        label: "TORRE DE' PICENARDI o TORRE DEI MALAMBERTI",
      },
      {
        value: 'L296',
        label: 'TORRICELLA DEL PIZZO',
      },
      {
        value: 'L360',
        label: 'TREDOSSI',
      },
      {
        value: 'L389',
        label: 'TRESCORE o TRESCORE CREMASCO',
      },
      {
        value: 'L412',
        label: 'TREZZOLASCO',
      },
      {
        value: 'L426',
        label: 'TRIGOLO',
      },
      {
        value: 'L535',
        label: 'VAIANO o VAIANO CREMASCO',
      },
      {
        value: 'L539',
        label: 'VAILATE',
      },
      {
        value: 'L541',
        label: 'VAIRANO o VAIRANO CREMASCO',
      },
      {
        value: 'L806',
        label: 'VESCOVATO',
      },
      {
        value: 'L825',
        label: 'VHO',
      },
      {
        value: 'L853',
        label: 'VIDICETO CON GURATA',
      },
      {
        value: 'L855',
        label: 'VIDOLASCO',
      },
      {
        value: 'L877',
        label: 'VIGHIZZOLO',
      },
      {
        value: 'M008',
        label: 'VILLAROCCA',
      },
      {
        value: 'M116',
        label: 'VOLONGO',
      },
      {
        value: 'M127',
        label: 'VOLTIDO',
      },
      {
        value: 'M146',
        label: 'ZANENGO',
      },
      {
        value: 'M148',
        label: 'ZAPPELLO CON BOLZONE',
      },
    ],
  },

  {
    value: 'CS',
    label: 'Cosenza',
    children: [
      {
        value: 'A033',
        label: 'ACQUAFORMOSA',
      },
      {
        value: 'A041',
        label: 'ACQUAPPESA',
      },
      {
        value: 'A053',
        label: 'ACRI',
      },
      {
        value: 'A102',
        label: 'AIELLO CALABRO',
      },
      {
        value: 'A104',
        label: 'AIELLO IN CALABRIA',
      },
      {
        value: 'A105',
        label: 'AIETA',
      },
      {
        value: 'A160',
        label: 'ALBIDONA',
      },
      {
        value: 'A183',
        label: 'ALESSANDRIA o ALESSANDRIA DEL CARRETTO',
      },
      {
        value: 'A234',
        label: 'ALTILIA',
      },
      {
        value: 'A240',
        label: 'ALTOMONTE',
      },
      {
        value: 'A253',
        label: 'AMANTEA',
      },
      {
        value: 'A263',
        label: 'AMENDOLARA',
      },
      {
        value: 'A340',
        label: 'APRIGLIANO',
      },
      {
        value: 'A762',
        label: 'BELMONTE o BELMONTE CALABRO',
      },
      {
        value: 'A768',
        label: 'BELSITO',
      },
      {
        value: 'A773',
        label: 'BELVEDERE o BELVEDERE MARITTIMO',
      },
      {
        value: 'A842',
        label: 'BIANCHI',
      },
      {
        value: 'A887',
        label: 'BISIGNANO',
      },
      {
        value: 'A912',
        label: 'BOCCHIGLIERO',
      },
      {
        value: 'A973',
        label: 'BONIFATI',
      },
      {
        value: 'B270',
        label: 'BUONVICINO',
      },
      {
        value: 'B424',
        label: 'CALOPEZZATI',
      },
      {
        value: 'B426',
        label: 'CALOVETO',
      },
      {
        value: 'B500',
        label: 'CAMPANA',
      },
      {
        value: 'B607',
        label: 'CANNA',
      },
      {
        value: 'B774',
        label: 'CARIATI',
      },
      {
        value: 'B802',
        label: 'CAROLEI',
      },
      {
        value: 'B813',
        label: 'CARPANZANO',
      },
      {
        value: 'M385',
        label: 'CASALI DEL MANCO',
      },
      {
        value: 'B903',
        label: 'CASALNUOVO o VILLAPIANA',
      },
      {
        value: 'B983',
        label: 'CASOLE o CASOLE BRUZIO',
      },
      {
        value: 'C002',
        label: "CASSANO o CASSANO ALL'IONIO o CASSANO ALLO IONIO",
      },
      {
        value: 'C108',
        label: 'CASTELFRANCO o CASTROLIBERO',
      },
      {
        value: 'C301',
        label: 'CASTIGLIONE o CASTIGLIONE COSENTINO',
      },
      {
        value: 'C348',
        label: 'CASTROREGIO',
      },
      {
        value: 'C349',
        label: 'CASTROVILLARI',
      },
      {
        value: 'C430',
        label: 'CELICO',
      },
      {
        value: 'C437',
        label: 'CELLARA',
      },
      {
        value: 'C489',
        label: 'CERCHIARA o CERCHIARA DI CALABRIA',
      },
      {
        value: 'C515',
        label: 'CERISANO',
      },
      {
        value: 'C554',
        label: 'CERVICATI',
      },
      {
        value: 'C560',
        label: 'CERZETO',
      },
      {
        value: 'C588',
        label: 'CETRARO',
      },
      {
        value: 'C717',
        label: 'CIPOLLINA o SANTA MARIA o SANTA MARIA DEL CEDRO',
      },
      {
        value: 'C763',
        label: 'CIVITA',
      },
      {
        value: 'C795',
        label: 'CLETO o PIETRAMALA',
      },
      {
        value: 'C905',
        label: 'COLOSIMI',
      },
      {
        value: 'D005',
        label: 'CORIGLIANO o CORIGLIANO CALABRO',
      },
      {
        value: 'M403',
        label: 'CORIGLIANO-ROSSANO',
      },
      {
        value: 'D086',
        label: 'COSENZA',
      },
      {
        value: 'D179',
        label: 'CROPALATI o CROGNALETO',
      },
      {
        value: 'D180',
        label: 'CROPALATI o CROPANI',
      },
      {
        value: 'D184',
        label: 'CROSIA',
      },
      {
        value: 'D289',
        label: 'DIAMANTE',
      },
      {
        value: 'D290',
        label: 'DIANO o FEGGIANO o SCIGLIANO',
      },
      {
        value: 'D304',
        label: 'DIPIGNANO',
      },
      {
        value: 'D328',
        label: 'DOMANICO',
      },
      {
        value: 'D464',
        label: 'FAGNANO o FAGNANO CASTELLO',
      },
      {
        value: 'D473',
        label: 'FALCONARA o FALCONARA ALBANESE',
      },
      {
        value: 'D582',
        label: 'FIGLINE o FIGLINE VEGLIATURO',
      },
      {
        value: 'D614',
        label: 'FIRMO',
      },
      {
        value: 'D624',
        label: 'FIUMEFREDDO o FIUMEFREDDO BRUZIO',
      },
      {
        value: 'D764',
        label: 'FRANCAVILLA o FRANCAVILLA MARITTIMA',
      },
      {
        value: 'D774',
        label: 'FRASCINETO',
      },
      {
        value: 'D828',
        label: 'FUSCALDO',
      },
      {
        value: 'E180',
        label: 'GRIMALDI',
      },
      {
        value: 'E185',
        label: 'GRISOLIA',
      },
      {
        value: 'E186',
        label: 'GRISOLIA CIPOLLINA',
      },
      {
        value: 'E242',
        label: 'GUARDIA o GUARDIA PIEMONTESE',
      },
      {
        value: 'E247',
        label: 'GUARDIA PIEMONTESE TERME',
      },
      {
        value: 'E407',
        label: 'LAGO',
      },
      {
        value: 'E417',
        label: 'LAINO BORGO',
      },
      {
        value: 'E418',
        label: 'LAINO BRUZIO',
      },
      {
        value: 'E419',
        label: 'LAINO CASTELLO',
      },
      {
        value: 'E450',
        label: 'LAPPANO',
      },
      {
        value: 'E475',
        label: 'LATTARICO',
      },
      {
        value: 'E677',
        label: 'LONGOBARDI',
      },
      {
        value: 'E678',
        label: 'LONGOBUCCO',
      },
      {
        value: 'E745',
        label: 'LUNGRO',
      },
      {
        value: 'E773',
        label: 'LUZZI',
      },
      {
        value: 'E835',
        label: "MAIERA'",
      },
      {
        value: 'E859',
        label: 'MALITO',
      },
      {
        value: 'E872',
        label: 'MALVITO',
      },
      {
        value: 'E878',
        label: 'MANDATORICCIO',
      },
      {
        value: 'E888',
        label: 'MANGONE',
      },
      {
        value: 'E914',
        label: 'MARANO MARCHESATO',
      },
      {
        value: 'E915',
        label: 'MARANO PRINCIPATO',
      },
      {
        value: 'F001',
        label: 'MARZI',
      },
      {
        value: 'F125',
        label: 'MENDICINO',
      },
      {
        value: 'F370',
        label: 'MONGRASSANO',
      },
      {
        value: 'F416',
        label: 'MONTALTO o MONTALTO AFFUGO o MONTALTO UFFUGO',
      },
      {
        value: 'F519',
        label: 'MONTEGIORDANO',
      },
      {
        value: 'F708',
        label: 'MORANO o MORANO CALABRO',
      },
      {
        value: 'F735',
        label: 'MORMANNO',
      },
      {
        value: 'F775',
        label: 'MOTTAFOLLONE',
      },
      {
        value: 'F907',
        label: 'NOCARA',
      },
      {
        value: 'G110',
        label: 'ORIOLO',
      },
      {
        value: 'G129',
        label: 'ORSOMARSO',
      },
      {
        value: 'G298',
        label: 'PALUDI',
      },
      {
        value: 'G307',
        label: 'PANETTIERI',
      },
      {
        value: 'G317',
        label: 'PAOLA',
      },
      {
        value: 'G320',
        label: 'PAPASIDERO',
      },
      {
        value: 'G331',
        label: 'PARENTI',
      },
      {
        value: 'G372',
        label: 'PATERNO o PATERNO CALABRO',
      },
      {
        value: 'G400',
        label: 'PEDACE',
      },
      {
        value: 'G411',
        label: 'PEDIVIGLIANO',
      },
      {
        value: 'G553',
        label: 'PIANE o PIANE CRATI',
      },
      {
        value: 'G615',
        label: 'PIETRAFITTA',
      },
      {
        value: 'G622',
        label: 'PIETRAPAOLA',
      },
      {
        value: 'G733',
        label: 'PLATACI',
      },
      {
        value: 'G975',
        label: 'PRAIA A MARE',
      },
      {
        value: 'H235',
        label: 'RENDE',
      },
      {
        value: 'H416',
        label: 'ROCCA IMPERIALE',
      },
      {
        value: 'H488',
        label: 'ROGGIANO GRAVINA o ROGIANO',
      },
      {
        value: 'H490',
        label: 'ROGLIANO',
      },
      {
        value: 'H565',
        label: 'ROSE',
      },
      {
        value: 'H572',
        label: 'ROSETO CAPO SPULICO o ROSITO o ROSITO CAPO SPULICO',
      },
      {
        value: 'H579',
        label: 'ROSSANO',
      },
      {
        value: 'H585',
        label: 'ROTA o ROTA GRECA',
      },
      {
        value: 'H621',
        label: 'ROVITO',
      },
      {
        value: 'H765',
        label: 'SAN BASILE',
      },
      {
        value: 'H774',
        label: 'SAN BENEDETTO ULLANO',
      },
      {
        value: 'H806',
        label: 'SAN COSMO o SAN COSMO ALBANESE',
      },
      {
        value: 'H818',
        label: 'SAN DEMETRIO o SAN DEMETRIO CORONE',
      },
      {
        value: 'H825',
        label: 'SAN DONATO o SAN DONATO DI NINEA',
      },
      {
        value: 'H841',
        label: 'SAN FILI',
      },
      {
        value: 'H881',
        label: 'SAN GIORGIO o SAN GIORGIO ALBANESE',
      },
      {
        value: 'H919',
        label: 'SAN GIOVANNI IN FIORE',
      },
      {
        value: 'H961',
        label: 'SAN LORENZO BELLIZZI',
      },
      {
        value: 'H962',
        label: 'SAN LORENZO DEL VALLO',
      },
      {
        value: 'H971',
        label: 'SAN LUCIDO',
      },
      {
        value: 'H981',
        label: 'SAN MARCO o SAN MARCO ARGENTANO',
      },
      {
        value: 'H992',
        label: 'SAN MARTINO o SAN MARTINO DI FINITA',
      },
      {
        value: 'I060',
        label: 'SAN NICOLA ARCELLA',
      },
      {
        value: 'I108',
        label: 'SAN PIETRO IN AMANTEA',
      },
      {
        value: 'I114',
        label: 'SAN PIETRO IN GUARANO',
      },
      {
        value: 'I165',
        label: 'SAN SOSTI',
      },
      {
        value: 'I388',
        label: 'SAN VINCENZO o SAN VINCENZO LA COSTA',
      },
      {
        value: 'H877',
        label: 'SANGINETO',
      },
      {
        value: 'I192',
        label: "SANT'AGATA o SANT'AGATA DI ESARO",
      },
      {
        value: 'I171',
        label: 'SANTA CATERINA o SANTA CATERINA ALBANESE',
      },
      {
        value: 'I183',
        label: 'SANTA DOMENICA o SANTA DOMENICA TALAO',
      },
      {
        value: 'I309',
        label: "SANTA SOFIA o SANTA SOFIA D'EPIRO",
      },
      {
        value: 'I359',
        label: 'SANTO STEFANO o SANTO STEFANO DI ROGLIANO',
      },
      {
        value: 'I423',
        label: 'SARACENA',
      },
      {
        value: 'I485',
        label: 'SCALA o SCALA COELI',
      },
      {
        value: 'I489',
        label: 'SCALEA',
      },
      {
        value: 'I642',
        label: "SERRA D'AIELLO",
      },
      {
        value: 'I650',
        label: 'SERRA PEDACE',
      },
      {
        value: 'I895',
        label: 'SPEZZANO ALBANESE',
      },
      {
        value: 'I896',
        label: 'SPEZZANO DELLA SILA',
      },
      {
        value: 'I897',
        label: 'SPEZZANO GRANDE',
      },
      {
        value: 'I898',
        label: 'SPEZZANO PICCOLO',
      },
      {
        value: 'L055',
        label: 'TARSIA',
      },
      {
        value: 'L124',
        label: 'TERRANOVA o TERRANOVA DA SIBARI',
      },
      {
        value: 'L133',
        label: 'TERRATI',
      },
      {
        value: 'L134',
        label: 'TERRAVECCHIA',
      },
      {
        value: 'L206',
        label: 'TORANO o TORANO CASTELLO',
      },
      {
        value: 'L305',
        label: 'TORTORA',
      },
      {
        value: 'L353',
        label: 'TREBISACCE',
      },
      {
        value: 'L375',
        label: 'TRENTA',
      },
      {
        value: 'L524',
        label: 'VACCARIZZO o VACCARIZZO ALBANESE',
      },
      {
        value: 'L747',
        label: 'VERBICARO',
      },
      {
        value: 'M202',
        label: 'ZUMPANO',
      },
    ],
  },

  {
    value: 'CT',
    label: 'Catania',
    children: [
      {
        value: 'A025',
        label: 'ACI BONACCORSI',
      },
      {
        value: 'A026',
        label: 'ACI CASTELLO',
      },
      {
        value: 'A027',
        label: 'ACI CATENA',
      },
      {
        value: 'A029',
        label: "ACI SANT'ANTONIO",
      },
      {
        value: 'A028',
        label: 'ACIREALE',
      },
      {
        value: 'A056',
        label: 'ADERNO o ADRANO',
      },
      {
        value: 'A766',
        label: 'BELPASSO',
      },
      {
        value: 'A841',
        label: 'BIANCAVILLA',
      },
      {
        value: 'B202',
        label: 'BRONTE',
      },
      {
        value: 'B384',
        label: 'CALATABIANO o CALATAFIMI',
      },
      {
        value: 'B428',
        label: 'CALTAGIRONE',
      },
      {
        value: 'B561',
        label: 'CAMPOROTONDO o CAMPOROTONDO ETNEO',
      },
      {
        value: 'B746',
        label: 'CARCACI',
      },
      {
        value: 'C091',
        label: 'CASTEL DI IUDICA',
      },
      {
        value: 'C297',
        label: 'CASTIGLIONE o CASTIGLIONE DI SICILIA',
      },
      {
        value: 'C351',
        label: 'CATANIA',
      },
      {
        value: 'D623',
        label: 'FIUMEFREDDO o FIUMEFREDDO DI SICILIA',
      },
      {
        value: 'D849',
        label: 'GAGLIANO o GAGLIANO CASTELFERRATO',
      },
      {
        value: 'E017',
        label: 'GIARRE',
      },
      {
        value: 'E018',
        label: 'GIARRE-RIPOSTO o IONIA',
      },
      {
        value: 'E133',
        label: 'GRAMMICHELE',
      },
      {
        value: 'E156',
        label: 'GRAVINA o GRAVINA DI CATANIA',
      },
      {
        value: 'E578',
        label: 'LICODIA o LICODIA EUBEA',
      },
      {
        value: 'E602',
        label: 'LINGUAGLOSSA',
      },
      {
        value: 'E854',
        label: 'MALETTO',
      },
      {
        value: 'M283',
        label: 'MANIACE',
      },
      {
        value: 'F004',
        label: 'MASCALI',
      },
      {
        value: 'F005',
        label: 'MASCALUCIA',
      },
      {
        value: 'M271',
        label: 'MAZZARRONE',
      },
      {
        value: 'F209',
        label: 'MILITELLO o MILITELLO IN VAL DI CATANIA',
      },
      {
        value: 'F214',
        label: 'MILO',
      },
      {
        value: 'F217',
        label: 'MINEO',
      },
      {
        value: 'F231',
        label: 'MIRABELLA o MIRABELLA IMBACCARI',
      },
      {
        value: 'F250',
        label: 'MISTERBIANCO',
      },
      {
        value: 'F781',
        label: "MOTTA SANT'ANASTASIA",
      },
      {
        value: 'F890',
        label: 'NICOLOSI',
      },
      {
        value: 'G253',
        label: 'PALAGONIA',
      },
      {
        value: 'G371',
        label: "PATERNO'",
      },
      {
        value: 'G402',
        label: 'PEDARA',
      },
      {
        value: 'G597',
        label: 'PIEDIMONTE o PIEDIMONTE ETNEO',
      },
      {
        value: 'H154',
        label: 'RADDUSA',
      },
      {
        value: 'M287',
        label: 'RAGALNA',
      },
      {
        value: 'H168',
        label: 'RAMACCA',
      },
      {
        value: 'H175',
        label: 'RANDAZZO',
      },
      {
        value: 'H325',
        label: 'RIPOSTO',
      },
      {
        value: 'H805',
        label: 'SAN CONO',
      },
      {
        value: 'H915',
        label: 'SAN GIOVANNI DI GALERNO',
      },
      {
        value: 'H922',
        label: 'SAN GIOVANNI LA PUNTA',
      },
      {
        value: 'H940',
        label: 'SAN GREGORIO o SAN GREGORIO DI CATANIA',
      },
      {
        value: 'I035',
        label: 'SAN MICHELE o SAN MICHELE DI GANZARIA',
      },
      {
        value: 'I098',
        label: 'SAN PIETRO CLARENZA',
      },
      {
        value: 'I202',
        label: "SANT'AGATA LI BATTIATI",
      },
      {
        value: 'I216',
        label: "SANT'ALFIO",
      },
      {
        value: 'I240',
        label: 'SANTA MARIA DI LICODIA',
      },
      {
        value: 'I314',
        label: 'SANTA VENERINA',
      },
      {
        value: 'I548',
        label: 'SCORDIA',
      },
      {
        value: 'L355',
        label: 'TRECASTAGNI',
      },
      {
        value: 'L369',
        label: 'TREMESTIERI o TREMESTIERI ETNEO',
      },
      {
        value: 'L658',
        label: 'VALVERDE',
      },
      {
        value: 'L828',
        label: 'VIAGRANDE',
      },
      {
        value: 'M100',
        label: 'VIZZINI',
      },
      {
        value: 'M139',
        label: 'ZAFFERANA ETNEA',
      },
    ],
  },

  {
    value: 'CZ',
    label: 'Catanzaro',
    children: [
      {
        value: 'A155',
        label: 'ALBI',
      },
      {
        value: 'A255',
        label: 'AMARONI',
      },
      {
        value: 'A257',
        label: 'AMATO',
      },
      {
        value: 'A272',
        label: 'ANDALI',
      },
      {
        value: 'A397',
        label: 'ARGUSTO',
      },
      {
        value: 'A542',
        label: 'BADOLATO',
      },
      {
        value: 'A736',
        label: 'BELCASTRO',
      },
      {
        value: 'A772',
        label: 'BELVEDERE o BELVEDERE DI SPINELLO',
      },
      {
        value: 'B002',
        label: 'BORGIA',
      },
      {
        value: 'B085',
        label: 'BOTRICELLO',
      },
      {
        value: 'B717',
        label: 'CARAFFA o CARAFFA DI CATANZARO',
      },
      {
        value: 'B758',
        label: 'CARDINALE',
      },
      {
        value: 'B790',
        label: 'CARLOPOLI',
      },
      {
        value: 'B968',
        label: 'CASINO o CASTELSILANO',
      },
      {
        value: 'C039',
        label: 'CASTAGNA',
      },
      {
        value: 'C352',
        label: 'CATANZARO',
      },
      {
        value: 'C453',
        label: 'CENADI',
      },
      {
        value: 'C472',
        label: 'CENTRACHE',
      },
      {
        value: 'C542',
        label: 'CERVA',
      },
      {
        value: 'C616',
        label: 'CHIARAVALLE o CHIARAVALLE CENTRALE',
      },
      {
        value: 'C674',
        label: 'CICALA',
      },
      {
        value: 'C726',
        label: "CIRO' MARINA o CIRO' MARINA",
      },
      {
        value: 'C960',
        label: 'CONFLENTI',
      },
      {
        value: 'D049',
        label: 'CORTALE',
      },
      {
        value: 'D122',
        label: 'COTRONE o CROTONE',
      },
      {
        value: 'D181',
        label: 'CROPANI',
      },
      {
        value: 'D218',
        label: 'CURINGA',
      },
      {
        value: 'D257',
        label: 'DAVOLI',
      },
      {
        value: 'D261',
        label: 'DECOLLATURA',
      },
      {
        value: 'D476',
        label: 'FALERNA',
      },
      {
        value: 'D544',
        label: 'FEROLETO ANTICO',
      },
      {
        value: 'D546',
        label: 'FEROLETO PIANO o PIANOPOLI',
      },
      {
        value: 'D744',
        label: 'FOSSATO o FOSSATO SERRALTA',
      },
      {
        value: 'D762',
        label: 'FRANCAVILLA o FRANCAVILLA ANGITOLA',
      },
      {
        value: 'D852',
        label: 'GAGLIATO',
      },
      {
        value: 'D932',
        label: 'GASPERINA',
      },
      {
        value: 'E031',
        label: 'GIMIGLIANO',
      },
      {
        value: 'E050',
        label: 'GIRIFALCO',
      },
      {
        value: 'E068',
        label: 'GIZZERIA',
      },
      {
        value: 'E239',
        label: 'GUARDAVALLE',
      },
      {
        value: 'E328',
        label: 'ISCA SULLO IONIO',
      },
      {
        value: 'E339',
        label: 'ISOLA o ISOLA DI CAPO RIZZUTO',
      },
      {
        value: 'E274',
        label: 'JACURSO',
      },
      {
        value: 'M208',
        label: 'LAMEZIA TERME',
      },
      {
        value: 'E806',
        label: 'MAGISANO',
      },
      {
        value: 'E834',
        label: 'MAIDA',
      },
      {
        value: 'E923',
        label: 'MARCEDUSA',
      },
      {
        value: 'E925',
        label: 'MARCELLINARA',
      },
      {
        value: 'E990',
        label: 'MARTIRANO',
      },
      {
        value: 'E991',
        label: 'MARTIRANO LOMBARDO',
      },
      {
        value: 'F200',
        label: 'MIGLIERINA',
      },
      {
        value: 'F432',
        label: 'MONTAURO',
      },
      {
        value: 'F537',
        label: 'MONTELEONE o MONTELEONE DI CALABRIA o VIBO VALENTIA',
      },
      {
        value: 'F586',
        label: 'MONTEPAONE',
      },
      {
        value: 'F607',
        label: 'MONTEROSSO o MONTEROSSO CALABRO',
      },
      {
        value: 'F780',
        label: 'MOTTA SANTA LUCIA',
      },
      {
        value: 'F888',
        label: 'NICASTRO',
      },
      {
        value: 'F910',
        label: 'NOCERA o NOCERA TERINESE',
      },
      {
        value: 'G034',
        label: 'OLIVADI',
      },
      {
        value: 'G272',
        label: 'PALERMITI',
      },
      {
        value: 'G439',
        label: 'PENTONE',
      },
      {
        value: 'G517',
        label: 'PETRIZZI',
      },
      {
        value: 'G518',
        label: "PETRONA'",
      },
      {
        value: 'G708',
        label: 'PISCOPIO',
      },
      {
        value: 'G734',
        label: 'PLATANIA',
      },
      {
        value: 'G508',
        label: 'POLICASTRO o PETILIA POLICASTRO',
      },
      {
        value: 'H403',
        label: 'ROCCA FERDINANDEA o ROCCA DI NETO',
      },
      {
        value: 'H742',
        label: 'SAMBIASE',
      },
      {
        value: 'H807',
        label: 'SAN COSTANTINO o SAN COSTANTINO CALABRO',
      },
      {
        value: 'H846',
        label: 'SAN FLORO',
      },
      {
        value: 'H941',
        label: "SAN GREGORIO o SAN GREGORIO D'IPPONA",
      },
      {
        value: 'H976',
        label: "SAN MANGO o SAN MANGO D'AQUINO",
      },
      {
        value: 'I026',
        label: 'SAN MAURO o SAN MAURO MARCHESATO',
      },
      {
        value: 'I057',
        label: "SAN NICOLA o SAN NICOLA DELL'ALTO",
      },
      {
        value: 'I058',
        label: 'SAN NICOLA o SAN NICOLA DA CRISSA',
      },
      {
        value: 'I093',
        label: 'SAN PIETRO A MAIDA',
      },
      {
        value: 'I095',
        label: 'SAN PIETRO A TIRIOLO o SAN PIETRO APOSTOLO',
      },
      {
        value: 'I164',
        label: 'SAN SOSTENE',
      },
      {
        value: 'I393',
        label: 'SAN VITO o SAN VITO SULLO IONIO',
      },
      {
        value: 'I266',
        label: "SANT'ANDREA o SANT'ANDREA APOSTOLO DELLO IONIO",
      },
      {
        value: 'I322',
        label: "SANT'ELIA o VALLEFIORITA",
      },
      {
        value: 'I334',
        label: "SANT'EUFEMIA LAMEZIA",
      },
      {
        value: 'I170',
        label: 'SANTA CATERINA o SANTA CATERINA DELLO IONIO',
      },
      {
        value: 'I463',
        label: 'SATRIANO',
      },
      {
        value: 'I589',
        label: 'SELLIA',
      },
      {
        value: 'I590',
        label: 'SELLIA MARINA',
      },
      {
        value: 'I639',
        label: 'SERRA o SERRA SAN BRUNO',
      },
      {
        value: 'I655',
        label: 'SERRASTRETTA',
      },
      {
        value: 'I671',
        label: 'SERSALE',
      },
      {
        value: 'I704',
        label: 'SETTINGIANO',
      },
      {
        value: 'I745',
        label: 'SIMERI CRICHI',
      },
      {
        value: 'I844',
        label: 'SORBO o SORBO SAN BASILE',
      },
      {
        value: 'I854',
        label: 'SORIANO o SORIANO CALABRO',
      },
      {
        value: 'I872',
        label: 'SOVERATO',
      },
      {
        value: 'I874',
        label: 'SOVERIA MANNELLI',
      },
      {
        value: 'I875',
        label: 'SOVERIA SIMERI',
      },
      {
        value: 'I929',
        label: 'SQUILLACE',
      },
      {
        value: 'I937',
        label: "STALETTI o STALETTI'",
      },
      {
        value: 'L070',
        label: 'TAVERNA',
      },
      {
        value: 'L177',
        label: 'TIRIOLO',
      },
      {
        value: 'L240',
        label: 'TORRE o TORRE DI RUGGIERO',
      },
      {
        value: 'M140',
        label: 'ZAGARISE',
      },
    ],
  },

  {
    value: 'EN',
    label: 'Enna',
    children: [
      {
        value: 'A070',
        label: 'AGIRA',
      },
      {
        value: 'A098',
        label: 'AIDONE',
      },
      {
        value: 'A478',
        label: 'ASSORO',
      },
      {
        value: 'A676',
        label: 'BARRAFRANCA',
      },
      {
        value: 'B380',
        label: 'CALASCIBETTA o CALASCA o CALASCA-CASTIGLIONE',
      },
      {
        value: 'C342',
        label: 'CASTROGIOVANNI o ENNA',
      },
      {
        value: 'C353',
        label: 'CATENANUOVA',
      },
      {
        value: 'C471',
        label: 'CENTORBI o CENTURIPE o CENTURUPI',
      },
      {
        value: 'C480',
        label: 'CERAMI',
      },
      {
        value: 'E536',
        label: 'LEONFORTE',
      },
      {
        value: 'F892',
        label: 'NICOSIA',
      },
      {
        value: 'F900',
        label: 'NISSORIA',
      },
      {
        value: 'G624',
        label: 'PIETRAPERZIA',
      },
      {
        value: 'H221',
        label: 'REGALBUTO',
      },
      {
        value: 'I891',
        label: 'SPERLINGA',
      },
      {
        value: 'L448',
        label: 'TROINA',
      },
      {
        value: 'L583',
        label: 'VALGUARNERA CAROPEPE',
      },
      {
        value: 'M011',
        label: 'VILLAROSA',
      },
    ],
  },

  {
    value: 'FC',
    label: 'Forlì-Cesena',
    children: [
      {
        value: 'A565',
        label: 'BAGNO DI ROMAGNA',
      },
      {
        value: 'A809',
        label: 'BERTINORO',
      },
      {
        value: 'B001',
        label: 'BORGHI',
      },
      {
        value: 'C339',
        label:
          'CASTROCARO E TERRA DEL SOLE o CASTROCARO TERME E TERRA DEL SOLE o TERRA DEL SOLE o TERRA DEL SOLE E CASTROCARO',
      },
      {
        value: 'C573',
        label: 'CESENA',
      },
      {
        value: 'C574',
        label: 'CESENATICO',
      },
      {
        value: 'C777',
        label: 'CIVITELLA o CIVITELLA DI ROMAGNA',
      },
      {
        value: 'D357',
        label: 'DOVADOLA',
      },
      {
        value: 'D618',
        label: 'FIUMANA',
      },
      {
        value: 'D704',
        label: "FORLI'",
      },
      {
        value: 'D705',
        label: 'FORLIMPOPOLI',
      },
      {
        value: 'D867',
        label: 'GALEATA',
      },
      {
        value: 'D899',
        label: 'GAMBETTOLA',
      },
      {
        value: 'D935',
        label: 'GATTEO',
      },
      {
        value: 'E675',
        label: 'LONGIANO',
      },
      {
        value: 'F097',
        label: 'MELDOLA',
      },
      {
        value: 'F139',
        label: 'MERCATO SARACENO',
      },
      {
        value: 'F244',
        label: 'MISANO o MISANO IN VILLA VITTORIA o MISANO ADRIATICO',
      },
      {
        value: 'F259',
        label: 'MODIGLIANA',
      },
      {
        value: 'F502',
        label: 'MONTEFIORE o MONTEFIORITO o MONTEFIORE CONCA',
      },
      {
        value: 'F641',
        label: 'MONTESCUDOLO o MONTESCUDO',
      },
      {
        value: 'F668',
        label: 'MONTIANO',
      },
      {
        value: 'F715',
        label: 'MORCIANO o MORCIANO DI ROMAGNA',
      },
      {
        value: 'F753',
        label: 'MORTANO',
      },
      {
        value: 'G904',
        label: 'PORTICO E SAN BENEDETTO o PORTICO o PORTICO DI ROMAGNA',
      },
      {
        value: 'H017',
        label: 'PREDAPPIO o PREDAPPIO NUOVA',
      },
      {
        value: 'H034',
        label: 'PREMILCUORE',
      },
      {
        value: 'H437',
        label: 'ROCCA SAN CASCIANO',
      },
      {
        value: 'H542',
        label: 'RONCOFREDDO',
      },
      {
        value: 'H613',
        label: 'ROVERSANO',
      },
      {
        value: 'I027',
        label:
          'SAN MAURO o SAN MAURO DI ROMA o SAN MAURO DI ROMAGNA o SAN MAURO PASCOLI',
      },
      {
        value: 'I304',
        label:
          "SANT'ARCANGELO o SANT'ARCANGELO DI ROMAGNA o SANTARCANGELO DI ROMAGNA",
      },
      {
        value: 'I310',
        label: 'SANTA SOFIA',
      },
      {
        value: 'I444',
        label: 'SARSINA',
      },
      {
        value: 'I472',
        label: 'SAVIGNANO o SAVIGNANO DI ROMAGNA o SAVIGNANO SUL RUBICONE',
      },
      {
        value: 'I550',
        label: 'SCORTICATA o TORRIANA',
      },
      {
        value: 'I779',
        label: 'SOGLIANO AL RUBICONE',
      },
      {
        value: 'I842',
        label: 'SORBANO',
      },
      {
        value: 'L099',
        label: 'TEODORANO',
      },
      {
        value: 'L361',
        label: 'TREDOZIO',
      },
      {
        value: 'L764',
        label: 'VERGHERETO',
      },
    ],
  },

  {
    value: 'FE',
    label: 'Ferrara',
    children: [
      {
        value: 'A393',
        label: 'ARGENTA',
      },
      {
        value: 'A806',
        label: 'BERRA',
      },
      {
        value: 'A965',
        label: 'BONDENO',
      },
      {
        value: 'C469',
        label: 'CENTO',
      },
      {
        value: 'C814',
        label: 'CODIGORO',
      },
      {
        value: 'C912',
        label: 'COMACCHIO',
      },
      {
        value: 'C980',
        label: 'COPPARO',
      },
      {
        value: 'D548',
        label: 'FERRARA',
      },
      {
        value: 'M323',
        label: 'FISCAGLIA',
      },
      {
        value: 'D713',
        label: 'FORMIGNANA',
      },
      {
        value: 'E107',
        label: 'GORO',
      },
      {
        value: 'E320',
        label: 'JOLANDA DI SAVOIA o LE VENEZIE',
      },
      {
        value: 'E410',
        label: 'LAGOSANTO',
      },
      {
        value: 'F016',
        label: 'MASI TORELLO',
      },
      {
        value: 'F026',
        label: 'MASSA FISCAGLIA',
      },
      {
        value: 'F156',
        label: 'MESOLA',
      },
      {
        value: 'F198',
        label: 'MIGLIARINO',
      },
      {
        value: 'F199',
        label: 'MIGLIARO',
      },
      {
        value: 'F235',
        label: 'MIRABELLO',
      },
      {
        value: 'G184',
        label: 'OSTELLATO',
      },
      {
        value: 'G768',
        label: 'POGGIO RENATICO',
      },
      {
        value: 'G916',
        label: 'PORTOMAGGIORE',
      },
      {
        value: 'M410',
        label: 'RIVA DEL PO',
      },
      {
        value: 'H360',
        label: 'RO',
      },
      {
        value: 'I209',
        label: "SANT'AGOSTINO",
      },
      {
        value: 'M381',
        label: 'TERRE DEL RENO',
      },
      {
        value: 'L390',
        label: 'TRESIGALLO',
      },
      {
        value: 'M409',
        label: 'TRESIGNANA',
      },
      {
        value: 'L868',
        label: 'VIGARANO MAINARDA',
      },
      {
        value: 'M110',
        label: 'VOGHIERA',
      },
    ],
  },

  {
    value: 'FG',
    label: 'Foggia',
    children: [
      {
        value: 'A015',
        label: 'ACCADIA',
      },
      {
        value: 'A150',
        label: 'ALBERONA',
      },
      {
        value: 'A320',
        label: 'ANZANO o ANZANO DEGLI IRPINI o ANZANO DI PUGLIA',
      },
      {
        value: 'A339',
        label: 'APRICENA',
      },
      {
        value: 'A463',
        label: 'ASCOLI o ASCOLI SATRIANO',
      },
      {
        value: 'A854',
        label: 'BICCARI',
      },
      {
        value: 'B104',
        label: 'BOVINO',
      },
      {
        value: 'B357',
        label: 'CAGNANO o CAGNANO VARANO',
      },
      {
        value: 'B584',
        label: 'CANDELA',
      },
      {
        value: 'B724',
        label: 'CARAPELLE',
      },
      {
        value: 'B784',
        label: 'CARLANTINO',
      },
      {
        value: 'B829',
        label: 'CARPINO',
      },
      {
        value: 'B904',
        label: 'CASALNUOVO o CASALNUOVO MONTEROTARO',
      },
      {
        value: 'B917',
        label: 'CASALVECCHIO o CASALVECCHIO DI PUGLIA',
      },
      {
        value: 'C198',
        label: 'CASTELLUCCIO DEI SAURI',
      },
      {
        value: 'C202',
        label: 'CASTELLUCCIO VALMAGGIORE',
      },
      {
        value: 'C222',
        label: 'CASTELNUOVO o CASTELNUOVO DELLA DAUNIA',
      },
      {
        value: 'C429',
        label: 'CELENZA o CELENZA VALFORTORE',
      },
      {
        value: 'C442',
        label: 'CELLE o CELLE DI SAN VITO o CELLE SAN VITO',
      },
      {
        value: 'C514',
        label: 'CERIGNOLA',
      },
      {
        value: 'C633',
        label: 'CHIEUTI',
      },
      {
        value: 'D269',
        label: 'DELICETO',
      },
      {
        value: 'D459',
        label: 'FAETO',
      },
      {
        value: 'D643',
        label: 'FOGGIA',
      },
      {
        value: 'E332',
        label: 'ISCHITELLA',
      },
      {
        value: 'E363',
        label: 'ISOLE TREMITI',
      },
      {
        value: 'E549',
        label: 'LESINA',
      },
      {
        value: 'E716',
        label: 'LUCERA',
      },
      {
        value: 'E885',
        label: 'MANFREDONIA',
      },
      {
        value: 'F059',
        label: 'MATTINATA',
      },
      {
        value: 'F631',
        label: "MONTE SANT'ANGELO",
      },
      {
        value: 'F538',
        label: 'MONTELEONE o MONTELEONE DI PUGLIA',
      },
      {
        value: 'F777',
        label: 'MOTTA MONTECORVINO',
      },
      {
        value: 'M266',
        label: 'ORDONA',
      },
      {
        value: 'G131',
        label: 'ORTA o ORTA NOVA',
      },
      {
        value: 'G312',
        label: 'PANNI',
      },
      {
        value: 'G487',
        label: 'PESCHICI',
      },
      {
        value: 'G604',
        label: 'PIETRA o PIETRAMONTECORVINO',
      },
      {
        value: 'G761',
        label: 'POGGIO IMPERIALE',
      },
      {
        value: 'H287',
        label: 'RIGNANO o RIGNANO GARGANICO',
      },
      {
        value: 'H467',
        label: "ROCCHETTA SANT'ANTONIO",
      },
      {
        value: 'H480',
        label: 'RODI GARGANICO',
      },
      {
        value: 'H568',
        label: 'ROSETO o ROSETO VALFORTORE',
      },
      {
        value: 'H926',
        label: 'SAN GIOVANNI ROTONDO',
      },
      {
        value: 'H985',
        label: 'SAN MARCO IN LAMIS',
      },
      {
        value: 'H986',
        label: 'SAN MARCO LA CATOLA',
      },
      {
        value: 'I054',
        label: 'SAN NICANDRO o SAN NICANDRO GARGANICO o SANNICANDRO GARGANICO',
      },
      {
        value: 'I072',
        label: 'SAN PAOLO o SAN PAOLO DI CIVITATE',
      },
      {
        value: 'I158',
        label: 'SAN SEVERO',
      },
      {
        value: 'I193',
        label: "SANT'AGATA o SANT'AGATA DI PUGLIA",
      },
      {
        value: 'I641',
        label: 'SERRACAPRIOLA',
      },
      {
        value: 'I962',
        label: 'STORNARA',
      },
      {
        value: 'I963',
        label: 'STORNARELLA',
      },
      {
        value: 'L273',
        label: 'TORREMAGGIORE',
      },
      {
        value: 'L447',
        label: 'TROIA',
      },
      {
        value: 'L842',
        label: 'VICO o VICO DEL GARGANO',
      },
      {
        value: 'L858',
        label: 'VIESTE',
      },
      {
        value: 'M131',
        label: 'VOLTURARA o VOLTURARA APPULA',
      },
      {
        value: 'M132',
        label: 'VOLTURINO',
      },
      {
        value: 'M267',
        label: 'ZAPPONETA',
      },
    ],
  },

  {
    value: 'FI',
    label: 'Firenze',
    children: [
      {
        value: 'A564',
        label: 'BAGNO A RIPOLI',
      },
      {
        value: 'A632',
        label: 'BARBERINO DI MUGELLO',
      },
      {
        value: 'M408',
        label: 'BARBERINO TAVARNELLE',
      },
      {
        value: 'A633',
        label: "BARBERINO VAL D'ELSA",
      },
      {
        value: 'B036',
        label: 'BORGO SAN LORENZO',
      },
      {
        value: 'B210',
        label: 'BROZZI',
      },
      {
        value: 'B405',
        label: 'CALENZANO o CALENDASCO',
      },
      {
        value: 'B507',
        label: 'CAMPI BISENZIO',
      },
      {
        value: 'B684',
        label: 'CAPRAIA o CAPRAIA E LIMITE',
      },
      {
        value: 'B962',
        label: 'CASELLINA E TORRI o SCANDICCI',
      },
      {
        value: 'C101',
        label: 'CASTELFIORENTINO',
      },
      {
        value: 'C529',
        label: 'CERRETO GUIDI',
      },
      {
        value: 'C540',
        label: 'CERTALDO',
      },
      {
        value: 'D299',
        label: 'DICOMANO',
      },
      {
        value: 'D403',
        label: 'EMPOLI',
      },
      {
        value: 'D575',
        label: 'FIESOLE',
      },
      {
        value: 'D583',
        label: 'FIGLINE o FIGLINE VALDARNO',
      },
      {
        value: 'M321',
        label: 'FIGLINE E INCISA VALDARNO',
      },
      {
        value: 'D612',
        label: 'FIRENZE',
      },
      {
        value: 'D613',
        label: 'FIRENZUOLA',
      },
      {
        value: 'D815',
        label: 'FUCECCHIO',
      },
      {
        value: 'D887',
        label: 'GALLUZZO',
      },
      {
        value: 'D895',
        label: 'GAMBASSI o GAMBASSI TERME',
      },
      {
        value: 'E169',
        label: 'GREVE o GREVE IN CHIANTI',
      },
      {
        value: 'E291',
        label: 'IMPRUNETA',
      },
      {
        value: 'E296',
        label: "INCISA o INCISA IN VAL D'ARNO",
      },
      {
        value: 'E466',
        label: 'LASTRA A SIGNA',
      },
      {
        value: 'E513',
        label: 'LEGNAIA',
      },
      {
        value: 'E668',
        label: 'LONDA',
      },
      {
        value: 'E971',
        label: 'MARRADI',
      },
      {
        value: 'F398',
        label: 'MONTAIONE',
      },
      {
        value: 'F451',
        label: 'MONTECALVOLI',
      },
      {
        value: 'F551',
        label: 'MONTELUPO o MONTELUPO FIORENTINO',
      },
      {
        value: 'F648',
        label: 'MONTESPERTOLI',
      },
      {
        value: 'G270',
        label: 'PALAZZUOLO o PALAZZUOLO DI ROMAGNA o PALAZZUOLO SUL SENIO',
      },
      {
        value: 'G420',
        label: 'PELAGO',
      },
      {
        value: 'G423',
        label: 'PELLEGRINO o PELLEGRINO DA CAREGGI',
      },
      {
        value: 'G825',
        label: 'PONTASSIEVE',
      },
      {
        value: 'G892',
        label: 'PORTA AL BORGO',
      },
      {
        value: 'G893',
        label: 'PORTA CARRATICA',
      },
      {
        value: 'G896',
        label: 'PORTA LUCCHESE',
      },
      {
        value: 'G899',
        label: 'PORTA SAN MARCO',
      },
      {
        value: 'G999',
        label: 'PRATO IN TOSCANA o PRATO',
      },
      {
        value: 'H222',
        label: 'REGGELLO',
      },
      {
        value: 'H286',
        label: "RIGNANO o RIGNANO SULL'ARNO",
      },
      {
        value: 'H617',
        label: 'ROVEZZANO',
      },
      {
        value: 'H635',
        label: 'RUFINA',
      },
      {
        value: 'H744',
        label: 'SAMBUCA o SAMBUCA PISTOIESE',
      },
      {
        value: 'H791',
        label: 'SAN CASCIANO IN VAL DI PESA',
      },
      {
        value: 'H937',
        label: 'SAN GODENZO',
      },
      {
        value: 'H980',
        label: 'SAN MARCELLO o SAN MARCELLO PISTOIESE',
      },
      {
        value: 'I085',
        label: 'SAN PIERO A SIEVE',
      },
      {
        value: 'I177',
        label: "SANTA CROCE o SANTA CROCE SULL'ARNO",
      },
      {
        value: 'I514',
        label: 'SCARPERIA',
      },
      {
        value: 'M326',
        label: 'SCARPERIA E SAN PIERO',
      },
      {
        value: 'I660',
        label: 'SERRAVALLE o SERRAVALLE PISTOIESE',
      },
      {
        value: 'I684',
        label: 'SESTO o SESTO FIORENTINO',
      },
      {
        value: 'I728',
        label: 'SIGNA',
      },
      {
        value: 'L067',
        label: 'TAVARNELLE o TAVARNELLE VAL DI PESA',
      },
      {
        value: 'L529',
        label: 'VAGLIA',
      },
      {
        value: 'L838',
        label: 'VICCHIO',
      },
      {
        value: 'M059',
        label: 'VINCI',
      },
    ],
  },

  {
    value: 'FM',
    label: 'Fermo',
    children: [
      {
        value: 'A009',
        label: 'ABBAZIA',
      },
      {
        value: 'A336',
        label: 'APRIANO',
      },
      {
        value: 'A790',
        label: 'BERDO SAN GIOVANNI',
      },
      {
        value: 'A824',
        label: 'BESERZIO DEL CARNARO',
      },
      {
        value: 'A890',
        label: 'BISTERZA',
      },
      {
        value: 'C124',
        label: 'CASTEL IABLANIZZA',
      },
      {
        value: 'C238',
        label: "CASTELNUOVO D'ISTRIA",
      },
      {
        value: 'C425',
        label: 'CEGLIE',
      },
      {
        value: 'C788',
        label: 'CLANA',
      },
      {
        value: 'D400',
        label: 'ELSANE',
      },
      {
        value: 'D620',
        label: 'FIUME',
      },
      {
        value: 'D669',
        label: 'FONTANA DEL CONTE',
      },
      {
        value: 'E478',
        label: 'LAURANA',
      },
      {
        value: 'F057',
        label: 'MATTERIA',
      },
      {
        value: 'F060',
        label: 'MATTUGLIE',
      },
      {
        value: 'F763',
        label: 'MOSCHIENA',
      },
      {
        value: 'H064',
        label: 'PRIMANO',
      },
      {
        value: 'H193',
        label: 'RATECEVO IN MONTE',
      },
      {
        value: 'H664',
        label: 'SAGORIA',
      },
      {
        value: 'I773',
        label: 'SMERIA',
      },
      {
        value: 'L128',
        label: 'TERRANOVA DI BISTERZA',
      },
      {
        value: 'M254',
        label: 'VAL SANTAMARINA',
      },
      {
        value: 'M293',
        label: 'VEGLIA',
      },
      {
        value: 'L935',
        label: 'VILLA DEL NEVOSO',
      },
      {
        value: 'M117',
        label: 'VOLOSCA',
      },
    ],
  },

  {
    value: 'FR',
    label: 'Frosinone',
    children: [
      {
        value: 'A032',
        label: 'ACQUAFONDATA',
      },
      {
        value: 'A054',
        label: 'ACUTO',
      },
      {
        value: 'A123',
        label: 'ALATRI',
      },
      {
        value: 'A244',
        label: 'ALVITO',
      },
      {
        value: 'A256',
        label: 'AMASENO o SAN LORENZO',
      },
      {
        value: 'A269',
        label: 'ANAGNI',
      },
      {
        value: 'A310',
        label: 'ANTICOLI DI CAMPAGNA o FIUGGI',
      },
      {
        value: 'A348',
        label: 'AQUINO',
      },
      {
        value: 'A363',
        label: 'ARCE',
      },
      {
        value: 'A421',
        label: 'ARNARA',
      },
      {
        value: 'A433',
        label: 'ARPINO',
      },
      {
        value: 'A486',
        label: 'ATINA',
      },
      {
        value: 'A502',
        label: 'AUSONIA o FRATTE',
      },
      {
        value: 'A720',
        label: 'BAUCO o BOVILLE ERNICA',
      },
      {
        value: 'A763',
        label: 'BELMONTE o BELMONTE CASTELLO',
      },
      {
        value: 'B195',
        label: 'BROCCO o BROCCOSTELLA',
      },
      {
        value: 'B862',
        label: 'CASALATTICO',
      },
      {
        value: 'B919',
        label: 'CASALVIERI',
      },
      {
        value: 'C034',
        label: 'CASSINO o SAN GERMANO',
      },
      {
        value: 'C223',
        label: 'CASTELNUOVO o CASTELNUOVO PARANO',
      },
      {
        value: 'C338',
        label: 'CASTRO DEI VOLSCI o CASTRO',
      },
      {
        value: 'C413',
        label: 'CECCANO',
      },
      {
        value: 'C479',
        label: 'CEPRANO',
      },
      {
        value: 'C545',
        label: 'CERVARO',
      },
      {
        value: 'C836',
        label: 'COLFELICE',
      },
      {
        value: 'C870',
        label: 'COLLE SAN MAGNO',
      },
      {
        value: 'C864',
        label: 'COLLEPARDO',
      },
      {
        value: 'C998',
        label: 'CORENO o CORENO AUSONIO',
      },
      {
        value: 'D440',
        label: 'ESPERIA',
      },
      {
        value: 'D483',
        label: 'FALVATERRA',
      },
      {
        value: 'D539',
        label: 'FERENTINO',
      },
      {
        value: 'D591',
        label: 'FILETTINO o FILETTINO GRAZIANI',
      },
      {
        value: 'D667',
        label: 'FONTANA o FONTANA LIRI',
      },
      {
        value: 'D682',
        label: 'FONTECHIARI o SCHIAVI',
      },
      {
        value: 'D810',
        label: 'FROSINONE',
      },
      {
        value: 'D819',
        label: 'FUMONE',
      },
      {
        value: 'D881',
        label: 'GALLINARO',
      },
      {
        value: 'E057',
        label: 'GIULIANO DI ROMA o GIULIANO',
      },
      {
        value: 'E236',
        label: 'GUARCINO',
      },
      {
        value: 'E340',
        label: 'ISOLA o ISOLA DEL LIRI o ISOLA PRESSO SORA',
      },
      {
        value: 'F620',
        label: 'MONTE SAN GIOVANNI CAMPANO o MONTE SAN GIOVANNI',
      },
      {
        value: 'F740',
        label: 'MOROLO',
      },
      {
        value: 'G276',
        label: 'PALIANO',
      },
      {
        value: 'G362',
        label: 'PASTENA',
      },
      {
        value: 'G374',
        label: 'PATRICA',
      },
      {
        value: 'G500',
        label: 'PESCOSOLIDO',
      },
      {
        value: 'G591',
        label: 'PICINISCO',
      },
      {
        value: 'G592',
        label: 'PICO',
      },
      {
        value: 'G598',
        label: 'PIEDIMONTE o PIEDIMONTE SAN GERMANO',
      },
      {
        value: 'G659',
        label: 'PIGLIO',
      },
      {
        value: 'G662',
        label: 'PIGNATARO o PIGNATARO INTERAMNA',
      },
      {
        value: 'G749',
        label: 'POFI',
      },
      {
        value: 'G838',
        label: 'PONTECORVO',
      },
      {
        value: 'G935',
        label: 'POSTA FIBRENO',
      },
      {
        value: 'H324',
        label: 'RIPI',
      },
      {
        value: 'H393',
        label: "ROCCA D'ARCE o ROCCADARCE",
      },
      {
        value: 'H415',
        label: 'ROCCAGUGLIELMA',
      },
      {
        value: 'H443',
        label: 'ROCCASECCA',
      },
      {
        value: 'H917',
        label: 'SAN GIOVANNI INCARICO',
      },
      {
        value: 'I265',
        label: "SANT'ANDREA o SANT'ANDREA DEL GARIGLIANO",
      },
      {
        value: 'I302',
        label: "SANT'APOLLINARE",
      },
      {
        value: 'I351',
        label: 'SANTOPADRE',
      },
      {
        value: 'I669',
        label: 'SERRONE',
      },
      {
        value: 'I697',
        label: 'SETTEFRATI',
      },
      {
        value: 'I716',
        label: 'SGURGOLA',
      },
      {
        value: 'I838',
        label: 'SORA',
      },
      {
        value: 'I973',
        label: 'STRANGOLAGALLI',
      },
      {
        value: 'L009',
        label: 'SUPINO',
      },
      {
        value: 'L105',
        label: 'TERELLE',
      },
      {
        value: 'L243',
        label: 'TORRE CAJETANI o TORRE',
      },
      {
        value: 'L290',
        label: 'TORRICE',
      },
      {
        value: 'L398',
        label: 'TREVI NEL LAZIO o TREVI',
      },
      {
        value: 'L437',
        label: 'TRIVIGLIANO',
      },
      {
        value: 'L598',
        label: 'VALLECORSA',
      },
      {
        value: 'L605',
        label: 'VALLEFREDDA o VALLEMAIO',
      },
      {
        value: 'L614',
        label: 'VALLEROTONDA',
      },
      {
        value: 'L780',
        label: 'VEROLI',
      },
      {
        value: 'L836',
        label: 'VICALVI',
      },
      {
        value: 'L843',
        label: 'VICO NEL LAZIO o VICO',
      },
      {
        value: 'I364',
        label: 'VILLA SANTO STEFANO o SANTO STEFANO',
      },
      {
        value: 'M083',
        label: 'VITICUSO',
      },
    ],
  },

  {
    value: 'GE',
    label: 'Genova',
    children: [
      {
        value: 'A331',
        label: 'APPARIZIONE',
      },
      {
        value: 'A388',
        label: 'ARENZANO',
      },
      {
        value: 'A506',
        label: 'AVEGNO',
      },
      {
        value: 'A658',
        label: 'BARGAGLI',
      },
      {
        value: 'A724',
        label: 'BAVARI',
      },
      {
        value: 'A922',
        label: 'BOGLIASCO',
      },
      {
        value: 'A923',
        label: 'BOGLIASCO PIEVE',
      },
      {
        value: 'A951',
        label: 'BOLZANETO',
      },
      {
        value: 'A992',
        label: 'BORGHETTO o BORGHETTO DI VARA',
      },
      {
        value: 'B066',
        label: 'BORZOLI',
      },
      {
        value: 'B067',
        label: 'BORZONASCA',
      },
      {
        value: 'B282',
        label: 'BUSALLA',
      },
      {
        value: 'B356',
        label: 'CAGNA',
      },
      {
        value: 'B369',
        label: 'CAIRO o CAIRO MONTENOTTE',
      },
      {
        value: 'B408',
        label: 'CALICE o CALESTANO',
      },
      {
        value: 'B451',
        label: 'CALVISIO',
      },
      {
        value: 'B490',
        label: 'CAMOGLI',
      },
      {
        value: 'B538',
        label: 'CAMPO LIGURE o CAMPOFREDDO',
      },
      {
        value: 'B551',
        label: 'CAMPOMORONE o LARVEGO',
      },
      {
        value: 'B596',
        label: 'CANEPA',
      },
      {
        value: 'B726',
        label: 'CARASCO',
      },
      {
        value: 'B815',
        label: 'CARPE',
      },
      {
        value: 'B837',
        label: 'CARRETTO',
      },
      {
        value: 'B927',
        label: 'CASANOVA o CASANOVA LERRONE',
      },
      {
        value: 'B939',
        label: 'CASARZA o CASARZA LIGURE',
      },
      {
        value: 'B956',
        label: 'CASELLA',
      },
      {
        value: 'C276',
        label: 'CASTELVECCHIO o CASTELVECCHIO DI ROCCA BARBENA',
      },
      {
        value: 'C302',
        label: 'CASTIGLIONE o CASTIGLIONE CHIAVARESE',
      },
      {
        value: 'C443',
        label: 'CELLE o CELLE LIGURE',
      },
      {
        value: 'C481',
        label: 'CERANESI',
      },
      {
        value: 'C621',
        label: 'CHIAVARI',
      },
      {
        value: 'C673',
        label: 'CICAGNA',
      },
      {
        value: 'C729',
        label: 'CISANO o CISANO SUL NEVA',
      },
      {
        value: 'C823',
        label: 'COGOLETO',
      },
      {
        value: 'C826',
        label: 'COGORNO',
      },
      {
        value: 'C995',
        label: 'COREGLIA o COREGLIA DI FONTANABUONA o COREGLIA LIGURE',
      },
      {
        value: 'D025',
        label: 'CORNIGLIANO o CORNIGLIANO LIGURE',
      },
      {
        value: 'D175',
        label: 'CROCEFIESCHI',
      },
      {
        value: 'D255',
        label: 'DAVAGNA o ROSSO',
      },
      {
        value: 'D509',
        label: 'FASCIA',
      },
      {
        value: 'D512',
        label: 'FAVALE o FAVALE DI MALVARO',
      },
      {
        value: 'D642',
        label: 'FOCE',
      },
      {
        value: 'D677',
        label: 'FONTANIGORDA',
      },
      {
        value: 'D969',
        label: 'GENOVA',
      },
      {
        value: 'E108',
        label: 'GORRA',
      },
      {
        value: 'E109',
        label: 'GORRETO',
      },
      {
        value: 'E341',
        label: 'ISOLA o ISOLA DEL CANTONE',
      },
      {
        value: 'E488',
        label: 'LAVAGNA',
      },
      {
        value: 'E519',
        label: 'LEIVI o SAN RUFINO',
      },
      {
        value: 'E650',
        label: 'LODISIO',
      },
      {
        value: 'E695',
        label: 'LORSICA',
      },
      {
        value: 'E737',
        label: 'LUMARZO',
      },
      {
        value: 'E822',
        label: 'MAGNONE o PORTIO',
      },
      {
        value: 'E918',
        label: 'MARASSI',
      },
      {
        value: 'E985',
        label: 'MARTINA o MARTINA OLBA',
      },
      {
        value: 'F020',
        label: 'MASONE',
      },
      {
        value: 'F098',
        label: 'MELE',
      },
      {
        value: 'F173',
        label: 'MEZZANEGO',
      },
      {
        value: 'F202',
        label: 'MIGNANEGO',
      },
      {
        value: 'F256',
        label: 'MOCONESI',
      },
      {
        value: 'F282',
        label: 'MOLASSANA',
      },
      {
        value: 'F354',
        label: 'MONEGLIA',
      },
      {
        value: 'F445',
        label: 'MONTEBRUNO',
      },
      {
        value: 'F609',
        label: 'MONTEROSSO o MONTEROSSO AL MARE',
      },
      {
        value: 'F682',
        label: 'MONTOGGIO',
      },
      {
        value: 'F805',
        label: 'MULTEDO',
      },
      {
        value: 'F858',
        label: 'NE',
      },
      {
        value: 'F862',
        label: 'NEIRONE',
      },
      {
        value: 'F873',
        label: 'NERVI',
      },
      {
        value: 'G091',
        label: 'ORCO',
      },
      {
        value: 'G093',
        label: 'ORERO',
      },
      {
        value: 'G414',
        label: 'PEGLI',
      },
      {
        value: 'G473',
        label: 'PERTI',
      },
      {
        value: 'G542',
        label: 'PIANA o PIANA CRIXIA',
      },
      {
        value: 'G605',
        label: 'PIETRA o PIETRA LIGURE',
      },
      {
        value: 'G646',
        label: 'PIEVE DI SORI o PIEVE LIGURE',
      },
      {
        value: 'G841',
        label: 'PONTEDECIMO',
      },
      {
        value: 'G913',
        label: 'PORTOFINO',
      },
      {
        value: 'G967',
        label: 'PRA',
      },
      {
        value: 'H073',
        label: 'PROPATA',
      },
      {
        value: 'H115',
        label: 'QUARTO AL MARE o QUARTO DEI MILLE',
      },
      {
        value: 'H135',
        label: 'QUINTO AL MARE',
      },
      {
        value: 'H183',
        label: 'RAPALLO',
      },
      {
        value: 'H212',
        label: 'RECCO',
      },
      {
        value: 'H258',
        label: 'REZZOAGLIO',
      },
      {
        value: 'H275',
        label: "RICCO' o RICCO' DEL GOLFO DI SPEZIA",
      },
      {
        value: 'H339',
        label: 'RIVAROLO o RIVAROLO LIGURE',
      },
      {
        value: 'H463',
        label: 'ROCCHETTA CAIRO',
      },
      {
        value: 'H536',
        label: 'RONCO o RONCO SCRIVIA',
      },
      {
        value: 'H546',
        label: 'RONDANINA',
      },
      {
        value: 'H581',
        label: 'ROSSIGLIONE',
      },
      {
        value: 'H599',
        label: 'ROVEGNO',
      },
      {
        value: 'H802',
        label: 'SAN COLOMBANO o SAN COLOMBANO CERTENOLI',
      },
      {
        value: 'H849',
        label: "SAN FRANCESCO D'ALBARO",
      },
      {
        value: 'H853',
        label: 'SAN FRUTTUOSO',
      },
      {
        value: 'H909',
        label: 'SAN GIOVANNI BATTISTA',
      },
      {
        value: 'I004',
        label: "SAN MARTINO D'ALBARO",
      },
      {
        value: 'I081',
        label: "SAN PIER D'ARENA",
      },
      {
        value: 'I134',
        label: 'SAN QUIRICO o SAN QUIRICO IN VAL DI POLCEVERA',
      },
      {
        value: 'I340',
        label: "SANT'ILARIO o SANT'ILARIO LIGURE",
      },
      {
        value: 'I346',
        label: "SANT'OLCESE",
      },
      {
        value: 'I225',
        label: 'SANTA MARGHERITA o SANTA MARGHERITA LIGURE',
      },
      {
        value: 'I363',
        label: 'SANTO STEFANO o SANTO STEFANO DI MAGRA',
      },
      {
        value: 'I368',
        label: "SANTO STEFANO D'AVETO",
      },
      {
        value: 'I475',
        label: 'SAVIGNONE',
      },
      {
        value: 'I640',
        label: "SERRA o SERRA RICCO'",
      },
      {
        value: 'I693',
        label: 'SESTRI LEVANTE',
      },
      {
        value: 'I694',
        label: 'SESTRI PONENTE',
      },
      {
        value: 'I852',
        label: 'SORI o SORI-CANEPA',
      },
      {
        value: 'I934',
        label: 'STAGLIENO',
      },
      {
        value: 'I987',
        label: 'STRUPPA',
      },
      {
        value: 'L167',
        label: 'TIGLIETO',
      },
      {
        value: 'L298',
        label: 'TORRIGLIA',
      },
      {
        value: 'L315',
        label: 'TOVO o TOVO SAN GIACOMO',
      },
      {
        value: 'L352',
        label: 'TREBIANO o TREBIANO MAGRA',
      },
      {
        value: 'L416',
        label: 'TRIBOGNA',
      },
      {
        value: 'L507',
        label: 'USCIO',
      },
      {
        value: 'L528',
        label: 'VADO o VADO LIGURE',
      },
      {
        value: 'L546',
        label: 'VALBREVENNA',
      },
      {
        value: 'L681',
        label: 'VARESE o VARESE LIGURE',
      },
      {
        value: 'L684',
        label: 'VARIGOTTI',
      },
      {
        value: 'L803',
        label: 'VERZI PIETRA',
      },
      {
        value: 'L819',
        label: 'VEZZANO o VEZZANO LIGURE',
      },
      {
        value: 'L822',
        label: 'VEZZI',
      },
      {
        value: 'L975',
        label: "VILLANOVA o VILLANOVA D'ALBENGA",
      },
      {
        value: 'M105',
        label: 'VOBBIA',
      },
      {
        value: 'M129',
        label: 'VOLTRI',
      },
      {
        value: 'M182',
        label: 'ZOAGLI',
      },
    ],
  },

  {
    value: 'GO',
    label: 'Gorizia',
    children: [
      {
        value: 'A095',
        label: 'AIBA',
      },
      {
        value: 'A099',
        label: 'AIDUSSINA',
      },
      {
        value: 'A298',
        label: 'ANICOVA CORADA',
      },
      {
        value: 'A505',
        label: 'AUZZA',
      },
      {
        value: 'A715',
        label: 'BATTAGLIA DELLA BAINSIZZA',
      },
      {
        value: 'A797',
        label: 'BERGOGNA',
      },
      {
        value: 'A867',
        label: 'BIGLIA',
      },
      {
        value: 'A868',
        label: 'BIGLIANA',
      },
      {
        value: 'B047',
        label: 'BORIANO',
      },
      {
        value: 'B125',
        label: 'BRAZZANO',
      },
      {
        value: 'B163',
        label: 'BRESTOVIZZA IN VALLE',
      },
      {
        value: 'B164',
        label: 'BRETTO',
      },
      {
        value: 'B245',
        label: 'BUDAGNE',
      },
      {
        value: 'B930',
        label: "CA' SANTO SPIRITO DELLA BAINSIZZA",
      },
      {
        value: 'B401',
        label: 'CAL DI CANALE',
      },
      {
        value: 'B793',
        label: 'CAMIGNA',
      },
      {
        value: 'B575',
        label: "CANALE D'ISONZO",
      },
      {
        value: 'B673',
        label: 'CAPORETTO',
      },
      {
        value: 'B712',
        label: 'CAPRIVA o CAPRIVA DEL FRIULI o CAPRIVA DI CORMONS',
      },
      {
        value: 'C099',
        label: 'CASTEL DOBRA',
      },
      {
        value: 'C416',
        label: 'CECONICO',
      },
      {
        value: 'C519',
        label: 'CERNIZZA GORIZIANA',
      },
      {
        value: 'C611',
        label: 'CHIAPOVANO',
      },
      {
        value: 'C720',
        label: 'CIRCHINA',
      },
      {
        value: 'C805',
        label: 'COBBIA',
      },
      {
        value: 'C921',
        label: 'COMENO',
      },
      {
        value: 'D014',
        label: 'CORMONS',
      },
      {
        value: 'D031',
        label: 'CORONA',
      },
      {
        value: 'D084',
        label: 'COSBANA o COSBANA NEL COLLIO',
      },
      {
        value: 'D138',
        label: 'CREDA',
      },
      {
        value: 'D282',
        label: 'DESCLA',
      },
      {
        value: 'D312',
        label: "DOBERDO' o DOBERDO' DEL LAGO",
      },
      {
        value: 'D322',
        label: 'DOL GRANDE',
      },
      {
        value: 'D326',
        label: 'DOL OTTELZA',
      },
      {
        value: 'D320',
        label: 'DOLE',
      },
      {
        value: 'D321',
        label: 'DOLEGNA o DOLEGNA DEL COLLIO',
      },
      {
        value: 'D368',
        label: 'DRESENZA',
      },
      {
        value: 'D427',
        label: 'ERSEL IN MONTE',
      },
      {
        value: 'D504',
        label: "FARRA o FARRA D'ISONZO",
      },
      {
        value: 'D645',
        label: 'FOGLIANO REDIPUGLIA o FOGLIANO DI MONFALCONE',
      },
      {
        value: 'D837',
        label: 'GABRIA',
      },
      {
        value: 'D838',
        label: 'GABROVIZZA',
      },
      {
        value: 'D922',
        label: 'GARGARO',
      },
      {
        value: 'E042',
        label: 'GIOIACI',
      },
      {
        value: 'E073',
        label: 'GODOVICI',
      },
      {
        value: 'E095',
        label: 'GORIANO',
      },
      {
        value: 'E098',
        label: 'GORIZIA',
      },
      {
        value: 'E119',
        label: 'GOZZA',
      },
      {
        value: 'E121',
        label: 'GRACOVA SERRAVALLE',
      },
      {
        value: 'E124',
        label: "GRADISCA o GRADISCA D'ISONZO",
      },
      {
        value: 'E125',
        label: 'GRADO',
      },
      {
        value: 'E277',
        label: "IDRESCA D'ISONZO",
      },
      {
        value: 'E278',
        label: 'IDRIA',
      },
      {
        value: 'E279',
        label: 'IDRIA DI SOTTO',
      },
      {
        value: 'E508',
        label: 'LEDINE',
      },
      {
        value: 'E572',
        label: 'LIBUSSINA',
      },
      {
        value: 'E641',
        label: 'LOCAVIZZA DI AIDUSSINA',
      },
      {
        value: 'E642',
        label: 'LOCAVIZZA DI CANALE',
      },
      {
        value: 'E696',
        label: 'LOSE',
      },
      {
        value: 'E720',
        label: 'LUCINICO',
      },
      {
        value: 'E732',
        label: 'LUICO',
      },
      {
        value: 'E952',
        label: 'MARIANO o MARIANO DEL FRIULI',
      },
      {
        value: 'F079',
        label: 'MEDANA',
      },
      {
        value: 'F081',
        label: 'MEDEA',
      },
      {
        value: 'F150',
        label: 'MERNA',
      },
      {
        value: 'F356',
        label: 'MONFALCONE',
      },
      {
        value: 'F635',
        label: 'MONTE SAN VITO',
      },
      {
        value: 'F652',
        label: 'MONTE URABICE',
      },
      {
        value: 'F577',
        label: "MONTENERO D'IDRIA",
      },
      {
        value: 'F649',
        label: 'MONTESPINO',
      },
      {
        value: 'F710',
        label: 'MORARO',
      },
      {
        value: 'F767',
        label: 'MOSSA',
      },
      {
        value: 'G053',
        label: 'OLTRESONZIA',
      },
      {
        value: 'G077',
        label: 'OPACCHIASELLA',
      },
      {
        value: 'G175',
        label: 'OSSECCA VITTUGLIA',
      },
      {
        value: 'G176',
        label: 'OSSEGLIANO SAN MICHELE',
      },
      {
        value: 'G310',
        label: 'PANIQUA',
      },
      {
        value: 'G599',
        label: 'PIEDIMONTE DEL CALVARIO o PODGORA',
      },
      {
        value: 'G731',
        label: 'PLANINA',
      },
      {
        value: 'G738',
        label: 'PLEZZO',
      },
      {
        value: 'G739',
        label: 'PLISCOVIZZA DELLA MADONNA',
      },
      {
        value: 'G744',
        label: 'POCRAI DEL PERO',
      },
      {
        value: 'G748',
        label: 'PODRAGA',
      },
      {
        value: 'H054',
        label: 'PREVACINA',
      },
      {
        value: 'H178',
        label: 'RANZIANO',
      },
      {
        value: 'H283',
        label: 'RIFEMBERGO',
      },
      {
        value: 'H513',
        label: 'ROMANS',
      },
      {
        value: 'H514',
        label: "ROMANS D'ISONZO",
      },
      {
        value: 'H531',
        label: 'RONCHI o RONCHI DEI LEGIONARI o RONCHI DI MONFALCONE',
      },
      {
        value: 'H550',
        label: 'RONZINA',
      },
      {
        value: 'H653',
        label: 'SABLE GRANDE',
      },
      {
        value: 'H660',
        label: 'SAGA',
      },
      {
        value: 'H665',
        label: 'SAGRADO',
      },
      {
        value: 'H692',
        label: 'SALCANO',
      },
      {
        value: 'H718',
        label: "SALONA D'ISONZO",
      },
      {
        value: 'H737',
        label: 'SAMARIA',
      },
      {
        value: 'H740',
        label: 'SAMBASSO',
      },
      {
        value: 'H787',
        label: "SAN CANZIAN D'ISONZO o SAN CANZIANO",
      },
      {
        value: 'H817',
        label: 'SAN DANIELE DEL CARSO',
      },
      {
        value: 'H845',
        label: 'SAN FLORIANO DEL COLLIO o SAN FLORIANO',
      },
      {
        value: 'H964',
        label: 'SAN LORENZO DI MOSSA o SAN LORENZO ISONTINO',
      },
      {
        value: 'I013',
        label: 'SAN MARTINO QUISCA',
      },
      {
        value: 'I082',
        label: "SAN PIER D'ISONZO o SAN PIETRO DELL'ISONZO",
      },
      {
        value: 'I091',
        label: 'SAN PIETRO DI GORIZIA o SAN PIETRO',
      },
      {
        value: 'I406',
        label: 'SAN VITO DI VIPACCO',
      },
      {
        value: 'I269',
        label: "SANT'ANDREA DI GORIZIA o SANT'ANDREA",
      },
      {
        value: 'I180',
        label: 'SANTA CROCE DI AIDUSSINA',
      },
      {
        value: 'I222',
        label: "SANTA LUCIA D'ISONZO",
      },
      {
        value: 'I355',
        label: 'SANTO SPIRITO DELLA BAINSIZZA',
      },
      {
        value: 'I479',
        label: "SAVOGNA D'ISONZO o SAVOGNA",
      },
      {
        value: 'I524',
        label: 'SCHERBINA',
      },
      {
        value: 'I552',
        label: 'SCRILLA',
      },
      {
        value: 'I557',
        label: 'SEBREGLIE',
      },
      {
        value: 'I568',
        label: 'SEDULA',
      },
      {
        value: 'I584',
        label: 'SELLA DELLE TRINCEE',
      },
      {
        value: 'I638',
        label: 'SERPENIZZA',
      },
      {
        value: 'I768',
        label: 'SLAPPE-ZORZI',
      },
      {
        value: 'I833',
        label: 'SONZIA',
      },
      {
        value: 'I939',
        label: 'STARANZANO',
      },
      {
        value: 'I989',
        label: 'STURIA DELLE FUSINE',
      },
      {
        value: 'L053',
        label: 'TARNOVA DELLA SELVA',
      },
      {
        value: 'L092',
        label: 'TEMENIZZA',
      },
      {
        value: 'L119',
        label: "TERNOVA D'ISONZO",
      },
      {
        value: 'L196',
        label: 'TOLMINO',
      },
      {
        value: 'L376',
        label: "TRENTA D'ISONZO",
      },
      {
        value: 'L417',
        label: 'TRIBUSSA',
      },
      {
        value: 'L474',
        label: 'TURRIACO',
      },
      {
        value: 'L520',
        label: 'USTIE',
      },
      {
        value: 'L782',
        label: 'VERPOGLIANO',
      },
      {
        value: 'L789',
        label: 'VERSA',
      },
      {
        value: 'L794',
        label: 'VERTOIBA o VERTOIBA IN CAMPI SANTI',
      },
      {
        value: 'L796',
        label: 'VERTOVINO',
      },
      {
        value: 'M038',
        label: 'VILLE MONTEVECCHIO',
      },
      {
        value: 'M043',
        label: 'VILLESSE',
      },
      {
        value: 'M066',
        label: 'VIPACCO',
      },
      {
        value: 'M112',
        label: 'VOISSIZZA DI COMENO',
      },
      {
        value: 'M134',
        label: 'VOLZANA',
      },
      {
        value: 'M135',
        label: 'VOSCHIA',
      },
      {
        value: 'M186',
        label: 'ZOLLA',
      },
    ],
  },

  {
    value: 'GR',
    label: 'Grosseto',
    children: [
      {
        value: 'A369',
        label: 'ARCIDOSSO',
      },
      {
        value: 'B497',
        label: 'CAMPAGNATICO',
      },
      {
        value: 'B646',
        label: 'CAPALBIO',
      },
      {
        value: 'C085',
        label: 'CASTEL DEL PIANO',
      },
      {
        value: 'C147',
        label: "CASTELL'AZZARA",
      },
      {
        value: 'C310',
        label: 'CASTIGLIONE DELLA PESCAIA',
      },
      {
        value: 'C705',
        label: 'CINIGIANO',
      },
      {
        value: 'C782',
        label: 'CIVITELLA PAGANICO o PAGANICO o PARI',
      },
      {
        value: 'D656',
        label: 'FOLLONICA',
      },
      {
        value: 'D948',
        label: 'GAVORRANO',
      },
      {
        value: 'E202',
        label: 'GROSSETO',
      },
      {
        value: 'E348',
        label: 'ISOLA DEL GIGLIO',
      },
      {
        value: 'E810',
        label: 'MAGLIANO o MAGLIANO IN TOSCANA',
      },
      {
        value: 'E875',
        label: 'MANCIANO',
      },
      {
        value: 'F032',
        label: 'MASSA MARITTIMA',
      },
      {
        value: 'F437',
        label: 'MONTE ARGENTARIO',
      },
      {
        value: 'F612',
        label: 'MONTEROTONDO MARITTIMO',
      },
      {
        value: 'F677',
        label: 'MONTIERI',
      },
      {
        value: 'G088',
        label: 'ORBETELLO',
      },
      {
        value: 'G716',
        label: 'PITIGLIANO',
      },
      {
        value: 'H417',
        label: 'ROCCALBEGNA',
      },
      {
        value: 'H449',
        label: 'ROCCASTRADA',
      },
      {
        value: 'I187',
        label: 'SANTA FIORA',
      },
      {
        value: 'I504',
        label: 'SCANSANO',
      },
      {
        value: 'I510',
        label: 'SCARLINO',
      },
      {
        value: 'I571',
        label: 'SEGGIANO',
      },
      {
        value: 'I601',
        label: 'SEMPRONIANO',
      },
      {
        value: 'I841',
        label: 'SORANO',
      },
    ],
  },

  {
    value: 'IM',
    label: 'Imperia',
    children: [
      {
        value: 'A111',
        label: 'AIROLE',
      },
      {
        value: 'A338',
        label: 'APRICALE',
      },
      {
        value: 'A344',
        label: "AQUILA o AQUILA D'ARROSCIA",
      },
      {
        value: 'A418',
        label: 'ARMO',
      },
      {
        value: 'A457',
        label: "ARZENO D'ONEGLIA o SAN BARTOLOMEO ED ARZENO",
      },
      {
        value: 'A499',
        label: 'AURIGO',
      },
      {
        value: 'A536',
        label: 'BADALUCCO',
      },
      {
        value: 'A581',
        label: 'BAIARDO o BAJARDO',
      },
      {
        value: 'A828',
        label: 'BESTAGNO',
      },
      {
        value: 'A984',
        label: 'BORDIGHERA',
      },
      {
        value: 'A993',
        label: "BORGHETTO o BORGHETTO D'ARROSCIA",
      },
      {
        value: 'A994',
        label: "BORGHETTO o BORGHETTO SAN NICOLO'",
      },
      {
        value: 'B039',
        label: "BORGO SANT'AGATA",
      },
      {
        value: 'B020',
        label: 'BORGOMARO',
      },
      {
        value: 'B074',
        label: 'BOSCOMARE',
      },
      {
        value: 'B291',
        label: 'BUSSANA',
      },
      {
        value: 'B559',
        label: 'CAMPOROSSO',
      },
      {
        value: 'B583',
        label: 'CANDEASCO',
      },
      {
        value: 'B721',
        label: 'CARAMAGNA o CARAMAGNA LIGURE',
      },
      {
        value: 'B734',
        label: 'CARAVONICA',
      },
      {
        value: 'B814',
        label: 'CARPASIO',
      },
      {
        value: 'B843',
        label: 'CARTARI E CALDERARA',
      },
      {
        value: 'C110',
        label: 'CASTEL VITTORIO o CASTELFRANCO',
      },
      {
        value: 'C143',
        label: 'CASTELLARO',
      },
      {
        value: 'C277',
        label: 'CASTELVECCHIO o CASTELVECCHIO DI SANTA MARIA MAGGIORE',
      },
      {
        value: 'C465',
        label: 'CENOVA',
      },
      {
        value: 'C511',
        label: 'CERIANA',
      },
      {
        value: 'C559',
        label: 'CERVO',
      },
      {
        value: 'C578',
        label: 'CESIO',
      },
      {
        value: 'C657',
        label: 'CHIUSANICO',
      },
      {
        value: 'C660',
        label: 'CHIUSAVECCHIA',
      },
      {
        value: 'C718',
        label: 'CIPRESSA',
      },
      {
        value: 'C755',
        label: 'CIVEZZA',
      },
      {
        value: 'C834',
        label: 'COLDIRODI o COLLA',
      },
      {
        value: 'C961',
        label: 'CONIO',
      },
      {
        value: 'D087',
        label: "COSIO o COSIO D'ARROSCIA",
      },
      {
        value: 'D104',
        label: 'COSTA o COSTA DI ONEGLIA',
      },
      {
        value: 'D114',
        label: 'COSTA RAINERA o COSTARAINERA',
      },
      {
        value: 'D293',
        label: 'DIANO ARENTINO',
      },
      {
        value: 'D294',
        label: 'DIANO BORELLO',
      },
      {
        value: 'D295',
        label: 'DIANO CALDERINA',
      },
      {
        value: 'D296',
        label: 'DIANO CASTELLO',
      },
      {
        value: 'D297',
        label: 'DIANO MARINA',
      },
      {
        value: 'D298',
        label: 'DIANO SAN PIETRO',
      },
      {
        value: 'D318',
        label: 'DOLCEACQUA',
      },
      {
        value: 'D319',
        label: 'DOLCEDO',
      },
      {
        value: 'D954',
        label: 'GAZZELLI',
      },
      {
        value: 'E290',
        label: 'IMPERIA',
      },
      {
        value: 'E346',
        label: 'ISOLABONA',
      },
      {
        value: 'E499',
        label: 'LAVINA',
      },
      {
        value: 'E603',
        label: 'LINGUEGLIETTA',
      },
      {
        value: 'E719',
        label: 'LUCINASCO',
      },
      {
        value: 'E966',
        label: 'MARO CASTELLO',
      },
      {
        value: 'F123',
        label: 'MENDATICA',
      },
      {
        value: 'F124',
        label: 'MENDATICA-MONTEGROSSO',
      },
      {
        value: 'F253',
        label: 'MOANO',
      },
      {
        value: 'F290',
        label: 'MOLINI DI TRIORA',
      },
      {
        value: 'F302',
        label: 'MOLTEDO INFERIORE o MONTEGRAZIE',
      },
      {
        value: 'F303',
        label: 'MOLTEDO SUPERIORE',
      },
      {
        value: 'F406',
        label: 'MONTALDO o MONTALDO LIGURE o MONTALTO LIGURE',
      },
      {
        value: 'M387',
        label: 'MONTALTO CARPASIO',
      },
      {
        value: 'F528',
        label: 'MONTEGROSSO o MONTEGROSSO PIAN LATTE',
      },
      {
        value: 'G035',
        label: 'OLIVASTRI',
      },
      {
        value: 'G041',
        label: 'OLIVETTA SAN MICHELE o PENNA o PIENA',
      },
      {
        value: 'G067',
        label: 'ONEGLIA',
      },
      {
        value: 'G164',
        label: 'OSPEDALETTI',
      },
      {
        value: 'G314',
        label: 'PANTASINA',
      },
      {
        value: 'G454',
        label: 'PERINALDO',
      },
      {
        value: 'G544',
        label: 'PIANAVIA',
      },
      {
        value: 'G563',
        label: 'PIANI',
      },
      {
        value: 'G607',
        label: 'PIETRABRUNA',
      },
      {
        value: 'G632',
        label: 'PIEVE o PIEVE DI TECO',
      },
      {
        value: 'G660',
        label: 'PIGNA',
      },
      {
        value: 'G750',
        label: 'POGGI',
      },
      {
        value: 'G814',
        label: 'POMPEIANA',
      },
      {
        value: 'G840',
        label: 'PONTEDASSIO',
      },
      {
        value: 'G890',
        label: 'PORNASSIO',
      },
      {
        value: 'G918',
        label: 'PORTO MAURIZIO',
      },
      {
        value: 'H027',
        label: "PRELA'",
      },
      {
        value: 'H180',
        label: 'RANZO',
      },
      {
        value: 'H257',
        label: 'REZZO',
      },
      {
        value: 'H328',
        label: 'RIVA o RIVA LIGURE',
      },
      {
        value: 'H345',
        label: 'RIVA SANTO STEFANO',
      },
      {
        value: 'H460',
        label: 'ROCCHETTA o ROCCHETTA NERVINA',
      },
      {
        value: 'H763',
        label: 'SAN BARTOLOMEO AL MARE o SAN BARTOLOMEO DEL CERVO',
      },
      {
        value: 'H780',
        label: 'SAN BIAGIO o SAN BIAGIO DELLA CIMA',
      },
      {
        value: 'H946',
        label: 'SAN LAZZARO o SAN LAZZARO REALE',
      },
      {
        value: 'H957',
        label: 'SAN LORENZO o SAN LORENZO AL MARE',
      },
      {
        value: 'I138',
        label: 'SAN REMO o SANREMO',
      },
      {
        value: 'I365',
        label: 'SANTO STEFANO AL MARE',
      },
      {
        value: 'I366',
        label: 'SANTO STEFANO AL MARE CON CASTELLARO',
      },
      {
        value: 'I440',
        label: 'SAROLA',
      },
      {
        value: 'I456',
        label: 'SASSO o SASSO DI BORDIGHERA',
      },
      {
        value: 'I556',
        label: 'SEBORGA',
      },
      {
        value: 'I796',
        label: 'SOLDANO',
      },
      {
        value: 'L024',
        label: 'TAGGIA',
      },
      {
        value: 'L077',
        label: 'TAVOLE',
      },
      {
        value: 'L146',
        label: 'TERZORIO',
      },
      {
        value: 'L236',
        label: 'TORRAZZA',
      },
      {
        value: 'L288',
        label: 'TORRIA',
      },
      {
        value: 'L430',
        label: 'TRIORA',
      },
      {
        value: 'L479',
        label: 'UBAGA',
      },
      {
        value: 'L596',
        label: 'VALLEBONA',
      },
      {
        value: 'L599',
        label: 'VALLECROSIA',
      },
      {
        value: 'L600',
        label: 'VALLECROSIA CON SAN BIAGIO DELLA CIMA',
      },
      {
        value: 'L632',
        label: 'VALLORIA o VALLORIA MARITTIMA',
      },
      {
        value: 'L693',
        label: 'VASIA',
      },
      {
        value: 'L741',
        label: 'VENTIMIGLIA',
      },
      {
        value: 'L809',
        label: 'VESSALICO',
      },
      {
        value: 'L943',
        label: 'VILLA FARALDI',
      },
      {
        value: 'L955',
        label: 'VILLA GUARDIA',
      },
      {
        value: 'M029',
        label: 'VILLA TALLA',
      },
      {
        value: 'M033',
        label: 'VILLA VIANI',
      },
      {
        value: 'M039',
        label: 'VILLE SAN PIETRO',
      },
      {
        value: 'M040',
        label: 'VILLE SAN SEBASTIANO',
      },
    ],
  },

  {
    value: 'IS',
    label: 'Isernia',
    children: [
      {
        value: 'A051',
        label: "ACQUAVIVA D'ISERNIA",
      },
      {
        value: 'A080',
        label: 'AGNONE',
      },
      {
        value: 'B682',
        label: 'CAPRACOTTA',
      },
      {
        value: 'B810',
        label: 'CAROVILLI',
      },
      {
        value: 'B830',
        label: 'CARPINONE',
      },
      {
        value: 'C082',
        label: 'CASTEL DEL GIUDICE',
      },
      {
        value: 'C270',
        label: 'CASTEL SAN VINCENZO',
      },
      {
        value: 'C246',
        label: 'CASTELPETROSO',
      },
      {
        value: 'C247',
        label: 'CASTELPIZZUTO',
      },
      {
        value: 'C620',
        label: 'CHIAUCI',
      },
      {
        value: 'C941',
        label: 'CONCA CASALE',
      },
      {
        value: 'D595',
        label: 'FILIGNANO',
      },
      {
        value: 'D715',
        label: 'FORNELLI',
      },
      {
        value: 'D811',
        label: 'FROSOLONE',
      },
      {
        value: 'E335',
        label: 'ISERNIA',
      },
      {
        value: 'E669',
        label: 'LONGANO',
      },
      {
        value: 'E778',
        label: "MACCHIA D'ISERNIA",
      },
      {
        value: 'E779',
        label: 'MACCHIAGODENA',
      },
      {
        value: 'F239',
        label: 'MIRANDA',
      },
      {
        value: 'F429',
        label: 'MONTAQUILA',
      },
      {
        value: 'F580',
        label: 'MONTENERO VAL COCCHIARA',
      },
      {
        value: 'F601',
        label: 'MONTERODUNI',
      },
      {
        value: 'G486',
        label: 'PESCHE',
      },
      {
        value: 'G495',
        label: 'PESCOLANCIANO',
      },
      {
        value: 'G497',
        label: 'PESCOPENNATARO',
      },
      {
        value: 'G606',
        label: 'PIETRABBONDANTE',
      },
      {
        value: 'G727',
        label: 'PIZZONE',
      },
      {
        value: 'G954',
        label: 'POZZILLI',
      },
      {
        value: 'H420',
        label: 'ROCCAMANDOLFI',
      },
      {
        value: 'H445',
        label: 'ROCCASICURA',
      },
      {
        value: 'I096',
        label: 'SAN PIETRO AVELLANA',
      },
      {
        value: 'I189',
        label: "SANT'AGAPITO",
      },
      {
        value: 'I282',
        label: "SANT'ANGELO DEL PESCO",
      },
      {
        value: 'I507',
        label: 'SCAPOLI',
      },
      {
        value: 'L696',
        label: 'VASTOGIRARDI',
      },
      {
        value: 'L725',
        label: 'VENAFRO',
      },
    ],
  },

  {
    value: 'KR',
    label: 'Crotone',
    children: [
      {
        value: 'B319',
        label: 'CACCURI',
      },
      {
        value: 'B771',
        label: 'CARFIZZI',
      },
      {
        value: 'B857',
        label: 'CASABONA',
      },
      {
        value: 'C501',
        label: 'CERENZIA',
      },
      {
        value: 'C725',
        label: "CIRO'",
      },
      {
        value: 'D123',
        label: 'COTRONEI',
      },
      {
        value: 'D189',
        label: 'CRUCOLI',
      },
      {
        value: 'D236',
        label: 'CUTRO',
      },
      {
        value: 'F108',
        label: 'MELISSA',
      },
      {
        value: 'F157',
        label: 'MESORACA',
      },
      {
        value: 'G278',
        label: 'PALLAGORIO',
      },
      {
        value: 'H383',
        label: 'ROCCABERNARDA',
      },
      {
        value: 'I308',
        label: 'SANTA SEVERINA',
      },
      {
        value: 'I468',
        label: 'SAVELLI',
      },
      {
        value: 'I494',
        label: 'SCANDALE',
      },
      {
        value: 'I982',
        label: 'STRONGOLI',
      },
      {
        value: 'L492',
        label: 'UMBRIATICO',
      },
      {
        value: 'L802',
        label: 'VERZINO',
      },
    ],
  },

  {
    value: 'LC',
    label: 'Lecco',
    children: [
      {
        value: 'A005',
        label: 'ABBADIA LARIANA',
      },
      {
        value: 'A112',
        label: 'AIRUNO',
      },
      {
        value: 'A594',
        label: 'BALLABIO',
      },
      {
        value: 'A683',
        label: 'BARZAGO',
      },
      {
        value: 'A686',
        label: "BARZANO'",
      },
      {
        value: 'A687',
        label: 'BARZIO',
      },
      {
        value: 'A745',
        label: 'BELLANO',
      },
      {
        value: 'B194',
        label: 'BRIVIO',
      },
      {
        value: 'B261',
        label: 'BULCIAGO',
      },
      {
        value: 'B763',
        label: 'CARENNO',
      },
      {
        value: 'B937',
        label: 'CASARGO',
      },
      {
        value: 'B943',
        label: 'CASATENOVO',
      },
      {
        value: 'B996',
        label: 'CASSAGO BRIANZA',
      },
      {
        value: 'C024',
        label: 'CASSINA VALSASSINA',
      },
      {
        value: 'C187',
        label: 'CASTELLO DI BRIANZA',
      },
      {
        value: 'C752',
        label: 'CIVATE',
      },
      {
        value: 'C839',
        label: 'COLICO',
      },
      {
        value: 'C851',
        label: 'COLLE BRIANZA',
      },
      {
        value: 'D065',
        label: 'CORTENOVA',
      },
      {
        value: 'D112',
        label: 'COSTA MASNAGA',
      },
      {
        value: 'D143',
        label: 'CREMELLA',
      },
      {
        value: 'D145',
        label: 'CREMENO',
      },
      {
        value: 'D280',
        label: 'DERVIO',
      },
      {
        value: 'D327',
        label: 'DOLZAGO',
      },
      {
        value: 'D346',
        label: 'DORIO',
      },
      {
        value: 'D398',
        label: 'ELLO',
      },
      {
        value: 'D428',
        label: 'ERVE',
      },
      {
        value: 'D436',
        label: 'ESINO LARIO',
      },
      {
        value: 'D865',
        label: 'GALBIATE',
      },
      {
        value: 'D913',
        label: 'GARBAGNATE MONASTERO',
      },
      {
        value: 'D926',
        label: 'GARLATE',
      },
      {
        value: 'E287',
        label: 'IMBERSAGO',
      },
      {
        value: 'E305',
        label: 'INTROBIO',
      },
      {
        value: 'E308',
        label: 'INTROZZO',
      },
      {
        value: 'M348',
        label: 'LA VALLETTA BRIANZA',
      },
      {
        value: 'E507',
        label: 'LECCO',
      },
      {
        value: 'E581',
        label: 'LIERNA',
      },
      {
        value: 'E656',
        label: 'LOMAGNA',
      },
      {
        value: 'E858',
        label: 'MALGRATE',
      },
      {
        value: 'E947',
        label: 'MARGNO',
      },
      {
        value: 'F133',
        label: 'MERATE',
      },
      {
        value: 'F248',
        label: 'MISSAGLIA',
      },
      {
        value: 'F265',
        label: 'MOGGIO',
      },
      {
        value: 'F304',
        label: 'MOLTENO',
      },
      {
        value: 'F561',
        label: 'MONTE MARENZO',
      },
      {
        value: 'F674',
        label: 'MONTICELLO BRIANZA',
      },
      {
        value: 'F758',
        label: 'MORTERONE',
      },
      {
        value: 'F887',
        label: 'NIBIONNO',
      },
      {
        value: 'G009',
        label: 'OGGIONO',
      },
      {
        value: 'G030',
        label: 'OLGINATE',
      },
      {
        value: 'G040',
        label: 'OLIVETO LARIO',
      },
      {
        value: 'G161',
        label: 'OSNAGO',
      },
      {
        value: 'G241',
        label: 'PAGNONA',
      },
      {
        value: 'G336',
        label: 'PARLASCO',
      },
      {
        value: 'G368',
        label: 'PASTURO',
      },
      {
        value: 'G448',
        label: 'PEREGO',
      },
      {
        value: 'G456',
        label: 'PERLEDO',
      },
      {
        value: 'G485',
        label: 'PESCATE',
      },
      {
        value: 'H028',
        label: 'PREMANA',
      },
      {
        value: 'H063',
        label: 'PRIMALUNA',
      },
      {
        value: 'H486',
        label: 'ROGENO',
      },
      {
        value: 'H596',
        label: 'ROVAGNATE',
      },
      {
        value: 'I243',
        label: "SANTA MARIA HOE'",
      },
      {
        value: 'I759',
        label: 'SIRONE',
      },
      {
        value: 'I761',
        label: 'SIRTORI',
      },
      {
        value: 'I994',
        label: 'SUEGLIO',
      },
      {
        value: 'I996',
        label: 'SUELLO',
      },
      {
        value: 'L022',
        label: 'TACENO',
      },
      {
        value: 'L368',
        label: 'TREMENICO',
      },
      {
        value: 'L581',
        label: 'VALGREGHENTINO',
      },
      {
        value: 'L634',
        label: 'VALMADRERA',
      },
      {
        value: 'M395',
        label: 'VALVARRONE',
      },
      {
        value: 'L680',
        label: 'VARENNA',
      },
      {
        value: 'L731',
        label: 'VENDROGNO',
      },
      {
        value: 'L751',
        label: 'VERCURAGO',
      },
      {
        value: 'M337',
        label: 'VERDERIO',
      },
      {
        value: 'L755',
        label: 'VERDERIO INFERIORE',
      },
      {
        value: 'L756',
        label: 'VERDERIO SUPERIORE',
      },
      {
        value: 'L813',
        label: 'VESTRENO',
      },
      {
        value: 'L866',
        label: "VIGANO'",
      },
    ],
  },

  {
    value: 'LE',
    label: 'Lecce',
    children: [
      {
        value: 'A042',
        label: 'ACQUARICA DEL CAPO',
      },
      {
        value: 'A184',
        label: 'ALESSANO',
      },
      {
        value: 'A185',
        label: 'ALEZIO o VILLAPICCIOTTI',
      },
      {
        value: 'A208',
        label: 'ALLISTE',
      },
      {
        value: 'A281',
        label: 'ANDRANO',
      },
      {
        value: 'A350',
        label: 'ARADEO',
      },
      {
        value: 'A425',
        label: 'ARNESANO',
      },
      {
        value: 'A572',
        label: 'BAGNOLO o BAGNOLO DEL SALENTO',
      },
      {
        value: 'B086',
        label: 'BOTRUGNO',
      },
      {
        value: 'B412',
        label: 'CALIMERA',
      },
      {
        value: 'B506',
        label: 'CAMPI o CAMPI SALENTINA',
      },
      {
        value: 'B616',
        label: 'CANNOLE',
      },
      {
        value: 'B690',
        label: 'CAPRARICA DI LECCE',
      },
      {
        value: 'B792',
        label: 'CARMIANO',
      },
      {
        value: 'B822',
        label: 'CARPIGNANO o CARPIGNANO SALENTINO',
      },
      {
        value: 'B936',
        label: 'CASARANO',
      },
      {
        value: 'C334',
        label: 'CASTRI DI LECCE o CASTRI FRANCONE',
      },
      {
        value: 'C335',
        label: "CASTRIGNANO DE' GRECI",
      },
      {
        value: 'C336',
        label: 'CASTRIGNANO DEL CAPO',
      },
      {
        value: 'M261',
        label: 'CASTRO',
      },
      {
        value: 'C377',
        label: 'CAVALLINO',
      },
      {
        value: 'C865',
        label: 'COLLEPASSO',
      },
      {
        value: 'C978',
        label: 'COPERTINO',
      },
      {
        value: 'D006',
        label: "CORIGLIANO o CORIGLIANO D'OTRANTO",
      },
      {
        value: 'D044',
        label: 'CORSANO',
      },
      {
        value: 'D223',
        label: 'CURSI',
      },
      {
        value: 'D237',
        label: 'CUTROFIANO',
      },
      {
        value: 'D305',
        label: 'DISO',
      },
      {
        value: 'D851',
        label: 'GAGLIANO o GAGLIANO DEL CAPO',
      },
      {
        value: 'D862',
        label: 'GALATINA',
      },
      {
        value: 'D863',
        label: 'GALATONE',
      },
      {
        value: 'D883',
        label: 'GALLIPOLI',
      },
      {
        value: 'E053',
        label: 'GIUGGIANELLO',
      },
      {
        value: 'E061',
        label: 'GIURDIGNANO',
      },
      {
        value: 'E227',
        label: 'GUAGNANO',
      },
      {
        value: 'E506',
        label: 'LECCE',
      },
      {
        value: 'E538',
        label: 'LEQUILE',
      },
      {
        value: 'E563',
        label: 'LEVERANO',
      },
      {
        value: 'E629',
        label: 'LIZZANELLO',
      },
      {
        value: 'E815',
        label: 'MAGLIE',
      },
      {
        value: 'E979',
        label: 'MARTANO',
      },
      {
        value: 'E984',
        label: 'MARTIGNANO',
      },
      {
        value: 'F054',
        label: 'MATINO',
      },
      {
        value: 'F101',
        label: 'MELENDUGNO',
      },
      {
        value: 'F109',
        label: 'MELISSANO',
      },
      {
        value: 'F117',
        label: 'MELPIGNANO',
      },
      {
        value: 'F194',
        label: 'MIGGIANO',
      },
      {
        value: 'F221',
        label: 'MINERVINO o MINERVINO DI LECCE',
      },
      {
        value: 'F604',
        label: 'MONTERONI o MONTERONI DI LECCE',
      },
      {
        value: 'F623',
        label: 'MONTESANO o MONTESANO SALENTINO',
      },
      {
        value: 'F716',
        label: 'MORCIANO o MORCIANO DI LEUCA',
      },
      {
        value: 'F816',
        label: 'MURO o MURO LECCESE',
      },
      {
        value: 'F842',
        label: "NARDO'",
      },
      {
        value: 'F881',
        label: 'NEVIANO',
      },
      {
        value: 'F916',
        label: 'NOCIGLIA',
      },
      {
        value: 'F970',
        label: 'NOVOLI',
      },
      {
        value: 'G136',
        label: 'ORTELLE',
      },
      {
        value: 'G188',
        label: 'OTRANTO',
      },
      {
        value: 'G285',
        label: 'PALMARIGGI',
      },
      {
        value: 'G325',
        label: 'PARABITA',
      },
      {
        value: 'G378',
        label: "PATU'",
      },
      {
        value: 'G751',
        label: 'POGGIARDO',
      },
      {
        value: 'M263',
        label: 'PORTO CESAREO',
      },
      {
        value: 'H047',
        label: 'PRESICCE',
      },
      {
        value: 'M428',
        label: 'PRESICCE-ACQUARICA',
      },
      {
        value: 'H147',
        label: 'RACALE',
      },
      {
        value: 'H632',
        label: 'RUFFANO',
      },
      {
        value: 'H708',
        label: 'SALICE o SALICE SALENTINO',
      },
      {
        value: 'H729',
        label: 'SALVE',
      },
      {
        value: 'M264',
        label: 'SAN CASSIANO',
      },
      {
        value: 'H793',
        label: 'SAN CESARIO o SAN CESARIO DI LECCE',
      },
      {
        value: 'H826',
        label: 'SAN DONATO o SAN DONATO DI LECCE',
      },
      {
        value: 'H882',
        label: 'SAN GIORGIO o SAN GIORGIO IONICO o SAN GIORGIO SOTTO TARANTO',
      },
      {
        value: 'I018',
        label: 'SAN MARZANO o SAN MARZANO DI SAN GIUSEPPE',
      },
      {
        value: 'I115',
        label: 'SAN PIETRO IN LAMA',
      },
      {
        value: 'H757',
        label: 'SANARICA',
      },
      {
        value: 'I059',
        label: 'SANNICOLA',
      },
      {
        value: 'I172',
        label: 'SANTA CESAREA o SANTA CESAREA TERME',
      },
      {
        value: 'I549',
        label: 'SCORRANO',
      },
      {
        value: 'I559',
        label: "SECLI'",
      },
      {
        value: 'I780',
        label: 'SOGLIANO o SOGLIANO CAVOUR',
      },
      {
        value: 'I800',
        label: 'SOLETO',
      },
      {
        value: 'I887',
        label: "SPECCHIA o SPECCHIA DE' PRETI",
      },
      {
        value: 'I923',
        label: 'SPONGANO',
      },
      {
        value: 'I930',
        label: 'SQUINZANO',
      },
      {
        value: 'I950',
        label: 'STERNATIA',
      },
      {
        value: 'L008',
        label: 'SUPERSANO',
      },
      {
        value: 'L010',
        label: 'SURANO',
      },
      {
        value: 'L011',
        label: 'SURBO',
      },
      {
        value: 'L064',
        label: 'TAURISANO',
      },
      {
        value: 'L074',
        label: 'TAVIANO',
      },
      {
        value: 'L166',
        label: 'TIGGIANO',
      },
      {
        value: 'L383',
        label: 'TREPUZZI',
      },
      {
        value: 'L419',
        label: 'TRICASE',
      },
      {
        value: 'L462',
        label: 'TUGLIE',
      },
      {
        value: 'L484',
        label: 'UGENTO',
      },
      {
        value: 'L485',
        label: 'UGGIANO LA CHIESA',
      },
      {
        value: 'L711',
        label: 'VEGLIE',
      },
      {
        value: 'L776',
        label: 'VERNOLE',
      },
      {
        value: 'M187',
        label: 'ZOLLINO',
      },
    ],
  },

  {
    value: 'LI',
    label: 'Livorno',
    children: [
      {
        value: 'A852',
        label: 'BIBBONA',
      },
      {
        value: 'B509',
        label: 'CAMPIGLIA MARITTIMA o CAMPIGLIA',
      },
      {
        value: 'B553',
        label: "CAMPO NELL'ELBA",
      },
      {
        value: 'B669',
        label: 'CAPOLIVERI',
      },
      {
        value: 'B685',
        label: 'CAPRAIA ISOLA',
      },
      {
        value: 'C044',
        label: 'CASTAGNETO CARDUCCI o CASTAGNETO o CASTAGNETO MARITTIMO',
      },
      {
        value: 'C415',
        label: 'CECINA o FITTO DI CECINA',
      },
      {
        value: 'C869',
        label: 'COLLESALVETTI',
      },
      {
        value: 'E625',
        label: 'LIVORNO',
      },
      {
        value: 'E680',
        label: 'LONGONE o PORTO AZZURRO o PORTO LONGONE',
      },
      {
        value: 'E930',
        label: 'MARCIANA',
      },
      {
        value: 'E931',
        label: 'MARCIANA MARINA',
      },
      {
        value: 'G687',
        label: 'PIOMBINO',
      },
      {
        value: 'G912',
        label: 'PORTOFERRAIO',
      },
      {
        value: 'H297',
        label: "RIO o RIO NELL'ELBA",
      },
      {
        value: 'M391',
        label: 'RIO',
      },
      {
        value: 'H305',
        label: 'RIO MARINA',
      },
      {
        value: 'H570',
        label: 'ROSIGNANO MARITTIMO o ROSIGNANO',
      },
      {
        value: 'I390',
        label: 'SAN VINCENZO',
      },
      {
        value: 'I454',
        label: 'SASSETTA',
      },
      {
        value: 'L019',
        label: 'SUVERETO',
      },
    ],
  },

  {
    value: 'LO',
    label: 'Lodi',
    children: [
      {
        value: 'A004',
        label: 'ABBADIA CERRETO',
      },
      {
        value: 'A811',
        label: 'BERTONICO',
      },
      {
        value: 'A919',
        label: "BOFFALORA D'ADDA o BOFFALORA o BOFFALORA D'ADDA",
      },
      {
        value: 'A995',
        label: 'BORGHETTO LODIGIANO o BORGHETTO',
      },
      {
        value: 'B017',
        label: 'BORGO SAN GIOVANNI o BORGO LITTORIO o CAZZIMANI',
      },
      {
        value: 'B141',
        label: 'BREMBIO',
      },
      {
        value: 'B456',
        label: 'CAMAIRAGO',
      },
      {
        value: 'B887',
        label: 'CASALETTO LODIGIANO o CASALETTO',
      },
      {
        value: 'B899',
        label: 'CASALMAIOCCO o COLOGNO',
      },
      {
        value: 'B910',
        label: 'CASALPUSTERLENGO',
      },
      {
        value: 'B961',
        label: 'CASELLE LANDI',
      },
      {
        value: 'B958',
        label: 'CASELLE LURANI o CASELLE',
      },
      {
        value: 'M393',
        label: 'CASTELGERUNDO',
      },
      {
        value: 'C228',
        label: "CASTELNUOVO BOCCA D'ADDA",
      },
      {
        value: 'C304',
        label: "CASTIGLIONE D'ADDA o CASTIGLIONE",
      },
      {
        value: 'C329',
        label: 'CASTIRAGA VIDARDO',
      },
      {
        value: 'C362',
        label: 'CAVACURTA',
      },
      {
        value: 'C394',
        label: "CAVENAGO D'ADDA o CAVENAGO",
      },
      {
        value: 'C555',
        label: "CERVIGNANO D'ADDA o CERVIGNANO",
      },
      {
        value: 'C816',
        label: 'CODOGNO',
      },
      {
        value: 'C917',
        label: 'COMAZZO',
      },
      {
        value: 'D021',
        label: 'CORNEGLIANO LAUDENSE o CORNEGLIANO',
      },
      {
        value: 'D028',
        label: 'CORNO GIOVINE',
      },
      {
        value: 'D029',
        label: 'CORNOVECCHIO',
      },
      {
        value: 'D068',
        label: 'CORTE PALASIO',
      },
      {
        value: 'D159',
        label: 'CRESPIATICA',
      },
      {
        value: 'D660',
        label: 'FOMBIO',
      },
      {
        value: 'D868',
        label: 'GALGAGNANO',
      },
      {
        value: 'E127',
        label: 'GRAFFIGNANA',
      },
      {
        value: 'E238',
        label: 'GUARDAMIGLIO',
      },
      {
        value: 'E627',
        label: 'LIVRAGA',
      },
      {
        value: 'E648',
        label: 'LODI',
      },
      {
        value: 'E651',
        label: 'LODI VECCHIO',
      },
      {
        value: 'E777',
        label: 'MACCASTORNA',
      },
      {
        value: 'E840',
        label: 'MAIRAGO',
      },
      {
        value: 'E852',
        label: 'MALEO',
      },
      {
        value: 'E994',
        label: 'MARUDO',
      },
      {
        value: 'F028',
        label: 'MASSALENGO',
      },
      {
        value: 'F102',
        label: 'MELETI',
      },
      {
        value: 'F149',
        label: 'MERLINO',
      },
      {
        value: 'F423',
        label: 'MONTANASO LOMBARDO o MONTANASO',
      },
      {
        value: 'F801',
        label: 'MULAZZANO',
      },
      {
        value: 'G107',
        label: 'ORIO LITTA o ORIO',
      },
      {
        value: 'G166',
        label: 'OSPEDALETTO LODIGIANO o OSPEDALETTO',
      },
      {
        value: 'G171',
        label: 'OSSAGO LODIGIANO',
      },
      {
        value: 'G096',
        label: 'PIEVE FISSIRAGA o ORGNAGA',
      },
      {
        value: 'H701',
        label: 'SALERANO SUL LAMBRO o SALERANO',
      },
      {
        value: 'H844',
        label: 'SAN FIORANO',
      },
      {
        value: 'I012',
        label: 'SAN MARTINO IN STRADA',
      },
      {
        value: 'I140',
        label: 'SAN ROCCO AL PORTO',
      },
      {
        value: 'I274',
        label: "SANT'ANGELO LODIGIANO o SANT'ANGELO",
      },
      {
        value: 'I362',
        label:
          'SANTO STEFANO LODIGIANO o SANTO STEFANO o SANTO STEFANO DEL CORNO',
      },
      {
        value: 'I561',
        label: 'SECUGNAGO',
      },
      {
        value: 'I612',
        label: 'SENNA LODIGIANA o SENNA',
      },
      {
        value: 'I815',
        label: 'SOMAGLIA',
      },
      {
        value: 'I848',
        label: 'SORDIO',
      },
      {
        value: 'F260',
        label: 'TAVAZZANO CON VILLAVESCO o MODIGNANO o VILLAVESCO',
      },
      {
        value: 'L125',
        label: 'TERRANOVA DEI PASSERINI o TERRANOVA',
      },
      {
        value: 'L469',
        label: 'TURANO LODIGIANO o TURANO',
      },
      {
        value: 'L572',
        label: 'VALERA FRATTA',
      },
      {
        value: 'L977',
        label: 'VILLANOVA DEL SILLARO o VILLANUOVA',
      },
      {
        value: 'M158',
        label: 'ZELO BUON PERSICO',
      },
    ],
  },

  {
    value: 'LT',
    label: 'Latina',
    children: [
      {
        value: 'A341',
        label: 'APRILIA',
      },
      {
        value: 'A707',
        label: 'BASSIANO',
      },
      {
        value: 'B527',
        label: 'CAMPODIMELE',
      },
      {
        value: 'C104',
        label: 'CASTELFORTE',
      },
      {
        value: 'C193',
        label: 'CASTELLONORATO',
      },
      {
        value: 'C740',
        label:
          'CISTERNA DI LATINA o CISTERNA DI LITTORIA o CISTERNA DI ROMA o CISTERNA',
      },
      {
        value: 'D003',
        label: 'CORI',
      },
      {
        value: 'D662',
        label: 'FONDI',
      },
      {
        value: 'D708',
        label: 'FORMIA o MOLA E CASTELLONE',
      },
      {
        value: 'D843',
        label: 'GAETA',
      },
      {
        value: 'E375',
        label: 'ITRI',
      },
      {
        value: 'E472',
        label: 'LATINA o LITTORIA',
      },
      {
        value: 'E527',
        label: 'LENOLA',
      },
      {
        value: 'E798',
        label: 'MAENZA',
      },
      {
        value: 'E913',
        label: 'MARANOLA',
      },
      {
        value: 'F616',
        label: 'MONTE SAN BIAGIO o MONTE SAN VITO o MONTICELLO',
      },
      {
        value: 'F937',
        label: 'NORMA',
      },
      {
        value: 'G865',
        label: 'PONTINIA',
      },
      {
        value: 'G871',
        label: 'PONZA',
      },
      {
        value: 'G698',
        label: 'PRIVERNO o PIPERNO',
      },
      {
        value: 'H076',
        label: 'PROSSEDI',
      },
      {
        value: 'H421',
        label: 'ROCCA MASSIMA',
      },
      {
        value: 'H413',
        label: 'ROCCAGORGA',
      },
      {
        value: 'H444',
        label: 'ROCCASECCA DEI VOLSCI o ROCCASECCA',
      },
      {
        value: 'H647',
        label: 'SABAUDIA',
      },
      {
        value: 'H836',
        label: 'SAN FELICE CIRCEO o SAN FELICE',
      },
      {
        value: 'I339',
        label: 'SANTI COSMA E DAMIANO',
      },
      {
        value: 'I634',
        label: 'SERMONETA',
      },
      {
        value: 'I712',
        label: 'SEZZE',
      },
      {
        value: 'I832',
        label: 'SONNINO',
      },
      {
        value: 'I892',
        label: 'SPERLONGA',
      },
      {
        value: 'I902',
        label: 'SPIGNO SATURNIA o SPIGNO',
      },
      {
        value: 'L120',
        label: 'TERRACINA',
      },
      {
        value: 'L742',
        label: 'VENTOTENE',
      },
    ],
  },

  {
    value: 'LU',
    label: 'Lucca',
    children: [
      {
        value: 'A241',
        label: 'ALTOPASCIO',
      },
      {
        value: 'A560',
        label: 'BAGNI DI LUCCA o BAGNO A CORSENA',
      },
      {
        value: 'A657',
        label: 'BARGA',
      },
      {
        value: 'B007',
        label: 'BORGO A MOZZANO o BORGO A MOZZANO E CERRETO DI SOTTO',
      },
      {
        value: 'B455',
        label: 'CAMAIORE',
      },
      {
        value: 'B557',
        label: 'CAMPORGIANO',
      },
      {
        value: 'B648',
        label: 'CAPANNORI',
      },
      {
        value: 'B760',
        label: 'CAREGGINE',
      },
      {
        value: 'C236',
        label: 'CASTELNUOVO DI GARFAGNANA',
      },
      {
        value: 'C303',
        label: 'CASTIGLIONE DI GARFAGNANA o CASTIGLIONE',
      },
      {
        value: 'C996',
        label: 'COREGLIA o COREGLIA ANTELMINELLI',
      },
      {
        value: 'D449',
        label: 'FABBRICHE DI VALLICO o TRASSILICO',
      },
      {
        value: 'M319',
        label: 'FABBRICHE DI VERGEMOLI',
      },
      {
        value: 'D730',
        label: 'FORTE DEI MARMI',
      },
      {
        value: 'D734',
        label: 'FOSCIANDORA',
      },
      {
        value: 'D874',
        label: 'GALLICANO',
      },
      {
        value: 'E059',
        label: 'GIUNCUGNANO',
      },
      {
        value: 'E715',
        label: 'LUCCA',
      },
      {
        value: 'F035',
        label: 'MASSAROSA',
      },
      {
        value: 'F225',
        label: 'MINUCCIANO',
      },
      {
        value: 'F283',
        label: 'MOLAZZANA',
      },
      {
        value: 'F452',
        label: 'MONTECARLO',
      },
      {
        value: 'G480',
        label: 'PESCAGLIA',
      },
      {
        value: 'G582',
        label: 'PIAZZA AL SERCHIO o PIAZZA',
      },
      {
        value: 'G628',
        label: 'PIETRASANTA',
      },
      {
        value: 'G648',
        label: 'PIEVE FOSCIANA',
      },
      {
        value: 'G882',
        label: 'PORCARI',
      },
      {
        value: 'I142',
        label: 'SAN ROMANO IN GARFAGNANA',
      },
      {
        value: 'I622',
        label: 'SERAVEZZA',
      },
      {
        value: 'I737',
        label: 'SILLANO',
      },
      {
        value: 'M347',
        label: 'SILLANO GIUNCUGNANO',
      },
      {
        value: 'I942',
        label: 'STAZZEMA',
      },
      {
        value: 'L533',
        label: 'VAGLI SOTTO',
      },
      {
        value: 'L763',
        label: 'VERGEMOLI',
      },
      {
        value: 'L833',
        label: 'VIAREGGIO',
      },
      {
        value: 'L913',
        label: 'VILLA BASILICA',
      },
      {
        value: 'L926',
        label: 'VILLA COLLEMANDINA',
      },
    ],
  },

  {
    value: 'MB',
    label: 'Monza e della Brianza',
    children: [
      {
        value: 'A087',
        label: 'AGRATE BRIANZA o AGRATE o AGRATE BRIANZA',
      },
      {
        value: 'A096',
        label: 'AICURZIO o AICURZIO',
      },
      {
        value: 'A159',
        label: 'ALBIATE o ALBIATE',
      },
      {
        value: 'A376',
        label: 'ARCORE o ARCORE',
      },
      {
        value: 'A668',
        label: 'BARLASSINA o BARLASSINA',
      },
      {
        value: 'A759',
        label: 'BELLUSCO o BELLUSCO',
      },
      {
        value: 'A802',
        label: 'BERNAREGGIO o BERNAREGGIO',
      },
      {
        value: 'A818',
        label: 'BESANA IN BRIANZA o BESANA o BESANA IN BRIANZA',
      },
      {
        value: 'A849',
        label: 'BIASSONO o BIASSONO',
      },
      {
        value: 'B105',
        label: 'BOVISIO-MASCIAGO o BOVISIO o BOVISIO-MASCIAGO',
      },
      {
        value: 'B187',
        label: 'BRIOSCO o BRIOSCO',
      },
      {
        value: 'B212',
        label: 'BRUGHERIO o BRUGHERIO',
      },
      {
        value: 'B272',
        label: 'BURAGO DI MOLGORA o BURAGO o BURAGO DI MOLGORA',
      },
      {
        value: 'B289',
        label: 'BUSNAGO o BUSNAGO',
      },
      {
        value: 'B501',
        label: 'CAMPARADA o CAMPARADA',
      },
      {
        value: 'B671',
        label: 'CAPONAGO o CAPONAGO',
      },
      {
        value: 'B729',
        label: 'CARATE BRIANZA o CARATE o CARATE BRIANZA',
      },
      {
        value: 'B798',
        label: 'CARNATE o CARNATE',
      },
      {
        value: 'C395',
        label: 'CAVENAGO DI BRIANZA o CAVENAGO o CAVENAGO DI BRIANZA',
      },
      {
        value: 'C512',
        label: 'CERIANO LAGHETTO o CERIANO o CERIANO LAGHETTO',
      },
      {
        value: 'C566',
        label: 'CESANO MADERNO o CESANO MADERNO',
      },
      {
        value: 'C820',
        label: 'COGLIATE o COGLIATE',
      },
      {
        value: 'C952',
        label: 'CONCOREZZO o CONCOREZZO',
      },
      {
        value: 'D019',
        label: "CORNATE D'ADDA o CORNATE o CORNATE D'ADDA",
      },
      {
        value: 'D038',
        label: 'CORREZZANA o CORREZZANA',
      },
      {
        value: 'D286',
        label: 'DESIO o DESIO',
      },
      {
        value: 'E063',
        label: 'GIUSSANO o GIUSSANO',
      },
      {
        value: 'E504',
        label: 'LAZZATE o LAZZATE',
      },
      {
        value: 'E530',
        label: 'LENTATE SUL SEVESO o LENTATE o LENTATE SUL SEVESO',
      },
      {
        value: 'E550',
        label: 'LESMO o LESMO',
      },
      {
        value: 'E591',
        label: 'LIMBIATE o LIMBIATE',
      },
      {
        value: 'E617',
        label: 'LISSONE o LISSONE',
      },
      {
        value: 'E786',
        label: 'MACHERIO o MACHERIO',
      },
      {
        value: 'F078',
        label: 'MEDA o MEDA',
      },
      {
        value: 'F165',
        label: 'MEZZAGO o MEZZAGO',
      },
      {
        value: 'F247',
        label: 'MISINTO o MISINTO',
      },
      {
        value: 'F704',
        label: 'MONZA o MONZA',
      },
      {
        value: 'F797',
        label: "MUGGIO' o MUGGI� o MUGGIO'",
      },
      {
        value: 'F944',
        label: 'NOVA MILANESE o NOVA o NOVA MILANESE',
      },
      {
        value: 'G116',
        label: 'ORNAGO o ORNAGO',
      },
      {
        value: 'H233',
        label: 'RENATE o RENATE',
      },
      {
        value: 'H529',
        label: 'RONCELLO o RONCELLO',
      },
      {
        value: 'H537',
        label: 'RONCO BRIANTINO o RONCO o RONCO BRIANTINO',
      },
      {
        value: 'I625',
        label: 'SEREGNO o SEREGNO',
      },
      {
        value: 'I709',
        label: 'SEVESO o SEVESO',
      },
      {
        value: 'I878',
        label: 'SOVICO o SOVICO',
      },
      {
        value: 'I998',
        label: 'SULBIATE o SULBIATE',
      },
      {
        value: 'L434',
        label: 'TRIUGGIO o TRIUGGIO',
      },
      {
        value: 'L511',
        label: 'USMATE VELATE o USMATE VELATE o VELATE o VELATE MILANESE',
      },
      {
        value: 'L677',
        label: 'VAREDO o VAREDO',
      },
      {
        value: 'L704',
        label: 'VEDANO AL LAMBRO o VEDANO o VEDANO AL LAMBRO',
      },
      {
        value: 'L709',
        label: 'VEDUGGIO CON COLZANO o VEDUGGIO CON COLZANO',
      },
      {
        value: 'L744',
        label: 'VERANO BRIANZA o VERANO o VERANO BRIANZA',
      },
      {
        value: 'M017',
        label: 'VILLASANTA o VILLA SAN FIORANO o VILLASANTA',
      },
      {
        value: 'M052',
        label: 'VIMERCATE o VIMERCATE',
      },
    ],
  },

  {
    value: 'MC',
    label: 'Macerata',
    children: [
      {
        value: 'A031',
        label: 'ACQUACANINA',
      },
      {
        value: 'A329',
        label: 'APIRO',
      },
      {
        value: 'A334',
        label: 'APPIGNANO',
      },
      {
        value: 'A739',
        label: 'BELFORTE o BELFORTE DEL CHIENTI o BELFORTE DEL CHIENTO',
      },
      {
        value: 'A947',
        label: 'BOLOGNOLA',
      },
      {
        value: 'B474',
        label: 'CAMERINO',
      },
      {
        value: 'B562',
        label: 'CAMPOROTONDO o CAMPOROTONDO DI FIASTRONE',
      },
      {
        value: 'C251',
        label: 'CASTELRAIMONDO',
      },
      {
        value: 'C267',
        label: 'CASTELSANTANGELO o CASTELSANTANGELO SUL NERA',
      },
      {
        value: 'C582',
        label: 'CESSAPALOMBO',
      },
      {
        value: 'C704',
        label: 'CINGOLI',
      },
      {
        value: 'C770',
        label: 'CIVITANOVA o CIVITANOVA MARCHE',
      },
      {
        value: 'C886',
        label: 'COLMURANO',
      },
      {
        value: 'D042',
        label: 'CORRIDONIA o PAUSULA',
      },
      {
        value: 'D429',
        label: "ESANATOGLIA o SANT'ANATOLIA",
      },
      {
        value: 'D564',
        label: 'FIASTRA',
      },
      {
        value: 'D566',
        label: 'FICANO o POGGIO SAN VICINO',
      },
      {
        value: 'D609',
        label: 'FIORDIMONTE',
      },
      {
        value: 'D628',
        label: 'FIUMINATA',
      },
      {
        value: 'D853',
        label: 'GAGLIOLE',
      },
      {
        value: 'E228',
        label: 'GUALDO',
      },
      {
        value: 'E694',
        label: 'LORO o LORO PICENO',
      },
      {
        value: 'E783',
        label: 'MACERATA',
      },
      {
        value: 'F051',
        label: 'MATELICA',
      },
      {
        value: 'F268',
        label: 'MOGLIANO',
      },
      {
        value: 'F460',
        label: 'MONTE CAVALLO o MONTE SAN POLO',
      },
      {
        value: 'F621',
        label: 'MONTE SAN GIUSTO o SAN GIUSTO',
      },
      {
        value: 'F622',
        label: 'MONTE SAN MARTINO',
      },
      {
        value: 'F454',
        label: 'MONTECASSIANO',
      },
      {
        value: 'F482',
        label: 'MONTECOSARO',
      },
      {
        value: 'F496',
        label: 'MONTEFANO',
      },
      {
        value: 'F552',
        label: 'MONTELUPONE',
      },
      {
        value: 'F567',
        label: 'MONTEMILONE o POLLENZA',
      },
      {
        value: 'F632',
        label: 'MONTESANTO o POTENZA PICENA',
      },
      {
        value: 'F749',
        label: 'MORROVALLE',
      },
      {
        value: 'F793',
        label: 'MUCCIA',
      },
      {
        value: 'G436',
        label: 'PENNA SAN GIOVANNI',
      },
      {
        value: 'G515',
        label: 'PETRIOLO',
      },
      {
        value: 'G657',
        label: 'PIEVE TORINA',
      },
      {
        value: 'G637',
        label: 'PIEVEBOVIGLIANA',
      },
      {
        value: 'G690',
        label: 'PIORACO',
      },
      {
        value: 'G911',
        label: 'PORTO CIVITANOVA',
      },
      {
        value: 'G919',
        label: 'PORTO RECANATI',
      },
      {
        value: 'H211',
        label: 'RECANATI',
      },
      {
        value: 'H323',
        label: 'RIPE SAN GINESIO',
      },
      {
        value: 'H876',
        label: 'SAN GINESIO',
      },
      {
        value: 'I156',
        label: 'SAN SEVERINO o SAN SEVERINO MARCHE',
      },
      {
        value: 'I286',
        label: "SANT'ANGELO IN PONTANO",
      },
      {
        value: 'I436',
        label: 'SARNANO',
      },
      {
        value: 'I569',
        label: 'SEFRO',
      },
      {
        value: 'I651',
        label: 'SERRAPETRONA',
      },
      {
        value: 'I661',
        label: 'SERRAVALLE o SERRAVALLE DI CHIENTI',
      },
      {
        value: 'L191',
        label: 'TOLENTINO',
      },
      {
        value: 'L366',
        label: 'TREIA',
      },
      {
        value: 'L501',
        label: 'URBISAGLIA o URBISAGLIA BONSERVIZI',
      },
      {
        value: 'L517',
        label: 'USSITA',
      },
      {
        value: 'M382',
        label: 'VALFORNACE',
      },
      {
        value: 'M078',
        label: 'VISSO',
      },
    ],
  },

  {
    value: 'ME',
    label: 'Messina',
    children: [
      {
        value: 'M211',
        label: 'ACQUEDOLCI',
      },
      {
        value: 'A177',
        label: 'ALCARA LI FUSI',
      },
      {
        value: 'A194',
        label: "ALI' o ALI' SUPERIORE",
      },
      {
        value: 'A201',
        label: "ALI' MARINA o ALI' TERME",
      },
      {
        value: 'A313',
        label: 'ANTILLO',
      },
      {
        value: 'A638',
        label: 'BARCELLONA POZZO DI GOTTO',
      },
      {
        value: 'A698',
        label: "BASICO' o CASALNUOVO",
      },
      {
        value: 'A723',
        label: 'BAUSO',
      },
      {
        value: 'B198',
        label: 'BROLO',
      },
      {
        value: 'B438',
        label: 'CALVARUSO',
      },
      {
        value: 'B660',
        label: 'CAPIZZI',
      },
      {
        value: 'B666',
        label: "CAPO D'ORLANDO",
      },
      {
        value: 'B695',
        label: 'CAPRI o CAPRI LEONE',
      },
      {
        value: 'B804',
        label: 'CARONIA',
      },
      {
        value: 'B918',
        label: 'CASALVECCHIO o CASALVECCHIO SICULO',
      },
      {
        value: 'C051',
        label: "CASTANIA o CASTELL'UMBERTO",
      },
      {
        value: 'C094',
        label: 'CASTEL DI LUCIO o CASTELLUCCIO',
      },
      {
        value: 'C210',
        label: 'CASTEL MOLA o CASTELMOLA o MOLA',
      },
      {
        value: 'C347',
        label: 'CASTROREALE',
      },
      {
        value: 'C568',
        label: "CESARO'",
      },
      {
        value: 'C956',
        label: "CONDRO'",
      },
      {
        value: 'D474',
        label: 'FALCONE',
      },
      {
        value: 'D569',
        label: 'FICARRA',
      },
      {
        value: 'D622',
        label: 'FIUMEDINISI',
      },
      {
        value: 'D635',
        label: 'FLORESTA',
      },
      {
        value: 'D661',
        label: 'FONDACHELLI-FANTINA',
      },
      {
        value: 'D733',
        label: "FORZA D'AGRO'",
      },
      {
        value: 'D765',
        label: 'FRANCAVILLA o FRANCAVILLA DI SICILIA',
      },
      {
        value: 'D793',
        label: "FRAZZANO'",
      },
      {
        value: 'D824',
        label: 'FURCI SICULO',
      },
      {
        value: 'D825',
        label: 'FURNARI',
      },
      {
        value: 'D844',
        label: 'GAGGI o KAGGI',
      },
      {
        value: 'D861',
        label: 'GALATI o GALATI MAMERTINO',
      },
      {
        value: 'D885',
        label: 'GALLODORO',
      },
      {
        value: 'E014',
        label: 'GIARDINI o GIARDINI-NAXOS',
      },
      {
        value: 'E043',
        label: 'GIOIOSA o GIOIOSA MAREA',
      },
      {
        value: 'E142',
        label: 'GRANITI',
      },
      {
        value: 'E233',
        label: "GUALTIERI SICAMINO'",
      },
      {
        value: 'E262',
        label: 'GUIDOMANDRI',
      },
      {
        value: 'E374',
        label: 'ITALA',
      },
      {
        value: 'E442',
        label: 'LANZA',
      },
      {
        value: 'E523',
        label: 'LENI',
      },
      {
        value: 'E555',
        label: 'LETOJANNI',
      },
      {
        value: 'E556',
        label: 'LETOJANNI GALLODORO',
      },
      {
        value: 'E571',
        label: 'LIBRIZZI',
      },
      {
        value: 'E594',
        label: 'LIMINA',
      },
      {
        value: 'E606',
        label: 'LIPARI',
      },
      {
        value: 'E634',
        label: 'LOCADI',
      },
      {
        value: 'E674',
        label: 'LONGI',
      },
      {
        value: 'E855',
        label: 'MALFA',
      },
      {
        value: 'E869',
        label: 'MALVAGNA',
      },
      {
        value: 'E876',
        label: 'MANDANICI',
      },
      {
        value: 'F066',
        label: "MAZZARA' o MAZZARRA' SANT'ANDREA",
      },
      {
        value: 'F147',
        label: "MERI'",
      },
      {
        value: 'F158',
        label: 'MESSINA',
      },
      {
        value: 'F206',
        label: 'MILAZZO',
      },
      {
        value: 'F210',
        label: 'MILITELLO o MILITELLO ROSMARINO',
      },
      {
        value: 'F242',
        label: 'MIRTO',
      },
      {
        value: 'F251',
        label: 'MISTRETTA',
      },
      {
        value: 'F277',
        label: 'MOIO o MOIO ALCANTARA',
      },
      {
        value: 'F359',
        label: 'MONFORTE o MONFORTE SAN GIORGIO',
      },
      {
        value: 'F368',
        label: 'MONGIUFFI MELIA',
      },
      {
        value: 'F395',
        label: 'MONTAGNAREALE',
      },
      {
        value: 'F400',
        label: 'MONTALBANO o MONTALBANO DI ELICONA o MONTALBANO ELICONA',
      },
      {
        value: 'F772',
        label: 'MOTTA CAMASTRA',
      },
      {
        value: 'F773',
        label: "MOTTA D'AFFERMO",
      },
      {
        value: 'F848',
        label: 'NASO',
      },
      {
        value: 'F901',
        label: 'NIZZA DI SICILIA o SAN FERDINANDO',
      },
      {
        value: 'F951',
        label: 'NOVARA o NOVARA DI SICILIA',
      },
      {
        value: 'G036',
        label: 'OLIVERI',
      },
      {
        value: 'G209',
        label: 'PACE DEL MELA',
      },
      {
        value: 'G234',
        label: 'PAGLIARA',
      },
      {
        value: 'G377',
        label: 'PATTI',
      },
      {
        value: 'G522',
        label: 'PETTINEO',
      },
      {
        value: 'G699',
        label: 'PIRAINO',
      },
      {
        value: 'H151',
        label: 'RACCUJA',
      },
      {
        value: 'H228',
        label: 'REITANO',
      },
      {
        value: 'H380',
        label: 'ROCCA o ROCCAVALDINA',
      },
      {
        value: 'H405',
        label: 'ROCCAFIORITA',
      },
      {
        value: 'H418',
        label: 'ROCCALUMERA',
      },
      {
        value: 'H455',
        label: 'ROCCELLA o ROCCELLA VALDEMONE',
      },
      {
        value: 'H479',
        label: "RODI' o RODI' MILICI",
      },
      {
        value: 'H519',
        label: 'ROMETTA',
      },
      {
        value: 'H711',
        label: 'SALINA',
      },
      {
        value: 'H842',
        label: 'SAN FILIPPO DEL MELA',
      },
      {
        value: 'H850',
        label: 'SAN FRATELLO',
      },
      {
        value: 'H982',
        label: "SAN MARCO o SAN MARCO D'ALFONSIO o SAN MARCO D'ALUNZIO",
      },
      {
        value: 'I084',
        label: 'SAN PIER MONFORTE o SAN PIER NICETO',
      },
      {
        value: 'I086',
        label: 'SAN PIERO PATTI o SAN PIETRO SOPRA PATTI',
      },
      {
        value: 'I147',
        label: 'SAN SALVATORE o SAN SALVATORE DI FITALIA',
      },
      {
        value: 'I328',
        label: 'SAN TEODORO',
      },
      {
        value: 'I199',
        label: "SANT'AGATA DI MILITELLO",
      },
      {
        value: 'I215',
        label: "SANT'ALESSIO SICULO",
      },
      {
        value: 'I283',
        label: "SANT'ANGELO DI BROLO",
      },
      {
        value: 'I184',
        label: 'SANTA DOMENICA o SANTA DOMENICA VITTORIA',
      },
      {
        value: 'I220',
        label: 'SANTA LUCIA o SANTA LUCIA DEL MELA',
      },
      {
        value: 'I254',
        label: 'SANTA MARINA SALINA',
      },
      {
        value: 'I311',
        label: 'SANTA TERESA o SANTA TERESA DI RIVA',
      },
      {
        value: 'I369',
        label: 'SANTO STEFANO DI BRIGA',
      },
      {
        value: 'I370',
        label: 'SANTO STEFANO DI CAMASTRA',
      },
      {
        value: 'I420',
        label: 'SAPONARA o SAPONARA VILLAFRANCA',
      },
      {
        value: 'I477',
        label: 'SAVOCA',
      },
      {
        value: 'I492',
        label: 'SCALETTA o SCALETTA ZANCLEA',
      },
      {
        value: 'I747',
        label: 'SINAGRA',
      },
      {
        value: 'I881',
        label: 'SPADAFORA',
      },
      {
        value: 'I882',
        label: 'SPADAFORA SAN MARTINO',
      },
      {
        value: 'I883',
        label: 'SPADAFORA SAN PIETRO',
      },
      {
        value: 'L042',
        label: 'TAORMINA',
      },
      {
        value: 'M210',
        label: 'TERME VIGLIATORE',
      },
      {
        value: 'L271',
        label: 'TORREGROTTA',
      },
      {
        value: 'M286',
        label: 'TORRENOVA',
      },
      {
        value: 'L308',
        label: 'TORTORICI',
      },
      {
        value: 'L431',
        label: 'TRIPI',
      },
      {
        value: 'L478',
        label: 'TUSA',
      },
      {
        value: 'L482',
        label: 'UCRIA',
      },
      {
        value: 'L561',
        label: 'VALDINA',
      },
      {
        value: 'L735',
        label: 'VENETICO',
      },
      {
        value: 'L950',
        label: 'VILLAFRANCA TIRRENA',
      },
    ],
  },

  {
    value: 'MI',
    label: 'Milano',
    children: [
      {
        value: 'A010',
        label: 'ABBIATEGRASSO',
      },
      {
        value: 'A063',
        label: 'AFFORI',
      },
      {
        value: 'A073',
        label: 'AGLIATE',
      },
      {
        value: 'A127',
        label: 'ALBAIRATE',
      },
      {
        value: 'A174',
        label: 'ALBUSCIAGO',
      },
      {
        value: 'A361',
        label: 'ARCAGNA',
      },
      {
        value: 'A362',
        label: 'ARCAGNANO',
      },
      {
        value: 'A375',
        label: 'ARCONATE',
      },
      {
        value: 'A389',
        label: 'ARESE',
      },
      {
        value: 'A413',
        label: 'ARLUNO',
      },
      {
        value: 'A423',
        label: 'ARNATE',
      },
      {
        value: 'A441',
        label: 'ARSAGO o ARSAGO SEPRIO',
      },
      {
        value: 'A473',
        label: 'ASSAGO',
      },
      {
        value: 'A545',
        label: 'BAGGIO',
      },
      {
        value: 'A602',
        label: 'BALSAMO',
      },
      {
        value: 'A618',
        label: 'BARANZATE',
      },
      {
        value: 'A620',
        label: 'BARATE',
      },
      {
        value: 'A623',
        label: 'BARBAIANA',
      },
      {
        value: 'A652',
        label: 'BAREGGIO',
      },
      {
        value: 'A659',
        label: 'BARGANO',
      },
      {
        value: 'A697',
        label: 'BASIANO',
      },
      {
        value: 'A699',
        label: 'BASIGLIO',
      },
      {
        value: 'A751',
        label: 'BELLINZAGO o BELLINZAGO LOMBARDO',
      },
      {
        value: 'A804',
        label: 'BERNATE o BERNATE TICINO',
      },
      {
        value: 'A820',
        label: 'BESATE',
      },
      {
        value: 'A829',
        label: 'BESTAZZO',
      },
      {
        value: 'A860',
        label: 'BIENATE',
      },
      {
        value: 'A872',
        label: 'BINASCO',
      },
      {
        value: 'A875',
        label: 'BINZAGO',
      },
      {
        value: 'A879',
        label: 'BIRAGO',
      },
      {
        value: 'A886',
        label: 'BISENTRATE',
      },
      {
        value: 'A920',
        label: 'BOFFALORA o BOFFALORA SOPRA TICINO',
      },
      {
        value: 'A934',
        label: 'BOLDINASCO',
      },
      {
        value: 'A938',
        label: 'BOLGIANO',
      },
      {
        value: 'A939',
        label: 'BOLLADELLO',
      },
      {
        value: 'A940',
        label: 'BOLLATE',
      },
      {
        value: 'A974',
        label: 'BONIROLA',
      },
      {
        value: 'B050',
        label: 'BORNAGO',
      },
      {
        value: 'B059',
        label: 'BORSANO',
      },
      {
        value: 'B090',
        label: 'BOTTEDO',
      },
      {
        value: 'B162',
        label: 'BRESSO',
      },
      {
        value: 'B170',
        label: 'BRIAVACCA',
      },
      {
        value: 'B231',
        label: 'BRUSUGLIO',
      },
      {
        value: 'B233',
        label: 'BRUZZANO o BRUZZANO DEI DUE BORGHI',
      },
      {
        value: 'B235',
        label: 'BUBBIANO',
      },
      {
        value: 'B240',
        label: 'BUCCINASCO',
      },
      {
        value: 'B286',
        label: 'BUSCATE',
      },
      {
        value: 'B292',
        label: 'BUSSERO',
      },
      {
        value: 'B299',
        label: 'BUSTIGHERA',
      },
      {
        value: 'B301',
        label: 'BUSTO GAROLFO',
      },
      {
        value: 'B331',
        label: "CA' DE' MAZZI",
      },
      {
        value: 'B341',
        label: "CA' DE' ZECCHI",
      },
      {
        value: 'B327',
        label: "CA' DEI BOLLI",
      },
      {
        value: 'B329',
        label: "CA' DELL'ACQUA",
      },
      {
        value: 'B363',
        label: 'CAIDATE',
      },
      {
        value: 'B372',
        label: 'CAJELLO',
      },
      {
        value: 'B420',
        label: "CALO'",
      },
      {
        value: 'B448',
        label: 'CALVIGNASCO',
      },
      {
        value: 'B461',
        label: 'CAMBIAGO',
      },
      {
        value: 'B548',
        label: 'CAMPOLUNGO',
      },
      {
        value: 'B593',
        label: 'CANEGRATE',
      },
      {
        value: 'B638',
        label: 'CANTONALE',
      },
      {
        value: 'B699',
        label: 'CAPRIANO o CAPRIANO DI BRIANZA',
      },
      {
        value: 'B805',
        label: 'CARONNO o CARONNO MILANESE o CARONNO PERTUSELLA',
      },
      {
        value: 'B820',
        label: 'CARPIANO',
      },
      {
        value: 'B850',
        label: 'CARUGATE',
      },
      {
        value: 'B875',
        label: 'CASALE o CASALE LITTA',
      },
      {
        value: 'B938',
        label: 'CASARILE o ZANAVASCO',
      },
      {
        value: 'B957',
        label: "CASELLE o CASELLE D'OZERO",
      },
      {
        value: 'B972',
        label: 'CASIRATE o CASIRATE OLONA',
      },
      {
        value: 'B981',
        label: 'CASOLATE',
      },
      {
        value: 'B986',
        label: 'CASONE o MENEDRAGO',
      },
      {
        value: 'B987',
        label: 'CASORATE o CASORATE SEMPIONE',
      },
      {
        value: 'B989',
        label: 'CASOREZZO',
      },
      {
        value: 'C003',
        label: "CASSANO D'ADDA",
      },
      {
        value: 'C008',
        label: 'CASSINA ALIPRANDI',
      },
      {
        value: 'C009',
        label: 'CASSINA AMATA',
      },
      {
        value: 'C010',
        label: 'CASSINA BARAGGIA',
      },
      {
        value: 'C012',
        label: "CASSINA DE' GATTI",
      },
      {
        value: 'C014',
        label: "CASSINA DE' PECCHI",
      },
      {
        value: 'C013',
        label: 'CASSINA DEL PERO o PERO',
      },
      {
        value: 'C015',
        label: 'CASSINA FERRARA',
      },
      {
        value: 'C017',
        label: 'CASSINA NUOVA',
      },
      {
        value: 'C018',
        label: 'CASSINA PERTUSELLA',
      },
      {
        value: 'C019',
        label: 'CASSINA POBBIA',
      },
      {
        value: 'C021',
        label: 'CASSINA SAVINA',
      },
      {
        value: 'C023',
        label: 'CASSINA TRIULZA',
      },
      {
        value: 'C025',
        label: 'CASSINA VERGHERA',
      },
      {
        value: 'C033',
        label: 'CASSINETTA DI LUGAGNANO o LUGAGNANO',
      },
      {
        value: 'C036',
        label: "CASSINO D'ALBERI",
      },
      {
        value: 'C042',
        label: 'CASTAGNATE OLONA',
      },
      {
        value: 'C052',
        label: 'CASTANO o CASTANO PRIMO',
      },
      {
        value: 'C054',
        label: 'CASTEGNATE o CASTEGNATE OLONA',
      },
      {
        value: 'C150',
        label: "CASTELLAZZO DE' BARZI",
      },
      {
        value: 'C159',
        label: 'CASTELLETTO MENDOSIO',
      },
      {
        value: 'C212',
        label: 'CASTELNOVATE',
      },
      {
        value: 'C328',
        label: 'CASTIRAGA DA REGGIO',
      },
      {
        value: 'C371',
        label: 'CAVAIONE',
      },
      {
        value: 'C382',
        label: 'CAVARIA ED UNITI o CAVARIA CON PREMEZZO o ORAGO',
      },
      {
        value: 'C399',
        label: 'CAVIAGA',
      },
      {
        value: 'C411',
        label: 'CAZZANO o CAZZANO BEFANA o CAZZANO BESANA',
      },
      {
        value: 'C419',
        label: 'CEDRATE',
      },
      {
        value: 'C477',
        label: 'CEPPEDA',
      },
      {
        value: 'C491',
        label: 'CERCHIATE',
      },
      {
        value: 'C523',
        label: 'CERNUSCO ASINARIO o CERNUSCO SUL NAVIGLIO',
      },
      {
        value: 'C536',
        label: 'CERRO o CERRO AL LAMBRO',
      },
      {
        value: 'C537',
        label: 'CERRO o CERRO MAGGIORE',
      },
      {
        value: 'C565',
        label: 'CESANO BOSCONE',
      },
      {
        value: 'C569',
        label: 'CESATE',
      },
      {
        value: 'C617',
        label: 'CHIARAVALLE o CHIARAVALLE MILANESE',
      },
      {
        value: 'C642',
        label: "CHIOSI D'ADDA VIGADORE",
      },
      {
        value: 'C643',
        label: "CHIOSI DI PORTA D'ADDA",
      },
      {
        value: 'C644',
        label: 'CHIOSI PORTA CREMONESE',
      },
      {
        value: 'C645',
        label: 'CHIOSI PORTA REGALE',
      },
      {
        value: 'C646',
        label: 'CHIOSI UNITI CON BOTTEDO',
      },
      {
        value: 'C693',
        label: 'CIMBRO',
      },
      {
        value: 'C706',
        label: 'CINISELLO',
      },
      {
        value: 'C707',
        label: 'CINISELLO BALSAMO',
      },
      {
        value: 'C733',
        label: 'CISLIANO',
      },
      {
        value: 'C802',
        label: 'COAZZANO',
      },
      {
        value: 'C887',
        label: 'COLNAGO',
      },
      {
        value: 'C895',
        label: 'COLOGNO o COLOGNO MONZESE',
      },
      {
        value: 'C908',
        label: 'COLTURANO',
      },
      {
        value: 'C909',
        label: 'COLZANO',
      },
      {
        value: 'C947',
        label: 'CONCESA',
      },
      {
        value: 'C981',
        label: 'COPRENO',
      },
      {
        value: 'C986',
        label: 'CORBETTA',
      },
      {
        value: 'D001',
        label: 'CORGENO',
      },
      {
        value: 'D013',
        label: 'CORMANO',
      },
      {
        value: 'D018',
        label: 'CORNAREDO',
      },
      {
        value: 'D023',
        label: 'CORNELIANO o CORNELIANO BERTARIO',
      },
      {
        value: 'D033',
        label: 'CORONATE o MORIMONDO',
      },
      {
        value: 'D035',
        label: 'CORPI SANTI DI MILANO',
      },
      {
        value: 'D045',
        label: 'CORSICO',
      },
      {
        value: 'D070',
        label: "CORTE SANT'ANDREA",
      },
      {
        value: 'D106',
        label: 'COSTA AL LAMBRO o COSTA LOMBARDA',
      },
      {
        value: 'D155',
        label: 'CRESCENZAGO',
      },
      {
        value: 'D190',
        label: 'CRUGNOLA',
      },
      {
        value: 'D198',
        label: 'CUGGIONO',
      },
      {
        value: 'D229',
        label: 'CUSAGO',
      },
      {
        value: 'D231',
        label: 'CUSANO o CUSANO MILANINO o CUSANO SUL SEVESO',
      },
      {
        value: 'D240',
        label: 'CUVIRONE',
      },
      {
        value: 'D244',
        label: 'DAIRAGO',
      },
      {
        value: 'D275',
        label: 'DERGANO',
      },
      {
        value: 'D367',
        label: 'DRESANO',
      },
      {
        value: 'D381',
        label: 'DUGNANO',
      },
      {
        value: 'D466',
        label: 'FAGNANO o FAGNANO SUL NAVIGLIO',
      },
      {
        value: 'D467',
        label: 'FAGNANO o FAGNANO OLONA',
      },
      {
        value: 'D580',
        label: 'FIGINO o FIGINO DI MILANO',
      },
      {
        value: 'D822',
        label: 'FURATO',
      },
      {
        value: 'D845',
        label: 'GAGGIANO',
      },
      {
        value: 'D912',
        label: 'GARBAGNATE o GARBAGNATE MILANESE',
      },
      {
        value: 'D914',
        label: 'GARBATOLA',
      },
      {
        value: 'D919',
        label: 'GAREGNANO',
      },
      {
        value: 'D936',
        label: 'GATTERA MAIOCCA',
      },
      {
        value: 'D995',
        label: 'GESSATE',
      },
      {
        value: 'E094',
        label: 'GORGONZOLA',
      },
      {
        value: 'E099',
        label: 'GORLA o GORLA PRECOTTO o GORLA PRIMO',
      },
      {
        value: 'E137',
        label: 'GRANCINO',
      },
      {
        value: 'E157',
        label: 'GRAZZANELLO',
      },
      {
        value: 'E162',
        label: 'GRECO MILANESE o SEGNANO',
      },
      {
        value: 'E170',
        label: 'GREZZAGO',
      },
      {
        value: 'E197',
        label: "GROPPELLO o GROPPELLO D'ADDA",
      },
      {
        value: 'E254',
        label: 'GUAZZINA',
      },
      {
        value: 'E258',
        label: 'GUDO VISCONTI',
      },
      {
        value: 'E260',
        label: 'GUGNANO',
      },
      {
        value: 'E294',
        label: 'INCIRANO',
      },
      {
        value: 'E300',
        label: 'INDUNO o INDUNO TICINO',
      },
      {
        value: 'E313',
        label: 'INVERUNO',
      },
      {
        value: 'E317',
        label: 'INZAGO',
      },
      {
        value: 'E344',
        label: 'ISOLA BALBA',
      },
      {
        value: 'E383',
        label: 'JERAGO',
      },
      {
        value: 'E384',
        label: 'JERAGO CON BESNATE',
      },
      {
        value: 'E385',
        label: 'JERAGO CON BESNATE ED ORAGO',
      },
      {
        value: 'E395',
        label: 'LACCHIARELLA',
      },
      {
        value: 'E415',
        label: 'LAINATE',
      },
      {
        value: 'E427',
        label: 'LAMBRATE',
      },
      {
        value: 'E453',
        label: 'LARDERA',
      },
      {
        value: 'E514',
        label: 'LEGNANO',
      },
      {
        value: 'E595',
        label: 'LIMITO',
      },
      {
        value: 'E601',
        label: 'LINATE AL LAMBRO o MEZZATE',
      },
      {
        value: 'E610',
        label: 'LISCATE',
      },
      {
        value: 'E639',
        label: 'LOCATE o LOCATE DI TRIULZI',
      },
      {
        value: 'E717',
        label: 'LUCERNATE',
      },
      {
        value: 'E801',
        label: 'MAGENTA',
      },
      {
        value: 'E819',
        label: 'MAGNAGO',
      },
      {
        value: 'E920',
        label: 'MARCALLO',
      },
      {
        value: 'E921',
        label: 'MARCALLO CON CASONE',
      },
      {
        value: 'F003',
        label: 'MASATE',
      },
      {
        value: 'F008',
        label: 'MASCIAGO o MASCIAGO MILANESE',
      },
      {
        value: 'F069',
        label: 'MAZZO o MAZZO MILANESE',
      },
      {
        value: 'F084',
        label: 'MEDIGLIA',
      },
      {
        value: 'F099',
        label: 'MELEGNANELLO',
      },
      {
        value: 'F100',
        label: 'MELEGNANO',
      },
      {
        value: 'F119',
        label: 'MELZO',
      },
      {
        value: 'F129',
        label: 'MENZAGO',
      },
      {
        value: 'F142',
        label: 'MERCUGNANO',
      },
      {
        value: 'F155',
        label: 'MESERO',
      },
      {
        value: 'F163',
        label: 'METTONE',
      },
      {
        value: 'F166',
        label: 'MEZZANA o MEZZANA CASATI',
      },
      {
        value: 'F179',
        label: 'MEZZANO PASSONE',
      },
      {
        value: 'F204',
        label: 'MIGNETE o MIGNETTES',
      },
      {
        value: 'F205',
        label: 'MILANO',
      },
      {
        value: 'F236',
        label: 'MIRABELLO o MIRABELLO SAN BERNARDINO',
      },
      {
        value: 'F344',
        label: 'MONCUCCO o MONCUCCO VECCHIO',
      },
      {
        value: 'F345',
        label: 'MONCUCCO o MONCUCCO DI MONZA',
      },
      {
        value: 'F366',
        label: 'MONGIARDINO o MONGIARDINO SILLARO',
      },
      {
        value: 'F435',
        label: 'MONTE o MONTESIRO',
      },
      {
        value: 'F575',
        label: 'MONTENATE',
      },
      {
        value: 'F752',
        label: 'MORSENCHIO',
      },
      {
        value: 'F782',
        label: 'MOTTA VIGANA',
      },
      {
        value: 'F783',
        label: 'MOTTA VISCONTI',
      },
      {
        value: 'F796',
        label: 'MUGGIANO',
      },
      {
        value: 'F827',
        label: 'MUSOCCO',
      },
      {
        value: 'F874',
        label: 'NERVIANO',
      },
      {
        value: 'F897',
        label: 'NIGUARDA',
      },
      {
        value: 'F939',
        label: 'NOSATE',
      },
      {
        value: 'F940',
        label: 'NOSEDO',
      },
      {
        value: 'F955',
        label: 'NOVATE o NOVATE MILANESE',
      },
      {
        value: 'F959',
        label: 'NOVEGRO',
      },
      {
        value: 'F968',
        label: 'NOVIGLIO',
      },
      {
        value: 'G059',
        label: 'OMATE',
      },
      {
        value: 'G078',
        label: 'OPERA',
      },
      {
        value: 'G092',
        label: 'ORENO',
      },
      {
        value: 'G101',
        label: 'ORIANO o ORIANO SOPRA TICINO',
      },
      {
        value: 'G181',
        label: 'OSSONA',
      },
      {
        value: 'G206',
        label: 'OZZERO',
      },
      {
        value: 'G220',
        label: 'PADERNO o PADERNO DUGNANO o PADERNO MILANESE',
      },
      {
        value: 'G244',
        label: 'PAINA',
      },
      {
        value: 'G265',
        label: 'PALAZZOLO o PALAZZOLO MILANESE',
      },
      {
        value: 'G316',
        label: 'PANTIGLIATE',
      },
      {
        value: 'G324',
        label: 'PARABIAGO',
      },
      {
        value: 'G360',
        label: 'PASSIRANA o PASSIRANA MILANESE',
      },
      {
        value: 'G366',
        label: 'PASTURAGO',
      },
      {
        value: 'G385',
        label: 'PAULLO',
      },
      {
        value: 'G413',
        label: 'PEDRIANO',
      },
      {
        value: 'G488',
        label: 'PESCHIERA o PESCHIERA BORROMEO',
      },
      {
        value: 'G501',
        label: 'PESSANO',
      },
      {
        value: 'G502',
        label: 'PESSANO CON BORNAGO',
      },
      {
        value: 'G527',
        label: 'PEVERANZA',
      },
      {
        value: 'G530',
        label: "PEZZOLO DE' CODAZZI",
      },
      {
        value: 'G531',
        label: 'PEZZOLO DI TAVAZZANO',
      },
      {
        value: 'G634',
        label: 'PIEVE o PIEVE EMANUELE',
      },
      {
        value: 'G679',
        label: 'PINZANO',
      },
      {
        value: 'G686',
        label: 'PIOLTELLO',
      },
      {
        value: 'G725',
        label: 'PIZZOLANO',
      },
      {
        value: 'G772',
        label: 'POGLIANO o POGLIANO MILANESE',
      },
      {
        value: 'G857',
        label: 'PONTESESTO',
      },
      {
        value: 'G908',
        label: "PORTO o PORTO D'ADDA",
      },
      {
        value: 'G955',
        label: "POZZO o POZZO D'ADDA",
      },
      {
        value: 'G965',
        label: 'POZZUOLO o POZZUOLO MARTESANA',
      },
      {
        value: 'H016',
        label: 'PRECOTTO',
      },
      {
        value: 'H026',
        label: 'PREGNANA o PREGNANA MILANESE',
      },
      {
        value: 'H031',
        label: 'PREMENUGO',
      },
      {
        value: 'H032',
        label: 'PREMEZZO',
      },
      {
        value: 'H075',
        label: 'PROSPIANO',
      },
      {
        value: 'H112',
        label: 'QUARTIANO',
      },
      {
        value: 'H116',
        label: 'QUARTO CAGNINO',
      },
      {
        value: 'H137',
        label: 'QUINTO ROMANO',
      },
      {
        value: 'H138',
        label: 'QUINTOSOLE',
      },
      {
        value: 'H141',
        label: 'QUINZANO o QUINZANO SAN PIETRO',
      },
      {
        value: 'H226',
        label: 'REGINA FITTAREZZA',
      },
      {
        value: 'H234',
        label: 'RENATE VEDUGGIO',
      },
      {
        value: 'H239',
        label: 'RESCALDA',
      },
      {
        value: 'H240',
        label: 'RESCALDINA',
      },
      {
        value: 'H264',
        label: 'RHO',
      },
      {
        value: 'H309',
        label: 'RIOZZO',
      },
      {
        value: 'H368',
        label: 'ROBBIANO',
      },
      {
        value: 'H370',
        label: 'ROBECCHETTO',
      },
      {
        value: 'H371',
        label: 'ROBECCHETTO CON INDUNO',
      },
      {
        value: 'H373',
        label: 'ROBECCO o ROBECCO SUL NAVIGLIO',
      },
      {
        value: 'H374',
        label: 'ROBECCO o ROBECCO LODIGIANO',
      },
      {
        value: 'H470',
        label: 'RODANO',
      },
      {
        value: 'H530',
        label: 'RONCHETTO',
      },
      {
        value: 'H560',
        label: 'ROSATE',
      },
      {
        value: 'H567',
        label: 'ROSERIO',
      },
      {
        value: 'H595',
        label: 'ROVAGNASCO',
      },
      {
        value: 'H623',
        label: 'ROZZANO',
      },
      {
        value: 'H636',
        label: 'RUGINELLO',
      },
      {
        value: 'H803',
        label: 'SAN COLOMBANO o SAN COLOMBANO AL LAMBRO',
      },
      {
        value: 'H813',
        label: 'SAN DAMIANO o SAN DAMIANO DI MONZA',
      },
      {
        value: 'H827',
        label: 'SAN DONATO o SAN DONATO MILANESE',
      },
      {
        value: 'H884',
        label: 'SAN GIORGIO o SAN GIORGIO SU LEGNANO',
      },
      {
        value: 'H927',
        label: 'SAN GIULIANO',
      },
      {
        value: 'H930',
        label: 'SAN GIULIANO MILANESE o VIBOLDONE',
      },
      {
        value: 'I064',
        label: 'SAN NOVO',
      },
      {
        value: 'I067',
        label: 'SAN PANCRAZIO o SAN PANCRAZIO AL COLLE',
      },
      {
        value: 'I077',
        label: 'SAN PEDRINO',
      },
      {
        value: 'I097',
        label: 'SAN PIETRO BESTAZZO',
      },
      {
        value: 'I099',
        label: 'SAN PIETRO CUSICO',
      },
      {
        value: 'I397',
        label: 'SAN VITO o SAN VITO E MARTA',
      },
      {
        value: 'I409',
        label: 'SAN VITTORE o SAN VITTORE OLONA',
      },
      {
        value: 'I415',
        label: 'SAN ZENONE o SAN ZENONE AL LAMBRO',
      },
      {
        value: 'I194',
        label: "SANT'AGATA o SANT'AGATA MARTESANA",
      },
      {
        value: 'I295',
        label: "SANT'ANTONINO o SANT'ANTONINO TICINO",
      },
      {
        value: 'I245',
        label: 'SANTA MARIA IN PRATO',
      },
      {
        value: 'I361',
        label: 'SANTO STEFANO o SANTO STEFANO TICINO',
      },
      {
        value: 'I566',
        label: 'SEDRIANO',
      },
      {
        value: 'I577',
        label: 'SEGRATE',
      },
      {
        value: 'I586',
        label: 'SELLANUOVA',
      },
      {
        value: 'I602',
        label: 'SENAGO',
      },
      {
        value: 'I675',
        label: 'SESONA',
      },
      {
        value: 'I685',
        label: 'SESTO o SESTO PERGOLA',
      },
      {
        value: 'I690',
        label: 'SESTO SAN GIOVANNI',
      },
      {
        value: 'I691',
        label: 'SESTO ULTERIANO',
      },
      {
        value: 'I696',
        label: 'SETTALA',
      },
      {
        value: 'I700',
        label: 'SETTIMO o SETTIMO MILANESE',
      },
      {
        value: 'I786',
        label: 'SOLARO',
      },
      {
        value: 'I793',
        label: "SOLBIATE SULL'ARNO o SOLBIATE ARNO o SOLBIATE ARNO E MONTE",
      },
      {
        value: 'I810',
        label: 'SOLTARICO',
      },
      {
        value: 'I819',
        label: 'SOMMA o SOMMA LOMBARDO',
      },
      {
        value: 'I999',
        label: 'SULBIATE INFERIORE',
      },
      {
        value: 'L001',
        label: 'SULBIATE SUPERIORE',
      },
      {
        value: 'L031',
        label: 'TAINATE',
      },
      {
        value: 'L068',
        label: 'TAVAZZANO',
      },
      {
        value: 'L135',
        label: 'TERRAZZANO',
      },
      {
        value: 'L222',
        label: 'TORMO',
      },
      {
        value: 'L226',
        label: 'TORNAVENTO',
      },
      {
        value: 'L358',
        label: 'TRECELLA',
      },
      {
        value: 'L362',
        label: 'TREGASIO',
      },
      {
        value: 'L373',
        label: 'TRENNO',
      },
      {
        value: 'L408',
        label: 'TREZZANO o TREZZANO ROSA',
      },
      {
        value: 'L409',
        label: 'TREZZANO o TREZZANO SUL NAVIGLIO',
      },
      {
        value: 'L411',
        label: "TREZZO o TREZZO SULL'ADDA",
      },
      {
        value: 'L415',
        label: 'TRIBIANO',
      },
      {
        value: 'L441',
        label: 'TRIVULZA',
      },
      {
        value: 'L442',
        label: 'TRIVULZINA',
      },
      {
        value: 'L454',
        label: 'TRUCCAZZANO',
      },
      {
        value: 'L471',
        label: 'TURBIGO',
      },
      {
        value: 'L476',
        label: 'TURRO o TURRO MILANESE',
      },
      {
        value: 'L510',
        label: 'USMATE',
      },
      {
        value: 'L536',
        label: 'VAIANO o VAIANO VALLE',
      },
      {
        value: 'L592',
        label: 'VALLE o VALLE GUIDINO',
      },
      {
        value: 'L664',
        label: 'VANZAGHELLO',
      },
      {
        value: 'L665',
        label: 'VANZAGO',
      },
      {
        value: 'L667',
        label: "VAPRIO o VAPRIO D'ADDA",
      },
      {
        value: 'L708',
        label: 'VEDUGGIO',
      },
      {
        value: 'L766',
        label: 'VERGO',
      },
      {
        value: 'L768',
        label: 'VERMEZZO',
      },
      {
        value: 'M424',
        label: 'VERMEZZO CON ZELO',
      },
      {
        value: 'L773',
        label: 'VERNATE',
      },
      {
        value: 'L852',
        label: 'VIDARDO',
      },
      {
        value: 'L861',
        label: 'VIGADORE',
      },
      {
        value: 'L867',
        label: 'VIGANO o VIGANO CERTOSINO',
      },
      {
        value: 'L871',
        label: 'VIGENTINO',
      },
      {
        value: 'L883',
        label: 'VIGNATE',
      },
      {
        value: 'L901',
        label: 'VIGONZINO',
      },
      {
        value: 'L928',
        label: 'VILLA CORTESE',
      },
      {
        value: 'L940',
        label: 'VILLA DOSIA',
      },
      {
        value: 'L996',
        label: 'VILLA PIZZONE',
      },
      {
        value: 'L997',
        label: 'VILLA POMPEIANA',
      },
      {
        value: 'M001',
        label: 'VILLA RAVERIO',
      },
      {
        value: 'M012',
        label: 'VILLA ROSSA',
      },
      {
        value: 'L976',
        label: 'VILLANOVA o VILLANOVA VIMERCATE',
      },
      {
        value: 'M053',
        label: 'VIMODRONE',
      },
      {
        value: 'M056',
        label: 'VINAGO',
      },
      {
        value: 'M087',
        label: 'VITTADONE',
      },
      {
        value: 'M091',
        label: 'VITTUONE',
      },
      {
        value: 'M101',
        label: 'VIZZOLA o VIZZOLA TICINO',
      },
      {
        value: 'M102',
        label: 'VIZZOLO o VIZZOLO PREDABISSI',
      },
      {
        value: 'M159',
        label: 'ZELO FORAMAGNO',
      },
      {
        value: 'M160',
        label: 'ZELO SURRIGONE',
      },
      {
        value: 'M176',
        label: 'ZIBIDO SAN GIACOMO',
      },
      {
        value: 'M181',
        label: 'ZIVIDO',
      },
      {
        value: 'M191',
        label: 'ZORLESCO',
      },
      {
        value: 'M205',
        label: 'ZUNICO',
      },
    ],
  },

  {
    value: 'MN',
    label: 'Mantova',
    children: [
      {
        value: 'A470',
        label: 'ASOLA',
      },
      {
        value: 'A575',
        label: 'BAGNOLO SAN VITO',
      },
      {
        value: 'A866',
        label: 'BIGARELLO',
      },
      {
        value: 'M396',
        label: 'BORGO MANTOVANO',
      },
      {
        value: 'M340',
        label: 'BORGO VIRGILIO',
      },
      {
        value: 'M406',
        label: 'BORGOCARBONARA',
      },
      {
        value: 'B011',
        label: 'BORGOFORTE',
      },
      {
        value: 'B012',
        label: 'BORGOFORTE A DESTRA o MOTTEGGIANA',
      },
      {
        value: 'B013',
        label: 'BORGOFRANCO o BORGOFRANCO SUL PO',
      },
      {
        value: 'B110',
        label: 'BOZZOLO',
      },
      {
        value: 'B739',
        label: 'CARBONARA o CARBONARA DI PO',
      },
      {
        value: 'B901',
        label: 'CASALMORO',
      },
      {
        value: 'B907',
        label: 'CASALOLDO',
      },
      {
        value: 'B909',
        label: 'CASALPOGLIO',
      },
      {
        value: 'B911',
        label: 'CASALROMANO',
      },
      {
        value: 'C076',
        label: "CASTEL D'ARIO o CASTELLARO",
      },
      {
        value: 'C118',
        label: 'CASTEL GOFFREDO',
      },
      {
        value: 'C059',
        label: 'CASTELBELFORTE',
      },
      {
        value: 'C195',
        label: 'CASTELLUCCHIO',
      },
      {
        value: 'C312',
        label: 'CASTIGLIONE DELLE STIVIERE',
      },
      {
        value: 'C406',
        label: 'CAVRIANA',
      },
      {
        value: 'C502',
        label: 'CERESARA',
      },
      {
        value: 'C930',
        label: 'COMMESSAGGIO',
      },
      {
        value: 'D227',
        label: 'CURTATONE',
      },
      {
        value: 'D351',
        label: 'DOSOLO',
      },
      {
        value: 'D529',
        label: 'FELONICA',
      },
      {
        value: 'D949',
        label: 'GAZOLDO DEGLI IPPOLITI o GAZZOLDO',
      },
      {
        value: 'D959',
        label: 'GAZZUOLO',
      },
      {
        value: 'E078',
        label: 'GOITO',
      },
      {
        value: 'E089',
        label: 'GONZAGA',
      },
      {
        value: 'E261',
        label: 'GUIDIZZOLO',
      },
      {
        value: 'E818',
        label: 'MAGNACAVALLO',
      },
      {
        value: 'E897',
        label: 'MANTOVA',
      },
      {
        value: 'E922',
        label: 'MARCARIA',
      },
      {
        value: 'E949',
        label: 'MARIANA o MARIANA MANTOVANA',
      },
      {
        value: 'E962',
        label: 'MARMIROLO',
      },
      {
        value: 'F086',
        label: 'MEDOLE',
      },
      {
        value: 'F267',
        label: 'MOGLIA',
      },
      {
        value: 'F705',
        label: 'MONZAMBANO',
      },
      {
        value: 'F804',
        label: 'MULO o VILLA POMA',
      },
      {
        value: 'G186',
        label: 'OSTIGLIA',
      },
      {
        value: 'G417',
        label: 'PEGOGNAGA',
      },
      {
        value: 'G633',
        label: 'PIEVE o PIEVE DI CORIANO',
      },
      {
        value: 'G717',
        label: 'PIUBEGA',
      },
      {
        value: 'G753',
        label: 'POGGIO o POGGIO RUSCO',
      },
      {
        value: 'G816',
        label: 'POMPONESCO',
      },
      {
        value: 'G862',
        label: 'PONTI SUL MINCIO o PONTI',
      },
      {
        value: 'G917',
        label: 'PORTO MANTOVANO',
      },
      {
        value: 'H123',
        label: 'QUATTROVILLE o VIRGILIO',
      },
      {
        value: 'H129',
        label: 'QUINGENTOLE',
      },
      {
        value: 'H143',
        label: 'QUISTELLO',
      },
      {
        value: 'H218',
        label: 'REDONDESCO',
      },
      {
        value: 'H248',
        label: 'REVERE',
      },
      {
        value: 'H342',
        label: 'RIVAROLO FUORI o RIVAROLO MANTOVANO',
      },
      {
        value: 'H481',
        label: 'RODIGO',
      },
      {
        value: 'H541',
        label: 'RONCOFERRARO',
      },
      {
        value: 'H604',
        label: 'ROVERBELLA',
      },
      {
        value: 'H652',
        label: 'SABBIONETA',
      },
      {
        value: 'H771',
        label: 'SAN BENEDETTO o SAN BENEDETTO PO',
      },
      {
        value: 'H870',
        label: 'SAN GIACOMO DELLE SEGNATE',
      },
      {
        value: 'H883',
        label: 'SAN GIORGIO o SAN GIORGIO BIGARELLO o SAN GIORGIO DI MANTOVA',
      },
      {
        value: 'H912',
        label: 'SAN GIOVANNI DEL DOSSO',
      },
      {
        value: 'I005',
        label: "SAN MARTINO DALL'ARGINE",
      },
      {
        value: 'I532',
        label: 'SCHIVENOGLIA',
      },
      {
        value: 'I632',
        label: 'SERMIDE o SERMIDE E FELONICA',
      },
      {
        value: 'I662',
        label: 'SERRAVALLE o SERRAVALLE A PO',
      },
      {
        value: 'I801',
        label: 'SOLFERINO',
      },
      {
        value: 'L015',
        label: 'SUSTINENTE',
      },
      {
        value: 'L020',
        label: 'SUZZARA',
      },
      {
        value: 'L826',
        label: 'VIADANA',
      },
      {
        value: 'M044',
        label: 'VILLIMPENTA',
      },
      {
        value: 'M125',
        label: 'VOLTA MANTOVANA',
      },
    ],
  },

  {
    value: 'MO',
    label: 'Modena',
    children: [
      {
        value: 'A713',
        label: 'BASTIGLIA',
      },
      {
        value: 'A959',
        label: 'BOMPORTO',
      },
      {
        value: 'B539',
        label: 'CAMPOGALLIANO',
      },
      {
        value: 'B566',
        label: 'CAMPOSANTO',
      },
      {
        value: 'B819',
        label: 'CARPI',
      },
      {
        value: 'C242',
        label: 'CASTELNUOVO RANGONE',
      },
      {
        value: 'C287',
        label: 'CASTELVETRO o CASTELVETRO DI MODENA',
      },
      {
        value: 'C398',
        label: 'CAVEZZO',
      },
      {
        value: 'C951',
        label: 'CONCORDIA SULLA SECCHIA',
      },
      {
        value: 'D486',
        label: 'FANANO',
      },
      {
        value: 'D599',
        label: "FINALE o FINALE EMILIA o FINALE NELL'EMILIA",
      },
      {
        value: 'D607',
        label: 'FIORANO o FIORANO MODENESE',
      },
      {
        value: 'D617',
        label: 'FIUMALBO',
      },
      {
        value: 'D711',
        label: 'FORMIGINE',
      },
      {
        value: 'D783',
        label: 'FRASSINORO',
      },
      {
        value: 'E264',
        label: 'GUIGLIA',
      },
      {
        value: 'E426',
        label: 'LAMA MOCOGNO',
      },
      {
        value: 'E904',
        label: 'MARANELLO',
      },
      {
        value: 'E905',
        label: 'MARANO o MARANO SUL PANARO',
      },
      {
        value: 'F087',
        label: 'MEDOLLA',
      },
      {
        value: 'F240',
        label: 'MIRANDOLA',
      },
      {
        value: 'F257',
        label: 'MODENA',
      },
      {
        value: 'F357',
        label:
          'MONFESTINO IN SERRA MAZZONI o MONFESTINO IN SERRAMAZZONI o SERRAMAZZONI',
      },
      {
        value: 'F484',
        label: 'MONTECRETO',
      },
      {
        value: 'F503',
        label: 'MONTEFIORINO',
      },
      {
        value: 'F642',
        label: 'MONTESE',
      },
      {
        value: 'F930',
        label: 'NONANTOLA',
      },
      {
        value: 'F966',
        label: 'NOVI o NOVI DI MODENA',
      },
      {
        value: 'G250',
        label: 'PALAGANO',
      },
      {
        value: 'G393',
        label: 'PAVULLO o PAVULLO NEL FRIGNANO',
      },
      {
        value: 'G649',
        label: 'PIEVEPELAGO',
      },
      {
        value: 'G789',
        label: 'POLINAGO',
      },
      {
        value: 'H061',
        label: 'PRIGNANO o PRIGNANO SULLA SECCHIA',
      },
      {
        value: 'H195',
        label: 'RAVARINO',
      },
      {
        value: 'H303',
        label: 'RIOLUNATO',
      },
      {
        value: 'H794',
        label: 'SAN CESARIO o SAN CESARIO SUL PANARO',
      },
      {
        value: 'H835',
        label: 'SAN FELICE o SAN FELICE SUL PANARO',
      },
      {
        value: 'I128',
        label: 'SAN POSSIDONIO',
      },
      {
        value: 'I133',
        label: 'SAN PROSPERO',
      },
      {
        value: 'I462',
        label: 'SASSUOLO',
      },
      {
        value: 'I473',
        label: 'SAVIGNANO o SAVIGNANO SUL PANARO',
      },
      {
        value: 'I689',
        label: 'SESTOLA',
      },
      {
        value: 'I802',
        label: 'SOLIERA',
      },
      {
        value: 'I903',
        label: 'SPILAMBERTO',
      },
      {
        value: 'L885',
        label: 'VIGNOLA',
      },
      {
        value: 'M183',
        label: 'ZOCCA',
      },
    ],
  },

  {
    value: 'MS',
    label: 'Massa-Carrara',
    children: [
      {
        value: 'A156',
        label: 'ALBIANO o ALBIANO MAGRA',
      },
      {
        value: 'A342',
        label: 'APUANIA',
      },
      {
        value: 'A496',
        label: 'AULLA',
      },
      {
        value: 'A576',
        label: 'BAGNONE',
      },
      {
        value: 'B409',
        label: 'CALICE o CALICE OSSOLANO o CALICE LIGURE',
      },
      {
        value: 'B832',
        label: 'CARRARA',
      },
      {
        value: 'B979',
        label: 'CASOLA o CASOLA IN LUNIGIANA',
      },
      {
        value: 'C914',
        label: 'COMANO',
      },
      {
        value: 'D590',
        label: 'FILATTIERA',
      },
      {
        value: 'D629',
        label: 'FIVIZZANO',
      },
      {
        value: 'D735',
        label: 'FOSDINOVO',
      },
      {
        value: 'E574',
        label: 'LICCIANA o LICCIANA NARDI',
      },
      {
        value: 'F023',
        label: 'MASSA o MASSA-CARRARA',
      },
      {
        value: 'F679',
        label: 'MONTIGNOSO',
      },
      {
        value: 'F802',
        label: 'MULAZZO',
      },
      {
        value: 'G746',
        label: 'PODENZANA',
      },
      {
        value: 'G870',
        label: 'PONTREMOLI',
      },
      {
        value: 'H461',
        label: 'ROCCHETTA o ROCCHETTA DI VARA',
      },
      {
        value: 'L129',
        label: 'TERRAROSSA',
      },
      {
        value: 'L386',
        label: 'TRESANA',
      },
      {
        value: 'L946',
        label: 'VILLAFRANCA o VILLAFRANCA IN LUNIGIANA',
      },
      {
        value: 'M169',
        label: 'ZERI',
      },
    ],
  },

  {
    value: 'MT',
    label: 'Matera',
    children: [
      {
        value: 'A017',
        label: 'ACCETTURA',
      },
      {
        value: 'A196',
        label: 'ALIANO',
      },
      {
        value: 'A801',
        label: 'BERNALDA',
      },
      {
        value: 'A942',
        label: 'BOLLITA o NOVA SIRI',
      },
      {
        value: 'B390',
        label: 'CALCIANO o CALCI',
      },
      {
        value: 'B391',
        label: 'CALCIANO o CALCINAIA',
      },
      {
        value: 'C723',
        label: 'CIRIGLIANO',
      },
      {
        value: 'C888',
        label: 'COLOBRARO',
      },
      {
        value: 'D128',
        label: 'CRACO',
      },
      {
        value: 'D547',
        label: 'FERRANDINA',
      },
      {
        value: 'D909',
        label: 'GARAGUSO',
      },
      {
        value: 'D971',
        label: 'GENZANO o GENZANO DI LUCANIA',
      },
      {
        value: 'E093',
        label: 'GORGOGLIONE',
      },
      {
        value: 'E147',
        label: 'GRASSANO',
      },
      {
        value: 'E213',
        label: 'GROTTOLE',
      },
      {
        value: 'E326',
        label: 'IRSINA o MONTEPELOSO',
      },
      {
        value: 'F052',
        label: 'MATERA',
      },
      {
        value: 'F201',
        label: 'MIGLIONICO',
      },
      {
        value: 'F399',
        label: 'MONTALBANO o MONTALBANO JONICO',
      },
      {
        value: 'F637',
        label: 'MONTESCAGLIOSO',
      },
      {
        value: 'G037',
        label: 'OLIVETO o OLIVETO LUCANO',
      },
      {
        value: 'G712',
        label: 'PISTICCI',
      },
      {
        value: 'G786',
        label: 'POLICORO',
      },
      {
        value: 'G806',
        label: 'POMARICO',
      },
      {
        value: 'H591',
        label: 'ROTONDELLA',
      },
      {
        value: 'H687',
        label: 'SALANDRA',
      },
      {
        value: 'H888',
        label: 'SAN GIORGIO LUCANO o SAN GIORGIO',
      },
      {
        value: 'I029',
        label: 'SAN MAURO FORTE o SAN MAURO',
      },
      {
        value: 'M256',
        label: 'SCANZANO JONICO',
      },
      {
        value: 'I954',
        label: 'STIGLIANO',
      },
      {
        value: 'L418',
        label: 'TRICARICO',
      },
      {
        value: 'L477',
        label: 'TURSI',
      },
      {
        value: 'D513',
        label: 'VALSINNI o FAVALE o FAVALE SAN CATULDO',
      },
    ],
  },

  {
    value: 'NA',
    label: 'Napoli',
    children: [
      {
        value: 'A024',
        label: 'ACERRA',
      },
      {
        value: 'A064',
        label: 'AFRAGOLA',
      },
      {
        value: 'A068',
        label: 'AGEROLA',
      },
      {
        value: 'A268',
        label: 'ANACAPRI',
      },
      {
        value: 'A455',
        label: 'ARZANO',
      },
      {
        value: 'A535',
        label: 'BACOLI',
      },
      {
        value: 'A617',
        label: "BARANO o BARANO D'ISCHIA",
      },
      {
        value: 'A675',
        label: 'BARRA',
      },
      {
        value: 'B076',
        label: 'BOSCOREALE',
      },
      {
        value: 'B077',
        label: 'BOSCOTRECASE',
      },
      {
        value: 'B227',
        label: 'BRUSCIANO',
      },
      {
        value: 'B371',
        label: 'CAIVANO',
      },
      {
        value: 'B452',
        label: 'CALVIZZANO',
      },
      {
        value: 'B565',
        label: 'CAMPOSANO',
      },
      {
        value: 'B696',
        label: 'CAPRI',
      },
      {
        value: 'B740',
        label: 'CARBONARA o CARBONARA DI NOLA',
      },
      {
        value: 'B759',
        label: 'CARDITO',
      },
      {
        value: 'B905',
        label: 'CASALNUOVO o CASALNUOVO DI NAPOLI',
      },
      {
        value: 'B922',
        label: 'CASAMARCIANO',
      },
      {
        value: 'B924',
        label: 'CASAMICCIOLA o CASAMICCIOLA TERME',
      },
      {
        value: 'B925',
        label: 'CASANDRINO',
      },
      {
        value: 'B946',
        label: 'CASAVATORE',
      },
      {
        value: 'B980',
        label: 'CASOLA o CASOLA DI NAPOLI',
      },
      {
        value: 'B990',
        label: 'CASORIA',
      },
      {
        value: 'C129',
        label: 'CASTELLAMARE o CASTELLAMMARE DI STABIA',
      },
      {
        value: 'C188',
        label: 'CASTELLO DI CISTERNA o CISTERNA',
      },
      {
        value: 'C495',
        label: 'CERCOLA o MASSA DI SOMMA',
      },
      {
        value: 'C603',
        label: 'CHIAIANO o CHIAIANO ED UNITI',
      },
      {
        value: 'C675',
        label: 'CICCIANO',
      },
      {
        value: 'C697',
        label: 'CIMITILE',
      },
      {
        value: 'C929',
        label: 'COMIZIANO o CUMIGNANO o CUMIGNANO E GALLO DI NOLA',
      },
      {
        value: 'D170',
        label: 'CRISPANO',
      },
      {
        value: 'H243',
        label: 'ERCOLANO o RESINA',
      },
      {
        value: 'D702',
        label: 'FORIO',
      },
      {
        value: 'D789',
        label: 'FRATTAMAGGIORE',
      },
      {
        value: 'D790',
        label: "FRATTAMINORE o POMIGLIANO D'ATELLA",
      },
      {
        value: 'E054',
        label: 'GIUGLIANO IN CAMPANIA o GIULIANO o GIULIANO IN CAMPANIA',
      },
      {
        value: 'E131',
        label: 'GRAGNANO',
      },
      {
        value: 'E224',
        label: 'GRUMO o GRUMO NEVANO',
      },
      {
        value: 'E329',
        label: 'ISCHIA',
      },
      {
        value: 'E396',
        label: 'LACCO o LACCO AMENO',
      },
      {
        value: 'E557',
        label: 'LETTERE',
      },
      {
        value: 'E577',
        label: 'LICIGNANO o LICIGNANO DI NAPOLI',
      },
      {
        value: 'E620',
        label: 'LIVERI',
      },
      {
        value: 'E906',
        label: 'MARANO o MARANO DI NAPOLI',
      },
      {
        value: 'E954',
        label: 'MARIGLIANELLA',
      },
      {
        value: 'E955',
        label: 'MARIGLIANO',
      },
      {
        value: 'M289',
        label: 'MASSA DI SOMMA',
      },
      {
        value: 'F030',
        label: 'MASSA LUBRENSE',
      },
      {
        value: 'F111',
        label: 'MELITO o MELITO DI NAPOLI',
      },
      {
        value: 'F162',
        label: 'META',
      },
      {
        value: 'F488',
        label: 'MONTE DI PROCIDA',
      },
      {
        value: 'F799',
        label: 'MUGNANO o MUGNANO DI NAPOLI',
      },
      {
        value: 'F839',
        label: 'NAPOLI',
      },
      {
        value: 'F924',
        label: 'NOLA',
      },
      {
        value: 'G190',
        label: 'OTTAIANO o OTTAVIANO',
      },
      {
        value: 'G309',
        label: 'PANICOCOLI o VILLARICCA',
      },
      {
        value: 'G568',
        label: 'PIANO o PIANO DI SORRENTO',
      },
      {
        value: 'G573',
        label: 'PIANURA',
      },
      {
        value: 'G670',
        label: 'PIMONTE',
      },
      {
        value: 'G706',
        label: 'PISCINOLA',
      },
      {
        value: 'G762',
        label: 'POGGIOMARINO',
      },
      {
        value: 'G795',
        label: 'POLLENA TROCCHIA',
      },
      {
        value: 'G812',
        label: "POMIGLIANO D'ARCO",
      },
      {
        value: 'G813',
        label: 'POMPEI',
      },
      {
        value: 'G863',
        label: 'PONTICELLI',
      },
      {
        value: 'G902',
        label: 'PORTICI',
      },
      {
        value: 'G964',
        label: 'POZZUOLI',
      },
      {
        value: 'H072',
        label: 'PROCIDA',
      },
      {
        value: 'H101',
        label: 'QUALIANO',
      },
      {
        value: 'H114',
        label: 'QUARTO',
      },
      {
        value: 'H433',
        label: 'ROCCARAINOLA',
      },
      {
        value: 'H860',
        label: 'SAN GENNARO o SAN GENNARO VESUVIANO',
      },
      {
        value: 'H892',
        label: 'SAN GIORGIO A CREMANO',
      },
      {
        value: 'H908',
        label: 'SAN GIOVANNI A TEDUCCIO',
      },
      {
        value: 'H931',
        label: 'SAN GIUSEPPE o SAN GIUSEPPE VESUVIANO',
      },
      {
        value: 'H954',
        label: 'SAN LEUCIO',
      },
      {
        value: 'I073',
        label: 'SAN PAOLO o SAN PAOLO BEL SITO',
      },
      {
        value: 'I094',
        label: 'SAN PIETRO A PATIERNO',
      },
      {
        value: 'I151',
        label: 'SAN SEBASTIANO o SAN SEBASTIANO AL VESUVIO',
      },
      {
        value: 'I391',
        label: 'SAN VITALIANO',
      },
      {
        value: 'I208',
        label: "SANT'AGNELLO",
      },
      {
        value: 'I262',
        label: "SANT'ANASTASIA",
      },
      {
        value: 'I293',
        label: "SANT'ANTIMO",
      },
      {
        value: 'I300',
        label: "SANT'ANTONIO ABATE",
      },
      {
        value: 'I331',
        label: "SANT'ERASMO o SANTERAMO",
      },
      {
        value: 'M273',
        label: "SANTA MARIA LA CARITA'",
      },
      {
        value: 'I469',
        label: 'SAVIANO',
      },
      {
        value: 'I540',
        label: 'SCISCIANO',
      },
      {
        value: 'I560',
        label: 'SECONDIGLIANO',
      },
      {
        value: 'I652',
        label: 'SERRARA FONTANA',
      },
      {
        value: 'I755',
        label: 'SIRICO',
      },
      {
        value: 'I776',
        label: 'SOCCAVO',
      },
      {
        value: 'I820',
        label: 'SOMMA o SOMMA VESUVIANA',
      },
      {
        value: 'I862',
        label: 'SORRENTO',
      },
      {
        value: 'I978',
        label: 'STRIANO',
      },
      {
        value: 'L142',
        label: 'TERZIGNO',
      },
      {
        value: 'L151',
        label: "TESTACCIO o TESTACCIO D'ISCHIA",
      },
      {
        value: 'L245',
        label: 'TORRE ANNUNZIATA',
      },
      {
        value: 'L259',
        label: 'TORRE DEL GRECO',
      },
      {
        value: 'M280',
        label: 'TRECASE',
      },
      {
        value: 'L460',
        label: 'TUFINO',
      },
      {
        value: 'L845',
        label: 'VICO EQUENSE',
      },
      {
        value: 'M072',
        label: 'VISCIANO',
      },
      {
        value: 'M115',
        label: 'VOLLA',
      },
    ],
  },

  {
    value: 'NO',
    label: 'Novara',
    children: [
      {
        value: 'A066',
        label: 'AGARO',
      },
      {
        value: 'A078',
        label: 'AGNELLENGO',
      },
      {
        value: 'A086',
        label: 'AGRANO',
      },
      {
        value: 'A088',
        label: 'AGRATE o AGRATE CONTURBIA',
      },
      {
        value: 'A130',
        label: 'ALBANO o ALBANO VERCELLESE',
      },
      {
        value: 'A169',
        label: 'ALBOGNO',
      },
      {
        value: 'A198',
        label: 'ALICE o ALICE CASTELLO',
      },
      {
        value: 'A250',
        label: 'ALZATE o ALZATE CON LINDUNO',
      },
      {
        value: 'A264',
        label: 'AMENO',
      },
      {
        value: 'A316',
        label: 'ANTRONAPIANA',
      },
      {
        value: 'A322',
        label: 'ANZINO',
      },
      {
        value: 'A325',
        label: "ANZOLA o ANZOLA D'OSSOLA",
      },
      {
        value: 'A349',
        label: 'ARA',
      },
      {
        value: 'A409',
        label: 'ARIZZANO o ARIZZANO',
      },
      {
        value: 'A410',
        label: 'ARIZZANO INFERIORE',
      },
      {
        value: 'A411',
        label: 'ARIZZANO SUPERIORE',
      },
      {
        value: 'A414',
        label: 'ARMENO',
      },
      {
        value: 'A429',
        label: 'ARONA',
      },
      {
        value: 'A450',
        label: "ARTO'",
      },
      {
        value: 'A504',
        label: 'AUZATE',
      },
      {
        value: 'A610',
        label: 'BANNIO o BANNIO ANZINO',
      },
      {
        value: 'A653',
        label: 'BARENGO',
      },
      {
        value: 'A752',
        label: 'BELLINZAGO o BELLINZAGO NOVARESE',
      },
      {
        value: 'A833',
        label: 'BEURA',
      },
      {
        value: 'A844',
        label: 'BIANDRATE',
      },
      {
        value: 'A862',
        label: 'BIENO',
      },
      {
        value: 'A911',
        label: 'BOCA',
      },
      {
        value: 'A926',
        label: 'BOGNANCO DENTRO',
      },
      {
        value: 'A927',
        label: 'BOGNANCO FUORI',
      },
      {
        value: 'A929',
        label: 'BOGOGNO',
      },
      {
        value: 'A936',
        label: 'BOLETO',
      },
      {
        value: 'A953',
        label: 'BOLZANO o BOLZANO NOVARESE',
      },
      {
        value: 'B043',
        label: 'BORGO TICINO',
      },
      {
        value: 'B016',
        label: 'BORGOLAVEZZARO',
      },
      {
        value: 'B019',
        label: 'BORGOMANERO',
      },
      {
        value: 'B023',
        label: "BORGONE o BORGONE D'OSSOLA",
      },
      {
        value: 'B176',
        label: 'BRIGA NOVARESE',
      },
      {
        value: 'B183',
        label: 'BRIONA',
      },
      {
        value: 'B189',
        label: 'BRISINO',
      },
      {
        value: 'B206',
        label: 'BROVELLO',
      },
      {
        value: 'B254',
        label: 'BUGLIO o MONTEBUGLIO',
      },
      {
        value: 'B257',
        label: 'BUGNATE',
      },
      {
        value: 'B308',
        label: 'BUTTOGNO',
      },
      {
        value: 'B321',
        label: 'CADDO',
      },
      {
        value: 'B421',
        label: 'CALOGNA',
      },
      {
        value: 'B431',
        label: 'CALTIGNAGA',
      },
      {
        value: 'B473',
        label: 'CAMERI',
      },
      {
        value: 'B503',
        label: 'CAMPELLO o CAMPELLO MONTI',
      },
      {
        value: 'B610',
        label: 'CANNERO o CANNERO RIVIERA',
      },
      {
        value: 'B757',
        label: 'CARDEZZA',
      },
      {
        value: 'B773',
        label: 'CARGIAGO',
      },
      {
        value: 'B823',
        label: 'CARPIGNANO o CARPIGNANO SESIA',
      },
      {
        value: 'B831',
        label: 'CARPUGNINO',
      },
      {
        value: 'B864',
        label: 'CASALBELTRAME',
      },
      {
        value: 'B876',
        label: 'CASALE o CASALE CORTE CERRO',
      },
      {
        value: 'B883',
        label: 'CASALEGGIO o CASALEGGIO NOVARA',
      },
      {
        value: 'B884',
        label: 'CASALEGGIO CASTELLAZZO',
      },
      {
        value: 'B897',
        label: 'CASALINO',
      },
      {
        value: 'B920',
        label: 'CASALVOLONE',
      },
      {
        value: 'B928',
        label: 'CASANOVA o CASANOVA ELVO',
      },
      {
        value: 'C149',
        label: 'CASTELLAZZO o CASTELLAZZO NOVARESE',
      },
      {
        value: 'C166',
        label: 'CASTELLETTO SOPRA TICINO',
      },
      {
        value: 'C171',
        label: 'CASTELLI CUSIANI',
      },
      {
        value: 'C305',
        label: "CASTIGLIONE o CASTIGLIONE D'OSSOLA",
      },
      {
        value: 'C364',
        label: 'CAVAGLIETTO',
      },
      {
        value: 'C365',
        label: "CAVAGLIO o CAVAGLIO D'AGOGNA",
      },
      {
        value: 'C366',
        label: 'CAVAGLIO o CAVAGLIO SAN DONNINO',
      },
      {
        value: 'C378',
        label: 'CAVALLIRIO',
      },
      {
        value: 'C379',
        label: 'CAVANDONE',
      },
      {
        value: 'C483',
        label: 'CERANO',
      },
      {
        value: 'C636',
        label: 'CHIGNOLO o CHIGNOLO VERBANO',
      },
      {
        value: 'C690',
        label: 'CIMAMULERA',
      },
      {
        value: 'C721',
        label: 'CIREGGIO',
      },
      {
        value: 'C736',
        label: 'CISORE',
      },
      {
        value: 'C827',
        label: 'COIMO',
      },
      {
        value: 'C828',
        label: 'COIRO o COIROMONTE',
      },
      {
        value: 'C829',
        label: 'COLAZZA',
      },
      {
        value: 'C926',
        label: 'COMIGNAGO',
      },
      {
        value: 'C932',
        label: 'COMNAGO',
      },
      {
        value: 'C989',
        label: 'CORCIAGO',
      },
      {
        value: 'D129',
        label: 'CRANA',
      },
      {
        value: 'D130',
        label: 'CRANA CATTUGNO',
      },
      {
        value: 'D135',
        label: 'CRAVEGNA',
      },
      {
        value: 'D162',
        label: 'CRESSA',
      },
      {
        value: 'D168',
        label: "CREVOLA o CREVOLA D'OSSOLA o CREVOLADOSSOLA",
      },
      {
        value: 'D177',
        label: 'CRODO MOZZIO o CRODO',
      },
      {
        value: 'D191',
        label: 'CRUSINALLO',
      },
      {
        value: 'D216',
        label: 'CUREGGIO',
      },
      {
        value: 'D224',
        label: 'CURSOLO',
      },
      {
        value: 'D241',
        label: 'CUZZAGO',
      },
      {
        value: 'D242',
        label: 'DAGNENTE',
      },
      {
        value: 'D306',
        label: 'DISSIMO',
      },
      {
        value: 'D309',
        label: 'DIVIGNANO',
      },
      {
        value: 'D347',
        label: 'DORMELLETTO',
      },
      {
        value: 'D438',
        label: 'ESIO',
      },
      {
        value: 'D492',
        label: 'FARA o FARA NOVARESE',
      },
      {
        value: 'D603',
        label: 'FINERO',
      },
      {
        value: 'D657',
        label: 'FOLSOGNO',
      },
      {
        value: 'D659',
        label: 'FOMARCO',
      },
      {
        value: 'D675',
        label: "FONTANETO D'AGOGNA o FONTANETTO",
      },
      {
        value: 'D676',
        label: 'FONTANETTO o FONTANETTO DA PO o FONTANETTO PO',
      },
      {
        value: 'D698',
        label: 'FORESTO o FORESTO SESIA',
      },
      {
        value: 'D716',
        label: 'FORNERO',
      },
      {
        value: 'D722',
        label: 'FORNO',
      },
      {
        value: 'D747',
        label: 'FOSSENO',
      },
      {
        value: 'D872',
        label: 'GALLIATE',
      },
      {
        value: 'D911',
        label: 'GARBAGNA o GARBAGNA NOVARESE',
      },
      {
        value: 'D921',
        label: 'GARGALLO',
      },
      {
        value: 'D937',
        label: 'GATTICO',
      },
      {
        value: 'M416',
        label: 'GATTICO-VERUNO',
      },
      {
        value: 'E001',
        label: 'GHEMME',
      },
      {
        value: 'E002',
        label: 'GHEVIO',
      },
      {
        value: 'E120',
        label: 'GOZZANO',
      },
      {
        value: 'E129',
        label: 'GRAGLIA o GRAGLIA PIANA',
      },
      {
        value: 'E143',
        label: 'GRANOZZO CON MONTICELLO',
      },
      {
        value: 'E177',
        label: 'GRIGNASCO',
      },
      {
        value: 'E303',
        label: 'INTRA',
      },
      {
        value: 'E314',
        label: 'INVORIO',
      },
      {
        value: 'E315',
        label: 'INVORIO INFERIORE',
      },
      {
        value: 'E316',
        label: 'INVORIO SUPERIORE',
      },
      {
        value: 'E359',
        label: 'ISOLA SAN GIULIO',
      },
      {
        value: 'E436',
        label: 'LANDIONA',
      },
      {
        value: 'E544',
        label: 'LESA',
      },
      {
        value: 'E545',
        label: 'LESA-BELGIRATE',
      },
      {
        value: 'E626',
        label: 'LIVORNO PIEMONTE o LIVORNO VERCELLESE o LIVORNO FERRARIS',
      },
      {
        value: 'E774',
        label: 'LUZZOGNO',
      },
      {
        value: 'E803',
        label: 'MAGGIORA',
      },
      {
        value: 'E824',
        label: 'MAGOGNINO',
      },
      {
        value: 'E880',
        label: 'MANDELLO o MANDELLO VITTA',
      },
      {
        value: 'E907',
        label: 'MARANO o MARANO TICINO',
      },
      {
        value: 'F047',
        label: 'MASSINO o MASSINO VISCONTI',
      },
      {
        value: 'F093',
        label: 'MEINA',
      },
      {
        value: 'F143',
        label: 'MERCURAGO',
      },
      {
        value: 'F188',
        label: 'MEZZOMERICO',
      },
      {
        value: 'F191',
        label: 'MIASINO',
      },
      {
        value: 'F195',
        label: 'MIGIANDONE',
      },
      {
        value: 'F317',
        label: 'MOMO',
      },
      {
        value: 'F584',
        label: 'MONTE OSSOLANO',
      },
      {
        value: 'F699',
        label: 'MONTRIGIASCO',
      },
      {
        value: 'F790',
        label: 'MOZZIO',
      },
      {
        value: 'F859',
        label: 'NEBBIUNO',
      },
      {
        value: 'F886',
        label: 'NIBBIOLA',
      },
      {
        value: 'F909',
        label: 'NOCCO',
      },
      {
        value: 'F952',
        label: 'NOVARA',
      },
      {
        value: 'G019',
        label: 'OLEGGIO',
      },
      {
        value: 'G020',
        label: 'OLEGGIO CASTELLO',
      },
      {
        value: 'G024',
        label: 'OLGIA',
      },
      {
        value: 'G085',
        label: 'ORASSO',
      },
      {
        value: 'G132',
        label: 'ORTA o ORTA NOVARESE',
      },
      {
        value: 'G134',
        label: 'ORTA SAN GIULIO',
      },
      {
        value: 'G266',
        label: 'PALAZZOLO o PALAZZOLO VERCELLESE',
      },
      {
        value: 'G279',
        label: 'PALLANZA',
      },
      {
        value: 'G349',
        label: 'PARUZZARO',
      },
      {
        value: 'G421',
        label: 'PELLA',
      },
      {
        value: 'G520',
        label: 'PETTENASCO',
      },
      {
        value: 'G554',
        label: 'PIANE o PIANE DI SERRAVALLE SESIA o PIANE SESIA',
      },
      {
        value: 'G703',
        label: 'PISANO',
      },
      {
        value: 'G775',
        label: 'POGNO',
      },
      {
        value: 'G809',
        label: 'POMBIA',
      },
      {
        value: 'H001',
        label: 'PRATO o PRATO SESIA',
      },
      {
        value: 'H025',
        label: 'PREGLIA',
      },
      {
        value: 'H037',
        label: 'PREMOSELLO o PREMOSELLO-CHIOVENDA',
      },
      {
        value: 'H041',
        label: 'PREQUARTERA',
      },
      {
        value: 'H051',
        label: 'PRESTINONE',
      },
      {
        value: 'H105',
        label: 'QUARNA',
      },
      {
        value: 'H213',
        label: 'RECETTO',
      },
      {
        value: 'H329',
        label: 'RIVA o RIVA VALDOBBIA',
      },
      {
        value: 'H381',
        label: 'ROCCA o ROCCA PIETRA',
      },
      {
        value: 'H502',
        label: 'ROMAGNANO o ROMAGNANO SESIA',
      },
      {
        value: 'H518',
        label: 'ROMENTINO',
      },
      {
        value: 'H600',
        label: 'ROVEGRO',
      },
      {
        value: 'H638',
        label: 'RUMIANCA',
      },
      {
        value: 'H698',
        label: 'SALECCHIO',
      },
      {
        value: 'H707',
        label: 'SALI o SALI VERCELLESE',
      },
      {
        value: 'H748',
        label: 'SAMBUGHETTO',
      },
      {
        value: 'H762',
        label: 'SAN BARTOLOMEO o SAN BARTOLOMEO VALMARA',
      },
      {
        value: 'H788',
        label: "SAN CARLO o SAN CARLO D'OSSOLA",
      },
      {
        value: 'H861',
        label: 'SAN GERMANO o SAN GERMANO VERCELLESE',
      },
      {
        value: 'H932',
        label: 'SAN GIUSEPPE o SAN GIUSEPPE DI CASTO',
      },
      {
        value: 'E003',
        label: 'SAN MAURIZIO o GHIFFA',
      },
      {
        value: 'I025',
        label: "SAN MAURIZIO D'OPAGLIO",
      },
      {
        value: 'I052',
        label: 'SAN NAZZARO SESIA',
      },
      {
        value: 'I116',
        label: 'SAN PIETRO MOSEZZO',
      },
      {
        value: 'I195',
        label: "SANT'AGATA o SANT'AGATA SOPRA CANNOBIO",
      },
      {
        value: 'I250',
        label: 'SANTA MARIA MAGGIORE E CRANA',
      },
      {
        value: 'I343',
        label: 'SANTINO',
      },
      {
        value: 'I528',
        label: 'SCHIERANCO',
      },
      {
        value: 'I663',
        label: 'SERRAVALLE o SERRAVALLE SESIA',
      },
      {
        value: 'I736',
        label: 'SILLA o SILLAVENGO',
      },
      {
        value: 'I767',
        label: 'SIZZANO',
      },
      {
        value: 'I806',
        label: 'SOLOGNO',
      },
      {
        value: 'I857',
        label: 'SORISO',
      },
      {
        value: 'I870',
        label: 'SOVAZZA',
      },
      {
        value: 'I880',
        label: 'SOZZAGO',
      },
      {
        value: 'I920',
        label: 'SPOCCIA',
      },
      {
        value: 'I976',
        label: 'STRESA BORROMEO o STRESA',
      },
      {
        value: 'I983',
        label: 'STROPINO',
      },
      {
        value: 'L005',
        label: 'SUNA',
      },
      {
        value: 'L007',
        label: 'SUNO',
      },
      {
        value: 'L043',
        label: 'TAPIGLIANO',
      },
      {
        value: 'L045',
        label: 'TAPPIA',
      },
      {
        value: 'L104',
        label: 'TERDOBBIATE',
      },
      {
        value: 'L223',
        label: 'TORNACO',
      },
      {
        value: 'L320',
        label: 'TRAFFIUME',
      },
      {
        value: 'L333',
        label: 'TRAREGO o TRAREGO VIGGIONA',
      },
      {
        value: 'L356',
        label: 'TRECATE',
      },
      {
        value: 'L443',
        label: 'TROBASO',
      },
      {
        value: 'L451',
        label: 'TRONZANO o TRONZANO VERCELLESE',
      },
      {
        value: 'L493',
        label: 'UNCHIO',
      },
      {
        value: 'L534',
        label: 'VAGNA',
      },
      {
        value: 'L666',
        label: 'VANZONE o VANZONE CON SAN CARLO',
      },
      {
        value: 'L668',
        label: "VAPRIO o VAPRIO D'AGOGNA",
      },
      {
        value: 'L670',
        label: 'VARALLO POMBIA',
      },
      {
        value: 'L761',
        label: 'VERGANO o VERGANO NOVARESE',
      },
      {
        value: 'L798',
        label: 'VERUNO',
      },
      {
        value: 'L808',
        label: 'VESPOLATE',
      },
      {
        value: 'L824',
        label: 'VEZZO',
      },
      {
        value: 'L839',
        label: 'VICENO',
      },
      {
        value: 'L847',
        label: 'VICOLUNGO',
      },
      {
        value: 'L875',
        label: 'VIGGIONA',
      },
      {
        value: 'L906',
        label: 'VILLA o VILLADOSSOLA',
      },
      {
        value: 'M062',
        label: 'VINZAGLIO',
      },
      {
        value: 'M107',
        label: 'VOCOGNO E PRESTINONE',
      },
      {
        value: 'M192',
        label: 'ZORNASCO',
      },
      {
        value: 'M195',
        label: 'ZOVERALLO',
      },
    ],
  },

  {
    value: 'NU',
    label: 'Nuoro',
    children: [
      {
        value: 'A355',
        label: "ARBATAX DI TORTOLI o TORTOLI'",
      },
      {
        value: 'A407',
        label: 'ARITZO',
      },
      {
        value: 'A454',
        label: 'ARZANA',
      },
      {
        value: 'A492',
        label: 'ATZARA',
      },
      {
        value: 'A503',
        label: 'AUSTIS',
      },
      {
        value: 'A722',
        label: 'BAUNEI',
      },
      {
        value: 'A776',
        label: "BELVI o BELVI'",
      },
      {
        value: 'A880',
        label: 'BIRORI',
      },
      {
        value: 'A895',
        label: 'BITTI',
      },
      {
        value: 'A948',
        label: 'BOLOTANA',
      },
      {
        value: 'B056',
        label: 'BORORE',
      },
      {
        value: 'B062',
        label: 'BORTIGALI',
      },
      {
        value: 'B068',
        label: 'BOSA o BOSA',
      },
      {
        value: 'B248',
        label: 'BUDONI o BUDONI',
      },
      {
        value: 'M285',
        label: 'CARDEDU',
      },
      {
        value: 'D287',
        label: 'DESULO',
      },
      {
        value: 'D345',
        label: 'DORGALI',
      },
      {
        value: 'D376',
        label: 'DUALCHI',
      },
      {
        value: 'D395',
        label: 'ELINI',
      },
      {
        value: 'D430',
        label: 'ESCALAPLANO o ESCALAPLANO',
      },
      {
        value: 'D431',
        label: 'ESCOLCA o ESCOLCA',
      },
      {
        value: 'D443',
        label: 'ESTERZILI o ESTERZILI',
      },
      {
        value: 'D640',
        label: 'FLUSSIO o FLUSSIO',
      },
      {
        value: 'D665',
        label: 'FONNI',
      },
      {
        value: 'D842',
        label: 'GADONI',
      },
      {
        value: 'D859',
        label: 'GAIRO',
      },
      {
        value: 'D888',
        label: "GALTELLI o GALTELLI'",
      },
      {
        value: 'D947',
        label: 'GAVOI',
      },
      {
        value: 'D968',
        label: 'GENONI o GENONI',
      },
      {
        value: 'D982',
        label: 'GERGEI o GERGEI',
      },
      {
        value: 'E049',
        label: 'GIRASOLE',
      },
      {
        value: 'E283',
        label: 'ILBONO',
      },
      {
        value: 'E323',
        label: 'IRGOLI',
      },
      {
        value: 'E324',
        label: 'IRGOLI DI GALTELLI',
      },
      {
        value: 'E336',
        label: 'ISILI o ISILI',
      },
      {
        value: 'E387',
        label: 'JERZU',
      },
      {
        value: 'E400',
        label: 'LACONI o LACONI',
      },
      {
        value: 'E441',
        label: 'LANUSEI',
      },
      {
        value: 'E517',
        label: 'LEI',
      },
      {
        value: 'E644',
        label: 'LOCERI',
      },
      {
        value: 'E646',
        label: 'LOCULI',
      },
      {
        value: 'E647',
        label: "LODE'",
      },
      {
        value: 'E649',
        label: 'LODINE',
      },
      {
        value: 'E700',
        label: 'LOTZORAI',
      },
      {
        value: 'E736',
        label: 'LULA',
      },
      {
        value: 'E788',
        label: 'MACOMER',
      },
      {
        value: 'E825',
        label: 'MAGOMADAS o MAGOMADAS',
      },
      {
        value: 'E874',
        label: 'MAMOIADA',
      },
      {
        value: 'F261',
        label: 'MODOLO o MODOLO',
      },
      {
        value: 'F698',
        label: 'MONTRESTA o MONTRESTA',
      },
      {
        value: 'F933',
        label: 'NORAGUGUME',
      },
      {
        value: 'F979',
        label: 'NUORO',
      },
      {
        value: 'F981',
        label: 'NURAGUS o NURAGUS',
      },
      {
        value: 'F982',
        label: 'NURALLAO o NURALLAO',
      },
      {
        value: 'F986',
        label: 'NURRI o NURRI',
      },
      {
        value: 'G031',
        label: 'OLIENA',
      },
      {
        value: 'G044',
        label: 'OLLOLAI',
      },
      {
        value: 'G058',
        label: 'OLZAI',
      },
      {
        value: 'G064',
        label: "ONANI o ONANI'",
      },
      {
        value: 'G070',
        label: 'ONIFAI',
      },
      {
        value: 'G071',
        label: 'ONIFERI',
      },
      {
        value: 'G084',
        label: 'ORANI',
      },
      {
        value: 'G097',
        label: 'ORGOSOLO',
      },
      {
        value: 'G119',
        label: 'OROSEI',
      },
      {
        value: 'G120',
        label: 'OROTELLI',
      },
      {
        value: 'G122',
        label: 'ORROLI o ORROLI',
      },
      {
        value: 'G146',
        label: 'ORTUERI',
      },
      {
        value: 'G147',
        label: 'ORUNE',
      },
      {
        value: 'G154',
        label: 'OSIDDA',
      },
      {
        value: 'G158',
        label: 'OSINI',
      },
      {
        value: 'G191',
        label: 'OTTANA',
      },
      {
        value: 'G201',
        label: 'OVODDA',
      },
      {
        value: 'G445',
        label: 'PERDASDEFOGU',
      },
      {
        value: 'G929',
        label: 'POSADA',
      },
      {
        value: 'H659',
        label: 'SADALI o SADALI',
      },
      {
        value: 'H661',
        label: 'SAGAMA o SAGAMA',
      },
      {
        value: 'I329',
        label: 'SAN TEODORO o SAN TEODORO',
      },
      {
        value: 'I448',
        label: 'SARULE',
      },
      {
        value: 'I668',
        label: 'SERRI o SERRI',
      },
      {
        value: 'I706',
        label: 'SEUI o SEUI',
      },
      {
        value: 'I707',
        label: 'SEULO o SEULO',
      },
      {
        value: 'I730',
        label: 'SILANUS',
      },
      {
        value: 'I748',
        label: 'SINDIA',
      },
      {
        value: 'I751',
        label: 'SINISCOLA',
      },
      {
        value: 'I851',
        label: 'SORGONO',
      },
      {
        value: 'L006',
        label: 'SUNI o SUNI',
      },
      {
        value: 'L036',
        label: 'TALANA',
      },
      {
        value: 'L140',
        label: 'TERTENIA',
      },
      {
        value: 'L153',
        label: 'TETI',
      },
      {
        value: 'L160',
        label: 'TIANA',
      },
      {
        value: 'L172',
        label: 'TINNURA o TINNURA',
      },
      {
        value: 'L202',
        label: 'TONARA',
      },
      {
        value: 'L231',
        label: "TORPE'",
      },
      {
        value: 'L423',
        label: 'TRIEI',
      },
      {
        value: 'L489',
        label: 'ULASSAI',
      },
      {
        value: 'L506',
        label: 'URZULEI',
      },
      {
        value: 'L514',
        label: 'USSASSAI',
      },
      {
        value: 'L953',
        label: 'VILLAGRANDE STRISAILI',
      },
      {
        value: 'L992',
        label: 'VILLANOVATULO o VILLANOVA TULO',
      },
    ],
  },

  {
    value: 'OR',
    label: 'Oristano',
    children: [
      {
        value: 'A007',
        label: 'ABBASANTA',
      },
      {
        value: 'A097',
        label: 'AIDOMAGGIORE',
      },
      {
        value: 'A180',
        label: 'ALES',
      },
      {
        value: 'A204',
        label: 'ALLAI',
      },
      {
        value: 'A380',
        label: 'ARDAULI',
      },
      {
        value: 'A480',
        label: 'ASUNI',
      },
      {
        value: 'A614',
        label: 'BARADILI',
      },
      {
        value: 'A655',
        label: 'BARESSA',
      },
      {
        value: 'A721',
        label: 'BAULADU',
      },
      {
        value: 'A856',
        label: "BIDONI'",
      },
      {
        value: 'A960',
        label: 'BONARCADO',
      },
      {
        value: 'B055',
        label: 'BORONEDDU',
      },
      {
        value: 'B281',
        label: 'BUSACHI',
      },
      {
        value: 'B314',
        label: 'CABRAS',
      },
      {
        value: 'D200',
        label: 'CUGLIERI',
      },
      {
        value: 'D214',
        label: 'CURCURIS',
      },
      {
        value: 'D695',
        label: 'FORDONGIANUS',
      },
      {
        value: 'E004',
        label: 'GHILARZA',
      },
      {
        value: 'E087',
        label: 'GONNOSCODINA',
      },
      {
        value: 'E088',
        label: 'GONNOSTRAMATZA',
      },
      {
        value: 'E972',
        label: 'MARRUBIU',
      },
      {
        value: 'F050',
        label: 'MASULLAS',
      },
      {
        value: 'F208',
        label: 'MILIS',
      },
      {
        value: 'F270',
        label: 'MOGORELLA',
      },
      {
        value: 'F272',
        label: 'MOGORO',
      },
      {
        value: 'F727',
        label: 'MORGONGIORI',
      },
      {
        value: 'F840',
        label: 'NARBOLIA',
      },
      {
        value: 'F867',
        label: 'NEONELI',
      },
      {
        value: 'F980',
        label: 'NURACHI',
      },
      {
        value: 'F985',
        label: 'NURECI',
      },
      {
        value: 'G043',
        label: 'OLLASTRA o OLLASTRA SIMAXIS',
      },
      {
        value: 'G113',
        label: 'ORISTANO',
      },
      {
        value: 'G379',
        label: 'PAU',
      },
      {
        value: 'G384',
        label: 'PAULILATINO',
      },
      {
        value: 'H756',
        label: 'SAMUGHEO',
      },
      {
        value: 'I384',
        label: 'SAN VERO MILIS',
      },
      {
        value: 'I205',
        label: 'SANTA GIUSTA',
      },
      {
        value: 'I374',
        label: 'SANTU LUSSURGIU',
      },
      {
        value: 'I564',
        label: 'SEDILO',
      },
      {
        value: 'I605',
        label: 'SENEGHE',
      },
      {
        value: 'I609',
        label: 'SENIS',
      },
      {
        value: 'I613',
        label: 'SENNARIOLO',
      },
      {
        value: 'I717',
        label: 'SIAMAGGIORE',
      },
      {
        value: 'I718',
        label: 'SIAMANNA',
      },
      {
        value: 'I719',
        label: 'SIAMANNA SIAPICCIA',
      },
      {
        value: 'I721',
        label: 'SIAPICCIA',
      },
      {
        value: 'I742',
        label: 'SIMALA',
      },
      {
        value: 'I743',
        label: 'SIMAXIS',
      },
      {
        value: 'I749',
        label: 'SINI',
      },
      {
        value: 'I757',
        label: 'SIRIS',
      },
      {
        value: 'I778',
        label: "SODDI'",
      },
      {
        value: 'I791',
        label: 'SOLARUSSA',
      },
      {
        value: 'I861',
        label: 'SORRADILE',
      },
      {
        value: 'L023',
        label: 'TADASUNI',
      },
      {
        value: 'L122',
        label: 'TERRALBA',
      },
      {
        value: 'L321',
        label: 'TRAMATZA',
      },
      {
        value: 'L393',
        label: 'TRESNURAGHES',
      },
      {
        value: 'L496',
        label: 'URAS',
      },
      {
        value: 'L508',
        label: 'USELLUS',
      },
      {
        value: 'L991',
        label: 'VILLANOVA TRUSCHEDU',
      },
      {
        value: 'M030',
        label: 'VILLAURBANA',
      },
      {
        value: 'M153',
        label: 'ZEDDIANI',
      },
      {
        value: 'M168',
        label: 'ZERFALIU',
      },
    ],
  },

  {
    value: 'PA',
    label: 'Palermo',
    children: [
      {
        value: 'A195',
        label: 'ALIA',
      },
      {
        value: 'A202',
        label: 'ALIMENA',
      },
      {
        value: 'A203',
        label: 'ALIMINUSA',
      },
      {
        value: 'A229',
        label: 'ALTAVILLA o ALTAVILLA MILICIA',
      },
      {
        value: 'A239',
        label: 'ALTOFONTE o PARCO',
      },
      {
        value: 'A546',
        label: 'BAGHERIA',
      },
      {
        value: 'A592',
        label: 'BALESTRATE',
      },
      {
        value: 'A719',
        label: 'BAUCINA',
      },
      {
        value: 'A764',
        label: 'BELMONTE o BELMONTE MEZZAGNO',
      },
      {
        value: 'A882',
        label: 'BISACQUINO',
      },
      {
        value: 'M268',
        label: 'BLUFI',
      },
      {
        value: 'A946',
        label: 'BOLOGNETTA o OGLIASTRO o SANTA MARIA DI OGLIASTRO',
      },
      {
        value: 'A958',
        label: 'BOMPIETRO o BUOMPIETRO',
      },
      {
        value: 'A991',
        label: 'BORGETTO',
      },
      {
        value: 'B315',
        label: 'CACCAMO',
      },
      {
        value: 'B430',
        label: 'CALTAVUTURO',
      },
      {
        value: 'B532',
        label: 'CAMPOFELICE o CAMPOFELICE DI ROCCELLA',
      },
      {
        value: 'B533',
        label: 'CAMPOFELICE DI FITALIA',
      },
      {
        value: 'B535',
        label: 'CAMPOFIORITO',
      },
      {
        value: 'B556',
        label: 'CAMPOREALE',
      },
      {
        value: 'B645',
        label: 'CAPACI',
      },
      {
        value: 'B780',
        label: 'CARINI',
      },
      {
        value: 'C067',
        label: 'CASTELBUONO',
      },
      {
        value: 'C074',
        label: 'CASTELDACCIA',
      },
      {
        value: 'C135',
        label: 'CASTELLANA SICULA',
      },
      {
        value: 'C344',
        label: 'CASTRONOVO o CASTRONOVO DI SICILIA',
      },
      {
        value: 'C420',
        label: "CEFALA' DIANA",
      },
      {
        value: 'C421',
        label: "CEFALU'",
      },
      {
        value: 'C496',
        label: 'CERDA',
      },
      {
        value: 'C654',
        label: 'CHIUSA o CHIUSA SCLAFANI',
      },
      {
        value: 'C696',
        label: 'CIMINNA',
      },
      {
        value: 'C708',
        label: 'CINISI',
      },
      {
        value: 'C871',
        label: 'COLLESANO',
      },
      {
        value: 'C968',
        label: 'CONTESSA o CONTESSA ENTELLINA',
      },
      {
        value: 'D009',
        label: 'CORLEONE',
      },
      {
        value: 'D567',
        label: 'FICARAZZI',
      },
      {
        value: 'D907',
        label: 'GANGI',
      },
      {
        value: 'D977',
        label: 'GERACI o GERACI SICULO',
      },
      {
        value: 'E013',
        label: 'GIARDINELLO',
      },
      {
        value: 'E055',
        label: 'GIULIANA',
      },
      {
        value: 'E074',
        label: 'GODRANO',
      },
      {
        value: 'E149',
        label: 'GRATTERI',
      },
      {
        value: 'E337',
        label: 'ISNELLO',
      },
      {
        value: 'E350',
        label: 'ISOLA DELLE FEMMINE',
      },
      {
        value: 'E459',
        label: 'LASCARI',
      },
      {
        value: 'E541',
        label: 'LERCARA FRIDDI',
      },
      {
        value: 'E957',
        label: 'MARINEO',
      },
      {
        value: 'F184',
        label: 'MEZZOJUSO',
      },
      {
        value: 'F246',
        label: 'MISILMERI',
      },
      {
        value: 'F377',
        label: 'MONREALE',
      },
      {
        value: 'F544',
        label: 'MONTELEPRE',
      },
      {
        value: 'F553',
        label: 'MONTEMAGGIORE o MONTEMAGGIORE BELSITO',
      },
      {
        value: 'G263',
        label: 'PALAZZO ADRIANO',
      },
      {
        value: 'G273',
        label: 'PALERMO',
      },
      {
        value: 'G348',
        label: 'PARTINICO',
      },
      {
        value: 'G510',
        label: 'PETRALIA SOPRANA',
      },
      {
        value: 'G511',
        label: 'PETRALIA SOTTANA',
      },
      {
        value: 'G543',
        label: 'PIANA DEGLI ALBANESI o PIANA DEI GRECI',
      },
      {
        value: 'G792',
        label: 'POLIZZI o POLIZZI GENEROSA',
      },
      {
        value: 'G797',
        label: 'POLLINA',
      },
      {
        value: 'H070',
        label: 'PRIZZI',
      },
      {
        value: 'H422',
        label: 'ROCCAMENA',
      },
      {
        value: 'H428',
        label: 'ROCCAPALUMBA',
      },
      {
        value: 'H797',
        label: 'SAN CIPIRELLO',
      },
      {
        value: 'H933',
        label: 'SAN GIUSEPPE o SAN GIUSEPPE JATO',
      },
      {
        value: 'I028',
        label: 'SAN MAURO o SAN MAURO CASTELVERDE',
      },
      {
        value: 'I174',
        label: 'SANTA CRISTINA o SANTA CRISTINA GELA',
      },
      {
        value: 'I188',
        label: 'SANTA FLAVIA o SOLANTO',
      },
      {
        value: 'I534',
        label: 'SCIARA',
      },
      {
        value: 'I538',
        label: 'SCILLATO',
      },
      {
        value: 'I541',
        label: 'SCLAFANI o SCLAFANI BAGNI',
      },
      {
        value: 'L112',
        label: 'TERMINI o TERMINI IMERESE',
      },
      {
        value: 'L131',
        label: 'TERRASINI o TERRASINI FAVAROTTA',
      },
      {
        value: 'L282',
        label: 'TORRETTA',
      },
      {
        value: 'L317',
        label: 'TRABIA',
      },
      {
        value: 'L332',
        label: 'TRAPPETO',
      },
      {
        value: 'L519',
        label: 'USTICA',
      },
      {
        value: 'L603',
        label: 'VALLEDOLMO',
      },
      {
        value: 'L740',
        label: 'VENTIMIGLIA o VENTIMIGLIA DI SICILIA',
      },
      {
        value: 'L837',
        label: 'VICARI',
      },
      {
        value: 'L916',
        label: 'VILLABATE',
      },
      {
        value: 'L951',
        label: 'VILLAFRATI',
      },
    ],
  },

  {
    value: 'PC',
    label: 'Piacenza',
    children: [
      {
        value: 'A067',
        label: 'AGAZZANO',
      },
      {
        value: 'A223',
        label: 'ALSENO',
      },
      {
        value: 'M386',
        label: 'ALTA VAL TIDONE',
      },
      {
        value: 'A823',
        label: 'BESENZONE',
      },
      {
        value: 'A831',
        label: 'BETTOLA o BORGONURE',
      },
      {
        value: 'A909',
        label: 'BOBBIO',
      },
      {
        value: 'B032',
        label: 'BORGO SAN BERNARDINO',
      },
      {
        value: 'B025',
        label: 'BORGONOVO VAL TIDONE',
      },
      {
        value: 'B332',
        label: 'CADEO',
      },
      {
        value: 'B404',
        label: 'CALENDASCO o CALDONAZZO',
      },
      {
        value: 'B479',
        label: 'CAMINATA',
      },
      {
        value: 'B643',
        label: 'CAORSO',
      },
      {
        value: 'B812',
        label: 'CARPANETO o CARPANETO PIACENTINO',
      },
      {
        value: 'C261',
        label: 'CASTEL SAN GIOVANNI',
      },
      {
        value: 'C145',
        label: "CASTELL'ARQUATO",
      },
      {
        value: 'C288',
        label: 'CASTELVETRO o CASTELVETRO PIACENTINO',
      },
      {
        value: 'C513',
        label: 'CERIGNALE',
      },
      {
        value: 'C838',
        label: 'COLI',
      },
      {
        value: 'D054',
        label: 'CORTE BRUGNATELLA',
      },
      {
        value: 'D061',
        label: 'CORTEMAGGIORE',
      },
      {
        value: 'D502',
        label: "FARINI o FARINI D'OLMO",
      },
      {
        value: 'D555',
        label: 'FERRIERE',
      },
      {
        value: 'D611',
        label: "FIORENZUOLA o FIORENZUOLA D'ARDA",
      },
      {
        value: 'D958',
        label: 'GAZZOLA o RIVALTA o RIVALTA TREBBIA',
      },
      {
        value: 'E114',
        label: 'GOSSOLENGO',
      },
      {
        value: 'E132',
        label: 'GRAGNANO o GRAGNANO TREBBIENSE',
      },
      {
        value: 'E196',
        label: 'GROPPARELLO',
      },
      {
        value: 'E726',
        label: "LUGAGNANO o LUGAGNANO VAL D'ARDA",
      },
      {
        value: 'F671',
        label: "MONTICELLI o MONTICELLI D'ONGINA",
      },
      {
        value: 'F724',
        label: 'MORFASSO',
      },
      {
        value: 'F759',
        label: 'MORTIZZA',
      },
      {
        value: 'F885',
        label: 'NIBBIANO',
      },
      {
        value: 'G195',
        label: 'OTTONE',
      },
      {
        value: 'G399',
        label: 'PECORARA',
      },
      {
        value: 'G535',
        label: 'PIACENZA',
      },
      {
        value: 'G557',
        label: 'PIANELLO o PIANELLO VAL TIDONE',
      },
      {
        value: 'G696',
        label: 'PIOZZANO o POMARO o POMARO PIACENTINO',
      },
      {
        value: 'G747',
        label: 'PODENZANO',
      },
      {
        value: 'G788',
        label: 'POLIGNANO o POLIGNANO PIACENTINO o SAN PIETRO IN CERRO',
      },
      {
        value: 'G842',
        label: "PONTE DELL'OLIO",
      },
      {
        value: 'G852',
        label: 'PONTENURE',
      },
      {
        value: 'H350',
        label: 'RIVERGARO',
      },
      {
        value: 'H593',
        label: 'ROTTOFRENO',
      },
      {
        value: 'H887',
        label: 'SAN GIORGIO o SAN GIORGIO PIACENTINO',
      },
      {
        value: 'H947',
        label: 'SAN LAZZARO o SAN LAZZARO ALBERONI',
      },
      {
        value: 'I299',
        label: "SANT'ANTONIO o SANT'ANTONIO A TREBBIA",
      },
      {
        value: 'I434',
        label: 'SARMATO',
      },
      {
        value: 'L348',
        label: 'TRAVO',
      },
      {
        value: 'L351',
        label: 'TREBECCO',
      },
      {
        value: 'L772',
        label: 'VERNASCA',
      },
      {
        value: 'L848',
        label: 'VICOMARINO o ZIANO o ZIANO PIACENTINO',
      },
      {
        value: 'L897',
        label: 'VIGOLZONE',
      },
      {
        value: 'L980',
        label: "VILLANOVA o VILLANOVA SULL'ARDA",
      },
      {
        value: 'M165',
        label: 'ZERBA',
      },
    ],
  },

  {
    value: 'PD',
    label: 'Padova',
    children: [
      {
        value: 'A001',
        label: 'ABANO BAGNI o ABANO TERME',
      },
      {
        value: 'A075',
        label: 'AGNA',
      },
      {
        value: 'A161',
        label: 'ALBIGNASEGO',
      },
      {
        value: 'A296',
        label: 'ANGUILLARA o ANGUILLARA VENETA',
      },
      {
        value: 'A434',
        label: "ARQUA' o ARQUA' PETRARCA",
      },
      {
        value: 'A438',
        label: 'ARRE',
      },
      {
        value: 'A458',
        label: 'ARZERGRANDE',
      },
      {
        value: 'A568',
        label: 'BAGNOLI o BAGNOLI DI SOPRA',
      },
      {
        value: 'A613',
        label: 'BAONE',
      },
      {
        value: 'A637',
        label: 'BARBONA',
      },
      {
        value: 'A714',
        label: 'BATTAGLIA o BATTAGLIA TERME',
      },
      {
        value: 'A906',
        label: 'BOARA o BOARA PISANI',
      },
      {
        value: 'M402',
        label: 'BORGO VENETO',
      },
      {
        value: 'B031',
        label: "BORGORICCO o SANT'EUFEMIA",
      },
      {
        value: 'B106',
        label: 'BOVOLENTA',
      },
      {
        value: 'B213',
        label: 'BRUGINE',
      },
      {
        value: 'B345',
        label: 'CADONEGHE',
      },
      {
        value: 'B564',
        label: 'CAMPO SAN MARTINO',
      },
      {
        value: 'B524',
        label: 'CAMPODARSEGO',
      },
      {
        value: 'B531',
        label: 'CAMPODORO',
      },
      {
        value: 'B563',
        label: 'CAMPOSAMPIERO',
      },
      {
        value: 'B589',
        label: 'CANDIANA o PONTE CASALE',
      },
      {
        value: 'B749',
        label: 'CARCERI',
      },
      {
        value: 'B795',
        label: 'CARMIGNANO o CARMIGNANO DI BRENTA',
      },
      {
        value: 'B833',
        label: 'CARRARA SAN GIORGIO',
      },
      {
        value: 'B834',
        label: 'CARRARA SANTO STEFANO',
      },
      {
        value: 'B848',
        label: 'CARTURA',
      },
      {
        value: 'B912',
        label: 'CASAL SER UGO o CASALSERUGO',
      },
      {
        value: 'B877',
        label: 'CASALE o CASALE DI SCODOSIA',
      },
      {
        value: 'C057',
        label: 'CASTELBALDO',
      },
      {
        value: 'C544',
        label: 'CERVARESE SANTA CROCE',
      },
      {
        value: 'C713',
        label: 'CINTO o CINTO EUGANEO',
      },
      {
        value: 'C743',
        label: 'CITTADELLA',
      },
      {
        value: 'C812',
        label: 'CODEVIGO',
      },
      {
        value: 'C964',
        label: 'CONSELVE',
      },
      {
        value: 'D040',
        label: 'CORREZZOLA',
      },
      {
        value: 'D226',
        label: 'CURTAROLO',
      },
      {
        value: 'M300',
        label: 'DUE CARRARE',
      },
      {
        value: 'D442',
        label: 'ESTE',
      },
      {
        value: 'D679',
        label: 'FONTANIVA',
      },
      {
        value: 'D879',
        label: 'GALLIERA o GALLIERA VENETA',
      },
      {
        value: 'D889',
        label: 'GALZIGNANO o GALZIGNANO TERME',
      },
      {
        value: 'D956',
        label: 'GAZZO o GAZZO PADOVANO',
      },
      {
        value: 'E145',
        label: 'GRANTORTO',
      },
      {
        value: 'E146',
        label: 'GRANZE',
      },
      {
        value: 'E515',
        label: 'LEGNARO',
      },
      {
        value: 'E592',
        label: 'LIMENA',
      },
      {
        value: 'E684',
        label: 'LOREGGIA',
      },
      {
        value: 'E709',
        label: 'LOZZO o LOZZO ATESTINO',
      },
      {
        value: 'F011',
        label: "MASERA' o MASERA' DI PADOVA",
      },
      {
        value: 'F013',
        label: 'MASI',
      },
      {
        value: 'F033',
        label: 'MASSANZAGO',
      },
      {
        value: 'F091',
        label: 'MEGLIADINO SAN FIDENZIO',
      },
      {
        value: 'F092',
        label: 'MEGLIADINO SAN VITALE',
      },
      {
        value: 'F148',
        label: 'MERLARA',
      },
      {
        value: 'F161',
        label: 'MESTRINO',
      },
      {
        value: 'F382',
        label: 'MONSELICE',
      },
      {
        value: 'F394',
        label: 'MONTAGNANA',
      },
      {
        value: 'F529',
        label: 'MONTEGROTTO TERME o SAN PIETRO MONTAGNON',
      },
      {
        value: 'F962',
        label: 'NOVENTA o NOVENTA PADOVANA',
      },
      {
        value: 'G167',
        label: 'OSPEDALETTO o OSPEDALETTO EUGANEO',
      },
      {
        value: 'G224',
        label: 'PADOVA',
      },
      {
        value: 'G461',
        label: 'PERNUMIA',
      },
      {
        value: 'G534',
        label: "PIACENZA o PIACENZA D'ADIGE",
      },
      {
        value: 'G587',
        label: 'PIAZZOLA o PIAZZOLA SUL BRENTA',
      },
      {
        value: 'G688',
        label: 'PIOMBINO o PIOMBINO DESE',
      },
      {
        value: 'G693',
        label: 'PIOVE DI SACCO',
      },
      {
        value: 'G802',
        label: 'POLVERARA',
      },
      {
        value: 'G823',
        label: 'PONSO',
      },
      {
        value: 'G855',
        label: "PONTE SAN NICOLO'",
      },
      {
        value: 'G850',
        label: 'PONTELONGO',
      },
      {
        value: 'G963',
        label: 'POZZONOVO',
      },
      {
        value: 'H622',
        label: 'ROVOLON',
      },
      {
        value: 'H625',
        label: 'RUBANO',
      },
      {
        value: 'H655',
        label: 'SACCOLONGO',
      },
      {
        value: 'H705',
        label: 'SALETTO',
      },
      {
        value: 'H893',
        label: 'SAN GIORGIO DELLE PERTICHE',
      },
      {
        value: 'H897',
        label: 'SAN GIORGIO IN BOSCO',
      },
      {
        value: 'I008',
        label: 'SAN MARTINO DI LUPARI',
      },
      {
        value: 'I043',
        label: 'SAN MICHELE DELLE BADESSE',
      },
      {
        value: 'I107',
        label: 'SAN PIETRO ENGU o SAN PIETRO IN GU',
      },
      {
        value: 'I120',
        label: 'SAN PIETRO VIMINARIO',
      },
      {
        value: 'I275',
        label: "SANT'ANGELO o SANT'ANGELO DI PIOVE DI SACCO",
      },
      {
        value: 'I319',
        label: "SANT'ELENA",
      },
      {
        value: 'I375',
        label: "SANT'URBANO",
      },
      {
        value: 'I207',
        label: 'SANTA GIUSTINA IN COLLE',
      },
      {
        value: 'I226',
        label: "SANTA MARGHERITA o SANTA MARGHERITA D'ADIGE",
      },
      {
        value: 'I418',
        label: 'SAONARA',
      },
      {
        value: 'I595',
        label: 'SELVAZZANO DENTRO',
      },
      {
        value: 'I799',
        label: 'SOLESINO',
      },
      {
        value: 'I938',
        label: 'STANGHELLA',
      },
      {
        value: 'L100',
        label: 'TEOLO',
      },
      {
        value: 'L132',
        label: 'TERRASSA o TERRASSA PADOVANA',
      },
      {
        value: 'L199',
        label: 'TOMBOLO',
      },
      {
        value: 'L270',
        label: 'TORREGLIA',
      },
      {
        value: 'L349',
        label: 'TREBASELEGHE',
      },
      {
        value: 'L414',
        label: 'TRIBANO',
      },
      {
        value: 'L497',
        label: 'URBANA',
      },
      {
        value: 'L710',
        label: 'VEGGIANO',
      },
      {
        value: 'L805',
        label: 'VESCOVANA',
      },
      {
        value: 'L878',
        label: "VIGHIZZOLO o VIGHIZZOLO D'ESTE",
      },
      {
        value: 'L892',
        label: 'VIGODARZERE',
      },
      {
        value: 'L900',
        label: 'VIGONZA',
      },
      {
        value: 'L934',
        label: 'VILLA DEL CONTE',
      },
      {
        value: 'L937',
        label: 'VILLA DI VILLA o VILLA ESTENSE',
      },
      {
        value: 'L947',
        label: 'VILLAFRANCA o VILLAFRANCA PADOVANA',
      },
      {
        value: 'L979',
        label: 'VILLANOVA o VILLANOVA DI CAMPOSAMPIERO',
      },
      {
        value: 'M103',
        label: "VO o VO'",
      },
    ],
  },

  {
    value: 'PE',
    label: 'Pescara',
    children: [
      {
        value: 'A008',
        label: 'ABBATEGGIO',
      },
      {
        value: 'A120',
        label: 'ALANNO',
      },
      {
        value: 'A945',
        label: 'BOLOGNANO',
      },
      {
        value: 'B193',
        label: 'BRITTOLI',
      },
      {
        value: 'B681',
        label: 'CAPPELLE o CAPPELLE SUL TAVO',
      },
      {
        value: 'B722',
        label: 'CARAMANICO o CARAMANICO TERME',
      },
      {
        value: 'B827',
        label: 'CARPINETO o CARPINETO DELLA NORA',
      },
      {
        value: 'C132',
        label: 'CASTELLAMMARE o CASTELLAMMARE ADRIATICO',
      },
      {
        value: 'C308',
        label: 'CASTIGLIONE A CASAURIA o CASTIGLIONE ALLA PESCARA',
      },
      {
        value: 'C354',
        label: 'CATIGNANO',
      },
      {
        value: 'C474',
        label: 'CEPAGATTI',
      },
      {
        value: 'C750',
        label: "CITTA' SANT'ANGELO",
      },
      {
        value: 'C771',
        label: 'CIVITAQUANA',
      },
      {
        value: 'C779',
        label: 'CIVITELLA CASANOVA',
      },
      {
        value: 'C853',
        label: 'COLLECORVINO',
      },
      {
        value: 'D078',
        label: 'CORVARA',
      },
      {
        value: 'D201',
        label: 'CUGNOLI',
      },
      {
        value: 'D394',
        label: 'ELICE',
      },
      {
        value: 'D501',
        label: 'FARINDOLA',
      },
      {
        value: 'E558',
        label: 'LETTOMANOPPELLO',
      },
      {
        value: 'E691',
        label: 'LORETO o LORETO APRUTINO',
      },
      {
        value: 'E892',
        label: 'MANOPPELLO',
      },
      {
        value: 'F441',
        label: 'MONTEBELLO o MONTEBELLO DI BERTONA',
      },
      {
        value: 'F646',
        label: 'MONTESILVANO',
      },
      {
        value: 'F647',
        label: 'MONTESILVANO MARINA',
      },
      {
        value: 'F765',
        label: 'MOSCUFO',
      },
      {
        value: 'F823',
        label: 'MUSELLARO',
      },
      {
        value: 'F908',
        label: 'NOCCIANO',
      },
      {
        value: 'G438',
        label: 'PENNE',
      },
      {
        value: 'G482',
        label: 'PESCARA',
      },
      {
        value: 'G499',
        label: 'PESCOSANSONESCO',
      },
      {
        value: 'G555',
        label: 'PIANELLA',
      },
      {
        value: 'G589',
        label: 'PICCIANO',
      },
      {
        value: 'G621',
        label: 'PIETRANICO',
      },
      {
        value: 'G878',
        label: 'POPOLI',
      },
      {
        value: 'H388',
        label: 'ROCCACARAMANICO',
      },
      {
        value: 'H425',
        label: 'ROCCAMORICE',
      },
      {
        value: 'H562',
        label: 'ROSCIANO',
      },
      {
        value: 'H715',
        label: 'SALLE',
      },
      {
        value: 'I482',
        label: 'SCAFA',
      },
      {
        value: 'I649',
        label: 'SERRAMONACESCA',
      },
      {
        value: 'I922',
        label: 'SPOLTORE',
      },
      {
        value: 'L263',
        label: "TORRE DE' PASSERI",
      },
      {
        value: 'L475',
        label: 'TURRIVALIGNANI',
      },
      {
        value: 'L846',
        label: 'VICOLI',
      },
      {
        value: 'L922',
        label: 'VILLA CELIERA',
      },
    ],
  },

  {
    value: 'PG',
    label: 'Perugia',
    children: [
      {
        value: 'A475',
        label: 'ASSISI',
      },
      {
        value: 'A710',
        label: 'BASTIA UMBRA',
      },
      {
        value: 'A832',
        label: 'BETTONA',
      },
      {
        value: 'A835',
        label: 'BEVAGNA',
      },
      {
        value: 'A913',
        label: 'BOCCHIGNANO',
      },
      {
        value: 'B504',
        label: 'CAMPELLO o CAMPELLO SUL CLITUNNO',
      },
      {
        value: 'B609',
        label: 'CANNARA',
      },
      {
        value: 'B659',
        label: 'CAPITONE',
      },
      {
        value: 'B797',
        label: 'CARNAIOLA',
      },
      {
        value: 'B948',
        label: 'CASCIA',
      },
      {
        value: 'C092',
        label: 'CASTEL DI LAGO',
      },
      {
        value: 'C252',
        label: 'CASTEL RITALDI o CASTEL RITALDI E SAN GIOVANNI',
      },
      {
        value: 'C257',
        label: 'CASTEL SAN BENEDETTO REATINO o SAN BENEDETTO',
      },
      {
        value: 'C258',
        label: 'CASTEL SAN FELICE',
      },
      {
        value: 'C260',
        label: 'CASTEL SAN GIOVANNI o CASTEL SAN GIOVANNI DI SPOLETO',
      },
      {
        value: 'C098',
        label: 'CASTELVECCHIO o CASTEL DI TORA',
      },
      {
        value: 'C309',
        label: 'CASTIGLIONE DEL LAGO',
      },
      {
        value: 'C527',
        label: 'CERRETO o CERRETO DI SPOLETO',
      },
      {
        value: 'C571',
        label: 'CESELLI',
      },
      {
        value: 'C575',
        label: 'CESI',
      },
      {
        value: 'C742',
        label: 'CITERNA',
      },
      {
        value: 'C744',
        label: "CITTA' DELLA PIEVE",
      },
      {
        value: 'C745',
        label: "CITTA' DI CASTELLO",
      },
      {
        value: 'C841',
        label: 'COLLALTO o COLLALTO SABINO',
      },
      {
        value: 'C845',
        label: 'COLLAZZONE',
      },
      {
        value: 'C849',
        label: 'COLLEBACCARO',
      },
      {
        value: 'C863',
        label: 'COLLEMANCIO',
      },
      {
        value: 'C990',
        label: 'CORCIANO',
      },
      {
        value: 'D108',
        label: 'COSTACCIARO',
      },
      {
        value: 'D279',
        label: 'DERUTA',
      },
      {
        value: 'D653',
        label: 'FOLIGNO',
      },
      {
        value: 'D745',
        label: 'FOSSATO o FOSSATO DI VICO',
      },
      {
        value: 'D786',
        label: 'FRATTA o UMBERTIDE',
      },
      {
        value: 'D787',
        label: 'FRATTA o FRATTA TODINA',
      },
      {
        value: 'E012',
        label: "GIANO o GIANO DELL'UMBRIA",
      },
      {
        value: 'E229',
        label: 'GUALDO CATTANEO',
      },
      {
        value: 'E230',
        label: 'GUALDO TADINO',
      },
      {
        value: 'E256',
        label: 'GUBBIO',
      },
      {
        value: 'E613',
        label: 'LISCIANO o LISCIANO NICCONE',
      },
      {
        value: 'E805',
        label: 'MAGIONE',
      },
      {
        value: 'E975',
        label: 'MARSCIANO',
      },
      {
        value: 'F024',
        label: 'MASSA o MASSA MARTANA',
      },
      {
        value: 'F090',
        label: 'MEGGIANO o PIEDIPATERNO SUL NERA',
      },
      {
        value: 'F456',
        label: 'MONTE CASTELLO o MONTE CASTELLO DI VIBIO',
      },
      {
        value: 'F619',
        label: 'MONTE SAN GIOVANNI o MONTE SAN GIOVANNI IN SABINA',
      },
      {
        value: 'F630',
        label: 'MONTE SANTA MARIA o MONTE SANTA MARIA IN SABINA',
      },
      {
        value: 'F492',
        label: 'MONTEFALCO',
      },
      {
        value: 'F521',
        label: 'MONTEGIOVE',
      },
      {
        value: 'F540',
        label: 'MONTELEONE o MONTELEONE DI SPOLETO',
      },
      {
        value: 'F613',
        label: 'MONTERUBBIAGLIO',
      },
      {
        value: 'F633',
        label: 'MONTESANTO o MONTESANTO VIEZI o MONTESANTO VIGI',
      },
      {
        value: 'F685',
        label: 'MONTONE',
      },
      {
        value: 'F911',
        label: 'NOCERA o NOCERA UMBRA',
      },
      {
        value: 'F935',
        label: 'NORCIA',
      },
      {
        value: 'G038',
        label: 'OLIVETO o OLIVETO IN SABINA',
      },
      {
        value: 'G212',
        label: 'PACIANO',
      },
      {
        value: 'G308',
        label: 'PANICALE',
      },
      {
        value: 'G351',
        label: 'PASCELUPO',
      },
      {
        value: 'G359',
        label: 'PASSIGNANO o PASSIGNANO SUL TRASIMENO',
      },
      {
        value: 'G478',
        label: 'PERUGIA',
      },
      {
        value: 'G601',
        label: 'PIEGARO',
      },
      {
        value: 'G618',
        label: 'PIETRALUNGA',
      },
      {
        value: 'G758',
        label: 'POGGIODOMO',
      },
      {
        value: 'G898',
        label: 'PORTARIA',
      },
      {
        value: 'G938',
        label: 'POSTICCIOLA',
      },
      {
        value: 'G951',
        label: 'POZZAGLIA o POZZAGLIA SABINA',
      },
      {
        value: 'H015',
        label: 'PRECI',
      },
      {
        value: 'H469',
        label: 'ROCCHETTE IN SABINA',
      },
      {
        value: 'H925',
        label: 'SAN GIOVANNI REATINO',
      },
      {
        value: 'H935',
        label: 'SAN GIUSTINO',
      },
      {
        value: 'I398',
        label: 'SAN VITO o SAN VITO IN MONTE',
      },
      {
        value: 'I263',
        label: "SANT'ANATOLIA o SANT'ANATOLIA DI NARCO",
      },
      {
        value: 'I323',
        label: "SANT'ELIA o SANT'ELIA REATINO",
      },
      {
        value: 'I521',
        label: 'SCHEGGIA',
      },
      {
        value: 'I522',
        label: 'SCHEGGIA E PASCELUPO',
      },
      {
        value: 'I523',
        label: 'SCHEGGINO',
      },
      {
        value: 'I585',
        label: 'SELLANO',
      },
      {
        value: 'I727',
        label: 'SIGILLO',
      },
      {
        value: 'I888',
        label: 'SPELLO',
      },
      {
        value: 'I921',
        label: 'SPOLETO',
      },
      {
        value: 'L188',
        label: 'TODI',
      },
      {
        value: 'L216',
        label: 'TORGIANO',
      },
      {
        value: 'L397',
        label: 'TREVI',
      },
      {
        value: 'L466',
        label: 'TUORO o TUORO SUL TRASIMENO',
      },
      {
        value: 'L573',
        label: 'VALFABBRICA',
      },
      {
        value: 'L627',
        label: 'VALLO o VALLO DI NERA',
      },
      {
        value: 'L653',
        label: 'VALTOPINA',
      },
      {
        value: 'L676',
        label: 'VARCO o VARCO SABINO',
      },
    ],
  },

  {
    value: 'PI',
    label: 'Pisa',
    children: [
      {
        value: 'A559',
        label: 'BAGNI DI CASCIANA o CASCIANA TERME',
      },
      {
        value: 'A562',
        label: 'BAGNI SAN GIULIANO o SAN GIULIANO TERME',
      },
      {
        value: 'A864',
        label: 'BIENTINA',
      },
      {
        value: 'B303',
        label: 'BUTI',
      },
      {
        value: 'B389',
        label: 'CALCI o CALCERANICA AL LAGO',
      },
      {
        value: 'B647',
        label: 'CAPANNOLI',
      },
      {
        value: 'B878',
        label: 'CASALE o CASALE DI VAL DI CECINA o CASALE MARITTIMO',
      },
      {
        value: 'M327',
        label: 'CASCIANA TERME LARI',
      },
      {
        value: 'B950',
        label: 'CASCINA',
      },
      {
        value: 'C113',
        label: 'CASTELFRANCO DI SOTTO',
      },
      {
        value: 'C174',
        label: 'CASTELLINA MARITTIMA',
      },
      {
        value: 'C244',
        label: 'CASTELNUOVO DI VAL DI CECINA o CASTELNUOVO VAL DI CECINA',
      },
      {
        value: 'C609',
        label: 'CHIANNI',
      },
      {
        value: 'D160',
        label: 'CRESPINA',
      },
      {
        value: 'M328',
        label: 'CRESPINA LORENZANA',
      },
      {
        value: 'D510',
        label: 'FAUGLIA',
      },
      {
        value: 'E250',
        label: 'GUARDISTALLO',
      },
      {
        value: 'E413',
        label: 'LAJATICO',
      },
      {
        value: 'E455',
        label: 'LARI',
      },
      {
        value: 'E688',
        label: 'LORENZANA',
      },
      {
        value: 'F458',
        label: 'MONTECATINI VAL DI CECINA',
      },
      {
        value: 'F640',
        label: 'MONTESCUDAIO',
      },
      {
        value: 'F661',
        label: 'MONTEVERDI o MONTEVERDI MARITTIMO',
      },
      {
        value: 'F686',
        label: "MONTOPOLI o MONTOPOLI IN VAL D'ARNO",
      },
      {
        value: 'G090',
        label: 'ORCIANO PISANO',
      },
      {
        value: 'G254',
        label: 'PALAIA',
      },
      {
        value: 'G395',
        label: 'PECCIOLI',
      },
      {
        value: 'G702',
        label: 'PISA',
      },
      {
        value: 'G804',
        label: 'POMARANCE',
      },
      {
        value: 'G822',
        label: 'PONSACCO',
      },
      {
        value: 'G843',
        label: 'PONTEDERA',
      },
      {
        value: 'H319',
        label: 'RIPARBELLA',
      },
      {
        value: 'I046',
        label: 'SAN MINIATO',
      },
      {
        value: 'I217',
        label: 'SANTA LUCE',
      },
      {
        value: 'I218',
        label: 'SANTA LUCE-ORCIANO',
      },
      {
        value: 'I232',
        label: 'SANTA MARIA A MONTE',
      },
      {
        value: 'L138',
        label: 'TERRICCIOLA',
      },
      {
        value: 'L702',
        label: 'VECCHIANO',
      },
      {
        value: 'L850',
        label: 'VICOPISANO',
      },
      {
        value: 'M126',
        label: 'VOLTERRA',
      },
    ],
  },

  {
    value: 'PN',
    label: 'Pordenone',
    children: [
      {
        value: 'A283',
        label: 'ANDREIS',
      },
      {
        value: 'A354',
        label: 'ARBA',
      },
      {
        value: 'A456',
        label: 'ARZENE',
      },
      {
        value: 'A516',
        label: 'AVIANO',
      },
      {
        value: 'A530',
        label: 'AZZANO DECIMO o AZZANO',
      },
      {
        value: 'A640',
        label: 'BARCIS',
      },
      {
        value: 'B215',
        label: 'BRUGNERA',
      },
      {
        value: 'B247',
        label: 'BUDOIA',
      },
      {
        value: 'B598',
        label: 'CANEVA',
      },
      {
        value: 'B940',
        label: 'CASARSA DELLA DELIZIA o CASARSA',
      },
      {
        value: 'C217',
        label: 'CASTELNOVO DEL FRIULI o CASTELNOVO',
      },
      {
        value: 'C385',
        label: 'CAVASSO NUOVO o CAVASSO',
      },
      {
        value: 'C640',
        label: 'CHIONS',
      },
      {
        value: 'C699',
        label: 'CIMOLAIS',
      },
      {
        value: 'C790',
        label: 'CLAUT',
      },
      {
        value: 'C791',
        label: 'CLAUZETTO',
      },
      {
        value: 'C991',
        label: 'CORDENONS',
      },
      {
        value: 'C993',
        label: 'CORDOVADO',
      },
      {
        value: 'D426',
        label: 'ERTO E CASSO',
      },
      {
        value: 'D487',
        label: 'FANNA',
      },
      {
        value: 'D621',
        label: 'FIUME VENETO o FIUME',
      },
      {
        value: 'D670',
        label: 'FONTANAFREDDA',
      },
      {
        value: 'D804',
        label: 'FRISANCO',
      },
      {
        value: 'E889',
        label: 'MANIAGO',
      },
      {
        value: 'F089',
        label: 'MEDUNO',
      },
      {
        value: 'F596',
        label: 'MONTEREALE VALCELLINA o MONTEREALE o MONTEREALE CELLINA',
      },
      {
        value: 'F750',
        label: 'MORSANO AL TAGLIAMENTO',
      },
      {
        value: 'G353',
        label: 'PASIANO DI PORDENONE',
      },
      {
        value: 'G680',
        label: 'PINZANO AL TAGLIAMENTO o PINZANO',
      },
      {
        value: 'G780',
        label: 'POLCENIGO',
      },
      {
        value: 'G886',
        label: 'PORCIA',
      },
      {
        value: 'G888',
        label: 'PORDENONE',
      },
      {
        value: 'G994',
        label: 'PRATA DI PORDENONE o PRATA',
      },
      {
        value: 'H010',
        label: 'PRAVISDOMINI',
      },
      {
        value: 'H609',
        label: 'ROVEREDO IN PIANO o ROVEREDO',
      },
      {
        value: 'H657',
        label: 'SACILE',
      },
      {
        value: 'H891',
        label: 'SAN GIORGIO DELLA RICHINVELDA o SAN GIORGIO',
      },
      {
        value: 'H999',
        label: 'SAN MARTINO AL TAGLIAMENTO o SAN MARTINO',
      },
      {
        value: 'I136',
        label: 'SAN QUIRINO',
      },
      {
        value: 'I403',
        label: 'SAN VITO AL TAGLIAMENTO',
      },
      {
        value: 'I621',
        label: 'SEQUALS',
      },
      {
        value: 'I686',
        label: 'SESTO AL REGHENA o SESTO',
      },
      {
        value: 'I904',
        label: 'SPILIMBERGO',
      },
      {
        value: 'L324',
        label: 'TRAMONTI DI SOPRA',
      },
      {
        value: 'L325',
        label: 'TRAMONTI DI SOTTO',
      },
      {
        value: 'L347',
        label: 'TRAVESIO',
      },
      {
        value: 'M265',
        label: 'VAJONT',
      },
      {
        value: 'L657',
        label: 'VALVASONE',
      },
      {
        value: 'M346',
        label: 'VALVASONE ARZENE',
      },
      {
        value: 'M085',
        label: "VITO D'ASIO",
      },
      {
        value: 'M096',
        label: 'VIVARO',
      },
      {
        value: 'M190',
        label: 'ZOPPOLA',
      },
    ],
  },

  {
    value: 'PO',
    label: 'Prato',
    children: [
      {
        value: 'B626',
        label: 'CANTAGALLO',
      },
      {
        value: 'B794',
        label: 'CARMIGNANO',
      },
      {
        value: 'F572',
        label: 'MONTEMURLO',
      },
      {
        value: 'G754',
        label: 'POGGIO A CAIANO',
      },
      {
        value: 'L537',
        label: 'VAIANO',
      },
      {
        value: 'L775',
        label: 'VERNIO',
      },
    ],
  },

  {
    value: 'PR',
    label: 'Parma',
    children: [
      {
        value: 'A138',
        label: 'ALBARETO o ALBARETO DI BORGOTARO',
      },
      {
        value: 'A646',
        label: 'BARDI',
      },
      {
        value: 'A731',
        label: 'BEDONIA',
      },
      {
        value: 'A788',
        label: 'BERCETO',
      },
      {
        value: 'A915',
        label: 'BOCCOLO DEI TASSI',
      },
      {
        value: 'A987',
        label: 'BORE o METTI E POZZOLO',
      },
      {
        value: 'B034',
        label: 'BORGO SAN DONNINO o FIDENZA',
      },
      {
        value: 'B042',
        label: 'BORGO VAL DI TARO o BORGOTARO',
      },
      {
        value: 'B293',
        label: 'BUSSETO',
      },
      {
        value: 'B407',
        label: 'CALESTANO',
      },
      {
        value: 'C852',
        label: 'COLLECCHIO',
      },
      {
        value: 'C904',
        label: 'COLORNO',
      },
      {
        value: 'C934',
        label: 'COMPIANO',
      },
      {
        value: 'D026',
        label: 'CORNIGLIO',
      },
      {
        value: 'D074',
        label: 'CORTILE SAN MARTINO',
      },
      {
        value: 'D526',
        label: 'FELINO',
      },
      {
        value: 'D673',
        label: 'FONTANELLATO',
      },
      {
        value: 'D685',
        label: 'FONTEVIVO',
      },
      {
        value: 'D728',
        label: 'FORNOVO o FORNOVO DI TARO',
      },
      {
        value: 'E080',
        label: 'GOLESE',
      },
      {
        value: 'E438',
        label: 'LANGHIRANO',
      },
      {
        value: 'E547',
        label: "LESIGNANO DE' BAGNI",
      },
      {
        value: 'E548',
        label: 'LESIGNANO DI PALMIA o TERENZO',
      },
      {
        value: 'E969',
        label: 'MARORE',
      },
      {
        value: 'F082',
        label: 'MEDESANO',
      },
      {
        value: 'F174',
        label: 'MEZZANI',
      },
      {
        value: 'F340',
        label: 'MONCHIO o MONCHIO DELLE CORTI',
      },
      {
        value: 'F473',
        label: 'MONTECHIARUGOLO',
      },
      {
        value: 'F882',
        label: 'NEVIANO DEGLI ARDUINI',
      },
      {
        value: 'F914',
        label: 'NOCETO',
      },
      {
        value: 'G255',
        label: 'PALANZANO',
      },
      {
        value: 'G337',
        label: 'PARMA',
      },
      {
        value: 'G424',
        label: 'PELLEGRINO o PELLEGRINO PARMENSE',
      },
      {
        value: 'G689',
        label: 'PIONE',
      },
      {
        value: 'G783',
        label: 'POLESINE o POLESINE PARMENSE',
      },
      {
        value: 'M367',
        label: 'POLESINE ZIBELLO',
      },
      {
        value: 'H384',
        label: 'ROCCABIANCA',
      },
      {
        value: 'H682',
        label: 'SALA o SALA BAGANZA',
      },
      {
        value: 'H720',
        label: 'SALSOMAGGIORE o SALSOMAGGIORE TERME',
      },
      {
        value: 'H828',
        label: "SAN DONATO o SAN DONATO D'ENZA",
      },
      {
        value: 'H948',
        label: 'SAN LAZZARO PARMENSE',
      },
      {
        value: 'I015',
        label: 'SAN MARTINO SINZANO',
      },
      {
        value: 'I068',
        label: 'SAN PANCRAZIO o SAN PANCRAZIO PARMENSE',
      },
      {
        value: 'I153',
        label: 'SAN SECONDO o SAN SECONDO PARMENSE',
      },
      {
        value: 'I763',
        label: 'SISSA',
      },
      {
        value: 'M325',
        label: 'SISSA TRECASALI',
      },
      {
        value: 'I803',
        label: 'SOLIGNANO',
      },
      {
        value: 'I840',
        label: 'SORAGNA',
      },
      {
        value: 'I845',
        label: 'SORBOLO',
      },
      {
        value: 'M411',
        label: 'SORBOLO MEZZANI',
      },
      {
        value: 'L183',
        label: 'TIZZANO o TIZZANO VAL PARMA',
      },
      {
        value: 'L229',
        label: 'TORNOLO',
      },
      {
        value: 'L299',
        label: 'TORRILE',
      },
      {
        value: 'L346',
        label: 'TRAVERSETOLO',
      },
      {
        value: 'L354',
        label: 'TRECASALI',
      },
      {
        value: 'L641',
        label: 'VALMOZZOLA',
      },
      {
        value: 'L672',
        label: "VARANO DE' MELEGARI",
      },
      {
        value: 'L689',
        label: 'VARSI',
      },
      {
        value: 'L870',
        label: 'VIGATTO',
      },
      {
        value: 'M174',
        label: 'ZIBELLO',
      },
    ],
  },

  {
    value: 'PT',
    label: 'Pistoia',
    children: [
      {
        value: 'A012',
        label: 'ABETONE',
      },
      {
        value: 'M376',
        label: 'ABETONE CUTIGLIANO',
      },
      {
        value: 'A071',
        label: 'AGLIANA',
      },
      {
        value: 'A561',
        label: 'BAGNI DI MONTECATINI o MONTECATINI TERME o MONTECATINI-TERME',
      },
      {
        value: 'B251',
        label: 'BUGGIANO',
      },
      {
        value: 'C631',
        label: 'CHIESINA UZZANESE',
      },
      {
        value: 'D235',
        label: 'CUTIGLIANO',
      },
      {
        value: 'E432',
        label: 'LAMPORECCHIO',
      },
      {
        value: 'E451',
        label: 'LARCIANO',
      },
      {
        value: 'E960',
        label: 'MARLIANA',
      },
      {
        value: 'F025',
        label: 'MASSA E COZZILE',
      },
      {
        value: 'F384',
        label: 'MONSUMMANO o MONSUMMANO TERME',
      },
      {
        value: 'F410',
        label: 'MONTALE',
      },
      {
        value: 'F459',
        label: 'MONTECATINI VAL DI NIEVOLE',
      },
      {
        value: 'G491',
        label: 'PESCIA',
      },
      {
        value: 'G636',
        label: 'PIEVE A NIEVOLE',
      },
      {
        value: 'G713',
        label: 'PISTOIA',
      },
      {
        value: 'G715',
        label: 'PITEGLIO',
      },
      {
        value: 'G833',
        label: 'PONTE BUGGIANESE',
      },
      {
        value: 'H109',
        label: 'QUARRATA o TIZZANA',
      },
      {
        value: 'M377',
        label: 'SAN MARCELLO PITEGLIO',
      },
      {
        value: 'L522',
        label: 'UZZANO',
      },
      {
        value: 'L717',
        label: 'VELLANO',
      },
    ],
  },

  {
    value: 'PU',
    label: 'Pesaro e Urbino',
    children: [
      {
        value: 'A035',
        label: 'ACQUALAGNA',
      },
      {
        value: 'A327',
        label: 'APECCHIO',
      },
      {
        value: 'A493',
        label: 'AUDITORE',
      },
      {
        value: 'A639',
        label: 'BARCHI',
      },
      {
        value: 'A740',
        label: "BELFORTE o BELFORTE ALL'ISAURO",
      },
      {
        value: 'B026',
        label: 'BORGO PACE',
      },
      {
        value: 'B352',
        label: 'CAGLI',
      },
      {
        value: 'B585',
        label: 'CANDELARA',
      },
      {
        value: 'B636',
        label: 'CANTIANO',
      },
      {
        value: 'B816',
        label: 'CARPEGNA',
      },
      {
        value: 'B846',
        label: 'CARTOCETO',
      },
      {
        value: 'C080',
        label: 'CASTELDELCI o CASTELDELCI',
      },
      {
        value: 'C095',
        label: 'CASTELDIMEZZO',
      },
      {
        value: 'C830',
        label: 'COLBORDOLO',
      },
      {
        value: 'M380',
        label: 'COLLI AL METAURO',
      },
      {
        value: 'D488',
        label: 'FANO',
      },
      {
        value: 'D534',
        label: 'FENIGLI',
      },
      {
        value: 'D541',
        label: 'FERMIGNANO',
      },
      {
        value: 'D610',
        label: 'FIORENZUOLA o FIORENZUOLA DI FOCARA',
      },
      {
        value: 'D749',
        label: 'FOSSOMBRONE',
      },
      {
        value: 'D791',
        label: 'FRATTE o FRATTE ROSA',
      },
      {
        value: 'D807',
        label: 'FRONTINO',
      },
      {
        value: 'D808',
        label: 'FRONTONE',
      },
      {
        value: 'D809',
        label: 'FRONTONE-SERRA',
      },
      {
        value: 'D836',
        label: 'GABICCE o GABICCE MARE',
      },
      {
        value: 'E035',
        label: 'GINESTRETO',
      },
      {
        value: 'E122',
        label: 'GRADARA',
      },
      {
        value: 'E351',
        label: 'ISOLA DEL PIANO',
      },
      {
        value: 'E352',
        label: 'ISOLA DI FANO',
      },
      {
        value: 'E743',
        label: 'LUNANO',
      },
      {
        value: 'E785',
        label: 'MACERATA FELTRIA',
      },
      {
        value: 'E838',
        label: 'MAIOLO o MAIOLO',
      },
      {
        value: 'F135',
        label: 'MERCATELLO o MERCATELLO SUL METAURO',
      },
      {
        value: 'F136',
        label: 'MERCATINO CONCA o PIAN DI CASTELLO',
      },
      {
        value: 'F137',
        label: 'MERCATINO MARECCHIA o NOVAFELTRIA o NOVAFELTRIA',
      },
      {
        value: 'F310',
        label: 'MOMBAROCCIO',
      },
      {
        value: 'F347',
        label: 'MONDAVIO',
      },
      {
        value: 'F348',
        label: 'MONDOLFO',
      },
      {
        value: 'F413',
        label: 'MONTALFOGLIO',
      },
      {
        value: 'F418',
        label: 'MONTALTO o MONTALTO TARUGO',
      },
      {
        value: 'F467',
        label: 'MONTE CERIGNONE',
      },
      {
        value: 'F524',
        label: 'MONTE GRIMANO o MONTE GRIMANO TERME',
      },
      {
        value: 'F571',
        label: 'MONTE MONTANARO',
      },
      {
        value: 'F589',
        label: 'MONTE PORZIO',
      },
      {
        value: 'F439',
        label: 'MONTEBELLO o MONTEBELLO METAURENSE',
      },
      {
        value: 'F450',
        label: 'MONTECALVO o MONTECALVO IN FOGLIA',
      },
      {
        value: 'F474',
        label: 'MONTECICCARDO',
      },
      {
        value: 'F478',
        label: 'MONTECOPIOLO',
      },
      {
        value: 'F490',
        label: 'MONTEFABBRI',
      },
      {
        value: 'F497',
        label: 'MONTEFELCINO',
      },
      {
        value: 'F530',
        label: 'MONTEGUIDUCCIO',
      },
      {
        value: 'F533',
        label: 'MONTELABBATE',
      },
      {
        value: 'F554',
        label: 'MONTEMAGGIORE',
      },
      {
        value: 'F555',
        label: 'MONTEMAGGIORE o MONTEMAGGIORE AL METAURO',
      },
      {
        value: 'F602',
        label: 'MONTEROLO',
      },
      {
        value: 'F643',
        label: 'MONTESECCO o MONTESECCO ANTICO',
      },
      {
        value: 'F658',
        label: 'MONTEVECCHIO',
      },
      {
        value: 'F969',
        label: 'NOVILARA',
      },
      {
        value: 'G089',
        label: 'ORCIANO DI PESARO',
      },
      {
        value: 'G416',
        label: 'PEGLIO',
      },
      {
        value: 'G433',
        label: 'PENNABILLI o PENNABILLI',
      },
      {
        value: 'G453',
        label: 'PERGOLA',
      },
      {
        value: 'G479',
        label: 'PESARO',
      },
      {
        value: 'G514',
        label: 'PETRIANO',
      },
      {
        value: 'G537',
        label: 'PIAGGE',
      },
      {
        value: 'G551',
        label: 'PIANDIMELETO',
      },
      {
        value: 'G627',
        label: 'PIETRARUBBIA',
      },
      {
        value: 'G682',
        label: 'PIOBBICO',
      },
      {
        value: 'G956',
        label: 'POZZO o POZZO ALTO',
      },
      {
        value: 'H721',
        label: 'SALTARA',
      },
      {
        value: 'H809',
        label: 'SAN COSTANZO',
      },
      {
        value: 'H886',
        label: 'SAN GIORGIO o SAN GIORGIO DI PESARO',
      },
      {
        value: 'H949',
        label: 'SAN LEO o SAN LEO',
      },
      {
        value: 'H958',
        label: 'SAN LORENZO o SAN LORENZO IN CAMPO',
      },
      {
        value: 'I399',
        label: 'SAN VITO o SAN VITO SUL CESANO',
      },
      {
        value: 'I201',
        label: "SANT'AGATA FELTRIA o SANT'AGATA FELTRIA",
      },
      {
        value: 'I268',
        label: "SANT'ANDREA o SANT'ANDREA DI SUASA",
      },
      {
        value: 'I285',
        label: "SANT'ANGELO IN LIZZOLA",
      },
      {
        value: 'I287',
        label: "SANT'ANGELO IN VADO",
      },
      {
        value: 'I344',
        label: "SANT'IPPOLITO",
      },
      {
        value: 'I459',
        label: 'SASSOCORVARO',
      },
      {
        value: 'M413',
        label: 'SASSOCORVARO AUDITORE',
      },
      {
        value: 'I460',
        label: 'SASSOFELTRIO',
      },
      {
        value: 'I517',
        label: 'SCAVOLINO',
      },
      {
        value: 'I654',
        label: "SERRA SANT'ABBONDIO",
      },
      {
        value: 'I670',
        label: 'SERRUNGARINA',
      },
      {
        value: 'I846',
        label: 'SORBOLONGO',
      },
      {
        value: 'L034',
        label: 'TALAMELLO o TALAMELLO',
      },
      {
        value: 'L078',
        label: 'TAVOLETO',
      },
      {
        value: 'L081',
        label: 'TAVULLIA o TOMBA DI PESARO',
      },
      {
        value: 'M379',
        label: 'TERRE ROVERESCHE',
      },
      {
        value: 'L242',
        label: 'TORRE o TORRE SAN MARCO',
      },
      {
        value: 'L498',
        label: 'URBANIA',
      },
      {
        value: 'L500',
        label: 'URBINO',
      },
      {
        value: 'M331',
        label: 'VALLEFOGLIA',
      },
    ],
  },

  {
    value: 'PV',
    label: 'Pavia',
    children: [
      {
        value: 'A118',
        label: 'ALAGNA',
      },
      {
        value: 'A134',
        label: 'ALBAREDO o ALBAREDO ARNABOLDI',
      },
      {
        value: 'A171',
        label: 'ALBONESE',
      },
      {
        value: 'A175',
        label: 'ALBUZZANO',
      },
      {
        value: 'A387',
        label: 'ARENA o ARENA PO',
      },
      {
        value: 'A395',
        label: 'ARGINE o ARGINE PO o BRESSANA',
      },
      {
        value: 'A538',
        label: 'BADIA o BADIA PAVESE',
      },
      {
        value: 'A550',
        label: 'BAGNARA o BAGNARIA',
      },
      {
        value: 'A634',
        label: 'BARBIANELLO',
      },
      {
        value: 'A672',
        label: 'BARONA',
      },
      {
        value: 'A690',
        label: "BASCAPE'",
      },
      {
        value: 'A695',
        label: 'BASELICA BOLOGNA',
      },
      {
        value: 'A711',
        label: "BASTIDA DE' DOSSI",
      },
      {
        value: 'A712',
        label: 'BASTIDA PANCARANA',
      },
      {
        value: 'A718',
        label: 'BATTUDA',
      },
      {
        value: 'A741',
        label: 'BELGIOIOSO',
      },
      {
        value: 'A775',
        label: 'BELVEDERE o BELVEDERE AL PO',
      },
      {
        value: 'A792',
        label: 'BEREGUARDO',
      },
      {
        value: 'A989',
        label: 'BORGARELLO',
      },
      {
        value: 'B028',
        label: 'BORGO PRIOLO',
      },
      {
        value: 'B038',
        label: 'BORGO SAN SIRO',
      },
      {
        value: 'B014',
        label: 'BORGOFRANCO o SUARDI',
      },
      {
        value: 'B030',
        label: 'BORGORATTO o BORGORATTO MORMOROLO',
      },
      {
        value: 'B051',
        label: 'BORNASCO',
      },
      {
        value: 'B082',
        label: 'BOSNASCO',
      },
      {
        value: 'B089',
        label: 'BOTTARONE o MEZZANA CORTI BOTTARONE',
      },
      {
        value: 'B117',
        label: 'BRALLO DI PREGOLA o PREGOLA',
      },
      {
        value: 'B122',
        label: 'BRANDUZZO',
      },
      {
        value: 'B142',
        label: 'BREME',
      },
      {
        value: 'B159',
        label: 'BRESSANA BOTTARONE',
      },
      {
        value: 'B201',
        label: 'BRONI',
      },
      {
        value: 'B307',
        label: 'BUTTIRAGO',
      },
      {
        value: 'B340',
        label: "CA' DE' TEDIOLI",
      },
      {
        value: 'B330',
        label: "CA' DELLA TERRA",
      },
      {
        value: 'B370',
        label: 'CAIRO o CAIRO LOMELLINO',
      },
      {
        value: 'B386',
        label: 'CALCABABBIO o CALAVINO',
      },
      {
        value: 'B411',
        label: 'CALIGNANO',
      },
      {
        value: 'B447',
        label: 'CALVIGNANO',
      },
      {
        value: 'B464',
        label: "CAMBIO'",
      },
      {
        value: 'B558',
        label: 'CAMPO RINALDO',
      },
      {
        value: 'B552',
        label: 'CAMPOMORTO',
      },
      {
        value: 'B567',
        label: 'CAMPOSPINOSO',
      },
      {
        value: 'B568',
        label: 'CAMPOSPINOSO ALBAREDO',
      },
      {
        value: 'B587',
        label: 'CANDIA o CANDIA LOMELLINA',
      },
      {
        value: 'B599',
        label: 'CANEVINO',
      },
      {
        value: 'B613',
        label: "CANNETO PAVESE o MONTU' DEI GABBI",
      },
      {
        value: 'B741',
        label: 'CARBONARA o CARBONARA AL TICINO',
      },
      {
        value: 'B821',
        label: 'CARPIGNAGO',
      },
      {
        value: 'B824',
        label: 'CARPIGNANO o CAVA CARPIGNANO o CURA CARPIGNANO',
      },
      {
        value: 'B929',
        label: 'CASANOVA LONATI',
      },
      {
        value: 'B944',
        label: 'CASATICO',
      },
      {
        value: 'B945',
        label: 'CASATISMA',
      },
      {
        value: 'B954',
        label: 'CASEI GEROLA',
      },
      {
        value: 'B988',
        label: 'CASORATE o CASORATE PRIMO',
      },
      {
        value: 'C028',
        label: 'CASSINE CALDERARI',
      },
      {
        value: 'C031',
        label: 'CASSINE SIRIGARI',
      },
      {
        value: 'C032',
        label: 'CASSINE TOLENTINE',
      },
      {
        value: 'C035',
        label: 'CASSINO o CASSINO PO',
      },
      {
        value: 'C038',
        label: 'CASSOLNOVO',
      },
      {
        value: 'C050',
        label: 'CASTANA',
      },
      {
        value: 'C053',
        label: 'CASTEGGIO',
      },
      {
        value: 'C131',
        label: 'CASTEL LAMBRO',
      },
      {
        value: 'C144',
        label: "CASTELLARO DE' GIORGI",
      },
      {
        value: 'C164',
        label: 'CASTELLETTO o CASTELLETTO PO',
      },
      {
        value: 'C157',
        label: 'CASTELLETTO DI BRANDUZZO',
      },
      {
        value: 'C184',
        label: "CASTELLO D'AGOGNA",
      },
      {
        value: 'C213',
        label: 'CASTELNOVETTO',
      },
      {
        value: 'C360',
        label: 'CAVA o CAVA MANARA',
      },
      {
        value: 'C368',
        label: 'CAVAGNERA',
      },
      {
        value: 'C414',
        label: 'CECIMA',
      },
      {
        value: 'C434',
        label: 'CELLA o CELLA DI BOBBIO',
      },
      {
        value: 'C451',
        label: 'CELPENCHIO',
      },
      {
        value: 'C484',
        label: 'CERANOVA',
      },
      {
        value: 'C508',
        label: 'CERETTO LOMELLINA o CERRETTO o CERRETTO LOMELLINO',
      },
      {
        value: 'C509',
        label: 'CERGNAGO',
      },
      {
        value: 'C541',
        label: 'CERTOSA DI PAVIA',
      },
      {
        value: 'C551',
        label: 'CERVESINA',
      },
      {
        value: 'C637',
        label: 'CHIGNOLO o CHIGNOLO PO',
      },
      {
        value: 'C684',
        label: 'CIGOGNOLA',
      },
      {
        value: 'C686',
        label: 'CILAVEGNA',
      },
      {
        value: 'C813',
        label: 'CODEVILLA',
      },
      {
        value: 'M419',
        label: 'COLLI VERDI',
      },
      {
        value: 'C913',
        label: 'COMAIRANO',
      },
      {
        value: 'C958',
        label: 'CONFIENZA',
      },
      {
        value: 'C979',
        label: 'COPIANO',
      },
      {
        value: 'C982',
        label: 'CORANA',
      },
      {
        value: 'C985',
        label: 'CORBESATE',
      },
      {
        value: 'D017',
        label: 'CORNALE',
      },
      {
        value: 'M338',
        label: 'CORNALE E BASTIDA',
      },
      {
        value: 'D036',
        label: 'CORPI SANTI DI PAVIA',
      },
      {
        value: 'D067',
        label: 'CORTEOLONA',
      },
      {
        value: 'M372',
        label: 'CORTEOLONA E GENZONE',
      },
      {
        value: 'D081',
        label: 'CORVINO o CORVINO SAN QUIRICO',
      },
      {
        value: 'D109',
        label: "COSTA DE' NOBILI o COSTA SAN ZENONE",
      },
      {
        value: 'D127',
        label: 'COZZO',
      },
      {
        value: 'D340',
        label: 'DONELASCO',
      },
      {
        value: 'D348',
        label: 'DORNO',
      },
      {
        value: 'D552',
        label: 'FERRERA o FERRERA ERBOGNONE',
      },
      {
        value: 'D594',
        label: 'FILIGHERA',
      },
      {
        value: 'D687',
        label: 'FOPPA',
      },
      {
        value: 'D732',
        label: 'FORTUNAGO',
      },
      {
        value: 'D743',
        label: 'FOSSARMATO',
      },
      {
        value: 'D771',
        label: 'FRASCAROLO',
      },
      {
        value: 'D873',
        label: 'GALLIAVOLA',
      },
      {
        value: 'D892',
        label: 'GAMBARANA',
      },
      {
        value: 'D901',
        label: "GAMBOLO'",
      },
      {
        value: 'D925',
        label: 'GARLASCO',
      },
      {
        value: 'D973',
        label: 'GENZONE',
      },
      {
        value: 'D980',
        label: 'GERENZAGO',
      },
      {
        value: 'D992',
        label: 'GERRE CHIOZZO',
      },
      {
        value: 'E046',
        label: 'GIOVENZANO',
      },
      {
        value: 'E062',
        label: 'GIUSSAGO',
      },
      {
        value: 'E265',
        label: 'GNIGNANO o SIZIANO',
      },
      {
        value: 'E072',
        label: 'GODIASCO o GODIASCO SALICE TERME',
      },
      {
        value: 'E077',
        label: 'GOIDO',
      },
      {
        value: 'E081',
        label: 'GOLFERENZO',
      },
      {
        value: 'E152',
        label: 'GRAVELLONA LOMELLINA',
      },
      {
        value: 'E195',
        label: 'GROPELLO CAIROLI o GROPELLO LOMELLINO o GROPPELLO',
      },
      {
        value: 'E231',
        label: 'GUALDRASCO',
      },
      {
        value: 'E267',
        label: 'GUINZANO',
      },
      {
        value: 'E310',
        label: 'INVERNO o INVERNO E MONTELEONE',
      },
      {
        value: 'E437',
        label: 'LANDRIANO',
      },
      {
        value: 'E439',
        label: 'LANGOSCO',
      },
      {
        value: 'E454',
        label: 'LARDIRAGO',
      },
      {
        value: 'E579',
        label: 'LICONASCO',
      },
      {
        value: 'E600',
        label: 'LINAROLO',
      },
      {
        value: 'E608',
        label: 'LIRIO',
      },
      {
        value: 'E662',
        label: 'LOMELLO',
      },
      {
        value: 'B387',
        label: 'LUNGAVILLA o CALCATA',
      },
      {
        value: 'E804',
        label: 'MAGHERNO',
      },
      {
        value: 'E881',
        label: 'MANDRINO',
      },
      {
        value: 'E886',
        label: 'MANGIALUPO',
      },
      {
        value: 'E934',
        label: 'MARCIGNAGO',
      },
      {
        value: 'E999',
        label: 'MARZANO',
      },
      {
        value: 'F080',
        label: 'MEDE',
      },
      {
        value: 'F122',
        label: 'MENCONICO',
      },
      {
        value: 'F170',
        label: 'MEZZANA BIGLI',
      },
      {
        value: 'F171',
        label: 'MEZZANA RABATTONE',
      },
      {
        value: 'F175',
        label: 'MEZZANINO',
      },
      {
        value: 'F180',
        label: 'MEZZANO o MEZZANO SICCOMARIO',
      },
      {
        value: 'F178',
        label: 'MEZZANO PARPANESE',
      },
      {
        value: 'F237',
        label: 'MIRABELLO o MIRABELLO ED UNITI DI PAVIA',
      },
      {
        value: 'F238',
        label: 'MIRADOLO o MIRADOLO TERME',
      },
      {
        value: 'F245',
        label: 'MISANO o MISANO OLONA',
      },
      {
        value: 'F417',
        label: 'MONTALTO o MONTALTO PAVESE',
      },
      {
        value: 'F444',
        label: 'MONTE BOLOGNOLA',
      },
      {
        value: 'F440',
        label: 'MONTEBELLO o MONTEBELLO DELLA BATTAGLIA',
      },
      {
        value: 'F449',
        label: 'MONTECALVO o MONTECALVO VERSIGGIA',
      },
      {
        value: 'F539',
        label: 'MONTELEONE o MONTELEONE SUI COLLI PAVESI',
      },
      {
        value: 'F624',
        label: 'MONTESANO o MONTESANO AL PIANO',
      },
      {
        value: 'F638',
        label: 'MONTESCANO',
      },
      {
        value: 'F644',
        label: 'MONTESEGALE',
      },
      {
        value: 'F670',
        label: 'MONTICELLI o MONTICELLI PAVESE',
      },
      {
        value: 'F701',
        label: "MONTU' BECCARIA",
      },
      {
        value: 'F702',
        label: "MONTU' BERCHIELLI",
      },
      {
        value: 'F739',
        label: 'MORNICO o MORNICO LOSANA',
      },
      {
        value: 'F754',
        label: 'MORTARA',
      },
      {
        value: 'F778',
        label: 'MOTTA SAN DAMIANO',
      },
      {
        value: 'F891',
        label: 'NICORVO',
      },
      {
        value: 'G021',
        label: 'OLEVANO o OLEVANO DI LOMELLINA',
      },
      {
        value: 'G032',
        label: 'OLIVA o OLIVA GESSI',
      },
      {
        value: 'G104',
        label: 'ORIGIOSO',
      },
      {
        value: 'G194',
        label: 'OTTOBIANO',
      },
      {
        value: 'G245',
        label: 'PAIRANA',
      },
      {
        value: 'G275',
        label: 'PALESTRO',
      },
      {
        value: 'G304',
        label: 'PANCARANA',
      },
      {
        value: 'G321',
        label: 'PAPIAGO',
      },
      {
        value: 'G342',
        label: 'PARONA',
      },
      {
        value: 'G388',
        label: 'PAVIA',
      },
      {
        value: 'G612',
        label: "PIETRA DE' GIORGI",
      },
      {
        value: 'G617',
        label: 'PIETRA GAVINA',
      },
      {
        value: 'G635',
        label: 'PIEVE ALBIGNOLA',
      },
      {
        value: 'G639',
        label: 'PIEVE DEL CAIRO',
      },
      {
        value: 'G650',
        label: 'PIEVE PORTO MORONE',
      },
      {
        value: 'G671',
        label: 'PINAROLO o PINAROLO PO',
      },
      {
        value: 'G711',
        label: 'PISSARELLO',
      },
      {
        value: 'G720',
        label: 'PIZZALE',
      },
      {
        value: 'G723',
        label: 'PIZZOCORNO',
      },
      {
        value: 'G810',
        label: 'POMETO',
      },
      {
        value: 'G835',
        label: 'PONTE CARATE',
      },
      {
        value: 'G851',
        label: 'PONTE NIZZA',
      },
      {
        value: 'G895',
        label: 'PORTALBERA',
      },
      {
        value: 'G971',
        label: 'PRADO',
      },
      {
        value: 'H204',
        label: 'REA',
      },
      {
        value: 'H216',
        label: 'REDAVALLE',
      },
      {
        value: 'H246',
        label: 'RETORBIDO',
      },
      {
        value: 'H336',
        label: 'RIVANAZZANO o RIVANAZZANO TERME',
      },
      {
        value: 'H369',
        label: 'ROBBIO',
      },
      {
        value: 'H375',
        label: 'ROBECCO o ROBECCO PAVESE',
      },
      {
        value: 'H396',
        label: "ROCCA DE' GIORGI",
      },
      {
        value: 'H450',
        label: 'ROCCA SUSELLA',
      },
      {
        value: 'H491',
        label: 'ROGNANO',
      },
      {
        value: 'H505',
        label: 'ROMAGNESE',
      },
      {
        value: 'H527',
        label: 'RONCARO',
      },
      {
        value: 'H559',
        label: 'ROSASCO',
      },
      {
        value: 'H614',
        label: 'ROVESCALA',
      },
      {
        value: 'H637',
        label: 'RUINO',
      },
      {
        value: 'H663',
        label: 'SAGLIANO o SAGLIANO DI CRENNA',
      },
      {
        value: 'H799',
        label: 'SAN CIPRIANO o SAN CIPRIANO PO',
      },
      {
        value: 'H814',
        label: 'SAN DAMIANO o SAN DAMIANO AL COLLE',
      },
      {
        value: 'H859',
        label: 'SAN GENESIO o SAN GENESIO ED UNITI',
      },
      {
        value: 'H885',
        label: 'SAN GIORGIO o SAN GIORGIO DI LOMELLINA',
      },
      {
        value: 'I014',
        label: 'SAN MARTINO SICCOMARIO',
      },
      {
        value: 'I080',
        label: 'SAN PERONE',
      },
      {
        value: 'I127',
        label: 'SAN PONZO o SAN PONZO SEMOLA',
      },
      {
        value: 'I380',
        label: 'SAN VARESE',
      },
      {
        value: 'I413',
        label: 'SAN ZENO o SAN ZENO E FOPPA',
      },
      {
        value: 'I416',
        label: 'SAN ZENONE o SAN ZENONE AL PO',
      },
      {
        value: 'I048',
        label: "SANNAZZARO o SANNAZZARO DE' BURGONDI",
      },
      {
        value: 'I211',
        label: "SANT'ALBANO o SANT'ALBANO DI BOBBIO",
      },
      {
        value: 'I213',
        label: "SANT'ALESSIO o SANT'ALESSIO CON VIALONE",
      },
      {
        value: 'I276',
        label: "SANT'ANGELO o SANT'ANGELO LOMELLINA",
      },
      {
        value: 'I175',
        label: 'SANTA CRISTINA o SANTA CRISTINA E BISSONE',
      },
      {
        value: 'I203',
        label: 'SANTA GIULETTA',
      },
      {
        value: 'I227',
        label: 'SANTA MARGHERITA o SANTA MARGHERITA DI BOBBIO',
      },
      {
        value: 'I228',
        label: 'SANTA MARGHERITA o SANTA MARGHERITA PO',
      },
      {
        value: 'I230',
        label: 'SANTA MARGHERITA DI STAFFORA',
      },
      {
        value: 'I236',
        label: "SANTA MARIA DELLA STRADA o TRAVACO' SICCOMARIO",
      },
      {
        value: 'I237',
        label: 'SANTA MARIA DELLA VERSA o SORIASCO',
      },
      {
        value: 'I447',
        label: 'SARTIRANA o SARTIRANA LOMELLINA',
      },
      {
        value: 'I487',
        label: 'SCALDASOLE',
      },
      {
        value: 'I599',
        label: 'SEMIANA',
      },
      {
        value: 'I739',
        label: 'SILVANO o SILVANO PIETRA',
      },
      {
        value: 'I825',
        label: 'SOMMO',
      },
      {
        value: 'I894',
        label: 'SPESSA',
      },
      {
        value: 'I918',
        label: 'SPIRAGO',
      },
      {
        value: 'I933',
        label: 'STAGHIGLIONE',
      },
      {
        value: 'I968',
        label: 'STRADELLA',
      },
      {
        value: 'L130',
        label: 'TERRASA',
      },
      {
        value: 'L234',
        label: 'TORRADELLO',
      },
      {
        value: 'L237',
        label: 'TORRAZZA COSTE',
      },
      {
        value: 'L249',
        label: 'TORRE BERETTI',
      },
      {
        value: 'L250',
        label: 'TORRE BERETTI E CASTELLARO',
      },
      {
        value: 'L256',
        label: "TORRE D'ARESE",
      },
      {
        value: 'L269',
        label: "TORRE D'ISOLA",
      },
      {
        value: 'L262',
        label: "TORRE DE' NEGRI",
      },
      {
        value: 'L266',
        label: "TORRE DE' TORTI",
      },
      {
        value: 'L260',
        label: 'TORRE DEL MANGANO',
      },
      {
        value: 'L261',
        label: 'TORRE DEL MONTE',
      },
      {
        value: 'L285',
        label: 'TORRE VECCHIA o TORREVECCHIA PIA',
      },
      {
        value: 'L289',
        label: 'TORRIANO',
      },
      {
        value: 'L292',
        label: 'TORRICELLA o TORRICELLA VERZATE',
      },
      {
        value: 'L300',
        label: 'TORRINO',
      },
      {
        value: 'L350',
        label: 'TREBBIANO o TREBBIANO NIZZA',
      },
      {
        value: 'L440',
        label: 'TRIVOLZIO',
      },
      {
        value: 'L446',
        label: 'TROGNANO',
      },
      {
        value: 'L449',
        label: 'TROMELLO',
      },
      {
        value: 'L453',
        label: 'TROVO',
      },
      {
        value: 'L467',
        label: 'TURAGO BORDONE',
      },
      {
        value: 'L523',
        label: 'VACCARIZZA',
      },
      {
        value: 'L542',
        label: 'VAIRANO o VAIRANO PAVESE',
      },
      {
        value: 'L562',
        label: 'VAL DI NIZZA',
      },
      {
        value: 'L568',
        label: 'VALEGGIO',
      },
      {
        value: 'L593',
        label: 'VALLE o VALLE LOMELLINA',
      },
      {
        value: 'L617',
        label: 'VALLE SALIMBENE',
      },
      {
        value: 'L659',
        label: 'VALVERDE',
      },
      {
        value: 'L690',
        label: 'VARZI',
      },
      {
        value: 'L716',
        label: 'VELEZZO o VELEZZO LOMELLINA',
      },
      {
        value: 'L720',
        label: 'VELLEZZO o VELLEZZO BELLINI',
      },
      {
        value: 'L784',
        label: 'VERRETTO',
      },
      {
        value: 'L786',
        label: 'VERRUA o VERRUA SICCOMARIO',
      },
      {
        value: 'L788',
        label: 'VERRUA PO',
      },
      {
        value: 'L854',
        label: 'VIDIGULFO',
      },
      {
        value: 'L862',
        label: 'VIGALFO',
      },
      {
        value: 'L872',
        label: 'VIGEVANO',
      },
      {
        value: 'L902',
        label: 'VIGONZONE',
      },
      {
        value: 'L917',
        label: 'VILLA BISCOSSI',
      },
      {
        value: 'L962',
        label: 'VILLALUNGA',
      },
      {
        value: 'L983',
        label: "VILLANOVA D'ARDENGHI",
      },
      {
        value: 'L994',
        label: 'VILLANTERIO',
      },
      {
        value: 'M005',
        label: 'VILLAREGGIO',
      },
      {
        value: 'M051',
        label: 'VIMANONE',
      },
      {
        value: 'M079',
        label: 'VISTARINO',
      },
      {
        value: 'M097',
        label: 'VIVENTE',
      },
      {
        value: 'M109',
        label: 'VOGHERA',
      },
      {
        value: 'M119',
        label: 'VOLPARA',
      },
      {
        value: 'M150',
        label: 'ZAVATTARELLO',
      },
      {
        value: 'M151',
        label: 'ZAVATTARELLO VALVERDE',
      },
      {
        value: 'M152',
        label: 'ZECCONE',
      },
      {
        value: 'M155',
        label: 'ZELATA',
      },
      {
        value: 'M161',
        label: 'ZEME',
      },
      {
        value: 'M162',
        label: 'ZENEVREDO',
      },
      {
        value: 'M166',
        label: 'ZERBO',
      },
      {
        value: 'M167',
        label: "ZERBOLO'",
      },
      {
        value: 'M175',
        label: 'ZIBIDO AL LAMBRO',
      },
      {
        value: 'M180',
        label: 'ZINASCO',
      },
    ],
  },

  {
    value: 'PZ',
    label: 'Potenza',
    children: [
      {
        value: 'A013',
        label: 'ABRIOLA',
      },
      {
        value: 'A020',
        label: 'ACERENZA',
      },
      {
        value: 'A131',
        label: 'ALBANO o ALBANO DI LUCANIA',
      },
      {
        value: 'A321',
        label: 'ANZI',
      },
      {
        value: 'A415',
        label: 'ARMENTO',
      },
      {
        value: 'A482',
        label: 'ATELLA',
      },
      {
        value: 'A519',
        label: 'AVIGLIANO',
      },
      {
        value: 'A604',
        label: 'BALVANO',
      },
      {
        value: 'A612',
        label: 'BANZI',
      },
      {
        value: 'A615',
        label: 'BARAGIANO',
      },
      {
        value: 'A666',
        label: 'BARILE',
      },
      {
        value: 'A743',
        label: 'BELLA',
      },
      {
        value: 'B173',
        label: 'BRIENZA',
      },
      {
        value: 'B181',
        label: 'BRINDISI o BRINDISI MONTAGNA',
      },
      {
        value: 'B440',
        label: 'CALVELLO',
      },
      {
        value: 'B443',
        label: 'CALVERA',
      },
      {
        value: 'B549',
        label: 'CAMPOMAGGIORE',
      },
      {
        value: 'B580',
        label: 'CANCELLARA',
      },
      {
        value: 'B743',
        label: 'CARBONE',
      },
      {
        value: 'B906',
        label: 'CASALNUOVO o CASALNUOVO LUCANO o SAN PAOLO ALBANESE',
      },
      {
        value: 'C120',
        label: 'CASTELGRANDE',
      },
      {
        value: 'C196',
        label: 'CASTELLUCCIO',
      },
      {
        value: 'C199',
        label: 'CASTELLUCCIO INFERIORE',
      },
      {
        value: 'C201',
        label: 'CASTELLUCCIO SUPERIORE',
      },
      {
        value: 'C209',
        label: 'CASTELMEZZANO',
      },
      {
        value: 'C271',
        label: 'CASTELSARACENO',
      },
      {
        value: 'C345',
        label: "CASTRONUOVO o CASTRONUOVO DI SANT'ANDREA",
      },
      {
        value: 'C539',
        label: 'CERSOSIMO',
      },
      {
        value: 'C619',
        label: 'CHIAROMONTE',
      },
      {
        value: 'D010',
        label: 'CORLETO o CORLETO PERTICARA',
      },
      {
        value: 'D414',
        label: 'EPISCOPIA',
      },
      {
        value: 'D497',
        label: 'FARDELLA',
      },
      {
        value: 'D593',
        label: 'FILIANO',
      },
      {
        value: 'D696',
        label: 'FORENZA',
      },
      {
        value: 'D766',
        label: 'FRANCAVILLA o FRANCAVILLA IN SINNI',
      },
      {
        value: 'D876',
        label: 'GALLICCHIO',
      },
      {
        value: 'E033',
        label: 'GINESTRA',
      },
      {
        value: 'E221',
        label: 'GRUMENTO NOVA o SAPONARA o SAPONARA DI GRUMENTO',
      },
      {
        value: 'E246',
        label: 'GUARDIA PERTICARA',
      },
      {
        value: 'E409',
        label: 'LAGONEGRO',
      },
      {
        value: 'E474',
        label: 'LATRONICO',
      },
      {
        value: 'E482',
        label: 'LAURENZANA',
      },
      {
        value: 'E483',
        label: 'LAURIA',
      },
      {
        value: 'E493',
        label: 'LAVELLO',
      },
      {
        value: 'E919',
        label: 'MARATEA',
      },
      {
        value: 'E976',
        label: 'MARSICO NUOVO',
      },
      {
        value: 'E977',
        label: 'MARSICOVETERE',
      },
      {
        value: 'F006',
        label: 'MASCHITO',
      },
      {
        value: 'F104',
        label: 'MELFI',
      },
      {
        value: 'F249',
        label: 'MISSANELLO',
      },
      {
        value: 'F295',
        label: 'MOLITERNO',
      },
      {
        value: 'F568',
        label: 'MONTEMILONE',
      },
      {
        value: 'F573',
        label: 'MONTEMURRO',
      },
      {
        value: 'F817',
        label: 'MURO o MURO LUCANO',
      },
      {
        value: 'F866',
        label: 'NEMOLI',
      },
      {
        value: 'F917',
        label: 'NOEPOLI o NOIA',
      },
      {
        value: 'G081',
        label: 'OPPIDO o OPPIDO LUCANO o PALMIRA',
      },
      {
        value: 'G261',
        label: 'PALAZZO o PALAZZO SAN GERVASIO',
      },
      {
        value: 'M269',
        label: 'PATERNO',
      },
      {
        value: 'G496',
        label: 'PESCOPAGANO',
      },
      {
        value: 'G590',
        label: 'PICERNO',
      },
      {
        value: 'G614',
        label: 'PIETRAFESA o SATRIANO DI LUCANIA',
      },
      {
        value: 'G616',
        label: 'PIETRAGALLA',
      },
      {
        value: 'G623',
        label: 'PIETRAPERTOSA',
      },
      {
        value: 'G663',
        label: 'PIGNOLA o PIGNOLA DI BASILICATA o VIGNOLA',
      },
      {
        value: 'G942',
        label: 'POTENZA',
      },
      {
        value: 'H186',
        label: 'RAPOLLA',
      },
      {
        value: 'H187',
        label: 'RAPONE',
      },
      {
        value: 'H307',
        label: 'RIONERO o RIONERO IN VOLTURE o RIONERO IN VULTURE',
      },
      {
        value: 'H312',
        label: 'RIPACANDIDA',
      },
      {
        value: 'H348',
        label: 'RIVELLO',
      },
      {
        value: 'H426',
        label: 'ROCCANOVA',
      },
      {
        value: 'H590',
        label: 'ROTONDA',
      },
      {
        value: 'H641',
        label: 'RUOTI',
      },
      {
        value: 'H646',
        label: 'RUVO o RUVO DEL MONTE',
      },
      {
        value: 'H730',
        label: 'SALVIA o SAVOIA DI LUCANIA',
      },
      {
        value: 'H795',
        label: 'SAN CHIRICO NUOVO',
      },
      {
        value: 'H796',
        label: 'SAN CHIRICO RAPARO',
      },
      {
        value: 'H808',
        label: 'SAN COSTANTINO o SAN COSTANTINO ALBANESE',
      },
      {
        value: 'H831',
        label: 'SAN FELE',
      },
      {
        value: 'H994',
        label: "SAN MARTINO o SAN MARTINO D'AGRI",
      },
      {
        value: 'I157',
        label: 'SAN SEVERINO o SAN SEVERINO LUCANO',
      },
      {
        value: 'I288',
        label: "SANT'ANGELO LE FRATTE",
      },
      {
        value: 'I305',
        label: "SANT'ARCANGELO",
      },
      {
        value: 'I426',
        label: 'SARCONI',
      },
      {
        value: 'I457',
        label: 'SASSO o SASSO DI CASTALDA',
      },
      {
        value: 'I610',
        label: 'SENISE',
      },
      {
        value: 'I917',
        label: 'SPINOSO',
      },
      {
        value: 'L082',
        label: 'TEANA',
      },
      {
        value: 'L126',
        label: 'TERRANOVA o TERRANOVA DI POLLINO',
      },
      {
        value: 'L181',
        label: 'TITO',
      },
      {
        value: 'L197',
        label: 'TOLVE',
      },
      {
        value: 'L326',
        label: 'TRAMUTOLA',
      },
      {
        value: 'L357',
        label: 'TRECCHINA',
      },
      {
        value: 'L439',
        label: 'TRIVIGNO',
      },
      {
        value: 'L532',
        label:
          'VAGLIO o VAGLIO BASILICATA o VAGLIO DI BASILICATA o VAGLIO LUCANO',
      },
      {
        value: 'L738',
        label: 'VENOSA',
      },
      {
        value: 'L859',
        label: 'VIETRI o VIETRI DI POTENZA',
      },
      {
        value: 'L873',
        label: 'VIGGIANELLO',
      },
      {
        value: 'L874',
        label: 'VIGGIANO',
      },
    ],
  },

  {
    value: 'RA',
    label: 'Ravenna',
    children: [
      {
        value: 'A191',
        label: 'ALFONSINE',
      },
      {
        value: 'A547',
        label: 'BAGNACAVALLO',
      },
      {
        value: 'A551',
        label: 'BAGNARA o BAGNARA DI ROMAGNA',
      },
      {
        value: 'B188',
        label: 'BRISIGHELLA',
      },
      {
        value: 'B982',
        label: 'CASOLA VALSENIO',
      },
      {
        value: 'C065',
        label: 'CASTEL BOLOGNESE',
      },
      {
        value: 'C553',
        label: 'CERVIA',
      },
      {
        value: 'C963',
        label: 'CONSELICE',
      },
      {
        value: 'D121',
        label: 'COTIGNOLA',
      },
      {
        value: 'D458',
        label: 'FAENZA',
      },
      {
        value: 'D829',
        label: 'FUSIGNANO',
      },
      {
        value: 'E730',
        label: 'LUGO',
      },
      {
        value: 'F029',
        label: 'MASSA LOMBARDA',
      },
      {
        value: 'H199',
        label: 'RAVENNA',
      },
      {
        value: 'H302',
        label: 'RIOLO o RIOLO DEI BAGNI o RIOLO TERME',
      },
      {
        value: 'H642',
        label: 'RUSSI',
      },
      {
        value: 'I196',
        label: "SANT'AGATA o SANT'AGATA SUL SANTERNO",
      },
      {
        value: 'I787',
        label: 'SOLAROLO',
      },
    ],
  },

  {
    value: 'RC',
    label: 'Reggio Calabria',
    children: [
      {
        value: 'A065',
        label: 'AFRICO',
      },
      {
        value: 'A077',
        label: 'AGNANA CALABRA',
      },
      {
        value: 'A303',
        label: 'ANOIA',
      },
      {
        value: 'A314',
        label: 'ANTONIMINA',
      },
      {
        value: 'A385',
        label: 'ARDORE',
      },
      {
        value: 'A544',
        label: 'BAGALADI',
      },
      {
        value: 'A552',
        label: 'BAGNARA o BAGNARA CALABRA',
      },
      {
        value: 'A780',
        label: 'BENESTARE',
      },
      {
        value: 'A843',
        label: 'BIANCO',
      },
      {
        value: 'A897',
        label: 'BIVONGI',
      },
      {
        value: 'B097',
        label: 'BOVA',
      },
      {
        value: 'B099',
        label: 'BOVA MARINA',
      },
      {
        value: 'B098',
        label: 'BOVALINO',
      },
      {
        value: 'B118',
        label: 'BRANCALEONE',
      },
      {
        value: 'B234',
        label: 'BRUZZANO o BRUZZANO ZEFFIRIO',
      },
      {
        value: 'B379',
        label: 'CALANNA',
      },
      {
        value: 'B481',
        label: 'CAMINI',
      },
      {
        value: 'B516',
        label: 'CAMPO o CAMPO CALABRO o CAMPO DI CALABRIA',
      },
      {
        value: 'B591',
        label: 'CANDIDONI',
      },
      {
        value: 'B614',
        label: 'CANNITELLO',
      },
      {
        value: 'B617',
        label: 'CANOLO',
      },
      {
        value: 'B718',
        label: 'CARAFFA o CARAFFA DEL BIANCO',
      },
      {
        value: 'B756',
        label: 'CARDETO',
      },
      {
        value: 'B766',
        label: 'CARERI',
      },
      {
        value: 'B775',
        label: "CARIDA'",
      },
      {
        value: 'B966',
        label: 'CASIGNANA',
      },
      {
        value: 'C285',
        label: 'CASTELVETERE o CAULONIA',
      },
      {
        value: 'C350',
        label: 'CATAFORIO',
      },
      {
        value: 'C355',
        label: 'CATONA',
      },
      {
        value: 'C695',
        label: "CIMINA'",
      },
      {
        value: 'C710',
        label: 'CINQUEFRONDI',
      },
      {
        value: 'C747',
        label: 'CITTANOVA',
      },
      {
        value: 'C954',
        label: 'CONDOFURI',
      },
      {
        value: 'D089',
        label: 'COSOLETO',
      },
      {
        value: 'D268',
        label: 'DELIANUOVA',
      },
      {
        value: 'D545',
        label: 'FEROLETO DELLA CHIESA',
      },
      {
        value: 'D557',
        label: 'FERRUZZANO',
      },
      {
        value: 'D619',
        label: 'FIUMARA',
      },
      {
        value: 'D746',
        label:
          'FOSSATO CALABRO o MONTEBELLO o MONTEBELLO IONICO o MONTEBELLO JONICO',
      },
      {
        value: 'D864',
        label: 'GALATRO',
      },
      {
        value: 'D877',
        label: 'GALLICO',
      },
      {
        value: 'D880',
        label: 'GALLINA',
      },
      {
        value: 'D975',
        label: 'GERACE o GERACE SUPERIORE',
      },
      {
        value: 'D976',
        label: 'GERACE MARINA o LOCRI',
      },
      {
        value: 'E025',
        label: 'GIFFONE',
      },
      {
        value: 'E041',
        label: 'GIOIA o GIOIA TAURO',
      },
      {
        value: 'E044',
        label: 'GIOIOSA o GIOIOSA IONICA',
      },
      {
        value: 'E212',
        label: 'GROTTERIA',
      },
      {
        value: 'E275',
        label: 'IATRINOLI',
      },
      {
        value: 'E402',
        label: 'LAGANADI',
      },
      {
        value: 'E479',
        label: 'LAUREANA o LAUREANA DI BORELLO o LAUREANA DI BORRELLO',
      },
      {
        value: 'E873',
        label: 'MAMMOLA',
      },
      {
        value: 'E956',
        label: 'MARINA DI GIOIOSA IONICA',
      },
      {
        value: 'E968',
        label: 'MAROPATI',
      },
      {
        value: 'E993',
        label: 'MARTONE',
      },
      {
        value: 'F105',
        label: "MELICUCCA'",
      },
      {
        value: 'F106',
        label: 'MELICUCCO',
      },
      {
        value: 'F112',
        label: 'MELITO o MELITO DI PORTO SALVO',
      },
      {
        value: 'F301',
        label: 'MOLOCHIO',
      },
      {
        value: 'F324',
        label: 'MONASTERACE',
      },
      {
        value: 'F779',
        label: 'MOTTA SAN GIOVANNI',
      },
      {
        value: 'G082',
        label: 'OPPIDO o OPPIDO MAMERTINA',
      },
      {
        value: 'G138',
        label: 'ORTI',
      },
      {
        value: 'G277',
        label: 'PALIZZI',
      },
      {
        value: 'G288',
        label: 'PALMI',
      },
      {
        value: 'G326',
        label: 'PARACORIO',
      },
      {
        value: 'G394',
        label: 'PAZZANO',
      },
      {
        value: 'G405',
        label: 'PEDAVOLI',
      },
      {
        value: 'G422',
        label: 'PELLARO',
      },
      {
        value: 'G729',
        label: 'PLACANICA',
      },
      {
        value: 'G735',
        label: "PLATI'",
      },
      {
        value: 'G745',
        label: 'PODARGONI',
      },
      {
        value: 'G791',
        label: 'POLISTENA',
      },
      {
        value: 'G905',
        label: 'PORTIGLIOLA',
      },
      {
        value: 'H013',
        label: 'PRECACORE o SAMO',
      },
      {
        value: 'H155',
        label: 'RADICENA',
      },
      {
        value: 'H224',
        label: 'REGGIO DI CALABRIA',
      },
      {
        value: 'H265',
        label: 'RIACE',
      },
      {
        value: 'H359',
        label: 'RIZZICONI',
      },
      {
        value: 'H408',
        label: 'ROCCAFORTE o ROCCAFORTE DEL GRECO',
      },
      {
        value: 'H456',
        label: 'ROCCELLA o ROCCELLA IONICA',
      },
      {
        value: 'H489',
        label: 'ROGHUDI',
      },
      {
        value: 'H557',
        label: 'ROSALI',
      },
      {
        value: 'H558',
        label: 'ROSARNO',
      },
      {
        value: 'H709',
        label: 'SALICE o SALICE CALABRO',
      },
      {
        value: 'H741',
        label: 'SAMBATELLO',
      },
      {
        value: 'H751',
        label: 'SAMO DI CALABRIA',
      },
      {
        value: 'M277',
        label: 'SAN FERDINANDO',
      },
      {
        value: 'H889',
        label: 'SAN GIORGIO o SAN GIORGIO MORGETO',
      },
      {
        value: 'H903',
        label: 'SAN GIOVANNI o SAN GIOVANNI DI GERACE',
      },
      {
        value: 'H934',
        label: 'SAN GIUSEPPE o VILLA SAN GIUSEPPE',
      },
      {
        value: 'H959',
        label: 'SAN LORENZO',
      },
      {
        value: 'H970',
        label: 'SAN LUCA',
      },
      {
        value: 'I083',
        label: 'SAN PIER FEDELE o SAN PIETRO',
      },
      {
        value: 'I102',
        label: "SAN PIETRO DI CARIDA'",
      },
      {
        value: 'I132',
        label: 'SAN PROCOPIO',
      },
      {
        value: 'I139',
        label: 'SAN ROBERTO',
      },
      {
        value: 'I198',
        label: "SANT'AGATA DEL BIANCO",
      },
      {
        value: 'I214',
        label: "SANT'ALESSIO o SANT'ALESSIO IN ASPROMONTE",
      },
      {
        value: 'I333',
        label: "SANT'EUFEMIA D'ASPROMONTE",
      },
      {
        value: 'I341',
        label: "SANT'ILARIO o SANT'ILARIO DELLO IONIO",
      },
      {
        value: 'I176',
        label: "SANTA CRISTINA o SANTA CRISTINA D'ASPROMONTE",
      },
      {
        value: 'I371',
        label: 'SANTO STEFANO IN ASPROMONTE',
      },
      {
        value: 'I536',
        label: 'SCIDO',
      },
      {
        value: 'I537',
        label: 'SCILLA',
      },
      {
        value: 'I600',
        label: 'SEMINARA',
      },
      {
        value: 'I656',
        label: 'SERRATA',
      },
      {
        value: 'I725',
        label: 'SIDERNO',
      },
      {
        value: 'I753',
        label: 'SINOPOLI',
      },
      {
        value: 'I936',
        label: 'STAITI',
      },
      {
        value: 'I955',
        label: 'STIGNANO',
      },
      {
        value: 'I956',
        label: 'STILO',
      },
      {
        value: 'L063',
        label: 'TAURIANOVA',
      },
      {
        value: 'L127',
        label: 'TERRANOVA o TERRANOVA SAPPO MINULIO',
      },
      {
        value: 'L391',
        label: 'TRESILICO',
      },
      {
        value: 'L673',
        label: 'VARAPODIO',
      },
      {
        value: 'M018',
        label: 'VILLA SAN GIOVANNI',
      },
    ],
  },

  {
    value: 'RE',
    label: 'Reggio Emilia',
    children: [
      {
        value: 'A162',
        label: 'ALBINEA',
      },
      {
        value: 'A573',
        label: 'BAGNOLO o BAGNOLO IN PIANO',
      },
      {
        value: 'A586',
        label: 'BAISO',
      },
      {
        value: 'A850',
        label: 'BIBBIANO',
      },
      {
        value: 'A988',
        label: 'BORETTO',
      },
      {
        value: 'B156',
        label: 'BRESCELLO',
      },
      {
        value: 'B283',
        label: 'BUSANA',
      },
      {
        value: 'B328',
        label: 'CADELBOSCO DI SOPRA',
      },
      {
        value: 'B499',
        label: 'CAMPAGNOLA o CAMPAGNOLA EMILIA',
      },
      {
        value: 'B502',
        label: 'CAMPEGINE',
      },
      {
        value: 'C669',
        label: "CANOSSA o CIANO o CIANO D'ENZA",
      },
      {
        value: 'B825',
        label: 'CARPINETI',
      },
      {
        value: 'B893',
        label: 'CASALGRANDE',
      },
      {
        value: 'B967',
        label: 'CASINA',
      },
      {
        value: 'C141',
        label: 'CASTELLARANO',
      },
      {
        value: 'C218',
        label: 'CASTELNOVO DI SOTTO',
      },
      {
        value: 'C219',
        label: "CASTELNOVO NE' MONTI",
      },
      {
        value: 'C405',
        label: 'CAVRIAGO',
      },
      {
        value: 'C840',
        label: 'COLLAGNA o CULAGNA',
      },
      {
        value: 'D037',
        label: 'CORREGGIO',
      },
      {
        value: 'D450',
        label: 'FABBRICO',
      },
      {
        value: 'D934',
        label: 'GATTATICO',
      },
      {
        value: 'D953',
        label: 'GAZZANO',
      },
      {
        value: 'E232',
        label: 'GUALTIERI',
      },
      {
        value: 'E253',
        label: 'GUASTALLA',
      },
      {
        value: 'E585',
        label: 'LIGONCHIO',
      },
      {
        value: 'E772',
        label: 'LUZZARA',
      },
      {
        value: 'F463',
        label: 'MONTECCHIO EMILIA',
      },
      {
        value: 'F960',
        label: 'NOVELLARA',
      },
      {
        value: 'G654',
        label: 'PIEVE SAN VINCENZO o RAMISETO',
      },
      {
        value: 'G947',
        label: 'POVIGLIO',
      },
      {
        value: 'H122',
        label: 'QUATTRO CASTELLA',
      },
      {
        value: 'H223',
        label: "REGGIO o REGGIO NELL'EMILIA",
      },
      {
        value: 'H225',
        label: 'REGGIOLO',
      },
      {
        value: 'H298',
        label: 'RIO o RIO SALICETO',
      },
      {
        value: 'H500',
        label: 'ROLO',
      },
      {
        value: 'H628',
        label: 'RUBIERA',
      },
      {
        value: 'I011',
        label: 'SAN MARTINO IN RIO',
      },
      {
        value: 'I123',
        label: "SAN POLO o SAN POLO D'ENZA o SAN POLO D'ENZA IN CAVIANO",
      },
      {
        value: 'I342',
        label: "SANT'ILARIO o SANT'ILARIO D'ENZA",
      },
      {
        value: 'I496',
        label: 'SCANDIANO',
      },
      {
        value: 'L184',
        label: 'TOANO',
      },
      {
        value: 'M364',
        label: 'VENTASSO',
      },
      {
        value: 'L815',
        label: 'VETTO',
      },
      {
        value: 'L820',
        label: 'VEZZANO o VEZZANO SUL CROSTOLO',
      },
      {
        value: 'L831',
        label: 'VIANO',
      },
      {
        value: 'L969',
        label: 'VILLA MINOZZO',
      },
    ],
  },

  {
    value: 'RG',
    label: 'Ragusa',
    children: [
      {
        value: 'A014',
        label: 'ACATE o BISCARI',
      },
      {
        value: 'C612',
        label: 'CHIARAMONTE GULFI o CHIARAMONTE',
      },
      {
        value: 'C927',
        label: 'COMISO',
      },
      {
        value: 'E016',
        label: 'GIARRATANA',
      },
      {
        value: 'E366',
        label: 'ISPICA o SPACCAFORNO',
      },
      {
        value: 'F258',
        label: 'MODICA',
      },
      {
        value: 'F610',
        label: 'MONTEROSSO o MONTEROSSO ALMO',
      },
      {
        value: 'G953',
        label: 'POZZALLO',
      },
      {
        value: 'H163',
        label: 'RAGUSA',
      },
      {
        value: 'H164',
        label: 'RAGUSA IBLA o RAGUSA INFERIORE',
      },
      {
        value: 'I178',
        label: 'SANTA CROCE o SANTA CROCE CAMERINA',
      },
      {
        value: 'I535',
        label: 'SCICLI',
      },
      {
        value: 'M088',
        label: 'VITTORIA',
      },
    ],
  },

  {
    value: 'RI',
    label: 'Rieti',
    children: [
      {
        value: 'A019',
        label: 'ACCUMOLI',
      },
      {
        value: 'A258',
        label: 'AMATRICE',
      },
      {
        value: 'A315',
        label: 'ANTRODOCO',
      },
      {
        value: 'A464',
        label: 'ASCREA',
      },
      {
        value: 'A472',
        label: 'ASPRA o ASPRA SABINA o CASPERIA',
      },
      {
        value: 'A765',
        label: 'BELMONTE o BELMONTE IN SABINA',
      },
      {
        value: 'A981',
        label: 'BORBONA',
      },
      {
        value: 'A996',
        label: 'BORGHETTO o BORGO VELINO',
      },
      {
        value: 'B008',
        label: 'BORGOCOLLEFEGATO o BORGOROSE',
      },
      {
        value: 'B595',
        label: 'CANEMORTO o ORVINIO',
      },
      {
        value: 'B627',
        label: 'CANTALICE',
      },
      {
        value: 'B631',
        label: 'CANTALUPO o CANTALUPO IN SABINA',
      },
      {
        value: 'B934',
        label: 'CASAPROTA',
      },
      {
        value: 'C268',
        label: "CASTEL SANT'ANGELO",
      },
      {
        value: 'C224',
        label: 'CASTELNUOVO o CASTELNUOVO DI FARFA',
      },
      {
        value: 'C490',
        label: 'CERCHIARA o CERCHIARA DI SABINA',
      },
      {
        value: 'C746',
        label: 'CITTADUCALE',
      },
      {
        value: 'C749',
        label: 'CITTAREALE',
      },
      {
        value: 'C857',
        label: 'COLLE DI TORA',
      },
      {
        value: 'C859',
        label: 'COLLEGIOVE',
      },
      {
        value: 'C876',
        label: 'COLLEVECCHIO',
      },
      {
        value: 'C880',
        label: 'COLLI DI LABRO o COLLI SUL VELINO',
      },
      {
        value: 'C946',
        label: 'CONCERVIANO',
      },
      {
        value: 'C959',
        label: 'CONFIGNI',
      },
      {
        value: 'C969',
        label: 'CONTIGLIANO',
      },
      {
        value: 'D124',
        label: 'COTTANELLO',
      },
      {
        value: 'D493',
        label: 'FARA o FARA IN SABINA',
      },
      {
        value: 'D560',
        label: 'FIAMIGNANO',
      },
      {
        value: 'D689',
        label: 'FORANO',
      },
      {
        value: 'D785',
        label: 'FRASSO o FRASSO SABINO',
      },
      {
        value: 'E160',
        label: 'GRECCIO',
      },
      {
        value: 'E393',
        label: 'LABRO',
      },
      {
        value: 'E535',
        label: 'LEONESSA',
      },
      {
        value: 'E681',
        label: 'LONGONE o LONGONE DI SAN SALVATORE MAGGIORE o LONGONE SABINO',
      },
      {
        value: 'E728',
        label: 'LUGNANO o LUGNANO DI VILLA TROIANA o VAZIA',
      },
      {
        value: 'E812',
        label: 'MAGLIANO o MAGLIANO SABINA',
      },
      {
        value: 'E927',
        label: 'MARCETELLI',
      },
      {
        value: 'F193',
        label: 'MICIGLIANO',
      },
      {
        value: 'F319',
        label: 'MOMPEO',
      },
      {
        value: 'F430',
        label: 'MONTASOLA',
      },
      {
        value: 'F446',
        label: 'MONTEBUONO',
      },
      {
        value: 'F541',
        label: 'MONTELEONE o MONTELEONE SABINO',
      },
      {
        value: 'F579',
        label: 'MONTENERO SABINO',
      },
      {
        value: 'F687',
        label: 'MONTOPOLI o MONTOPOLI DI SABINA',
      },
      {
        value: 'F746',
        label: 'MORRO o MORRO REATINO',
      },
      {
        value: 'F876',
        label: 'NESPOLO',
      },
      {
        value: 'G232',
        label: 'PAGANICO SABINO',
      },
      {
        value: 'G498',
        label: 'PESCOROCCHIANO',
      },
      {
        value: 'G507',
        label: 'PETESCIA o TURANIA',
      },
      {
        value: 'G513',
        label: 'PETRELLA o PETRELLA SALTO',
      },
      {
        value: 'G756',
        label: 'POGGIO BUSTONE',
      },
      {
        value: 'G757',
        label: 'POGGIO CATINO',
      },
      {
        value: 'G759',
        label: 'POGGIO FIDONI',
      },
      {
        value: 'G763',
        label: 'POGGIO MIRTETO',
      },
      {
        value: 'G764',
        label: 'POGGIO MOIANO',
      },
      {
        value: 'G765',
        label: 'POGGIO NATIVO',
      },
      {
        value: 'G770',
        label: 'POGGIO SAN LORENZO',
      },
      {
        value: 'G934',
        label: 'POSTA',
      },
      {
        value: 'H282',
        label: 'RIETI',
      },
      {
        value: 'H354',
        label: 'RIVODUTRI',
      },
      {
        value: 'H446',
        label: 'ROCCA SINIBALDA',
      },
      {
        value: 'H427',
        label: 'ROCCANTICA',
      },
      {
        value: 'H713',
        label: 'SALISANO',
      },
      {
        value: 'I499',
        label: 'SCANDRIGLIA',
      },
      {
        value: 'I581',
        label: 'SELCI',
      },
      {
        value: 'I959',
        label: 'STIMIGLIANO',
      },
      {
        value: 'L046',
        label: 'TARANO',
      },
      {
        value: 'L189',
        label: 'TOFFIA',
      },
      {
        value: 'L286',
        label: 'TORRI o TORRI IN SABINA',
      },
      {
        value: 'L293',
        label: 'TORRICELLA o TORRICELLA IN SABINA',
      },
      {
        value: 'L525',
        label: 'VACONE',
      },
    ],
  },

  {
    value: 'RM',
    label: 'Roma',
    children: [
      {
        value: 'A062',
        label: 'AFFILE',
      },
      {
        value: 'A084',
        label: 'AGOSTA',
      },
      {
        value: 'A132',
        label: 'ALBANO o ALBANO LAZIALE',
      },
      {
        value: 'A210',
        label: 'ALLUMIERE',
      },
      {
        value: 'A297',
        label: 'ANGUILLARA o ANGUILLARA SABAZIA',
      },
      {
        value: 'A309',
        label: 'ANTICOLI o ANTICOLI CORRADO',
      },
      {
        value: 'A323',
        label: 'ANZIO',
      },
      {
        value: 'A370',
        label: "ARCINAZZO ROMANO o PONZA o PONZA D'ARCINAZZO",
      },
      {
        value: 'M213',
        label: 'ARDEA',
      },
      {
        value: 'A401',
        label: 'ARICCIA',
      },
      {
        value: 'A412',
        label: 'ARLENA o ARLENA DI CASTRO',
      },
      {
        value: 'A446',
        label: 'ARSOLI',
      },
      {
        value: 'A449',
        label: 'ARTENA o MONFORTINO o MONTEFORTINO',
      },
      {
        value: 'A749',
        label: 'BELLEGRA o CIVITELLA o CIVITELLA SAN SISTO',
      },
      {
        value: 'M299',
        label: 'BOVILLE',
      },
      {
        value: 'B114',
        label: 'BRACCIANO',
      },
      {
        value: 'B472',
        label: 'CAMERATA o CAMERATA NUOVA',
      },
      {
        value: 'B496',
        label: 'CAMPAGNANO o CAMPAGNANO DI ROMA',
      },
      {
        value: 'B576',
        label: 'CANALE E MONTEVIRGINIO o CANALE MONTERANO',
      },
      {
        value: 'B632',
        label: 'CANTALUPO BARDELLA o MANDELA',
      },
      {
        value: 'B635',
        label: 'CANTERANO',
      },
      {
        value: 'B649',
        label: 'CAPENA o LEPRIGNANO',
      },
      {
        value: 'B687',
        label: 'CAPRANICA o CAPRANICA PRENESTINA',
      },
      {
        value: 'B828',
        label: 'CARPINETO o CARPINETO ROMANO',
      },
      {
        value: 'B932',
        label: 'CASAPE',
      },
      {
        value: 'C116',
        label: 'CASTEL GANDOLFO',
      },
      {
        value: 'C203',
        label: 'CASTEL MADAMA',
      },
      {
        value: 'C266',
        label: 'CASTEL SAN PIETRO o CASTEL SAN PIETRO ROMANO',
      },
      {
        value: 'C237',
        label: 'CASTELNUOVO DI PORTO',
      },
      {
        value: 'C390',
        label: 'CAVE',
      },
      {
        value: 'C518',
        label: 'CERNETO o CERRETO o CERRETO LAZIALE o MONTERUFO',
      },
      {
        value: 'C543',
        label: 'CERVARA o CERVARA DI ROMA',
      },
      {
        value: 'C552',
        label: 'CERVETERI',
      },
      {
        value: 'M272',
        label: 'CIAMPINO',
      },
      {
        value: 'C677',
        label: 'CICILIANO',
      },
      {
        value: 'C702',
        label: 'CINETO ROMANO o SCARPA',
      },
      {
        value: 'C767',
        label: 'CIVITA LAVINIA o LANUVIO',
      },
      {
        value: 'C773',
        label: 'CIVITAVECCHIA',
      },
      {
        value: 'C784',
        label: 'CIVITELLA SAN PAOLO',
      },
      {
        value: 'C858',
        label: 'COLLEFERRO',
      },
      {
        value: 'C900',
        label: 'COLONNA',
      },
      {
        value: 'D024',
        label: 'CORNETO o CORNETO TARQUINIA o TARQUINIA',
      },
      {
        value: 'D393',
        label: 'ELENA',
      },
      {
        value: 'D452',
        label: 'FABRICA o FABRICA DI ROMA',
      },
      {
        value: 'D561',
        label: 'FIANO o FIANO ROMANO',
      },
      {
        value: 'D586',
        label: 'FILACCIANO',
      },
      {
        value: 'M297',
        label: 'FIUMICINO',
      },
      {
        value: 'M309',
        label: 'FONTE NUOVA',
      },
      {
        value: 'D707',
        label: 'FORMELLO',
      },
      {
        value: 'D773',
        label: 'FRASCATI',
      },
      {
        value: 'D875',
        label: 'GALLICANO o GALLICANO NEL LAZIO',
      },
      {
        value: 'D945',
        label: 'GAVIGNANO',
      },
      {
        value: 'D964',
        label: 'GENAZZANO',
      },
      {
        value: 'D972',
        label: 'GENZANO o GENZANO DI ROMA',
      },
      {
        value: 'D978',
        label: 'GERANO',
      },
      {
        value: 'E091',
        label: 'GORGA',
      },
      {
        value: 'E204',
        label: 'GROTTAFERRATA',
      },
      {
        value: 'E263',
        label: 'GUIDONIA MONTECELIO',
      },
      {
        value: 'E330',
        label: 'ISCHIA o ISCHIA DI CASTRO',
      },
      {
        value: 'E382',
        label: 'JENNE',
      },
      {
        value: 'E392',
        label: 'LABICO o LUGNANO o LUGNANO LABICANO',
      },
      {
        value: 'M212',
        label: 'LADISPOLI',
      },
      {
        value: 'M207',
        label: 'LARIANO',
      },
      {
        value: 'E576',
        label: 'LICENZA',
      },
      {
        value: 'E813',
        label: 'MAGLIANO ROMANO',
      },
      {
        value: 'E900',
        label: 'MANZIANA',
      },
      {
        value: 'E908',
        label: 'MARANO o MARANO EQUO',
      },
      {
        value: 'E924',
        label: 'MARCELLINA',
      },
      {
        value: 'E958',
        label: 'MARINO',
      },
      {
        value: 'F064',
        label: 'MAZZANO o MAZZANO ROMANO',
      },
      {
        value: 'F127',
        label: 'MENTANA',
      },
      {
        value: 'F419',
        label: 'MONTALTO o MONTALTO DI CASTRO',
      },
      {
        value: 'F477',
        label: 'MONTE COMPATRI o MONTECOMPATRI',
      },
      {
        value: 'F590',
        label: 'MONTE PORZIO o MONTE PORZIO CATONE',
      },
      {
        value: 'F466',
        label: 'MONTECELIO o MONTICELLI',
      },
      {
        value: 'F504',
        label: 'MONTEFLAVIO',
      },
      {
        value: 'F534',
        label: 'MONTELANICO',
      },
      {
        value: 'F545',
        label: 'MONTELIBRETTI',
      },
      {
        value: 'F611',
        label: 'MONTEROTONDO',
      },
      {
        value: 'F692',
        label: 'MONTORIO ROMANO',
      },
      {
        value: 'F730',
        label: 'MORICONE',
      },
      {
        value: 'F734',
        label: 'MORLUPO',
      },
      {
        value: 'F857',
        label: 'NAZZANO',
      },
      {
        value: 'F865',
        label: 'NEMI',
      },
      {
        value: 'F871',
        label: 'NEROLA',
      },
      {
        value: 'F879',
        label: 'NETTUNIA',
      },
      {
        value: 'F880',
        label: 'NETTUNO',
      },
      {
        value: 'G022',
        label: 'OLEVANO o OLEVANO ROMANO',
      },
      {
        value: 'G111',
        label: 'ORIOLO o ORIOLO ROMANO',
      },
      {
        value: 'G274',
        label: 'PALESTRINA',
      },
      {
        value: 'G293',
        label: 'PALOMBARA o PALOMBARA SABINA',
      },
      {
        value: 'G444',
        label: 'PERCILE',
      },
      {
        value: 'G704',
        label: 'PISCIANO o PISONIANO',
      },
      {
        value: 'G784',
        label: 'POLI',
      },
      {
        value: 'G811',
        label: 'POMEZIA',
      },
      {
        value: 'G874',
        label: 'PONZANO o PONZANO ROMANO',
      },
      {
        value: 'H267',
        label: 'RIANO',
      },
      {
        value: 'H288',
        label: 'RIGNANO o RIGNANO FLAMINIO',
      },
      {
        value: 'H300',
        label: 'RIOFREDDO',
      },
      {
        value: 'H387',
        label: 'ROCCA CANTERANO',
      },
      {
        value: 'H401',
        label: 'ROCCA DI CAVE',
      },
      {
        value: 'H404',
        label: 'ROCCA DI PAPA',
      },
      {
        value: 'H432',
        label: 'ROCCA PRIORA',
      },
      {
        value: 'H441',
        label: 'ROCCA SANTO STEFANO',
      },
      {
        value: 'H411',
        label: 'ROCCAGIOVINE',
      },
      {
        value: 'H494',
        label: 'ROIATE',
      },
      {
        value: 'H501',
        label: 'ROMA',
      },
      {
        value: 'H618',
        label: 'ROVIANO',
      },
      {
        value: 'H658',
        label: 'SACROFANO o SCROFANO',
      },
      {
        value: 'H745',
        label: 'SAMBUCI',
      },
      {
        value: 'M295',
        label: 'SAN CESAREO',
      },
      {
        value: 'H942',
        label: 'SAN GREGORIO o SAN GREGORIO DA SASSOLA',
      },
      {
        value: 'H995',
        label: 'SAN MARTINO o SAN MARTINO AL CIMINO',
      },
      {
        value: 'I039',
        label: 'SAN MICHELE o SAN MICHELE IN TEVERINA',
      },
      {
        value: 'I125',
        label: 'SAN POLO DEI CAVALIERI',
      },
      {
        value: 'I400',
        label: 'SAN VITO o SAN VITO ROMANO',
      },
      {
        value: 'I284',
        label: "SANT'ANGELO IN CAPOCCIA o SANT'ANGELO ROMANO",
      },
      {
        value: 'I352',
        label: "SANT'ORESTE",
      },
      {
        value: 'I255',
        label: 'SANTA MARINELLA',
      },
      {
        value: 'I424',
        label: 'SARACINESCO',
      },
      {
        value: 'I573',
        label: 'SEGNI',
      },
      {
        value: 'I855',
        label: 'SORIANO o SORIANO NEL CIMINO',
      },
      {
        value: 'D475',
        label: 'STABIA o FALERIA',
      },
      {
        value: 'I992',
        label: 'SUBIACO',
      },
      {
        value: 'L182',
        label: 'TIVOLI',
      },
      {
        value: 'L192',
        label: 'TOLFA',
      },
      {
        value: 'L302',
        label: 'TORRITA o TORRITA TIBERINA',
      },
      {
        value: 'L310',
        label: 'TOSCANELLA o TUSCANIA',
      },
      {
        value: 'L401',
        label: 'TREVIGNANO o TREVIGNANO ROMANO',
      },
      {
        value: 'L611',
        label: 'VALLEPIETRA',
      },
      {
        value: 'L625',
        label: 'VALLINFREDA',
      },
      {
        value: 'L639',
        label: 'VALMONTONE',
      },
      {
        value: 'L719',
        label: 'VELLETRI',
      },
      {
        value: 'L713',
        label: 'VIANO o VEJANO',
      },
      {
        value: 'L851',
        label: 'VICOVARO',
      },
      {
        value: 'M095',
        label: 'VIVARO o VIVARO ROMANO',
      },
      {
        value: 'M141',
        label: 'ZAGAROLO',
      },
    ],
  },

  {
    value: 'RN',
    label: 'Rimini',
    children: [
      {
        value: 'A747',
        label: 'BELLARIA-IGEA MARINA',
      },
      {
        value: 'C357',
        label: 'CATTOLICA',
      },
      {
        value: 'D004',
        label: 'CORIANO',
      },
      {
        value: 'D961',
        label: 'GEMMANO',
      },
      {
        value: 'F346',
        label: 'MONDAINO',
      },
      {
        value: 'F476',
        label: 'MONTE COLOMBO',
      },
      {
        value: 'F523',
        label: 'MONTEGRIDOLFO',
      },
      {
        value: 'M368',
        label: 'MONTESCUDO-MONTE COLOMBO',
      },
      {
        value: 'G755',
        label: 'POGGIO BERNI',
      },
      {
        value: 'M324',
        label: 'POGGIO TORRIANA',
      },
      {
        value: 'H274',
        label: 'RICCIONE',
      },
      {
        value: 'H294',
        label: 'RIMINI',
      },
      {
        value: 'H724',
        label: 'SALUDECIO',
      },
      {
        value: 'H801',
        label: 'SAN CLEMENTE',
      },
      {
        value: 'H921',
        label: 'SAN GIOVANNI IN MARIGNANO',
      },
      {
        value: 'L797',
        label: 'VERUCCHIO',
      },
    ],
  },

  {
    value: 'RO',
    label: 'Rovigo',
    children: [
      {
        value: 'A059',
        label: 'ADRIA',
      },
      {
        value: 'A400',
        label: 'ARIANO o ARIANO NEL POLESINE',
      },
      {
        value: 'A435',
        label: "ARQUA' o ARQUA' POLESINE",
      },
      {
        value: 'A539',
        label: 'BADIA o BADIA POLESINE',
      },
      {
        value: 'A574',
        label: 'BAGNOLO o BAGNOLO DI PO',
      },
      {
        value: 'A754',
        label: 'BELLOMBRA',
      },
      {
        value: 'A795',
        label: 'BERGANTINO',
      },
      {
        value: 'A907',
        label: 'BOARA o BOARA POLESINE',
      },
      {
        value: 'B060',
        label: 'BORSEA',
      },
      {
        value: 'B069',
        label: 'BOSARO',
      },
      {
        value: 'B095',
        label: 'BOTTRIGHE',
      },
      {
        value: 'B290',
        label: 'BUSO o BUSO SARZANO',
      },
      {
        value: 'B348',
        label: "CA' EMO o FASANA POLESINE",
      },
      {
        value: 'B432',
        label: 'CALTO',
      },
      {
        value: 'B578',
        label: 'CANARO',
      },
      {
        value: 'B582',
        label: 'CANDA',
      },
      {
        value: 'C122',
        label: 'CASTELGUGLIELMO',
      },
      {
        value: 'C207',
        label: 'CASTELMASSA',
      },
      {
        value: 'C215',
        label: 'CASTELNOVO o CASTELNOVO BARIANO',
      },
      {
        value: 'C461',
        label: 'CENESELLI',
      },
      {
        value: 'C500',
        label: 'CEREGNANO',
      },
      {
        value: 'C942',
        label: 'CONCADIRAME',
      },
      {
        value: 'C967',
        label: 'CONTARINA o CONTARINA',
      },
      {
        value: 'C987',
        label: 'CORBOLA',
      },
      {
        value: 'D105',
        label: 'COSTA o COSTA DI ROVIGO',
      },
      {
        value: 'D161',
        label: 'CRESPINO',
      },
      {
        value: 'D176',
        label: 'CROCETTA',
      },
      {
        value: 'D337',
        label: 'DONADA',
      },
      {
        value: 'D568',
        label: 'FICAROLO',
      },
      {
        value: 'D577',
        label: 'FIESSO o FIESSO UMBERTIANO',
      },
      {
        value: 'D776',
        label: 'FRASSINELLE o FRASSINELLE POLESINE',
      },
      {
        value: 'D788',
        label: 'FRATTA o FRATTA POLESINE',
      },
      {
        value: 'D855',
        label: 'GAIBA',
      },
      {
        value: 'D942',
        label: 'GAVELLO',
      },
      {
        value: 'E008',
        label: 'GIACCIANO CON BARUCHELLA',
      },
      {
        value: 'E176',
        label: 'GRIGNANO o GRIGNANO DI POLESINE',
      },
      {
        value: 'E240',
        label: 'GUARDA VENETA',
      },
      {
        value: 'E522',
        label: 'LENDINARA',
      },
      {
        value: 'E689',
        label: 'LOREO',
      },
      {
        value: 'E761',
        label: 'LUSIA',
      },
      {
        value: 'F036',
        label: 'MASSA SUPERIORE',
      },
      {
        value: 'F095',
        label: 'MELARA',
      },
      {
        value: 'F994',
        label: 'OCCHIOBELLO',
      },
      {
        value: 'G323',
        label: 'PAPOZZE',
      },
      {
        value: 'G525',
        label: 'PETTORAZZA GRIMANI',
      },
      {
        value: 'G673',
        label: 'PINCARA',
      },
      {
        value: 'G782',
        label: 'POLESELLA',
      },
      {
        value: 'G836',
        label: 'PONTECCHIO o PONTECCHIO POLESINE',
      },
      {
        value: 'G923',
        label: 'PORTO TOLLE o SAN NICCOLO',
      },
      {
        value: 'G926',
        label: 'PORTO VIRO o TAGLIO DI PORTO VIRO',
      },
      {
        value: 'H169',
        label: 'RAMO DI PALO',
      },
      {
        value: 'H573',
        label: 'ROSOLINA',
      },
      {
        value: 'H620',
        label: 'ROVIGO',
      },
      {
        value: 'H667',
        label: 'SAGUEDO',
      },
      {
        value: 'H689',
        label: 'SALARA',
      },
      {
        value: 'H728',
        label: 'SALVATERRA',
      },
      {
        value: 'H768',
        label: 'SAN BELLINO',
      },
      {
        value: 'H996',
        label: 'SAN MARTINO o SAN MARTINO DI VENEZZE',
      },
      {
        value: 'I303',
        label: "SANT'APOLLINARE o SANT'APOLLINARE CON SELVA",
      },
      {
        value: 'I953',
        label: 'STIENTA',
      },
      {
        value: 'L026',
        label: 'TAGLIO DI PO',
      },
      {
        value: 'L359',
        label: 'TRECENTA',
      },
      {
        value: 'L918',
        label: "VILLA D'ADIGE o VILLABONA",
      },
      {
        value: 'L939',
        label: 'VILLADOSE',
      },
      {
        value: 'L967',
        label: 'VILLAMARZANA',
      },
      {
        value: 'L985',
        label: 'VILLANOVA DEL GHEBBO',
      },
      {
        value: 'L988',
        label: 'VILLANOVA MARCHESANA',
      },
    ],
  },

  {
    value: 'SA',
    label: 'Salerno',
    children: [
      {
        value: 'A023',
        label: 'ACERNO',
      },
      {
        value: 'A091',
        label: 'AGROPOLI',
      },
      {
        value: 'A128',
        label: 'ALBANELLA',
      },
      {
        value: 'A186',
        label: 'ALFANO',
      },
      {
        value: 'A230',
        label: 'ALTAVILLA o ALTAVILLA SILENTINA',
      },
      {
        value: 'A251',
        label: 'AMALFI',
      },
      {
        value: 'A294',
        label: 'ANGRI',
      },
      {
        value: 'A343',
        label: 'AQUARA',
      },
      {
        value: 'A460',
        label: 'ASCEA',
      },
      {
        value: 'A484',
        label: 'ATENA o ATENA LUCANA',
      },
      {
        value: 'A487',
        label: 'ATRANI',
      },
      {
        value: 'A495',
        label: 'AULETTA',
      },
      {
        value: 'A674',
        label: 'BARONISSI',
      },
      {
        value: 'A717',
        label: 'BATTIPAGLIA',
      },
      {
        value: 'M294',
        label: 'BELLIZZI',
      },
      {
        value: 'A756',
        label: 'BELLOSGUARDO',
      },
      {
        value: 'B115',
        label: 'BRACIGLIANO',
      },
      {
        value: 'B242',
        label: 'BUCCINO',
      },
      {
        value: 'B266',
        label: 'BUONABITACOLO',
      },
      {
        value: 'B351',
        label: 'CAGGIANO',
      },
      {
        value: 'B437',
        label: 'CALVANICO',
      },
      {
        value: 'B476',
        label: 'CAMEROTA',
      },
      {
        value: 'B492',
        label: 'CAMPAGNA',
      },
      {
        value: 'B555',
        label: 'CAMPORA',
      },
      {
        value: 'B608',
        label: 'CANNALONGA',
      },
      {
        value: 'B644',
        label: 'CAPACCIO o CAPACCIO PAESTUM',
      },
      {
        value: 'B657',
        label: 'CAPITELLO o POLICASTRO DEL GOLFO',
      },
      {
        value: 'B895',
        label: 'CASAL VELINO o CASALICCHIO',
      },
      {
        value: 'B868',
        label: 'CASALBUONO o CASALNUOVO',
      },
      {
        value: 'B888',
        label: 'CASALETTO o CASALETTO SPARTANO',
      },
      {
        value: 'B959',
        label: 'CASELLE o CASELLE IN PITTARI',
      },
      {
        value: 'C256',
        label: 'CASTEL RUGGERO',
      },
      {
        value: 'C259',
        label: 'CASTEL SAN GIORGIO o SAN GIORGIO',
      },
      {
        value: 'C262',
        label: 'CASTEL SAN LORENZO',
      },
      {
        value: 'C069',
        label: 'CASTELCIVITA o CASTELLUCCIA',
      },
      {
        value: 'C125',
        label: 'CASTELLABATE',
      },
      {
        value: 'C231',
        label: 'CASTELNUOVO CILENTO',
      },
      {
        value: 'C235',
        label: 'CASTELNUOVO DI CONZA',
      },
      {
        value: 'C306',
        label: 'CASTIGLIONE o CASTIGLIONE DEL GENOVESI',
      },
      {
        value: 'C361',
        label: "CAVA o CAVA DE' TIRRENI",
      },
      {
        value: 'C444',
        label: 'CELLE o CELLE DI BULGHERIA',
      },
      {
        value: 'C470',
        label: 'CENTOLA',
      },
      {
        value: 'C485',
        label: 'CERASO',
      },
      {
        value: 'C584',
        label: 'CETARA',
      },
      {
        value: 'C676',
        label: 'CICERALE',
      },
      {
        value: 'C879',
        label: 'COLLIANO',
      },
      {
        value: 'C940',
        label: 'CONCA o CONCA DEI MARINI o CONCA MARINI',
      },
      {
        value: 'C973',
        label: 'CONTRONE',
      },
      {
        value: 'C974',
        label: 'CONTURSI TERME',
      },
      {
        value: 'C984',
        label: 'CORBARA',
      },
      {
        value: 'D011',
        label: 'CORLETO o CORLETO MONFORTE',
      },
      {
        value: 'D195',
        label: 'CUCCARO o CUCCARO VETERE',
      },
      {
        value: 'D292',
        label: 'DIANO o TEGGIANO',
      },
      {
        value: 'D390',
        label: 'EBOLI',
      },
      {
        value: 'D527',
        label: 'FELITTO',
      },
      {
        value: 'D615',
        label: 'FISCIANO',
      },
      {
        value: 'D647',
        label: 'FOGNA',
      },
      {
        value: 'D826',
        label: 'FURORE',
      },
      {
        value: 'D832',
        label: 'FUTANI',
      },
      {
        value: 'D866',
        label: 'GALDO',
      },
      {
        value: 'E026',
        label: 'GIFFONI SEI CASALI',
      },
      {
        value: 'E027',
        label: 'GIFFONI VALLE PIANA',
      },
      {
        value: 'E037',
        label: 'GIOI',
      },
      {
        value: 'E060',
        label: 'GIUNGANO',
      },
      {
        value: 'E365',
        label: 'ISPANI',
      },
      {
        value: 'E480',
        label: 'LAUREANA o LAUREANA CILENTO',
      },
      {
        value: 'E485',
        label: 'LAURINO',
      },
      {
        value: 'E486',
        label: 'LAURITO',
      },
      {
        value: 'E498',
        label: 'LAVIANO',
      },
      {
        value: 'E580',
        label: 'LICUSATI',
      },
      {
        value: 'E767',
        label: 'LUSTRA',
      },
      {
        value: 'E814',
        label: 'MAGLIANO VETERE',
      },
      {
        value: 'E839',
        label: 'MAIORI',
      },
      {
        value: 'F138',
        label: 'MERCATO o MERCATO SAN SEVERINO o SAN SEVERINO ROTA',
      },
      {
        value: 'F223',
        label: 'MINORI',
      },
      {
        value: 'F278',
        label: 'MOIO o MOIO DELLA CIVITELLA',
      },
      {
        value: 'F426',
        label: 'MONTANO o MONTANO ANTILIA',
      },
      {
        value: 'F618',
        label: 'MONTE SAN GIACOMO o SAN GIACOMO',
      },
      {
        value: 'F479',
        label: 'MONTECORICE o ORTODONICO',
      },
      {
        value: 'F480',
        label: 'MONTECORVINO PUGLIANO',
      },
      {
        value: 'F481',
        label: 'MONTECORVINO ROVELLA',
      },
      {
        value: 'F507',
        label: 'MONTEFORTE o MONTEFORTE CILENTO',
      },
      {
        value: 'F625',
        label: 'MONTESANO o MONTESANO SULLA MARCELLANA',
      },
      {
        value: 'F731',
        label: 'MORIGERATI',
      },
      {
        value: 'F912',
        label: 'NOCERA INFERIORE',
      },
      {
        value: 'F913',
        label: 'NOCERA SUPERIORE',
      },
      {
        value: 'F967',
        label: 'NOVI o NOVI VELIA',
      },
      {
        value: 'G011',
        label: 'OGLIASTRO o OGLIASTRO CILENTO',
      },
      {
        value: 'G023',
        label: 'OLEVANO o OLEVANO SUL FUSCIANO o OLEVANO SUL TUSCIANO',
      },
      {
        value: 'G039',
        label: 'OLIVETO o OLIVETO CITRA',
      },
      {
        value: 'G063',
        label: 'OMIGNANO',
      },
      {
        value: 'G121',
        label: 'ORRIA',
      },
      {
        value: 'G192',
        label: 'OTTATI',
      },
      {
        value: 'G226',
        label: 'PADULA',
      },
      {
        value: 'G230',
        label: 'PAGANI',
      },
      {
        value: 'G292',
        label: 'PALO o PALOMONTE',
      },
      {
        value: 'G426',
        label: 'PELLEZZANO',
      },
      {
        value: 'G447',
        label: 'PERDIFUMO',
      },
      {
        value: 'G455',
        label: 'PERITO',
      },
      {
        value: 'G476',
        label: 'PERTOSA',
      },
      {
        value: 'G509',
        label: 'PETINA',
      },
      {
        value: 'G538',
        label: 'PIAGGINE',
      },
      {
        value: 'G539',
        label: 'PIAGGINE SOPRANE',
      },
      {
        value: 'G540',
        label: "PIAGGINE SOTTANE o VALLE DELL'ANGELO",
      },
      {
        value: 'G707',
        label: 'PISCIOTTA',
      },
      {
        value: 'G793',
        label: 'POLLA',
      },
      {
        value: 'G796',
        label: 'POLLICA',
      },
      {
        value: 'G834',
        label: 'PONTECAGNANO FAIANO',
      },
      {
        value: 'G887',
        label: 'PORCILI o STELLA CILENTO',
      },
      {
        value: 'G932',
        label: 'POSITANO',
      },
      {
        value: 'G939',
        label: 'POSTIGLIONE',
      },
      {
        value: 'G976',
        label: 'PRAIANO',
      },
      {
        value: 'H062',
        label: 'PRIGNANO o PRIGNANO CILENTO',
      },
      {
        value: 'H198',
        label: 'RAVELLO',
      },
      {
        value: 'H277',
        label: 'RICIGLIANO',
      },
      {
        value: 'H394',
        label: 'ROCCADASPIDE',
      },
      {
        value: 'H412',
        label: 'ROCCAGLORIOSA',
      },
      {
        value: 'H431',
        label: 'ROCCAPIEMONTE',
      },
      {
        value: 'H485',
        label: 'ROFRANO',
      },
      {
        value: 'H503',
        label: 'ROMAGNANO o ROMAGNANO AL MONTE',
      },
      {
        value: 'H564',
        label: 'ROSCIGNO',
      },
      {
        value: 'H644',
        label: 'RUTINO',
      },
      {
        value: 'H654',
        label: 'SACCO',
      },
      {
        value: 'H683',
        label: 'SALA o SALA CONSILINA',
      },
      {
        value: 'H686',
        label: 'SALA DI GIOI o SALENTO',
      },
      {
        value: 'H703',
        label: 'SALERNO',
      },
      {
        value: 'H732',
        label: 'SALVITELLE',
      },
      {
        value: 'H800',
        label: 'SAN CIPRIANO o SAN CIPRIANO PICENTINO',
      },
      {
        value: 'H907',
        label: 'SAN GIOVANNI A PIRO',
      },
      {
        value: 'H943',
        label: 'SAN GREGORIO o SAN GREGORIO MAGNO',
      },
      {
        value: 'H977',
        label: 'SAN MANGO o SAN MANGO PIEMONTE',
      },
      {
        value: 'I019',
        label: 'SAN MARZANO o SAN MARZANO SUL SARNO',
      },
      {
        value: 'I031',
        label: 'SAN MAURO CILENTO',
      },
      {
        value: 'I032',
        label: 'SAN MAURO LA BRUCA',
      },
      {
        value: 'I089',
        label: 'SAN PIETRO o SAN PIETRO AL TANAGRO',
      },
      {
        value: 'I143',
        label: 'SAN RUFO',
      },
      {
        value: 'I377',
        label: 'SAN VALENTINO o SAN VALENTINO TORIO',
      },
      {
        value: 'I278',
        label: "SANT'ANGELO A FASANELLA",
      },
      {
        value: 'I307',
        label: "SANT'ARSENIO",
      },
      {
        value: 'I317',
        label: "SANT'EGIDIO o SANT'EGIDIO DEL MONTE ALBINO",
      },
      {
        value: 'I253',
        label: 'SANTA MARINA',
      },
      {
        value: 'I260',
        label: 'SANTA MENNA o SANTOMENNA',
      },
      {
        value: 'I410',
        label: 'SANZA',
      },
      {
        value: 'I422',
        label: 'SAPRI',
      },
      {
        value: 'I438',
        label: 'SARNO',
      },
      {
        value: 'I451',
        label: 'SASSANO',
      },
      {
        value: 'I483',
        label: 'SCAFATI',
      },
      {
        value: 'I486',
        label: 'SCALA',
      },
      {
        value: 'I648',
        label: 'SERRAMEZZANA',
      },
      {
        value: 'I666',
        label: 'SERRE',
      },
      {
        value: 'I677',
        label: 'SESSA CILENTO',
      },
      {
        value: 'I720',
        label: 'SIANO',
      },
      {
        value: 'M253',
        label: 'SICIGNANO o SICIGNANO DEGLI ALBURNI',
      },
      {
        value: 'I960',
        label: 'STIO',
      },
      {
        value: 'L212',
        label: 'TORCHIARA',
      },
      {
        value: 'L233',
        label: 'TORRACA',
      },
      {
        value: 'L274',
        label: 'TORRE ORSAIA',
      },
      {
        value: 'L306',
        label: 'TORTORELLA',
      },
      {
        value: 'L323',
        label: 'TRAMONTI',
      },
      {
        value: 'L377',
        label: 'TRENTINARA',
      },
      {
        value: 'L628',
        label: 'VALLO o VALLO DELLA LUCANIA',
      },
      {
        value: 'L656',
        label: 'VALVA',
      },
      {
        value: 'L835',
        label: 'VIBONATI',
      },
      {
        value: 'L860',
        label: 'VIETRI o VIETRI SUL MARE',
      },
    ],
  },

  {
    value: 'SI',
    label: 'Siena',
    children: [
      {
        value: 'A006',
        label: 'ABBADIA SAN SALVATORE',
      },
      {
        value: 'A461',
        label: 'ASCIANO',
      },
      {
        value: 'A468',
        label: 'ASINALUNGA o SINALUNGA',
      },
      {
        value: 'B269',
        label: 'BUONCONVENTO',
      },
      {
        value: 'B984',
        label: "CASOLE o CASOLE D'ELSA",
      },
      {
        value: 'C172',
        label: 'CASTELLINA IN CHIANTI',
      },
      {
        value: 'C227',
        label: 'CASTELNUOVO BERARDENGA',
      },
      {
        value: 'C313',
        label: "CASTIGLIONE D'ORCIA",
      },
      {
        value: 'C587',
        label: 'CETONA',
      },
      {
        value: 'C608',
        label: 'CHIANCIANO o CHIANCIANO TERME',
      },
      {
        value: 'C661',
        label: 'CHIUSDINO',
      },
      {
        value: 'C662',
        label: 'CHIUSI',
      },
      {
        value: 'C847',
        label: "COLLE o COLLE DI VAL D'ELSA",
      },
      {
        value: 'D858',
        label: 'GAIOLE o GAIOLE IN CHIANTI',
      },
      {
        value: 'F038',
        label: "MASSE DEL TERZO DI CITTA' o MASSE DI CITTA'",
      },
      {
        value: 'F039',
        label: 'MASSE DI SAN MARTINO',
      },
      {
        value: 'F040',
        label: 'MASSE DI SIENA',
      },
      {
        value: 'F402',
        label: 'MONTALCINO',
      },
      {
        value: 'M378',
        label: 'MONTALCINO',
      },
      {
        value: 'F592',
        label: 'MONTEPULCIANO',
      },
      {
        value: 'F598',
        label: 'MONTERIGGIONI',
      },
      {
        value: 'F605',
        label: "MONTERONI o MONTERONI D'ARBIA",
      },
      {
        value: 'F676',
        label: 'MONTICIANO',
      },
      {
        value: 'F815',
        label: 'MURLO',
      },
      {
        value: 'G547',
        label: 'PIANCASTAGNAIO',
      },
      {
        value: 'G602',
        label: 'PIENZA',
      },
      {
        value: 'G752',
        label: 'POGGIBONSI',
      },
      {
        value: 'H153',
        label: 'RADDA o RADDA IN CHIANTI',
      },
      {
        value: 'H156',
        label: 'RADICOFANI',
      },
      {
        value: 'H157',
        label: 'RADICONDOLI',
      },
      {
        value: 'H185',
        label: 'RAPOLANO o RAPOLANO TERME',
      },
      {
        value: 'H790',
        label: 'SAN CASCIANO DEI BAGNI',
      },
      {
        value: 'H875',
        label: 'SAN GIMIGNANO',
      },
      {
        value: 'H911',
        label: "SAN GIOVANNI D'ASSO",
      },
      {
        value: 'I135',
        label: "SAN QUIRICO o SAN QUIRICO D'ORCIA",
      },
      {
        value: 'I445',
        label: 'SARTEANO',
      },
      {
        value: 'I726',
        label: 'SIENA',
      },
      {
        value: 'I877',
        label: 'SOVICILLE',
      },
      {
        value: 'L303',
        label: 'TORRITA o TORRITA DI SIENA',
      },
      {
        value: 'L384',
        label: 'TREQUANDA',
      },
    ],
  },

  {
    value: 'SO',
    label: 'Sondrio',
    children: [
      {
        value: 'A030',
        label: 'ACQUA',
      },
      {
        value: 'A135',
        label: 'ALBAREDO o ALBAREDO PER SAN MARCO',
      },
      {
        value: 'A172',
        label: 'ALBOSAGGIA',
      },
      {
        value: 'A273',
        label: 'ANDALO o ANDALO VALTELLINO',
      },
      {
        value: 'A337',
        label: 'APRICA',
      },
      {
        value: 'A382',
        label: 'ARDENNO',
      },
      {
        value: 'A777',
        label: 'BEMA',
      },
      {
        value: 'A787',
        label: 'BERBENNO o BERBENNO DI VALTELLINA',
      },
      {
        value: 'A848',
        label: 'BIANZONE',
      },
      {
        value: 'A921',
        label: 'BOFFETTO',
      },
      {
        value: 'B049',
        label: 'BORMIO',
      },
      {
        value: 'B255',
        label: 'BUGLIO o BUGLIO IN MONTE',
      },
      {
        value: 'B366',
        label: 'CAIOLO',
      },
      {
        value: 'B530',
        label: 'CAMPODOLCINO',
      },
      {
        value: 'B571',
        label: 'CAMPOVICO',
      },
      {
        value: 'B993',
        label: 'CASPOGGIO',
      },
      {
        value: 'C186',
        label: "CASTELLO DELL'ACQUA",
      },
      {
        value: 'C325',
        label: 'CASTIONE o CASTIONE ANDEVENNO',
      },
      {
        value: 'C418',
        label: 'CEDRASCO',
      },
      {
        value: 'C493',
        label: 'CERCINO',
      },
      {
        value: 'C623',
        label: 'CHIAVENNA',
      },
      {
        value: 'C628',
        label: 'CHIESA o CHIESA IN VALMALENCO',
      },
      {
        value: 'C651',
        label: 'CHIURO',
      },
      {
        value: 'C709',
        label: 'CINO',
      },
      {
        value: 'C785',
        label: 'CIVO',
      },
      {
        value: 'C903',
        label: 'COLORINA',
      },
      {
        value: 'D088',
        label: 'COSIO o COSIO VALTELLINO',
      },
      {
        value: 'D258',
        label: 'DAZIO',
      },
      {
        value: 'D266',
        label: 'DELEBIO',
      },
      {
        value: 'D377',
        label: 'DUBINO',
      },
      {
        value: 'D456',
        label: 'FAEDO o FAEDO VALTELLINO',
      },
      {
        value: 'D694',
        label: 'FORCOLA',
      },
      {
        value: 'D830',
        label: 'FUSINE',
      },
      {
        value: 'D990',
        label: 'GEROLA o GEROLA ALTA',
      },
      {
        value: 'E090',
        label: 'GORDONA',
      },
      {
        value: 'E200',
        label: 'GROSIO',
      },
      {
        value: 'E201',
        label: 'GROSOTTO',
      },
      {
        value: 'E342',
        label: 'ISOLA o ISOLATO o MADESIMO',
      },
      {
        value: 'E443',
        label: 'LANZADA',
      },
      {
        value: 'E621',
        label: 'LIVIGNO',
      },
      {
        value: 'E705',
        label: 'LOVERO',
      },
      {
        value: 'E896',
        label: 'MANTELLO',
      },
      {
        value: 'F070',
        label: 'MAZZO o MAZZO DI VALTELLINA',
      },
      {
        value: 'F115',
        label: 'MELLO',
      },
      {
        value: 'F121',
        label: 'MENAROLA',
      },
      {
        value: 'F153',
        label: 'MESE',
      },
      {
        value: 'F393',
        label: 'MONTAGNA o MONTAGNA IN VALTELLINA',
      },
      {
        value: 'F712',
        label: 'MORBEGNO',
      },
      {
        value: 'F956',
        label: 'NOVATE o NOVATE MEZZOLA',
      },
      {
        value: 'G410',
        label: 'PEDESINA',
      },
      {
        value: 'G431',
        label: 'PENDOLASCO o POGGIRIDENTI',
      },
      {
        value: 'G572',
        label: 'PIANTEDO',
      },
      {
        value: 'G576',
        label: 'PIATEDA',
      },
      {
        value: 'G718',
        label: 'PIURO',
      },
      {
        value: 'G829',
        label: 'PONTE o PONTE IN VALTELLINA',
      },
      {
        value: 'G937',
        label: 'POSTALESIO',
      },
      {
        value: 'G993',
        label: 'PRATA o PRATA CAMPORTACCIO',
      },
      {
        value: 'H192',
        label: 'RASURA',
      },
      {
        value: 'H493',
        label: 'ROGOLO',
      },
      {
        value: 'H752',
        label: 'SAMOLACO',
      },
      {
        value: 'H868',
        label: 'SAN GIACOMO o SAN GIACOMO FILIPPO',
      },
      {
        value: 'I636',
        label: 'SERNIO',
      },
      {
        value: 'I828',
        label: 'SONDALO',
      },
      {
        value: 'I829',
        label: 'SONDRIO',
      },
      {
        value: 'I928',
        label: 'SPRIANA',
      },
      {
        value: 'L035',
        label: 'TALAMONA',
      },
      {
        value: 'L056',
        label: 'TARTANO',
      },
      {
        value: 'L084',
        label: 'TEGLIO',
      },
      {
        value: 'L175',
        label: 'TIRANO',
      },
      {
        value: 'L244',
        label: 'TORRE o TORRE DI SANTA MARIA',
      },
      {
        value: 'L316',
        label: "TOVO o TOVO DI SANT'AGATA",
      },
      {
        value: 'L330',
        label: 'TRAONA',
      },
      {
        value: 'L392',
        label: 'TRESIVIO',
      },
      {
        value: 'L638',
        label: 'VAL MASINO',
      },
      {
        value: 'L557',
        label: 'VALDIDENTRO',
      },
      {
        value: 'L563',
        label: 'VALDISOTTO',
      },
      {
        value: 'L576',
        label: 'VALFURVA',
      },
      {
        value: 'L749',
        label: 'VERCEIA',
      },
      {
        value: 'L799',
        label: 'VERVIO',
      },
      {
        value: 'L907',
        label: 'VILLA o VILLA DI CHIAVENNA',
      },
      {
        value: 'L908',
        label: 'VILLA o VILLA DI TIRANO',
      },
    ],
  },

  {
    value: 'SP',
    label: 'La Spezia',
    children: [
      {
        value: 'A261',
        label: 'AMEGLIA',
      },
      {
        value: 'A373',
        label: 'ARCOLA',
      },
      {
        value: 'A836',
        label: 'BEVERINO',
      },
      {
        value: 'A932',
        label: 'BOLANO',
      },
      {
        value: 'A961',
        label: 'BONASSOLA',
      },
      {
        value: 'B214',
        label: 'BRUGNATO',
      },
      {
        value: 'B410',
        label: 'CALICE AL CORNOVIGLIO o CALICE LIGURE',
      },
      {
        value: 'B838',
        label: 'CARRO',
      },
      {
        value: 'B839',
        label: 'CARRODANO',
      },
      {
        value: 'C240',
        label: 'CASTELNUOVO MAGRA',
      },
      {
        value: 'D265',
        label: 'DEIVA o DEIVA MARINA',
      },
      {
        value: 'D655',
        label: 'FOLLO',
      },
      {
        value: 'D758',
        label: 'FRAMURA',
      },
      {
        value: 'E070',
        label: 'GODANO o SESTA GODANO',
      },
      {
        value: 'E463',
        label: 'LA SPEZIA o SPEZIA',
      },
      {
        value: 'E542',
        label: 'LERICI',
      },
      {
        value: 'E560',
        label: 'LEVANTO',
      },
      {
        value: 'G143',
        label: 'LUNI o ORTONOVO',
      },
      {
        value: 'E842',
        label: 'MAISSANA',
      },
      {
        value: 'G664',
        label: 'PIGNONE',
      },
      {
        value: 'G925',
        label: 'PORTOVENERE',
      },
      {
        value: 'H304',
        label: 'RIOMAGGIORE',
      },
      {
        value: 'I449',
        label: 'SARZANA',
      },
      {
        value: 'L774',
        label: 'VERNAZZA',
      },
      {
        value: 'M177',
        label: 'ZIGNAGO',
      },
    ],
  },

  {
    value: 'SR',
    label: 'Siracusa',
    children: [
      {
        value: 'A494',
        label: 'AUGUSTA',
      },
      {
        value: 'A522',
        label: 'AVOLA',
      },
      {
        value: 'B237',
        label: 'BUCCHERI',
      },
      {
        value: 'B287',
        label: 'BUSCEMI',
      },
      {
        value: 'B603',
        label: 'CANICATTINI BAGNI',
      },
      {
        value: 'B787',
        label: 'CARLENTINI',
      },
      {
        value: 'C006',
        label: 'CASSARO',
      },
      {
        value: 'D540',
        label: 'FERLA',
      },
      {
        value: 'D636',
        label: 'FLORIDIA',
      },
      {
        value: 'D768',
        label: 'FRANCOFONTE',
      },
      {
        value: 'E532',
        label: 'LENTINI',
      },
      {
        value: 'F107',
        label: 'MELILLI',
      },
      {
        value: 'F943',
        label: 'NOTO',
      },
      {
        value: 'G211',
        label: 'PACHINO',
      },
      {
        value: 'G267',
        label: 'PALAZZOLO o PALAZZOLO ACREIDE',
      },
      {
        value: 'M257',
        label: 'PORTOPALO DI CAPO PASSERO',
      },
      {
        value: 'M279',
        label: 'PRIOLO GARGALLO',
      },
      {
        value: 'H574',
        label: 'ROSOLINI',
      },
      {
        value: 'I754',
        label: 'SIRACUSA',
      },
      {
        value: 'I785',
        label: 'SOLARINO',
      },
      {
        value: 'I864',
        label: 'SORTINO',
      },
    ],
  },

  {
    value: 'SS',
    label: 'Sassari',
    children: [
      {
        value: 'A069',
        label: 'AGGIUS',
      },
      {
        value: 'H848',
        label: "AGLIENTU o SAN FRANCESCO D'AGLIENTU",
      },
      {
        value: 'A115',
        label: "ALA o ALA' DEI SARDI",
      },
      {
        value: 'A192',
        label: 'ALGHERO',
      },
      {
        value: 'A287',
        label: 'ANELA',
      },
      {
        value: 'A379',
        label: 'ARDARA',
      },
      {
        value: 'A453',
        label: 'ARZACHENA',
      },
      {
        value: 'M214',
        label: 'BADESI',
      },
      {
        value: 'A606',
        label: 'BANARI',
      },
      {
        value: 'A611',
        label: 'BANTINE',
      },
      {
        value: 'A781',
        label: 'BENETUTTI',
      },
      {
        value: 'A789',
        label: 'BERCHIDDA',
      },
      {
        value: 'A827',
        label: 'BESSUDE',
      },
      {
        value: 'A976',
        label: 'BONNANARO',
      },
      {
        value: 'A977',
        label: 'BONO',
      },
      {
        value: 'A978',
        label: 'BONORVA',
      },
      {
        value: 'B063',
        label: 'BORTIGIADAS',
      },
      {
        value: 'B064',
        label: 'BORUTTA',
      },
      {
        value: 'B094',
        label: 'BOTTIDDA',
      },
      {
        value: 'B246',
        label: "BUDDUSO'",
      },
      {
        value: 'B264',
        label: 'BULTEI',
      },
      {
        value: 'B265',
        label: 'BULZI',
      },
      {
        value: 'B276',
        label: 'BURGOS',
      },
      {
        value: 'B378',
        label: 'CALANGIANUS',
      },
      {
        value: 'B772',
        label: 'CARGEGHE',
      },
      {
        value: 'C272',
        label: 'CASTELSARDO',
      },
      {
        value: 'C600',
        label: 'CHEREMULE',
      },
      {
        value: 'C613',
        label: 'CHIARAMONTI',
      },
      {
        value: 'C818',
        label: 'CODRONGIANOS o CODRONGIANUS',
      },
      {
        value: 'D100',
        label: 'COSSOINE',
      },
      {
        value: 'M292',
        label: 'ERULA',
      },
      {
        value: 'D441',
        label: 'ESPORLATU',
      },
      {
        value: 'D637',
        label: 'FLORINAS',
      },
      {
        value: 'D929',
        label: 'GAROFAI',
      },
      {
        value: 'E019',
        label: 'GIAVE',
      },
      {
        value: 'M274',
        label: 'GOLFO ARANCI',
      },
      {
        value: 'E285',
        label: 'ILLORAI',
      },
      {
        value: 'E376',
        label: 'ITTIREDDU',
      },
      {
        value: 'E377',
        label: 'ITTIRI',
      },
      {
        value: 'E425',
        label: 'LA MADDALENA',
      },
      {
        value: 'E401',
        label: 'LAERRU',
      },
      {
        value: 'M275',
        label: 'LOIRI PORTO SAN PAOLO',
      },
      {
        value: 'E747',
        label: 'LUOGOSANTO',
      },
      {
        value: 'E752',
        label: 'LURAS',
      },
      {
        value: 'E902',
        label: 'MARA',
      },
      {
        value: 'E992',
        label: 'MARTIS',
      },
      {
        value: 'F542',
        label: 'MONTELEONE o MONTELEONE ROCCA DORIA',
      },
      {
        value: 'F667',
        label: 'MONTI',
      },
      {
        value: 'F721',
        label: 'MORES',
      },
      {
        value: 'F818',
        label: 'MUROS',
      },
      {
        value: 'F973',
        label: 'NUCHIS',
      },
      {
        value: 'F975',
        label: "NUGHEDU o NUGHEDU SAN NICOLO'",
      },
      {
        value: 'F976',
        label: 'NULE',
      },
      {
        value: 'F977',
        label: 'NULVI',
      },
      {
        value: 'G015',
        label: 'OLBIA o TERRANOVA o TERRANOVA PAUSANIA',
      },
      {
        value: 'G046',
        label: 'OLMEDO',
      },
      {
        value: 'G153',
        label: 'OSCHIRI',
      },
      {
        value: 'G156',
        label: 'OSILO',
      },
      {
        value: 'G178',
        label: 'OSSI',
      },
      {
        value: 'G203',
        label: 'OZIERI',
      },
      {
        value: 'G225',
        label: 'PADRIA',
      },
      {
        value: 'M301',
        label: 'PADRU',
      },
      {
        value: 'G258',
        label: 'PALAU',
      },
      {
        value: 'G376',
        label: 'PATTADA',
      },
      {
        value: 'G450',
        label: 'PERFUGAS',
      },
      {
        value: 'G740',
        label: 'PLOAGHE',
      },
      {
        value: 'G924',
        label: 'PORTO TORRES',
      },
      {
        value: 'G962',
        label: 'POZZOMAGGIORE',
      },
      {
        value: 'H095',
        label: 'PUTIFIGARI',
      },
      {
        value: 'H209',
        label: 'REBECCU',
      },
      {
        value: 'H507',
        label: 'ROMANA',
      },
      {
        value: 'M276',
        label: "SANT'ANTONIO DI GALLURA",
      },
      {
        value: 'M284',
        label: 'SANTA MARIA COGHINAS',
      },
      {
        value: 'I312',
        label: 'SANTA TERESA o SANTA TERESA GALLURA',
      },
      {
        value: 'I452',
        label: 'SASSARI',
      },
      {
        value: 'I565',
        label: 'SEDINI',
      },
      {
        value: 'I598',
        label: 'SEMESTENE',
      },
      {
        value: 'I614',
        label: 'SENNORI',
      },
      {
        value: 'I732',
        label: 'SILIGO',
      },
      {
        value: 'I863',
        label: 'SORSO',
      },
      {
        value: 'M290',
        label: 'STINTINO',
      },
      {
        value: 'L088',
        label: 'TELTI',
      },
      {
        value: 'L093',
        label: 'TEMPIO PAUSANIA',
      },
      {
        value: 'M282',
        label: 'TERGU',
      },
      {
        value: 'L158',
        label: 'THIESI',
      },
      {
        value: 'L180',
        label: 'TISSI',
      },
      {
        value: 'L235',
        label: 'TORRALBA',
      },
      {
        value: 'L428',
        label: "TRINITA' D'AGULTU E VIGNOLA",
      },
      {
        value: 'L464',
        label: 'TULA',
      },
      {
        value: 'L503',
        label: 'URI',
      },
      {
        value: 'L509',
        label: 'USINI',
      },
      {
        value: 'L604',
        label: 'VALLEDORIA',
      },
      {
        value: 'M259',
        label: 'VIDDALBA',
      },
      {
        value: 'L989',
        label: 'VILLANOVA MONTELEONE',
      },
    ],
  },

  {
    value: 'SU',
    label: 'Sud Sardegna',
    children: [],
  },

  {
    value: 'SV',
    label: 'Savona',
    children: [
      {
        value: 'A122',
        label: 'ALASSIO',
      },
      {
        value: 'A145',
        label: 'ALBENGA',
      },
      {
        value: 'A166',
        label: 'ALBISOLA SUPERIORE',
      },
      {
        value: 'A165',
        label: 'ALBISSOLA MARINA',
      },
      {
        value: 'A226',
        label: 'ALTARE',
      },
      {
        value: 'A278',
        label: 'ANDORA',
      },
      {
        value: 'A422',
        label: 'ARNASCO',
      },
      {
        value: 'A593',
        label: 'BALESTRINO',
      },
      {
        value: 'A647',
        label: 'BARDINETO',
      },
      {
        value: 'A648',
        label: 'BARDINO NUOVO',
      },
      {
        value: 'A649',
        label: 'BARDINO VECCHIO',
      },
      {
        value: 'A796',
        label: 'BERGEGGI',
      },
      {
        value: 'A865',
        label: 'BIESTRO',
      },
      {
        value: 'A931',
        label: 'BOISSANO',
      },
      {
        value: 'A999',
        label: 'BORGHETTO SANTO SPIRITO',
      },
      {
        value: 'B004',
        label: 'BORGIO',
      },
      {
        value: 'B005',
        label: 'BORGIO VEREZZI',
      },
      {
        value: 'B048',
        label: 'BORMIDA',
      },
      {
        value: 'B208',
        label: 'BROVIDA',
      },
      {
        value: 'B416',
        label: 'CALIZZANO',
      },
      {
        value: 'B523',
        label: 'CAMPOCHIESA',
      },
      {
        value: 'B748',
        label: 'CARCARE',
      },
      {
        value: 'C063',
        label: 'CASTELBIANCO',
      },
      {
        value: 'C462',
        label: 'CENESI',
      },
      {
        value: 'C463',
        label: 'CENGIO',
      },
      {
        value: 'C510',
        label: 'CERIALE',
      },
      {
        value: 'D095',
        label: 'COSSERIA',
      },
      {
        value: 'D264',
        label: 'DEGO',
      },
      {
        value: 'D397',
        label: 'ELLERA',
      },
      {
        value: 'D424',
        label: 'ERLI',
      },
      {
        value: 'D522',
        label: 'FEGLINO o ORCO FEGLINO',
      },
      {
        value: 'D598',
        label: 'FINALBORGO',
      },
      {
        value: 'D600',
        label: 'FINALE LIGURE',
      },
      {
        value: 'D601',
        label: 'FINALMARINA',
      },
      {
        value: 'D602',
        label: 'FINALPIA',
      },
      {
        value: 'D927',
        label: 'GARLENDA',
      },
      {
        value: 'E064',
        label: 'GIUSTENICE',
      },
      {
        value: 'E066',
        label: 'GIUSVALLA',
      },
      {
        value: 'E414',
        label: 'LAIGUEGLIA',
      },
      {
        value: 'E632',
        label: 'LOANO',
      },
      {
        value: 'E816',
        label: 'MAGLIOLO',
      },
      {
        value: 'E860',
        label: 'MALLARE',
      },
      {
        value: 'F046',
        label: 'MASSIMINO',
      },
      {
        value: 'F213',
        label: 'MILLESIMO',
      },
      {
        value: 'F226',
        label: 'MIOGLIA',
      },
      {
        value: 'F813',
        label: 'MURIALDO',
      },
      {
        value: 'F847',
        label: 'NASINO',
      },
      {
        value: 'F926',
        label: 'NOLI',
      },
      {
        value: 'G014',
        label: 'OLBA',
      },
      {
        value: 'G076',
        label: 'ONZO',
      },
      {
        value: 'G144',
        label: 'ORTOVERO',
      },
      {
        value: 'G155',
        label: 'OSIGLIA',
      },
      {
        value: 'G281',
        label: 'PALLARE',
      },
      {
        value: 'G741',
        label: 'PLODIO',
      },
      {
        value: 'G866',
        label: 'PONTINVREA',
      },
      {
        value: 'H126',
        label: 'QUILIANO',
      },
      {
        value: 'H179',
        label: 'RANZI PIETRA',
      },
      {
        value: 'H266',
        label: 'RIALTO',
      },
      {
        value: 'H452',
        label: 'ROCCAVIGNALE',
      },
      {
        value: 'H464',
        label: 'ROCCHETTA CENGIO',
      },
      {
        value: 'I204',
        label: 'SANTA GIULIA',
      },
      {
        value: 'I453',
        label: 'SASSELLO',
      },
      {
        value: 'I480',
        label: 'SAVONA',
      },
      {
        value: 'I574',
        label: 'SEGNO',
      },
      {
        value: 'I926',
        label: 'SPOTORNO',
      },
      {
        value: 'I946',
        label: 'STELLA',
      },
      {
        value: 'I947',
        label: 'STELLANELLO',
      },
      {
        value: 'L152',
        label: 'TESTICO',
      },
      {
        value: 'L190',
        label: 'TOIRANO',
      },
      {
        value: 'L499',
        label: 'URBE',
      },
      {
        value: 'L675',
        label: 'VARAZZE',
      },
      {
        value: 'L718',
        label: 'VELLEGO',
      },
      {
        value: 'L730',
        label: 'VENDONE',
      },
      {
        value: 'L759',
        label: 'VEREZZI',
      },
      {
        value: 'L823',
        label: 'VEZZI PORTIO',
      },
      {
        value: 'M197',
        label: 'ZUCCARELLO',
      },
    ],
  },

  {
    value: 'TA',
    label: 'Taranto',
    children: [
      {
        value: 'A514',
        label: 'AVETRANA',
      },
      {
        value: 'B808',
        label: 'CAROSINO',
      },
      {
        value: 'C136',
        label: 'CASTELLANETA',
      },
      {
        value: 'D171',
        label: 'CRISPIANO',
      },
      {
        value: 'D463',
        label: 'FAGGIANO',
      },
      {
        value: 'D754',
        label: 'FRAGAGNANO',
      },
      {
        value: 'E036',
        label: 'GINOSA',
      },
      {
        value: 'E205',
        label: 'GROTTAGLIE',
      },
      {
        value: 'E469',
        label: 'LATERZA',
      },
      {
        value: 'E537',
        label: 'LEPORANO',
      },
      {
        value: 'E630',
        label: 'LIZZANO',
      },
      {
        value: 'E882',
        label: 'MANDURIA',
      },
      {
        value: 'E986',
        label: 'MARTINA o MARTINA FRANCA',
      },
      {
        value: 'E995',
        label: 'MARUGGIO',
      },
      {
        value: 'F027',
        label: 'MASSAFRA',
      },
      {
        value: 'F531',
        label: 'MONTEIASI',
      },
      {
        value: 'F563',
        label: 'MONTEMESOLA',
      },
      {
        value: 'F587',
        label: 'MONTEPARANO',
      },
      {
        value: 'F784',
        label: 'MOTTOLA',
      },
      {
        value: 'G251',
        label: 'PALAGIANELLO',
      },
      {
        value: 'G252',
        label: 'PALAGIANO',
      },
      {
        value: 'H090',
        label: 'PULSANO',
      },
      {
        value: 'H409',
        label: 'ROCCAFORZATA',
      },
      {
        value: 'I467',
        label: 'SAVA',
      },
      {
        value: 'M298',
        label: 'STATTE',
      },
      {
        value: 'L049',
        label: 'TARANTO',
      },
      {
        value: 'L294',
        label: 'TORRICELLA',
      },
    ],
  },

  {
    value: 'TE',
    label: 'Teramo',
    children: [
      {
        value: 'A125',
        label: 'ALBA ADRIATICA',
      },
      {
        value: 'A270',
        label: 'ANCARANO',
      },
      {
        value: 'A445',
        label: 'ARSITA o BACUCCO',
      },
      {
        value: 'A488',
        label: 'ATRI',
      },
      {
        value: 'A692',
        label: 'BASCIANO',
      },
      {
        value: 'A746',
        label: 'BELLANTE',
      },
      {
        value: 'A885',
        label: 'BISENTI',
      },
      {
        value: 'B515',
        label: 'CAMPLI',
      },
      {
        value: 'B640',
        label: 'CANZANO',
      },
      {
        value: 'C040',
        label: 'CASTAGNA o CASTEL CASTAGNA',
      },
      {
        value: 'C128',
        label: 'CASTELLALTO',
      },
      {
        value: 'C169',
        label: 'CASTELLI',
      },
      {
        value: 'C311',
        label: 'CASTIGLIONE DELLA VALLE o COLLEDARA',
      },
      {
        value: 'C316',
        label: 'CASTIGLIONE MESSER RAIMONDO',
      },
      {
        value: 'C322',
        label: 'CASTILENTI',
      },
      {
        value: 'C449',
        label: 'CELLINO o CELLINO ATTANASIO',
      },
      {
        value: 'C517',
        label: 'CERMIGNANO',
      },
      {
        value: 'C781',
        label: 'CIVITELLA DEL TRONTO',
      },
      {
        value: 'C901',
        label: 'COLONNELLA',
      },
      {
        value: 'C972',
        label: 'CONTROGUERRA',
      },
      {
        value: 'D043',
        label: 'CORROPOLI',
      },
      {
        value: 'D076',
        label: 'CORTINO',
      },
      {
        value: 'D178',
        label: 'CROGNALETO',
      },
      {
        value: 'D489',
        label: 'FANO ADRIANO',
      },
      {
        value: 'E058',
        label: 'GIULIANOVA',
      },
      {
        value: 'E343',
        label: "ISOLA o ISOLA DEL GRAN SASSO D'ITALIA",
      },
      {
        value: 'E989',
        label: 'MARTINSICURO',
      },
      {
        value: 'F500',
        label: 'MONTEFINO o MONTESECCO',
      },
      {
        value: 'F585',
        label: 'MONTEPAGANO o ROSETO DEGLI ABRUZZI',
      },
      {
        value: 'F690',
        label: 'MONTORIO o MONTORIO AL VOMANO',
      },
      {
        value: 'F747',
        label: "MORRO o MORRO D'ORO",
      },
      {
        value: 'F764',
        label: "MOSCIANO o MOSCIANO SANT'ANGELO",
      },
      {
        value: 'F831',
        label: 'MUTIGNANO o PINETO',
      },
      {
        value: 'F870',
        label: 'NERETO',
      },
      {
        value: 'F942',
        label: 'NOTARESCO',
      },
      {
        value: 'G437',
        label: "PENNA SANT'ANDREA",
      },
      {
        value: 'G608',
        label: 'PIETRACAMELA',
      },
      {
        value: 'H440',
        label: 'ROCCA SANTA MARIA',
      },
      {
        value: 'I318',
        label: "SANT'EGIDIO o SANT'EGIDIO ALLA VIBRATA",
      },
      {
        value: 'I348',
        label: "SANT'OMERO",
      },
      {
        value: 'I741',
        label: 'SILVI',
      },
      {
        value: 'L103',
        label: 'TERAMO',
      },
      {
        value: 'L207',
        label: 'TORANO o TORANO NUOVO',
      },
      {
        value: 'L295',
        label: 'TORRICELLA o TORRICELLA SICURA',
      },
      {
        value: 'L307',
        label: 'TORTORETO',
      },
      {
        value: 'L314',
        label: 'TOSSICIA',
      },
      {
        value: 'L597',
        label: 'VALLE CASTELLANA',
      },
      {
        value: 'L619',
        label: 'VALLE SAN GIOVANNI',
      },
    ],
  },

  {
    value: 'TN',
    label: 'Trento',
    children: [
      {
        value: 'A090',
        label: 'AGRONE',
      },
      {
        value: 'A116',
        label: 'ALA',
      },
      {
        value: 'A158',
        label: 'ALBIANO',
      },
      {
        value: 'A178',
        label: 'ALDENO',
      },
      {
        value: 'A213',
        label: 'ALMAZZAGO',
      },
      {
        value: 'M349',
        label: 'ALTAVALLE',
      },
      {
        value: 'M350',
        label: 'ALTOPIANO DELLA VIGOLANA',
      },
      {
        value: 'A260',
        label: 'AMBLAR',
      },
      {
        value: 'M351',
        label: 'AMBLAR-DON',
      },
      {
        value: 'A274',
        label: 'ANDALO',
      },
      {
        value: 'A276',
        label: 'ANDOGNO',
      },
      {
        value: 'A372',
        label: 'ARCO',
      },
      {
        value: 'A420',
        label: 'ARNAGO',
      },
      {
        value: 'A426',
        label: 'ARNO o BONDO BREGUZZO',
      },
      {
        value: 'A520',
        label: 'AVIO',
      },
      {
        value: 'M215',
        label: 'BALBIDO',
      },
      {
        value: 'M216',
        label: 'BALLINO',
      },
      {
        value: 'A608',
        label: 'BANCO',
      },
      {
        value: 'M217',
        label: 'BARCESINO',
      },
      {
        value: 'A693',
        label: 'BASELGA o BASELGA DI VEZZANO',
      },
      {
        value: 'A694',
        label: "BASELGA DI PINE'",
      },
      {
        value: 'A730',
        label: 'BEDOLLO',
      },
      {
        value: 'A808',
        label: 'BERSONE',
      },
      {
        value: 'A821',
        label: 'BESENELLO',
      },
      {
        value: 'A822',
        label: 'BESENO',
      },
      {
        value: 'A839',
        label: 'BEZZECCA',
      },
      {
        value: 'A840',
        label: 'BIACESA',
      },
      {
        value: 'A863',
        label: 'BIENO',
      },
      {
        value: 'A900',
        label: 'BLEGGIO',
      },
      {
        value: 'A901',
        label: 'BLEGGIO INFERIORE',
      },
      {
        value: 'A902',
        label: 'BLEGGIO SUPERIORE',
      },
      {
        value: 'A916',
        label: 'BOCENAGO',
      },
      {
        value: 'A933',
        label: 'BOLBENO',
      },
      {
        value: 'A935',
        label: 'BOLENTINA',
      },
      {
        value: 'A943',
        label: 'BOLLONE',
      },
      {
        value: 'A967',
        label: 'BONDO',
      },
      {
        value: 'A968',
        label: 'BONDONE',
      },
      {
        value: 'M218',
        label: 'BONDONE STORO',
      },
      {
        value: 'M219',
        label: 'BONO',
      },
      {
        value: 'A997',
        label: 'BORGHETTO',
      },
      {
        value: 'B006',
        label: 'BORGO o BORGO VALSUGANA',
      },
      {
        value: 'M352',
        label: 'BORGO CHIESE',
      },
      {
        value: 'M429',
        label: "BORGO D'ANAUNIA",
      },
      {
        value: 'M353',
        label: 'BORGO LARES',
      },
      {
        value: 'B065',
        label: 'BORZAGO',
      },
      {
        value: 'B078',
        label: 'BOSENTINO',
      },
      {
        value: 'B108',
        label: 'BOZZANA',
      },
      {
        value: 'M220',
        label: 'BRANCOLINO',
      },
      {
        value: 'B135',
        label: 'BREGUZZO',
      },
      {
        value: 'B153',
        label: 'BRENTONICO',
      },
      {
        value: 'B158',
        label: 'BRESIMO',
      },
      {
        value: 'B165',
        label: 'BREZ',
      },
      {
        value: 'B185',
        label: 'BRIONE',
      },
      {
        value: 'M221',
        label: 'BRUSINO',
      },
      {
        value: 'B335',
        label: 'CADERZONE o CADERZONE TERME',
      },
      {
        value: 'B344',
        label: 'CADINE',
      },
      {
        value: 'B360',
        label: "CAGNO'",
      },
      {
        value: 'B385',
        label:
          'CALAVINO o CALATAFIMI o CALATAFIMI SEGESTA o CALATAFIMI-SEGESTA',
      },
      {
        value: 'B388',
        label: 'CALCERANICA o CALCATA',
      },
      {
        value: 'B400',
        label: 'CALDES o CALDIERO',
      },
      {
        value: 'B403',
        label: 'CALDONAZZO o CALDOGNO',
      },
      {
        value: 'B419',
        label: 'CALLIANO',
      },
      {
        value: 'B514',
        label: 'CAMPITELLO o CAMPITELLO DI FASSA',
      },
      {
        value: 'B518',
        label: 'CAMPO',
      },
      {
        value: 'B525',
        label: 'CAMPODENNO',
      },
      {
        value: 'B577',
        label: 'CANAL SAN BOVO',
      },
      {
        value: 'M222',
        label: 'CANALE',
      },
      {
        value: 'B579',
        label: 'CANAZEI',
      },
      {
        value: 'B600',
        label: 'CANEZZA',
      },
      {
        value: 'B697',
        label: 'CAPRIANA',
      },
      {
        value: 'B723',
        label: 'CARANO',
      },
      {
        value: 'B750',
        label: 'CARCIATO',
      },
      {
        value: 'M223',
        label: 'CARES',
      },
      {
        value: 'B783',
        label: 'CARISOLO',
      },
      {
        value: 'B856',
        label: 'CARZANO',
      },
      {
        value: 'B964',
        label: 'CASEZ',
      },
      {
        value: 'C043',
        label: "CASTAGNE'",
      },
      {
        value: 'C183',
        label: 'CASTEL CONDINO o CASTELLO',
      },
      {
        value: 'M354',
        label: 'CASTEL IVANO',
      },
      {
        value: 'C103',
        label: 'CASTELFONDO',
      },
      {
        value: 'C138',
        label: 'CASTELLANO',
      },
      {
        value: 'C182',
        label: 'CASTELLO',
      },
      {
        value: 'C189',
        label: 'CASTELLO DI FIEMME o CASTELLO-MOLINA DI FIEMME',
      },
      {
        value: 'C194',
        label: 'CASTELLO TESINO',
      },
      {
        value: 'C216',
        label: 'CASTELNOVO o CASTELNUOVO',
      },
      {
        value: 'M225',
        label: 'CASTELPIETRA',
      },
      {
        value: 'C358',
        label: 'CAURIA',
      },
      {
        value: 'C372',
        label: 'CAVALESE',
      },
      {
        value: 'C380',
        label: 'CAVARENO',
      },
      {
        value: 'C392',
        label: 'CAVEDAGO',
      },
      {
        value: 'C393',
        label: 'CAVEDINE',
      },
      {
        value: 'C400',
        label: 'CAVIZZANA',
      },
      {
        value: 'M226',
        label: 'CAVRASTO',
      },
      {
        value: 'C427',
        label: 'CELENTINO',
      },
      {
        value: 'C445',
        label: 'CELLEDIZZO',
      },
      {
        value: 'C452',
        label: 'CEMBRA',
      },
      {
        value: 'M355',
        label: 'CEMBRA LISIGNAGO',
      },
      {
        value: 'C467',
        label: "CENTA SAN NICOLO'",
      },
      {
        value: 'C626',
        label: 'CHIENIS',
      },
      {
        value: 'C666',
        label: 'CHIZZOLA',
      },
      {
        value: 'C667',
        label: 'CIAGO',
      },
      {
        value: 'C694',
        label: 'CIMEGO',
      },
      {
        value: 'C700',
        label: 'CIMONE',
      },
      {
        value: 'C712',
        label: 'CINTE TESINO',
      },
      {
        value: 'C727',
        label: 'CIS',
      },
      {
        value: 'C756',
        label: 'CIVEZZANO',
      },
      {
        value: 'C794',
        label: 'CLES',
      },
      {
        value: 'C797',
        label: 'CLOZ',
      },
      {
        value: 'C822',
        label: 'COGNOLA',
      },
      {
        value: 'C825',
        label: 'COGOLO',
      },
      {
        value: 'C892',
        label: 'COLOGNA',
      },
      {
        value: 'C891',
        label: 'COLOGNA CAVAZZO',
      },
      {
        value: 'C915',
        label: 'COMANO',
      },
      {
        value: 'M314',
        label: 'COMANO TERME',
      },
      {
        value: 'C916',
        label: 'COMASINE',
      },
      {
        value: 'M227',
        label: 'COMIGHELLO',
      },
      {
        value: 'C931',
        label: 'COMMEZZADURA',
      },
      {
        value: 'C944',
        label: 'CONCEI',
      },
      {
        value: 'C953',
        label: 'CONDINO',
      },
      {
        value: 'M356',
        label: "CONTA'",
      },
      {
        value: 'C994',
        label: 'COREDO',
      },
      {
        value: 'D116',
        label: 'COSTASAVINA',
      },
      {
        value: 'D125',
        label: 'COVELO',
      },
      {
        value: 'D164',
        label: 'CRETO',
      },
      {
        value: 'D188',
        label: 'CROVIANA',
      },
      {
        value: 'D206',
        label: 'CUNEVO',
      },
      {
        value: 'D243',
        label: 'DAIANO',
      },
      {
        value: 'D246',
        label: 'DAMBEL',
      },
      {
        value: 'D248',
        label: 'DAONE',
      },
      {
        value: 'D249',
        label: 'DARDINE',
      },
      {
        value: 'D250',
        label: "DARE'",
      },
      {
        value: 'D252',
        label: 'DARZO',
      },
      {
        value: 'M228',
        label: 'DASINDO',
      },
      {
        value: 'D263',
        label: 'DEGGIANO',
      },
      {
        value: 'D273',
        label: 'DENNO',
      },
      {
        value: 'D274',
        label: 'DERCOLO',
      },
      {
        value: 'D276',
        label: 'DERMULO',
      },
      {
        value: 'D302',
        label: 'DIMARO',
      },
      {
        value: 'M366',
        label: 'DIMARO FOLGARIDA',
      },
      {
        value: 'D336',
        label: 'DON',
      },
      {
        value: 'D349',
        label: 'DORSINO',
      },
      {
        value: 'D365',
        label: 'DRENA',
      },
      {
        value: 'D371',
        label: 'DRO',
      },
      {
        value: 'M229',
        label: 'DUVREDO',
      },
      {
        value: 'D409',
        label: 'ENGUISO',
      },
      {
        value: 'D457',
        label: 'FAEDO',
      },
      {
        value: 'D468',
        label: 'FAI o FAI DELLA PAGANELLA',
      },
      {
        value: 'D478',
        label: 'FALESINA',
      },
      {
        value: 'D516',
        label: 'FAVER',
      },
      {
        value: 'M230',
        label: 'FAVRIO',
      },
      {
        value: 'D565',
        label: "FIAVE'",
      },
      {
        value: 'D572',
        label: 'FIERA DI PRIMIERO',
      },
      {
        value: 'D573',
        label: 'FIEROZZO',
      },
      {
        value: 'D616',
        label: 'FISTO',
      },
      {
        value: 'D631',
        label: 'FLAVON',
      },
      {
        value: 'H249',
        label: 'FOLAS-REVIAN',
      },
      {
        value: 'D651',
        label: 'FOLGARIA',
      },
      {
        value: 'D663',
        label: 'FONDO',
      },
      {
        value: 'D714',
        label: 'FORNACE',
      },
      {
        value: 'D723',
        label: 'FORNO',
      },
      {
        value: 'D775',
        label: 'FRASSILONGO',
      },
      {
        value: 'D792',
        label: 'FRAVEGGIO',
      },
      {
        value: 'D916',
        label: 'GARDOLO',
      },
      {
        value: 'D928',
        label: 'GARNIGA o GARNIGA TERME',
      },
      {
        value: 'E048',
        label: 'GIOVO',
      },
      {
        value: 'E065',
        label: 'GIUSTINO',
      },
      {
        value: 'M231',
        label: 'GODENZO',
      },
      {
        value: 'E150',
        label: 'GRAUNO',
      },
      {
        value: 'E178',
        label: 'GRIGNO',
      },
      {
        value: 'E222',
        label: 'GRUMES',
      },
      {
        value: 'E225',
        label: 'GRUMO',
      },
      {
        value: 'E288',
        label: 'IMER',
      },
      {
        value: 'E331',
        label: 'ISCHIA',
      },
      {
        value: 'E334',
        label: 'ISERA',
      },
      {
        value: 'E378',
        label: 'IVANO FRACENA',
      },
      {
        value: 'E276',
        label: "JAVRE'",
      },
      {
        value: 'M232',
        label: "LAGUNA MUSTE'",
      },
      {
        value: 'E452',
        label: 'LARDARO',
      },
      {
        value: 'M233',
        label: 'LARIDO',
      },
      {
        value: 'M234',
        label: 'LASES',
      },
      {
        value: 'E461',
        label: 'LASINO',
      },
      {
        value: 'E492',
        label: 'LAVARONE',
      },
      {
        value: 'E500',
        label: 'LAVIS',
      },
      {
        value: 'M313',
        label: 'LEDRO',
      },
      {
        value: 'E516',
        label: 'LEGOS',
      },
      {
        value: 'E533',
        label: 'LENZIMA',
      },
      {
        value: 'E534',
        label: 'LENZUMO',
      },
      {
        value: 'E565',
        label: 'LEVICO o LEVICO TERME',
      },
      {
        value: 'E614',
        label: 'LISIGNAGO',
      },
      {
        value: 'E624',
        label: 'LIVO',
      },
      {
        value: 'E628',
        label: 'LIZZANA',
      },
      {
        value: 'E643',
        label: 'LOCCA',
      },
      {
        value: 'E653',
        label: 'LODRONE',
      },
      {
        value: 'E658',
        label: 'LOMASO',
      },
      {
        value: 'E663',
        label: 'LON',
      },
      {
        value: 'M235',
        label: 'LONA',
      },
      {
        value: 'E664',
        label: 'LONA LASES o LONA-LASES',
      },
      {
        value: 'E703',
        label: 'LOVER',
      },
      {
        value: 'E744',
        label: 'LUNDO',
      },
      {
        value: 'E757',
        label: 'LUSERNA',
      },
      {
        value: 'M236',
        label: 'MADICE',
      },
      {
        value: 'E796',
        label: 'MADRANO',
      },
      {
        value: 'E797',
        label: 'MADRUZZO',
      },
      {
        value: 'M357',
        label: 'MADRUZZO',
      },
      {
        value: 'E826',
        label: 'MAGRAS',
      },
      {
        value: 'E827',
        label: "MAGRE'",
      },
      {
        value: 'M237',
        label: 'MALA',
      },
      {
        value: 'E850',
        label: "MALE'",
      },
      {
        value: 'E857',
        label: 'MALGOLO o SALTER MALGOLO',
      },
      {
        value: 'E866',
        label: 'MALOSCO',
      },
      {
        value: 'E898',
        label: 'MANZANO',
      },
      {
        value: 'E909',
        label: 'MARANO',
      },
      {
        value: 'E935',
        label: 'MARCO',
      },
      {
        value: 'E948',
        label: 'MARGONE',
      },
      {
        value: 'F014',
        label: 'MASI DI VIGO',
      },
      {
        value: 'F045',
        label: 'MASSIMENO',
      },
      {
        value: 'F049',
        label: 'MASTELLINA',
      },
      {
        value: 'F056',
        label: 'MATTARELLO',
      },
      {
        value: 'F068',
        label: 'MAZZIN',
      },
      {
        value: 'F076',
        label: 'MEANO',
      },
      {
        value: 'F077',
        label: 'MECHEL',
      },
      {
        value: 'M238',
        label: 'MENAS',
      },
      {
        value: 'F160',
        label: 'MESTRIAGO',
      },
      {
        value: 'F168',
        label: 'MEZZANA',
      },
      {
        value: 'F176',
        label: 'MEZZANO',
      },
      {
        value: 'F177',
        label: 'MEZZANO IMER',
      },
      {
        value: 'F183',
        label: 'MEZZOCORONA',
      },
      {
        value: 'F185',
        label: 'MEZZOLAGO',
      },
      {
        value: 'F187',
        label: 'MEZZOLOMBARDO',
      },
      {
        value: 'F227',
        label: 'MIOLA',
      },
      {
        value: 'F263',
        label: 'MOENA',
      },
      {
        value: 'F264',
        label: 'MOERNA',
      },
      {
        value: 'F286',
        label: 'MOLINA DI LEDRO',
      },
      {
        value: 'F296',
        label: 'MOLLARO',
      },
      {
        value: 'F307',
        label: 'MOLVENO',
      },
      {
        value: 'F341',
        label: 'MONCLASSICO',
      },
      {
        value: 'F396',
        label: 'MONTAGNE',
      },
      {
        value: 'F615',
        label: 'MONTES',
      },
      {
        value: 'M239',
        label: 'MONTEVACCINO',
      },
      {
        value: 'F728',
        label: 'MORI',
      },
      {
        value: 'F755',
        label: 'MORTASO',
      },
      {
        value: 'F835',
        label: 'NAGO-TORBOLE',
      },
      {
        value: 'F837',
        label: 'NANNO',
      },
      {
        value: 'F853',
        label: 'NAVE SAN ROCCO',
      },
      {
        value: 'F905',
        label: 'NOARNA',
      },
      {
        value: 'F919',
        label: "NOGARE'",
      },
      {
        value: 'F920',
        label: 'NOGAREDO',
      },
      {
        value: 'F928',
        label: 'NOMESINO',
      },
      {
        value: 'F929',
        label: 'NOMI',
      },
      {
        value: 'F936',
        label: 'NORIGLIO',
      },
      {
        value: 'F947',
        label: 'NOVALEDO',
      },
      {
        value: 'M430',
        label: 'NOVELLA',
      },
      {
        value: 'G052',
        label: 'OLTRESARCA',
      },
      {
        value: 'M240',
        label: "ORTISE'",
      },
      {
        value: 'G168',
        label: 'OSPEDALETTO',
      },
      {
        value: 'G173',
        label: 'OSSANA',
      },
      {
        value: 'G214',
        label: 'PADERGNONE',
      },
      {
        value: 'G296',
        label: "PALU' o PALU' DEL FERSINA",
      },
      {
        value: 'G305',
        label: "PANCHIA'",
      },
      {
        value: 'G313',
        label: 'PANNONE',
      },
      {
        value: 'G373',
        label: 'PATONE',
      },
      {
        value: 'G409',
        label: 'PEDERSANO',
      },
      {
        value: 'G419',
        label: 'PEIO o PEJO',
      },
      {
        value: 'G428',
        label: 'PELLIZZANO',
      },
      {
        value: 'G429',
        label: 'PELUGO',
      },
      {
        value: 'G464',
        label: 'PERA',
      },
      {
        value: 'G452',
        label: 'PERGINE o PERGINE VALSUGANA',
      },
      {
        value: 'G470',
        label: 'PERSONE',
      },
      {
        value: 'G569',
        label: 'PIANO',
      },
      {
        value: 'G641',
        label: 'PIEVE DI BONO',
      },
      {
        value: 'M365',
        label: 'PIEVE DI BONO-PREZZO',
      },
      {
        value: 'G644',
        label: 'PIEVE DI LEDRO',
      },
      {
        value: 'G656',
        label: 'PIEVE TESINO',
      },
      {
        value: 'G667',
        label: 'PILCANTE',
      },
      {
        value: 'G681',
        label: 'PINZOLO',
      },
      {
        value: 'M241',
        label: 'POIA',
      },
      {
        value: 'G808',
        label: 'POMAROLO',
      },
      {
        value: 'G880',
        label: 'POR',
      },
      {
        value: 'M358',
        label: 'PORTE DI RENDENA',
      },
      {
        value: 'G948',
        label: 'POVO',
      },
      {
        value: 'G950',
        label: 'POZZA o POZZA DI FASSA',
      },
      {
        value: 'G984',
        label: 'PRANZO',
      },
      {
        value: 'G989',
        label: 'PRASO',
      },
      {
        value: 'H012',
        label: "PRE'",
      },
      {
        value: 'M344',
        label: 'PREDAIA',
      },
      {
        value: 'H018',
        label: 'PREDAZZO',
      },
      {
        value: 'H023',
        label: 'PREGASINA',
      },
      {
        value: 'H024',
        label: 'PREGHENA',
      },
      {
        value: 'H035',
        label: 'PREMIONE',
      },
      {
        value: 'H039',
        label: 'PREORE',
      },
      {
        value: 'H049',
        label: 'PRESSON',
      },
      {
        value: 'H057',
        label: 'PREZZO',
      },
      {
        value: 'H066',
        label: 'PRIMIERO',
      },
      {
        value: 'M359',
        label: 'PRIMIERO SAN MARTINO DI CASTROZZA',
      },
      {
        value: 'H067',
        label: "PRIO'",
      },
      {
        value: 'H125',
        label: 'QUETTA',
      },
      {
        value: 'H146',
        label: 'RABBI',
      },
      {
        value: 'H162',
        label: 'RAGOLI',
      },
      {
        value: 'M242',
        label: 'RANGO',
      },
      {
        value: 'H181',
        label: 'RANZO',
      },
      {
        value: 'H201',
        label: 'RAVINA',
      },
      {
        value: 'H254',
        label: "REVO'",
      },
      {
        value: 'H330',
        label: 'RIVA o RIVA DEL GARDA',
      },
      {
        value: 'H504',
        label: 'ROMAGNANO',
      },
      {
        value: 'H506',
        label: 'ROMALLO',
      },
      {
        value: 'H515',
        label: 'ROMARZOLLO',
      },
      {
        value: 'H517',
        label: 'ROMENO',
      },
      {
        value: 'H528',
        label: 'RONCEGNO o RONCEGNO TERME',
      },
      {
        value: 'H532',
        label: 'RONCHI o RONCHI VALSUGANA',
      },
      {
        value: 'M243',
        label: 'RONCHI DI ALA',
      },
      {
        value: 'H543',
        label: 'RONCOGNO',
      },
      {
        value: 'H545',
        label: 'RONCONE',
      },
      {
        value: 'H551',
        label: 'RONZO',
      },
      {
        value: 'M303',
        label: 'RONZO-CHIENIS',
      },
      {
        value: 'H552',
        label: 'RONZONE',
      },
      {
        value: 'H605',
        label: 'ROVER CARBONARE',
      },
      {
        value: 'H607',
        label: "ROVERE' DELLA LUNA",
      },
      {
        value: 'H612',
        label: 'ROVERETO',
      },
      {
        value: 'H634',
        label: "RUFFRE' o RUFFRE'-MENDOLA",
      },
      {
        value: 'H639',
        label: 'RUMO',
      },
      {
        value: 'M244',
        label: 'SACCO',
      },
      {
        value: 'H666',
        label: 'SAGRON MIS',
      },
      {
        value: 'H750',
        label: 'SAMOCLEVO',
      },
      {
        value: 'H754',
        label: 'SAMONE',
      },
      {
        value: 'H869',
        label: 'SAN GIACOMO',
      },
      {
        value: 'M390',
        label: 'SAN GIOVANNI DI FASSA',
      },
      {
        value: 'M345',
        label: 'SAN LORENZO DORSINO',
      },
      {
        value: 'H966',
        label: 'SAN LORENZO IN BANALE',
      },
      {
        value: 'H972',
        label: 'SAN LUGANO',
      },
      {
        value: 'I042',
        label: "SAN MICHELE ALL'ADIGE",
      },
      {
        value: 'I354',
        label: "SANT'ORSOLA o SANT'ORSOLA TERME",
      },
      {
        value: 'I229',
        label: 'SANTA MARGHERITA',
      },
      {
        value: 'I411',
        label: 'SANZENO',
      },
      {
        value: 'I419',
        label: 'SAONE',
      },
      {
        value: 'I427',
        label: 'SARDAGNA',
      },
      {
        value: 'I439',
        label: 'SARNONICO',
      },
      {
        value: 'I458',
        label: 'SASSO',
      },
      {
        value: 'M245',
        label: 'SAVIGNANO',
      },
      {
        value: 'I518',
        label: 'SCLEMO',
      },
      {
        value: 'I554',
        label: 'SCURELLE',
      },
      {
        value: 'I575',
        label: 'SEGNO',
      },
      {
        value: 'I576',
        label: 'SEGONZANO',
      },
      {
        value: 'I579',
        label: 'SEIO',
      },
      {
        value: 'M360',
        label: 'SELLA GIUDICARIE',
      },
      {
        value: 'I617',
        label: 'SEO',
      },
      {
        value: 'I665',
        label: "SERRAVALLE ALL'ADIGE",
      },
      {
        value: 'I672',
        label: 'SERSO',
      },
      {
        value: 'I710',
        label: 'SEVIGNANO',
      },
      {
        value: 'I714',
        label: 'SFRUZ',
      },
      {
        value: 'I760',
        label: 'SIROR',
      },
      {
        value: 'I772',
        label: 'SMARANO',
      },
      {
        value: 'I834',
        label: 'SOPRAMONTE',
      },
      {
        value: 'I839',
        label: 'SORAGA o SORAGA DI FASSA',
      },
      {
        value: 'I871',
        label: 'SOVER',
      },
      {
        value: 'I889',
        label: 'SPERA',
      },
      {
        value: 'I899',
        label: 'SPIAZZO',
      },
      {
        value: 'I924',
        label: 'SPORMAGGIORE',
      },
      {
        value: 'I925',
        label: 'SPORMINORE',
      },
      {
        value: 'I949',
        label: 'STENICO',
      },
      {
        value: 'I964',
        label: 'STORO',
      },
      {
        value: 'I967',
        label: 'STRADA',
      },
      {
        value: 'I972',
        label: 'STRAMENTIZZO',
      },
      {
        value: 'M246',
        label: 'STRAVINO',
      },
      {
        value: 'I975',
        label: 'STREMBO',
      },
      {
        value: 'I979',
        label: 'STRIGNO',
      },
      {
        value: 'I988',
        label: 'STUMIAGA',
      },
      {
        value: 'L012',
        label: "SUSA'",
      },
      {
        value: 'L033',
        label: 'TAIO',
      },
      {
        value: 'L060',
        label: 'TASSULLO',
      },
      {
        value: 'L076',
        label: 'TAVODO',
      },
      {
        value: 'L079',
        label: 'TAVON',
      },
      {
        value: 'L089',
        label: 'TELVE',
      },
      {
        value: 'L090',
        label: 'TELVE DI SOPRA',
      },
      {
        value: 'L096',
        label: 'TENNA',
      },
      {
        value: 'L097',
        label: 'TENNO',
      },
      {
        value: 'L107',
        label: 'TERLAGO',
      },
      {
        value: 'L110',
        label: 'TERMENAGO',
      },
      {
        value: 'L114',
        label: 'TERMON',
      },
      {
        value: 'L121',
        label: 'TERRAGNOLO',
      },
      {
        value: 'M407',
        label: "TERRE D'ADIGE",
      },
      {
        value: 'L137',
        label: 'TERRES',
      },
      {
        value: 'L145',
        label: 'TERZOLAS',
      },
      {
        value: 'L147',
        label: 'TESERO',
      },
      {
        value: 'L161',
        label: 'TIARNO',
      },
      {
        value: 'L162',
        label: 'TIARNO DI SOPRA',
      },
      {
        value: 'L163',
        label: 'TIARNO DI SOTTO',
      },
      {
        value: 'M247',
        label: 'TIGNERONE',
      },
      {
        value: 'L174',
        label: 'TIONE o TIONE DI TRENTO',
      },
      {
        value: 'L200',
        label: 'TON',
      },
      {
        value: 'L201',
        label: 'TONADICO',
      },
      {
        value: 'L211',
        label: 'TORCEGNO',
      },
      {
        value: 'L232',
        label: 'TORRA',
      },
      {
        value: 'L313',
        label: 'TOSS',
      },
      {
        value: 'L322',
        label: 'TRAMBILENO o TRAMBILLENO',
      },
      {
        value: 'L329',
        label: 'TRANSACQUA',
      },
      {
        value: 'M361',
        label: 'TRE VILLE',
      },
      {
        value: 'L378',
        label: 'TRENTO',
      },
      {
        value: 'L385',
        label: 'TRES',
      },
      {
        value: 'L456',
        label: 'TUENETTO',
      },
      {
        value: 'L457',
        label: 'TUENNO',
      },
      {
        value: 'L550',
        label: 'VALDA',
      },
      {
        value: 'M343',
        label: 'VALDAONE',
      },
      {
        value: 'L575',
        label: 'VALFLORIANA',
      },
      {
        value: 'L588',
        label: 'VALLARSA',
      },
      {
        value: 'L618',
        label: 'VALLE SAN FELICE',
      },
      {
        value: 'M362',
        label: 'VALLELAGHI',
      },
      {
        value: 'M248',
        label: 'VARANO',
      },
      {
        value: 'L678',
        label: 'VARENA',
      },
      {
        value: 'L694',
        label: 'VASIO',
      },
      {
        value: 'L697',
        label: 'VATTARO',
      },
      {
        value: 'L757',
        label: 'VERDESINA',
      },
      {
        value: 'L769',
        label: 'VERMIGLIO',
      },
      {
        value: 'L800',
        label: "VERVO'",
      },
      {
        value: 'L821',
        label: 'VEZZANO',
      },
      {
        value: 'L832',
        label: 'VIARAGO',
      },
      {
        value: 'L863',
        label: 'VIGALZANO',
      },
      {
        value: 'L884',
        label: 'VIGNOLA',
      },
      {
        value: 'L886',
        label: 'VIGNOLA FALESINA o VIGNOLA-FALESINA',
      },
      {
        value: 'M249',
        label: 'VIGO CAVEDINE',
      },
      {
        value: 'L891',
        label: "VIGO D'ANAUNIA",
      },
      {
        value: 'L893',
        label: 'VIGO DI FASSA',
      },
      {
        value: 'M250',
        label: 'VIGO LOMASO',
      },
      {
        value: 'L903',
        label: 'VIGO RENDENA',
      },
      {
        value: 'L895',
        label: 'VIGOLO BASELGA',
      },
      {
        value: 'L896',
        label: 'VIGOLO VATTARO',
      },
      {
        value: 'L910',
        label: 'VILLA AGNEDO',
      },
      {
        value: 'L911',
        label: 'VILLA BANALE',
      },
      {
        value: 'L957',
        label: 'VILLA LAGARINA',
      },
      {
        value: 'M006',
        label: 'VILLA RENDENA',
      },
      {
        value: 'M251',
        label: 'VILLAMONTAGNA',
      },
      {
        value: 'M036',
        label: 'VILLAZZANO',
      },
      {
        value: 'M363',
        label: "VILLE D'ANAUNIA",
      },
      {
        value: 'M037',
        label: 'VILLE DEL MONTE',
      },
      {
        value: 'M431',
        label: 'VILLE DI FIEMME',
      },
      {
        value: 'M064',
        label: 'VION',
      },
      {
        value: 'M113',
        label: 'VOLANO',
      },
      {
        value: 'M142',
        label: 'ZAMBANA',
      },
      {
        value: 'M173',
        label: 'ZIANO o ZIANO DI FIEMME',
      },
      {
        value: 'M198',
        label: 'ZUCLO',
      },
    ],
  },

  {
    value: 'TO',
    label: 'Torino',
    children: [
      {
        value: 'A003',
        label: 'ABBADIA o ABBADIA ALPINA',
      },
      {
        value: 'A074',
        label: "AGLIE'",
      },
      {
        value: 'A109',
        label: 'AIRASCA',
      },
      {
        value: 'A117',
        label: 'ALA DI STURA',
      },
      {
        value: 'A157',
        label: "ALBIANO o ALBIANO D'IVREA",
      },
      {
        value: 'A199',
        label: 'ALICE SUPERIORE',
      },
      {
        value: 'A218',
        label: 'ALMESE',
      },
      {
        value: 'A221',
        label: 'ALPETTE',
      },
      {
        value: 'A222',
        label: 'ALPIGNANO',
      },
      {
        value: 'A275',
        label: 'ANDEZENO',
      },
      {
        value: 'A282',
        label: 'ANDRATE',
      },
      {
        value: 'A295',
        label: 'ANGROGNA',
      },
      {
        value: 'A405',
        label: 'ARIGNANO',
      },
      {
        value: 'A518',
        label: 'AVIGLIANA',
      },
      {
        value: 'A524',
        label: 'AVUGLIONE E VERNONE',
      },
      {
        value: 'A525',
        label: 'AZEGLIO',
      },
      {
        value: 'A583',
        label: 'BAIO o BAIO DORA',
      },
      {
        value: 'A584',
        label: 'BAIRO',
      },
      {
        value: 'A587',
        label: 'BALANGERO',
      },
      {
        value: 'A590',
        label: 'BALDISSERO o BALDISSERO CANAVESE',
      },
      {
        value: 'A591',
        label: 'BALDISSERO o BALDISSERO TORINESE',
      },
      {
        value: 'A599',
        label: 'BALME',
      },
      {
        value: 'A607',
        label: 'BANCHETTE',
      },
      {
        value: 'A622',
        label: 'BARATONIA',
      },
      {
        value: 'A625',
        label: 'BARBANIA',
      },
      {
        value: 'A644',
        label: 'BARDASSANO',
      },
      {
        value: 'A651',
        label: 'BARDONECCHIA',
      },
      {
        value: 'A673',
        label: 'BARONE CANAVESE',
      },
      {
        value: 'A727',
        label: 'BEAULARD',
      },
      {
        value: 'A734',
        label: 'BEINASCO',
      },
      {
        value: 'A853',
        label: 'BIBIANA',
      },
      {
        value: 'A910',
        label: 'BOBBIO PELLICE o PELLICE',
      },
      {
        value: 'A941',
        label: 'BOLLENGO',
      },
      {
        value: 'A980',
        label: 'BONZO',
      },
      {
        value: 'A990',
        label: 'BORGARO TORINESE',
      },
      {
        value: 'B003',
        label: 'BORGIALLO',
      },
      {
        value: 'B015',
        label: "BORGOFRANCO o BORGOFRANCO D'IVREA",
      },
      {
        value: 'B021',
        label: 'BORGOMASINO',
      },
      {
        value: 'B024',
        label: 'BORGONE o BORGONE SUSA',
      },
      {
        value: 'B075',
        label: 'BOSCONERO',
      },
      {
        value: 'B096',
        label: 'BOUSSON',
      },
      {
        value: 'B103',
        label: 'BOVILE',
      },
      {
        value: 'B121',
        label: 'BRANDIZZO',
      },
      {
        value: 'B171',
        label: 'BRICHERASIO',
      },
      {
        value: 'B205',
        label: 'BROSSO',
      },
      {
        value: 'B209',
        label: 'BROZOLO',
      },
      {
        value: 'B216',
        label: 'BRUINO',
      },
      {
        value: 'B225',
        label: 'BRUSASCO',
      },
      {
        value: 'B226',
        label: 'BRUSASCO CAVAGNOLO',
      },
      {
        value: 'B232',
        label: 'BRUZOLO',
      },
      {
        value: 'B278',
        label: 'BURIASCO',
      },
      {
        value: 'B279',
        label: 'BUROLO',
      },
      {
        value: 'B284',
        label: 'BUSANO',
      },
      {
        value: 'B297',
        label: 'BUSSOLENO',
      },
      {
        value: 'B298',
        label: 'BUSSOLINO o BUSSOLINO GASSINESE',
      },
      {
        value: 'B305',
        label: 'BUTTIGLIERA o BUTTIGLIERA ALTA',
      },
      {
        value: 'B350',
        label: 'CAFASSE',
      },
      {
        value: 'B435',
        label: 'CALUSO',
      },
      {
        value: 'B454',
        label: 'CAMAGNA o CAMAGNA DI TORINO',
      },
      {
        value: 'B462',
        label: 'CAMBIANO',
      },
      {
        value: 'B512',
        label: 'CAMPIGLIONE o CAMPIGLIONE FENILE',
      },
      {
        value: 'B517',
        label: 'CAMPO o CAMPO CANAVESE',
      },
      {
        value: 'B588',
        label: 'CANDIA o CANDIA CANAVESE',
      },
      {
        value: 'B592',
        label: 'CANDIOLO',
      },
      {
        value: 'B605',
        label: 'CANISCHIO',
      },
      {
        value: 'B628',
        label: 'CANTALUPA',
      },
      {
        value: 'B637',
        label: 'CANTOIRA',
      },
      {
        value: 'B705',
        label: 'CAPRIE o CHIAVRIE',
      },
      {
        value: 'B733',
        label: 'CARAVINO o MASINO',
      },
      {
        value: 'B762',
        label: 'CAREMA',
      },
      {
        value: 'B777',
        label: 'CARIGNANO',
      },
      {
        value: 'B791',
        label: 'CARMAGNOLA',
      },
      {
        value: 'B867',
        label: 'CASALBORGONE',
      },
      {
        value: 'B953',
        label: "CASCINETTE D'IVREA",
      },
      {
        value: 'B955',
        label: 'CASELETTE',
      },
      {
        value: 'B960',
        label: 'CASELLE o CASELLE TORINESE',
      },
      {
        value: 'C045',
        label: 'CASTAGNETO o CASTAGNETO PO',
      },
      {
        value: 'C048',
        label: 'CASTAGNOLE o CASTAGNOLE PIEMONTE',
      },
      {
        value: 'C133',
        label: 'CASTELLAMONTE',
      },
      {
        value: 'C241',
        label: 'CASTELNUOVO NIGRA',
      },
      {
        value: 'C307',
        label: 'CASTIGLIONE o CASTIGLIONE TORINESE',
      },
      {
        value: 'C369',
        label: 'CAVAGNOLO',
      },
      {
        value: 'C403',
        label: 'CAVORETTO',
      },
      {
        value: 'C404',
        label: 'CAVOUR',
      },
      {
        value: 'C487',
        label: 'CERCENASCO',
      },
      {
        value: 'C497',
        label: 'CERE o CERES',
      },
      {
        value: 'C505',
        label: 'CERESOLE o CERESOLE REALE',
      },
      {
        value: 'C564',
        label: 'CESANA o CESANA TORINESE',
      },
      {
        value: 'C579',
        label: 'CESNOLA',
      },
      {
        value: 'C597',
        label: 'CHAMPLAS DU COL',
      },
      {
        value: 'C602',
        label: 'CHIABRANO',
      },
      {
        value: 'C604',
        label: 'CHIALAMBERTO',
      },
      {
        value: 'C610',
        label: 'CHIANOC o CHIANOCCO',
      },
      {
        value: 'C624',
        label: 'CHIAVERANO',
      },
      {
        value: 'C627',
        label: 'CHIERI',
      },
      {
        value: 'C629',
        label: 'CHIESANOVA o CHIESANUOVA',
      },
      {
        value: 'C639',
        label: 'CHIOMONTE',
      },
      {
        value: 'C655',
        label: 'CHIUSA o CHIUSA DI SAN MICHELE',
      },
      {
        value: 'C665',
        label: 'CHIVASSO',
      },
      {
        value: 'C679',
        label: 'CICONIO',
      },
      {
        value: 'C711',
        label: 'CINTANO',
      },
      {
        value: 'C715',
        label: 'CINZANO',
      },
      {
        value: 'C722',
        label: "CIRIE'",
      },
      {
        value: 'C793',
        label: 'CLAVIERE o CLAVIERES',
      },
      {
        value: 'C801',
        label: 'COASSOLO o COASSOLO TORINESE',
      },
      {
        value: 'C803',
        label: 'COAZZE',
      },
      {
        value: 'C906',
        label: 'COL SAN GIOVANNI',
      },
      {
        value: 'C860',
        label: 'COLLEGNO',
      },
      {
        value: 'C867',
        label: 'COLLERETTO CASTELNUOVO',
      },
      {
        value: 'C868',
        label: 'COLLERETTO GIACOSA o COLLERETTO PARELLA',
      },
      {
        value: 'C955',
        label: 'CONDOVE',
      },
      {
        value: 'D008',
        label: 'CORIO',
      },
      {
        value: 'D092',
        label: 'COSSANO o COSSANO CANAVESE',
      },
      {
        value: 'D197',
        label: 'CUCEGLIO',
      },
      {
        value: 'D202',
        label: 'CUMIANA',
      },
      {
        value: 'D208',
        label: "CUORGNE'",
      },
      {
        value: 'D285',
        label: 'DESERTES',
      },
      {
        value: 'D373',
        label: 'DRUENT o DRUENTO',
      },
      {
        value: 'D433',
        label: 'ESILLE o EXILLES',
      },
      {
        value: 'D460',
        label: 'FAETTO',
      },
      {
        value: 'D520',
        label: 'FAVRIA',
      },
      {
        value: 'D521',
        label: 'FAVRIA-OGLIANICO',
      },
      {
        value: 'D524',
        label: 'FELETTO',
      },
      {
        value: 'D532',
        label: 'FENESTRELLE',
      },
      {
        value: 'D535',
        label: 'FENILE',
      },
      {
        value: 'D536',
        label: 'FENILS',
      },
      {
        value: 'D553',
        label: 'FERRERA o FERRERA CENISIO o MONCENISIO',
      },
      {
        value: 'D562',
        label: 'FIANO',
      },
      {
        value: 'D608',
        label: 'FIORANO o FIORANO CANAVESE',
      },
      {
        value: 'D646',
        label: 'FOGLIZZO',
      },
      {
        value: 'D699',
        label: 'FORESTO o FORESTO DI SUSA',
      },
      {
        value: 'D724',
        label: 'FORNO ALPI GRAIE o FORNO GROSCAVALLO',
      },
      {
        value: 'D725',
        label: 'FORNO CANAVESE o FORNO DI RIVARA',
      },
      {
        value: 'D779',
        label: 'FRASSINERE',
      },
      {
        value: 'D781',
        label: 'FRASSINETTO',
      },
      {
        value: 'D805',
        label: 'FRONT',
      },
      {
        value: 'D812',
        label: 'FROSSASCO',
      },
      {
        value: 'D931',
        label: 'GARZIGLIANA',
      },
      {
        value: 'D933',
        label: 'GASSINO o GASSINO TORINESE',
      },
      {
        value: 'D983',
        label: 'GERMAGNANO',
      },
      {
        value: 'E009',
        label: 'GIAGLIONE',
      },
      {
        value: 'E020',
        label: 'GIAVENO',
      },
      {
        value: 'E067',
        label: 'GIVOLETTO',
      },
      {
        value: 'E154',
        label: 'GRAVERE',
      },
      {
        value: 'E199',
        label: 'GROSCAVALLO',
      },
      {
        value: 'E203',
        label: 'GROSSO',
      },
      {
        value: 'E216',
        label: 'GRUGLIASCO',
      },
      {
        value: 'E301',
        label: 'INGRIA',
      },
      {
        value: 'E311',
        label: 'INVERSO PINASCA',
      },
      {
        value: 'E312',
        label: 'INVERSO PORTE',
      },
      {
        value: 'E345',
        label: 'ISOLABELLA',
      },
      {
        value: 'E368',
        label: 'ISSIGLIO',
      },
      {
        value: 'E379',
        label: 'IVREA',
      },
      {
        value: 'E394',
        label: 'LA CASSA',
      },
      {
        value: 'E423',
        label: 'LA LOGGIA',
      },
      {
        value: 'E445',
        label: 'LANZO o LANZO TORINESE',
      },
      {
        value: 'E484',
        label: 'LAURIANO',
      },
      {
        value: 'E518',
        label: 'LEINI o LEYNI',
      },
      {
        value: 'E520',
        label: 'LEMIE',
      },
      {
        value: 'E551',
        label: 'LESSOLO',
      },
      {
        value: 'E566',
        label: 'LEVONE',
      },
      {
        value: 'E635',
        label: 'LOCANA',
      },
      {
        value: 'E660',
        label: 'LOMBARDORE',
      },
      {
        value: 'E661',
        label: 'LOMBRIASCO',
      },
      {
        value: 'E683',
        label: "LORANZE'",
      },
      {
        value: 'E727',
        label: 'LUGNACCO',
      },
      {
        value: 'E756',
        label: 'LUSERNA',
      },
      {
        value: 'E758',
        label: 'LUSERNA SAN GIOVANNI',
      },
      {
        value: 'E759',
        label: 'LUSERNETTA',
      },
      {
        value: 'E763',
        label: "LUSIGLIE'",
      },
      {
        value: 'E782',
        label: 'MACELLO',
      },
      {
        value: 'E817',
        label: 'MAGLIONE',
      },
      {
        value: 'E890',
        label: 'MANIGLIA',
      },
      {
        value: 'M316',
        label: 'MAPPANO',
      },
      {
        value: 'E937',
        label: 'MARCORENGO',
      },
      {
        value: 'E941',
        label: 'MARENTINO',
      },
      {
        value: 'F041',
        label: 'MASSELLO',
      },
      {
        value: 'F053',
        label: 'MATHI o MATI',
      },
      {
        value: 'F058',
        label: 'MATTIE',
      },
      {
        value: 'F067',
        label: "MAZZE'",
      },
      {
        value: 'F074',
        label: 'MEANA o MEANA DI SUSA',
      },
      {
        value: 'F075',
        label: 'MEANO',
      },
      {
        value: 'F103',
        label: 'MELEZET',
      },
      {
        value: 'F128',
        label: 'MENTOULLES',
      },
      {
        value: 'F140',
        label: 'MERCENASCO',
      },
      {
        value: 'F164',
        label: 'MEUGLIANO',
      },
      {
        value: 'F182',
        label: 'MEZZENILE',
      },
      {
        value: 'F212',
        label: 'MILLAURES',
      },
      {
        value: 'F255',
        label: 'MOCCHIE',
      },
      {
        value: 'F298',
        label: 'MOLLIERES',
      },
      {
        value: 'F315',
        label: 'MOMBELLO o MOMBELLO DI TORINO',
      },
      {
        value: 'F318',
        label: 'MOMPANTERO',
      },
      {
        value: 'F327',
        label: 'MONASTERO o MONASTERO DI LANZO',
      },
      {
        value: 'F331',
        label: 'MONASTEROLO o MONASTEROLO TORINESE',
      },
      {
        value: 'F335',
        label: 'MONCALIERI',
      },
      {
        value: 'F353',
        label: 'MONDRONE',
      },
      {
        value: 'F407',
        label: 'MONTALDO o MONTALDO TORINESE',
      },
      {
        value: 'F411',
        label: 'MONTALENGHE',
      },
      {
        value: 'F420',
        label: 'MONTALTO o MONTALTO DORA',
      },
      {
        value: 'F422',
        label: 'MONTANARO',
      },
      {
        value: 'F650',
        label: 'MONTESTRUTTO',
      },
      {
        value: 'F651',
        label: 'MONTEU DA PO',
      },
      {
        value: 'F733',
        label: 'MORIONDO TORINESE',
      },
      {
        value: 'F889',
        label: 'NICHELINO',
      },
      {
        value: 'F906',
        label: 'NOASCA',
      },
      {
        value: 'F925',
        label: 'NOLE',
      },
      {
        value: 'F927',
        label: 'NOMAGLIO',
      },
      {
        value: 'F931',
        label: 'NONE',
      },
      {
        value: 'F948',
        label: 'NOVALESA',
      },
      {
        value: 'G010',
        label: 'OGLIANICO',
      },
      {
        value: 'G033',
        label: 'OLIVA o TAVERNETTE',
      },
      {
        value: 'G087',
        label: 'ORBASSANO',
      },
      {
        value: 'G109',
        label: 'ORIO o ORIO CANAVESE',
      },
      {
        value: 'G151',
        label: 'OSASCO',
      },
      {
        value: 'G152',
        label: 'OSASIO',
      },
      {
        value: 'G196',
        label: 'OULX o ULZIO',
      },
      {
        value: 'G202',
        label: 'OZEGNA',
      },
      {
        value: 'G262',
        label: 'PALAZZO o PALAZZO CANAVESE',
      },
      {
        value: 'G269',
        label: 'PALAZZO PIVERONE',
      },
      {
        value: 'G303',
        label: 'PANCALIERI',
      },
      {
        value: 'G330',
        label: 'PARELLA',
      },
      {
        value: 'G387',
        label: 'PAVAROLO',
      },
      {
        value: 'G392',
        label: 'PAVONE o PAVONE CANAVESE',
      },
      {
        value: 'G396',
        label: 'PECCO',
      },
      {
        value: 'G398',
        label: 'PECETTO TORINESE',
      },
      {
        value: 'G462',
        label: 'PEROSA o PEROSA CANAVESE',
      },
      {
        value: 'G463',
        label: 'PEROSA o PEROSA ARGENTINA',
      },
      {
        value: 'G465',
        label: 'PERRERO',
      },
      {
        value: 'G477',
        label: 'PERTUSIO',
      },
      {
        value: 'G505',
        label: 'PESSINETTO',
      },
      {
        value: 'G559',
        label: 'PIANEZZA',
      },
      {
        value: 'G584',
        label: 'PIAZZO',
      },
      {
        value: 'G672',
        label: 'PINASCA',
      },
      {
        value: 'G674',
        label: 'PINEROLO',
      },
      {
        value: 'G678',
        label: 'PINO DI CHIERI o PINO TORINESE',
      },
      {
        value: 'G684',
        label: 'PIOBESI o PIOBESI TORINESE',
      },
      {
        value: 'G691',
        label: 'PIOSSASCO',
      },
      {
        value: 'G705',
        label: 'PISCINA',
      },
      {
        value: 'G719',
        label: 'PIVERONE',
      },
      {
        value: 'G777',
        label: 'POIRINO',
      },
      {
        value: 'G805',
        label: 'POMARETTO',
      },
      {
        value: 'G826',
        label: 'PONT CANAVESE o PONT-CANAVESE',
      },
      {
        value: 'G900',
        label: 'PORTE',
      },
      {
        value: 'G973',
        label: 'PRAGELATO',
      },
      {
        value: 'G978',
        label: 'PRALI o PRALY',
      },
      {
        value: 'G979',
        label: 'PRALORMO',
      },
      {
        value: 'G982',
        label: 'PRAMOLLO',
      },
      {
        value: 'G986',
        label: 'PRAROSTINO',
      },
      {
        value: 'G988',
        label: 'PRASCORSANO',
      },
      {
        value: 'G997',
        label: 'PRATIGLIONE',
      },
      {
        value: 'H058',
        label: 'PRIACCO',
      },
      {
        value: 'H100',
        label: 'QUAGLIUZZO',
      },
      {
        value: 'H120',
        label: 'QUASSOLO',
      },
      {
        value: 'H127',
        label: 'QUINCINETTO',
      },
      {
        value: 'H207',
        label: 'REANO',
      },
      {
        value: 'H251',
        label: 'REVIGLIASCO o REVIGLIASCO TORINESE',
      },
      {
        value: 'H270',
        label: 'RIBORDONE',
      },
      {
        value: 'H278',
        label: 'RICLARETTO',
      },
      {
        value: 'H337',
        label: 'RIVA PRESSO CHIERI',
      },
      {
        value: 'H333',
        label: 'RIVALBA',
      },
      {
        value: 'H335',
        label: 'RIVALTA o RIVALTA DI TORINO',
      },
      {
        value: 'H338',
        label: 'RIVARA',
      },
      {
        value: 'H340',
        label: 'RIVAROLO o RIVAROLO CANAVESE',
      },
      {
        value: 'H344',
        label: 'RIVAROSSA',
      },
      {
        value: 'H349',
        label: 'RIVERA',
      },
      {
        value: 'H355',
        label: 'RIVOLI',
      },
      {
        value: 'H367',
        label: 'ROBASSOMERO',
      },
      {
        value: 'H386',
        label: 'ROCCA CANAVESE o ROCCA DI CORIO',
      },
      {
        value: 'H430',
        label: 'ROCCAPIATTA',
      },
      {
        value: 'H457',
        label: 'ROCCHEMOLLES',
      },
      {
        value: 'H483',
        label: 'RODORETTO',
      },
      {
        value: 'H498',
        label: 'ROLETTO',
      },
      {
        value: 'H499',
        label: 'ROLLIERES',
      },
      {
        value: 'H511',
        label: 'ROMANO o ROMANO CANAVESE',
      },
      {
        value: 'H539',
        label: 'RONCO o RONCO CANAVESE',
      },
      {
        value: 'H547',
        label: 'RONDISSONE',
      },
      {
        value: 'H554',
        label: "RORA'",
      },
      {
        value: 'H555',
        label: 'RORETO o RORETO CHISONE o ROURE',
      },
      {
        value: 'H583',
        label: 'ROSTA',
      },
      {
        value: 'H627',
        label: 'RUBIANA',
      },
      {
        value: 'H631',
        label: 'RUEGLIO',
      },
      {
        value: 'H684',
        label: 'SALABERTANO o SALBERTRAND',
      },
      {
        value: 'H691',
        label: 'SALASSA',
      },
      {
        value: 'H696',
        label: 'SALE CANISCHIO',
      },
      {
        value: 'H702',
        label: 'SALERANO o SALERANO CANAVESE',
      },
      {
        value: 'H722',
        label: 'SALTO',
      },
      {
        value: 'H734',
        label: 'SALZA o SALZA DI PINEROLO',
      },
      {
        value: 'H753',
        label: 'SAMONE',
      },
      {
        value: 'H775',
        label: 'SAN BENIGNO CANAVESE',
      },
      {
        value: 'H789',
        label: "SAN CARLO o SAN CARLO CANAVESE o SAN CARLO DI CIRIE'",
      },
      {
        value: 'H804',
        label: 'SAN COLOMBANO o SAN COLOMBANO BELMONTE',
      },
      {
        value: 'H820',
        label: 'SAN DIDERO',
      },
      {
        value: 'H847',
        label: 'SAN FRANCESCO AL CAMPO o VAUDA SAN MORIZIO',
      },
      {
        value: 'H862',
        label: 'SAN GERMANO o SAN GERMANO CHISONE',
      },
      {
        value: 'H873',
        label: 'SAN GILLIO',
      },
      {
        value: 'H874',
        label: 'SAN GILLIO TORINESE',
      },
      {
        value: 'H890',
        label: 'SAN GIORGIO o SAN GIORGIO CANAVESE',
      },
      {
        value: 'H900',
        label: 'SAN GIORIO DI SUSA',
      },
      {
        value: 'H904',
        label: 'SAN GIOVANNI o SAN GIOVANNI PELLICE',
      },
      {
        value: 'H936',
        label: 'SAN GIUSTO o SAN GIUSTO CANAVESE',
      },
      {
        value: 'H997',
        label: 'SAN MARTINO o SAN MARTINO CANAVESE',
      },
      {
        value: 'H998',
        label: 'SAN MARTINO o SAN MARTINO DI PERRERO',
      },
      {
        value: 'I024',
        label: 'SAN MAURIZIO CANAVESE o SAN MORIZIO o SAN MORIZIO CANAVESE',
      },
      {
        value: 'I030',
        label: 'SAN MAURO o SAN MAURO TORINESE',
      },
      {
        value: 'I090',
        label: 'SAN PIETRO o SAN PIETRO VAL LEMINA',
      },
      {
        value: 'I137',
        label: 'SAN RAFFAELE CIMENA',
      },
      {
        value: 'I152',
        label: 'SAN SEBASTIANO o SAN SEBASTIANO DA PO',
      },
      {
        value: 'I154',
        label: 'SAN SECONDO o SAN SECONDO DI PINEROLO',
      },
      {
        value: 'H855',
        label: 'SANGANO',
      },
      {
        value: 'I258',
        label: "SANT'AMBROGIO o SANT'AMBROGIO DI TORINO",
      },
      {
        value: 'I296',
        label: "SANT'ANTONINO o SANT'ANTONINO DI SUSA",
      },
      {
        value: 'I327',
        label: 'SANTENA',
      },
      {
        value: 'I466',
        label: "SAUZE D'OULX",
      },
      {
        value: 'I465',
        label: 'SAUZE DI CESANA',
      },
      {
        value: 'I481',
        label: 'SAVOULX',
      },
      {
        value: 'I490',
        label: 'SCALENGHE',
      },
      {
        value: 'I511',
        label: 'SCARMAGNO',
      },
      {
        value: 'I539',
        label: 'SCIOLZE',
      },
      {
        value: 'I692',
        label: 'SESTRIERE o SESTRIERES',
      },
      {
        value: 'I701',
        label: 'SETTIMO ROTTARO',
      },
      {
        value: 'I702',
        label: 'SETTIMO TAVAGNASCO o SETTIMO VITTONE',
      },
      {
        value: 'I703',
        label: 'SETTIMO TORINESE',
      },
      {
        value: 'I807',
        label: 'SOLOMIAC',
      },
      {
        value: 'I886',
        label: 'SPARONE',
      },
      {
        value: 'I969',
        label: 'STRAMBINELLO',
      },
      {
        value: 'I970',
        label: 'STRAMBINO',
      },
      {
        value: 'L013',
        label: 'SUSA',
      },
      {
        value: 'L066',
        label: 'TAVAGNASCO',
      },
      {
        value: 'L159',
        label: 'THURES',
      },
      {
        value: 'L171',
        label: 'TINA',
      },
      {
        value: 'L219',
        label: 'TORINO',
      },
      {
        value: 'L238',
        label: 'TORRAZZA PIEMONTE',
      },
      {
        value: 'L247',
        label: 'TORRE BAIRO o TORRE CANAVESE',
      },
      {
        value: 'L277',
        label: 'TORRE PELLICE',
      },
      {
        value: 'L327',
        label: 'TRANA',
      },
      {
        value: 'L338',
        label: 'TRAUSELLA',
      },
      {
        value: 'L340',
        label: 'TRAVE o TRAVES',
      },
      {
        value: 'L344',
        label: 'TRAVERSE',
      },
      {
        value: 'L345',
        label: 'TRAVERSELLA',
      },
      {
        value: 'L445',
        label: 'TROFARELLO',
      },
      {
        value: 'L515',
        label: 'USSEAUX',
      },
      {
        value: 'L516',
        label: 'USSEGLIO',
      },
      {
        value: 'L538',
        label: 'VAIE o VAYES',
      },
      {
        value: 'L555',
        label: 'VAL DELLA TORRE',
      },
      {
        value: 'M405',
        label: 'VAL DI CHY',
      },
      {
        value: 'L548',
        label: 'VALCHIUSA o VICO o VICO CANAVESE',
      },
      {
        value: 'M415',
        label: 'VALCHIUSA',
      },
      {
        value: 'L578',
        label: 'VALGIOIE',
      },
      {
        value: 'L629',
        label: 'VALLO o VALLO TORINESE',
      },
      {
        value: 'L644',
        label: 'VALPERGA',
      },
      {
        value: 'L685',
        label: 'VARISELLA',
      },
      {
        value: 'L698',
        label: 'VAUDA CANAVESE o VAUDA DI FRONT',
      },
      {
        value: 'L726',
        label: 'VENALZIO o VENAUS',
      },
      {
        value: 'L727',
        label: 'VENARIA REALE',
      },
      {
        value: 'L779',
        label: 'VEROLENGO',
      },
      {
        value: 'L787',
        label: 'VERRUA o VERRUA SAVOIA',
      },
      {
        value: 'L811',
        label: "VESTIGNE'",
      },
      {
        value: 'L830',
        label: "VIALFRE'",
      },
      {
        value: 'L857',
        label: 'VIDRACCO',
      },
      {
        value: 'L898',
        label: 'VIGONE',
      },
      {
        value: 'L948',
        label: 'VILLAFRANCA o VILLAFRANCA PIEMONTE o VILLAFRANCA SABAUDA',
      },
      {
        value: 'L982',
        label: 'VILLANOVA CANAVESE o VILLANOVA MATHI',
      },
      {
        value: 'L999',
        label: 'VILLAR ALMESE o VILLAR DORA',
      },
      {
        value: 'M007',
        label: 'VILLAR FOCCHIARDO',
      },
      {
        value: 'M013',
        label: 'VILLAR PELLICE',
      },
      {
        value: 'M014',
        label: 'VILLAR PEROSA',
      },
      {
        value: 'M002',
        label: 'VILLARBASSE',
      },
      {
        value: 'M004',
        label: 'VILLAREGGIA',
      },
      {
        value: 'M027',
        label: 'VILLASTELLONE',
      },
      {
        value: 'M060',
        label: 'VINOVO',
      },
      {
        value: 'M069',
        label: 'VIRLE o VIRLE PIEMONTE',
      },
      {
        value: 'M071',
        label: 'VISCHE',
      },
      {
        value: 'M080',
        label: 'VISTRORIO',
      },
      {
        value: 'M094',
        label: "VIU'",
      },
      {
        value: 'M122',
        label: 'VOLPIANO',
      },
      {
        value: 'M133',
        label: 'VOLVERA',
      },
    ],
  },

  {
    value: 'TP',
    label: 'Trapani',
    children: [
      {
        value: 'A176',
        label: 'ALCAMO',
      },
      {
        value: 'B288',
        label: 'BUSETO PALIZZOLO',
      },
      {
        value: 'B521',
        label: 'CAMPOBELLO DI MAZARA',
      },
      {
        value: 'C130',
        label: 'CASTELLAMMARE DEL GOLFO',
      },
      {
        value: 'C286',
        label: 'CASTELVETRANO',
      },
      {
        value: 'D234',
        label: 'CUSTONACI',
      },
      {
        value: 'D423',
        label: 'ERICE o MONTE SAN GIULIANO',
      },
      {
        value: 'D518',
        label: 'FAVIGNANA',
      },
      {
        value: 'E023',
        label: 'GIBELLINA',
      },
      {
        value: 'E974',
        label: 'MARSALA',
      },
      {
        value: 'F061',
        label: 'MAZARA o MAZARA DEL VALLO',
      },
      {
        value: 'G208',
        label: 'PACECO',
      },
      {
        value: 'G315',
        label: 'PANTELLERIA',
      },
      {
        value: 'G319',
        label: 'PAPARELLA SAN MARCO o VALDERICE',
      },
      {
        value: 'G347',
        label: 'PARTANNA',
      },
      {
        value: 'M281',
        label: 'PETROSINO',
      },
      {
        value: 'G767',
        label: 'POGGIOREALE',
      },
      {
        value: 'H688',
        label: 'SALAPARUTA',
      },
      {
        value: 'H700',
        label: 'SALEMI',
      },
      {
        value: 'I407',
        label: 'SAN VITO LO CAPO',
      },
      {
        value: 'I291',
        label: 'SANTA NINFA',
      },
      {
        value: 'L331',
        label: 'TRAPANI',
      },
      {
        value: 'M081',
        label: 'VITA',
      },
      {
        value: 'M137',
        label: 'XITTA',
      },
    ],
  },

  {
    value: 'TR',
    label: 'Terni',
    children: [
      {
        value: 'A045',
        label: 'ACQUASPARTA',
      },
      {
        value: 'A207',
        label: 'ALLERONA',
      },
      {
        value: 'A242',
        label: 'ALVIANO',
      },
      {
        value: 'A262',
        label: 'AMELIA',
      },
      {
        value: 'A439',
        label: 'ARRONE',
      },
      {
        value: 'A490',
        label: 'ATTIGLIANO',
      },
      {
        value: 'M258',
        label: 'AVIGLIANO UMBRO',
      },
      {
        value: 'A691',
        label: 'BASCHI',
      },
      {
        value: 'B446',
        label: "CALVI o CALVI DELL'UMBRIA",
      },
      {
        value: 'C117',
        label: 'CASTEL GIORGIO',
      },
      {
        value: 'C289',
        label: 'CASTEL VISCARDO',
      },
      {
        value: 'C873',
        label: 'COLLESCIPOLI',
      },
      {
        value: 'C874',
        label: 'COLLESTATTE',
      },
      {
        value: 'D454',
        label: 'FABRO',
      },
      {
        value: 'D538',
        label: 'FERENTILLO',
      },
      {
        value: 'D570',
        label: 'FICULLE',
      },
      {
        value: 'E045',
        label: 'GIOVE',
      },
      {
        value: 'E241',
        label: 'GUARDEA',
      },
      {
        value: 'E729',
        label: 'LUGNANO o LUGNANO IN TEVERINA',
      },
      {
        value: 'F457',
        label: 'MONTECASTRILLI',
      },
      {
        value: 'F462',
        label: 'MONTECCHIO',
      },
      {
        value: 'F510',
        label: 'MONTEFRANCO',
      },
      {
        value: 'F513',
        label: 'MONTEGABBIONE',
      },
      {
        value: 'F543',
        label: "MONTELEONE o MONTELEONE D'ORVIETO",
      },
      {
        value: 'F844',
        label: 'NARNI',
      },
      {
        value: 'G148',
        label: 'ORVIETO',
      },
      {
        value: 'G189',
        label: 'OTRICOLI',
      },
      {
        value: 'G322',
        label: 'PAPIGNO',
      },
      {
        value: 'G344',
        label: 'PARRANO',
      },
      {
        value: 'G432',
        label: 'PENNA o PENNA IN TEVERINA',
      },
      {
        value: 'G595',
        label: 'PIEDILUCO',
      },
      {
        value: 'G790',
        label: 'POLINO',
      },
      {
        value: 'G881',
        label: 'PORANO',
      },
      {
        value: 'G884',
        label: 'PORCHIANO o PORCHIANO DEL MONTE',
      },
      {
        value: 'H857',
        label: 'SAN GEMINI',
      },
      {
        value: 'I381',
        label: 'SAN VENANZO',
      },
      {
        value: 'I981',
        label: 'STRONCONE',
      },
      {
        value: 'L117',
        label: 'TERNI',
      },
      {
        value: 'L275',
        label: 'TORRE ORSINA',
      },
    ],
  },

  {
    value: 'TS',
    label: 'Trieste',
    children: [
      {
        value: 'A141',
        label: 'ALBARO VESCOVA',
      },
      {
        value: 'A148',
        label: 'ALBER DI SESANA',
      },
      {
        value: 'A498',
        label: 'AUREMO DI SOPRA',
      },
      {
        value: 'A500',
        label: 'AURISINA o NABRESINA',
      },
      {
        value: 'B244',
        label: 'BUCUIE',
      },
      {
        value: 'B318',
        label: 'CACCIA o VILLA CACCIA',
      },
      {
        value: 'B713',
        label: 'CAPRIVA DEL CARSO',
      },
      {
        value: 'C391',
        label: 'CAVE AUREMIANE',
      },
      {
        value: 'D002',
        label: 'CORGNALE',
      },
      {
        value: 'D090',
        label: 'COSSANA',
      },
      {
        value: 'D153',
        label: 'CRENOVIZZA',
      },
      {
        value: 'D307',
        label: 'DIVACCIA GROTTE DEL TIMAVO',
      },
      {
        value: 'D308',
        label: 'DIVACCIA SAN CANZIANO',
      },
      {
        value: 'D324',
        label: 'DOLINA o SAN DORLIGO DELLA VALLE',
      },
      {
        value: 'D382',
        label: 'DUINO',
      },
      {
        value: 'D383',
        label: 'DUINO AURISINA o DUINO-AURISINA',
      },
      {
        value: 'D389',
        label: 'DUTTOGLIANO',
      },
      {
        value: 'D485',
        label: 'FAMIE',
      },
      {
        value: 'E460',
        label: 'LASE',
      },
      {
        value: 'E849',
        label: 'MALCHINA o MAUCHINIE',
      },
      {
        value: 'F378',
        label: 'MONRUPINO',
      },
      {
        value: 'F795',
        label: 'MUGGIA',
      },
      {
        value: 'F834',
        label: 'NACLA SAN MAURIZIO',
      },
      {
        value: 'G380',
        label: 'PAUGNANO',
      },
      {
        value: 'G941',
        label: 'POSTUMIA GROTTE',
      },
      {
        value: 'G946',
        label: 'POVERIO',
      },
      {
        value: 'H237',
        label: 'REPNO o RUPINGRANDE',
      },
      {
        value: 'H482',
        label: 'RODITTI',
      },
      {
        value: 'H871',
        label: 'SAN GIACOMO IN COLLE',
      },
      {
        value: 'I044',
        label: 'SAN MICHELE DI POSTUMIA',
      },
      {
        value: 'I078',
        label: 'SAN PELAGIO',
      },
      {
        value: 'I100',
        label: 'SAN PIETRO DEL CARSO',
      },
      {
        value: 'I547',
        label: 'SCOPPO',
      },
      {
        value: 'I616',
        label: 'SENOSECCHIA',
      },
      {
        value: 'I674',
        label: 'SESANA',
      },
      {
        value: 'I715',
        label: 'SGONICO',
      },
      {
        value: 'I746',
        label: 'SINADOLE',
      },
      {
        value: 'I770',
        label: 'SLIVIA o SLIVNO',
      },
      {
        value: 'I961',
        label: 'STORIE',
      },
      {
        value: 'L198',
        label: 'TOMADIO',
      },
      {
        value: 'L424',
        label: 'TRIESTE',
      },
      {
        value: 'M024',
        label: 'VILLA SLAVINA',
      },
      {
        value: 'L914',
        label: 'VILLABASSA',
      },
    ],
  },

  {
    value: 'TV',
    label: 'Treviso',
    children: [
      {
        value: 'A136',
        label: "ALBAREDO o SANT'ANDREA DI CAVASAGRA",
      },
      {
        value: 'A237',
        label: 'ALTIVOLE',
      },
      {
        value: 'A360',
        label: 'ARCADE',
      },
      {
        value: 'A471',
        label: 'ASOLO',
      },
      {
        value: 'B061',
        label: 'BORSO o BORSO DEL GRAPPA',
      },
      {
        value: 'B128',
        label: 'BREDA o BREDA DI PIAVE',
      },
      {
        value: 'B349',
        label: 'CAERANO o CAERANO DI SAN MARCO',
      },
      {
        value: 'B678',
        label: 'CAPPELLA o CAPPELLA MAGGIORE',
      },
      {
        value: 'B744',
        label: 'CARBONERA',
      },
      {
        value: 'B879',
        label: 'CASALE o CASALE SUL SILE',
      },
      {
        value: 'B965',
        label: 'CASIER',
      },
      {
        value: 'C073',
        label: 'CASTELCUCCO',
      },
      {
        value: 'C111',
        label: 'CASTELFRANCO o CASTELFRANCO VENETO',
      },
      {
        value: 'C190',
        label: 'CASTELLO DI GODEGO o GODEGO',
      },
      {
        value: 'C384',
        label: 'CAVASO o CAVASO DEL TOMBA',
      },
      {
        value: 'C460',
        label: 'CENEDA',
      },
      {
        value: 'C580',
        label: 'CESSALTO',
      },
      {
        value: 'C614',
        label: 'CHIARANO',
      },
      {
        value: 'C670',
        label: "CIANO-NOGARE' o CROCETTA DEL MONTELLO o CROCETTA TREVIGIANA",
      },
      {
        value: 'C689',
        label: 'CIMADOLMO',
      },
      {
        value: 'C735',
        label: 'CISON o CISON DI VALMARINO',
      },
      {
        value: 'C815',
        label: "CODOGNE'",
      },
      {
        value: 'C848',
        label: 'COLLE o COLLE UMBERTO',
      },
      {
        value: 'C957',
        label: 'CONEGLIANO',
      },
      {
        value: 'C992',
        label: 'CORDIGNANO',
      },
      {
        value: 'D030',
        label: 'CORNUDA',
      },
      {
        value: 'D157',
        label: 'CRESPANO o CRESPANO DEL GRAPPA o CRESPANO VENETO',
      },
      {
        value: 'D505',
        label: 'FARRA o FARRA DI SOLIGO',
      },
      {
        value: 'D654',
        label: 'FOLLINA',
      },
      {
        value: 'D674',
        label: 'FONTANELLE',
      },
      {
        value: 'D680',
        label: 'FONTE',
      },
      {
        value: 'D794',
        label: 'FREGONA',
      },
      {
        value: 'D854',
        label: 'GAIARINE',
      },
      {
        value: 'E021',
        label: 'GIAVERA DEL MONTELLO',
      },
      {
        value: 'E071',
        label: "GODEGA o GODEGA DI SANT'URBANO",
      },
      {
        value: 'E092',
        label: 'GORGO o GORGO AL MONTICANO',
      },
      {
        value: 'E373',
        label: 'ISTRANA',
      },
      {
        value: 'E408',
        label: 'LAGO o SAN GIORGIO o SAN GIORGIO DI LAGO',
      },
      {
        value: 'E692',
        label: 'LORIA',
      },
      {
        value: 'E893',
        label: "MANSUE'",
      },
      {
        value: 'E940',
        label: 'MARENO o MARENO DI PIAVE',
      },
      {
        value: 'F009',
        label: 'MASER',
      },
      {
        value: 'F012',
        label: 'MASERADA o MASERADA SUL PIAVE',
      },
      {
        value: 'F088',
        label: 'MEDUNA o MEDUNA DI LIVENZA',
      },
      {
        value: 'F116',
        label: 'MELMA o SILEA',
      },
      {
        value: 'F190',
        label: 'MIANE',
      },
      {
        value: 'F269',
        label: 'MOGLIANO o MOGLIANO VENETO',
      },
      {
        value: 'F332',
        label: 'MONASTIER o MONASTIER DI TREVISO',
      },
      {
        value: 'F360',
        label: 'MONFUMO',
      },
      {
        value: 'F443',
        label: 'MONTEBELLUNA',
      },
      {
        value: 'F725',
        label: 'MORGANO',
      },
      {
        value: 'F729',
        label: 'MORIAGO o MORIAGO DELLA BATTAGLIA',
      },
      {
        value: 'F770',
        label: 'MOTTA o MOTTA DI LIVENZA',
      },
      {
        value: 'F872',
        label: 'NERVESA o NERVESA DELLA BATTAGLIA',
      },
      {
        value: 'F999',
        label: 'ODERZO',
      },
      {
        value: 'G115',
        label: 'ORMELLE',
      },
      {
        value: 'G123',
        label: 'ORSAGO',
      },
      {
        value: 'G221',
        label: "PADERNO o PADERNO D'ASOLO o PADERNO DEL GRAPPA",
      },
      {
        value: 'G229',
        label: 'PAESE',
      },
      {
        value: 'G408',
        label: 'PEDEROBBA',
      },
      {
        value: 'G578',
        label: 'PIAVON',
      },
      {
        value: 'M422',
        label: 'PIEVE DEL GRAPPA',
      },
      {
        value: 'G645',
        label: 'PIEVE DI SOLIGO',
      },
      {
        value: 'G846',
        label: 'PONTE DI PIAVE',
      },
      {
        value: 'G875',
        label: 'PONZANO o PONZANO VENETO',
      },
      {
        value: 'G909',
        label: "PORTOBUFFOLE'",
      },
      {
        value: 'G933',
        label: 'POSSAGNO',
      },
      {
        value: 'G944',
        label: 'POVEGLIANO',
      },
      {
        value: 'H022',
        label: 'PREGANZIOL',
      },
      {
        value: 'H131',
        label: 'QUINTO o QUINTO DI TREVISO',
      },
      {
        value: 'H220',
        label: 'REFRONTOLO',
      },
      {
        value: 'H238',
        label: 'RESANA',
      },
      {
        value: 'H252',
        label: 'REVINE',
      },
      {
        value: 'H253',
        label: 'REVINE LAGO',
      },
      {
        value: 'H280',
        label: 'RIESE o RIESE PIO X',
      },
      {
        value: 'H523',
        label: 'RONCADE',
      },
      {
        value: 'H706',
        label: 'SALGAREDA',
      },
      {
        value: 'H781',
        label: 'SAN BIAGIO DI CALLALTA',
      },
      {
        value: 'H843',
        label: 'SAN FIOR',
      },
      {
        value: 'I101',
        label: 'SAN PIETRO DI BARBOZZA',
      },
      {
        value: 'I103',
        label: 'SAN PIETRO DI FELETTO',
      },
      {
        value: 'I124',
        label: 'SAN POLO o SAN POLO DI PIAVE',
      },
      {
        value: 'I382',
        label: 'SAN VENDEMIANO',
      },
      {
        value: 'I417',
        label: 'SAN ZENONE o SAN ZENONE DEGLI EZZELINI',
      },
      {
        value: 'I221',
        label: 'SANTA LUCIA o SANTA LUCIA DI PIAVE',
      },
      {
        value: 'I435',
        label: 'SARMEDE',
      },
      {
        value: 'I578',
        label: 'SEGUSINO',
      },
      {
        value: 'I635',
        label: 'SERNAGLIA o SERNAGLIA DELLA BATTAGLIA',
      },
      {
        value: 'I664',
        label: 'SERRAVALLE',
      },
      {
        value: 'I890',
        label: 'SPERCENIGO',
      },
      {
        value: 'I927',
        label: 'SPRESIANO',
      },
      {
        value: 'L014',
        label: 'SUSEGANA',
      },
      {
        value: 'L058',
        label: 'TARZO',
      },
      {
        value: 'L402',
        label: 'TREVIGNANO',
      },
      {
        value: 'L407',
        label: 'TREVISO',
      },
      {
        value: 'L565',
        label: 'VALDOBBIADENE',
      },
      {
        value: 'L700',
        label: 'VAZZOLA',
      },
      {
        value: 'L706',
        label: 'VEDELAGO',
      },
      {
        value: 'L856',
        label: 'VIDOR',
      },
      {
        value: 'M048',
        label: 'VILLORBA',
      },
      {
        value: 'M089',
        label: 'VITTORIO o VITTORIO VENETO',
      },
      {
        value: 'M118',
        label: 'VOLPAGO o VOLPAGO DEL MONTELLO',
      },
      {
        value: 'M163',
        label: 'ZENSON DI PIAVE',
      },
      {
        value: 'M171',
        label: 'ZERO BRANCO',
      },
    ],
  },

  {
    value: 'UD',
    label: 'Udine',
    children: [
      {
        value: 'A103',
        label: 'AIELLO DEL FRIULI',
      },
      {
        value: 'A254',
        label: 'AMARO',
      },
      {
        value: 'A267',
        label: 'AMPEZZO',
      },
      {
        value: 'A346',
        label: 'AQUILEIA',
      },
      {
        value: 'A447',
        label: 'ARTA o ARTA TERME',
      },
      {
        value: 'A448',
        label: 'ARTEGNA',
      },
      {
        value: 'A491',
        label: 'ATTIMIS',
      },
      {
        value: 'A553',
        label: 'BAGNARIA o BAGNARIA ARSA',
      },
      {
        value: 'A700',
        label: 'BASILIANO o PASIAN SCHIAVONESCO',
      },
      {
        value: 'A810',
        label: 'BERTIOLO',
      },
      {
        value: 'A855',
        label: 'BICINICCO',
      },
      {
        value: 'A983',
        label: 'BORDANO',
      },
      {
        value: 'B259',
        label: 'BUJA o BUJA ( corretto BUIA)',
      },
      {
        value: 'B309',
        label: 'BUTTRIO',
      },
      {
        value: 'B483',
        label: 'CAMINO AL TAGLIAMENTO o CAMINO DI CODROIPO',
      },
      {
        value: 'B536',
        label: 'CAMPOFORMIDO',
      },
      {
        value: 'B545',
        label: 'CAMPOLONGO o CAMPOLONGO AL TORRE',
      },
      {
        value: 'M311',
        label: 'CAMPOLONGO TAPOGLIANO',
      },
      {
        value: 'B560',
        label: 'CAMPOROSSO o CAMPOROSSO IN VALCANALE',
      },
      {
        value: 'B788',
        label: 'CARLINO',
      },
      {
        value: 'B994',
        label: 'CASSACCO',
      },
      {
        value: 'C084',
        label: 'CASTEL DEL MONTE UDINESE',
      },
      {
        value: 'C327',
        label: 'CASTIONS DI STRADA',
      },
      {
        value: 'C389',
        label: 'CAVAZZO o CAVAZZO CARNICO',
      },
      {
        value: 'C494',
        label: 'CERCIVENTO',
      },
      {
        value: 'C556',
        label: 'CERVIGNANO o CERVIGNANO DEL FRIULI',
      },
      {
        value: 'C570',
        label: 'CESCLANS',
      },
      {
        value: 'C641',
        label: 'CHIOPRIS VISCONE o CHIOPRIS-VISCONE',
      },
      {
        value: 'C656',
        label: 'CHIUSA o CHIUSAFORTE',
      },
      {
        value: 'C731',
        label: 'CISERIIS',
      },
      {
        value: 'C758',
        label: 'CIVIDALE DEL FRIULI',
      },
      {
        value: 'C817',
        label: 'CODROIPO',
      },
      {
        value: 'C842',
        label: 'COLLALTO o COLLALTO DELLA SOIMA o SEGNACCO',
      },
      {
        value: 'C885',
        label: 'COLLOREDO DI MONTE ALBANO',
      },
      {
        value: 'C918',
        label: 'COMEGLIANS',
      },
      {
        value: 'D027',
        label: 'CORNO DI ROSAZZO',
      },
      {
        value: 'D085',
        label: 'COSEANO',
      },
      {
        value: 'D300',
        label: 'DIGNANO',
      },
      {
        value: 'D316',
        label: 'DOGNA',
      },
      {
        value: 'D366',
        label: 'DRENCHIA',
      },
      {
        value: 'D408',
        label: 'ENEMONZO',
      },
      {
        value: 'D455',
        label: 'FAEDIS',
      },
      {
        value: 'D461',
        label: 'FAGAGNA',
      },
      {
        value: 'D525',
        label: 'FELETTO o FELETTO UMBERTO',
      },
      {
        value: 'D627',
        label: 'FIUMICELLO',
      },
      {
        value: 'M400',
        label: 'FIUMICELLO VILLA VICENTINA',
      },
      {
        value: 'D630',
        label: "FLAIBANO o SANT'ODORICO",
      },
      {
        value: 'D700',
        label: 'FORGARIA o FORGARIA NEL FRIULI',
      },
      {
        value: 'D718',
        label: 'FORNI AVOLTRI',
      },
      {
        value: 'D719',
        label: 'FORNI DI SOPRA',
      },
      {
        value: 'D720',
        label: 'FORNI DI SOTTO',
      },
      {
        value: 'D831',
        label: 'FUSINE IN VALROMANA o ROCCALBA',
      },
      {
        value: 'D962',
        label: 'GEMONA o GEMONA DEL FRIULI',
      },
      {
        value: 'E083',
        label: 'GONARS',
      },
      {
        value: 'E179',
        label: 'GRIMACCO',
      },
      {
        value: 'E319',
        label: 'IOANNIS o IOANNIZ',
      },
      {
        value: 'E322',
        label: 'IPPLIS',
      },
      {
        value: 'E404',
        label: 'LAGLESIE SAN LEOPOLDO o SAN LEOPOLDO ALAGLESIE',
      },
      {
        value: 'E473',
        label: 'LATISANA',
      },
      {
        value: 'E476',
        label: 'LAUCO',
      },
      {
        value: 'E553',
        label: 'LESTIZZA',
      },
      {
        value: 'E584',
        label: 'LIGNANO SABBIADORO',
      },
      {
        value: 'E586',
        label: 'LIGOSULLO',
      },
      {
        value: 'E760',
        label: 'LUSEVERA',
      },
      {
        value: 'E820',
        label: 'MAGNANO o MAGNANO IN RIVIERA',
      },
      {
        value: 'E833',
        label: 'MAJANO',
      },
      {
        value: 'E846',
        label: 'MALBORGHETTO',
      },
      {
        value: 'E847',
        label: 'MALBORGHETTO VALBRUNA o MALBORGHETTO-VALBRUNA',
      },
      {
        value: 'E899',
        label: 'MANZANO',
      },
      {
        value: 'E910',
        label: 'MARANO o MARANO LACUNARE o MARANO LAGUNARE',
      },
      {
        value: 'E982',
        label: 'MARTIGNACCO',
      },
      {
        value: 'F144',
        label: 'MERETO DI TOMBA o MERETTO DI TOMBA',
      },
      {
        value: 'F228',
        label: 'MIONE',
      },
      {
        value: 'F266',
        label: 'MOGGIO UDINESE',
      },
      {
        value: 'F275',
        label: 'MOIMACCO',
      },
      {
        value: 'F574',
        label: 'MONTENARS',
      },
      {
        value: 'F756',
        label: 'MORTEGLIANO',
      },
      {
        value: 'F760',
        label: 'MORUZZO',
      },
      {
        value: 'F821',
        label: 'MUSCOLO-STRASSOLDO',
      },
      {
        value: 'F832',
        label: 'MUZZANA o MUZZANA DEL TURGNANO',
      },
      {
        value: 'F898',
        label: 'NIMIS',
      },
      {
        value: 'G163',
        label: 'OSOPPO',
      },
      {
        value: 'G198',
        label: 'OVARO',
      },
      {
        value: 'G238',
        label: 'PAGNACCO',
      },
      {
        value: 'G268',
        label: 'PALAZZOLO o PALAZZOLO DELLO STELLA',
      },
      {
        value: 'G284',
        label: 'PALMANOVA',
      },
      {
        value: 'G300',
        label: 'PALUZZA',
      },
      {
        value: 'G352',
        label: 'PASIAN DI PRATO',
      },
      {
        value: 'G356',
        label: 'PASSARIANO o RIVOLTO',
      },
      {
        value: 'G381',
        label: 'PAULARO',
      },
      {
        value: 'G389',
        label: 'PAVIA o PAVIA DI UDINE',
      },
      {
        value: 'G472',
        label: 'PERTEOLE',
      },
      {
        value: 'G736',
        label: 'PLATISCHIS o TAIPANA',
      },
      {
        value: 'G743',
        label: 'POCENIA',
      },
      {
        value: 'G831',
        label: 'PONTEBBA',
      },
      {
        value: 'G832',
        label: 'PONTEBBA NOVA o PONTEFELLA',
      },
      {
        value: 'G891',
        label: 'PORPETTO',
      },
      {
        value: 'G949',
        label: 'POVOLETTO',
      },
      {
        value: 'G966',
        label: 'POZZUOLO o POZZUOLO DEL FRIULI',
      },
      {
        value: 'G969',
        label: 'PRADAMANO',
      },
      {
        value: 'H002',
        label: 'PRATO o PRATO CARNICO',
      },
      {
        value: 'H014',
        label: 'PRECENICCO',
      },
      {
        value: 'H029',
        label: 'PREMARIACCO',
      },
      {
        value: 'H038',
        label: 'PREONE',
      },
      {
        value: 'H040',
        label: 'PREPOTTO',
      },
      {
        value: 'H089',
        label: 'PULFERO',
      },
      {
        value: 'H149',
        label: 'RACCOLANA',
      },
      {
        value: 'H161',
        label: 'RAGOGNA',
      },
      {
        value: 'H196',
        label: 'RAVASCLETTO',
      },
      {
        value: 'H200',
        label: 'RAVEO',
      },
      {
        value: 'H206',
        label: 'REANA o REANA DEL ROIALE o REANA DEL ROJALE',
      },
      {
        value: 'H229',
        label: 'REMANZACCO',
      },
      {
        value: 'H242',
        label: 'RESIA',
      },
      {
        value: 'H244',
        label: 'RESIUTTA',
      },
      {
        value: 'H289',
        label: 'RIGOLATO',
      },
      {
        value: 'H347',
        label: "RIVE D'ARCANO",
      },
      {
        value: 'H352',
        label: 'RIVIGNANO',
      },
      {
        value: 'M317',
        label: 'RIVIGNANO TEOR',
      },
      {
        value: 'H471',
        label: 'RODDA',
      },
      {
        value: 'H533',
        label: 'RONCHIS',
      },
      {
        value: 'H629',
        label: 'RUDA',
      },
      {
        value: 'H816',
        label: 'SAN DANIELE o SAN DANIELE DEL FRIULI',
      },
      {
        value: 'H895',
        label: 'SAN GIORGIO DI NOGARO',
      },
      {
        value: 'H906',
        label: 'SAN GIOVANNI AL NATISONE o SAN GIOVANNI DI MANZANO',
      },
      {
        value: 'H951',
        label: 'SAN LEONARDO',
      },
      {
        value: 'I092',
        label: 'SAN PIETRO AL NATISONE o SAN PIETRO DEGLI SCHIAVI',
      },
      {
        value: 'I404',
        label: 'SAN VITO AL TORRE',
      },
      {
        value: 'I405',
        label: 'SAN VITO DI FAGAGNA',
      },
      {
        value: 'I248',
        label: 'SANTA MARIA LA LONGA',
      },
      {
        value: 'I464',
        label: 'SAURIS',
      },
      {
        value: 'I478',
        label: 'SAVOGNA',
      },
      {
        value: 'I542',
        label: 'SCODAVACCA',
      },
      {
        value: 'I562',
        label: 'SEDEGLIANO',
      },
      {
        value: 'I777',
        label: 'SOCCHIEVE',
      },
      {
        value: 'I974',
        label: 'STREGNA',
      },
      {
        value: 'L018',
        label: 'SUTRIO',
      },
      {
        value: 'L039',
        label: 'TALMASSONS',
      },
      {
        value: 'L044',
        label: 'TAPOGLIANO',
      },
      {
        value: 'L050',
        label: 'TARCENTO',
      },
      {
        value: 'L052',
        label: 'TARCETTA',
      },
      {
        value: 'L057',
        label: 'TARVISIO',
      },
      {
        value: 'L065',
        label: 'TAVAGNACCO',
      },
      {
        value: 'L101',
        label: 'TEOR',
      },
      {
        value: 'L144',
        label: "TERZO o TERZO D'AQUILEIA o TERZO DI AQUILEIA",
      },
      {
        value: 'L195',
        label: 'TOLMEZZO',
      },
      {
        value: 'L246',
        label: 'TORREANO',
      },
      {
        value: 'L309',
        label: 'TORVISCOSA',
      },
      {
        value: 'L335',
        label: 'TRASAGHIS',
      },
      {
        value: 'L381',
        label: 'TREPPO o TREPPO CARNICO',
      },
      {
        value: 'L382',
        label: 'TREPPO GRANDE',
      },
      {
        value: 'M399',
        label: 'TREPPO LIGOSULLO',
      },
      {
        value: 'L421',
        label: 'TRICESIMO',
      },
      {
        value: 'L438',
        label: 'TRIVIGNANO UDINESE',
      },
      {
        value: 'L481',
        label: 'UCOVIZZA o UGOVIZZA VALBRUNA',
      },
      {
        value: 'L483',
        label: 'UDINE',
      },
      {
        value: 'L610',
        label: 'VALLENONCELLO',
      },
      {
        value: 'L686',
        label: 'VARMO',
      },
      {
        value: 'L743',
        label: 'VENZONE',
      },
      {
        value: 'L801',
        label: 'VERZEGNIS',
      },
      {
        value: 'L909',
        label: 'VILLA o VILLA SANTINA',
      },
      {
        value: 'M034',
        label: 'VILLA VICENTINA',
      },
      {
        value: 'M073',
        label: 'VISCO',
      },
      {
        value: 'M200',
        label: 'ZUGLIO',
      },
    ],
  },

  {
    value: 'VA',
    label: 'Varese',
    children: [
      {
        value: 'A011',
        label: 'ABBIATEGUAZZONE',
      },
      {
        value: 'A085',
        label: 'AGRA',
      },
      {
        value: 'A167',
        label: 'ALBIZZATE',
      },
      {
        value: 'A290',
        label: 'ANGERA',
      },
      {
        value: 'A356',
        label: 'ARBIZZO',
      },
      {
        value: 'A371',
        label: 'ARCISATE',
      },
      {
        value: 'A378',
        label: 'ARCUMEGGIA',
      },
      {
        value: 'A381',
        label: 'ARDENA',
      },
      {
        value: 'A416',
        label: 'ARMIO',
      },
      {
        value: 'A428',
        label: 'AROLO',
      },
      {
        value: 'A531',
        label: 'AZZATE',
      },
      {
        value: 'A532',
        label: 'AZZIO',
      },
      {
        value: 'A619',
        label: 'BARASSO',
      },
      {
        value: 'A645',
        label: 'BARDELLO',
      },
      {
        value: 'A682',
        label: 'BARZA',
      },
      {
        value: 'A688',
        label: 'BARZOLA',
      },
      {
        value: 'A728',
        label: 'BEDERO VALCUVIA',
      },
      {
        value: 'A819',
        label: 'BESANO',
      },
      {
        value: 'A825',
        label: 'BESNATE',
      },
      {
        value: 'A826',
        label: 'BESOZZO',
      },
      {
        value: 'A845',
        label: 'BIANDRONNO',
      },
      {
        value: 'A858',
        label: 'BIEGNO',
      },
      {
        value: 'A891',
        label: 'BISUSCHIO',
      },
      {
        value: 'A899',
        label: 'BIZZOZERO',
      },
      {
        value: 'A908',
        label: 'BOBBIATE',
      },
      {
        value: 'A917',
        label: 'BODIO',
      },
      {
        value: 'A918',
        label: 'BODIO LOMNAGO o LOMNAGO',
      },
      {
        value: 'A928',
        label: 'BOGNO',
      },
      {
        value: 'B126',
        label: 'BREBBIA',
      },
      {
        value: 'B131',
        label: 'BREGANO',
      },
      {
        value: 'B150',
        label: 'BRENTA',
      },
      {
        value: 'B166',
        label: 'BREZZO DI BEDERO',
      },
      {
        value: 'B182',
        label: 'BRINZIO',
      },
      {
        value: 'B190',
        label: 'BRISSAGO',
      },
      {
        value: 'B191',
        label: 'BRISSAGO-VALTRAVAGLIA',
      },
      {
        value: 'B219',
        label: 'BRUNELLO',
      },
      {
        value: 'B228',
        label: 'BRUSIMPIANO',
      },
      {
        value: 'B258',
        label: 'BUGUGGIATE',
      },
      {
        value: 'B300',
        label: 'BUSTO ARSIZIO',
      },
      {
        value: 'B312',
        label: 'CABIAGLIO o CASTELLO CABIAGLIO',
      },
      {
        value: 'B325',
        label: 'CADEGLIANO',
      },
      {
        value: 'B326',
        label: 'CADEGLIANO-VICONAGO',
      },
      {
        value: 'B347',
        label: 'CADREZZATE',
      },
      {
        value: 'M425',
        label: 'CADREZZATE CON OSMATE',
      },
      {
        value: 'B368',
        label: 'CAIRATE',
      },
      {
        value: 'B634',
        label: 'CANTELLO o CAZZONE',
      },
      {
        value: 'B668',
        label: 'CAPOLAGO',
      },
      {
        value: 'B714',
        label: 'CAPRONNO',
      },
      {
        value: 'B732',
        label: 'CARAVATE',
      },
      {
        value: 'B753',
        label: 'CARDANA',
      },
      {
        value: 'B754',
        label: 'CARDANO o CARDANO AL CAMPO',
      },
      {
        value: 'B796',
        label: 'CARNAGO',
      },
      {
        value: 'B806',
        label: 'CARONNO CORBELLARO',
      },
      {
        value: 'B807',
        label: 'CARONNO GHIRINGHELLO o CARONNO VARESINO',
      },
      {
        value: 'B921',
        label: 'CASALZUIGNO',
      },
      {
        value: 'B949',
        label: 'CASCIAGO',
      },
      {
        value: 'C004',
        label: 'CASSANO MAGNAGO',
      },
      {
        value: 'C139',
        label: 'CASTELLANZA',
      },
      {
        value: 'C273',
        label: 'CASTELSEPRIO',
      },
      {
        value: 'C343',
        label: 'CASTRONNO',
      },
      {
        value: 'C402',
        label: 'CAVONA',
      },
      {
        value: 'C431',
        label: 'CELINA',
      },
      {
        value: 'C732',
        label: 'CISLAGO',
      },
      {
        value: 'C751',
        label: 'CITTIGLIO',
      },
      {
        value: 'C796',
        label: 'CLIVIO',
      },
      {
        value: 'C809',
        label: 'COCQUIO',
      },
      {
        value: 'C810',
        label: 'COCQUIO-TREVISAGO',
      },
      {
        value: 'C911',
        label: 'COMABBIO',
      },
      {
        value: 'C922',
        label: 'COMERIO',
      },
      {
        value: 'D144',
        label: 'CREMENAGA',
      },
      {
        value: 'D152',
        label: 'CRENNA',
      },
      {
        value: 'D185',
        label: 'CROSIO DELLA VALLE',
      },
      {
        value: 'D192',
        label: 'CUASSO AL MONTE',
      },
      {
        value: 'D199',
        label: 'CUGLIATE o CUGLIATE-FABIASCO',
      },
      {
        value: 'D204',
        label: 'CUNARDO',
      },
      {
        value: 'D217',
        label: 'CURIGLIA o CURIGLIA CON MONTEVIASCO',
      },
      {
        value: 'D238',
        label: 'CUVEGLIO o CUVEGLIO IN VALLE',
      },
      {
        value: 'D239',
        label: 'CUVIO',
      },
      {
        value: 'D256',
        label: 'DAVERIO',
      },
      {
        value: 'D384',
        label: 'DUMENZA',
      },
      {
        value: 'D385',
        label: 'DUNO',
      },
      {
        value: 'D446',
        label: 'FABIASCO',
      },
      {
        value: 'D543',
        label: 'FERNO',
      },
      {
        value: 'D869',
        label: 'GALLARATE',
      },
      {
        value: 'D908',
        label: 'GARABIOLO',
      },
      {
        value: 'D946',
        label: 'GAVIRATE',
      },
      {
        value: 'D950',
        label: 'GAZZADA',
      },
      {
        value: 'D951',
        label: 'GAZZADA SCHIANNO',
      },
      {
        value: 'D963',
        label: 'GEMONIO',
      },
      {
        value: 'D981',
        label: 'GERENZANO',
      },
      {
        value: 'D987',
        label: 'GERMIGNAGA',
      },
      {
        value: 'E079',
        label: 'GOLASECCA',
      },
      {
        value: 'E101',
        label: 'GORLA MAGGIORE',
      },
      {
        value: 'E102',
        label: 'GORLA MINORE',
      },
      {
        value: 'E104',
        label: 'GORNATE INFERIORE o GORNATE OLONA o GORNATE-OLONA',
      },
      {
        value: 'E105',
        label: 'GORNATE SUPERIORE',
      },
      {
        value: 'E144',
        label: 'GRANTOLA',
      },
      {
        value: 'E268',
        label: 'GURONE',
      },
      {
        value: 'E292',
        label: 'INARZO',
      },
      {
        value: 'E367',
        label: 'ISPRA',
      },
      {
        value: 'E386',
        label: 'JERAGO CON ORAGO',
      },
      {
        value: 'E494',
        label: 'LAVENA o LAVENA PONTE TRESA',
      },
      {
        value: 'E495',
        label: 'LAVENO',
      },
      {
        value: 'E496',
        label: 'LAVENO-MOMBELLO',
      },
      {
        value: 'E510',
        label: 'LEGGIUNO',
      },
      {
        value: 'E511',
        label: 'LEGGIUNO-SANGIANO',
      },
      {
        value: 'E609',
        label: 'LISANZA',
      },
      {
        value: 'E616',
        label: 'LISSAGO',
      },
      {
        value: 'E665',
        label: 'LONATE CEPPINO',
      },
      {
        value: 'E666',
        label: 'LONATE POZZOLO',
      },
      {
        value: 'E707',
        label: 'LOZZA',
      },
      {
        value: 'E710',
        label: 'LOZZO',
      },
      {
        value: 'E769',
        label: 'LUVINATE',
      },
      {
        value: 'E775',
        label: 'MACCAGNO o MACCAGNO SUPERIORE',
      },
      {
        value: 'M339',
        label: 'MACCAGNO CON PINO E VEDDASCA',
      },
      {
        value: 'E776',
        label: 'MACCAGNO INFERIORE',
      },
      {
        value: 'E856',
        label: 'MALGESSO',
      },
      {
        value: 'E863',
        label: 'MALNATE',
      },
      {
        value: 'E929',
        label: 'MARCHIROLO',
      },
      {
        value: 'E965',
        label: 'MARNATE',
      },
      {
        value: 'F002',
        label: 'MARZIO',
      },
      {
        value: 'F018',
        label: 'MASNAGO',
      },
      {
        value: 'F134',
        label: 'MERCALLO',
      },
      {
        value: 'F154',
        label: 'MESENZANA',
      },
      {
        value: 'F169',
        label: 'MEZZANA o MEZZANA SUPERIORE',
      },
      {
        value: 'F334',
        label: 'MONATE',
      },
      {
        value: 'F525',
        label: 'MONTEGRINO',
      },
      {
        value: 'F526',
        label: 'MONTEGRINO VALTRAVAGLIA',
      },
      {
        value: 'F663',
        label: 'MONTEVIASCO',
      },
      {
        value: 'F684',
        label: 'MONTONATE',
      },
      {
        value: 'F703',
        label: 'MONVALLE',
      },
      {
        value: 'F711',
        label: 'MORAZZONE',
      },
      {
        value: 'F736',
        label: 'MORNAGO',
      },
      {
        value: 'F742',
        label: 'MOROSOLO',
      },
      {
        value: 'F794',
        label: 'MUCENO',
      },
      {
        value: 'F819',
        label: 'MUSADINO',
      },
      {
        value: 'F825',
        label: 'MUSIGNANO',
      },
      {
        value: 'F903',
        label: 'NIZZOLINA',
      },
      {
        value: 'G008',
        label: 'OGGIONA o OGGIONA CON SANTO STEFANO',
      },
      {
        value: 'G028',
        label: 'OLGIATE OLONA',
      },
      {
        value: 'G029',
        label: 'OLGINASIO',
      },
      {
        value: 'G057',
        label: 'OLTRONA o OLTRONA AL LAGO',
      },
      {
        value: 'G103',
        label: 'ORIGGIO',
      },
      {
        value: 'G105',
        label: 'ORINO',
      },
      {
        value: 'G106',
        label: 'ORINO AZZIO',
      },
      {
        value: 'H174',
        label: 'RANCO',
      },
      {
        value: 'H597',
        label: 'ROVATE',
      },
      {
        value: 'H640',
        label: 'RUNO',
      },
      {
        value: 'H656',
        label: 'SACCONAGO',
      },
      {
        value: 'H723',
        label: 'SALTRIO',
      },
      {
        value: 'H736',
        label: 'SAMARATE',
      },
      {
        value: 'H872',
        label: 'SANGIANO',
      },
      {
        value: 'I239',
        label: 'SANTA MARIA DEL MONTE',
      },
      {
        value: 'I441',
        label: 'SARONNO',
      },
      {
        value: 'I525',
        label: 'SCHIANNO',
      },
      {
        value: 'I688',
        label: 'SESTO CALENDE',
      },
      {
        value: 'I794',
        label: 'SOLBIATE OLONA',
      },
      {
        value: 'L003',
        label: 'SUMIRAGO',
      },
      {
        value: 'L032',
        label: 'TAINO',
      },
      {
        value: 'L115',
        label: 'TERNATE',
      },
      {
        value: 'L208',
        label: 'TORBA',
      },
      {
        value: 'L319',
        label: 'TRADATE',
      },
      {
        value: 'L341',
        label: 'TRAVEDONA',
      },
      {
        value: 'L342',
        label: 'TRAVEDONA-MONATE',
      },
      {
        value: 'L405',
        label: 'TREVISAGO',
      },
      {
        value: 'L480',
        label: 'UBOLDO',
      },
      {
        value: 'L637',
        label: 'VAL MARCHIROLO',
      },
      {
        value: 'L577',
        label: 'VALGANNA',
      },
      {
        value: 'L674',
        label: 'VARARO',
      },
      {
        value: 'L682',
        label: 'VARESE',
      },
      {
        value: 'L701',
        label: 'VECCANA',
      },
      {
        value: 'L705',
        label: 'VEDDASCA',
      },
      {
        value: 'L714',
        label: 'VELATE',
      },
      {
        value: 'L732',
        label: 'VENEGONO',
      },
      {
        value: 'L733',
        label: 'VENEGONO INFERIORE',
      },
      {
        value: 'L734',
        label: 'VENEGONO SUPERIORE',
      },
      {
        value: 'L765',
        label: 'VERGIATE',
      },
      {
        value: 'L767',
        label: 'VERGOBBIO',
      },
      {
        value: 'L849',
        label: 'VICONAGO',
      },
      {
        value: 'L876',
        label: "VIGGIU' o VIGGIU' ED UNITI",
      },
      {
        value: 'M114',
        label: 'VOLDOMINO',
      },
      {
        value: 'M128',
        label: 'VOLTORRE',
      },
    ],
  },

  {
    value: 'VB',
    label: 'Verbano-Cusio-Ossola',
    children: [
      {
        value: 'A317',
        label: 'ANTRONA SCHIERANCO',
      },
      {
        value: 'A427',
        label: 'AROLA',
      },
      {
        value: 'A497',
        label: 'AURANO',
      },
      {
        value: 'A534',
        label: 'BACENO',
      },
      {
        value: 'A725',
        label: 'BAVENO',
      },
      {
        value: 'A733',
        label: 'BEE',
      },
      {
        value: 'A742',
        label: 'BELGIRATE',
      },
      {
        value: 'A834',
        label: 'BEURA-CARDEZZA',
      },
      {
        value: 'A925',
        label: 'BOGNANCO',
      },
      {
        value: 'M370',
        label: 'BORGOMEZZAVALLE',
      },
      {
        value: 'B207',
        label: 'BROVELLO-CARPUGNINO',
      },
      {
        value: 'B463',
        label: 'CAMBIASCA',
      },
      {
        value: 'B615',
        label: 'CANNOBIO',
      },
      {
        value: 'B694',
        label: 'CAPREZZO',
      },
      {
        value: 'C367',
        label: 'CAVAGLIO-SPOCCIA',
      },
      {
        value: 'C478',
        label: 'CEPPO MORELLI',
      },
      {
        value: 'C567',
        label: 'CESARA',
      },
      {
        value: 'D099',
        label: 'COSSOGNO',
      },
      {
        value: 'D134',
        label: 'CRAVEGGIA',
      },
      {
        value: 'D225',
        label: 'CURSOLO-ORASSO',
      },
      {
        value: 'D332',
        label: 'DOMODOSSOLA',
      },
      {
        value: 'D374',
        label: 'DRUOGNO',
      },
      {
        value: 'D481',
        label: 'FALMENTA',
      },
      {
        value: 'D706',
        label: 'FORMAZZA',
      },
      {
        value: 'D984',
        label: 'GERMAGNO',
      },
      {
        value: 'E028',
        label: 'GIGNESE',
      },
      {
        value: 'E153',
        label: 'GRAVELLONA TOCE',
      },
      {
        value: 'E269',
        label: 'GURRO',
      },
      {
        value: 'E304',
        label: 'INTRAGNA',
      },
      {
        value: 'E685',
        label: 'LOREGLIA',
      },
      {
        value: 'E790',
        label: 'MACUGNAGA',
      },
      {
        value: 'E795',
        label: 'MADONNA DEL SASSO',
      },
      {
        value: 'E853',
        label: 'MALESCO',
      },
      {
        value: 'F010',
        label: 'MASERA',
      },
      {
        value: 'F048',
        label: 'MASSIOLA',
      },
      {
        value: 'F146',
        label: 'MERGOZZO',
      },
      {
        value: 'F192',
        label: 'MIAZZINA',
      },
      {
        value: 'F483',
        label: 'MONTECRESTESE',
      },
      {
        value: 'F639',
        label: 'MONTESCHENO',
      },
      {
        value: 'F932',
        label: 'NONIO',
      },
      {
        value: 'G007',
        label: 'OGGEBBIO',
      },
      {
        value: 'G062',
        label: 'OMEGNA',
      },
      {
        value: 'G117',
        label: 'ORNAVASSO',
      },
      {
        value: 'G280',
        label: 'PALLANZENO',
      },
      {
        value: 'G600',
        label: 'PIEDIMULERA',
      },
      {
        value: 'G658',
        label: 'PIEVE VERGONTE',
      },
      {
        value: 'H030',
        label: 'PREMENO',
      },
      {
        value: 'H033',
        label: 'PREMIA',
      },
      {
        value: 'H106',
        label: 'QUARNA SOPRA',
      },
      {
        value: 'H107',
        label: 'QUARNA SOTTO',
      },
      {
        value: 'H203',
        label: 'RE',
      },
      {
        value: 'H777',
        label: 'SAN BERNARDINO VERBANO',
      },
      {
        value: 'I249',
        label: 'SANTA MARIA MAGGIORE',
      },
      {
        value: 'I619',
        label: 'SEPPIANA',
      },
      {
        value: 'L187',
        label: 'TOCENO',
      },
      {
        value: 'L336',
        label: 'TRASQUERA',
      },
      {
        value: 'L450',
        label: 'TRONTANO',
      },
      {
        value: 'M404',
        label: 'VALLE CANNOBINA',
      },
      {
        value: 'L651',
        label: 'VALSTRONA',
      },
      {
        value: 'L691',
        label: 'VARZO',
      },
      {
        value: 'L746',
        label: 'VERBANIA',
      },
      {
        value: 'L864',
        label: 'VIGANELLA',
      },
      {
        value: 'L889',
        label: 'VIGNONE',
      },
      {
        value: 'M042',
        label: 'VILLETTE',
      },
      {
        value: 'M111',
        label: 'VOGOGNA',
      },
    ],
  },

  {
    value: 'VC',
    label: 'Vercelli',
    children: [
      {
        value: 'A079',
        label: 'AGNONA',
      },
      {
        value: 'A119',
        label: 'ALAGNA o ALAGNA VALSESIA',
      },
      {
        value: 'M389',
        label: 'ALTO SERMENZA',
      },
      {
        value: 'A279',
        label: 'ANDORNO CACCIORNA',
      },
      {
        value: 'A353',
        label: 'ARANCO',
      },
      {
        value: 'A358',
        label: 'ARBORIO',
      },
      {
        value: 'A466',
        label: 'ASIGLIANO o ASIGLIANO VERCELLESE',
      },
      {
        value: 'A600',
        label: 'BALMUCCIA',
      },
      {
        value: 'A601',
        label: 'BALOCCO',
      },
      {
        value: 'A847',
        label: "BIANZE'",
      },
      {
        value: 'A914',
        label: 'BOCCIOLETO',
      },
      {
        value: 'B009',
        label: "BORGO D'ALE",
      },
      {
        value: 'B046',
        label: 'BORGO VERCELLI',
      },
      {
        value: 'B041',
        label: 'BORGOSESIA',
      },
      {
        value: 'B052',
        label: 'BORNATE',
      },
      {
        value: 'B136',
        label: 'BREIA',
      },
      {
        value: 'B280',
        label: 'BURONZO',
      },
      {
        value: 'B459',
        label: 'CAMASCO',
      },
      {
        value: 'B505',
        label: 'CAMPERTOGNO',
      },
      {
        value: 'B752',
        label: 'CARCOFORO',
      },
      {
        value: 'B767',
        label: 'CARESANA',
      },
      {
        value: 'B768',
        label: 'CARESANABLOT',
      },
      {
        value: 'B782',
        label: 'CARISIO',
      },
      {
        value: 'B951',
        label: 'CASCINE DI STRA',
      },
      {
        value: 'B952',
        label: 'CASCINE SAN GIACOMO o SAN GIACOMO VERCELLESE',
      },
      {
        value: 'C151',
        label: 'CASTELLENGO',
      },
      {
        value: 'C168',
        label: 'CASTELLETTO VILLA',
      },
      {
        value: 'C450',
        label: 'CELLIO',
      },
      {
        value: 'M398',
        label: 'CELLIO CON BREIA',
      },
      {
        value: 'C546',
        label: 'CERVAROLO',
      },
      {
        value: 'C548',
        label: 'CERVATTO',
      },
      {
        value: 'C622',
        label: 'CHIAVAZZA',
      },
      {
        value: 'C680',
        label: 'CIGLIANO',
      },
      {
        value: 'C757',
        label: 'CIVIASCO',
      },
      {
        value: 'C884',
        label: 'COLLOBIANO',
      },
      {
        value: 'D097',
        label: 'COSSILA',
      },
      {
        value: 'D113',
        label: 'COSTANZANA',
      },
      {
        value: 'D132',
        label: 'CRAVAGLIANA',
      },
      {
        value: 'D154',
        label: 'CRESCENTINO',
      },
      {
        value: 'D169',
        label: 'CREVOLA o CREVOLA SESIA',
      },
      {
        value: 'D174',
        label: 'CROCE DI MOSSO',
      },
      {
        value: 'D187',
        label: 'CROVA',
      },
      {
        value: 'D281',
        label: 'DESANA',
      },
      {
        value: 'D313',
        label: 'DOCCIO',
      },
      {
        value: 'D556',
        label: 'FERRUTA',
      },
      {
        value: 'D632',
        label: 'FLECCHIA',
      },
      {
        value: 'D641',
        label: 'FOBELLO',
      },
      {
        value: 'D712',
        label: 'FORMIGLIANA',
      },
      {
        value: 'D938',
        label: 'GATTINARA',
      },
      {
        value: 'E007',
        label: 'GHISLARENGO',
      },
      {
        value: 'E163',
        label: 'GREGGIO',
      },
      {
        value: 'E237',
        label: 'GUARDABOSONE',
      },
      {
        value: 'E361',
        label: 'ISOLELLA',
      },
      {
        value: 'E433',
        label: 'LAMPORO',
      },
      {
        value: 'E528',
        label: 'LENTA',
      },
      {
        value: 'E583',
        label: 'LIGNANA',
      },
      {
        value: 'E636',
        label: 'LOCARNO',
      },
      {
        value: 'E711',
        label: 'LOZZOLO',
      },
      {
        value: 'F297',
        label: 'MOLLIA',
      },
      {
        value: 'F342',
        label: 'MONCRIVELLO',
      },
      {
        value: 'F714',
        label: 'MORCA',
      },
      {
        value: 'F741',
        label: 'MORONDO',
      },
      {
        value: 'F774',
        label: "MOTTA DE' CONTI",
      },
      {
        value: 'G016',
        label: 'OLCENENGO',
      },
      {
        value: 'G018',
        label: 'OLDENICO',
      },
      {
        value: 'G343',
        label: 'PARONE',
      },
      {
        value: 'G471',
        label: 'PERTENGO',
      },
      {
        value: 'G528',
        label: 'PEZZANA',
      },
      {
        value: 'G548',
        label: 'PIANCERI',
      },
      {
        value: 'G666',
        label: 'PILA',
      },
      {
        value: 'G685',
        label: 'PIODE',
      },
      {
        value: 'G940',
        label: 'POSTUA',
      },
      {
        value: 'G985',
        label: 'PRAROLO',
      },
      {
        value: 'H108',
        label: 'QUARONA',
      },
      {
        value: 'H132',
        label: 'QUINTO o QUINTO VERCELLESE',
      },
      {
        value: 'H188',
        label: 'RASSA',
      },
      {
        value: 'H291',
        label: 'RIMA SAN GIUSEPPE',
      },
      {
        value: 'H292',
        label: 'RIMASCO',
      },
      {
        value: 'H293',
        label: 'RIMELLA',
      },
      {
        value: 'H346',
        label: 'RIVE',
      },
      {
        value: 'H364',
        label: 'ROASENDA o ROVASENDA',
      },
      {
        value: 'H365',
        label: 'ROASIO',
      },
      {
        value: 'H549',
        label: 'RONSECCO',
      },
      {
        value: 'H577',
        label: 'ROSSA',
      },
      {
        value: 'H648',
        label: 'SABBIA',
      },
      {
        value: 'H690',
        label: 'SALASCO',
      },
      {
        value: 'H725',
        label: 'SALUGGIA',
      },
      {
        value: 'I337',
        label: "SANTHIA'",
      },
      {
        value: 'I544',
        label: 'SCOPA',
      },
      {
        value: 'I545',
        label: 'SCOPELLO',
      },
      {
        value: 'I984',
        label: 'STROPPIANA',
      },
      {
        value: 'L420',
        label: 'TRICERRO',
      },
      {
        value: 'L429',
        label: 'TRINO o TRINO VERCELLESE',
      },
      {
        value: 'L566',
        label: 'VALDUGGIA',
      },
      {
        value: 'L622',
        label: 'VALLE SUPERIORE MOSSO',
      },
      {
        value: 'L635',
        label: 'VALMAGGIA',
      },
      {
        value: 'L669',
        label: 'VARALLO',
      },
      {
        value: 'L750',
        label: 'VERCELLI',
      },
      {
        value: 'M003',
        label: 'VILLARBOIT',
      },
      {
        value: 'M028',
        label: 'VILLATA',
      },
      {
        value: 'M061',
        label: 'VINTEBBIO',
      },
      {
        value: 'M106',
        label: 'VOCCA',
      },
    ],
  },

  {
    value: 'VE',
    label: 'Venezia',
    children: [
      {
        value: 'A302',
        label: 'ANNONE o ANNONE VENETO',
      },
      {
        value: 'B273',
        label: 'BURANO',
      },
      {
        value: 'B493',
        label: 'CAMPAGNA o CAMPAGNA LUPIA',
      },
      {
        value: 'B546',
        label: 'CAMPOLONGO o CAMPOLONGO MAGGIORE',
      },
      {
        value: 'B554',
        label: 'CAMPONOGARA',
      },
      {
        value: 'B642',
        label: 'CAORLE',
      },
      {
        value: 'M308',
        label: 'CAVALLINO-TREPORTI',
      },
      {
        value: 'C383',
        label: 'CAVARZERE',
      },
      {
        value: 'C388',
        label: 'CAVAZUCCHERINA o JESOLO',
      },
      {
        value: 'C422',
        label: 'CEGGIA',
      },
      {
        value: 'C638',
        label: 'CHIOGGIA',
      },
      {
        value: 'C647',
        label: 'CHIRIGNAGO',
      },
      {
        value: 'C714',
        label: 'CINTO o CINTO CAOMAGGIORE',
      },
      {
        value: 'C938',
        label: 'CONA',
      },
      {
        value: 'C950',
        label: 'CONCORDIA o CONCORDIA SAGITARIA o CONCORDIA SAGITTARIA',
      },
      {
        value: 'D325',
        label: 'DOLO',
      },
      {
        value: 'D415',
        label: 'ERACLEA o GRISOLERA',
      },
      {
        value: 'D515',
        label: 'FAVARO o FAVARO VENETO',
      },
      {
        value: 'D578',
        label: "FIESSO o FIESSO D'ARTICO",
      },
      {
        value: 'D740',
        label: 'FOSSALTA o FOSSALTA DI PIAVE',
      },
      {
        value: 'D741',
        label: 'FOSSALTA o FOSSALTA DI PORTOGRUARO',
      },
      {
        value: 'D748',
        label: "FOSSO'",
      },
      {
        value: 'D893',
        label: 'GAMBARARE',
      },
      {
        value: 'E215',
        label: 'GRUARO',
      },
      {
        value: 'E845',
        label: 'MALAMOCCO',
      },
      {
        value: 'E936',
        label: 'MARCON',
      },
      {
        value: 'E980',
        label: 'MARTELLAGO',
      },
      {
        value: 'F130',
        label: 'MEOLO',
      },
      {
        value: 'F159',
        label: 'MESTRE',
      },
      {
        value: 'F229',
        label: 'MIRA o MIRA ED ORIAGO',
      },
      {
        value: 'F241',
        label: 'MIRANO',
      },
      {
        value: 'F807',
        label: 'MURANO',
      },
      {
        value: 'F826',
        label: 'MUSILE o MUSILE DI PIAVE',
      },
      {
        value: 'F904',
        label: 'NOALE',
      },
      {
        value: 'F963',
        label: 'NOVENTA o NOVENTA DI PIAVE',
      },
      {
        value: 'G425',
        label: 'PELLESTRINA',
      },
      {
        value: 'G565',
        label: 'PIANIGA',
      },
      {
        value: 'G914',
        label: 'PORTOGRUARO',
      },
      {
        value: 'G981',
        label: 'PRAMAGGIORE',
      },
      {
        value: 'H117',
        label: "QUARTO D'ALTINO o SAN MICHELE DEL QUARTO",
      },
      {
        value: 'H735',
        label: 'SALZANO',
      },
      {
        value: 'H823',
        label: "SAN DONA' DI PIAVE",
      },
      {
        value: 'I040',
        label: 'SAN MICHELE o SAN MICHELE AL TAGLIAMENTO',
      },
      {
        value: 'I373',
        label: 'SAN STINO DI LIVENZA o SANTO STINO DI LIVENZA',
      },
      {
        value: 'I242',
        label: 'SANTA MARIA DI SALA',
      },
      {
        value: 'I551',
        label: "SCORZE'",
      },
      {
        value: 'I908',
        label: 'SPINEA',
      },
      {
        value: 'I965',
        label: 'STRA',
      },
      {
        value: 'L085',
        label: 'TEGLIO o TEGLIO VENETO',
      },
      {
        value: 'L267',
        label: 'TORRE DI MOSTO',
      },
      {
        value: 'L736',
        label: 'VENEZIA',
      },
      {
        value: 'L899',
        label: 'VIGONOVO',
      },
      {
        value: 'M154',
        label: 'ZELARINO',
      },
    ],
  },

  {
    value: 'VI',
    label: 'Vicenza',
    children: [
      {
        value: 'A093',
        label: 'AGUGLIARO',
      },
      {
        value: 'A154',
        label: 'ALBETTONE',
      },
      {
        value: 'A220',
        label: 'ALONTE',
      },
      {
        value: 'A231',
        label: 'ALTAVILLA o ALTAVILLA VICENTINA',
      },
      {
        value: 'A236',
        label: 'ALTISSIMO',
      },
      {
        value: 'A377',
        label: 'ARCUGNANO',
      },
      {
        value: 'A444',
        label: 'ARSIERO',
      },
      {
        value: 'A459',
        label: 'ARZIGNANO',
      },
      {
        value: 'A465',
        label: 'ASIAGO',
      },
      {
        value: 'A467',
        label: 'ASIGLIANO o ASIGLIANO VENETO',
      },
      {
        value: 'A627',
        label: 'BARBARANO o BARBARANO VICENTINO',
      },
      {
        value: 'M401',
        label: 'BARBARANO MOSSANO',
      },
      {
        value: 'A703',
        label: 'BASSANO o BASSANO DEL GRAPPA',
      },
      {
        value: 'A954',
        label: 'BOLZANO o BOLZANO VICENTINO',
      },
      {
        value: 'B132',
        label: 'BREGANZE',
      },
      {
        value: 'B143',
        label: 'BRENDOLA',
      },
      {
        value: 'B161',
        label: 'BRESSANVIDO',
      },
      {
        value: 'B196',
        label: 'BROGLIANO',
      },
      {
        value: 'B402',
        label: 'CALDOGNO o CALDIERO',
      },
      {
        value: 'B433',
        label: 'CALTRANO',
      },
      {
        value: 'B441',
        label: 'CALVENE',
      },
      {
        value: 'B485',
        label: 'CAMISANO o CAMISANO VICENTINO',
      },
      {
        value: 'B511',
        label: 'CAMPIGLIA o CAMPIGLIA DEI BERICI',
      },
      {
        value: 'B547',
        label: 'CAMPOLONGO o CAMPOLONGO SUL BRENTA',
      },
      {
        value: 'B835',
        label: "CARRE'",
      },
      {
        value: 'B844',
        label: 'CARTIGLIANO',
      },
      {
        value: 'B992',
        label: 'CASOTTO',
      },
      {
        value: 'C037',
        label: 'CASSOLA',
      },
      {
        value: 'C056',
        label: 'CASTEGNERO',
      },
      {
        value: 'C119',
        label: 'CASTELGOMBERTO',
      },
      {
        value: 'C605',
        label: 'CHIAMPO',
      },
      {
        value: 'C650',
        label: 'CHIUPPANO',
      },
      {
        value: 'C734',
        label: 'CISMON o CISMON DEL GRAPPA',
      },
      {
        value: 'C824',
        label: 'COGOLLO o COGOLLO DEL CENGIO',
      },
      {
        value: 'M426',
        label: 'COLCERESA',
      },
      {
        value: 'C949',
        label: 'CONCO',
      },
      {
        value: 'D020',
        label: 'CORNEDO o CORNEDO VICENTINO',
      },
      {
        value: 'D107',
        label: 'COSTABISSARA',
      },
      {
        value: 'D136',
        label: 'CREAZZO',
      },
      {
        value: 'D156',
        label: 'CRESPADORO',
      },
      {
        value: 'D183',
        label: 'CROSARA',
      },
      {
        value: 'D379',
        label: 'DUEVILLE',
      },
      {
        value: 'D407',
        label: 'ENEGO',
      },
      {
        value: 'D496',
        label: 'FARA VICENTINO',
      },
      {
        value: 'D717',
        label: 'FORNI o TONEZZA o TONEZZA DEL CIMONE',
      },
      {
        value: 'D721',
        label: "FORNI IN VAL D'ASTICO",
      },
      {
        value: 'D750',
        label: 'FOZA',
      },
      {
        value: 'D882',
        label: 'GALLIO',
      },
      {
        value: 'D897',
        label: 'GAMBELLARA',
      },
      {
        value: 'D902',
        label: 'GAMBUGLIANO',
      },
      {
        value: 'E138',
        label: 'GRANCONA',
      },
      {
        value: 'E184',
        label: 'GRISIGNANO o GRISIGNANO DI ZOCCO',
      },
      {
        value: 'E226',
        label: 'GRUMOLO DELLE ABBADESSE',
      },
      {
        value: 'E354',
        label: 'ISOLA DI MALO o ISOLA VICENTINA',
      },
      {
        value: 'E403',
        label: 'LAGHI',
      },
      {
        value: 'E465',
        label: 'LASTEBASSE',
      },
      {
        value: 'E671',
        label: 'LONGARE',
      },
      {
        value: 'E682',
        label: 'LONIGO',
      },
      {
        value: 'E731',
        label: 'LUGO o LUGO DI VICENZA',
      },
      {
        value: 'E762',
        label: 'LUSIANA',
      },
      {
        value: 'M427',
        label: 'LUSIANA CONCO',
      },
      {
        value: 'E828',
        label: 'MAGRE o MAGRE VICENTINO',
      },
      {
        value: 'E864',
        label: 'MALO',
      },
      {
        value: 'E912',
        label: 'MARANO o MARANO VICENTINO',
      },
      {
        value: 'E970',
        label: 'MAROSTICA',
      },
      {
        value: 'F019',
        label: 'MASON o MASON VICENTINO',
      },
      {
        value: 'F306',
        label: 'MOLVENA',
      },
      {
        value: 'F486',
        label: 'MONTE DI MALO',
      },
      {
        value: 'F442',
        label: 'MONTEBELLO o MONTEBELLO VICENTINO',
      },
      {
        value: 'F464',
        label: 'MONTECCHIO MAGGIORE',
      },
      {
        value: 'F465',
        label: 'MONTECCHIO PRECALCINO',
      },
      {
        value: 'F514',
        label: 'MONTEGALDA',
      },
      {
        value: 'F515',
        label: 'MONTEGALDELLA',
      },
      {
        value: 'F662',
        label: 'MONTEVIALE',
      },
      {
        value: 'F675',
        label: 'MONTICELLO CONTE OTTO',
      },
      {
        value: 'F696',
        label: 'MONTORSO VICENTINO',
      },
      {
        value: 'F768',
        label: 'MOSSANO',
      },
      {
        value: 'F810',
        label: 'MURE o SALCEDO',
      },
      {
        value: 'F829',
        label: 'MUSSOLENTE',
      },
      {
        value: 'F838',
        label: 'NANTO',
      },
      {
        value: 'F922',
        label: 'NOGAROLE o NOGAROLE VICENTINO',
      },
      {
        value: 'F946',
        label: 'NOVALE',
      },
      {
        value: 'F957',
        label: 'NOVE',
      },
      {
        value: 'F964',
        label: 'NOVENTA VICENTINA',
      },
      {
        value: 'G095',
        label: 'ORGIANO',
      },
      {
        value: 'G406',
        label: 'PEDEMONTE',
      },
      {
        value: 'G560',
        label: 'PIANEZZE',
      },
      {
        value: 'G694',
        label: 'PIOVENE o PIOVENE ROCCHETTE',
      },
      {
        value: 'G776',
        label: 'POIANA MAGGIORE o POJANA MAGGIORE',
      },
      {
        value: 'G931',
        label: 'POSINA',
      },
      {
        value: 'G943',
        label: 'POVE o POVE DEL GRAPPA',
      },
      {
        value: 'G957',
        label: 'POZZO o POZZOLEONE',
      },
      {
        value: 'H134',
        label: 'QUINTO o QUINTO VICENTINO',
      },
      {
        value: 'H214',
        label: 'RECOARO o RECOARO TERME',
      },
      {
        value: 'H361',
        label: 'ROANA',
      },
      {
        value: 'H512',
        label: "ROMANO o ROMANO D'EZZELINO",
      },
      {
        value: 'H556',
        label: "ROSA'",
      },
      {
        value: 'H580',
        label: 'ROSSANO o ROSSANO VENETO',
      },
      {
        value: 'H594',
        label: 'ROTZO',
      },
      {
        value: 'H863',
        label: 'SAN GERMANO o SAN GERMANO DEI BERICI',
      },
      {
        value: 'I047',
        label: 'SAN NAZARIO',
      },
      {
        value: 'I117',
        label: 'SAN PIETRO MUSSOLINO',
      },
      {
        value: 'I401',
        label: 'SAN VITO o SAN VITO DI LEGUZZANO',
      },
      {
        value: 'H829',
        label: 'SANDRIGO',
      },
      {
        value: 'I353',
        label: 'SANTORSO',
      },
      {
        value: 'I425',
        label: 'SARCEDO',
      },
      {
        value: 'I430',
        label: 'SAREGO',
      },
      {
        value: 'I527',
        label: 'SCHIAVON',
      },
      {
        value: 'I531',
        label: 'SCHIO',
      },
      {
        value: 'I783',
        label: 'SOLAGNA',
      },
      {
        value: 'I867',
        label: 'SOSSANO',
      },
      {
        value: 'I879',
        label: 'SOVIZZO',
      },
      {
        value: 'L156',
        label: 'TEZZE o TEZZE SUL BRENTA',
      },
      {
        value: 'L157',
        label: 'THIENE',
      },
      {
        value: 'L248',
        label: 'TORREBELVICINO',
      },
      {
        value: 'L297',
        label: 'TORRI DI QUARTESOLO',
      },
      {
        value: 'L387',
        label: 'TRESCHE-CONCA',
      },
      {
        value: 'L394',
        label: 'TRETTO',
      },
      {
        value: 'L433',
        label: 'TRISSINO',
      },
      {
        value: 'M384',
        label: 'VAL LIONA',
      },
      {
        value: 'M423',
        label: 'VALBRENTA',
      },
      {
        value: 'L551',
        label: 'VALDAGNO',
      },
      {
        value: 'L554',
        label: 'VALDASTICO',
      },
      {
        value: 'L624',
        label: 'VALLI DEI SIGNORI o VALLI DEL PASUBIO',
      },
      {
        value: 'L630',
        label: 'VALLONARA',
      },
      {
        value: 'L646',
        label: 'VALROVINA',
      },
      {
        value: 'L650',
        label: 'VALSTAGNA',
      },
      {
        value: 'L723',
        label: "VELO o VELO D'ASTICO",
      },
      {
        value: 'L840',
        label: 'VICENZA',
      },
      {
        value: 'L952',
        label: 'VILLAGA',
      },
      {
        value: 'M032',
        label: 'VILLAVERLA',
      },
      {
        value: 'M145',
        label: "ZANE'",
      },
      {
        value: 'M170',
        label: 'ZERMEGHEDO',
      },
      {
        value: 'M194',
        label: 'ZOVENCEDO',
      },
      {
        value: 'M199',
        label: 'ZUGLIANO',
      },
    ],
  },

  {
    value: 'VR',
    label: 'Verona',
    children: [
      {
        value: 'A061',
        label: 'AFFI',
      },
      {
        value: 'A137',
        label: "ALBAREDO o ALBAREDO D'ADIGE",
      },
      {
        value: 'A292',
        label: 'ANGIARI',
      },
      {
        value: 'A374',
        label: 'ARCOLE',
      },
      {
        value: 'A513',
        label: 'AVESA',
      },
      {
        value: 'A540',
        label: 'BADIA CALAVENA',
      },
      {
        value: 'A650',
        label: 'BARDOLINO',
      },
      {
        value: 'A737',
        label: 'BELFIORE o BELFIORE DI PORCILE',
      },
      {
        value: 'A758',
        label: 'BELLUNO VERONESE',
      },
      {
        value: 'A837',
        label: 'BEVILACQUA',
      },
      {
        value: 'A838',
        label: 'BEVILACQUA BOSCHI',
      },
      {
        value: 'A964',
        label: 'BONAVIGO',
      },
      {
        value: 'B070',
        label: "BOSCHI SANT'ANNA",
      },
      {
        value: 'B073',
        label: 'BOSCO CHIESANUOVA',
      },
      {
        value: 'B107',
        label: 'BOVOLONE',
      },
      {
        value: 'B151',
        label: 'BRENTINO',
      },
      {
        value: 'B152',
        label: 'BRENTINO BELLUNO',
      },
      {
        value: 'B154',
        label:
          'BRENZONE o BRENZONE SUL GARDA o CASTELLETTO o CASTELLETTO DI BRENZONE',
      },
      {
        value: 'B155',
        label: 'BREONIO',
      },
      {
        value: 'B296',
        label: 'BUSSOLENGO',
      },
      {
        value: 'B304',
        label: 'BUTTAPIETRA',
      },
      {
        value: 'B342',
        label: "CA' DI DAVID",
      },
      {
        value: 'B709',
        label: 'CAPRINO o CAPRINO VERONESE',
      },
      {
        value: 'B886',
        label: 'CASALEONE',
      },
      {
        value: 'C041',
        label: 'CASTAGNARO',
      },
      {
        value: 'C078',
        label: "CASTEL D'AZZANO",
      },
      {
        value: 'C225',
        label: 'CASTELNUOVO o CASTELNUOVO DEL GARDA o CASTELNUOVO DI VERONA',
      },
      {
        value: 'C326',
        label: 'CASTIONE o CASTIONE VERONESE',
      },
      {
        value: 'C370',
        label: 'CAVAION o CAVAION VERONESE',
      },
      {
        value: 'C412',
        label: 'CAZZANO o CAZZANO DI TRAMIGNA',
      },
      {
        value: 'C498',
        label: 'CEREA',
      },
      {
        value: 'C538',
        label: 'CERRO o CERRO VERONESE',
      },
      {
        value: 'C890',
        label: 'COLOGNA o COLOGNA VENETA',
      },
      {
        value: 'C897',
        label: 'COLOGNOLA o COLOGNOLA AI COLLI',
      },
      {
        value: 'C943',
        label: 'CONCAMARISE',
      },
      {
        value: 'D039',
        label: 'CORREZZO',
      },
      {
        value: 'D118',
        label: 'COSTERMANO o COSTERMANO SUL GARDA',
      },
      {
        value: 'D193',
        label: 'CUCCA o VERONELLA',
      },
      {
        value: 'D317',
        label: "DOLCE'",
      },
      {
        value: 'D419',
        label: "ERBE'",
      },
      {
        value: 'D420',
        label: 'ERBEZZO',
      },
      {
        value: 'D549',
        label: 'FERRARA DI MONTE BALDO',
      },
      {
        value: 'D818',
        label: 'FUMANE',
      },
      {
        value: 'D915',
        label: 'GARDA',
      },
      {
        value: 'D957',
        label: 'GAZZO o GAZZO VERONESE',
      },
      {
        value: 'E171',
        label: 'GREZZANA',
      },
      {
        value: 'E284',
        label: 'ILLASI',
      },
      {
        value: 'E349',
        label: 'ISOLA DELLA SCALA',
      },
      {
        value: 'E358',
        label: 'ISOLA PORCARIZZA o ISOLA RIZZA',
      },
      {
        value: 'E489',
        label: 'LAVAGNO',
      },
      {
        value: 'E502',
        label: 'LAZISE',
      },
      {
        value: 'E512',
        label: 'LEGNAGO',
      },
      {
        value: 'E848',
        label: 'MALCESINE',
      },
      {
        value: 'E911',
        label: 'MARANO o MARANO DI VALPOLICELLA',
      },
      {
        value: 'E926',
        label: 'MARCELLISE',
      },
      {
        value: 'F172',
        label: 'MEZZANE DI SOTTO',
      },
      {
        value: 'F218',
        label: 'MINERBE',
      },
      {
        value: 'F252',
        label: 'MIZZOLE',
      },
      {
        value: 'F461',
        label: 'MONTECCHIA DI CROSARA',
      },
      {
        value: 'F508',
        label: "MONTEFORTE o MONTEFORTE D'ALPONE",
      },
      {
        value: 'F691',
        label: 'MONTORIO o MONTORIO VERONESE',
      },
      {
        value: 'F789',
        label: 'MOZZECANE',
      },
      {
        value: 'F860',
        label: 'NEGARINE',
      },
      {
        value: 'F861',
        label: 'NEGRAR o NEGRAR DI VALPOLICELLA',
      },
      {
        value: 'F918',
        label: 'NOGARA',
      },
      {
        value: 'F921',
        label: 'NOGAROLE o NOGAROLE ROCCA',
      },
      {
        value: 'G080',
        label: 'OPPEANO',
      },
      {
        value: 'G297',
        label: "PALU'",
      },
      {
        value: 'G341',
        label: "PARONA o PARONA ALL'ADIGE o PARONA DI VALPOLICELLA",
      },
      {
        value: 'G365',
        label: 'PASTRENGO',
      },
      {
        value: 'G481',
        label: 'PESCANTINA',
      },
      {
        value: 'G489',
        label: 'PESCHIERA o PESCHIERA DEL GARDA o PESCHIERA SUL LAGO DI GARDA',
      },
      {
        value: 'G945',
        label: 'POVEGLIANO VERONESE',
      },
      {
        value: 'H048',
        label: 'PRESSANA',
      },
      {
        value: 'H084',
        label: 'PRUN',
      },
      {
        value: 'H133',
        label: 'QUINTO o QUINTO DI VALPANTENA',
      },
      {
        value: 'H142',
        label: 'QUINZANO o QUINZANO VERONESE',
      },
      {
        value: 'H356',
        label: 'RIVOLI o RIVOLI VERONESE',
      },
      {
        value: 'H522',
        label: "RONCA'",
      },
      {
        value: 'H540',
        label: "RONCO o RONCO ALL'ADIGE",
      },
      {
        value: 'H606',
        label: 'ROVERCHIARA',
      },
      {
        value: 'H608',
        label: "ROVERE' DI VELO o ROVERE' VERONESE",
      },
      {
        value: 'H610',
        label: "ROVEREDO o ROVEREDO DI GUA'",
      },
      {
        value: 'H712',
        label: 'SALINE o SAN MAURO DI SALINE',
      },
      {
        value: 'H714',
        label: 'SALIZZOLE',
      },
      {
        value: 'H783',
        label: 'SAN BONIFACIO',
      },
      {
        value: 'H916',
        label: 'SAN GIOVANNI ILARIONE',
      },
      {
        value: 'H924',
        label: 'SAN GIOVANNI LUPATOTO',
      },
      {
        value: 'I003',
        label: 'SAN MARTINO BUON ALBERGO',
      },
      {
        value: 'I022',
        label: "SAN MASSIMO o SAN MASSIMO ALL'ADIGE",
      },
      {
        value: 'I041',
        label: 'SAN MICHELE o SAN MICHELE EXTRA',
      },
      {
        value: 'I105',
        label: 'SAN PIETRO DI MORUBIO',
      },
      {
        value: 'I109',
        label: 'SAN PIETRO IN CARIANO',
      },
      {
        value: 'I414',
        label: 'SAN ZENO DI MONTAGNA',
      },
      {
        value: 'H944',
        label: 'SANGUINETTO',
      },
      {
        value: 'I259',
        label: "SANT'AMBROGIO o SANT'AMBROGIO DI VALPOLICELLA",
      },
      {
        value: 'I292',
        label: "SANT'ANNA D'ALFAEDO",
      },
      {
        value: 'I246',
        label: 'SANTA MARIA IN STELLE',
      },
      {
        value: 'I594',
        label: 'SELVA DI PROGNO',
      },
      {
        value: 'I775',
        label: 'SOAVE',
      },
      {
        value: 'I821',
        label: 'SOMMACAMPAGNA',
      },
      {
        value: 'I826',
        label: 'SONA',
      },
      {
        value: 'I850',
        label: "SORGA'",
      },
      {
        value: 'L136',
        label: 'TERRAZZO',
      },
      {
        value: 'L287',
        label: 'TORRI o TORRI DEL BENACO',
      },
      {
        value: 'L364',
        label: 'TREGNAGO',
      },
      {
        value: 'L396',
        label: 'TREVENZUOLO',
      },
      {
        value: 'L567',
        label: 'VALEGGIO o VALEGGIO SUL MINCIO',
      },
      {
        value: 'L722',
        label: 'VELO o VELO VERONESE',
      },
      {
        value: 'L781',
        label: 'VERONA',
      },
      {
        value: 'L810',
        label: 'VESTENANOVA',
      },
      {
        value: 'L869',
        label: 'VIGASIO',
      },
      {
        value: 'L912',
        label: 'VILLA BARTOLOMEA',
      },
      {
        value: 'L949',
        label: 'VILLAFRANCA o VILLAFRANCA DI VERONA',
      },
      {
        value: 'M172',
        label: 'ZEVIO',
      },
      {
        value: 'M178',
        label: 'ZIMELLA',
      },
    ],
  },

  {
    value: 'VS',
    label: 'Medio Campidano',
    children: [],
  },

  {
    value: 'VT',
    label: 'Viterbo',
    children: [
      {
        value: 'A040',
        label: 'ACQUAPENDENTE',
      },
      {
        value: 'A548',
        label: 'BAGNAIA',
      },
      {
        value: 'A577',
        label: 'BAGNOREA o BAGNOREGIO',
      },
      {
        value: 'A628',
        label: 'BARBARANO o BARBARANO ROMANO',
      },
      {
        value: 'A701',
        label: 'BASSANELLO o VASANELLO',
      },
      {
        value: 'A704',
        label: 'BASSANO DI SUTRI o BASSANO ROMANO',
      },
      {
        value: 'A706',
        label: 'BASSANO IN TEVERINA',
      },
      {
        value: 'A857',
        label: 'BIEDA o BLERA',
      },
      {
        value: 'A949',
        label: 'BOLSENA',
      },
      {
        value: 'A955',
        label: 'BOMARZO',
      },
      {
        value: 'B597',
        label: 'CANEPINA',
      },
      {
        value: 'B604',
        label: 'CANINO',
      },
      {
        value: 'B663',
        label: 'CAPODIMONTE',
      },
      {
        value: 'B688',
        label: 'CAPRANICA',
      },
      {
        value: 'B691',
        label: 'CAPRAROLA',
      },
      {
        value: 'B735',
        label: 'CARBOGNANO',
      },
      {
        value: 'C068',
        label: 'CASTEL CELLESI',
      },
      {
        value: 'C269',
        label: "CASTEL SANT'ELIA",
      },
      {
        value: 'C315',
        label: 'CASTIGLIONE IN TEVERINA',
      },
      {
        value: 'C446',
        label: 'CELLENO',
      },
      {
        value: 'C447',
        label: 'CELLERE',
      },
      {
        value: 'C765',
        label: 'CIVITA CASTELLANA',
      },
      {
        value: 'C780',
        label: "CIVITELLA D'AGLIANO",
      },
      {
        value: 'C988',
        label: 'CORCHIANO',
      },
      {
        value: 'D503',
        label: 'FARNESE',
      },
      {
        value: 'D870',
        label: 'GALLESE',
      },
      {
        value: 'E126',
        label: 'GRADOLI',
      },
      {
        value: 'E128',
        label: 'GRAFFIGNANO',
      },
      {
        value: 'E210',
        label: 'GROTTE DI CASTRO',
      },
      {
        value: 'E211',
        label: 'GROTTE DI SANTO STEFANO',
      },
      {
        value: 'E467',
        label: 'LATERA',
      },
      {
        value: 'E713',
        label: 'LUBRIANO',
      },
      {
        value: 'E978',
        label: 'MARTA',
      },
      {
        value: 'F603',
        label: 'MONTE ROMANO',
      },
      {
        value: 'F499',
        label: 'MONTEFIASCONE',
      },
      {
        value: 'F606',
        label: 'MONTEROSI',
      },
      {
        value: 'F868',
        label: 'NEPI',
      },
      {
        value: 'G065',
        label: 'ONANO',
      },
      {
        value: 'G135',
        label: 'ORTE',
      },
      {
        value: 'G571',
        label: 'PIANSANO',
      },
      {
        value: 'H071',
        label: 'PROCENO',
      },
      {
        value: 'H419',
        label: 'ROCCALVECCE',
      },
      {
        value: 'H534',
        label: 'RONCIGLIONE',
      },
      {
        value: 'H913',
        label: 'SAN GIOVANNI DI BIEDA o VILLA SAN GIOVANNI IN TUSCIA',
      },
      {
        value: 'H969',
        label: 'SAN LORENZO NUOVO',
      },
      {
        value: 'L017',
        label: 'SUTRI',
      },
      {
        value: 'L150',
        label: 'TESSENNANO',
      },
      {
        value: 'L569',
        label: 'VALENTANO',
      },
      {
        value: 'L612',
        label: 'VALLERANO',
      },
      {
        value: 'L814',
        label: 'VETRALLA',
      },
      {
        value: 'L882',
        label: 'VIGNANELLO',
      },
      {
        value: 'M082',
        label: 'VITERBO',
      },
      {
        value: 'M086',
        label: 'VITORCHIANO',
      },
    ],
  },

  {
    value: 'VV',
    label: 'Vibo Valentia',
    children: [
      {
        value: 'A043',
        label: 'ACQUARO',
      },
      {
        value: 'A386',
        label: 'ARENA',
      },
      {
        value: 'B169',
        label: 'BRIATICO',
      },
      {
        value: 'B197',
        label: 'BROGNATURO',
      },
      {
        value: 'B655',
        label: 'CAPISTRANO',
      },
      {
        value: 'C581',
        label: 'CESSANITI',
      },
      {
        value: 'D253',
        label: "DASA'",
      },
      {
        value: 'D303',
        label: 'DINAMI',
      },
      {
        value: 'D364',
        label: 'DRAPIA',
      },
      {
        value: 'D453',
        label: 'FABRIZIA',
      },
      {
        value: 'D587',
        label: 'FILADELFIA',
      },
      {
        value: 'D589',
        label: 'FILANDARI',
      },
      {
        value: 'D596',
        label: 'FILOGASO',
      },
      {
        value: 'D767',
        label: 'FRANCICA',
      },
      {
        value: 'D988',
        label: 'GEROCARNE',
      },
      {
        value: 'E321',
        label: 'IONADI',
      },
      {
        value: 'E389',
        label: 'JOPPOLO',
      },
      {
        value: 'E590',
        label: 'LIMBADI',
      },
      {
        value: 'E836',
        label: 'MAIERATO',
      },
      {
        value: 'F207',
        label: 'MILETO',
      },
      {
        value: 'F364',
        label: 'MONGIANA',
      },
      {
        value: 'F843',
        label: 'NARDODIPACE',
      },
      {
        value: 'F893',
        label: 'NICOTERA',
      },
      {
        value: 'G335',
        label: 'PARGHELIA',
      },
      {
        value: 'G722',
        label: 'PIZZO',
      },
      {
        value: 'G728',
        label: 'PIZZONI',
      },
      {
        value: 'G785',
        label: 'POLIA',
      },
      {
        value: 'H271',
        label: 'RICADI',
      },
      {
        value: 'H516',
        label: 'ROMBIOLO',
      },
      {
        value: 'H785',
        label: 'SAN CALOGERO',
      },
      {
        value: 'I350',
        label: "SANT'ONOFRIO",
      },
      {
        value: 'I744',
        label: 'SIMBARIO',
      },
      {
        value: 'I853',
        label: 'SORIANELLO',
      },
      {
        value: 'I884',
        label: 'SPADOLA',
      },
      {
        value: 'I905',
        label: 'SPILINGA',
      },
      {
        value: 'I945',
        label: 'STEFANACONI',
      },
      {
        value: 'L452',
        label: 'TROPEA',
      },
      {
        value: 'L607',
        label: 'VALLELONGA',
      },
      {
        value: 'L699',
        label: 'VAZZANO',
      },
      {
        value: 'M138',
        label: 'ZACCANOPOLI',
      },
      {
        value: 'M143',
        label: 'ZAMBRONE',
      },
      {
        value: 'M204',
        label: 'ZUNGRI',
      },
    ],
  },

  {
    value: 'EE',
    label: 'Estero',
    children: [
      {
        value: 'Z200',
        label: 'AFGHANISTAN',
      },
      {
        value: 'Z300',
        label: 'AFRICA DEL SUD-OVEST o NAMIBIA o NAMIBIA=AFRICA DEL SUD OVEST',
      },
      {
        value: 'Z100',
        label: 'ALBANIA',
      },
      {
        value: 'Z301',
        label: 'ALGERIA',
      },
      {
        value: 'Z101',
        label: 'ANDORRA',
      },
      {
        value: 'Z302',
        label: 'ANGOLA',
      },
      {
        value: 'Z529',
        label: 'ANGUILLA (ISOLA)',
      },
      {
        value: 'Z532',
        label: 'ANTIGUA E BARBUDA',
      },
      {
        value: 'Z500',
        label: 'ANTILLE BRITANNICHE',
      },
      {
        value: 'Z521',
        label: 'ANTILLE BRITANNICHE',
      },
      {
        value: 'Z523',
        label: 'ANTILLE BRITANNICHE',
      },
      {
        value: 'Z501',
        label: 'ANTILLE OLANDESI',
      },
      {
        value: 'Z201',
        label: 'ARABIA MERIDIONALE FEDERAZIONE',
      },
      {
        value: 'Z202',
        label: 'ARABIA MERIDIONALE PROTETTORATO',
      },
      {
        value: 'Z203',
        label: 'ARABIA SAUDITA',
      },
      {
        value: 'Z600',
        label: 'ARGENTINA',
      },
      {
        value: 'Z137',
        label: 'ARMENIA',
      },
      {
        value: 'Z252',
        label: 'ARMENIA',
      },
      {
        value: 'Z700',
        label: 'AUSTRALIA',
      },
      {
        value: 'Z102',
        label: 'AUSTRIA',
      },
      {
        value: 'Z141',
        label: 'AZERBAIGIAN',
      },
      {
        value: 'Z253',
        label: 'AZERBAIGIAN',
      },
      {
        value: 'Z502',
        label: 'BAHAMA (ISOLE) o BAHAMAS',
      },
      {
        value: 'Z204',
        label: 'BAHREIN',
      },
      {
        value: 'Z249',
        label: 'BANGLADESH',
      },
      {
        value: 'Z522',
        label: 'BARBADOS',
      },
      {
        value: 'Z303',
        label: 'BASUTOLAND-SUD AFRICA BRITANNICO',
      },
      {
        value: 'Z304',
        label: 'BECIUANIA-SUD AFRICA BRITANNICO',
      },
      {
        value: 'Z103',
        label: 'BELGIO',
      },
      {
        value: 'Z512',
        label: 'BELIZE o HONDURAS BRITANNICO',
      },
      {
        value: 'Z314',
        label: 'BENIN o DAHOMEY=BENIN',
      },
      {
        value: 'Z400',
        label: 'BERMUDA (ISOLE)',
      },
      {
        value: 'Z205',
        label: 'BHUTAN',
      },
      {
        value: 'Z139',
        label: 'BIELORUSSIA o BIELORUSSIA=RUSSIA BIANCA',
      },
      {
        value: 'Z206',
        label: 'BIRMANIA o MYANMAR',
      },
      {
        value: 'Z601',
        label: 'BOLIVIA',
      },
      {
        value: 'Z364',
        label: 'BOPHUTHATSWANA',
      },
      {
        value: 'Z153',
        label: 'BOSNIA ED ERZEGOVINA',
      },
      {
        value: 'Z358',
        label: 'BOTSWANA',
      },
      {
        value: 'Z602',
        label: 'BRASILE',
      },
      {
        value: 'Z207',
        label: 'BRUNEI',
      },
      {
        value: 'Z104',
        label: 'BULGARIA',
      },
      {
        value: 'Z354',
        label: 'BURKINA o BURKINA FASO o VOLTA=ALTOVOLTA',
      },
      {
        value: 'Z305',
        label: 'BURUNDI',
      },
      {
        value: 'Z208',
        label: 'CAMBOGIA',
      },
      {
        value: 'Z306',
        label: 'CAMERUN',
      },
      {
        value: 'Z401',
        label: 'CANADA',
      },
      {
        value: 'Z307',
        label: 'CAPO VERDE o CAPO VERDE (ISOLE)',
      },
      {
        value: 'Z701',
        label: 'CAROLINE (ISOLE)',
      },
      {
        value: 'Z530',
        label: 'CAYMAN (ISOLE)',
      },
      {
        value: 'Z156',
        label: 'CECA REPUBBLICA o REPUBBLICA CECA',
      },
      {
        value: 'Z105',
        label: 'CECOSLOVACCHIA',
      },
      {
        value: 'Z308',
        label:
          'CENTRAFRICANA REPUBBLICA o CENTROAFRICANA REPUBBLICA o CENTROAFRICANO IMPERO',
      },
      {
        value: 'Z209',
        label: 'CEYLON o SRI LANKA',
      },
      {
        value: 'Z702',
        label: 'CHRISTMAS (ISOLA)',
      },
      {
        value: 'Z309',
        label: 'CIAD',
      },
      {
        value: 'Z603',
        label: 'CILE',
      },
      {
        value: 'Z217',
        label:
          'CINA NAZIONALE o FORMOSA=TAIWAN o TAIWAN o TAIWAN ( REPUBBLICA DELLA CINA NAZIONALE )',
      },
      {
        value: 'Z210',
        label: 'CINA REPUBBLICA POPOLARE',
      },
      {
        value: 'Z211',
        label: 'CIPRO',
      },
      {
        value: 'Z367',
        label: 'CISKEI',
      },
      {
        value: 'Z106',
        label: "CITTA' DEL VATICANO",
      },
      {
        value: 'Z212',
        label: 'COCOS (ISOLE)',
      },
      {
        value: 'Z604',
        label: 'COLOMBIA',
      },
      {
        value: 'Z310',
        label: 'COMORE o COMORE (ISOLE)',
      },
      {
        value: 'Z311',
        label: 'CONGO BRAZZAVILLE o CONGO REPUBBLICA POPOLARE',
      },
      {
        value: 'Z312',
        label: 'CONGO LEOPOLDVILLE o CONGO REPUBBLICA DEMOCRATICA o ZAIRE',
      },
      {
        value: 'Z703',
        label: 'COOK (ISOLE)',
      },
      {
        value: 'Z214',
        label: 'COREA DEL NORD',
      },
      {
        value: 'Z213',
        label: 'COREA DEL SUD o REPUBBLICA DI COREA',
      },
      {
        value: 'Z313',
        label: "COSTA D'AVORIO",
      },
      {
        value: 'Z215',
        label: 'COSTA DEI PIRATI=TRUCIAL STATES o EMIRATI ARABI UNITI',
      },
      {
        value: 'Z503',
        label: 'COSTA RICA',
      },
      {
        value: 'Z149',
        label: 'CROAZIA',
      },
      {
        value: 'Z504',
        label: 'CUBA',
      },
      {
        value: 'Z107',
        label: 'DANIMARCA',
      },
      {
        value: 'Z900',
        label: 'DIPENDENZE AUSTRALIANE',
      },
      {
        value: 'Z901',
        label: 'DIPENDENZE BRITANNICHE',
      },
      {
        value: 'Z800',
        label: 'DIPENDENZE CANADESI',
      },
      {
        value: 'Z902',
        label: 'DIPENDENZE FRANCESI',
      },
      {
        value: 'Z903',
        label: 'DIPENDENZE NEOZELANDESI',
      },
      {
        value: 'Z904',
        label: 'DIPENDENZE NORVEGESI ANTARTICHE',
      },
      {
        value: 'Z801',
        label: 'DIPENDENZE NORVEGESI ARTICHE',
      },
      {
        value: 'Z802',
        label: 'DIPENDENZE RUSSE o DIPENDENZE SOVIETICHE',
      },
      {
        value: 'Z905',
        label: 'DIPENDENZE STATUNITENSI',
      },
      {
        value: 'Z906',
        label: 'DIPENDENZE SUDAFRICANE',
      },
      {
        value: 'Z526',
        label: 'DOMINICA',
      },
      {
        value: 'Z505',
        label: 'DOMINICANA REPUBBLICA o REPUBBLICA DOMINICANA',
      },
      {
        value: 'Z605',
        label: 'ECUADOR',
      },
      {
        value: 'Z336',
        label: 'EGITTO',
      },
      {
        value: 'Z506',
        label: 'EL SALVADOR',
      },
      {
        value: 'Z368',
        label: 'ERITREA',
      },
      {
        value: 'Z144',
        label: 'ESTONIA',
      },
      {
        value: 'Z315',
        label: 'ETIOPIA',
      },
      {
        value: 'Z108',
        label: 'FAER OER (ISOLE)',
      },
      {
        value: 'Z609',
        label: 'FALKLAND (ISOLE) o MALVINE=FALKLAND (ISOLE)',
      },
      {
        value: 'Z154',
        label: 'FEDERAZIONE RUSSA o RUSSIA=FEDERAZIONE RUSSA',
      },
      {
        value: 'Z704',
        label: 'FIGI=VITI o VITI',
      },
      {
        value: 'Z216',
        label: 'FILIPPINE',
      },
      {
        value: 'Z109',
        label: 'FINLANDIA',
      },
      {
        value: 'Z110',
        label: 'FRANCIA',
      },
      {
        value: 'Z316',
        label: 'GABON',
      },
      {
        value: 'Z317',
        label: 'GAMBIA',
      },
      {
        value: 'Z218',
        label: 'GAZA (TERRITORIO DI)',
      },
      {
        value: 'Z136',
        label: 'GEORGIA',
      },
      {
        value: 'Z254',
        label: 'GEORGIA',
      },
      {
        value: 'Z112',
        label: 'GERMANIA o GERMANIA REPUBBLICA FEDERALE',
      },
      {
        value: 'Z111',
        label: 'GERMANIA REPUBBLICA DEMOCRATICA',
      },
      {
        value: 'Z318',
        label: 'GHANA',
      },
      {
        value: 'Z507',
        label: 'GIAMAICA',
      },
      {
        value: 'Z219',
        label: 'GIAPPONE',
      },
      {
        value: 'Z113',
        label: 'GIBILTERRA',
      },
      {
        value: 'Z361',
        label: 'GIBUTI',
      },
      {
        value: 'Z705',
        label: 'GILBERT E ELLICE (ISOLE)',
      },
      {
        value: 'Z220',
        label: 'GIORDANIA',
      },
      {
        value: 'Z114',
        label: 'GRAN BRETAGNA E IRLANDA DEL NORD o REGNO UNITO',
      },
      {
        value: 'Z115',
        label: 'GRECIA',
      },
      {
        value: 'Z524',
        label: 'GRENADA',
      },
      {
        value: 'Z402',
        label: 'GROENLANDIA',
      },
      {
        value: 'Z508',
        label: 'GUADALUPA',
      },
      {
        value: 'Z706',
        label: 'GUAM (ISOLA)',
      },
      {
        value: 'Z509',
        label: 'GUATEMALA',
      },
      {
        value: 'Z608',
        label: 'GUAYANA OLANDESE o SURINAME',
      },
      {
        value: 'Z607',
        label: 'GUAYANA=GUYANA FRANCESE o GUIANA FRANCESE',
      },
      {
        value: 'Z319',
        label: 'GUINEA',
      },
      {
        value: 'Z320',
        label: 'GUINEA BISSAU o GUINEA PORTOGHESE',
      },
      {
        value: 'Z321',
        label: 'GUINEA EQUATORIALE o GUINEA SPAGNOLA',
      },
      {
        value: 'Z606',
        label: 'GUYANA o GUYANA BRITANNICA',
      },
      {
        value: 'Z510',
        label: 'HAITI',
      },
      {
        value: 'Z511',
        label: 'HONDURAS',
      },
      {
        value: 'Z221',
        label: 'HONG KONG',
      },
      {
        value: 'Z323',
        label: 'IFNI',
      },
      {
        value: 'Z222',
        label: 'INDIA',
      },
      {
        value: 'Z223',
        label: 'INDONESIA',
      },
      {
        value: 'Z224',
        label: 'IRAN',
      },
      {
        value: 'Z225',
        label: 'IRAQ',
      },
      {
        value: 'Z707',
        label: 'IRIAN OCCIDENTALE',
      },
      {
        value: 'Z116',
        label: 'IRLANDA',
      },
      {
        value: 'Z117',
        label: 'ISLANDA',
      },
      {
        value: 'Z721',
        label: 'ISOLE CILENE (PASQUA E SALA Y GOMEZ) o PASQUA (ISOLA)',
      },
      {
        value: 'Z124',
        label: 'ISOLE DEL CANALE o NORMANNE (ISOLE)=ISOLE DEL CANALE',
      },
      {
        value: 'Z727',
        label: "ISOLE DELL'UNIONE o TOKELAU=ISOLE DELL'UNIONE",
      },
      {
        value: 'Z226',
        label: 'ISRAELE',
      },
      {
        value: 'Z118',
        label: 'IUGOSLAVIA o SERBIA E MONTENEGRO',
      },
      {
        value: 'Z255',
        label: 'KAZAKHSTAN o KAZAKISTAN',
      },
      {
        value: 'Z152',
        label: 'KAZAKISTAN',
      },
      {
        value: 'Z322',
        label: 'KENYA',
      },
      {
        value: 'Z142',
        label: 'KIRGHIZISTAN',
      },
      {
        value: 'Z256',
        label: 'KIRGHIZISTAN',
      },
      {
        value: 'Z731',
        label: 'KIRIBATI',
      },
      {
        value: 'Z160',
        label: 'KOSOVO',
      },
      {
        value: 'Z227',
        label: 'KUWAIT',
      },
      {
        value: 'Z324',
        label: 'LA REUNION (ISOLA)',
      },
      {
        value: 'Z228',
        label: 'LAOS',
      },
      {
        value: 'Z359',
        label: 'LESOTHO',
      },
      {
        value: 'Z145',
        label: 'LETTONIA',
      },
      {
        value: 'Z229',
        label: 'LIBANO',
      },
      {
        value: 'Z325',
        label: 'LIBERIA',
      },
      {
        value: 'Z326',
        label: 'LIBIA',
      },
      {
        value: 'Z119',
        label: 'LIECHTENSTEIN',
      },
      {
        value: 'Z146',
        label: 'LITUANIA',
      },
      {
        value: 'Z120',
        label: 'LUSSEMBURGO',
      },
      {
        value: 'Z231',
        label: 'MACAO',
      },
      {
        value: 'Z148',
        label: 'MACEDONIA o MACEDONIA DEL NORD',
      },
      {
        value: 'Z708',
        label: 'MACQUARIE (ISOLE)',
      },
      {
        value: 'Z327',
        label: 'MADAGASCAR',
      },
      {
        value: 'Z328',
        label: 'MALAWI',
      },
      {
        value: 'Z230',
        label: 'MALAYSIA',
      },
      {
        value: 'Z247',
        label: 'MALAYSIA',
      },
      {
        value: 'Z232',
        label: 'MALDIVE',
      },
      {
        value: 'Z329',
        label: 'MALI',
      },
      {
        value: 'Z121',
        label: 'MALTA',
      },
      {
        value: 'Z122',
        label: 'MAN (ISOLA)',
      },
      {
        value: 'Z709',
        label: 'MARCUS (ISOLE)',
      },
      {
        value: 'Z710',
        label: 'MARIANNE (ISOLE)',
      },
      {
        value: 'Z330',
        label: 'MAROCCO',
      },
      {
        value: 'Z711',
        label: 'MARSHALL o MARSHALL (ISOLE)',
      },
      {
        value: 'Z513',
        label: 'MARTINICA',
      },
      {
        value: 'Z331',
        label: 'MAURITANIA',
      },
      {
        value: 'Z332',
        label: 'MAURIZIO o MAURIZIO (ISOLE)',
      },
      {
        value: 'Z360',
        label: 'MAYOTTE (ISOLA)',
      },
      {
        value: 'Z514',
        label: 'MESSICO',
      },
      {
        value: 'Z735',
        label: 'MICRONESIA STATI FEDERATI',
      },
      {
        value: 'Z712',
        label: 'MIDWAY (ISOLE)',
      },
      {
        value: 'Z140',
        label: 'MOLDAVIA',
      },
      {
        value: 'Z123',
        label: 'MONACO',
      },
      {
        value: 'Z233',
        label: 'MONGOLIA',
      },
      {
        value: 'Z159',
        label: 'MONTENEGRO',
      },
      {
        value: 'Z531',
        label: 'MONTSERRAT',
      },
      {
        value: 'Z333',
        label: 'MOZAMBICO',
      },
      {
        value: 'Z713',
        label: 'NAURU o NAURU (ISOLE)',
      },
      {
        value: 'Z234',
        label: 'NEPAL',
      },
      {
        value: 'Z515',
        label: 'NICARAGUA',
      },
      {
        value: 'Z334',
        label: 'NIGER',
      },
      {
        value: 'Z335',
        label: 'NIGERIA',
      },
      {
        value: 'Z714',
        label: 'NIUE=SAVAGE (ISOLE) o SAVAGE (ISOLE)',
      },
      {
        value: 'Z715',
        label: 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)',
      },
      {
        value: 'Z125',
        label: 'NORVEGIA',
      },
      {
        value: 'Z716',
        label: 'NUOVA CALEDONIA (ISOLE E DIPENDENZE)',
      },
      {
        value: 'Z718',
        label: 'NUOVA GUINEA',
      },
      {
        value: 'Z719',
        label: 'NUOVA ZELANDA',
      },
      {
        value: 'Z717',
        label: 'NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)',
      },
      {
        value: 'Z235',
        label: 'OMAN',
      },
      {
        value: 'Z126',
        label: 'PAESI BASSI',
      },
      {
        value: 'Z236',
        label: 'PAKISTAN',
      },
      {
        value: 'Z734',
        label: 'PALAU o PALAU REPUBBLICA',
      },
      {
        value: 'Z516',
        label: "PANAMA o PANAMA'",
      },
      {
        value: 'Z517',
        label: 'PANAMA ZONA DEL CANALE',
      },
      {
        value: 'Z730',
        label: 'PAPUA NUOVA GUINEA',
      },
      {
        value: 'Z720',
        label: 'PAPUASIA',
      },
      {
        value: 'Z610',
        label: 'PARAGUAY',
      },
      {
        value: 'Z611',
        label: "PERU'",
      },
      {
        value: 'Z722',
        label: 'PITCAIRN (E DIPENDENZE)',
      },
      {
        value: 'Z723',
        label: 'POLINESIA FRANCESE (ISOLE)',
      },
      {
        value: 'Z127',
        label: 'POLONIA o REPUBBLICA DI POLONIA',
      },
      {
        value: 'Z128',
        label: 'PORTOGALLO',
      },
      {
        value: 'Z518',
        label: 'PUERTO RICO',
      },
      {
        value: 'Z237',
        label: 'QATAR',
      },
      {
        value: 'Z337',
        label: 'RHODESIA o ZIMBABWE',
      },
      {
        value: 'Z129',
        label: 'ROMANIA',
      },
      {
        value: 'Z338',
        label: 'RUANDA',
      },
      {
        value: 'Z238',
        label: 'RYUKYU (ISOLE)',
      },
      {
        value: 'Z362',
        label: 'SAHARA MERIDIONALE',
      },
      {
        value: 'Z363',
        label: 'SAHARA SETTENTRIONALE',
      },
      {
        value: 'Z339',
        label: 'SAHARA SPAGNOLO',
      },
      {
        value: 'Z533',
        label:
          'SAINT KITTS E NEVIS o SAINT KITTS E NEVIS=SAINT CHRISTOPHER E NEVIS',
      },
      {
        value: 'Z527',
        label: 'SAINT LUCIA',
      },
      {
        value: 'Z403',
        label: 'SAINT PIERRE ET MIQUELON (ISOLE)',
      },
      {
        value: 'Z528',
        label: 'SAINT VINCENT E GRENADINE',
      },
      {
        value: 'Z724',
        label: 'SALOMONE o SALOMONE (ISOLE)',
      },
      {
        value: 'Z726',
        label: 'SAMOA',
      },
      {
        value: 'Z725',
        label: 'SAMOA AMERICANE (ISOLE)',
      },
      {
        value: 'Z130',
        label: 'SAN MARINO',
      },
      {
        value: 'Z340',
        label: "SANT'ELENA (ISOLA)",
      },
      {
        value: 'Z341',
        label: "SAO TOME' E PRINCIPE o SAO TOME' E PRINCIPE (ISOLE)",
      },
      {
        value: 'Z342',
        label: 'SEICELLE o SEICELLE (ISOLE)',
      },
      {
        value: 'Z343',
        label: 'SENEGAL',
      },
      {
        value: 'Z158',
        label: 'SERBIA',
      },
      {
        value: 'Z344',
        label: 'SIERRA LEONE',
      },
      {
        value: 'Z239',
        label: 'SIKKIM',
      },
      {
        value: 'Z248',
        label: 'SINGAPORE',
      },
      {
        value: 'Z240',
        label: 'SIRIA',
      },
      {
        value: 'Z155',
        label: 'SLOVACCHIA',
      },
      {
        value: 'Z150',
        label: 'SLOVENIA',
      },
      {
        value: 'Z345',
        label: 'SOMALIA',
      },
      {
        value: 'Z346',
        label: 'SOMALIA FRANCESE o TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA',
      },
      {
        value: 'Z131',
        label: 'SPAGNA',
      },
      {
        value: 'Z404',
        label: "STATI UNITI D'AMERICA",
      },
      {
        value: 'Z907',
        label: 'SUD SUDAN',
      },
      {
        value: 'Z347',
        label: 'SUDAFRICANA REPUBBLICA',
      },
      {
        value: 'Z348',
        label: 'SUDAN',
      },
      {
        value: 'Z132',
        label: 'SVEZIA',
      },
      {
        value: 'Z133',
        label: 'SVIZZERA',
      },
      {
        value: 'Z349',
        label: 'SWAZILAND o SWAZILAND-SUDAFRICA BRITANNICO',
      },
      {
        value: 'Z147',
        label: 'TAGIKISTAN',
      },
      {
        value: 'Z257',
        label: 'TAGIKISTAN',
      },
      {
        value: 'Z350',
        label: 'TANGANICA',
      },
      {
        value: 'Z357',
        label: 'TANZANIA',
      },
      {
        value: 'Z161',
        label: 'TERRITORI PALESTINESI',
      },
      {
        value: 'Z241',
        label: 'THAILANDIA',
      },
      {
        value: 'Z242',
        label: 'TIMOR (ISOLA)',
      },
      {
        value: 'Z351',
        label: 'TOGO',
      },
      {
        value: 'Z728',
        label: 'TONGA o TONGA=ISOLE DEGLI AMICI',
      },
      {
        value: 'Z365',
        label: 'TRANSKEI',
      },
      {
        value: 'Z612',
        label: 'TRINIDAD E TOBAGO',
      },
      {
        value: 'Z352',
        label: 'TUNISIA',
      },
      {
        value: 'Z243',
        label: 'TURCHIA',
      },
      {
        value: 'Z151',
        label: 'TURKMENISTAN',
      },
      {
        value: 'Z258',
        label: 'TURKMENISTAN',
      },
      {
        value: 'Z519',
        label: 'TURKS E CAICOS (ISOLE)',
      },
      {
        value: 'Z732',
        label: 'TUVALU',
      },
      {
        value: 'Z138',
        label: 'UCRAINA',
      },
      {
        value: 'Z353',
        label: 'UGANDA',
      },
      {
        value: 'Z134',
        label: 'UNGHERIA',
      },
      {
        value: 'Z135',
        label: 'UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE',
      },
      {
        value: 'Z613',
        label: 'URUGUAY',
      },
      {
        value: 'Z143',
        label: 'UZBEKISTAN',
      },
      {
        value: 'Z259',
        label: 'UZBEKISTAN',
      },
      {
        value: 'Z733',
        label: 'VANUATU',
      },
      {
        value: 'Z366',
        label: 'VENDA',
      },
      {
        value: 'Z614',
        label: 'VENEZUELA',
      },
      {
        value: 'Z520',
        label: 'VERGINI AMERICANE (ISOLE)',
      },
      {
        value: 'Z525',
        label: 'VERGINI BRITANNICHE (ISOLE)',
      },
      {
        value: 'Z251',
        label: 'VIETNAM',
      },
      {
        value: 'Z245',
        label: 'VIETNAM DEL NORD',
      },
      {
        value: 'Z244',
        label: 'VIETNAM DEL SUD',
      },
      {
        value: 'Z729',
        label: 'WALLIS E FUTUNA (ISOLE)',
      },
      {
        value: 'Z246',
        label: 'YEMEN',
      },
      {
        value: 'Z250',
        label: 'YEMEN REPUBBLICA DEMOCRATICA POPOLARE',
      },
      {
        value: 'Z355',
        label: 'ZAMBIA',
      },
      {
        value: 'Z356',
        label: 'ZANZIBAR',
      },
    ],
  },
];
